import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import PriceListsApi from 'api/connections/PriceLists/PriceListsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

const PriceListDeleteModal = props => {
  const {
    priceList: { id: priceListId, name: priceListName },
    onClose,
    onSuccess,
    handleError,
    setAlert
  } = props;

  const onApprove = async () => {
    try {
      await PriceListsApi.deletePricesList(priceListId);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      handleError(err);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete price list <%=priceListName%>?',
        { priceListName }
      )}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

PriceListDeleteModal.propTypes = {
  priceList: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default PriceListDeleteModal;
