import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class TargetsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.targetsPath = 'targets';
    this.targetPath = 'targets/:id';
    this.targetConfirmPath = 'targets/:id/confirm';
    this.targetBonusPath = 'targets/:id/bonus';
    this.targetCopyPath = 'targets/:id/copy';
  }

  getTargets(pathParams) {
    return this.get(this.targetsPath, pathParams);
  }

  getTarget(id) {
    return this.get(insertPathParams(this.targetPath, { id }));
  }

  createTarget(payload) {
    return this.post(this.targetsPath, payload);
  }

  deleteTarget(id) {
    return this.delete(insertPathParams(this.targetPath, { id }));
  }

  updateTarget(id, payload) {
    return this.put(insertPathParams(this.targetPath, { id }), payload);
  }

  confirmTarget(id) {
    return this.post(insertPathParams(this.targetConfirmPath, { id }));
  }

  createTargetBonus(id, payload) {
    return this.post(insertPathParams(this.targetBonusPath, { id }), payload);
  }

  copyTarget(id, payload) {
    return this.post(insertPathParams(this.targetCopyPath, { id }), payload);
  }
}

export default new TargetsApi();
