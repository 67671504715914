export default {
  Wholesalers: 'Hurtownie',
  Wholesaler: 'Hurtownia',
  'All wholesaler': 'Wszystkie hurtownie',
  'Wholesaler details': 'Szczegóły hurtowni',
  'Create wholesaler': 'Dodawanie hurtowni',
  'Update wholesaler': 'Edytowanie hurtowni',
  'Main contact email': 'Główny email kontaktowy',
  'Orders email': 'Email do zamówień',
  'Same as main': 'Taki sam jak główny',
  'Orders email same as main': 'Email do zamówień taki sam jak główny',
  'Deleting wholesaler successfully finish':
    'Usuwanie hurtowni zakończone sukcesem',
  'Are you sure you want to delete wholesaler <%= name %>':
    'Czy na pewno chcesz usunać hurtownie <%= name %>',
  Position: 'Stanowisko',
  'Contacts data': 'Dane kontaktowe',
  'Create contact': 'Dodawanie kontaktu',
  'Create wholesalers contact': 'Dodawanie kontaktu hurtowni',
  'Update wholesalers contact': 'Edytownaie kontaktu hurtowni',
  'Are you sure you want to delete contact <%= full_name %>':
    'Czy na pewno chcesz usunać kontakt <%= full_name %>',
  'Update contact': 'Edytowanie kontaktu',
  'Wholesaler Name': 'Nazwa hurtowni',
  'Import emails': 'Emaile do importów',
  'Import domains': 'Domeny do importów',
  Contact: 'Kontakt',
  'Products mapping': 'Mapowanie produktów',
  'All products': 'Wszystkie produkty',
  'Wholesaler notes': 'Notatki hurtowni',
  'Wholesaler files': 'Pliki hurtowni',
  'Add wholesaler file': 'Dodawanie pliku hurtowni',
  'Import mappings': 'Importuj mapowania',
  'Import products mappings': 'Importuj mapowania produktów',
  'Import client mappings': 'Importuj mapowania klientów',
  'Load csv or excel file': 'Załaduj plik csv lub excel',
  'The warehouse provides sales data': 'Hurtownia dostarcza dane sprzedażowe',
  'Wholesaler tax number': 'NIP hurtowni',
  'Import columns': 'Kolumny importu',
  'Update wholesaler import columns': 'Edytuj nazwy kolumn importów',
  'Mapping deleted': 'Mapowanie usunięte',
  'Clients mapping': 'Mapowanie klientów',
  'Create client mapping': 'Dodawania mapowania klienta',
  'Update client mapping': 'Edycja mapowania klienta',
  'Mapping saved': 'Mapowanie zapisane',
  'Imported field name': 'Nazwa pola importowanego',
  'Wholesaler column name': 'Nazwa pola w hurtowni',
  'Columns mapping': 'Mapowanie kolumn',
  'Default values': 'Wartości domyślne',
  'Custom value': 'Wartość niestandardowa',
  save: 'zapisz',
  'General Information': 'Ogólne informacje',
  'Address data': 'Dane adresowe',
  'Main contact details': 'Główne dane kontaktowe',
  'Data import': 'Import danych',
  'External name': 'Zewnętrzna nazwa',
  Agencies: 'Oddziały',
  Agency: 'Oddział',
  'Agency saved': 'Oddział zapisany',
  'Agency deleted': 'Oddział usunięty',
  'Create wholesaler agency': 'Dodawanie oddziału hurtowni',
  'Update wholesaler agency': 'Edytowanie oddziału hurtowni',
  'Create agency': 'Dodawanie oddziału',
  'Update agency': 'Edytowanie oddziału',
  'Are you sure you want to delete agency <%=agencyName%> for wholesaler <%=wholesalerName%>':
    'Czy na pewno chcesz usunać oddział <%=agencyName%> dla hurtowni <%=wholesalerName%>',
  'Wholesaler dont have agencies': 'Hurtownia nie posiada oddziałów'
};
