import Visits from 'modules/Clients/component/Details/Visits/Visits';
import PropTypes from 'prop-types';
import VisitsTable from 'modules/Clients/component/Details/Visits/VisitsTable';
import VisitsFilter from 'modules/Clients/component/Details/Visits/VisitsTable/Filter';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Pagination from 'modules/Layout/component/List/Pagination';
import { visitStatusesList } from 'modules/Visits/utils/visitStatuses';

class VisitsHistory extends Visits {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        date_to: new Date()
      },
      filterValidation: {},
      sort: { sort_field: 'scheduled_at' },
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      visits: []
    };
  }

  componentDidMount() {
    this.fetchVisits('date_to');
  }

  render() {
    return (
      <>
        <VisitsFilter
          filter={this.state.filter}
          validation={this.state.filterValidation}
          setFilter={filter => this.setFilter(filter, 'date_to')}
          hasRole={this.context.hasRole}
          archive
          visits={visitStatusesList}
        />
        <VisitsTable
          sort={this.state.sort}
          setSort={sort => this.setSort(sort, 'date_to')}
          visits={this.state.visits}
        />
        <Pagination
          pagination={this.state.pagination}
          setPagination={pagination =>
            this.setPagination(pagination, 'date_to')
          }
          rowsPerPageOptions={[5, 10, 15]}
        />
      </>
    );
  }
}

VisitsHistory.propTypes = {
  setAlert: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired
};

export default VisitsHistory;
