import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import getPathQuery from 'api/utils/getPathQuery';
import { useLocation } from 'react-router-dom';

const TabPanel = props => {
  const { children, index } = props;
  const location = useLocation();

  const tabIndex = getPathQuery(location.search, 'tab_index');

  return (
    <div role='tabpanel' hidden={tabIndex !== index} id={`tabpanel-${index}`}>
      {tabIndex === index && (
        <Box style={{ paddingTop: '1.5rem' }}>{children}</Box>
      )}
    </div>
  );
};

TabPanel.defaultProps = {
  children: <></>
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired
};

export default TabPanel;
