import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t, { availableLocals } from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { ROUTE_PRODUCT_CATEGORIES_LIST } from 'routing/routes/ProductCategories';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import { formatProductCategoriesError } from 'modules/ProductCategories/utils/formater';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import {
  TYPE_GROUP,
  TYPE_SUBCATEGORY
} from 'modules/ProductCategories/context/ProductCategoriesProvider';
import formatFormMultiLangObj from 'modules/Shared/utils/formatFormMultiLangObj';
import Checkbox from 'modules/Layout/component/Checkbox';

class ProductCategoriesUpdateForm extends Component {
  constructor(props) {
    const {
      item,
      itemType,
      selectedGroupId,
      selectedCategoryId
    } = props.productCategoriesContext;

    super(props);
    this.state = {
      formData: {
        group_id: itemType !== 'group' ? selectedGroupId : '',
        category_id: itemType === 'subcategory' ? selectedCategoryId : '',
        name: formatFormMultiLangObj(item.names),
        include_in_targets: item.include_in_targets
      },
      validation: {
        pl: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Name') })
        },
        group_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Product group')
          })
        },
        category_id: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Category') })
        }
      },
      loading: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onLangChange = this.onLangChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.formatProductsCategoriesOptions = this.formatProductsCategoriesOptions.bind(
      this
    );
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.history.push(ROUTE_PRODUCT_CATEGORIES_LIST);
  }

  handleError(err) {
    const { message, errors } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const { validation } = this.state;
      const newValidateState = formatProductCategoriesError(errors);
      this.setState({
        validation: { ...validation, ...newValidateState }
      });
      this.props.setAlert(message);
    } else if (err instanceof ApiError) {
      this.props.setAlert(message);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onSelectChange(e) {
    const { name, value } = e.target;

    this.setState(state => {
      const { formData, validation } = state;
      return {
        formData: {
          ...formData,
          group_id: name === 'group_id' ? value : formData.group_id,
          category_id: name === 'category_id' ? value : ''
        },
        validation: {
          ...validation,
          [name]: { ...validation[name], status: false }
        }
      };
    });
  }

  onLangChange(e) {
    const { name, value } = e.target;

    this.setState(state => {
      const { formData, validation } = state;
      return {
        formData: {
          ...formData,
          name: { ...formData.name, [name]: value }
        },
        validation: {
          ...validation,
          pl: {
            ...validation.pl,
            status: name === 'pl' ? false : validation.pl.status
          }
        }
      };
    });
  }

  onCheckboxChange(e) {
    this.setState(prevState => {
      const { checked } = e.target;

      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          include_in_targets: checked
        }
      };
    });
  }

  runValidation() {
    /* eslint prefer-const: "off" */
    let [isValid, newValidateState] = validate(
      { ...this.state.formData, ...this.state.formData.name },
      this.state.validation
    );
    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const {
      item: { id: itemId }
    } = this.props.productCategoriesContext;
    this.setState({ loading: true });

    try {
      const {
        data: { data: res }
      } = await ProductCategoriesApi.updateProductCategories(
        itemId,
        this.state.formData
      );
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  formatProductsCategoriesOptions() {
    const groupObj = this.props.productCategories.find(
      group => group.id === this.state.formData.group_id
    );

    if (!groupObj) return [];

    return formatOptions(groupObj.categories, 'id', 'name');
  }

  render() {
    const locals = Object.keys(this.state.formData.name);
    const { include_in_targets } = this.state.formData;
    const {
      itemType,
      item: { can_move }
    } = this.props.productCategoriesContext;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Typography component='h6' variant='h6'>
          {t('Name')}
        </Typography>

        <Box className='language-form-box'>
          {locals.map(local => (
            <Input
              key={`name-${local}`}
              name={local}
              label={availableLocals.find(item => item.slug === local).name}
              value={this.state.formData.name[local]}
              required={local === 'pl'}
              onChange={this.onLangChange}
              errorStatus={this.state.validation[local]?.status}
              errorText={this.state.validation[local]?.message}
            />
          ))}
        </Box>
        {itemType !== TYPE_GROUP && (
          <Select
            name='group_id'
            label='Product group'
            value={this.state.formData.group_id}
            options={formatOptions(this.props.productCategories, 'id', 'name')}
            onChange={this.onSelectChange}
            required
            disabled={!can_move}
            tooltipMsg={t(
              'You cannot change the group when there are open sales targets for that group.'
            )}
            errorStatus={this.state.validation.group_id.status}
            errorText={this.state.validation.group_id.message}
          />
        )}

        {itemType === TYPE_SUBCATEGORY && (
          <Select
            name='category_id'
            label='Category'
            value={this.state.formData.category_id}
            options={this.formatProductsCategoriesOptions()}
            onChange={this.onSelectChange}
            required
            disabled={!can_move}
            tooltipMsg={t(
              'You cannot change the category when there are open sales targets for that category.'
            )}
            errorStatus={this.state.validation.category_id.status}
            errorText={this.state.validation.category_id.message}
          />
        )}
        {itemType === 'group' && (
          <Checkbox
            name='include_in_targets'
            label='Include the group when calculating goals'
            checked={include_in_targets}
            onChange={this.onCheckboxChange}
          />
        )}
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link to={ROUTE_PRODUCT_CATEGORIES_LIST} className='router-button'>
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={this.state.loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

ProductCategoriesUpdateForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  productCategoriesContext: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedCategoryId: PropTypes.number,
    item: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      names: PropTypes.shape({}).isRequired,
      group_id: PropTypes.number,
      category_id: PropTypes.number,
      can_move: PropTypes.bool.isRequired,
      include_in_targets: PropTypes.bool
    }).isRequired,
    itemType: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  productCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default withRouter(ProductCategoriesUpdateForm);
