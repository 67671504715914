import PropTypes from 'prop-types';
import { useState } from 'react';
import t from 'translate/translate';
import {
  FormControlLabel,
  Checkbox as MaterialUiCheckbox,
  Tooltip,
  Box
} from '@material-ui/core';

const Checkbox = props => {
  const {
    checked,
    onChange,
    name,
    color,
    label,
    style,
    labelStyle,
    disabled,
    tooltipMsg,
    useInternalState
  } = props;
  const [internalChecked, setInternalChecked] = useState(checked);

  const onClick = e => {
    setInternalChecked(e.target.checked);

    onChange(e);
  };

  const checkbox = (
    <FormControlLabel
      control={
        <MaterialUiCheckbox
          checked={useInternalState ? internalChecked : checked}
          onClick={onClick}
          name={name}
          color={color}
          style={style}
          disabled={disabled}
        />
      }
      label={t(label)}
      style={labelStyle}
    />
  );

  if (disabled && tooltipMsg) {
    return (
      <Tooltip title={t(tooltipMsg)}>
        <Box>{checkbox}</Box>
      </Tooltip>
    );
  }

  return checkbox;
};

Checkbox.defaultProps = {
  checked: false,
  color: 'primary',
  label: null,
  style: {},
  disabled: false,
  tooltipMsg: null,
  useInternalState: true
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  useInternalState: PropTypes.bool
};

export default Checkbox;
