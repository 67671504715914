import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import { Box, Typography } from '@material-ui/core';
import Accordion from 'modules/Layout/component/Accordion';
import { withStyles } from '@material-ui/core/styles';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Checkbox from 'modules/Layout/component/Checkbox';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import priorityOptions from 'modules/Products/priorityTypes';
import Input from 'modules/Layout/component/Input';
import CategoryPicker from 'modules/Layout/component/CategoryPicker';
import clsx from 'clsx';

const useStyles = theme => ({
  accordionDetailsBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    width: '100%'
  },
  accordionDetailsGroupWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsGroupChildrenWrapper: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  categoriesBoxWrapper: {
    '& .MuiAutocomplete-root': {
      [theme.breakpoints.up('md')]: {
        width: '250px',
        marginRight: theme.spacing(1.5)
      }
    }
  },
  accordionDetailsBox: {
    [theme.breakpoints.up('md')]: {
      flex: '0 1 250px',
      marginRight: theme.spacing(1.5)
    },
    display: 'flex',
    alignItems: 'center'
  }
});

export const getHasAllCategoriesStatus = user => {
  return Boolean(
    user.departments.find(department => department.has_all_categories)
  );
};

class ProductsFilter extends Component {
  static contextType = AuthContext;

  render() {
    const {
      filter,
      validation,
      onFilterChange,
      onAutocompleteChange,
      onCategoryPick,
      classes
    } = this.props;

    return (
      <Accordion label={t('Filter')}>
        <Box className={classes.accordionDetailsBoxWrapper}>
          {getHasAllCategoriesStatus(this.context.user) && (
            <Box className={classes.accordionDetailsGroupWrapper}>
              <Box className={classes.accordionDetailsGroupChildrenWrapper}>
                <Box className={classes.accordionDetailsBox}>
                  <Checkbox
                    onChange={onFilterChange}
                    checked={filter.my_products}
                    name='my_products'
                    label='My products'
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box className={classes.accordionDetailsGroupWrapper}>
            <Typography>{t('Identification')}</Typography>
            <Box className={classes.accordionDetailsGroupChildrenWrapper}>
              <Box className={classes.accordionDetailsBox}>
                {this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
                  <Autocomplete
                    label='Country'
                    name='country_id'
                    value={filter.country_id}
                    options={formatOptions(this.props.countries, 'id', 'name')}
                    onChange={(_, v) => onAutocompleteChange('country_id', v)}
                    errorStatus={validation.country_id?.status}
                    errorText={validation.country_id?.message}
                  />
                )}
              </Box>
              <Box className={classes.accordionDetailsBox}>
                <Autocomplete
                  label='Name'
                  name='sku'
                  value={filter.sku}
                  options={formatOptions(
                    this.props.filterProducts,
                    'sku',
                    'sku'
                  )}
                  onChange={(_, v) => onAutocompleteChange('sku', v)}
                  loading={this.props.loadingFilterProducts}
                  errorStatus={validation.sku?.status}
                  errorText={validation.sku?.message}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.accordionDetailsGroupWrapper}>
            <Typography>{t('Category')}</Typography>
            <Box
              className={clsx(
                classes.accordionDetailsGroupChildrenWrapper,
                classes.categoriesBoxWrapper
              )}
            >
              <CategoryPicker
                onChange={onCategoryPick}
                groupId={filter.group_id}
                categoryId={filter.category_id}
                subcategoryId={filter.subcategory_id}
              />
            </Box>
          </Box>
          <Box className={classes.accordionDetailsGroupWrapper}>
            <Typography>{t('Other')}</Typography>
            <Box className={classes.accordionDetailsGroupChildrenWrapper}>
              <Box className={classes.accordionDetailsBox}>
                <Input
                  name='batches_min'
                  label='Min batches'
                  type='number'
                  inputProps={{
                    min: 0
                  }}
                  value={filter.batches_min}
                  onChange={onFilterChange}
                  errorStatus={validation.batches_min?.status}
                  errorText={validation.batches_min?.message}
                />
              </Box>
              <Box className={classes.accordionDetailsBox}>
                <Input
                  name='batches_max'
                  label='Max batches'
                  type='number'
                  inputProps={{
                    min: 0
                  }}
                  value={filter.batches_max}
                  onChange={onFilterChange}
                  errorStatus={validation.batches_max?.status}
                  errorText={validation.batches_max?.message}
                />
              </Box>
              <Box className={classes.accordionDetailsBox}>
                <Select
                  name='priority'
                  label='Priority'
                  value={filter.priority}
                  options={formatOptions(priorityOptions, 'id', 'icon')}
                  onChange={onFilterChange}
                  errorStatus={validation.priority?.status}
                  errorText={validation.priority?.message}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.accordionDetailsGroupWrapper}>
            <Box className={classes.accordionDetailsGroupChildrenWrapper}>
              <Box className={classes.accordionDetailsBox}>
                <Checkbox
                  onChange={onFilterChange}
                  checked={filter.direct_order_only}
                  name='direct_order_only'
                  label='Products available only in direct orders'
                />
              </Box>
              <Box className={classes.accordionDetailsBox}>
                <Checkbox
                  onChange={onFilterChange}
                  checked={filter.promotions_blocked}
                  name='promotions_blocked'
                  label='Product unavailable in promotions'
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Accordion>
    );
  }
}

ProductsFilter.propTypes = {
  filter: PropTypes.shape({
    my_products: PropTypes.bool,
    sku: PropTypes.string,
    batches_min: PropTypes.string,
    batches_max: PropTypes.string,
    country_id: PropTypes.number,
    direct_order_only: PropTypes.bool,
    promotions_blocked: PropTypes.bool,
    priority: PropTypes.number,
    group_id: PropTypes.number,
    category_id: PropTypes.number,
    subcategory_id: PropTypes.number
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onAutocompleteChange: PropTypes.func.isRequired,
  onCategoryPick: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  filterProducts: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  validation: PropTypes.shape({
    sku: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string
    }),
    batches_min: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string
    }),
    batches_max: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string
    }),
    country_id: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string
    }),
    priority: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string
    })
  }).isRequired,
  classes: PropTypes.shape({
    accordionDetailsBoxWrapper: PropTypes.string.isRequired,
    accordionDetailsGroupWrapper: PropTypes.string.isRequired,
    accordionDetailsGroupChildrenWrapper: PropTypes.string.isRequired,
    categoriesBoxWrapper: PropTypes.string.isRequired,
    accordionDetailsBox: PropTypes.string.isRequired
  }).isRequired,
  loadingFilterProducts: PropTypes.bool.isRequired
};

export default withStyles(useStyles)(ProductsFilter);
