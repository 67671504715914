import { Route } from 'react-router-dom';
import {
  TARGET_LIST,
  TARGET_CREATE,
  TARGET_UPDATE
} from 'api/auth/permissions/Targets';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import TargetsList from 'modules/Targets/view/List';
import TargetsDetails from 'modules/Targets/view/Details';
import TargetsCreate from 'modules/Targets/view/Create';
import TargetsUpdate from 'modules/Targets/view/Update';

export const ROUTE_TARGETS_LIST = '/targets';
export const ROUTE_TARGETS_DETAILS = '/targets/details/:id';
export const ROUTE_TARGETS_CREATE = '/targets/create';
export const ROUTE_TARGETS_UPDATE = '/targets/update/:id';

export const permMap = {
  [ROUTE_TARGETS_LIST]: [TARGET_LIST],
  [ROUTE_TARGETS_DETAILS]: [TARGET_LIST],
  [ROUTE_TARGETS_CREATE]: [TARGET_CREATE],
  [ROUTE_TARGETS_UPDATE]: [TARGET_UPDATE]
};

const routes = {
  protected: [
    <Route key='targets-list' exact path={ROUTE_TARGETS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_TARGETS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <TargetsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='targets-details' exact path={ROUTE_TARGETS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_TARGETS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <TargetsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='targets-create' exact path={ROUTE_TARGETS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_TARGETS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <TargetsCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='targets-update' exact path={ROUTE_TARGETS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_TARGETS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <TargetsUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
