export const POSTAL_CODES_SHOW = 'postal_codes.show';
export const POSTAL_CODES_CREATE = 'postal_codes.create';
export const POSTAL_CODES_UPDATE = 'postal_codes.update';
export const POSTAL_CODES_DELETE = 'postal_codes.delete';

export const POSTAL_CODES_COUNTRIES_SHOW = 'postal_codes.countries.show';
export const POSTAL_CODES_COUNTRIES_CREATE = 'postal_codes.countries.create';
export const POSTAL_CODES_COUNTRIES_UPDATE = 'postal_codes.countries.update';
export const POSTAL_CODES_COUNTRIES_DELETE = 'postal_codes.countries.delete';

export const POSTAL_CODES_REGIONS_SHOW = 'postal_codes.regions.show';
