import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PostalCodesBox from 'modules/PostalCodes/component/shared/PostalCodesBox';
import { ListItem, ListItemText } from '@material-ui/core';
import { useStyles } from 'modules/PostalCodes/component/shared/style';
import { ADMIN } from 'api/auth/roles';
import {
  POSTAL_CODES_COUNTRIES_SHOW,
  POSTAL_CODES_SHOW
} from 'api/auth/permissions/PostalCodes';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import ApiError from 'api/exceptions/ValidationApiError';
import { PostalCodesContext } from 'modules/PostalCodes/context/PostalCodesProvider';
import authContext from 'modules/Auth/context/Auth/authContext';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { COUNTRY } from 'modules/PostalCodes/constants';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from 'modules/Layout/component/IconButton';
import {
  ROUTE_POSTAL_CODES_CREATE,
  ROUTE_POSTAL_CODES_UPDATE
} from 'routing/routes/PostalCodes';

const CountriesList = () => {
  const classes = useStyles();
  const { hasRole, user, hasPermission } = useContext(authContext);
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();

  const {
    selectedItem,
    handleListItemClick,
    handleCountryDepth,
    handleSetCountryName,
    handleChosenItem
  } = useContext(PostalCodesContext);

  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    if (hasPermission([POSTAL_CODES_SHOW])) {
      try {
        const {
          data: { data: countriesData }
        } = await PostalCodesApi.getCountries({
          per_page: Number.MAX_SAFE_INTEGER
        });
        setCountries(countriesData);
        if (hasRole([ADMIN])) {
          handleSetCountryName(countriesData, user.country_id);
          const [{ depth }] = countriesData.filter(
            country => country.id === user.country_id
          );
          handleCountryDepth(depth);
        }
      } catch (e) {
        if (e instanceof ApiError) {
          setAlert(e.getPayload().message);
        }
      }
    }
  };

  const addCountry = () => {
    handleChosenItem({ type: 'country' });
    history.push(ROUTE_POSTAL_CODES_CREATE);
  };

  const updateCountry = (e, id, name, language, currency_id) => {
    e.stopPropagation();
    handleChosenItem({ type: 'country', name, id, language, currency_id });
    history.push(ROUTE_POSTAL_CODES_UPDATE);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
      <PostalCodesBox
        title='Country'
        data={countries}
        addFn={addCountry}
        authorizeAddButton
      >
        {countries.map(({ name, id, depth, language, currency_id }) => (
          <ListItem
            onClick={() => {
              handleListItemClick({ id, type: COUNTRY });
              handleCountryDepth(depth);
            }}
            className={classes.listItem}
            selected={selectedItem[COUNTRY] === id}
            key={`${name}${id}`}
          >
            <ListItemText style={{ cursor: 'default' }} primary={name} />
            <IconButton
              className='update-icon'
              onClick={e => updateCountry(e, id, name, language, currency_id)}
              icon={<EditIcon fontSize='small' />}
              alt='update'
            />
          </ListItem>
        ))}
      </PostalCodesBox>
    )
  );
};

export default CountriesList;
