import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  formGroupWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(1.5)
  },
  accordionDetailsBox: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 1 250px',
      marginRight: theme.spacing(1.5)
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default useStyles;
