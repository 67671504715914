import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_TARGETS_LIST } from 'routing/routes/Targets';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import TargetsApi from 'api/connections/Targets/TargetsApi';
import TargetDataSets from 'modules/Targets/component/Details/DataSets';
import TargetResultsDataSets from 'modules/Targets/component/Details/ResultsDataSets';
import TargetBonusesDataSets from 'modules/Targets/component/Details/BonusesDataSets';

class TargetsDetails extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.targetId = parseInt(props.match.params.id, 10);
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      target: null
    };

    this.handleError = this.handleError.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    if (!this.targetId) {
      this.props.history.push(ROUTE_TARGETS_LIST);
    }
    this.setCurrentPage('Target details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_TARGETS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: target }
      } = await TargetsApi.getTarget(this.targetId);

      this.setState({ target });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { target } = this.state;

    if (!target) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <TargetDataSets
              data={target}
              handleError={this.handleError}
              fetchTarget={this.fetchData}
              setAlert={this.setAlert}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={12}>
              <Paper className='p1'>
                <TargetResultsDataSets data={target} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className='p1'>
                <TargetBonusesDataSets data={target} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

TargetsDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(TargetsDetails);
