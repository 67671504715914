import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  order: {
    backgroundColor: 'white',
    width: 20,
    textAlign: 'center',
    borderRadius: 5,
    transform: 'translate(-10px, -42px)'
  },
  infoWindow: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    minWidth: 300,
    transform: 'translate(20px, -50%)',
    display: ({ show }) => (show ? 'block' : 'none'),
    position: 'relative',
    zIndex: 100
  },
  paragraph: {
    fontSize: 12,
    marginLeft: 5
  },
  label: {
    fontSize: 12,
    fontWeight: 700
  }
}));

export default useStyles;
