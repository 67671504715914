import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import routeConfig from 'routing/routes';
import Guard from 'routing/Guard';
import Wrapper from 'modules/Layout/view/Wrapper';
import NotFound from 'modules/Layout/view/NotFound';

const segregateRoutes = routes => {
  return routes.reduce(
    (previousValue, currentValue) => ({
      public: [...previousValue.public, ...(currentValue.public ?? [])],
      protected: [...previousValue.protected, ...(currentValue.protected ?? [])]
    }),
    {
      public: [],
      protected: []
    }
  );
};

const routerCreator = () => {
  const routes = segregateRoutes(routeConfig);

  return (
    <Router>
      <Switch>
        {routes.public}
        <Route path='/404'>
          <NotFound />
        </Route>
        <Route path='/'>
          <Guard>
            <Wrapper>
              <Switch>
                {routes.protected}
                <Redirect to='/404' />
              </Switch>
            </Wrapper>
          </Guard>
        </Route>
        <Redirect to='/404' />
      </Switch>
    </Router>
  );
};

export default routerCreator;
