import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import renderAddress from 'modules/Clients/utils/renderAddress';

class SaleGroupClientsTable extends Component {
  static getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'tax_number',
        label: 'Tax number',
        sortable: true
      },
      {
        property: 'sales_group_name',
        label: 'Current shopping group'
      },
      {
        property: 'city',
        label: 'Address',
        sortable: false,
        render: (_, row) => renderAddress(row)
      }
    ];
  }

  render() {
    const { clients, sort, setSort, selected, onSelect } = this.props;

    return (
      <Table
        cols={SaleGroupClientsTable.getCols()}
        rows={clients}
        sort={sort}
        setSort={setSort}
        selected={selected}
        onSelect={onSelect}
      />
    );
  }
}

SaleGroupClientsTable.defaultProps = {
  clients: []
};

SaleGroupClientsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default withRouter(SaleGroupClientsTable);
