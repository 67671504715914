import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import Loader from 'modules/Layout/component/Loader';
import t from 'translate/translate';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ProductsApi from 'api/connections/Products/ProductsApi';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { addParentsIdsToCategoriesObj } from 'modules/ProductCategories/utils/formater';

import ProductsTree from 'modules/Products/component/ProductsTree';

class ProductManagerProducts extends Component {
  static contextType = AlertContext;

  static formatSelected(data) {
    return {
      groups: data.groups.map(g => ({ ...g, type: 'group' })),
      categories: data.categories.map(c => ({ ...c, type: 'category' })),
      subcategories: data.subcategories.map(s => ({
        ...s,
        type: 'subcategory'
      })),
      products: data.products.map(p => ({ ...p, type: 'product' }))
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      categoriesObj: null,
      selected: null
    };

    this.fetchProductsAssignToUser = this.fetchProductsAssignToUser.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.fetchProductsAssignToUser();
  }

  async onSubmit(data) {
    const { groups, categories, subcategories, products } = data;

    const payload = {
      groups: groups.map(i => i.id),
      categories: categories.map(i => i.id),
      subcategories: subcategories.map(i => i.id),
      products: products.map(i => i.id)
    };

    try {
      await ProductsApi.assignProductsToUser(this.props.userId, payload);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    try {
      const [
        {
          data: { data: selectedData }
        },
        { data: categoriesObj }
      ] = await Promise.all([
        ProductsApi.getProductsAssignToUser(this.props.userId),
        ProductCategoriesApi.getProductCategories()
      ]);

      this.setState({
        categoriesObj: addParentsIdsToCategoriesObj(categoriesObj),
        selected: ProductManagerProducts.formatSelected(selectedData)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchProductsAssignToUser() {
    try {
      const {
        data: { data: selectedData }
      } = await ProductsApi.getProductsAssignToUser(this.props.userId);

      this.setState({
        selected: ProductManagerProducts.formatSelected(selectedData)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { categoriesObj, selected } = this.state;

    if (!categoriesObj) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} md={7}>
          <Paper className='p1'>
            <ProductsTree
              categoriesObj={categoriesObj}
              selected={selected}
              handleError={this.handleError}
              onSubmit={this.onSubmit}
              updateActionDisableStatus={this.props.updateActionDisableStatus}
              updateActionDisableMsg={this.props.updateActionDisableMsg}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ProductManagerProducts.defaultProps = {
  updateActionDisableMsg: null
};

ProductManagerProducts.propTypes = {
  setAlert: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  updateActionDisableStatus: PropTypes.bool.isRequired,
  updateActionDisableMsg: PropTypes.string
};

export default ProductManagerProducts;
