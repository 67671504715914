import PropTypes from 'prop-types';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Loader from 'modules/Layout/component/Loader';
import { isEmpty } from 'lodash/lang';
import { Typography } from '@material-ui/core';
import capitalize from 'modules/Shared/utils/capitalize';
import t from 'translate/translate';

const ClientsTreeUnrelated = props => {
  const {
    unrelated,
    selectedClients,
    selectMode,
    isSelected: propsIsSelected,
    onSelect,
    renderDeleteItemBtn
  } = props;

  if (unrelated === undefined) return <Loader />;

  const unrelatedClientAssignStatus = selectedClients.some(clientId =>
    unrelated.some(city => city.clients.some(client => client.id === clientId))
  );

  if (!unrelatedClientAssignStatus && !selectMode) {
    return (
      <Typography>
        {t('No unrelated clients assigned to user found')}
      </Typography>
    );
  }

  if (isEmpty(unrelated)) {
    return <Typography>{t('No unrelated clients found')}</Typography>;
  }

  const unrelatedClientsCount = unrelated.reduce((acc, city) => {
    return (
      acc + (selectMode ? city.clients_number : city.assigned_clients_number)
    );
  }, 0);

  return (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      disableSelection
    >
      <TreeItem
        key='unrelated'
        nodeId='unrelated'
        labelText={t('Unrelated')}
        labelInfo={unrelatedClientsCount}
      >
        {unrelated.map(city => {
          const cityClientIds = city.clients.map(c => c.id);

          const isVisible = selectedClients.some(clientId =>
            cityClientIds.includes(clientId)
          );

          if (!isVisible && !selectMode) return null;

          return (
            <TreeItem
              key={city.name}
              nodeId={city.name}
              labelText={capitalize(city.name)}
              labelInfo={
                selectMode ? city.clients_number : city.assigned_clients_number
              }
            >
              {city.clients.map(client => {
                const isSelected = propsIsSelected('clients', client.id);

                if (!isSelected && !selectMode) return null;

                const item = {
                  postalCodeLevel: 'clients',
                  ...client
                };

                return (
                  <TreeItem
                    itemData={item}
                    key={client.id}
                    nodeId={client.id.toString()}
                    labelText={capitalize(client.name)}
                    selectMode={selectMode}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    actionBtn={
                      !selectMode && isSelected && renderDeleteItemBtn(item)
                    }
                  />
                );
              })}
            </TreeItem>
          );
        })}
      </TreeItem>
    </TreeView>
  );
};

ClientsTreeUnrelated.defaultProps = {
  unrelated: undefined,
  selectMode: false
};

ClientsTreeUnrelated.propTypes = {
  unrelated: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  selectedClients: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  selectMode: PropTypes.bool,
  isSelected: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  renderDeleteItemBtn: PropTypes.func.isRequired
};

export default ClientsTreeUnrelated;
