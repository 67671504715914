export const CLOSE = 0;
export const OPEN = 1;
export const SUSPENDED = 2;

export const clientBusinessStatuses = {
  [CLOSE]: 'close',
  [OPEN]: 'open',
  [SUSPENDED]: 'suspended'
};

export const clientBusinessStatusesArr = [
  { value: CLOSE, status: 'close' },
  { value: OPEN, status: 'open' },
  { value: SUSPENDED, status: 'suspended' }
];
