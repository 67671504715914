import KnowledgeBaseView from 'modules/KnowledgeBase/view';
import { Route } from 'react-router-dom';
import { KNOWLEDGE_SHOW } from 'api/auth/permissions/KnowledgeBase';
import Authorize from 'modules/Auth/component/Authorize';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';

export const ROUTE_KNOWLEDGE_BASE = '/knowledge_base';

export const permMap = {
  [ROUTE_KNOWLEDGE_BASE]: [KNOWLEDGE_SHOW]
};

const routes = {
  protected: [
    <Route key='knowledge-base' exact path={ROUTE_KNOWLEDGE_BASE}>
      <Authorize redirect permissions={permMap[ROUTE_KNOWLEDGE_BASE]}>
        <BasicContextConsumerWrapper
          component={fn => <KnowledgeBaseView contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
