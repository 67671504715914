import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';

import { ROUTE_SALES_IMPORTS_LIST } from 'routing/routes/SalesImports';
import { ROUTE_WHOLESALERS_LIST } from 'routing/routes/Wholesalers';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import SalesImportsApi from 'api/connections/SalesImports/SalesImportsApi';

import SalesImportDataSets from 'modules/SalesImports/component/Details/DataSets';
import SalesImportNewClientsList from 'modules/SalesImports/component/Details/NewClients';
import SalesImportNewProductsList from 'modules/SalesImports/component/Details/NewProducts';
import CancelImportDialog from 'modules/SalesImports/component/CancelImportDialog';
import SetWholesalerDialog from 'modules/SalesImports/component/SetWholesalerDialog';
import MapProductDialog from 'modules/SalesImports/component/MapProductDialog';
import MapClientDialog from 'modules/SalesImports/component/MapClientDialog';
import ClientsApi from 'api/connections/Clients/ClientsApi';

class SalesImportsDetails extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.salesImportId = parseInt(props.match.params.id, 10);
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      salesImport: null,
      cancelDialogOpen: false,
      mapProductDialogOpen: false,
      mapClientDialogOpen: false,
      setWholesalerDialogOpen: false,
      temporaryItem: null,
      clientsData: []
    };

    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSetWholesalerClick = this.onSetWholesalerClick.bind(this);
    this.onMapProductClick = this.onMapProductClick.bind(this);
    this.onMapClientClick = this.onMapClientClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSetWholesalerSuccess = this.onSetWholesalerSuccess.bind(this);
  }

  componentDidMount() {
    if (!this.salesImportId) {
      this.props.history.push(ROUTE_SALES_IMPORTS_LIST);
    }
    this.setCurrentPage('Sales import details');
    this.fetchData();
  }

  onCancelClick() {
    this.setState({ cancelDialogOpen: true });
  }

  onSetWholesalerClick() {
    this.setState({ setWholesalerDialogOpen: true });
  }

  onSetWholesalerSuccess() {
    this.props.history.push(ROUTE_WHOLESALERS_LIST);
  }

  onMapProductClick(item) {
    this.setState({ mapProductDialogOpen: true, temporaryItem: item });
  }

  onMapClientClick(item) {
    if (isEmpty(this.state.clientsData)) {
      this.fetchClients();
    }
    this.setState({ mapClientDialogOpen: true, temporaryItem: item });
  }

  onDialogClose() {
    this.setState({
      cancelDialogOpen: false,
      setWholesalerDialogOpen: false,
      mapProductDialogOpen: false,
      mapClientDialogOpen: false,
      temporaryItem: null
    });
  }

  formatMappingData() {
    const {
      product_mapping,
      product_name,
      client_mapping,
      client_tax_number
    } = this.state.salesImport.errors;

    const productData = [
      ...product_mapping.map(m => ({ ...m, error_type: 'mapping missing' })),
      ...product_name.map(m => ({ ...m, error_type: 'wrong name' }))
    ];

    const clientData = [
      ...client_mapping.map(m => ({ ...m, error_type: 'mapping missing' })),
      ...client_tax_number.map(m => ({ ...m, error_type: 'wrong tax number' }))
    ];

    return { productData, clientData };
  }

  async fetchClients() {
    try {
      const {
        data: { data: clientsData }
      } = await ClientsApi.getAllClients({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({ clientsData });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: salesImport }
      } = await SalesImportsApi.getSalesImport(this.salesImportId);

      this.setState({ salesImport });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_SALES_IMPORTS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const {
      salesImport,
      cancelDialogOpen,
      setWholesalerDialogOpen,
      mapProductDialogOpen,
      mapClientDialogOpen,
      temporaryItem,
      clientsData
    } = this.state;

    if (!salesImport) return <Loader />;

    const { productData, clientData } = this.formatMappingData();

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <SalesImportDataSets
              data={salesImport}
              onCancelClick={this.onCancelClick}
              onSetWholesalerClick={this.onSetWholesalerClick}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={12}>
              <Paper className='p1'>
                <SalesImportNewClientsList
                  data={clientData}
                  onMapClientClick={this.onMapClientClick}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className='p1'>
                <SalesImportNewProductsList
                  data={productData}
                  onMapProductClick={this.onMapProductClick}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {cancelDialogOpen && (
          <CancelImportDialog
            salesImport={salesImport}
            onSuccess={this.fetchData}
            onClose={this.onDialogClose}
          />
        )}
        {setWholesalerDialogOpen && (
          <SetWholesalerDialog
            salesImport={salesImport}
            onSuccess={this.onSetWholesalerSuccess}
            onClose={this.onDialogClose}
          />
        )}
        {mapProductDialogOpen && (
          <MapProductDialog
            importId={salesImport.id}
            temporaryProduct={temporaryItem}
            onSuccess={this.fetchData}
            onClose={this.onDialogClose}
          />
        )}
        {mapClientDialogOpen && (
          <MapClientDialog
            importId={salesImport.id}
            temporaryClient={temporaryItem}
            clients={clientsData}
            onSuccess={this.fetchData}
            onClose={this.onDialogClose}
          />
        )}
      </Grid>
    );
  }
}

SalesImportsDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(SalesImportsDetails);
