import { Route } from 'react-router-dom';
import Dashboard from 'modules/Dashboard/view';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';

export const ROUTE_DASHBOARD = '/';

const routes = {
  protected: [
    <Route key='dashboard' exact path={ROUTE_DASHBOARD}>
      <BasicContextConsumerWrapper
        component={fn => <Dashboard contextMethods={fn} />}
      />
    </Route>
  ]
};

export default routes;
