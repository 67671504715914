import Filter from 'modules/Layout/component/List/Filter';
import { ADMIN, SUPER_ADMIN, DIRECTOR } from 'api/auth/roles';
import { formatOptions } from 'modules/Layout/component/Select';
import { getHasAllCategoriesStatus } from 'modules/Products/component/List/Filter';

class ClientDetailsNewProductsFilter extends Filter {
  getInputs() {
    return [
      {
        children: [
          {
            ...this.getBasicInputValues('sku'),
            label: 'Name',
            type: 'autocomplete',
            emptyValueText: 'All products',
            options: formatOptions(this.props.allProducts ?? [], 'sku', 'sku'),
            loading: !this.props.allProducts
          },
          {
            ...this.getBasicInputValues('my_products'),
            label: 'My products',
            type: 'checkbox',
            displayStatus:
              !this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) &&
              !getHasAllCategoriesStatus(this.context.user)
          }
        ]
      }
    ];
  }
}

export default ClientDetailsNewProductsFilter;
