import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PostalCodesBox from 'modules/PostalCodes/component/shared/PostalCodesBox';
import { ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import IconButton from 'modules/Layout/component/IconButton';
import Dialog from 'modules/Layout/component/Dialog';
import { useStyles } from 'modules/PostalCodes/component/shared/style';
import { ADMIN } from 'api/auth/roles';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import ApiError from 'api/exceptions/ValidationApiError';
import { PostalCodesContext } from 'modules/PostalCodes/context/PostalCodesProvider';
import authContext from 'modules/Auth/context/Auth/authContext';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import t from 'translate/translate';
import { COUNTRY, REGION } from 'modules/PostalCodes/constants';
import {
  ROUTE_POSTAL_CODES_CREATE,
  ROUTE_POSTAL_CODES_UPDATE
} from 'routing/routes/PostalCodes';
import EditIcon from '@material-ui/icons/Edit';

const RegionsList = () => {
  const classes = useStyles();
  const { hasRole, user } = useContext(authContext);

  const {
    selectedItem,
    handleListItemClick,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    dialogData,
    handleChosenItem
  } = useContext(PostalCodesContext);
  const { setAlert } = useContext(AlertContext);

  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRegions = async id => {
    try {
      setIsLoading(true);
      const {
        data: { data: regionsData }
      } = await PostalCodesApi.getRegions({
        country_id: id,
        per_page: Number.MAX_SAFE_INTEGER
      });
      setRegions(regionsData);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const removeItem = async () => {
    try {
      const { id } = dialogData;
      await PostalCodesApi.deleteRegion(id);
      setRegions(prevState => prevState.filter(region => region.id !== id));
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const addRegion = () => {
    handleChosenItem({ type: 'region' });
    history.push(ROUTE_POSTAL_CODES_CREATE);
  };

  const updateRegion = (e, id, name) => {
    e.stopPropagation();
    handleChosenItem({ type: 'region', name, id, parentType: 'country' });
    history.push(ROUTE_POSTAL_CODES_UPDATE);
  };

  useEffect(() => {
    if (hasRole([ADMIN])) {
      fetchRegions(user.country_id);
    }
    if (selectedItem[COUNTRY]) {
      fetchRegions(selectedItem[COUNTRY]);
    }
  }, [selectedItem[COUNTRY]]);

  return (
    (selectedItem[COUNTRY] || user.country_id) &&
    (isLoading ? (
      <CircularProgress style={{ marginLeft: 30 }} />
    ) : (
      <>
        <PostalCodesBox title='Region' data={regions} addFn={addRegion}>
          {regions.map(({ name, id, cities_count }) => (
            <ListItem
              onClick={async () => {
                handleListItemClick({ id, type: REGION });
              }}
              className={classes.listItem}
              selected={selectedItem[REGION] === id}
              key={`${name}${id}`}
            >
              <ListItemText
                style={{ cursor: 'default', marginRight: 20 }}
                primary={name}
              />
              <IconButton
                className='update-icon'
                onClick={e => updateRegion(e, id, name)}
                icon={<EditIcon fontSize='small' />}
                alt='update'
              />
              <IconButton
                className='delete-icon'
                onClick={e => handleOpenDialog(id, name, e, REGION)}
                icon={<DeleteForeverIcon />}
                tooltipMsg='Delete all the cities of this region, first.'
                disabled={cities_count > 0}
                alt='delete'
              />
            </ListItem>
          ))}
        </PostalCodesBox>
        <Dialog
          open={openDialog[REGION]}
          title={t('Deletion confirmation')}
          description={`${t('Are you sure you want to delete region: ')}${
            dialogData.name
          }?`}
          onApprove={async () => {
            await removeItem();
            handleCloseDialog();
          }}
          onCancel={handleCloseDialog}
        />
      </>
    ))
  );
};

export default RegionsList;
