import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { clientBonusStatusesArr } from 'modules/ClientsBonuses/clientBonusStatuses';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class ClientsContractsBonusesFilter extends Filter {
  static contextType = AuthContext;

  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('target_name'),
            label: 'Contract name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('client_name'),
            label: 'Client name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('status'),
            label: 'Bonus status',
            type: 'select',
            emptyValueText: 'All statuses',
            options: formatOptions(clientBonusStatusesArr, 'key', 'name', true)
          }
        ]
      }
    ];
  }
}

export default ClientsContractsBonusesFilter;
