import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import { ROUTE_SHOPPING_GROUPS_LIST } from 'routing/routes/ShoppingGroups';
import SalesGroupFileCreateForm from 'modules/SalesGroups/component/Files/Create/Form';
import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';

class SalesGroupFileCreate extends Component {
  static contextType = CurrentPageContext;

  constructor(props) {
    super(props);
    this.groupId = parseInt(this.props.match.params.id, 10);
  }

  async componentDidMount() {
    if (!this.groupId) {
      this.props.history.push(ROUTE_SHOPPING_GROUPS_LIST);
    }
    this.context.setCurrentPage('Create shopping group file');
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <SalesGroupFileCreateForm />
          </Paper>
        </Box>
      </Box>
    );
  }
}

SalesGroupFileCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(SalesGroupFileCreate);
