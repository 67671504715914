import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import { DISCOUNTS_CREATE } from 'api/auth/permissions/Discounts';
import { ROUTE_DISCOUNTS_CREATE } from 'routing/routes/Discounts';
import { downloadFile } from 'modules/Shared/utils/file';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DiscountsApi from 'api/connections/Discounts/DiscountsApi';
import DiscountsTable from 'modules/Discounts/component/List/Table';
import DiscountsFilter from 'modules/Discounts/component/List/Filter';
import DiscountsDeleteDialog from 'modules/Discounts/component/DeleteDialog';

class DiscountsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      filter: {},
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      discounts: [],
      loading: true,
      deleteDialogStatus: false,
      discountToDelete: null
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchDiscounts = this.fetchDiscounts.bind(this);
    this.exportDiscounts = this.exportDiscounts.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Discounts');
    this.fetchDiscounts();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  onCreateClick() {
    this.props.history.push(ROUTE_DISCOUNTS_CREATE);
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchDiscounts()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchDiscounts()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchDiscounts()
    );
  }

  closeDeleteDialog() {
    this.setState({
      deleteDialogStatus: false,
      discountToDelete: null
    });
  }

  openDeleteDialog(discount) {
    this.setState({
      deleteDialogStatus: true,
      discountToDelete: discount
    });
  }

  async fetchDiscounts() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: discounts, meta }
      } = await DiscountsApi.getDiscounts({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        discounts,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportDiscounts() {
    try {
      const { filter, sort } = this.state;

      const res = await DiscountsApi.exportDiscounts({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          <Authorize permissions={[DISCOUNTS_CREATE]}>
            <IconButton
              className='create-icon'
              onClick={this.onCreateClick}
              icon={<AddIcon fontSize='large' />}
              alt='create'
            />
          </Authorize>
          <IconButton
            onClick={this.exportDiscounts}
            icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
            alt='export table'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const {
      filter,
      sort,
      discounts,
      loading,
      filterValidation,
      deleteDialogStatus,
      discountToDelete
    } = this.state;

    return (
      <>
        <DiscountsFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderActions()}
        <DiscountsTable
          sort={sort}
          setSort={this.setSort}
          discounts={discounts}
          loading={loading}
          onDeleteClick={this.openDeleteDialog}
        />
        {this.renderActions()}
        {deleteDialogStatus && (
          <DiscountsDeleteDialog
            onClose={this.closeDeleteDialog}
            discount={discountToDelete}
            onSuccess={this.fetchDiscounts}
          />
        )}
      </>
    );
  }
}

DiscountsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(DiscountsList);
