import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import {
  DEPARTMENTS_DELETE,
  DEPARTMENTS_UPDATE
} from 'api/auth/permissions/Departments';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { ROUTE_DEPARTMENTS_UPDATE } from 'routing/routes/Departments';
import CheckIcon from '@material-ui/icons/Check';
import insertPathParams from 'api/utils/insertPathParams';

const DepartmentDataSet = props => {
  const {
    department: {
      id,
      name,
      country_name,
      product_groups,
      categories,
      users_count,
      has_all_categories
    },
    categoriesObj,
    onDeleteClick
  } = props;

  const history = useHistory();

  const onUpdateClick = () => {
    history.push(insertPathParams(ROUTE_DEPARTMENTS_UPDATE, { id }));
  };

  const renderCategories = () => {
    return categoriesObj.categories
      .filter(item => categories.includes(item.id))
      .map(item => item.name)
      .join(', ');
  };

  const renderProductGroups = () => {
    return categoriesObj.productGroups
      .filter(item => product_groups.includes(item.id))
      .map(item => item.name)
      .join(', ');
  };

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box>
        <TextDataSet label='Name' data={name} />
        <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
          <TextDataSet label='Country' data={country_name} />
        </Authorize>
        {has_all_categories && (
          <TextDataSet
            data={<CheckIcon color='primary' />}
            label='Department assigned to all categories'
          />
        )}
        {!has_all_categories && (
          <>
            <TextDataSet label='Product groups' data={renderProductGroups()} />
            <TextDataSet label='Categories' data={renderCategories()} />
          </>
        )}
      </Box>
      <Box display='flex' alignItems='flex-start'>
        <Authorize permissions={[DEPARTMENTS_UPDATE]}>
          <IconButton
            className='update-icon'
            onClick={onUpdateClick}
            icon={<EditIcon />}
            alt='update'
          />
        </Authorize>
        <Authorize permissions={[DEPARTMENTS_DELETE]}>
          <IconButton
            className='delete-icon'
            onClick={onDeleteClick}
            icon={<DeleteForeverIcon />}
            alt='delete'
            disabled={users_count > 0}
            tooltipMsg='The department in which the users are located cannot be deleted'
          />
        </Authorize>
      </Box>
    </Box>
  );
};

DepartmentDataSet.propTypes = {
  department: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    country_name: PropTypes.string.isRequired,
    product_groups: PropTypes.arrayOf(PropTypes.number).isRequired,
    categories: PropTypes.arrayOf(PropTypes.number).isRequired,
    users_count: PropTypes.number.isRequired,
    has_all_categories: PropTypes.bool.isRequired
  }).isRequired,
  categoriesObj: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    productGroups: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default DepartmentDataSet;
