import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { validate } from 'modules/Shared/utils/validator';
import t from 'translate/translate';
import { Typography, Box } from '@material-ui/core';
import AuthApi from 'api/connections/Auth/AuthApi';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const Form = ({ setStatus }) => {
  const { setAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: ''
  });
  const [validation, setValidation] = useState({
    email: {
      status: false,
      message: t(
        'Pole <%= field %> is required, and should be in the correct format',
        { field: t('Email') }
      )
    }
  });

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidation({
      ...validation,
      [e.target.name]: { ...validation[e.target.name], status: false }
    });
  };

  const handleResponse = res => {
    if (res.status === 200) {
      setStatus(true);
    } else {
      setAlert(res.getPayload().message);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const [isValid, newValidateState] = validate(formData, validation);
    if (!isValid) {
      setValidation(newValidateState);
    } else {
      setLoading(true);
      const res = await AuthApi.resetPassword({ ...formData });
      setLoading(false);
      handleResponse(res);
    }
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Box my={4}>
        <Typography component='h1' variant='h5'>
          {t('If You would like to change password, insert Your email below')}
        </Typography>
      </Box>
      <Input
        name='email'
        label='Email'
        type='email'
        required
        value={formData.email}
        onChange={onChange}
        errorStatus={validation.email.status}
        errorText={validation.email.message}
      />
      <Button
        type='submit'
        fullWidth
        text={t('Send')}
        loading={loading}
        color='primary'
      />
    </form>
  );
};

Form.propTypes = {
  setStatus: PropTypes.func.isRequired
};

export default Form;
