import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import isPast from 'date-fns/isPast';
import endOfDay from 'date-fns/endOfDay';
import isSameDay from 'date-fns/isSameDay';
import parse from 'date-fns/parse';
import addDays from 'date-fns/addDays';

const API_CALL_FORMAT = 'dd.MM.yyyy';

export default (dateStart, dateEnd, month, visits) => {
  const numberOfDays = differenceInCalendarDays(dateEnd, dateStart);
  const days = [];
  let day = dateStart;

  for (let i = 0; i <= numberOfDays; i += 1) {
    days.push({
      id: Math.random().toString(36).substr(2, 9),
      date: getDate(day),
      fullDate: day,
      disabledDay: getMonth(day) !== month,
      disabledCreating: isPast(endOfDay(day)),
      // eslint-disable-next-line no-loop-func
      visits: visits.filter(visit =>
        isSameDay(
          parse(visit.scheduled_at.split(' ')[0], API_CALL_FORMAT, new Date()),
          day
        )
      )
    });
    day = addDays(day, 1);
  }

  return days;
};
