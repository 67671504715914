import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import t, { availableLocals } from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { ROUTE_POSTAL_CODES_LIST } from 'routing/routes/PostalCodes';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { PostalCodesContext } from 'modules/PostalCodes/context/PostalCodesProvider';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import { COUNTRY } from 'modules/PostalCodes/constants';
import useTypes from 'modules/PostalCodes/hooks/useTypes';
import CurrenciesApi from 'api/connections/Currencies/CurrenciesApi';

const CreatePostalCodesForm = ({ item }) => {
  const history = useHistory();
  const types = useTypes();

  const { selectedItem } = useContext(PostalCodesContext);
  const { setAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    type: ''
  });
  const [currenciesData, setCurrenciesData] = useState(undefined);

  const [validation, setValidation] = useState({
    name: {
      status: false,
      message: t('Field <%= field %> is required', { field: t('Name') })
    },
    code: {
      status: false,
      message: t('Field <%= field %> is required', { field: t('Postal code') })
    },
    depth: {
      status: false,
      message: t('Field <%= field %> is required', {
        field: t('Structure depth')
      })
    },
    language: {
      status: false,
      message: t('Field <%= field %> is required', {
        field: t('Default language')
      })
    },
    currency_id: {
      status: false,
      message: t('Field <%= field %> is required', {
        field: t('Currency')
      })
    }
  });

  const structureDepths = [2, 1, 0];

  const onChange = e => {
    setFormData(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      };
    });

    setValidation({
      ...validation,
      [e.target.name]: { ...validation[e.target.name], status: false }
    });
  };

  const runValidation = () => {
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      setValidation(newValidateState);
    }

    return isValid;
  };

  const handleResponse = () => {
    setAlert({ value: 'Success', variant: COLOR_VARIANTS_SUCCESS });
    history.push(ROUTE_POSTAL_CODES_LIST);
  };

  const handleError = err => {
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      setValidation({ ...validation, ...newValidateState });
      setAlert(message);
    } else if (err instanceof ApiError) {
      setAlert(message);
    }
  };

  const makeApiCall = async () => {
    setLoading(true);

    const preparedParams = {};
    const [{ path }] = types.filter(
      type => type.name.toLowerCase() === item.type
    );
    const params = Object.entries(selectedItem)
      .map(([key, value]) => value !== null && { [`${key}_id`]: value })
      .filter(arrItem => arrItem);
    params.forEach(obj => {
      const [key] = Object.keys(obj);
      preparedParams[key] = obj[key];
    });
    try {
      await PostalCodesApi.createItem(path, {
        ...formData,
        ...preparedParams
      });
      handleResponse();
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const {
        data: { data: currencies }
      } = await CurrenciesApi.getCurrencies({
        per_page: Number.MAX_SAFE_INTEGER
      });

      setCurrenciesData(currencies);
    } catch (err) {
      handleError(err);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const isValid = runValidation();
    if (isValid) await makeApiCall();
  };

  useEffect(() => {
    if (item.type === COUNTRY) {
      return fetchCurrencies();
    }
  }, []);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Typography variant='h5' component='h5'>
        {t(`Adding a ${item.type}`)}
      </Typography>
      {item.type === 'postal code' ? (
        <Input
          name='code'
          label='Postal code'
          value={formData.code}
          required
          onChange={onChange}
          errorStatus={validation.code.status}
          errorText={validation.code.message}
        />
      ) : (
        <Input
          name='name'
          label='Name'
          value={formData.name}
          required
          onChange={onChange}
          errorStatus={validation.name.status}
          errorText={validation.name.message}
        />
      )}
      {item.type === COUNTRY && (
        <>
          <Select
            name='depth'
            label='Structure depth'
            value={formData.depth}
            required
            options={structureDepths}
            onChange={onChange}
            errorStatus={validation.depth.status}
            errorText={validation.depth.message}
          />
          <Select
            name='language'
            label='Default language'
            value={formData.language}
            options={formatOptions(availableLocals, 'slug', 'name')}
            onChange={onChange}
            errorStatus={validation.language.status}
            errorText={validation.language.message}
          />
          <Select
            name='currency_id'
            label='Currency'
            value={formData.currency_id}
            required
            options={formatOptions(currenciesData ?? [], 'id', 'name')}
            onChange={onChange}
            errorStatus={validation.currency_id.status}
            errorText={validation.currency_id.message}
          />
        </>
      )}

      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
        width={1}
      >
        <Box>
          <Link to={ROUTE_POSTAL_CODES_LIST} className='router-button'>
            <Button text={t('Cancel')} />
          </Link>
        </Box>
        <Box>
          <Button
            type='submit'
            fullWidth
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </Box>
      </Box>
    </form>
  );
};

CreatePostalCodesForm.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

export default CreatePostalCodesForm;
