import BaseApiConfig from 'api/connections/BaseApiConfig';

class ClientsUsersApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.clientsUsersPath = '/clients/users';
    this.codesPath = '/clients/users/codes';
    this.clientsUsersRegionsPath = '/clients/users/regions';
    this.clientsUsersSubregionsPath = '/clients/users/subregions';
    this.clientsUsersSubsubregionsPath = '/clients/users/subsubregions';
    this.clientsUsersCitiesPath = '/clients/users/cities';
    this.clientsUsersPostalCodesPath = '/clients/users/postal-codes';
    this.clientsUsersClientsPath = '/clients/users/clients';
    this.unkonwClientsPath = '/clients/users/unknown-clients';
  }

  getClientsFromRegions(pathParams) {
    return this.get(this.clientsUsersRegionsPath, pathParams);
  }

  getClientsFromSubregions(pathParams) {
    return this.get(this.clientsUsersSubregionsPath, pathParams);
  }

  getClientsFromSubsubregions(pathParams) {
    return this.get(this.clientsUsersSubsubregionsPath, pathParams);
  }

  getClientsFromCities(pathParams) {
    return this.get(this.clientsUsersCitiesPath, pathParams);
  }

  getClientsFromPostalCodes(pathParams) {
    return this.get(this.clientsUsersPostalCodesPath, pathParams);
  }

  getClientsFromClients(pathParams) {
    return this.get(this.clientsUsersClientsPath, pathParams);
  }

  getClientsAssignToUser(pathParams) {
    return this.get(this.codesPath, pathParams);
  }

  getUnknownClients(pathParams) {
    return this.get(this.unkonwClientsPath, pathParams);
  }

  assignManyClientsToUserByCodes(payload) {
    return this.post(this.codesPath, payload);
  }

  assignClientsToUser(payload) {
    return this.post(this.clientsUsersPath, payload);
  }

  deleteClientsFromUser(payload) {
    const options = {
      data: payload
    };
    return this.delete(this.codesPath, options);
  }
}

export default new ClientsUsersApi();
