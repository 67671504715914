import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import WholesalersAgenciesApi from 'api/connections/Wholesalers/WholesalersAgenciesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const WholesalerAgencyDeleteDialog = props => {
  const {
    wholesalerId,
    wholesalerName,
    agency: { id: agencyId, name: agencyName },
    onClose,
    onSuccess
  } = props;

  const { setAlert } = useContext(AlertContext);

  const deleteAgency = async () => {
    try {
      await WholesalersAgenciesApi.deleteWholesalerAgency({
        wholesalerId,
        agencyId
      });

      setAlert({
        value: t('Agency deleted'),
        variant: COLOR_VARIANTS_SUCCESS
      });

      onClose();
      onSuccess();
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete agency <%=agencyName%> for wholesaler <%=wholesalerName%>',
        {
          agencyName,
          wholesalerName
        }
      )}
      onApprove={deleteAgency}
      onCancel={onClose}
    />
  );
};

WholesalerAgencyDeleteDialog.propTypes = {
  wholesalerId: PropTypes.number.isRequired,
  wholesalerName: PropTypes.string.isRequired,
  agency: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.number.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default WholesalerAgencyDeleteDialog;
