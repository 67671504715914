import { Route } from 'react-router-dom';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';
import { CLIENTS_LIST } from 'api/auth/permissions/Clients';

import ClientsBonusesList from 'modules/ClientsBonuses/view/List';
import ClientsBonusesDetails from 'modules/ClientsBonuses/view/Details';

export const ROUTE_CLIENTS_BONUSES_LIST = '/clients_bonuses';
export const ROUTE_CLIENTS_BONUSES_DETAILS = '/clients_bonuses/details/:id';

export const permMap = {
  [ROUTE_CLIENTS_BONUSES_LIST]: [CLIENTS_LIST],
  [ROUTE_CLIENTS_BONUSES_DETAILS]: [CLIENTS_LIST]
};

const routes = {
  protected: [
    <Route key='clients-bonuses-list' exact path={ROUTE_CLIENTS_BONUSES_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_BONUSES_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientsBonusesList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-bonuses-details'
      exact
      path={ROUTE_CLIENTS_BONUSES_DETAILS}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_BONUSES_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientsBonusesDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
