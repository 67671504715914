import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import { VALUABLE_TARGET_TYPE } from 'modules/Targets/targetTypes';
import { renderEstimationPercent } from 'modules/Targets/component/List/Table';
import renderPrice from 'modules/Products/utils/renderPrice';

const TargetResultsDataSets = props => {
  const {
    type,
    result_value,
    result_percent,
    current_value,
    current_percent,
    current_estimation_value,
    current_estimation_percent,
    current_update_date,
    currency
  } = props.data;

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box>
        {result_value || result_value === 0 ? (
          <>
            <TextDataSet
              label={
                type === VALUABLE_TARGET_TYPE
                  ? 'As part of the target, products were sold for value'
                  : 'Quantities of products were sold within the target'
              }
              data={
                type === VALUABLE_TARGET_TYPE
                  ? renderPrice(result_value, currency)
                  : result_value
              }
            />
            <TextDataSet
              label='The goal was met on the level'
              data={`${result_percent}%`}
            />
          </>
        ) : (
          <>
            {current_update_date ? (
              <>
                <TextDataSet
                  label='Date of estimating the level of target achievement'
                  data={current_update_date}
                />
                <TextDataSet
                  label={`Current ${
                    type === VALUABLE_TARGET_TYPE ? 'value' : 'number'
                  } of products sold`}
                  data={
                    type === VALUABLE_TARGET_TYPE
                      ? renderPrice(current_value, currency)
                      : current_value
                  }
                />
                <TextDataSet
                  label='Current target achievement level'
                  data={renderEstimationPercent(current_percent)}
                />
                <TextDataSet
                  label={`Estimated ${
                    type === VALUABLE_TARGET_TYPE ? 'value' : 'number'
                  } of products sold`}
                  data={
                    type === VALUABLE_TARGET_TYPE
                      ? renderPrice(current_estimation_value, currency)
                      : current_estimation_value
                  }
                />
                <TextDataSet
                  label='Estimated target achievement level'
                  data={renderEstimationPercent(current_estimation_percent)}
                />
              </>
            ) : (
              <Typography variant='body1'>
                {t(
                  'The system has not yet set an estimated target achievement level'
                )}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

TargetResultsDataSets.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.number,
    result_value: PropTypes.number,
    result_percent: PropTypes.number,
    current_value: PropTypes.number,
    current_percent: PropTypes.number,
    current_estimation_value: PropTypes.number,
    current_estimation_percent: PropTypes.number,
    current_update_date: PropTypes.string,
    currency: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(TargetResultsDataSets);
