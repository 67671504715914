import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2, 0, 2),
    padding: theme.spacing(0.5, 6),
    fontSize: theme.spacing(2)
  },
  progress: {
    margin: theme.spacing(0, 2)
  }
}));

export default useStyles;
