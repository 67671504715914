import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import parse from 'date-fns/parse';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/DatePicker';
import addYears from 'date-fns/addYears';

const filterTypes = [{ slug: 'my_clients', label: 'My clients' }];

class StatisticsClientsOrdersFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'autocomplete',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasPermission([
              POSTAL_CODES_COUNTRIES_SHOW
            ])
          },
          {
            ...this.getBasicInputValues('type'),
            label: 'Type',
            type: 'select',
            emptyValueText: 'All',
            options: formatOptions(filterTypes, 'slug', 'label', true)
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('date_from'),
            label: 'Date from',
            type: 'date',
            maxDate: this.props.filter.date_to
              ? parse(this.props.filter.date_to, API_DATE_FORMAT, new Date())
              : addYears(new Date(), 20)
          },
          {
            ...this.getBasicInputValues('date_to'),
            label: 'Date to',
            type: 'date',
            minDate: this.props.filter.date_from
              ? parse(this.props.filter.date_from, API_DATE_FORMAT, new Date())
              : addYears(new Date(), -20)
          }
        ]
      }
    ];
  }
}

export default StatisticsClientsOrdersFilter;
