import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Grid, Paper } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import { ROUTE_SHOPPING_GROUPS_LIST } from 'routing/routes/ShoppingGroups';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Table from 'modules/Layout/component/List/Table';
import t from 'translate/translate';
import discountsTypes from 'modules/Discounts/discountsTypes';
import DiscountsApi from 'api/connections/Discounts/DiscountsApi';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_DISCOUNTS_CREATE,
  ROUTE_DISCOUNTS_DETAILS,
  ROUTE_DISCOUNTS_UPDATE
} from 'routing/routes/Discounts';
import Authorize from 'modules/Auth/component/Authorize';
import {
  DISCOUNTS_CREATE,
  DISCOUNTS_UPDATE,
  DISCOUNTS_DELETE
} from 'api/auth/permissions/Discounts';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DiscountsDeleteDialog from 'modules/Discounts/component/DeleteDialog';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';

class SalesGroupDetailsDiscounts extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.salesGroupId = parseInt(props.match.params.id, 10);
    this.setAlert = props.setAlert;

    this.canModifyAllDiscounts = [SUPER_ADMIN, ADMIN, DIRECTOR].includes(
      context.user.role
    );

    this.state = {
      discounts: [],
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      loading: true,
      deleteDialogStatus: false,
      discountToDelete: null
    };

    this.setPagination = this.setPagination.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_DISCOUNTS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    let path = insertPathParams(ROUTE_DISCOUNTS_UPDATE, { id });
    path += `?sales_group_id=${this.salesGroupId}&sales_group_name=${this.props.salesGroupName}`;

    this.props.history.push(path);
  }

  onCreateClick() {
    let path = `${ROUTE_DISCOUNTS_CREATE}`;
    path += `?sales_group_id=${this.salesGroupId}&sales_group_name=${this.props.salesGroupName}`;

    this.props.history.push(path);
  }

  setPagination(pagination) {
    this.setState(
      state => ({
        pagination: { ...state.pagination, ...pagination },
        loading: true
      }),
      () => this.fetchData()
    );
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'type',
        label: 'Type',
        sortable: false,
        render: typeId =>
          t(discountsTypes.find(type => type.id === typeId).name)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.canModifyAllDiscounts,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([DISCOUNTS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([DISCOUNTS_DELETE]),
            disable: false,
            disableMsg: null,
            onClick: this.openDeleteDialog
          }
        }
      }
    ];
  }

  closeDeleteDialog() {
    this.setState({
      deleteDialogStatus: false,
      discountToDelete: null
    });
  }

  openDeleteDialog(discount) {
    this.setState({
      deleteDialogStatus: true,
      discountToDelete: discount
    });
  }

  async fetchData() {
    const { per_page, page } = this.state.pagination;

    try {
      const {
        data: { data: discounts, meta }
      } = await DiscountsApi.getDiscounts({
        per_page,
        page,
        sales_group_id: this.salesGroupId
      });

      this.setState({
        discounts,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_SHOPPING_GROUPS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const {
      discounts,
      pagination,
      loading,
      deleteDialogStatus,
      discountToDelete
    } = this.state;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} md={6}>
          <Paper className='p1'>
            <Table
              cols={this.getCols()}
              rows={discounts}
              loading={loading}
              actions={this.canModifyAllDiscounts}
              dense
            />
            <Authorize permissions={[DISCOUNTS_CREATE]}>
              <Box>
                {this.canModifyAllDiscounts && (
                  <IconButton
                    className='create-icon'
                    onClick={this.onCreateClick}
                    icon={<AddIcon fontSize='large' />}
                    alt='create'
                  />
                )}
              </Box>
            </Authorize>
            <Pagination
              pagination={pagination}
              setPagination={this.setPagination}
            />
          </Paper>
          {deleteDialogStatus && (
            <DiscountsDeleteDialog
              onClose={this.closeDeleteDialog}
              discount={discountToDelete}
              onSuccess={this.fetchData}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

SalesGroupDetailsDiscounts.defaultProps = {
  salesGroupName: ''
};

SalesGroupDetailsDiscounts.propTypes = {
  salesGroupName: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(SalesGroupDetailsDiscounts);
