import { Component } from 'react';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import PropTypes from 'prop-types';
import Loader from 'modules/Layout/component/Loader';
import { isEmpty } from 'lodash/lang';
import capitalize from 'modules/Shared/utils/capitalize';
import ClientsTreePostalClients from 'modules/Clients/component/ClientsTree/Clients';

class ClientsTreePostalCodes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataToRender: undefined
    };
  }

  async componentDidMount() {
    const dataToRender = await this.props.getDataToRender(
      'postal_codes',
      this.props.cityId
    );

    this.setState({ dataToRender });
  }

  render() {
    const { dataToRender } = this.state;
    const { selectMode, isParentSelected } = this.props;

    if (dataToRender === undefined) return <Loader />;

    if (isEmpty(dataToRender)) return null;

    return dataToRender.map(postalCode => {
      const isVisible = this.props.isVisible('postal_codes', postalCode.id);
      const isSelected =
        isParentSelected ||
        this.props.isSelected('postal_codes', postalCode.id);

      if (!isVisible && !isSelected && !selectMode) return null;

      const item = {
        postalCodeLevel: 'postal_codes',
        region_id: this.props.regionId,
        subregion_id: this.props.subregionId,
        subsubregion_id: this.props.subsubregionId,
        ...postalCode
      };

      return (
        <TreeItem
          itemData={item}
          key={`postalCode-${postalCode.id}`}
          nodeId={`postalCode-${postalCode.id}`}
          labelText={capitalize(postalCode.code)}
          labelInfo={
            selectMode
              ? postalCode.clients_number
              : postalCode.assigned_clients_number
          }
          selectMode={selectMode}
          isSelected={isSelected}
          onSelect={this.props.onSelect}
          actionBtn={
            !selectMode && isSelected && this.props.renderDeleteItemBtn(item)
          }
        >
          <ClientsTreePostalClients
            regionId={this.props.regionId}
            subregionId={this.props.subregionId}
            subsubregionId={this.props.subsubregionId}
            cityId={this.props.cityId}
            postalCodeId={postalCode.id}
            getDataToRender={this.props.getDataToRender}
            isVisible={this.props.isVisible}
            isParentSelected={isSelected}
            isSelected={this.props.isSelected}
            selectMode={selectMode}
            onSelect={this.props.onSelect}
            renderDeleteItemBtn={this.props.renderDeleteItemBtn}
          />
        </TreeItem>
      );
    });
  }
}

ClientsTreePostalCodes.defaultProps = {
  subregionId: null,
  subsubregionId: null
};

ClientsTreePostalCodes.propTypes = {
  regionId: PropTypes.number.isRequired,
  subregionId: PropTypes.number,
  subsubregionId: PropTypes.number,
  cityId: PropTypes.number.isRequired,
  getDataToRender: PropTypes.func.isRequired,
  isVisible: PropTypes.func.isRequired,
  isParentSelected: PropTypes.bool.isRequired,
  isSelected: PropTypes.func.isRequired,
  selectMode: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  renderDeleteItemBtn: PropTypes.func.isRequired
};

export default ClientsTreePostalCodes;
