import { Route } from 'react-router-dom';
import {
  VISITS_POSTPONE,
  VISITS_SHOW,
  VISITS_UPDATE
} from 'api/auth/permissions/Visits';
import Visits from 'modules/Visits/view/List';
import VisitsCalendar from 'modules/Visits/view/Calendar';
import CreateVisitView from 'modules/Visits/view/Create';
import VisitDetails from 'modules/Visits/view/Details/DetailsWrapper';
import VisitFileUpdate from 'modules/Visits/view/File/Update';
import VisitFileCreate from 'modules/Visits/view/File/Create';
import VisitUpdate from 'modules/Visits/view/Update';
import VisitPostpone from 'modules/Visits/view/Postpone';
import VisitProductAdd from 'modules/Visits/view/Details/Products/Add';
import VisitsMapRoutes from 'modules/Visits/view/MapRoutes';
import VisitsRoutePlanning from 'modules/Visits/view/RoutePlanning';
import LongAgoVisitedClients from 'modules/Visits/view/LongAgoVisitedClients';
import Authorize from 'modules/Auth/component/Authorize';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import { CLIENTS_LIST } from 'api/auth/permissions/Clients';

export const ROUTE_VISITS_LIST = '/visits';
export const ROUTE_VISITS_CALENDAR = '/visits/calendar';
export const ROUTE_VISITS_CREATE = '/visits/create';
export const ROUTE_VISITS_DETAILS = '/visits/details/:id';
export const ROUTE_VISITS_UPDATE = '/visits/update/:id';
export const ROUTE_VISITS_POSTPONE = '/visits/postpone/:id';
export const ROUTE_VISIT_FILE_UPDATE = '/visits/:visitId/update_file/:fileId';
export const ROUTE_VISIT_FILE_CREATE = '/visits/details/:id/create_file';
export const ROUTE_VISIT_PRODUCT_ADD = '/visits/details/:id/add_product';
export const ROUTE_VISITS_MAP_ROUTES = '/map_routes';
export const ROUTE_VISITS_ROUTE_PLANNING = '/map_routes/route_planning';
export const ROUTE_LONG_AGO_VISITED_CLIENTS = '/long_ago_visited_clients';

export const permMap = {
  [ROUTE_VISITS_LIST]: [VISITS_SHOW],
  [ROUTE_VISITS_CALENDAR]: [VISITS_SHOW],
  [ROUTE_VISITS_CREATE]: [VISITS_SHOW],
  [ROUTE_VISITS_DETAILS]: [VISITS_SHOW],
  [ROUTE_VISITS_UPDATE]: [VISITS_UPDATE],
  [ROUTE_VISITS_POSTPONE]: [VISITS_POSTPONE],
  [ROUTE_VISIT_FILE_UPDATE]: [VISITS_UPDATE],
  [ROUTE_VISIT_FILE_CREATE]: [VISITS_UPDATE],
  [ROUTE_VISIT_PRODUCT_ADD]: [VISITS_UPDATE],
  [ROUTE_VISITS_MAP_ROUTES]: [VISITS_SHOW],
  [ROUTE_VISITS_ROUTE_PLANNING]: [VISITS_SHOW],
  [ROUTE_LONG_AGO_VISITED_CLIENTS]: [CLIENTS_LIST]
};

const routes = {
  protected: [
    <Route key='visits' exact path={ROUTE_VISITS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_VISITS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <Visits contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visits-calendar' exact path={ROUTE_VISITS_CALENDAR}>
      <Authorize redirect permissions={permMap[ROUTE_VISITS_CALENDAR]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitsCalendar contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visits-create' exact path={ROUTE_VISITS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_VISITS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <CreateVisitView contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visits-details' exact path={ROUTE_VISITS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_VISITS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visits-update' exact path={ROUTE_VISITS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_VISITS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route exact path={ROUTE_VISITS_POSTPONE} key='visits-postpone'>
      <Authorize permissions={permMap[ROUTE_VISITS_POSTPONE]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitPostpone contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visit-file-update' exact path={ROUTE_VISIT_FILE_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_VISIT_FILE_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitFileUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visit-file-create' exact path={ROUTE_VISIT_FILE_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_VISIT_FILE_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitFileCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visit-product-create' exact path={ROUTE_VISIT_PRODUCT_ADD}>
      <Authorize redirect permissions={permMap[ROUTE_VISIT_PRODUCT_ADD]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitProductAdd contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visits-map-routes' exact path={ROUTE_VISITS_MAP_ROUTES}>
      <Authorize redirect permissions={permMap[ROUTE_VISITS_MAP_ROUTES]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitsMapRoutes contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='visits-route-planning' exact path={ROUTE_VISITS_ROUTE_PLANNING}>
      <Authorize redirect permissions={permMap[ROUTE_VISITS_ROUTE_PLANNING]}>
        <BasicContextConsumerWrapper
          component={fn => <VisitsRoutePlanning contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='long-ago-visited-clients'
      exact
      path={ROUTE_LONG_AGO_VISITED_CLIENTS}
    >
      <Authorize redirect permissions={permMap[ROUTE_LONG_AGO_VISITED_CLIENTS]}>
        <BasicContextConsumerWrapper
          component={fn => <LongAgoVisitedClients contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
