import { Component } from 'react';
import PropTypes from 'prop-types';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { formatMetaToPagination } from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import format from 'date-fns/format';
import ValidationApiError from 'api/exceptions/ValidationApiError';

export const API_DATE_FORMAT = 'dd.MM.yyyy';

class Visits extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      filterValidation: {},
      sort: { sort_field: 'scheduled_at' },
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      // eslint-disable-next-line react/no-unused-state
      visits: []
    };

    const { setAlert, clientId } = props;
    this.setAlert = setAlert;
    this.clientId = clientId;
  }

  componentDidMount() {
    this.fetchVisits();
  }

  setSort = (sort, dateType) => {
    this.setState({ sort: { ...this.state.sort, ...sort } }, () =>
      this.fetchVisits(dateType)
    );
  };

  setPagination = (pagination, dateType) => {
    this.setState(
      { pagination: { ...this.state.pagination, ...pagination } },
      () => this.fetchVisits(dateType)
    );
  };

  setFilter = (filter, dateType) => {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchVisits(dateType)
    );
  };

  fetchVisits = async dateType => {
    try {
      const {
        data: { data: visits, meta }
      } = await VisitsApi.getVisits({
        ...this.state.sort,
        ...this.state.filter,
        [dateType]: format(new Date(), API_DATE_FORMAT),
        client_id: this.clientId,
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page
      });

      this.setState({
        // eslint-disable-next-line react/no-unused-state
        visits,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  };
}

Visits.propTypes = {
  setAlert: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired
};

export default Visits;
