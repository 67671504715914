import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import VisitProductsApi from 'api/connections/Visits/VisitProductsApi';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';

class UpdateVisitProductModal extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.onSuccess = props.onSuccess;
    this.onClose = props.onClose;
    const { note } = this.props.product;

    this.state = {
      formData: {
        note
      },
      validation: {
        note: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Note')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    const { note } = this.state.formData;
    const { id } = this.props.product;
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.onSuccess(id, note);
    this.onClose();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { validation } = state;
      const { value } = e.target;

      return {
        formData: {
          note: value
        },
        validation: {
          note: {
            ...validation.note,
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    const { id } = this.props.product;

    this.setState({ loading: true });
    try {
      await VisitProductsApi.updateVisitProduct(id, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { note },
      validation,
      loading
    } = this.state;

    const {
      product: { product_sku: sku },
      open
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Update')}</DialogTitle>
        <DialogContent>
          <TextDataSet label={t('Name')} data={sku} />
          <form
            id='update-visit-product-form'
            noValidate
            onSubmit={this.onSubmit}
          >
            <Input
              name='note'
              label='Note'
              value={note}
              required
              errorStatus={validation.note.status}
              errorText={validation.note.message}
              onChange={this.onChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.onClose} />
          <Button
            form='update-visit-product-form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

UpdateVisitProductModal.propTypes = {
  product: PropTypes.shape({
    product_sku: PropTypes.string,
    note: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default UpdateVisitProductModal;
