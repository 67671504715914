import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PostalCodesBox from 'modules/PostalCodes/component/shared/PostalCodesBox';
import { ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import IconButton from 'modules/Layout/component/IconButton';
import Dialog from 'modules/Layout/component/Dialog';
import { useStyles } from 'modules/PostalCodes/component/shared/style';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import ApiError from 'api/exceptions/ValidationApiError';
import { PostalCodesContext } from 'modules/PostalCodes/context/PostalCodesProvider';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import t from 'translate/translate';
import { REGION, SUBREGION } from 'modules/PostalCodes/constants';
import EditIcon from '@material-ui/icons/Edit';
import {
  ROUTE_POSTAL_CODES_CREATE,
  ROUTE_POSTAL_CODES_UPDATE
} from 'routing/routes/PostalCodes';

const SubregionsList = () => {
  const classes = useStyles();

  const {
    selectedItem,
    handleListItemClick,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    dialogData,
    countryDepth,
    handleChosenItem
  } = useContext(PostalCodesContext);
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();
  const [subRegions, setSubRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSubRegions = async id => {
    try {
      setIsLoading(true);
      const {
        data: { data: subRegionsData }
      } = await PostalCodesApi.getSubRegions({
        region_id: id,
        per_page: Number.MAX_SAFE_INTEGER
      });
      setSubRegions(subRegionsData);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const removeItem = async () => {
    try {
      const { id } = dialogData;
      await PostalCodesApi.deleteSubRegion(id);
      setSubRegions(prevState =>
        prevState.filter(subRegion => subRegion.id !== id)
      );
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const addSubregion = () => {
    handleChosenItem({ type: 'subregion' });
    history.push(ROUTE_POSTAL_CODES_CREATE);
  };

  const updateSubRegion = (e, id, name) => {
    e.stopPropagation();
    handleChosenItem({ type: 'subregion', name, id, parentType: 'region' });
    history.push(ROUTE_POSTAL_CODES_UPDATE);
  };

  useEffect(() => {
    if (selectedItem[REGION]) {
      fetchSubRegions(selectedItem[REGION]);
    }
  }, [selectedItem[REGION]]);

  return (
    selectedItem[REGION] &&
    countryDepth >= 1 &&
    (isLoading ? (
      <CircularProgress style={{ marginLeft: 30 }} />
    ) : (
      <>
        <PostalCodesBox
          title='Subregion'
          data={subRegions}
          addFn={addSubregion}
        >
          {subRegions.map(({ name, id, cities_count }) => (
            <ListItem
              onClick={() => {
                handleListItemClick({ id, type: SUBREGION });
              }}
              className={classes.listItem}
              selected={selectedItem[SUBREGION] === id}
              key={`${name}${id}`}
            >
              <ListItemText
                style={{ cursor: 'default', marginRight: 20 }}
                primary={name}
              />
              <IconButton
                className='update-icon'
                onClick={e => updateSubRegion(e, id, name)}
                icon={<EditIcon fontSize='small' />}
                alt='update'
              />
              <IconButton
                className='delete-icon'
                onClick={e => handleOpenDialog(id, name, e, SUBREGION)}
                icon={<DeleteForeverIcon />}
                tooltipMsg='Delete all the cities of this subregion, first.'
                disabled={cities_count > 0}
                alt='delete'
              />
            </ListItem>
          ))}
        </PostalCodesBox>

        <Dialog
          open={openDialog[SUBREGION]}
          title={t('Deletion confirmation')}
          description={`${t('Are you sure you want to delete subregion: ')}${
            dialogData.name
          }?`}
          onApprove={async () => {
            await removeItem();
            handleCloseDialog();
          }}
          onCancel={handleCloseDialog}
        />
      </>
    ))
  );
};

export default SubregionsList;
