import ListIcon from '@material-ui/icons/List';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import {
  ROUTE_TARGETS_LIST,
  permMap as targetsPermMap
} from 'routing/routes/Targets';
import {
  ROUTE_TARGETS_BONUSES_LIST,
  permMap as targetsBonusesPermMap
} from '../../../../../../routing/routes/TargetsBonuses';

const menu = [
  {
    key: 'menu-targets',
    label: 'Targets and bonuses',
    icon: <TrackChangesIcon />,
    path: ROUTE_TARGETS_LIST,
    anyPermissions: [
      ...targetsPermMap[ROUTE_TARGETS_LIST],
      ...targetsBonusesPermMap[ROUTE_TARGETS_BONUSES_LIST]
    ],
    children: [
      {
        key: 'menu-targets-list',
        label: 'Sell targets',
        icon: <ListIcon />,
        path: ROUTE_TARGETS_LIST,
        permissions: targetsPermMap[ROUTE_TARGETS_LIST]
      },
      {
        key: 'menu-targets-bonuses-list',
        label: 'Workers bonuses',
        icon: <ListIcon />,
        path: ROUTE_TARGETS_BONUSES_LIST,
        permissions: targetsBonusesPermMap[ROUTE_TARGETS_BONUSES_LIST]
      }
    ]
  }
];

export default menu;
