import * as _ from 'lodash';

export default function formatSalesResults(data) {
  const valuesArr = _.values(data).filter(value => value.quantity_sum > 0);

  return valuesArr.map(valueObj => {
    const { id, name, sku, price_sum, quantity_sum } = valueObj;

    return {
      id,
      name: name ?? sku,
      price_sum,
      quantity_sum,
      children: (() => {
        if (
          valueObj.categories &&
          Object.keys(valueObj.categories).length > 0
        ) {
          return formatSalesResults(valueObj.categories);
        }
        if (
          valueObj.subcategories &&
          Object.keys(valueObj.subcategories).length > 0
        ) {
          return formatSalesResults(valueObj.subcategories);
        }
        if (valueObj.products && Object.keys(valueObj.products).length > 0) {
          return formatSalesResults(valueObj.products);
        }
        return [];
      })()
    };
  });
}
