import SharedLocal from 'translate/locals/pl/Shared';
import AuthLocal from 'translate/locals/pl/Auth';
import ProfileLocal from 'translate/locals/pl/Profile';
import DashboardLocal from 'translate/locals/pl/Dashboard';
import DepartmentsLocal from 'translate/locals/pl/Departments';
import UsersLocal from 'translate/locals/pl/Users';
import ClientsLocal from 'translate/locals/pl/Clients';
import ClientsContractsLocal from 'translate/locals/pl/ClientsContracts';
import ClientsBonusesLocal from 'translate/locals/pl/ClientsBonuses';
import ProductCategories from 'translate/locals/pl/ProductCategories';
import Product from 'translate/locals/pl/Products';
import Wholesalers from 'translate/locals/pl/Wholesalers';
import PostalCodes from 'translate/locals/pl/PostalCodes';
import Sales from 'translate/locals/pl/Sales';
import ShoppingGroups from 'translate/locals/pl/ShoppingGroups';
import SalesImports from 'translate/locals/pl/SalesImports';
import SalesResults from 'translate/locals/pl/SalesResults';
import Visits from 'translate/locals/pl/Visits';
import Targets from 'translate/locals/pl/Targets';
import TargetsBonuses from 'translate/locals/pl/TargetsBonuses';
import Taxes from 'translate/locals/pl/Taxes';
import Discounts from 'translate/locals/pl/Discounts';
import Orders from 'translate/locals/pl/Orders';
import Statistics from 'translate/locals/pl/Statistics';
import Promotions from 'translate/locals/pl/Promotions';
import KnowledgeBase from 'translate/locals/pl/KnowledgeBase';
import AffiliationLocal from 'translate/locals/pl/Affiliation';
import PriceListsLocal from 'translate/locals/pl/PriceLists';
import CurrenciesLocal from 'translate/locals/pl/Currencies';
import ImportsLocal from 'translate/locals/pl/Imports';
import PaymentMethodsLocal from 'translate/locals/pl/PaymentMethods';

export default {
  ...SharedLocal,
  ...AuthLocal,
  ...ProfileLocal,
  ...DashboardLocal,
  ...DepartmentsLocal,
  ...UsersLocal,
  ...ClientsLocal,
  ...ClientsContractsLocal,
  ...ClientsBonusesLocal,
  ...ProductCategories,
  ...Product,
  ...Wholesalers,
  ...PostalCodes,
  ...Sales,
  ...ShoppingGroups,
  ...SalesImports,
  ...SalesResults,
  ...Visits,
  ...Targets,
  ...TargetsBonuses,
  ...Taxes,
  ...Discounts,
  ...Orders,
  ...Statistics,
  ...Promotions,
  ...KnowledgeBase,
  ...AffiliationLocal,
  ...PriceListsLocal,
  ...CurrenciesLocal,
  ...ImportsLocal,
  ...PaymentMethodsLocal
};
