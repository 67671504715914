import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import MoneyIcon from '@material-ui/icons/Money';
import BlockIcon from '@material-ui/icons/Block';
import SyncIcon from '@material-ui/icons/Sync';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_TARGETS_BONUSES_UPDATE } from 'routing/routes/TargetsBonuses';
import targetBonusesTypes, {
  BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION,
  BONUS_TYPE_WAITING_FOR_CONFIRMATION,
  BONUS_TYPE_CONFIRMED,
  BONUS_TYPE_SUSPENDED
} from 'modules/TargetsBonuses/targetBonusesTypes';
import renderPrice from 'modules/Products/utils/renderPrice';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import SetBonusFinalValueModal from 'modules/TargetsBonuses/component/Modals/SetBonusFinalValueModal';
import ConfirmBonusModal from 'modules/TargetsBonuses/component/Modals/ConfirmBonusModal';
import PaidBonusModal from 'modules/TargetsBonuses/component/Modals/PaidBonusModal';
import SuspendBonusModal from 'modules/TargetsBonuses/component/Modals/SuspendBonusModal';
import UnSuspendBonusModal from 'modules/TargetsBonuses/component/Modals/UnSuspendBonusModal';
import Authorize from 'modules/Auth/component/Authorize';
import { TARGET_UPDATE } from 'api/auth/permissions/Targets';

class TargetBonusesDetailsDataSets extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      finalValueModalStatus: false,
      confirmModalStatus: false,
      paidModalStatus: false,
      unSuspendModalStatus: false
    };

    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onUpdateFinalValueClick = this.onUpdateFinalValueClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.onPaidClick = this.onPaidClick.bind(this);
    this.onSuspendClick = this.onSuspendClick.bind(this);
    this.onUnSuspendClick = this.onUnSuspendClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.getEstimatedBonusValue = this.getEstimatedBonusValue.bind(this);
  }

  onUpdateClick() {
    const { id: bonusId } = this.props.bonus;

    this.props.history.push(
      insertPathParams(ROUTE_TARGETS_BONUSES_UPDATE, { bonusId })
    );
  }

  onUpdateFinalValueClick() {
    this.setState({ finalValueModalStatus: true });
  }

  onConfirmClick() {
    this.setState({ confirmModalStatus: true });
  }

  onPaidClick() {
    this.setState({ paidModalStatus: true });
  }

  onSuspendClick() {
    this.setState({ suspendModalStatus: true });
  }

  onUnSuspendClick() {
    this.setState({ unSuspendModalStatus: true });
  }

  getEstimatedBonusValue() {
    const {
      target: { current_estimation_percent: percent },
      value_level_1,
      value_level_2,
      value_level_3,
      value_level_4
    } = this.state.bonus;

    if (percent >= 90 && percent < 100) return value_level_1;
    if (percent >= 100 && percent < 110) return value_level_2;
    if (percent >= 110 && percent < 120) return value_level_3;
    if (percent >= 120) return value_level_4;
  }

  closeAllModals() {
    this.setState({
      finalValueModalStatus: false,
      confirmModalStatus: false,
      paidModalStatus: false,
      suspendModalStatus: false,
      unSuspendModalStatus: false
    });
  }

  render() {
    const {
      finalValueModalStatus,
      confirmModalStatus,
      paidModalStatus,
      suspendModalStatus,
      unSuspendModalStatus
    } = this.state;

    const {
      bonus: {
        user: { first_name, last_name },
        target: { result_percent },
        id: bonusId,
        name,
        status,
        confirmed_by,
        confirmed_at,
        suspended_by,
        suspended_at,
        suspended_reason,
        final_value,
        value_level_1,
        value_level_2,
        value_level_3,
        value_level_4,
        currency
      },
      fetchBonusData
    } = this.props;

    return (
      <>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem' }}
            >
              {t('Basic data')}
            </Typography>
            <TextDataSet label='Name' data={name} />
            <TextDataSet
              label='User name'
              data={`${first_name} ${last_name}`}
            />
            <TextDataSet
              label='Status'
              data={t(targetBonusesTypes.find(i => i.id === status).name)}
            />

            {!isEmpty(confirmed_by) && (
              <>
                <TextDataSet
                  label='Confirmed by'
                  data={`${confirmed_by.first_name} ${confirmed_by.last_name}`}
                />
                <TextDataSet label='Confirmed at' data={confirmed_at || ''} />
              </>
            )}

            {suspended_at && (
              <>
                <TextDataSet
                  label='Suspended by'
                  data={`${suspended_by.first_name} ${suspended_by.last_name}`}
                />
                <TextDataSet label='Suspended at' data={suspended_at} />
                <TextDataSet label='Suspended reason' data={suspended_reason} />
              </>
            )}

            {status !== BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION && (
              <TextDataSet
                label='Final bonus value'
                data={renderPrice(final_value, currency)}
              />
            )}

            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem', marginTop: '1rem' }}
            >
              {t('Bonus value at each goal fulfillment level')}
            </Typography>
            <TextDataSet
              label='Bonus at 90%'
              data={renderPrice(value_level_1, currency)}
              color={
                result_percent >= 90 && result_percent < 100
                  ? 'primary'
                  : 'initial'
              }
            />
            <TextDataSet
              label='Bonus at 100%'
              data={renderPrice(value_level_2, currency)}
              color={
                result_percent >= 100 && result_percent < 110
                  ? 'primary'
                  : 'initial'
              }
            />
            <TextDataSet
              label='Bonus at 110%'
              data={renderPrice(value_level_3, currency)}
              color={
                result_percent >= 110 && result_percent < 120
                  ? 'primary'
                  : 'initial'
              }
            />
            <TextDataSet
              label='Bonus at 120%'
              data={renderPrice(value_level_4, currency)}
              color={result_percent >= 120 ? 'primary' : 'initial'}
            />
          </Box>
          <Box display='flex' alignItems='flex-start'>
            <Authorize permissions={[TARGET_UPDATE]}>
              {status === BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION && (
                <IconButton
                  className='update-icon'
                  onClick={this.onUpdateClick}
                  icon={<EditIcon />}
                  alt='update'
                />
              )}
              {status === BONUS_TYPE_WAITING_FOR_CONFIRMATION && (
                <>
                  <IconButton
                    onClick={this.onConfirmClick}
                    className='details-icon'
                    icon={<CheckIcon />}
                    alt='confirm'
                  />
                  <IconButton
                    className='update-icon'
                    onClick={this.onUpdateFinalValueClick}
                    icon={<EditIcon />}
                    alt='update'
                  />
                </>
              )}
              {status === BONUS_TYPE_CONFIRMED && (
                <IconButton
                  className='money-icon'
                  onClick={this.onPaidClick}
                  icon={<MoneyIcon />}
                  alt='set status to paid'
                />
              )}
              {(status === BONUS_TYPE_CONFIRMED ||
                status === BONUS_TYPE_WAITING_FOR_CONFIRMATION) && (
                <IconButton
                  className='delete-icon'
                  onClick={this.onSuspendClick}
                  icon={<BlockIcon />}
                  alt='suspend'
                />
              )}
              {status === BONUS_TYPE_SUSPENDED && (
                <IconButton
                  className='unsuspend-icon'
                  onClick={this.onUnSuspendClick}
                  icon={<SyncIcon />}
                  alt='undo suspension'
                />
              )}
            </Authorize>
          </Box>
        </Box>
        {finalValueModalStatus && (
          <SetBonusFinalValueModal
            bonusId={bonusId}
            value={final_value}
            onClose={this.closeAllModals}
            onSuccess={fetchBonusData}
          />
        )}
        {confirmModalStatus && (
          <ConfirmBonusModal
            bonusId={bonusId}
            onClose={this.closeAllModals}
            onSuccess={fetchBonusData}
          />
        )}
        {paidModalStatus && (
          <PaidBonusModal
            bonusId={bonusId}
            onClose={this.closeAllModals}
            onSuccess={fetchBonusData}
          />
        )}
        {suspendModalStatus && (
          <SuspendBonusModal
            bonusId={bonusId}
            onClose={this.closeAllModals}
            onSuccess={fetchBonusData}
          />
        )}
        {unSuspendModalStatus && (
          <UnSuspendBonusModal
            bonusId={bonusId}
            onClose={this.closeAllModals}
            onSuccess={fetchBonusData}
          />
        )}
      </>
    );
  }
}

TargetBonusesDetailsDataSets.propTypes = {
  bonus: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }).isRequired,
    target: PropTypes.shape({
      department_id: PropTypes.number,
      result_percent: PropTypes.number
    }).isRequired,
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.number,
    confirmed_by: PropTypes.arrayOf(PropTypes.shape({})),
    confirmed_at: PropTypes.string,
    suspended_by: PropTypes.arrayOf(PropTypes.shape({})),
    suspended_at: PropTypes.string,
    suspended_reason: PropTypes.string,
    final_value: PropTypes.number,
    value_level_1: PropTypes.number,
    value_level_2: PropTypes.number,
    value_level_3: PropTypes.number,
    value_level_4: PropTypes.number,
    currency: PropTypes.string.isRequired
  }).isRequired,
  fetchBonusData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(TargetBonusesDetailsDataSets);
