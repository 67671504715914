import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import VisitFileUpdateForm from 'modules/Visits/components/Details/Files/Update/Form';
import { withRouter } from 'react-router-dom';
import VisitFilesApi from 'api/connections/Visits/VisitFilesApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_VISITS_DETAILS, ROUTE_VISITS_LIST } from 'routing/routes/Visits';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class VisitFileUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.visitId = parseInt(this.props.match.params.visitId, 10);
    this.fileId = parseInt(this.props.match.params.fileId, 10);

    this.state = {
      visitFile: null
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  componentDidMount() {
    if (!this.visitId || !this.fileId) {
      this.props.history.push(ROUTE_VISITS_LIST);
    }
    this.setCurrentPage('Update visit file');
    this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: { data: files }
      } = await VisitFilesApi.getVisitFiles(this.visitId);

      const visitFile = files.find(file => file.id === this.fileId);

      if (
        !visitFile ||
        (visitFile && visitFile.creator_id !== this.context.user.id)
      ) {
        let path = insertPathParams(ROUTE_VISITS_DETAILS, {
          id: this.visitId
        });
        path += '?tab_index=files';
        this.props.history.push(path);
      }

      this.setState({ visitFile });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
        if (err instanceof NotFoundApiError) {
          let path = insertPathParams(ROUTE_VISITS_DETAILS, {
            id: this.visitId
          });
          path += '?tab_index=files';
          this.props.history.push(path);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { visitFile } = this.state;
    if (!visitFile) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <VisitFileUpdateForm
              visitId={this.visitId}
              fileId={this.fileId}
              visitFile={visitFile}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

VisitFileUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      visitId: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(VisitFileUpdate);
