import ApiError from 'api/exceptions/ApiError';

export default class ValidationApiError extends ApiError {
  constructor(error) {
    super('Validation error.', error);
  }

  processApiValidationError() {
    const errors = this.getErrors();
    const objKeys = Object.keys(errors);
    const newValidateState = {};

    objKeys.forEach(key => {
      newValidateState[key] = {
        status: true,
        message: errors[key]
      };
    });

    return newValidateState;
  }
}
