import { Component } from 'react';
import PropTypes from 'prop-types';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';

import ProductsTreeProducts from 'modules/Visits/components/Details/Products/Add/ProductsTreeProducts';

class ProductsTreeSubCategories extends Component {
  render() {
    const {
      categoriesObj,
      productsData,
      treeItemsToDisplay,
      selected,
      onClick,
      onSelect
    } = this.props;

    return categoriesObj.map(item => {
      return (
        <TreeItem
          itemData={item}
          key={item.id}
          nodeId={item.id.toString()}
          labelText={item.name}
          onIconClick={() => onClick(item)}
        >
          {productsData[item.id] ? (
            <ProductsTreeProducts
              productsData={productsData[item.id]}
              treeItemsToDisplay={treeItemsToDisplay}
              selected={selected}
              onClick={onClick}
              onSelect={onSelect}
            />
          ) : (
            <TreeItem nodeId={`placeholder-${item.id}`} />
          )}
        </TreeItem>
      );
    });
  }
}

ProductsTreeSubCategories.propTypes = {
  categoriesObj: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsData: PropTypes.shape({}).isRequired,
  treeItemsToDisplay: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.number),
    categories: PropTypes.arrayOf(PropTypes.number),
    subcategories: PropTypes.arrayOf(PropTypes.number),
    products: PropTypes.arrayOf(PropTypes.number)
  }),
  selected: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

ProductsTreeSubCategories.defaultProps = {
  treeItemsToDisplay: null
};

export default ProductsTreeSubCategories;
