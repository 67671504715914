import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';
import { ROUTE_WHOLESALERS_LIST } from 'routing/routes/Wholesalers';
import WholesalerFileCreateForm from 'modules/Wholesalers/component/Details/Files/Create/Form';

class WholesalerFileCreate extends Component {
  static contextType = CurrentPageContext;

  constructor(props) {
    super(props);

    this.wholesalerId = parseInt(this.props.match.params.id, 10);
  }

  async componentDidMount() {
    if (!this.wholesalerId) {
      this.props.history.push(ROUTE_WHOLESALERS_LIST);
    }
    this.context.setCurrentPage('Add wholesaler file');
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper className='p1'>
            <WholesalerFileCreateForm wholesalerId={this.wholesalerId} />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalerFileCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(WholesalerFileCreate);
