import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Table from 'modules/Layout/component/List/Table';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { withRouter } from 'react-router-dom';
import {
  ROUTE_PRODUCTS_BATCHES_CREATE,
  ROUTE_PRODUCTS_BATCHES_UPDATE
} from 'routing/routes/Products';
import insertPathParams from 'api/utils/insertPathParams';
import {
  PRODUCTS_DELETE,
  PRODUCTS_UPDATE
} from 'api/auth/permissions/Products';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ProductBatchDeleteDialog from 'modules/Products/component/Batches/DeleteDialog';
import Authorize from 'modules/Auth/component/Authorize';

class ProductBatchesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { batches } = this.props;
    this.productId = parseInt(props.match.params.id, 10);

    this.state = {
      batches,
      deleteDialogOpen: false,
      batchToDelete: null
    };
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  onUpdateClick({ id: batchId }) {
    this.props.history.push(
      insertPathParams(ROUTE_PRODUCTS_BATCHES_UPDATE, {
        productId: this.productId,
        batchId
      })
    );
  }

  onCreateClick() {
    this.props.history.push(
      insertPathParams(ROUTE_PRODUCTS_BATCHES_CREATE, {
        id: this.productId
      })
    );
  }

  onDeleteClick(batch) {
    this.setState({ deleteDialogOpen: true, batchToDelete: batch });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, batchToDelete: null });
  }

  onDeleteSuccess(batchId) {
    this.setState(prevState => {
      return {
        ...prevState,
        batches: prevState.batches.filter(batch =>
          batch.id === batchId ? null : batch
        )
      };
    });
  }

  getCols() {
    return [
      {
        property: 'number',
        label: 'Batch number',
        sortable: false
      },
      {
        property: 'quantity',
        label: 'Quantity',
        sortable: false
      },
      {
        property: 'expiration_date',
        label: 'Expiration date',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasSomeOfPermission([
          PRODUCTS_UPDATE,
          PRODUCTS_DELETE
        ]),
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: this.context.hasPermission([PRODUCTS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([PRODUCTS_DELETE]),
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { batches } = this.state;
    const { deleteDialogOpen, batchToDelete } = this.state;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={batches}
          dense
          actions={this.context.hasSomeOfPermission([
            PRODUCTS_UPDATE,
            PRODUCTS_DELETE
          ])}
        />
        <Authorize permissions={[PRODUCTS_UPDATE]}>
          <Box display='flex' alignItems='center'>
            <IconButton
              className='create-icon'
              onClick={this.onCreateClick}
              icon={<AddIcon fontSize='large' />}
              alt='create'
            />
          </Box>
        </Authorize>
        {deleteDialogOpen && (
          <ProductBatchDeleteDialog
            batch={batchToDelete}
            productId={this.productId}
            onClose={this.onDeleteDialogClose}
            onSuccess={this.onDeleteSuccess}
          />
        )}
      </>
    );
  }
}

ProductBatchesList.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(ProductBatchesList);
