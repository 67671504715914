import PropTypes from 'prop-types';
import { Box, Container, CssBaseline } from '@material-ui/core';
import logo from 'asset/img/logo.png';
import Alerts from 'modules/Layout/component/Alerts';

const PublicWrapper = ({ children }) => {
  return (
    <Container maxWidth='sm'>
      <CssBaseline />
      <Alerts />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        mt={8}
      >
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <img src={logo} alt='VetExpert logo' />
        </Box>
        <Box my={5} width={1} textAlign='center'>
          {children}
        </Box>
      </Box>
    </Container>
  );
};

PublicWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default PublicWrapper;
