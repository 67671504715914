import { Component } from 'react';
import { ROUTE_USERS_CREATE } from 'routing/routes/Users';
import t from 'translate/translate';
import UsersTable from 'modules/Users/component/List/Table';
import UsersFilter from 'modules/Users/component/List/Filter';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import UsersApi from 'api/connections/Users/UsersApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ApiError from 'api/exceptions/ApiError';
import Dialog from 'modules/Layout/component/Dialog';
import PropTypes from 'prop-types';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import Authorize from 'modules/Auth/component/Authorize';
import { USERS_CREATE } from 'api/auth/permissions/Users';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';

const deleteSuccessMsg = t('Deleting user successfully finish');

class UsersList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        first_name: '',
        last_name: '',
        email: '',
        product_group_name: '',
        department_id: '',
        country_id: '',
        role: '',
        my_departments: false
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      users: [],
      countries: [],
      departments: [],
      dialog: {
        open: false,
        description: '',
        id: null
      },
      loading: true
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Users');
    this.fetchData();
  }

  onDeleteClick(row) {
    const { id, first_name, last_name } = row;
    const description = t(
      'Are you sure you want to delete the user <%= first_name %> <%= last_name %>',
      {
        first_name,
        last_name
      }
    );
    this.setState({
      dialog: { open: true, description, id }
    });
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchUsers()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchUsers()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchUsers()
    );
  }

  closeDialog() {
    this.setState({
      dialog: { open: false, description: '', id: null }
    });
  }

  async fetchUsers() {
    try {
      const {
        data: { data: usersData, meta }
      } = await UsersApi.getUsers({
        ...this.state.sort,
        ...this.state.filter,
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page
      });

      this.setState({
        users: usersData,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async deleteUser() {
    try {
      await UsersApi.deleteUser(this.state.dialog.id);
      this.setAlert({
        value: deleteSuccessMsg,
        variant: COLOR_VARIANTS_SUCCESS
      });
      this.closeDialog();
      this.fetchUsers();
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.closeDialog();
        this.setAlert(message);
      }
    }
  }

  async fetchData() {
    try {
      let countries = [];
      let departments = [];

      const {
        data: { data: usersData, meta }
      } = await UsersApi.getUsers({
        ...this.state.sort,
        ...this.state.filter,
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page
      });

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      if (this.context.hasPermission([DEPARTMENTS_LIST])) {
        const {
          data: { data: departmentsData }
        } = await DepartmentsApi.getDepartments({
          per_page: Number.MAX_SAFE_INTEGER
        });
        departments = departmentsData;
      }

      this.setState({
        countries,
        departments,
        users: usersData,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      }
    }
  }

  renderActions() {
    return (
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Authorize permissions={[USERS_CREATE]}>
            <IconButton
              icon={<AddIcon fontSize='large' />}
              onClick={() => this.props.history.push(ROUTE_USERS_CREATE)}
              className='create-icon'
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={this.state.pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    return (
      <div>
        <UsersFilter
          filter={this.state.filter}
          validation={this.state.filterValidation}
          setFilter={this.setFilter}
          countries={this.state.countries}
          departments={this.state.departments}
        />
        {this.renderActions()}
        <UsersTable
          sort={this.state.sort}
          setSort={this.setSort}
          users={this.state.users}
          countries={this.state.countries}
          onDeleteClick={this.onDeleteClick}
          loading={this.state.loading}
        />
        {this.renderActions()}
        <Dialog
          open={this.state.dialog.open}
          title={t('Deletion confirmation')}
          description={this.state.dialog.description}
          onApprove={this.deleteUser}
          onCancel={this.closeDialog}
        />
      </div>
    );
  }
}

UsersList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(UsersList);
