import ListIcon from '@material-ui/icons/List';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {
  ROUTE_STATISTICS_CLIENTS_ORDERS_LIST,
  ROUTE_STATISTICS_INTERNET_CLIENTS_REPORT_LIST,
  ROUTE_STATISTICS_CLIENTS_SALES_LIST,
  ROUTE_STATISTICS_PRODUCTS_SALES_LIST,
  ROUTE_STATISTICS_PRIORITY_PRODUCTS_SALES_LIST,
  ROUTE_STATISTICS_USERS_FINISHED_VISITS_LIST,
  ROUTE_STATISTICS_WHOLESALERS_SALES_LIST,
  ROUTE_STATISTICS_USERS_ORDERS_VALUE_LIST,
  ROUTE_STATISTICS_USERS_VISITS_LIST,
  ROUTE_STATISTICS_USERS_ORDERS_VISITS_LIST,
  ROUTE_STATISTICS_USERS_VISITS_CLIENTS_LIST,
  ROUTE_STATISTICS_USERS_VISITS_COMPARISON_LIST,
  ROUTE_STATISTICS_SALES_COMPARISON_LIST,
  ROUTE_TRADERS_VISITS_REPORT,
  ROUTE_CLIENTS_TARGETS_REPORT,
  ROUTE_GRATIS_PRODUCTS_REPORT,
  permMap
} from 'routing/routes/Statistics';

const menu = [
  {
    key: 'menu-statistics',
    label: 'Statistics',
    icon: <EqualizerIcon />,
    path: ROUTE_STATISTICS_CLIENTS_ORDERS_LIST,
    anyPermissions: [...permMap[ROUTE_STATISTICS_CLIENTS_ORDERS_LIST]],
    children: [
      {
        key: 'menu-statistics-clients-orders',
        label: 'Clients orders',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_CLIENTS_ORDERS_LIST,
        permissions: permMap[ROUTE_STATISTICS_CLIENTS_ORDERS_LIST]
      },
      {
        key: 'menu-statistics-internet-clients-report',
        label: 'Internet clients report',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_INTERNET_CLIENTS_REPORT_LIST,
        permissions: permMap[ROUTE_STATISTICS_INTERNET_CLIENTS_REPORT_LIST]
      },
      {
        key: 'menu-statistics-clients-sales',
        label: 'Clients sales',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_CLIENTS_SALES_LIST,
        permissions: permMap[ROUTE_STATISTICS_CLIENTS_SALES_LIST]
      },
      {
        key: 'menu-statistics-products-sales',
        label: 'Products sales',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_PRODUCTS_SALES_LIST,
        permissions: permMap[ROUTE_STATISTICS_PRODUCTS_SALES_LIST]
      },
      {
        key: 'menu-statistics-priority-products-sales',
        label: 'Priority products sales',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_PRIORITY_PRODUCTS_SALES_LIST,
        permissions: permMap[ROUTE_STATISTICS_PRIORITY_PRODUCTS_SALES_LIST]
      },
      {
        key: 'menu-statistics-users-finished-visits',
        label: 'Users finished visits',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_USERS_FINISHED_VISITS_LIST,
        permissions: permMap[ROUTE_STATISTICS_USERS_FINISHED_VISITS_LIST]
      },
      {
        key: 'menu-statistics-wholesalers-sales',
        label: 'Wholesalers sales',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_WHOLESALERS_SALES_LIST,
        permissions: permMap[ROUTE_STATISTICS_WHOLESALERS_SALES_LIST]
      },
      {
        key: 'menu-statistics-users-orders-value',
        label: 'Users orders',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_USERS_ORDERS_VALUE_LIST,
        permissions: permMap[ROUTE_STATISTICS_USERS_ORDERS_VALUE_LIST]
      },
      {
        key: 'menu-statistics-users-visits-value',
        label: 'Users visits',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_USERS_VISITS_LIST,
        permissions: permMap[ROUTE_STATISTICS_USERS_VISITS_LIST]
      },
      {
        key: 'menu-statistics-users-orders-visits-value',
        label: 'Users orders during visits',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_USERS_ORDERS_VISITS_LIST,
        permissions: permMap[ROUTE_STATISTICS_USERS_ORDERS_VISITS_LIST]
      },
      {
        key: 'statistics-user-visits-active-clients',
        label: 'Statistics of visited active clients',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_USERS_VISITS_CLIENTS_LIST,
        permissions: permMap[ROUTE_STATISTICS_USERS_VISITS_CLIENTS_LIST]
      },
      {
        key: 'menu-statistics-users-visits-comparison',
        label: 'Users visits comparison',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_USERS_VISITS_COMPARISON_LIST,
        permissions: permMap[ROUTE_STATISTICS_USERS_VISITS_COMPARISON_LIST]
      },
      {
        key: 'menu-statistics-sales-comparison',
        label: 'Sales comparison',
        icon: <ListIcon />,
        path: ROUTE_STATISTICS_SALES_COMPARISON_LIST,
        permissions: permMap[ROUTE_STATISTICS_SALES_COMPARISON_LIST]
      },
      {
        key: 'menu-statistics-visit-report',
        label: 'Traders visits report',
        icon: <ListIcon />,
        path: ROUTE_TRADERS_VISITS_REPORT,
        permissions: permMap[ROUTE_TRADERS_VISITS_REPORT]
      },
      {
        key: 'menu-statistics-clients-targets-report',
        label: 'Clients contracts report',
        icon: <ListIcon />,
        path: ROUTE_CLIENTS_TARGETS_REPORT,
        permissions: permMap[ROUTE_CLIENTS_TARGETS_REPORT]
      },
      {
        key: 'menu-statistics-gratis-products-report',
        label: 'Gratis products report',
        icon: <ListIcon />,
        path: ROUTE_GRATIS_PRODUCTS_REPORT,
        permissions: permMap[ROUTE_GRATIS_PRODUCTS_REPORT]
      }
    ]
  }
];

export default menu;
