import { Route } from 'react-router-dom';
import { CURRENCIES_SHOW } from 'api/auth/permissions/Currencies';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import CurrenciesList from 'modules/Currencies/view/List';
import CurrenciesDetails from 'modules/Currencies/view/Details';

export const ROUTE_CURRENCIES_LIST = '/currencies';
export const ROUTE_CURRENCIES_DETAILS = '/currencies/details/:id';

export const permMap = {
  [ROUTE_CURRENCIES_LIST]: [CURRENCIES_SHOW],
  [ROUTE_CURRENCIES_DETAILS]: [CURRENCIES_SHOW]
};

const routes = {
  protected: [
    <Route key='currencies-list' exact path={ROUTE_CURRENCIES_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_CURRENCIES_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <CurrenciesList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='currencies-details' exact path={ROUTE_CURRENCIES_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_CURRENCIES_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <CurrenciesDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
