import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import {
  ROUTE_WHOLESALERS_LIST,
  ROUTE_WHOLESALERS_UPDATE
} from 'routing/routes/Wholesalers';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import {
  WHOLESALERS_UPDATE,
  WHOLESALERS_DELETE
} from 'api/auth/permissions/Wholesalers';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import DeleteWholesalerDialog from 'modules/Wholesalers/component/DeleteWholesalerDialog';
import WholesalersContactsDetails from 'modules/Wholesalers/component/Contacts/Details';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';

class WholesalerDetailsBasicData extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpen: false
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  onDeleteClick() {
    this.setState({ deleteDialogOpen: true });
  }

  onEditClick() {
    const { id } = this.props.wholesaler;
    this.props.history.push(insertPathParams(ROUTE_WHOLESALERS_UPDATE, { id }));
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false });
  }

  onDeleteSuccess() {
    this.props.history.push(ROUTE_WHOLESALERS_LIST);
  }

  getCountry() {
    const { country_id } = this.props.wholesaler;

    if (!country_id) return null;
    const result = this.props.countries.find(
      country => country.id === country_id
    );
    return result && result.name;
  }

  render() {
    const { deleteDialogOpen } = this.state;

    const {
      fetchWholesaler,
      wholesaler: {
        city,
        id,
        main_contact_email,
        name,
        orders_emails,
        orders_email_same_as_main,
        phone,
        postal_code,
        street,
        tax_number,
        contacts,
        emails,
        domains,
        provides_sales_data
      }
    } = this.props;

    return (
      <Grid container spacing={3} justify='center'>
        {!id ? (
          <Loader />
        ) : (
          <>
            <Grid item xs={12} lg={5}>
              <Paper className='p1'>
                <Box display='flex' justifyContent='space-between'>
                  <Box>
                    <Typography
                      component='h6'
                      variant='h6'
                      style={{ marginBottom: '.5rem' }}
                    >
                      {t('General Information')}
                    </Typography>
                    <TextDataSet label='Name' data={name} />
                    <TextDataSet label='Tax number' data={tax_number} />

                    <Typography
                      component='h6'
                      variant='h6'
                      style={{ marginBottom: '.5rem', marginTop: '1rem' }}
                    >
                      {t('Address data')}
                    </Typography>
                    <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
                      <TextDataSet label='Country' data={this.getCountry()} />
                    </Authorize>
                    <TextDataSet label='City' data={city} />
                    <TextDataSet label='Street' data={street} />
                    <TextDataSet label='Postal code' data={postal_code} />

                    <Typography
                      component='h6'
                      variant='h6'
                      style={{ marginBottom: '.5rem', marginTop: '1rem' }}
                    >
                      {t('Main contact details')}
                    </Typography>

                    <TextDataSet
                      label='Main contact email'
                      data={main_contact_email}
                    />
                    {orders_email_same_as_main ? (
                      <TextDataSet
                        label='Orders email'
                        data={t('Same as main')}
                      />
                    ) : (
                      <TextDataSet
                        label='Orders email'
                        data={orders_emails.join(', ')}
                      />
                    )}
                    <TextDataSet label='Phone number' data={phone} />

                    <Typography
                      component='h6'
                      variant='h6'
                      style={{ marginBottom: '.5rem', marginTop: '1rem' }}
                    >
                      {t('Data import')}
                    </Typography>

                    {provides_sales_data ? (
                      <TextDataSet
                        data={<CheckIcon color='primary' />}
                        label='The warehouse provides sales data'
                      />
                    ) : (
                      <TextDataSet
                        data={<BlockIcon color='error' />}
                        label='The warehouse provides sales data'
                      />
                    )}
                    {provides_sales_data && (
                      <>
                        <TextDataSet label='Import emails' data={emails} />
                        <TextDataSet label='Import domains' data={domains} />
                      </>
                    )}
                  </Box>
                  <Box display='flex' alignItems='flex-start'>
                    <Authorize permissions={[WHOLESALERS_UPDATE]}>
                      <IconButton
                        className='update-icon'
                        onClick={this.onEditClick}
                        icon={<EditIcon />}
                        alt='update'
                      />
                    </Authorize>
                    <Authorize permissions={[WHOLESALERS_DELETE]}>
                      <IconButton
                        className='delete-icon'
                        onClick={this.onDeleteClick}
                        icon={<DeleteForeverIcon />}
                        alt='delete'
                      />
                    </Authorize>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={7}>
              <Paper className='p1'>
                <Typography
                  component='h6'
                  variant='h6'
                  style={{ marginBottom: '1rem' }}
                >
                  {t('Contacts data')}
                </Typography>
                <WholesalersContactsDetails
                  wholesalerId={id}
                  contacts={contacts}
                  fetchWholesaler={fetchWholesaler}
                />
              </Paper>
            </Grid>
          </>
        )}

        {deleteDialogOpen && (
          <DeleteWholesalerDialog
            wholesaler={this.props.wholesaler}
            onClose={this.onDeleteDialogClose}
            onSuccess={this.onDeleteSuccess}
          />
        )}
      </Grid>
    );
  }
}

WholesalerDetailsBasicData.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  wholesaler: PropTypes.shape({
    city: PropTypes.string,
    id: PropTypes.number,
    main_contact_email: PropTypes.string,
    name: PropTypes.string,
    orders_emails: PropTypes.arrayOf(PropTypes.string),
    orders_email_same_as_main: PropTypes.bool.isRequired,
    phone: PropTypes.string,
    postal_code: PropTypes.string,
    street: PropTypes.string,
    tax_number: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({})),
    emails: PropTypes.arrayOf(PropTypes.string),
    domains: PropTypes.arrayOf(PropTypes.string),
    country_id: PropTypes.number,
    provides_sales_data: PropTypes.bool.isRequired
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchWholesaler: PropTypes.func.isRequired
};

export default withRouter(WholesalerDetailsBasicData);
