import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';

import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ClientsBonusesApi from 'api/connections/ClientsBonuses/ClientsBonusesApi';

class SetBonusFinalValueDialog extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        final_value: props.value ?? '',
        description: ''
      },
      validation: {
        final_value: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Value')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Final bonus value changed'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onClose();
    this.props.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...state.formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      await ClientsBonusesApi.updateBonusValue(this.props.bonusId, formData);

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { final_value, description },
      validation,
      loading
    } = this.state;

    return (
      <Dialog
        open
        onClose={this.props.onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Set final value')}</DialogTitle>
        <DialogContent>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            <Input
              name='final_value'
              label='Value'
              value={final_value}
              onChange={this.onChange}
              required
              errorStatus={validation.final_value.status}
              errorText={validation.final_value.message}
            />
            <Input
              name='description'
              label='Description'
              value={description}
              onChange={this.onChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.props.onClose} />
          <Button
            form='form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

SetBonusFinalValueDialog.defaultProps = {
  value: ''
};

SetBonusFinalValueDialog.propTypes = {
  bonusId: PropTypes.number.isRequired,
  value: PropTypes.number,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SetBonusFinalValueDialog;
