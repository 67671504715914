export default {
  'All category': 'Wszystkie kategorie',
  'Product categories': 'Kategorie produktowe',
  'Product group': 'Grupa produktowa',
  Group: 'Grupa',
  'Product subcategory': 'Podkategoria produktowa',
  'Create product group': 'Dodawanie grupy produktowej',
  'Create product category': 'Dodawanie kategorii produktowej',
  'Create product subcategory': 'Dodawanie podkategorii produktowej',
  'Update product group': 'Edytowanie grupy produktowej',
  'Update product category': 'Edytowanie kategorii produktowej',
  'Update product subcategory': 'Edytowanie podkategorii produktowej',
  'Product group name': 'Nazwa grupy produktowej',
  'Product category name': 'Nazwa kategorii produktowej',
  'Category/product group cannot be deleted when has at least one department or product':
    'Nie można usunąć kategorii/grupy produktowej która posiada choćby jeden dział lub produkt',
  'Are you sure you want to delete category <%= name %>':
    'Czy na pewno chcesz usunać kategorie <%= name %>',
  'Deleting product category successfully finish':
    'Usuwanie kategorii produktowej zakończone sukcesem',
  Subcategory: 'Podkategoria',
  Subcategories: 'Podkategorie',
  'No categories': 'Brak kategorii',
  'No subcategories': 'Brak podkategorii',
  'Product category': 'Kategoria produktowa',
  'You cannot change the category when there are open sales targets for that category.':
    'Nie można zmienić kategori, kiedy są niezamknięte cele sprzedażowe dla tej kategorii.',
  'You cannot change the group when there are open sales targets for that group.':
    'Nie można zmienić grupy, kiedy są niezamknięte cele sprzedażowe dla tej grupy.',
  'Include the group when calculating goals':
    'Uwzględniaj grupę podczas kalkulacji celów'
};
