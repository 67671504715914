import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import { CURRENCIES_MANAGEMENT } from 'api/auth/permissions/Currencies';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import t from 'translate/translate';

class CurrenciesTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: true,
            disable: ({ name }) => name === 'PLN',
            disableMsg: t('The PLN currency cannot be updated'),
            onClick: this.props.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([CURRENCIES_MANAGEMENT]),
            disable: ({ name }) => name === 'PLN',
            disableMsg: t('The PLN currency cannot be updated'),
            onClick: this.props.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([CURRENCIES_MANAGEMENT]),
            disable: ({ name }) => name === 'PLN',
            disableMsg: t('The PLN currency cannot be updated'),
            onClick: this.props.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { currencies, sort, setSort, loading } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={currencies}
          sort={sort}
          setSort={setSort}
          loading={loading}
          actions
        />
      </>
    );
  }
}

CurrenciesTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDetailsClick: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default CurrenciesTable;
