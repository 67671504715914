import {
  FormHelperText,
  InputLabel,
  MenuItem,
  FormControl,
  Select as MaterialUiSelect,
  Tooltip,
  Box
} from '@material-ui/core';
import t from 'translate/translate';
import PropTypes from 'prop-types';
import { isPlainObject } from 'lodash/lang';
import { withStyles } from '@material-ui/core/styles';

export const formatOptions = (arr, keyName, valueName, translate = false) => {
  if (!arr) return arr;

  return arr.map(item => {
    return {
      key: item[keyName],
      value: translate ? t(item[valueName]) : item[valueName]
    };
  });
};

const useStyles = theme => ({
  list: {
    '& li.Mui-selected': {
      backgroundColor: theme.palette.primary.light
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.primary.main
    },
    maxHeight: '200px'
  },
  selectMenu: {
    height: 'unset'
  }
});

const Select = props => {
  const {
    name,
    label,
    value,
    emptyValueText,
    required,
    onChange,
    options,
    fullWidth,
    multiple,
    errorStatus,
    errorText,
    disabled,
    tooltipMsg,
    margin,
    style
  } = props;
  const labelId = `${name}-label`;
  const borderSelectClasses = props.classes;

  const menuProps = {
    classes: {
      list: borderSelectClasses.list
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  const select = (
    <FormControl
      variant='outlined'
      margin={margin}
      required={required}
      fullWidth={fullWidth}
      error={errorStatus}
      disabled={disabled}
    >
      <InputLabel id={labelId}>{t(label)}</InputLabel>
      <MaterialUiSelect
        labelId={labelId}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        label={t(label)}
        multiple={multiple}
        MenuProps={menuProps}
        style={style.input}
        classes={{ select: style.select, selectMenu: style.selectMenu }}
      >
        {emptyValueText && (
          <MenuItem key={`${name}-emptyValue`} value=''>
            <em>{t(emptyValueText)}</em>
          </MenuItem>
        )}
        {options.map(option => {
          return isPlainObject(option) ? (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          ) : (
            <MenuItem key={option} value={option}>
              {t(option)}
            </MenuItem>
          );
        })}
      </MaterialUiSelect>
      {errorStatus && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );

  const selectWithTooltip = (
    <Tooltip title={t(tooltipMsg)}>
      <Box>{select}</Box>
    </Tooltip>
  );

  if (disabled && tooltipMsg) return selectWithTooltip;

  return select;
};

Select.defaultProps = {
  fullWidth: true,
  value: '',
  emptyValueText: false,
  required: false,
  multiple: false,
  errorStatus: false,
  errorText: null,
  disabled: false,
  tooltipMsg: null,
  margin: 'normal',
  style: {}
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  emptyValueText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  errorStatus: PropTypes.bool,
  errorText: PropTypes.string,
  classes: PropTypes.shape({
    list: PropTypes.string.isRequired
  }).isRequired,
  disabled: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  margin: PropTypes.string,
  style: PropTypes.shape({})
};

export default withStyles(useStyles)(Select);
