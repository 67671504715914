import { Route } from 'react-router-dom';
import { SALES_LIST } from 'api/auth/permissions/Sales';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';
import SalesList from 'modules/Sales/view/List';

export const ROUTE_SALES_LIST = '/sales';

export const permMap = {
  [ROUTE_SALES_LIST]: [SALES_LIST]
};

const routes = {
  protected: [
    <Route key='sales' exact path={ROUTE_SALES_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_SALES_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesList contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
