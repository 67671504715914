import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { Box, Divider, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';
import IconButton from 'modules/Layout/component/IconButton';
import insertPathParams from 'api/utils/insertPathParams';
import AddIcon from '@material-ui/icons/Add';
import DefinePromotionDialog from 'modules/Orders/component/OrderPreparing/DefinePromotionDialog';
import { DeleteForever } from '@material-ui/icons';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';
import clsx from 'clsx';

const useStyles = {
  invalidPromotion: {
    borderColor: '#f44336'
  }
};

class OrderDefinedPromotions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      definePromotionStatus: false
    };

    this.onPromotionDefineClick = this.onPromotionDefineClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
  }

  onPromotionDefineClick() {
    this.setState({
      definePromotionStatus: true
    });
  }

  onDialogClose() {
    this.setState({
      definePromotionStatus: false
    });
  }

  renderDefinedPromotion(promotion, index) {
    const { onPromotionDelete, promotions, products, isDetails } = this.props;

    let isInvalid = false;

    const productsNames = promotion.products.map(id => {
      const { sku, promotions_blocked } = products.find(p => p.id === id);

      if (promotions_blocked) isInvalid = true;

      const redirectLink = insertPathParams(ROUTE_PRODUCTS_DETAILS, { id });

      return (
        <Link
          to={redirectLink}
          className={clsx(
            'router-link router-link-underline',
            promotions_blocked && 'router-link-error'
          )}
          target='_blank'
        >
          {sku}
        </Link>
      );
    });

    return (
      <>
        <Box
          key={`defined-promotion-${index}`}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          width={1}
        >
          <Box display='flex' flexDirection='column'>
            <TextDataSet label={t('Products')} data={productsNames} />
            <TextDataSet
              label={t('Percentage')}
              data={`${promotion.percent}%`}
            />
            {isInvalid && (
              <Typography color='error'>
                {t(
                  'This promotion includes a product that is not available in the promotions'
                )}
              </Typography>
            )}
          </Box>
          {!isDetails && (
            <IconButton
              className='delete-icon'
              icon={<DeleteForever />}
              alt='delete'
              onClick={() => onPromotionDelete(index)}
            />
          )}
        </Box>
        {index !== promotions.length - 1 && <Divider />}
      </>
    );
  }

  renderDefinedPromotions() {
    const { promotions, isDetails, products } = this.props;

    if (isDetails && isEmpty(promotions)) {
      return (
        <Typography variant='subtitle1' color='textSecondary'>
          {t('Order have no promotions')}
        </Typography>
      );
    }

    return (
      <Box width={1}>
        {promotions.map((promotion, index) =>
          this.renderDefinedPromotion(promotion, index)
        )}
        {!isDetails && (
          <Box display='flex' alignItems='center'>
            <IconButton
              onClick={this.onPromotionDefineClick}
              className='create-icon'
              icon={<AddIcon fontSize='large' />}
              alt='define promotion'
              disabled={isEmpty(products)}
              tooltipMsg={t('Select product first')}
            />
            <Typography variant='subtitle1' color='textSecondary'>
              {t('Click on the add button to define promotion.')}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  render() {
    const { definePromotionStatus } = this.state;
    const { products } = this.props;

    return (
      <>
        <Typography component='h6' variant='h6'>
          {t('Promotions define in order')}
        </Typography>
        <Box className='language-form-box' pt={1}>
          {this.renderDefinedPromotions()}
        </Box>
        {definePromotionStatus && (
          <DefinePromotionDialog
            products={products}
            onPromotionDefineSuccess={this.props.onPromotionDefineSuccess}
            onClose={this.onDialogClose}
          />
        )}
      </>
    );
  }
}

OrderDefinedPromotions.defaultProps = {
  isDetails: false,
  onPromotionDefineSuccess: null,
  onPromotionDelete: null
};

OrderDefinedPromotions.propTypes = {
  isDetails: PropTypes.bool,
  promotions: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  onPromotionDefineSuccess: PropTypes.func,
  onPromotionDelete: PropTypes.func,
  classes: PropTypes.shape({
    invalidPromotion: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(withStyles(useStyles)(OrderDefinedPromotions));
