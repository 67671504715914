import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import useStyles from 'modules/Visits/components/shared/styles';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';

const ClientInfoWindow = ({ client, show }) => {
  const classes = useStyles({ show });
  const { name, city, street, postal_code, evaluation } = client;
  return (
    <Box className={classes.infoWindow}>
      <Box display='flex'>
        <Typography className={classes.label}> {t('Client name')}: </Typography>
        <Typography className={classes.paragraph}>{name}</Typography>
      </Box>
      <Box display='flex'>
        <Typography className={classes.label}> {t('Place')}: </Typography>
        <Typography className={classes.paragraph}>{`${city || ''}, ${
          street || ''
        }, ${postal_code || ''}`}</Typography>
      </Box>
      <Typography className={classes.label}>
        {t("Client's evaluation")}:
      </Typography>
      {!isEmpty(evaluation)
        ? evaluation.map(
            ({
              cooperation_evaluation,
              potential_evaluation,
              department_name
            }) => (
              <div key={department_name}>
                <Typography
                  className={classes.label}
                  style={{ paddingLeft: 10 }}
                >
                  {department_name}:
                </Typography>
                <Box display='flex' style={{ paddingLeft: 10 }}>
                  <Typography className={classes.label}>
                    {t('Cooperation')}:
                  </Typography>
                  <Typography
                    style={{ marginRight: 5 }}
                    className={classes.paragraph}
                  >
                    {cooperation_evaluation || t('No data')}
                  </Typography>
                  <Typography className={classes.label}>
                    {t('Potential')}:
                  </Typography>
                  <Typography className={classes.paragraph}>
                    {potential_evaluation || t('No data')}
                  </Typography>
                </Box>
              </div>
            )
          )
        : t('No data')}
    </Box>
  );
};

ClientInfoWindow.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    postal_code: PropTypes.string,
    evaluation: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  show: PropTypes.bool.isRequired
};

export default ClientInfoWindow;
