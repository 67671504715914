import {
  ADMIN,
  CLIENT,
  DIRECTOR,
  getSpecificRoles,
  SUPER_ADMIN
} from 'api/auth/roles';

export default function formatRolesOptions(role) {
  switch (role) {
    case ADMIN: {
      return getSpecificRoles([SUPER_ADMIN, CLIENT]);
    }
    case DIRECTOR: {
      return getSpecificRoles([SUPER_ADMIN, ADMIN, DIRECTOR, CLIENT]);
    }
    default: {
      return getSpecificRoles([CLIENT]);
    }
  }
}
