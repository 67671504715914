import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Link } from '@material-ui/core';
import PublicWrapper from 'modules/Layout/view/PublicWrapper';
import Form from 'modules/Auth/component/Password/Forced/Form';
import { ROUTE_LOGIN } from 'routing/routes/Auth';
import t from 'translate/translate';

import AuthContext from 'modules/Auth/context/Auth/authContext';

const Forced = () => {
  const { user, logout } = useContext(AuthContext);
  const history = useHistory();

  if (!user) {
    history.push(ROUTE_LOGIN);
  }

  return (
    <PublicWrapper>
      <Form user={user} />
      <Box>
        <Link to='#!' onClick={() => logout()} className='router-link'>
          <Typography color='primary'>{t('Log out')}</Typography>
        </Link>
      </Box>
    </PublicWrapper>
  );
};

export default Forced;
