import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import WholesalerFilesApi from 'api/connections/Wholesalers/WholesalersFilesApi';

const DeleteWholesalerFileDialog = props => {
  const {
    file: { id: fileId, name: fileName },
    wholesalerId,
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteMapping = async () => {
    try {
      await WholesalerFilesApi.deleteWholesalerFile({
        wholesalerId,
        fileId
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess(fileId);
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete file <%=fileName%>', {
        fileName
      })}
      onApprove={deleteMapping}
      onCancel={onClose}
    />
  );
};

DeleteWholesalerFileDialog.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  wholesalerId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default DeleteWholesalerFileDialog;
