import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN
} from 'api/auth/roles';

export const QUANTITATIVE_TARGET_TYPE = 1;
export const VALUABLE_TARGET_TYPE = 2;
export const QUANTITATIVE_MANAGERS_TARGET_TYPE = 'qm';
export const VALUABLE_MANAGERS_TARGET_TYPE = 'vm';

const targetOptions = [
  { id: QUANTITATIVE_TARGET_TYPE, name: 'quantitative' },
  { id: VALUABLE_TARGET_TYPE, name: 'valuable' },
  { id: QUANTITATIVE_MANAGERS_TARGET_TYPE, name: 'quantitative for managers' },
  { id: VALUABLE_MANAGERS_TARGET_TYPE, name: 'valuable for managers' }
];

const basicTargetOptions = [
  { id: QUANTITATIVE_TARGET_TYPE, name: 'quantitative' },
  { id: VALUABLE_TARGET_TYPE, name: 'valuable' }
];

export const getTargetOptionsAvailableForRole = (role, isList = false) => {
  if (
    [SUPER_ADMIN, ADMIN, DIRECTOR].includes(role) ||
    (role === DEPARTMENT_MANAGER && isList)
  ) {
    return targetOptions;
  }

  return basicTargetOptions;
};

export default targetOptions;
