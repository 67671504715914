import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Input from 'modules/Layout/component/Input';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Button from 'modules/Layout/component/Button';
import { Box, Typography } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import { isArray, isEmpty } from 'lodash/lang';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';
import { ROUTE_SHOPPING_GROUPS_DETAILS } from 'routing/routes/ShoppingGroups';
import discountsTypes, {
  PERCENTAGE_DISCOUNTS,
  PAYMENT_TYPE_DISCOUNTS,
  THRESHOLD_DISCOUNTS
} from 'modules/Discounts/discountsTypes';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import DiscountsApi from 'api/connections/Discounts/DiscountsApi';
import CategoryPicker from 'modules/Discounts/component/CategoryPicker';

import ThresholdsDiscounts from 'modules/Discounts/component/ThresholdsDiscounts';
import {
  ROUTE_DISCOUNTS_DETAILS,
  ROUTE_DISCOUNTS_LIST
} from 'routing/routes/Discounts';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  KEY_ACCOUNT_MANAGER,
  SUPER_ADMIN,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';

const recipientTypes = [
  { slug: 'client', name: 'Client' },
  { slug: 'salesGroup', name: 'Shopping group' }
];

class DiscountForm extends Component {
  static contextType = AuthContext;

  static setRecipientType(discount, clientId, salesGroupId, loggedUserRole) {
    if (discount) {
      return discount.client_id ? 'client' : 'salesGroup';
    }
    if (![SUPER_ADMIN, ADMIN, DIRECTOR].includes(loggedUserRole)) {
      return 'client';
    }
    if (clientId) return 'client';
    if (salesGroupId) return 'salesGroup';
    return '';
  }

  static setData(discount, clientId, clientName, salesGroupId, salesGroupName) {
    let data = {};

    if (discount) {
      if (discount.client_id) {
        data.clients = [{ id: discount.client_id, name: discount.client_name }];
      } else {
        data.salesGroups = [
          { id: discount.sales_group_id, name: discount.sales_group_name }
        ];
      }
    }

    if (salesGroupId) {
      data.salesGroups = [{ id: salesGroupId, name: salesGroupName }];
    }

    if (clientName) {
      data.clients = [{ id: clientId, name: clientName }];
    }

    return data;
  }

  constructor(props, context) {
    super(props, context);

    const {
      discount,
      clientId,
      clientName,
      salesGroupId,
      salesGroupName
    } = props;

    this.loggedUserRole = context.user.role;

    this.state = {
      formData: {
        country_id: '',
        client_id: discount?.client_id || clientId,
        sales_group_id: discount?.sales_group_id || salesGroupId,
        name: discount?.name || '',
        type: discount?.type || discount?.type === 0 ? discount?.type : '',
        groupsIds: discount?.groups.map(g => g.id) || [],
        categoriesIds: discount?.categories.map(c => c.id) || [],
        subcategoriesIds: discount?.subcategories.map(s => s.id) || [],
        percentage:
          discount?.type_data.percentage || discount?.type_data.percentage === 0
            ? discount?.type_data.percentage * 100
            : '',
        thresholds:
          isArray(discount?.type_data) && !isEmpty(discount?.type_data)
            ? discount?.type_data.map(td => ({
                ...td,
                percentage: td.percentage * 100
              }))
            : []
      },
      recipientType: DiscountForm.setRecipientType(
        discount,
        clientId,
        salesGroupId,
        this.loggedUserRole
      ),
      data: DiscountForm.setData(
        discount,
        clientId,
        clientName,
        salesGroupId,
        salesGroupName
      ),
      validation: {
        recipientType: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Recipient type')
          })
        },
        client_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Client')
          })
        },
        sales_group_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Shopping group')
          })
        },
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        },
        type: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Type')
          })
        },
        percentage: {
          status: false,
          message: t(
            'Field Discount value is required and should be between 0-99'
          )
        },
        thresholds: {
          status: false
        }
      },
      loading: false
    };

    this.redirectToSpecificPlace = this.redirectToSpecificPlace.bind(this);
    this.onRecipientTypeChange = this.onRecipientTypeChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onThresholdsChange = this.onThresholdsChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onCategoryPick = this.onCategoryPick.bind(this);
  }

  componentDidMount() {
    const { salesGroupId, clientId, discount } = this.props;

    const isUpdate = Boolean(salesGroupId || clientId || discount);

    if (
      ![SUPER_ADMIN, ADMIN, DIRECTOR].includes(this.loggedUserRole) &&
      !isUpdate
    ) {
      this.fetchClients();
    }
    if (
      this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) &&
      !isUpdate
    ) {
      this.fetchData();
    }
  }

  handleResponse(res) {
    const { id } = res.data.data;

    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.redirectToSpecificPlace(id);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        ...state,
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onThresholdsChange(thresholds) {
    this.setState(state => {
      return {
        ...state,
        formData: {
          ...state.formData,
          thresholds
        },
        validation: {
          ...state.validation,
          thresholds: {
            status: false
          }
        }
      };
    });
  }

  onRecipientTypeChange(e) {
    const { value } = e.target;

    this.setState(
      state => {
        const { validation } = state;

        return {
          ...state,
          recipientType: value,
          validation: {
            ...validation,
            recipientType: {
              ...validation.recipientType,
              status: false
            }
          }
        };
      },
      () => {
        if (value === 'client' && !this.state.data?.client) {
          this.fetchClients();
        }
        if (value === 'salesGroup' && !this.state.data?.salesGroups) {
          this.fetchSalesGroups();
        }
      }
    );
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onChange(event);
  }

  onCategoryPick(name, values) {
    this.setState(state => {
      let { groupsIds, categoriesIds, subcategoriesIds } = state.formData;

      const formatValues = values.map(v => v.key);

      if (name === 'group') groupsIds = formatValues;
      if (name === 'category') categoriesIds = formatValues;
      if (name === 'subcategory') subcategoriesIds = formatValues;

      return {
        ...state,
        formData: {
          ...state.formData,
          groupsIds,
          categoriesIds,
          subcategoriesIds
        }
      };
    });
  }

  getClients() {
    const { country_id } = this.state.formData;
    const { clients = [] } = this.state.data;

    if (
      this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) &&
      country_id
    ) {
      return clients.filter(client => client.country_id === country_id);
    }

    return clients;
  }

  getSalesGroups() {
    const { country_id } = this.state.formData;
    const { salesGroups = [] } = this.state.data;

    if (
      this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) &&
      country_id
    ) {
      return salesGroups.filter(group => group.country_id === country_id);
    }

    return salesGroups;
  }

  redirectToSpecificPlace(id) {
    const { discount, clientId, salesGroupId } = this.props;

    let path =
      id || discount
        ? insertPathParams(ROUTE_DISCOUNTS_DETAILS, { id: id || discount.id })
        : ROUTE_DISCOUNTS_LIST;

    if (clientId) {
      path = insertPathParams(ROUTE_CLIENTS_DETAILS, {
        id: clientId
      });

      path += '?tab_index=discounts';
    }

    if (salesGroupId) {
      path = insertPathParams(ROUTE_SHOPPING_GROUPS_DETAILS, {
        id: salesGroupId
      });

      path += '?tab_index=discounts';
    }

    this.props.history.push(path);
  }

  async fetchData() {
    try {
      const {
        data: { data: countries }
      } = await PostalCodesApi.getCountries({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState(state => ({
        data: { ...state.data, countries }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchClients() {
    try {
      const {
        data: { data: clients }
      } = await ClientsApi.getAllClients({
        my_clients: [
          DEPARTMENT_MANAGER,
          KEY_ACCOUNT_MANAGER,
          TELEMARKETER,
          TRADER
        ].includes(this.loggedUserRole)
      });

      this.setState(state => ({
        data: { ...state.data, clients }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchSalesGroups() {
    try {
      const {
        data: { data: salesGroups }
      } = await SalesGroupsApi.getSalesGroups({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState(state => ({
        data: { ...state.data, salesGroups }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  runValidation() {
    /* eslint prefer-const: "off" */
    const {
      formData,
      formData: { type, thresholds },
      recipientType,
      validation
    } = this.state;
    let [isValid, newValidateState] = validate(
      { ...formData, recipientType },
      validation
    );

    if (type === THRESHOLD_DISCOUNTS && isEmpty(thresholds)) {
      newValidateState.thresholds.status = true;
      isValid = false;
    }

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const {
      formData,
      formData: {
        type,
        percentage,
        thresholds,
        groupsIds,
        categoriesIds,
        subcategoriesIds
      }
    } = this.state;
    const { discount } = this.props;

    this.setState({ loading: true });

    const payload = {
      ...formData,
      percentage: percentage / 100,
      thresholds: thresholds.map(td => ({
        ...td,
        percentage: td.percentage / 100
      })),
      categories: [...groupsIds, ...categoriesIds, ...subcategoriesIds]
    };

    try {
      let res = null;

      if (type === 0) {
        res = discount
          ? await DiscountsApi.updatePercentageDiscount(discount.id, payload)
          : await DiscountsApi.createPercentageDiscount(payload);
      }
      if (type === 1) {
        res = discount
          ? await DiscountsApi.updateThresholdDiscount(discount.id, payload)
          : await DiscountsApi.createThresholdDiscount(payload);
      }
      if (type === 2) {
        res = discount
          ? await DiscountsApi.updatePaymentDiscount(discount.id, payload)
          : await DiscountsApi.createPaymentDiscount(payload);
      }

      this.setState({ loading: false });
      this.handleResponse(res);
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        country_id,
        client_id,
        sales_group_id,
        name,
        type,
        percentage,
        thresholds,
        groupsIds,
        categoriesIds,
        subcategoriesIds
      },
      recipientType,
      validation,
      data,
      loading
    } = this.state;
    const { discount, clientId, salesGroupId } = this.props;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <>
          <Input
            name='name'
            label='Name'
            value={name}
            required
            onChange={this.onChange}
            errorStatus={validation.name.status}
            errorText={validation.name.message}
          />
          <Typography component='h6' variant='h6'>
            {t('Restriction to categories')}
          </Typography>
          <Box className='language-form-box'>
            <Box mt={1} ml={1}>
              <Typography variant='body1' color='textSecondary'>
                {t('Select a category covered by discount.')}
              </Typography>
            </Box>
            <CategoryPicker
              onChange={this.onCategoryPick}
              groupsIds={groupsIds}
              categoriesIds={categoriesIds}
              subcategoriesIds={subcategoriesIds}
            />
          </Box>
          {[SUPER_ADMIN, ADMIN, DIRECTOR].includes(this.loggedUserRole) && (
            <Select
              name='recipientType'
              label='Recipient type'
              value={recipientType}
              required
              options={formatOptions(recipientTypes, 'slug', 'name', true)}
              onChange={this.onRecipientTypeChange}
              errorStatus={validation.recipientType.status}
              errorText={validation.recipientType.message}
              disabled={Boolean(salesGroupId || clientId || discount)}
            />
          )}
          {recipientType &&
            this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
              <Autocomplete
                name='country_id'
                label='Country'
                value={country_id}
                options={formatOptions(data?.countries || [], 'id', 'name')}
                onChange={(_, v) => this.onAutocompleteChange('country_id', v)}
                loading={
                  !data.countries &&
                  Boolean(discount || clientId || salesGroupId) !== true
                }
                disabled={Boolean(discount || clientId)}
              />
            )}
          {recipientType === 'client' && (
            <Autocomplete
              name='client_id'
              label='Client'
              value={client_id}
              options={formatOptions(this.getClients(), 'id', 'name')}
              onChange={(_, v) => this.onAutocompleteChange('client_id', v)}
              required
              errorStatus={validation.client_id.status}
              errorText={validation.client_id.message}
              loading={!data.clients}
              disabled={Boolean(discount || clientId)}
            />
          )}
          {recipientType === 'salesGroup' && (
            <Autocomplete
              name='sales_group_id'
              label='Shopping group'
              value={sales_group_id}
              options={formatOptions(this.getSalesGroups(), 'id', 'name')}
              onChange={(_, v) =>
                this.onAutocompleteChange('sales_group_id', v)
              }
              required
              errorStatus={validation.sales_group_id.status}
              errorText={validation.sales_group_id.message}
              loading={!data.salesGroups}
              disabled={Boolean(discount || salesGroupId)}
            />
          )}
          {recipientType && (
            <Select
              name='type'
              label='Type'
              value={type}
              required
              options={formatOptions(discountsTypes, 'id', 'name', true)}
              onChange={this.onChange}
              errorStatus={validation.type.status}
              errorText={validation.type.message}
              disabled={Boolean(discount)}
            />
          )}

          {(type === PERCENTAGE_DISCOUNTS ||
            type === PAYMENT_TYPE_DISCOUNTS) && (
            <Input
              name='percentage'
              label='Discount value'
              type='number'
              inputProps={{
                min: 0,
                max: 99
              }}
              value={percentage}
              required
              onChange={this.onChange}
              errorStatus={validation.percentage.status}
              errorText={validation.percentage.message}
            />
          )}

          {type === THRESHOLD_DISCOUNTS && (
            <ThresholdsDiscounts
              thresholds={thresholds}
              onChange={this.onThresholdsChange}
              isAllInvalid={validation.thresholds.status}
            />
          )}
        </>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button
            text={t('Cancel')}
            onClick={() => this.redirectToSpecificPlace()}
          />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

DiscountForm.defaultProps = {
  discount: null,
  clientId: null,
  clientName: null,
  salesGroupId: null,
  salesGroupName: null
};

DiscountForm.propTypes = {
  discount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    type: PropTypes.number.isRequired,
    client_id: PropTypes.number,
    country_id: PropTypes.number,
    sales_group_id: PropTypes.number,
    percentage: PropTypes.number,
    thresholds: PropTypes.arrayOf(PropTypes.shape({})),
    type_data: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.shape({})
    ]),
    groups: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    categories: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    subcategories: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  }),
  clientId: PropTypes.number,
  clientName: PropTypes.string,
  salesGroupId: PropTypes.number,
  salesGroupName: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(DiscountForm);
