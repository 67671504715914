import Filter from 'modules/Layout/component/List/Filter';

class SaleGroupClientsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: 'Organizational data',
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('tax_number'),
            label: 'Tax number',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('address'),
            label: 'Address',
            type: 'text'
          }
        ]
      }
    ];
  }
}

export default SaleGroupClientsFilter;
