import { Route } from 'react-router-dom';
import {
  STATISTICS_READ,
  STATISTICS_TRADER_RESULTS_READ
} from 'api/auth/permissions/Statistics';
import { INTERNET_CLIENTS_LIST } from 'api/auth/permissions/Clients';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import StatisticsClientsOrdersList from 'modules/Statistics/ClientsOrders/view/List';
import StatisticsInternetClientsSalesList from 'modules/Statistics/InternetClientsReport/view/List';
import StatisticsClientsSalesList from 'modules/Statistics/ClientsSales/view/List';
import StatisticsProductsSalesList from 'modules/Statistics/ProductsSales/view/List';
import StatisticsPriorityProductsSalesList from 'modules/Statistics/PriorityProductsSales/view/List';
import StatisticsUsersFinishedVisitsList from 'modules/Statistics/UsersFinishedVisits/view/List';
import StatisticsWholesalersSalesList from 'modules/Statistics/WholesalersSales/view/List';
import StatisticsUsersOrdersValueList from 'modules/Statistics/UsersOrdersValue/view/List';
import StatisticsUsersVisitsList from 'modules/Statistics/UsersVisits/view/List';
import StatisticsUsersOrdersVisitsList from 'modules/Statistics/UsersOrdersVisits/view/List';
import StatisticsUsersVisitsClientsList from 'modules/Statistics/UsersVisitsClients/view/List';
import StatisticsUsersVisitsComparisonList from 'modules/Statistics/UsersVisitsComparison/view/List';
import StatisticsSalesComparisonList from 'modules/Statistics/SalesComparison/view/Comparison';
import StatisticsTraderVisitsReport from 'modules/Statistics/TradersVisitsReport/view/List';
import StatisticsClientsTargetsReport from 'modules/Statistics/ClientsTargetsReport/view/List';
import StatisticsGratisProductsReport from 'modules/Statistics/GratisProductsReport/view/List';

export const ROUTE_STATISTICS_CLIENTS_ORDERS_LIST =
  '/statistics_clients_orders';
export const ROUTE_STATISTICS_INTERNET_CLIENTS_REPORT_LIST =
  '/statistics_internet_clients_orders';
export const ROUTE_STATISTICS_CLIENTS_SALES_LIST = '/statistics_clients_sales';
export const ROUTE_STATISTICS_PRODUCTS_SALES_LIST =
  '/statistics_products_sales';
export const ROUTE_STATISTICS_PRIORITY_PRODUCTS_SALES_LIST =
  '/statistics_priority_products_sales';
export const ROUTE_STATISTICS_USERS_FINISHED_VISITS_LIST =
  '/statistics_users_finished_visits';
export const ROUTE_STATISTICS_WHOLESALERS_SALES_LIST =
  '/statistics_wholesalers_sales';
export const ROUTE_STATISTICS_USERS_ORDERS_VALUE_LIST =
  '/statistics_users_orders';
export const ROUTE_STATISTICS_USERS_VISITS_LIST = '/statistics_users_visits';
export const ROUTE_STATISTICS_USERS_ORDERS_VISITS_LIST =
  '/statistics_users_orders_during_visits';
export const ROUTE_STATISTICS_USERS_VISITS_CLIENTS_LIST =
  '/statistics_of_visited_active_clients';
export const ROUTE_STATISTICS_USERS_VISITS_COMPARISON_LIST =
  '/statistics_users_visits_comparison';
export const ROUTE_STATISTICS_SALES_COMPARISON_LIST =
  '/statistics_sales_comparison';
export const ROUTE_TRADERS_VISITS_REPORT = '/traders_visits_report';
export const ROUTE_CLIENTS_TARGETS_REPORT = '/clients_targets_report';
export const ROUTE_GRATIS_PRODUCTS_REPORT = '/gratis_products_report';

export const permMap = {
  [ROUTE_STATISTICS_CLIENTS_ORDERS_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_INTERNET_CLIENTS_REPORT_LIST]: [INTERNET_CLIENTS_LIST],
  [ROUTE_STATISTICS_CLIENTS_SALES_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_PRODUCTS_SALES_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_PRIORITY_PRODUCTS_SALES_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_USERS_FINISHED_VISITS_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_USERS_FINISHED_VISITS_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_WHOLESALERS_SALES_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_USERS_ORDERS_VALUE_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_USERS_VISITS_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_USERS_ORDERS_VISITS_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_USERS_VISITS_CLIENTS_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_USERS_VISITS_COMPARISON_LIST]: [STATISTICS_READ],
  [ROUTE_STATISTICS_SALES_COMPARISON_LIST]: [STATISTICS_READ],
  [ROUTE_TRADERS_VISITS_REPORT]: [STATISTICS_TRADER_RESULTS_READ],
  [ROUTE_CLIENTS_TARGETS_REPORT]: [STATISTICS_READ],
  [ROUTE_GRATIS_PRODUCTS_REPORT]: [STATISTICS_READ]
};

const routes = {
  protected: [
    <Route
      key='statistics-clients-orders-list'
      exact
      path={ROUTE_STATISTICS_CLIENTS_ORDERS_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_CLIENTS_ORDERS_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => <StatisticsClientsOrdersList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-internet-clients-orders-list'
      exact
      path={ROUTE_STATISTICS_INTERNET_CLIENTS_REPORT_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_INTERNET_CLIENTS_REPORT_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsInternetClientsSalesList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-clients-sales-list'
      exact
      path={ROUTE_STATISTICS_CLIENTS_SALES_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_CLIENTS_SALES_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => <StatisticsClientsSalesList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-products-sales-list'
      exact
      path={ROUTE_STATISTICS_PRODUCTS_SALES_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_PRODUCTS_SALES_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => <StatisticsProductsSalesList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-priority-products-sales-list'
      exact
      path={ROUTE_STATISTICS_PRIORITY_PRODUCTS_SALES_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_PRIORITY_PRODUCTS_SALES_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsPriorityProductsSalesList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-users-finished-visits-list'
      exact
      path={ROUTE_STATISTICS_USERS_FINISHED_VISITS_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_USERS_FINISHED_VISITS_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsUsersFinishedVisitsList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-wholesalers-sales-list'
      exact
      path={ROUTE_STATISTICS_WHOLESALERS_SALES_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_WHOLESALERS_SALES_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsWholesalersSalesList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-users-orders-value-list'
      exact
      path={ROUTE_STATISTICS_USERS_ORDERS_VALUE_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_USERS_ORDERS_VALUE_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsUsersOrdersValueList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-users-visits-list'
      exact
      path={ROUTE_STATISTICS_USERS_VISITS_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_USERS_VISITS_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => <StatisticsUsersVisitsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-users-orders-visits-list'
      exact
      path={ROUTE_STATISTICS_USERS_ORDERS_VISITS_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_USERS_ORDERS_VISITS_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsUsersOrdersVisitsList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics_of_visited_active_clients'
      exact
      path={ROUTE_STATISTICS_USERS_VISITS_CLIENTS_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_USERS_VISITS_CLIENTS_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsUsersVisitsClientsList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-users-visits-comparison-list'
      exact
      path={ROUTE_STATISTICS_USERS_VISITS_COMPARISON_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_USERS_VISITS_COMPARISON_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsUsersVisitsComparisonList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='statistics-sales-comparison-list'
      exact
      path={ROUTE_STATISTICS_SALES_COMPARISON_LIST}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_STATISTICS_SALES_COMPARISON_LIST]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsSalesComparisonList contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route key='traders-visit-report' exact path={ROUTE_TRADERS_VISITS_REPORT}>
      <Authorize redirect permissions={permMap[ROUTE_TRADERS_VISITS_REPORT]}>
        <BasicContextConsumerWrapper
          component={fn => <StatisticsTraderVisitsReport contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients_targets_report'
      exact
      path={ROUTE_CLIENTS_TARGETS_REPORT}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_TARGETS_REPORT]}>
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsClientsTargetsReport contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='gratis_products_report'
      exact
      path={ROUTE_GRATIS_PRODUCTS_REPORT}
    >
      <Authorize redirect permissions={permMap[ROUTE_GRATIS_PRODUCTS_REPORT]}>
        <BasicContextConsumerWrapper
          component={fn => (
            <StatisticsGratisProductsReport contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
