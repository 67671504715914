import { useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import useStyles from 'modules/Layout/component/Alerts/style';

import AlertContext from 'modules/Shared/context/Alert/alertContext';

const Alerts = () => {
  const classes = useStyles();
  const { alerts } = useContext(AlertContext);

  return (
    <div className={classes.root}>
      {alerts.length > 0 &&
        alerts.map(alert => (
          <Alert severity={alert.variant} key={alert.id}>
            {alert.value}
          </Alert>
        ))}
    </div>
  );
};

export default Alerts;
