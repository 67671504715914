/* eslint no-shadow: "off" */
import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import { Box } from '@material-ui/core';
import DatePicker, {
  API_DATE_FORMAT
} from 'modules/Layout/component/Date/DatePicker';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import { formatOptions } from 'modules/Layout/component/Select';
import {
  POSTAL_CODES_COUNTRIES_SHOW,
  POSTAL_CODES_REGIONS_SHOW,
  POSTAL_CODES_SHOW
} from 'api/auth/permissions/PostalCodes';
import Accordion from 'modules/Layout/component/Accordion';
import _ from 'lodash';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { isEmpty } from 'lodash/lang';
import parse from 'date-fns/parse';
import addYears from 'date-fns/addYears';
import Authorize from 'modules/Auth/component/Authorize';

class CurrentComparisonSalesResultsFilter extends Component {
  static contextType = AuthContext;

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = _.isArray(res) ? res.map(v => v.key) : res.key;
    }

    this.props.onFilterChange({ target: { name, value } });
  }

  setDisableStatus(propertyName) {
    const {
      client_id,
      sales_group_id,
      regions,
      subregions,
      subsubregions
    } = this.props.filter;

    switch (propertyName) {
      case 'client_id': {
        return (
          Boolean(sales_group_id) ||
          !isEmpty(regions) ||
          !isEmpty(subregions) ||
          !isEmpty(subsubregions)
        );
      }
      case 'sales_group_id': {
        return (
          Boolean(client_id) ||
          !isEmpty(regions) ||
          !isEmpty(subregions) ||
          !isEmpty(subsubregions)
        );
      }
      case 'country_id':
      case 'regions':
      case 'subregions':
      case 'subsubregions': {
        return Boolean(client_id) || Boolean(sales_group_id);
      }
      default:
        return null;
    }
  }

  render() {
    const {
      clientsData,
      salesGroupsData,
      countriesData,
      regionsData,
      subregionsData,
      subsubregionsData
    } = this.props.filterData;

    const {
      date_from,
      date_to,
      client_id,
      sales_group_id,
      country_id,
      regions,
      subregions,
      subsubregions
    } = this.props.filter;

    const { filterTitle, clientsLoading, regionsLoading } = this.props;

    return (
      <Accordion label={t(filterTitle)}>
        <Box>
          <DatePicker
            name='date_from'
            label='Date from'
            value={date_from}
            onChange={this.props.onFilterChange}
            maxDate={
              date_to
                ? parse(date_to, API_DATE_FORMAT, new Date())
                : addYears(new Date(), 20)
            }
          />
          <DatePicker
            name='date_to'
            label='Date to'
            value={date_to}
            onChange={this.props.onFilterChange}
            minDate={
              date_from
                ? parse(date_from, API_DATE_FORMAT, new Date())
                : addYears(new Date(), -20)
            }
          />
          <Autocomplete
            name='client_id'
            label='Client'
            value={client_id}
            onChange={(e, v) => this.onAutocompleteChange('client_id', v)}
            options={formatOptions(clientsData ?? [], 'id', 'name')}
            loading={clientsLoading}
            disabled={this.setDisableStatus('client_id')}
          />
          <Autocomplete
            name='sales_group_id'
            label='Shopping group'
            value={sales_group_id}
            options={formatOptions(salesGroupsData ?? [], 'id', 'name')}
            onChange={(_, v) => this.onAutocompleteChange('sales_group_id', v)}
            loading={clientsLoading}
            disabled={this.setDisableStatus('sales_group_id')}
          />
          {this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
            <Autocomplete
              label='Country'
              name='country_id'
              value={country_id}
              options={formatOptions(countriesData ?? [], 'id', 'name')}
              onChange={(_, v) => this.onAutocompleteChange('country_id', v)}
              loading={regionsLoading}
              disabled={this.setDisableStatus('country_id')}
            />
          )}
          <Authorize permissions={[POSTAL_CODES_REGIONS_SHOW]}>
            <Autocomplete
              label='Region'
              name='regions'
              value={regions}
              options={formatOptions(regionsData ?? [], 'id', 'name')}
              onChange={(_, v) => this.onAutocompleteChange('regions', v)}
              multiple
              loading={regionsLoading}
              disabled={this.setDisableStatus('regions')}
            />
          </Authorize>
          <Authorize permissions={[POSTAL_CODES_SHOW]}>
            <Autocomplete
              label='Subregion'
              name='subregions'
              value={subregions}
              options={formatOptions(subregionsData ?? [], 'id', 'name')}
              onChange={(_, v) => this.onAutocompleteChange('subregions', v)}
              multiple
              loading={regionsLoading}
              disabled={this.setDisableStatus('subregions')}
            />
            <Autocomplete
              label='Sub subregion'
              name='subsubregions'
              value={subsubregions}
              options={formatOptions(subsubregionsData ?? [], 'id', 'name')}
              onChange={(_, v) => this.onAutocompleteChange('subsubregions', v)}
              multiple
              loading={regionsLoading}
              disabled={this.setDisableStatus('subsubregions')}
            />
          </Authorize>
        </Box>
      </Accordion>
    );
  }
}

CurrentComparisonSalesResultsFilter.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    date_from: PropTypes.string,
    date_to: PropTypes.string,
    client_id: PropTypes.number,
    sales_group_id: PropTypes.number,
    country_id: PropTypes.number,
    regions: PropTypes.arrayOf(PropTypes.number),
    subregions: PropTypes.arrayOf(PropTypes.number),
    subsubregions: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  filterData: PropTypes.shape({
    clientsData: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    salesGroupsData: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    countriesData: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    regionsData: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    subregionsData: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    subsubregionsData: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  }).isRequired,
  clientsLoading: PropTypes.bool.isRequired,
  regionsLoading: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired
};

export default CurrentComparisonSalesResultsFilter;
