export const comparisonTypes = {
  'direct-wholesalers-summary': 'direct-wholesalers-summary',
  'direct-wholesalers': 'direct-wholesalers',
  wholesalers: 'wholesalers'
};

export const isWholesalerNeeded = type => {
  return [
    comparisonTypes['direct-wholesalers'],
    comparisonTypes.wholesalers
  ].includes(type);
};

export const comparisonTypesSelect = [
  {
    label: 'Direct sales and total wholesalers sales comparison',
    slug: comparisonTypes['direct-wholesalers-summary']
  },
  {
    label: 'Direct sales and indicated wholesalers sales comparison',
    slug: comparisonTypes['direct-wholesalers']
  },
  {
    label: 'Wholesalers sales comparison',
    slug: comparisonTypes.wholesalers
  }
];
