import { useState } from 'react';
import PropTypes from 'prop-types';
import MuiPickersProvider from 'modules/Layout/component/Date/MuiPickersProvider';
import t from 'translate/translate';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import addYears from 'date-fns/addYears';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import TimePicker from 'modules/Layout/component/Date/TimePicker';
import { Box } from '@material-ui/core';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';

export const API_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';

const parseDateString = (dateStr, dateFormat) => {
  return parse(dateStr, dateFormat ?? API_DATE_TIME_FORMAT, new Date());
};

const DateTimePicker = props => {
  const {
    name,
    label,
    value,
    initValueNow,
    onChange,
    required,
    errorStatus,
    errorText,
    variant,
    minDate,
    dateFormat,
    disabled,
    resultDateformat
  } = props;
  const [selectedDate, handleDateChange] = useState(() => {
    if (initValueNow) return new Date();
    if (value) return parseDateString(value, dateFormat);
    return null;
  });

  const OnDateChange = date => {
    handleDateChange(date);
    const formattedDate = format(date, dateFormat ?? API_DATE_TIME_FORMAT);

    onChange({
      target: {
        name,
        value: formattedDate
      }
    });
  };

  const onTimeChange = ({ hours, minutes }) => {
    let newDate = setHours(selectedDate, hours);
    newDate = setMinutes(newDate, minutes);

    OnDateChange(newDate);
  };

  return (
    <MuiPickersProvider>
      <Box display='flex' alignItems='center'>
        <Box width={3 / 4}>
          <MuiDatePicker
            name={name}
            margin='normal'
            inputVariant='outlined'
            variant={variant}
            autoOk
            fullWidth
            ampm={false}
            invalidDateMessage={t(
              `Wrong Date format, correct one is ${API_DATE_TIME_FORMAT}`
            )}
            label={t(label)}
            value={selectedDate}
            onChange={OnDateChange}
            required={required}
            error={errorStatus}
            helperText={errorStatus && errorText}
            minDate={minDate}
            disabled={disabled}
            format={resultDateformat}
          />
        </Box>
        <Box minWidth={1 / 4} flexShrink={0} mt={1.5}>
          <TimePicker date={selectedDate} onChange={onTimeChange} />
        </Box>
      </Box>
    </MuiPickersProvider>
  );
};

DateTimePicker.defaultProps = {
  value: null,
  initValueNow: false,
  required: false,
  errorStatus: false,
  errorText: '',
  variant: 'inline',
  minDate: addYears(new Date(), -20),
  dateFormat: null,
  disabled: false,
  resultDateformat: 'dd.MM.yyyy'
};

DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  initValueNow: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  errorStatus: PropTypes.bool,
  errorText: PropTypes.string,
  variant: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  resultDateformat: PropTypes.string
};

export default DateTimePicker;
