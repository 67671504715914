import BaseApiConfig from 'api/connections/BaseApiConfig';

class SalesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.salesPath = 'sales';
  }

  getSales(pathParams) {
    return this.get(this.salesPath, pathParams);
  }
}

export default new SalesApi();
