import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { setColor } from 'modules/Visits/utils/clientStatusDot';

const generateCols = notVisitedClientsCheckbox => {
  return notVisitedClientsCheckbox
    ? [
        {
          property: 'iconStatus',
          label: 'Time from last visit',
          sortable: false,
          render: value => {
            return (
              <FiberManualRecordIcon
                fontSize='small'
                style={{ color: setColor(value) }}
              />
            );
          }
        },
        {
          property: 'name',
          label: 'Client',
          sortable: false
        },
        {
          property: 'city',
          label: 'City',
          sortable: false
        },
        {
          property: 'street',
          label: 'Street',
          sortable: false
        },
        {
          property: 'latest_visit_date',
          label: 'Latest visit',
          sortable: false
        }
      ]
    : [
        {
          property: 'name',
          label: 'Name',
          sortable: false
        },
        {
          property: 'city',
          label: 'City',
          sortable: false
        },
        {
          property: 'street',
          label: 'Street',
          sortable: false
        }
      ];
};

const ClientsTable = ({
  clients,
  notVisitedClientsCheckbox,
  loading,
  onClientChangeFn
}) => {
  const [client, setClient] = useState(null);

  const getCols = () => {
    return generateCols(notVisitedClientsCheckbox);
  };

  const onSelect = clientId => {
    const chosenClient = clients.find(({ id }) => id === clientId);
    setClient(chosenClient);
    onClientChangeFn(chosenClient);
  };

  return (
    <>
      <Table
        cols={getCols()}
        rows={clients}
        hover
        dense
        loading={loading}
        onSelect={onSelect}
        selected={[client?.id]}
      />
    </>
  );
};

ClientsTable.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  notVisitedClientsCheckbox: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClientChangeFn: PropTypes.func.isRequired
};

export default withRouter(ClientsTable);
