import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  WHOLESALERS_UPDATE,
  WHOLESALERS_DELETE
} from 'api/auth/permissions/Wholesalers';
import renderAddress from 'modules/Clients/utils/renderAddress';

class WholesalersAgenciesTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'email',
        label: 'Email',
        sortable: false
      },
      {
        property: 'id',
        label: 'Address',
        sortable: false,
        render: (_, row) => renderAddress(row)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: this.context.hasPermission([WHOLESALERS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.props.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([WHOLESALERS_DELETE]),
            disable: false,
            disableMsg: null,
            onClick: this.props.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { agencies, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={agencies}
        loading={loading}
        actions={this.context.hasSomeOfPermission([
          WHOLESALERS_UPDATE,
          WHOLESALERS_DELETE
        ])}
      />
    );
  }
}

WholesalersAgenciesTable.propTypes = {
  agencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default WholesalersAgenciesTable;
