import ListIcon from '@material-ui/icons/List';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import {
  ROUTE_PRODUCTS_LIST,
  ROUTE_TOP_SELLERS_LIST,
  permMap as productsPermMap
} from 'routing/routes/Products';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import {
  ROUTE_PRODUCT_CATEGORIES_LIST,
  permMap as productCategoriesPermMap
} from 'routing/routes/ProductCategories';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  KEY_ACCOUNT_MANAGER,
  PRODUCT_MANAGER,
  SUPER_ADMIN
} from 'api/auth/roles';

const menu = [
  {
    key: 'menu-products-nested',
    label: 'Products',
    icon: <LocalGroceryStoreIcon />,
    path: ROUTE_PRODUCTS_LIST,
    anyPermissions: [
      ...productsPermMap[ROUTE_PRODUCTS_LIST],
      ...productCategoriesPermMap[ROUTE_PRODUCT_CATEGORIES_LIST]
    ],
    children: [
      {
        key: 'menu-products',
        label: 'Products',
        icon: <ListIcon />,
        path: ROUTE_PRODUCTS_LIST,
        permissions: productsPermMap[ROUTE_PRODUCTS_LIST]
      },
      {
        key: 'menu-product-top-sellers-nested',
        label: 'Top sellers',
        icon: <ListIcon />,
        path: ROUTE_TOP_SELLERS_LIST,
        permissions: productsPermMap[ROUTE_TOP_SELLERS_LIST]
      },
      {
        key: 'menu-product-categories-nested',
        label: 'Products Categories',
        icon: <AllInboxIcon />,
        path: ROUTE_PRODUCT_CATEGORIES_LIST,
        roles: [
          SUPER_ADMIN,
          ADMIN,
          DIRECTOR,
          DEPARTMENT_MANAGER,
          KEY_ACCOUNT_MANAGER,
          PRODUCT_MANAGER
        ]
      }
    ]
  }
];

export default menu;
