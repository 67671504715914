import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import targetBonusesTypes from 'modules/TargetsBonuses/targetBonusesTypes';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN
} from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class TargetsBonusesFilter extends Filter {
  static contextType = AuthContext;

  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('status'),
            label: 'Status',
            type: 'select',
            emptyValueText: 'All statuses',
            options: formatOptions(targetBonusesTypes, 'id', 'name', true)
          },
          {
            ...this.getBasicInputValues('user_name'),
            label: 'User name',
            type: 'text',
            displayStatus: this.context.hasRole([
              SUPER_ADMIN,
              ADMIN,
              DIRECTOR,
              DEPARTMENT_MANAGER
            ])
          },
          {
            ...this.getBasicInputValues('final_value_from'),
            label: 'Value from',
            type: 'number'
          },
          {
            ...this.getBasicInputValues('final_value_to'),
            label: 'Value to',
            type: 'number'
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('period'),
            label: 'Period',
            type: 'periodPicker'
          }
        ]
      }
    ];
  }
}

export default TargetsBonusesFilter;
