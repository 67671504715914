import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class VisitsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.visitsPath = 'visits';
    this.visitPath = 'visits/:id';
    this.visitPostponePath = 'visits/postpone/:id';
    this.visitCancelPath = 'visits/cancel/:id';
    this.visitNotesPath = 'visits/:id/notes';
    this.visitsCopyPath = 'visits/copyrange';
  }

  getVisits(pathParams) {
    return this.get(this.visitsPath, pathParams);
  }

  getVisit(id) {
    return this.get(insertPathParams(this.visitPath, { id }));
  }

  createVisit(payload) {
    return this.post(this.visitsPath, payload);
  }

  updateVisit(id, payload) {
    return this.put(insertPathParams(this.visitPath, { id }), payload);
  }

  postponeVisit(id, payload) {
    return this.post(insertPathParams(this.visitPostponePath, { id }), payload);
  }

  cancelVisit(id, payload) {
    return this.post(insertPathParams(this.visitCancelPath, { id }), payload);
  }

  getVisitNotes(id, pathParams) {
    return this.get(insertPathParams(this.visitNotesPath, { id }), pathParams);
  }

  createVisitNote(id, payload) {
    return this.post(insertPathParams(this.visitNotesPath, { id }), payload);
  }

  copyVisits(payload) {
    return this.post(this.visitsCopyPath, payload);
  }
}

export default new VisitsApi();
