import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Table from 'modules/Layout/component/List/Table';
import t from 'translate/translate';
import { SALES_IMPORTS_UPDATE } from 'api/auth/permissions/SalesImports';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class SalesImportNewClientsList extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'wholesaler_client_number',
        label: 'Wholesaler client number',
        sortable: false
      },
      {
        property: 'wholesaler_client_name',
        label: 'Wholesaler client name',
        sortable: false
      },
      {
        property: 'error_type',
        label: 'Error type',
        sortable: false,
        render: errorType => t(errorType)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: false,
            disable: null,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: false,
            disable: null,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: null,
            disableMsg: null,
            onClick: null
          },
          mapItem: {
            status: this.context.hasPermission([SALES_IMPORTS_UPDATE]),
            disable: null,
            disableMsg: null,
            onClick: this.props.onMapClientClick
          }
        }
      }
    ];
  }

  render() {
    const { data } = this.props;

    return (
      <>
        <Typography
          component='h6'
          variant='h6'
          style={{ marginBottom: '1rem' }}
        >
          {t('Temporary clients (ready to map)')}
        </Typography>
        <Table cols={this.getCols()} rows={data} dense actions />
      </>
    );
  }
}

SalesImportNewClientsList.defaultProps = {
  data: []
};

SalesImportNewClientsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  onMapClientClick: PropTypes.func.isRequired
};

export default withRouter(SalesImportNewClientsList);
