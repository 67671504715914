import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN
} from 'api/auth/roles';
import parse from 'date-fns/parse';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/DatePicker';
import addYears from 'date-fns/addYears';

class VisitsTableFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('user_id'),
            label: 'User',
            type: 'autocomplete',
            emptyValueText: 'All users',
            options: formatOptions(this.props.users, 'id', 'name'),
            displayStatus: this.context.hasRole([
              ADMIN,
              DIRECTOR,
              SUPER_ADMIN,
              DEPARTMENT_MANAGER
            ])
          },
          {
            ...this.getBasicInputValues('client_id'),
            label: 'Client',
            type: 'autocomplete',
            emptyValueText: 'All clients',
            options: formatOptions(this.props.clients, 'id', 'name')
          },
          {
            ...this.getBasicInputValues('my_clients'),
            label: 'My clients',
            type: 'checkbox',
            displayStatus: this.context.hasRole([DEPARTMENT_MANAGER])
          },
          {
            ...this.getBasicInputValues('my_visits'),
            label: 'My visits',
            type: 'checkbox',
            displayStatus: !this.context.hasRole([ADMIN, SUPER_ADMIN])
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('date_from'),
            label: 'Date from',
            type: 'date',
            maxDate: this.props.filter.date_to
              ? parse(this.props.filter.date_to, API_DATE_FORMAT, new Date())
              : addYears(new Date(), 20)
          },
          {
            ...this.getBasicInputValues('date_to'),
            label: 'Date to',
            type: 'date',
            minDate: this.props.filter.date_from
              ? parse(this.props.filter.date_from, API_DATE_FORMAT, new Date())
              : addYears(new Date(), -20)
          }
        ]
      }
    ];
  }
}

export default VisitsTableFilter;
