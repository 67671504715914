import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import DepartmentsOrderTypesApi from 'api/connections/Departments/DepartmentsOrderTypesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';

const DepartmentOrderTypeDeleteModal = props => {
  const {
    orderType: { id, name: orderTypeName },
    onClose,
    onSuccess,
    setAlert
  } = props;

  const handleError = err => {
    if (err instanceof ApiError) {
      setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  };

  const onApprove = async () => {
    try {
      await DepartmentsOrderTypesApi.deleteDepartmentOrderType(id);
      setAlert({
        value: t('Order type deleted'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      handleError(err);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete order type <%=orderTypeName%>?',
        { orderTypeName }
      )}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

DepartmentOrderTypeDeleteModal.propTypes = {
  orderType: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default DepartmentOrderTypeDeleteModal;
