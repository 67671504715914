import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';

import {
  SALES_IMPORTS_STATUSES,
  UNMAPPED_PRODUCTS_SALE_IMPORT_STATUS,
  IMPORTED_SALE_IMPORT_STATUS
} from 'modules/SalesImports/constants';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { ROUTE_SALES_IMPORTS_DETAILS } from 'routing/routes/SalesImports';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import CancelImportDialog from 'modules/SalesImports/component/CancelImportDialog';

class SalesImportsTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      cancelDialogOpen: false,
      checkedSalesImport: null
    };

    this.renderCountry = this.renderCountry.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_SALES_IMPORTS_DETAILS, { id })
    );
  }

  onCancelClick(item) {
    this.setState({ cancelDialogOpen: true, checkedSalesImport: item });
  }

  onDialogClose() {
    this.setState({
      cancelDialogOpen: false,
      checkedSalesImport: null
    });
  }

  getCols() {
    return [
      {
        property: 'date',
        label: 'Import start date',
        sortable: true
      },
      {
        property: 'wholesaler_name',
        label: 'Wholesaler',
        sortable: true
      },
      {
        property: 'country_id',
        label: 'Country',
        sortable: true,
        render: this.renderCountry,
        displayStatus: this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])
      },
      {
        property: 'status',
        label: 'Status',
        render: SalesImportsTable.renderStatus,
        sortable: true
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          cancel: {
            status: this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]),
            disable: ({ status }) =>
              status !== UNMAPPED_PRODUCTS_SALE_IMPORT_STATUS &&
              status !== IMPORTED_SALE_IMPORT_STATUS,
            disableMsg: 'Import have wrong status',
            onClick: this.onCancelClick
          }
        }
      }
    ];
  }

  static renderStatus(id) {
    const statusName = SALES_IMPORTS_STATUSES.find(item => item.id === id)
      ?.name;
    return statusName && t(statusName);
  }

  renderCountry(id) {
    if (id) {
      const country = this.props.countries.find(item => item.id === id);
      return country?.name;
    }
    return null;
  }

  render() {
    const { cancelDialogOpen, checkedSalesImport } = this.state;
    const {
      salesImports,
      sort,
      setSort,
      loading,
      onCancelImportSuccess
    } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={salesImports}
          sort={sort}
          setSort={setSort}
          loading={loading}
          actions
        />
        {cancelDialogOpen && (
          <CancelImportDialog
            salesImport={checkedSalesImport}
            onClose={this.onDialogClose}
            onSuccess={onCancelImportSuccess}
          />
        )}
      </>
    );
  }
}

SalesImportsTable.defaultProps = {
  salesImports: [],
  countries: []
};

SalesImportsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  salesImports: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  onCancelImportSuccess: PropTypes.func.isRequired
};

export default withRouter(SalesImportsTable);
