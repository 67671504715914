import { useReducer } from 'react';
import PropTypes from 'prop-types';
import AuthApi from 'api/connections/Auth/AuthApi';
import ProfileApi from 'api/connections/Users/ProfileApi';
import {
  clearTokens,
  setAccessToken,
  setRefreshToken
} from 'api/auth/services/token';
import {
  setImpersonateRootId,
  getImpersonateRootId,
  clearImpersonateRootId
} from 'api/auth/services/impersonate';
import { setLangToLocalStorage } from 'translate/translate';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import authReducer, {
  LOGIN_SUCCESS,
  GET_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  LOGOUT,
  IS_IMPERSONATE,
  IS_NOT_IMPERSONATE
} from 'modules/Auth/context/Auth/authReducer';

const AuthState = props => {
  const initialState = {
    user: null,
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    isAuthenticated: false,
    impersonateRootId: null
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const getUser = async () => {
    const impersonateRootId = getImpersonateRootId();
    if (impersonateRootId) {
      dispatch({
        type: IS_IMPERSONATE,
        payload: impersonateRootId
      });
    }

    try {
      const res = await ProfileApi.getUser();
      setLangToLocalStorage(res.data.data.language);
      dispatch({
        type: GET_USER_SUCCESS,
        payload: res.data.data
      });
    } catch (err) {
      return err;
    }
  };

  const updateUser = user => {
    setLangToLocalStorage(user.language);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: user
    });
  };

  const login = async payload => {
    try {
      const res = await AuthApi.login(payload);
      setAccessToken(res.data.access_token);
      setRefreshToken(res.data.refresh_token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      await getUser();
      return res;
    } catch (err) {
      return err;
    }
  };

  const impersonate = async (id = null, callback) => {
    if (!state.impersonateRootId && !id) return null;

    if (!state.impersonateRootId) {
      setImpersonateRootId(state.user.id);
      dispatch({
        type: IS_IMPERSONATE,
        payload: state.user.id
      });
    }

    const user_id = id || state.impersonateRootId;

    if (!id || id === state.impersonateRootId) {
      clearImpersonateRootId();
      dispatch({ type: IS_NOT_IMPERSONATE });
    }

    try {
      const res = await AuthApi.impersonate({ user_id });
      setAccessToken(res.data.access_token);
      setRefreshToken(res.data.refresh_token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      await getUser();
      callback();
    } catch (err) {
      return err;
    }
  };

  const logout = () => {
    clearTokens();
    clearImpersonateRootId();
    dispatch({ type: LOGOUT });
  };

  const hasPermission = slugs => {
    return slugs.every(slug => state.user.permissions.includes(slug));
  };

  const hasSomeOfPermission = slugs => {
    return slugs.some(slug => state.user.permissions.includes(slug));
  };

  const hasRole = roles => {
    return roles.includes(state.user.role);
  };

  const { children } = props;
  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
        impersonateRootId: state.impersonateRootId,
        login,
        logout,
        getUser,
        updateUser,
        hasPermission,
        hasSomeOfPermission,
        hasRole,
        impersonate
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthState.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthState;
