import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validateBaseOnFormInputs } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import DepartmentsOrderTypesApi from 'api/connections/Departments/DepartmentsOrderTypesApi';

class DepartmentOrderTypeFormModal extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      formData: {
        department_id: this.props.departmentId,
        name: props.orderType?.name ?? '',
        short_name: props.orderType?.short_name ?? ''
      },
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        },
        short_name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Short name')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Order type saved'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onClose();
    this.props.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const validateBaseOnFormInputsState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...validateBaseOnFormInputsState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation(e.target.elements);
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation(formElements) {
    const { validation } = this.state;
    const [isValid, validateBaseOnFormInputsState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!isValid) {
      this.setState({ validation: validateBaseOnFormInputsState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    try {
      this.setState({ loading: true });

      if (this.props.orderType) {
        await DepartmentsOrderTypesApi.updateDepartmentOrderType(
          this.props.orderType.id,
          formData
        );
      } else {
        await DepartmentsOrderTypesApi.createDepartmentOrderType(formData);
      }

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { name, short_name },
      validation,
      loading
    } = this.state;

    const { onClose } = this.props;

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>
          {t(this.props.orderType ? 'Update order type' : 'Create order type')}
        </DialogTitle>
        <DialogContent>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            <Input
              name='name'
              label='Name'
              value={name}
              onChange={this.onChange}
              required
              errorStatus={validation.name.status}
              errorText={validation.name.message}
            />
            <Input
              name='short_name'
              label='Short name'
              value={short_name}
              onChange={this.onChange}
              required
              errorStatus={validation.short_name.status}
              errorText={validation.short_name.message}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button
            form='form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DepartmentOrderTypeFormModal.defaultProps = {
  orderType: undefined
};

DepartmentOrderTypeFormModal.propTypes = {
  departmentId: PropTypes.number.isRequired,
  orderType: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    short_name: PropTypes.string.isRequired
  }),
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default DepartmentOrderTypeFormModal;
