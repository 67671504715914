import { Route } from 'react-router-dom';
import {
  DISCOUNTS_LIST,
  DISCOUNTS_CREATE,
  DISCOUNTS_UPDATE
} from 'api/auth/permissions/Discounts';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import DiscountsList from 'modules/Discounts/view/List';
import DiscountsDetails from 'modules/Discounts/view/Details';
import DiscountCreate from 'modules/Discounts/view/Create';
import DiscountUpdate from 'modules/Discounts/view/Update';

export const ROUTE_DISCOUNTS_LIST = '/discounts';
export const ROUTE_DISCOUNTS_DETAILS = '/discounts/details/:id';
export const ROUTE_DISCOUNTS_CREATE = '/discounts/create';
export const ROUTE_DISCOUNTS_UPDATE = '/discounts/update/:id';

export const permMap = {
  [ROUTE_DISCOUNTS_LIST]: [DISCOUNTS_LIST],
  [ROUTE_DISCOUNTS_DETAILS]: [DISCOUNTS_LIST],
  [ROUTE_DISCOUNTS_CREATE]: [DISCOUNTS_CREATE],
  [ROUTE_DISCOUNTS_UPDATE]: [DISCOUNTS_UPDATE]
};

const routes = {
  protected: [
    <Route key='discounts-list' exact path={ROUTE_DISCOUNTS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_DISCOUNTS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <DiscountsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='discounts-details' exact path={ROUTE_DISCOUNTS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_DISCOUNTS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <DiscountsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='discount-create' exact path={ROUTE_DISCOUNTS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_DISCOUNTS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <DiscountCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='discount-update' exact path={ROUTE_DISCOUNTS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_DISCOUNTS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <DiscountUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
