import _ from 'lodash';
import { roles } from 'api/auth/roles';

export default users => {
  const departments = _.uniqBy(
    users.map(user => ({
      id: user.department_id,
      name: user.department_name,
      users: []
    })),
    'id'
  );

  users.forEach(user => {
    const {
      user_id,
      user_name,
      role: user_role,
      department_id,
      manager_id
    } = user;

    const userObj = {
      id: user_id,
      name: user_name,
      role: roles.find(role => role.slug === user_role).name,
      manager_id
    };

    departments
      .find(department => department.id === department_id)
      .users.push(userObj);
  });

  return departments;
};
