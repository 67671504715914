import { Component } from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import CustomDialog from 'modules/Layout/component/CustomDialog';
import MonthYearPicker, {
  API_DATE_FORMAT
} from 'modules/Layout/component/Date/MonthYearPicker';
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import ClientsPreviousUsers from 'api/connections/Clients/ClientsPreviousUsers';
import Loader from 'modules/Layout/component/Loader';
import { Box, Typography } from '@material-ui/core';
import getUsersGroupedByDepartments from 'modules/Clients/utils/getUsersGroupedByDepartments';

const maxDate = addMonths(new Date(), -1);

class ClientPreviousUsersModal extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.onClose = props.onClose;
    this.onSuccess = props.onSuccess;
    this.client = props.client;

    const initDate = format(maxDate, API_DATE_FORMAT);

    this.state = {
      period: initDate,
      clientsPreviousUsers: [],
      loading: false
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.fetchPreviousClientUsers();
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.onClose();
    this.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.props.setAlert(message);
    }
  }

  onChange(e) {
    const { value } = e.target;

    this.setState({ period: value }, this.fetchPreviousClientUsers);
  }

  async fetchPreviousClientUsers() {
    const { client_id } = this.client;
    const { period } = this.state;

    this.setState({ loading: true });

    try {
      const {
        data: { data: clientsPreviousUsers }
      } = await ClientsPreviousUsers.getClientsPreviousUsers({
        client_id,
        date: period
      });

      this.setState({ clientsPreviousUsers, loading: false });
    } catch (err) {
      this.handleError(err);
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, period, clientsPreviousUsers } = this.state;

    const groupedUsers = getUsersGroupedByDepartments(clientsPreviousUsers);

    return (
      <CustomDialog
        open
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        title={t("Client's users history")}
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <MonthYearPicker
          label='Period'
          onChange={this.onChange}
          value={period}
          name='month'
          maxDate={maxDate}
          variant=''
        />

        {loading ? (
          <Loader />
        ) : (
          <DialogContent>
            {groupedUsers?.map(department => (
              <div key={`department-${department.id}-modal`}>
                <Typography>
                  <strong>{department.name}:</strong>
                </Typography>
                <Box ml={3} mb={2}>
                  {department.users.map(user => (
                    <Typography key={`${user.id}-modal`}>{`${t(user.role)}: ${
                      user.name
                    }`}</Typography>
                  ))}
                </Box>
              </div>
            ))}
          </DialogContent>
        )}
      </CustomDialog>
    );
  }
}

ClientPreviousUsersModal.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default ClientPreviousUsersModal;
