import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash/lang';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import StatisticsExportApi from 'api/connections/Statistics/StatisticsExportApi';
import { downloadFile } from 'modules/Shared/utils/file';
import IconButton from 'modules/Layout/component/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import ClientsTargetsReportTable from 'modules/Statistics/ClientsTargetsReport/component/List/Table';
import ClientsTargetsReportFilter from 'modules/Statistics/ClientsTargetsReport/component/List/Filter';

class ClientsTargetsReport extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      filter: {
        with_archival: false,
        client_name: '',
        target_name: '',
        city: ''
      },
      filterValidation: {},
      statistics: [],
      loadingStatistics: false
    };

    this.setFilter = this.setFilter.bind(this);
    this.fetchStatistics = this.fetchStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportStatistics = this.exportStatistics.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Clients contracts report');

    this.fetchStatistics();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setFilter(filter) {
    this.setState({ filter: { ...this.state.filter, ...filter } }, () =>
      this.fetchStatistics()
    );
  }

  async fetchStatistics() {
    const { filter } = this.state;

    try {
      this.setState({ loadingStatistics: true });

      const {
        data: { data: statistics }
      } = await StatisticsApi.getClientsTargetsReport(filter);

      this.setState({
        statistics,
        loadingStatistics: false
      });
    } catch (err) {
      this.setState({ statistics: [], loadingStatistics: false });
      this.handleError(err);
    }
  }

  async exportStatistics() {
    try {
      const { filter } = this.state;

      const res = await StatisticsExportApi.exportClientsTargetsReport(filter);

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { statistics } = this.state;

    return (
      <Box display='flex' justifyContent='flex-start'>
        <IconButton
          onClick={this.exportStatistics}
          icon={
            <GetAppOutlinedIcon
              color={isEmpty(statistics) ? 'disabled' : 'primary'}
              fontSize='large'
            />
          }
          disabled={isEmpty(statistics)}
          alt='export table'
        />
      </Box>
    );
  }

  render() {
    const {
      statistics,
      countries,
      departments,
      filter,
      filterValidation,
      loadingCountries,
      loadingDepartments,
      loadingStatistics
    } = this.state;

    return (
      <div>
        <ClientsTargetsReportFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          countries={countries}
          departments={departments}
          loadingCountries={loadingCountries}
          loadingDepartments={loadingDepartments}
        />

        {this.renderActions()}
        <ClientsTargetsReportTable
          statistics={statistics}
          countries={countries}
          loading={loadingStatistics}
        />
        {this.renderActions()}
      </div>
    );
  }
}

ClientsTargetsReport.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default ClientsTargetsReport;
