export default {
  'Clients bonuses': 'Premie klientów',
  'Client bonus': 'Premia klienta',
  'Bonus status': 'Status premii',
  'Change history': 'Historia zmian',
  'The system has not yet set an estimated contract achievement level.':
    'System nie wyznaczył jeszcze szacowanego poziomu realizacji kontraktu.',
  'Final bonus value changed': 'Ostateczna wartość premii zmieniona',
  'Bonus confirmed': 'Premia zatwierdzona',
  'Status set to paid': 'Status ustawiony na wypłacony',
  'Bonus suspended': 'Premia zawieszona',
  'Bonus unsuspended': 'Zawieszonie premii cofnięte',
  'Old value': 'Poprzednia wartość',
  'Final value of purchased products':
    'Ostateczna wartość zakupionych produktów',
  'Current value of purchased products':
    'Aktualna wartość zakupionych produktów',
  'Current bonus value': 'Aktualna wartość premii',
  'Date of estimating the level of contract achievement':
    'Data oszacowania stopnia realizacji kontraktu',
  'Estimated total value of purchased products':
    'Szacunkowa łączna wartość zakupionych produktów',
  'Estimated contract bonus value': 'Szacowana wartość premii',
  'The system did not create a bonuses yet':
    'System nie stworzył jeszcze premii',
  'Estimated values': 'Szacowane wartości',
  'Purchase value': 'Wartość zakupów',
  'Bonus value': 'Wartość premii',
  'Current purchase value': 'Aktualna wartość zakupów',
  'Estimated purchase value': 'Szacowana wartość zakupów',
  'Estimating date': 'Data ostatniego szacowania',
  'in progress': 'w trakcie realizacji'
};
