import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import t from 'translate/translate';
import CustomDialog from 'modules/Layout/component/CustomDialog';
import { ListItem, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const CalendarModal = ({ onClose, open, chosenDay: { visits } }) => {
  const history = useHistory();

  const onVisitClick = id => {
    history.push(`/visits/details/${id}`);
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
      title={t('Daily visits list')}
      fullWidth
      transitionDuration={{
        enter: 200,
        exit: 100
      }}
    >
      <DialogContent>
        {visits.map(({ client_name, id }) => (
          <ListItem
            style={{ borderBottom: '1px solid lightgray' }}
            button
            key={id}
            onClick={() => onVisitClick(id)}
          >
            <ListItemText primary={client_name} />
          </ListItem>
        ))}
      </DialogContent>
    </CustomDialog>
  );
};

CalendarModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  chosenDay: PropTypes.shape({
    visits: PropTypes.arrayOf(
      PropTypes.shape({ client_name: PropTypes.string.isRequired })
    )
  }).isRequired,
  open: PropTypes.bool.isRequired
};

export default CalendarModal;
