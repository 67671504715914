export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const IMPERSONATE_SUCCESS = 'IMPERSONATE_SUCCESS';
export const IS_IMPERSONATE = 'IS_IMPERSONATE';
export const IS_NOT_IMPERSONATE = 'IS_NOT_IMPERSONATE';

export default (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        expiresIn: action.payload.expires_in,
        isAuthenticated: true
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
        impersonateRootId: null
      };
    case IMPERSONATE_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        expiresIn: action.payload.expires_in,
        isAuthenticated: true
      };
    case IS_IMPERSONATE:
      return {
        ...state,
        impersonateRootId: action.payload
      };
    case IS_NOT_IMPERSONATE:
      return {
        ...state,
        impersonateRootId: null
      };
    default:
      return state;
  }
};
