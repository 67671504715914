import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import CheckIcon from '@material-ui/icons/Check';
import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ORDERS_UPDATE, ORDERS_DELETE } from 'api/auth/permissions/Orders';
import ordersStatuses, {
  ORDER_STATUS_PREPARING
} from 'modules/Orders/ordersStatuses';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import renderPrice from 'modules/Products/utils/renderPrice';

class OrdersTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.setRemoveDisableStatus = this.setRemoveDisableStatus.bind(this);
    this.setUpdateDisableStatus = this.setUpdateDisableStatus.bind(this);
  }

  setRemoveDisableStatus(order) {
    const { status, user_id, user_departments } = order;

    if (status !== ORDER_STATUS_PREPARING) return true;

    const { role, id, departments } = this.context.user;

    if (user_id === id) return false;

    if ([ADMIN, SUPER_ADMIN].includes(role)) return false;

    if (role === DIRECTOR && user_departments) {
      return !departments
        .map(department => department.id)
        .some(d => user_departments.includes(d));
    }

    return true;
  }

  setUpdateDisableStatus(order) {
    const { status, user_id } = order;

    if (status !== ORDER_STATUS_PREPARING) return true;

    const { id } = this.context.user;

    if (user_id === id) return false;

    return true;
  }

  getCols() {
    return [
      {
        property: 'client_name',
        label: 'Client name',
        sortable: true
      },
      {
        property: 'status',
        label: 'Status',
        sortable: false,
        render: status => t(ordersStatuses.find(o => o.status === status).name)
      },
      {
        property: 'total_price',
        label: 'Price',
        sortable: false,
        nullable: true,
        render: (value, { currency }) => renderPrice(value, currency)
      },
      {
        property: 'wholesaler_name',
        label: 'Wholesaler name',
        sortable: true
      },
      {
        property: 'user_name',
        label: 'User name',
        sortable: true
      },
      {
        property: 'order_date',
        label: 'Order date',
        sortable: true
      },
      {
        property: 'is_direct',
        label: 'Direct order',
        sortable: false,
        render: status => (status ? <CheckIcon color='primary' /> : null)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.props.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([ORDERS_UPDATE]),
            disable: this.setUpdateDisableStatus,
            disableMsg: t('Order cannot be updated'),
            onClick: this.props.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([ORDERS_DELETE]),
            disable: this.setRemoveDisableStatus,
            disableMsg: t('Order cannot be deleted'),
            onClick: this.props.onDeleteClick
          },
          changeStatus: {
            status: true,
            disable: ({ status }) => status === ORDER_STATUS_PREPARING,
            disableMsg: t('Status of order cannot be changed'),
            onClick: this.props.onChangeStatusClick
          }
        }
      }
    ];
  }

  render() {
    const { orders, sort, setSort, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={orders}
        sort={sort}
        setSort={setSort}
        loading={loading}
        actions
      />
    );
  }
}

OrdersTable.defaultProps = {
  orders: []
};

OrdersTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  onDetailsClick: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onChangeStatusClick: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(OrdersTable);
