import { useEffect, useState, useContext } from 'react';
import { useCurrentPage } from 'modules/Shared/hook';
import { Box, Paper } from '@material-ui/core';
import CreateDepartmentForm from 'modules/Departments/component/Create/Form';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import AuthContext from 'modules/Auth/context/Auth/authContext';

const DepartmentsCreate = () => {
  useCurrentPage('Adding a department');
  const { hasPermission } = useContext(AuthContext);

  const [countries, setCountries] = useState([]);
  const [categoriesObj, setCategoriesObj] = useState({
    productGroups: [],
    categories: []
  });

  const fetchData = async () => {
    const {
      data: categoriesData
    } = await ProductCategoriesApi.getProductCategories();
    setCategoriesObj(prepareCategories(categoriesData));

    if (hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
      const {
        data: { data: countriesData }
      } = await DepartmentsApi.getCountries();
      setCountries(countriesData);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box width={1} display='flex' justifyContent='center'>
      <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
        <Paper style={{ padding: '1rem' }}>
          <CreateDepartmentForm
            countries={countries}
            categoriesObj={categoriesObj}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default DepartmentsCreate;
