import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import { ORDER_PAYMENT_METHODS_MANAGEMENT } from 'api/auth/permissions/PaymentMethods';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class PaymentMethodsTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'identifier',
        label: 'Identifier',
        sortable: false
      },
      {
        property: 'price_limit',
        label: 'Price limit',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: false,
            disable: null,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: this.context.hasPermission([
              ORDER_PAYMENT_METHODS_MANAGEMENT
            ]),
            disable: null,
            disableMsg: null,
            onClick: this.props.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([
              ORDER_PAYMENT_METHODS_MANAGEMENT
            ]),
            disable: null,
            disableMsg: null,
            onClick: this.props.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { paymentMethods, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={paymentMethods}
        loading={loading}
        actions={this.context.hasPermission([ORDER_PAYMENT_METHODS_MANAGEMENT])}
      />
    );
  }
}

PaymentMethodsTable.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default PaymentMethodsTable;
