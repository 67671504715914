import { Box, CircularProgress } from '@material-ui/core';

const PageLoader = () => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      height='100vh'
    >
      <CircularProgress size={100} />
    </Box>
  );
};

export default PageLoader;
