import PropTypes from 'prop-types';
import t from 'translate/translate';
import {
  Dialog as MaterialUiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import Button from 'modules/Layout/component/Button';

const Dialog = props => {
  const { open, title, description, onApprove, onCancel, loading } = props;

  return (
    <div>
      <MaterialUiDialog
        open={open}
        onClose={onCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onCancel} />
          <Button
            text={t('Approve')}
            onClick={onApprove}
            color='primary'
            loading={loading}
          />
        </DialogActions>
      </MaterialUiDialog>
    </div>
  );
};

Dialog.defaultProps = {
  loading: false
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onApprove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default Dialog;
