import { Box, CardContent, Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import BarChart from 'modules/Layout/component/Charts/BarChart';
import t from 'translate/translate';
import SalesResultsLegend from 'modules/SalesResults/component/ResultsDetails/Legend';
import PieChart from 'modules/Layout/component/Charts/PieChart';

export const renderWholesalersPies = (
  nameSuffix,
  wholesalersSales,
  chartWidth,
  onDrillDown
) =>
  wholesalersSales.map((wholesaler, index) => (
    <Grid item xs={12} md={6} mb={4} key={wholesaler.id}>
      <Box mb={4}>
        <Typography align='center' variant='h6'>
          {wholesaler.name}
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Box display='flex' justifyContent='space-around'>
            <PieChart
              data={wholesaler.data}
              valuePropertyName='quantity_sum'
              title={t('Number of wholesalers sales')}
              outerRadius={chartWidth}
              innerRadius={15}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix + index + 1}
              suffix='szt.'
            />
            <PieChart
              data={wholesaler.data}
              valuePropertyName='price_sum'
              title={t('Sum of wholesalers sales')}
              outerRadius={chartWidth}
              innerRadius={15}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix + index + 1}
              suffix='PLN'
            />
          </Box>
          <SalesResultsLegend nameSuffix={nameSuffix + index + 1} />
        </CardContent>
      </Card>
    </Grid>
  ));

export const renderWholesalersBars = (
  nameSuffix,
  wholesalersSales,
  barWidth,
  onDrillDown
) =>
  wholesalersSales.map((wholesaler, index) => (
    <Grid item xs={12} md={6} mb={4} key={wholesaler.id}>
      <Box mb={4}>
        <Typography align='center' variant='h6'>
          {wholesaler.name}
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Box display='flex' justifyContent='space-around'>
            <BarChart
              data={wholesaler.data}
              valuePropertyName='quantity_sum'
              title={t('Number of wholesalers sales')}
              width={barWidth}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix + index + 1}
              suffix='szt.'
            />
            <BarChart
              data={wholesaler.data}
              valuePropertyName='price_sum'
              title={t('Sum of wholesalers sales')}
              width={barWidth}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix + index + 1}
              suffix='PLN'
            />
          </Box>
          <SalesResultsLegend nameSuffix={nameSuffix + index + 1} />
        </CardContent>
      </Card>
    </Grid>
  ));
