export default {
  Taxes: 'Podatki',
  Tax: 'Podatek',
  'Update tax': 'Edytuj podatek',
  'Create tax': 'Dodaj podatek',
  'Field Value is required and correct format is X.XX':
    'Pole Wartość jest wymagane, poprawny format to X.XX',
  'Are you sure you want to delete tax <%=taxName%> for country <%=countryName%>?':
    'Czy na pewno chcesz usunać podatek <%=taxName%> dla kraju <%=countryName%>?',
  'Taxes types': 'Rodzaje Podatków'
};
