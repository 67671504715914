import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCTS_DETAILS
} from 'routing/routes/Products';
import { Box, Paper } from '@material-ui/core';
import ProductBatchUpdateForm from 'modules/Products/component/Batches/Update/Form';
import { withRouter } from 'react-router-dom';
import ProductsApi from 'api/connections/Products/ProductsApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import insertPathParams from 'api/utils/insertPathParams';

class ProductBatchUpdate extends Component {
  constructor(props) {
    super(props);
    this.productId = parseInt(props.match.params.productId, 10) || null;
    this.batchId = parseInt(props.match.params.batchId, 10) || null;
    this.state = {
      productBatch: null
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  async componentDidMount() {
    if (!this.productId || !this.batchId) {
      this.props.history.push(ROUTE_PRODUCTS_LIST);
    }
    this.setCurrentPage('Update product batch');
    await this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: { data: product }
      } = await ProductsApi.getProduct(this.productId);

      const productBatch = product.batches.find(
        ({ id }) => id === this.batchId
      );

      if (!productBatch) {
        this.props.history.push(
          insertPathParams(ROUTE_PRODUCTS_DETAILS, { id: this.productId })
        );
      }

      this.setState({ productBatch });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
        if (err instanceof NotFoundApiError) {
          this.props.history.push(
            insertPathParams(ROUTE_PRODUCTS_DETAILS, {
              id: this.productId
            })
          );
        }
      }
    }
  }

  render() {
    const { productBatch } = this.state;
    if (!productBatch) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ProductBatchUpdateForm
              productId={this.productId}
              batchId={this.batchId}
              productBatch={productBatch}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ProductBatchUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      batchId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ProductBatchUpdate);
