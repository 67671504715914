import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { Grid, Paper } from '@material-ui/core';
import ClientsMappingList from 'modules/Wholesalers/component/Details/ClientsMapping/Table';
import WholesalersMappingsApi from 'api/connections/Wholesalers/WholesalersMappingsApi';
import { formatMetaToPagination } from 'modules/Layout/component/List/Pagination';
import WholesalerClientsMappingFilter from 'modules/Wholesalers/component/Details/ClientsMapping/Table/Filter';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Loader from 'modules/Layout/component/Loader';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';
import ImportMappingsDialog from 'modules/Wholesalers/component/Details/ClientsMapping/ImportMappingsDialog';

class ClientsMapping extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { wholesalerId } = props;

    this.state = {
      clientsMapping: [],
      filter: {
        client_name: ''
      },
      filterValidation: {},
      pagination: {
        total: 0,
        page: 1,
        per_page: 15
      },
      importMappingsDialogStatus: false
    };

    this.wholesalerId = wholesalerId;

    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onDeleteMappingSuccess = this.onDeleteMappingSuccess.bind(this);
    this.onImportClick = this.onImportClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.fetchMappings();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  onDeleteMappingSuccess(mappingId) {
    this.setState(prevState => {
      return {
        ...prevState,
        clientsMapping: prevState.clientsMapping.filter(
          mapping => mapping.id !== mappingId
        )
      };
    });
  }

  onImportClick() {
    this.setState({ importMappingsDialogStatus: true });
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchMappings()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchMappings()
    );
  }

  closeDialog() {
    this.setState({ importMappingsDialogStatus: false });
  }

  async fetchMappings() {
    const {
      pagination: { page, per_page },
      filter
    } = this.state;

    try {
      const {
        data: { data: clientsMapping, meta }
      } = await WholesalersMappingsApi.getWholesalersClientsMappings({
        wholesaler_id: this.wholesalerId,
        ...filter,
        per_page,
        page
      });

      this.setState({
        clientsMapping,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      clientsMapping,
      pagination,
      filter,
      filterValidation,
      importMappingsDialogStatus
    } = this.state;

    if (!clientsMapping) return <Loader />;

    return (
      <Grid container justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <Button
              type='button'
              color='primary'
              text={t('Import mappings')}
              onClick={this.onImportClick}
            />
            <WholesalerClientsMappingFilter
              filter={filter}
              validation={filterValidation}
              setFilter={this.setFilter}
            />
            <ClientsMappingList
              wholesalerId={this.wholesalerId}
              wholesalerName={this.props.wholesalerName}
              clientsMapping={clientsMapping}
              onDeleteMappingSuccess={this.onDeleteMappingSuccess}
              setPagination={this.setPagination}
              pagination={pagination}
            />
          </Paper>
        </Grid>
        {importMappingsDialogStatus && (
          <ImportMappingsDialog
            wholesalerId={this.wholesalerId}
            onClose={this.closeDialog}
            onSuccess={this.fetchData}
          />
        )}
      </Grid>
    );
  }
}

ClientsMapping.propTypes = {
  wholesalerId: PropTypes.number.isRequired,
  wholesalerName: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(ClientsMapping);
