import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MonthYearPicker, {
  API_DATE_FORMAT
} from 'modules/Layout/component/Date/MonthYearPicker';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN
} from 'api/auth/roles';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import t from 'translate/translate';
import Checkbox from 'modules/Layout/component/Checkbox';
import Button from 'modules/Layout/component/Button';
import { ROUTE_VISITS_LIST } from 'routing/routes/Visits';
import format from 'date-fns/format';

const InputsHeader = ({
  dateChangeWithArrowsFn,
  hasRoleFn,
  monthStart,
  onDateChangeFn,
  maxDate,
  onUserChangeFn,
  userId,
  users,
  historyPushFn,
  onCopyVisitsChangeFn,
  onCopyVisitsModalOpenFn,
  disabledCopyButton
}) => (
  <Box display='flex' justifyContent='center' alignItems='center'>
    <IconButton onClick={e => dateChangeWithArrowsFn(e, true)}>
      <ArrowBackIcon />
    </IconButton>
    <MonthYearPicker
      label='Date'
      onChange={onDateChangeFn}
      value={format(monthStart, API_DATE_FORMAT)}
      name='Date'
      variant=''
      maxDate={maxDate}
      margin='none'
      fullWidth={false}
    />
    <IconButton onClick={e => dateChangeWithArrowsFn(e)}>
      <ArrowForwardIcon />
    </IconButton>
    {hasRoleFn([ADMIN, DIRECTOR, SUPER_ADMIN, DEPARTMENT_MANAGER]) && (
      <Select
        label='User'
        name='user_name'
        onChange={onUserChangeFn}
        value={userId}
        options={formatOptions(users, 'id', 'name')}
        emptyValueText={`${t('User')}`}
        margin='none'
        style={{ input: { width: 200 } }}
        fullWidth={false}
      />
    )}
    <Checkbox
      onChange={onCopyVisitsChangeFn}
      name='copy_visits'
      label='Copying visits'
      style={{ marginLeft: 10 }}
    />
    <Button
      color='primary'
      text={t('Copy visits')}
      onClick={onCopyVisitsModalOpenFn}
      style={{ display: 'block' }}
      disabled={disabledCopyButton}
    />
    <Button
      color='primary'
      text={t('Visits list')}
      onClick={() => historyPushFn(ROUTE_VISITS_LIST)}
      style={{ marginLeft: 'auto', display: 'block' }}
    />
  </Box>
);

InputsHeader.defaultProps = {
  users: []
};

InputsHeader.propTypes = {
  dateChangeWithArrowsFn: PropTypes.func.isRequired,
  hasRoleFn: PropTypes.func.isRequired,
  monthStart: PropTypes.instanceOf(Date).isRequired,
  onDateChangeFn: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  onUserChangeFn: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  historyPushFn: PropTypes.func.isRequired,
  onCopyVisitsChangeFn: PropTypes.func.isRequired,
  onCopyVisitsModalOpenFn: PropTypes.func.isRequired,
  disabledCopyButton: PropTypes.bool.isRequired
};

export default InputsHeader;
