import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ClientsContactApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.createClientContactPath = 'clients/:id/contacts';
    this.clientContactPath = 'clients/:clientId/contacts/:contactId';
  }

  createClientContact(id, payload) {
    return this.post(
      insertPathParams(this.createClientContactPath, { id }),
      payload
    );
  }

  updateClientContact(ids, payload) {
    return this.put(insertPathParams(this.clientContactPath, ids), payload);
  }

  deleteClientContact(ids) {
    return this.delete(insertPathParams(this.clientContactPath, ids));
  }
}

export default new ClientsContactApi();
