import { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';

import SalesResultsApi from 'api/connections/SalesResults/SalesResultsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';

import SalesResultsTable from 'modules/SalesResults/component/List/Table';
import SalesResultsFilter from 'modules/SalesResults/component/List/Filter';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';

class SalesResultsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      filter: {
        department_name: '',
        month_from: '',
        month_to: ''
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      salesResults: [],
      departments: [],
      loading: true
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchSalesResults = this.fetchSalesResults.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Departments sales results archive');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchSalesResults()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchSalesResults()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchSalesResults()
    );
  }

  async fetchData() {
    const {
      pagination: { per_page, page }
    } = this.state;

    try {
      let departments = [];

      const {
        data: { data: salesResults, meta }
      } = await SalesResultsApi.getSalesResults({ per_page, page });

      if (this.context.hasPermission([DEPARTMENTS_LIST])) {
        const {
          data: { data: departmentsData }
        } = await DepartmentsApi.getDepartments({
          per_page: Number.MAX_SAFE_INTEGER
        });

        departments = departmentsData;
      }

      this.setState({
        salesResults,
        departments,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchSalesResults() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: salesResults, meta }
      } = await SalesResultsApi.getSalesResults({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        salesResults,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      filter,
      sort,
      salesResults,
      departments,
      pagination,
      loading,
      filterValidation
    } = this.state;

    return (
      <div>
        <SalesResultsFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          departments={departments}
        />
        <SalesResultsTable
          sort={sort}
          setSort={this.setSort}
          salesResults={salesResults}
          loading={loading}
        />
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </div>
    );
  }
}

SalesResultsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default SalesResultsList;
