import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { SHOPPING_GROUPS_UPDATE } from 'api/auth/permissions/SalesGroups';

import SalesGroupClientsDeleteDialog from 'modules/SalesGroups/component/Details/Clients/Delete/Dialog';
import { Link } from 'react-router-dom';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';

class SalesGroupClientsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.salesGroupId = props.salesGroupId;

    this.state = {
      deleteDialogStatus: false,
      clientToDelete: null
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  onDeleteClick(client) {
    this.setState({ deleteDialogStatus: true, clientToDelete: client });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogStatus: false, clientToDelete: null });
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false,
        render: (name, { id }) => (
          <Link
            to={insertPathParams(ROUTE_CLIENTS_DETAILS, {
              id
            })}
            className='router-link router-link-underline'
          >
            {name}
          </Link>
        )
      },
      {
        property: 'tax_number',
        label: 'Tax number',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasPermission([SHOPPING_GROUPS_UPDATE]),
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: this.context.hasPermission([SHOPPING_GROUPS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { clients, onDetachClientFromSalesGroupSuccess } = this.props;
    const { deleteDialogStatus, clientToDelete } = this.state;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={clients}
          dense
          actions={this.context.hasPermission([SHOPPING_GROUPS_UPDATE])}
        />
        {deleteDialogStatus && (
          <SalesGroupClientsDeleteDialog
            client={clientToDelete}
            groupId={this.salesGroupId}
            onClose={this.onDeleteDialogClose}
            onSuccess={onDetachClientFromSalesGroupSuccess}
          />
        )}
      </>
    );
  }
}

SalesGroupClientsList.propTypes = {
  salesGroupId: PropTypes.number.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDetachClientFromSalesGroupSuccess: PropTypes.func.isRequired
};

export default SalesGroupClientsList;
