export default {
  Clients: 'Klienci',
  Name: 'Nazwa',
  'Recipient number': 'Numer odbiorcy',
  'Tax number': 'NIP',
  City: 'Miasto',
  Street: 'Ulica',
  'Postal code': 'Kod pocztowy',
  'Client details': 'Szczegóły klienta',
  Hours: 'Godziny',
  'Create client': 'Dodawanie klienta',
  'Update client': 'Edytowanie klienta',
  'Create internet client': 'Dodawanie klienta internetowego',
  'Update internet client': 'Edytowanie klienta internetowego',
  'Create client contact': 'Dodawanie kontaktu klienta',
  'Update client contact': 'Edytowanie kontaktu klienta',
  Address: 'Adres',
  'My clients': 'Moi klienci',
  'Creator name': 'Właściciel',
  'Created at': 'Data utworzenia',
  'Created at from': 'Data utworzenia od',
  'Created at to': 'Data utworzenia do',
  Files: 'Pliki',
  Notes: 'Notatki',
  'General data': 'Dane podstawowe',
  'Are you sure you want to delete file <%=fileName%>':
    'Czy na pewno chcesz usunać plik <%=fileName%>',
  'Update client file': 'Edytowanie pliku klienta',
  'Update file': 'Edytowanie pliku',
  'Create client file': 'Dodawanie pliku klienta',
  'Create file': 'Dodawanie pliku',
  'Upload file': 'Załaduj plik',
  File: 'Plik',
  'You are not a creator of this file': 'Nie jesteś twórcą tego pliku',
  'You are not assign to this client': 'Nie jesteś przypisany do tego klienta',
  'Sort by': 'Sortuj po',
  None: 'Bez sortowania',
  'Create note': 'Dodawanie notatki',
  'Update note': 'Edytowanie notatki',
  Content: 'Treść',
  'Are you sure you want to delete this note':
    'Czy na pewno chcesz usunać tę notatkę',
  'Client account': 'Konto klienta',
  "Client doesn't have account": 'Klient nie posiada konta',
  'Creat account for client': 'Dodawnie konta dla klienta',
  'Create account for <%=name%>': 'Dodawnie konta dla klienta <%=name%>',
  'Create account': 'Dodawanie konta',
  'Update account for client': 'Edytowanie konta dla klienta',
  'Update account for <%=name%>': 'Edytowanie konta dla klienta <%=name%>',
  'Update account': 'Edytowanie konta',
  'Are you sure you want to delete account for client <%=clientName%>':
    'Czy na pewno chcesz usunąć konto dla klienta <%=clientName%>',
  Visits: 'Wizyty',
  'Upcoming visits': 'Nadchodzące wizyty',
  'Visits history': 'Historia wizyt',
  'Visit data': 'Dane wizyty',
  'User name': 'Nazwa użytkownika',
  'Scheduled at': 'Data spotkania',
  Place: 'Miejsce',
  'Visit date': 'Data wizyty',
  From: 'Od',
  To: 'Do',
  'Clients list': 'Lista klientów',
  "Client's users": 'Opiekunowie klienta',
  Cooperation: 'Współpraca',
  Potential: 'Potencjał',
  "Client's evaluation": 'Ocena klienta',
  "Client's evaluation and activity": 'Ocena klienta i aktywność',
  "client's users history": 'historia opiekunów klienta',
  "Client's users history": 'Historia opiekunów klienta',
  'Are you sure you want to delete user <%=userName%> from client?':
    'Czy na pewno chcesz usunąć użytkownika <%=userName%> od klienta?',
  'Assign employees to the client': 'Przypisz pracowników do klienta',
  'Choose country first': 'Wybierz najpierw kraj',
  'Client business status': 'Status działalności klienta',
  active: 'aktywny',
  inactive: 'nieaktywny',
  'Client category': 'Kategoria klienta',
  'Assign client to myself': 'Przypisz klienta do siebie',
  'The client is not assigned to you or to your subordinate':
    'Klient nie jest przypisany do Ciebie lub do Twojego podwładnego',
  'You cannot see the data because the client is not assigned to any employee from your department.':
    'Nie możesz zobaczyć danych ponieważ klient nie jest przypisany do żadnego pracownika z Twojego działu.',
  'Activity in the department': 'Aktywność w dziale',
  'suspended client': 'klient zawieszony',
  'active client': 'klient aktywny',
  'inactive client': 'klient nieaktywny',
  'Bank account for bonuses': 'Konto bankowe dla premii',
  'Clients unassigned to employee': 'Klienci nieprzypisani do pracownika',
  'Unassigned to employee': 'Nieprzypisani do pracownika',
  'Client active in department': 'Klient aktywny w dziale',
  'Client inactive in department': 'Klient nieaktywny w dziale',
  'Clients divided into regions': 'Klienci podzieleni na regiony',
  'Unrelated clients divided into cities':
    'Niezidentyfikowani klienci podzieleni na miasta',
  'No unrelated clients assigned to user found':
    'Nie znaleziono niezidentyfikowanych klientów przypisanych do użytkownika',
  'Not assigned to a trader': 'Nieprzypisani do handlowca',
  'Not assigned to a telemarketer': 'Nieprzypisani do telemarketera',
  'Enter the correct data in the filter': 'Wprowadz poprawne dane w filtrze',
  'List of clients not assigned to employees':
    'Lista klientów nieprzypisanych do pracowników',
  'Assign trader': 'Przypisz handlowca',
  'Assign telemarketer': 'Przypisz telemarketera',
  'Select client first': 'Wybierz najpierw klienta',
  'Select the user that will be assigned to the client':
    'Wybierz użytkownika, który zostanie przypisany do klienta',
  'Successfully assigning a user to a client':
    'Przypisywanie użytkownika do klienta zakończone sukcesem',
  'no clients selected': 'nie wybrano klienta',
  'clear selected clients': 'wyczyść zaznaczonych klientów',
  'Client number': 'Identyfikator klienta',
  'Wholesalers mappings': 'Mapowania hurtowni',
  'Create wholesaler mapping': 'Dodawanie mapowania hurtowni',
  'Update wholesaler mapping': 'Edycja mapowania hurtowni',
  'New products since last visit': 'Nowe produkty od ostatniej wizyty',
  'Quantity purchased by the client': 'Ilość zakupiona przez klienta',
  'With a individual contract': 'Posiadający indywidualny kontrakt',
  'With a group contract': 'Posiadający grupowy kontrakt',
  'select client': 'wybierz klienta',
  'Select client': 'Wybierz klienta',
  'Main email': 'Główny email',
  'Main phone number': 'Główny numer telefonu',
  Comments: 'Uwagi',
  'Comments updated successfully': 'Uwagi zapisane',
  "Client don't have any comments": 'Klient nie posiada żadnych uwag',
  'One of the filters can be selected simultaneously':
    'Jedno z filtrów może być jednoczesnie wybrane',
  'Internet clients': 'Klienci internetowi',
  'Internet client': 'Klient internetowy',
  'Client activity': 'Aktywność klienta',
  'Internet client details': 'Szczegóły klienta internetowego'
};
