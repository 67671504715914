import { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from 'modules/Layout/component/Loader';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ProductsApi from 'api/connections/Products/ProductsApi';
import ProductsTreeGroups from 'modules/Products/component/ProductsTree/ProductsTreeGroups';
import { Box } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import t from 'translate/translate';
import CustomDialog from 'modules/Layout/component/CustomDialog';
import Button from 'modules/Layout/component/Button';

class ProductsTree extends Component {
  static setTreeItemsToDisplay(assignedProductsAndCategories) {
    return {
      groups: [
        ...assignedProductsAndCategories.groups.map(i => i.id),
        ...assignedProductsAndCategories.categories.map(i => i.group_id),
        ...assignedProductsAndCategories.subcategories.map(i => i.group_id),
        ...assignedProductsAndCategories.products.map(i => i.group_id)
      ],
      categories: [
        ...assignedProductsAndCategories.categories.map(i => i.id),
        ...assignedProductsAndCategories.subcategories.map(i => i.category_id),
        ...assignedProductsAndCategories.products.map(i => i.category_id)
      ],
      subcategories: [
        ...assignedProductsAndCategories.subcategories.map(i => i.id),
        ...assignedProductsAndCategories.products.map(i => i.subcategory_id)
      ],
      products: [...assignedProductsAndCategories.products.map(i => i.id)]
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      productsData: {},
      updateModalStatus: false
    };

    this.onClick = this.onClick.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  async onClick(item) {
    const { group_id, category_id, id: subcategory_id } = item;

    const { productsData } = this.state;

    if (productsData[subcategory_id]) return null;

    this.setState(state => ({
      productsData: {
        ...state.productsData,
        [subcategory_id]: 'loading'
      }
    }));

    try {
      const {
        data: { data: products }
      } = await ProductsApi.getProducts({
        subcategory_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState(state => ({
        productsData: {
          ...state.productsData,
          [subcategory_id]: products.map(p => ({
            ...p,
            group_id,
            category_id,
            subcategory_id,
            type: 'product'
          }))
        }
      }));
    } catch (err) {
      this.props.handleError(err);
    }
  }

  onSelect(isChecked, item) {
    const { categoriesObj } = this.props;
    const { type, id, group_id, category_id, subcategory_id } = item;

    this.setState(state => {
      const newSelected = { ...state.selected };

      const isItemSelected = (itemId, arrName) => {
        return Boolean(newSelected[arrName].find(i => i.id === itemId));
      };

      if (isChecked) {
        if (
          type === 'category' ||
          type === 'subcategory' ||
          type === 'product'
        ) {
          if (isItemSelected(group_id, 'groups')) {
            newSelected.groups = newSelected.groups.filter(
              g => g.id !== group_id
            );
            newSelected.categories = [
              ...newSelected.categories,
              ...categoriesObj.find(group => group.id === group_id).categories
            ];
          }
        }

        if (type === 'subcategory' || type === 'product') {
          if (isItemSelected(category_id, 'categories')) {
            newSelected.categories = newSelected.categories.filter(
              c => c.id !== category_id
            );
            newSelected.subcategories = [
              ...newSelected.subcategories,
              ...categoriesObj
                .find(group => group.id === group_id)
                .categories.find(category => category.id === category_id)
                .subcategories
            ];
          }
        }

        if (type === 'group') {
          newSelected.groups = newSelected.groups.filter(
            group => group.id !== id
          );
        }

        if (type === 'category') {
          newSelected.categories = newSelected.categories.filter(
            c => c.id !== id
          );
        }
        if (type === 'subcategory') {
          newSelected.subcategories = newSelected.subcategories.filter(
            s => s.id !== id
          );
        }

        if (type === 'product') {
          if (isItemSelected(subcategory_id, 'subcategories')) {
            newSelected.subcategories = newSelected.subcategories.filter(
              s => s.id !== subcategory_id
            );
            newSelected.products = [
              ...newSelected.products,
              ...state.productsData[subcategory_id]
            ];
          }
          newSelected.products = newSelected.products.filter(p => p.id !== id);
        }
      } else {
        if (type === 'group' || type === 'category' || type === 'subcategory') {
          newSelected.products = newSelected.products.filter(
            p => p.subcategory_id !== id
          );
        }
        if (type === 'group' || type === 'category') {
          newSelected.subcategories = newSelected.subcategories.filter(
            s => s.group_id !== id
          );
        }
        if (type === 'group') {
          newSelected.groups = [...newSelected.groups, item];
          newSelected.categories = newSelected.categories.filter(
            c => c.group_id !== id
          );
        }
        if (type === 'category') {
          newSelected.categories = [...newSelected.categories, item];
        }
        if (type === 'subcategory') {
          newSelected.subcategories = [...newSelected.subcategories, item];
        }
        if (type === 'product') {
          newSelected.products = [...newSelected.products, item];
        }
      }

      return { ...state, selected: newSelected };
    });
  }

  onUpdateClick() {
    const { selected } = this.props;

    this.setState({ updateModalStatus: true, selected });
  }

  onClose() {
    this.setState({ updateModalStatus: false, selected: null });
  }

  getSubmitBtnComponent() {
    return (
      <Button
        color='primary'
        text={t('Save')}
        onClick={() => {
          this.props.onSubmit(this.state.selected);
          this.onClose();
        }}
      />
    );
  }

  render() {
    const { categoriesObj, handleError, selected: propsSelected } = this.props;
    const { selected, productsData, updateModalStatus } = this.state;

    if (!categoriesObj) return <Loader />;

    return (
      <Box width={1} display='flex' flexDirection='column'>
        <Box width={1} display='flex' flexDirection='row-reverse'>
          <IconButton
            className='update-icon'
            onClick={this.onUpdateClick}
            icon={<EditIcon />}
            alt='update'
            disabled={this.props.updateActionDisableStatus}
            tooltipMsg={this.props.updateActionDisableMsg}
          />
        </Box>
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          disableSelection
        >
          <ProductsTreeGroups
            categoriesObj={categoriesObj}
            productsData={productsData}
            treeItemsToDisplay={ProductsTree.setTreeItemsToDisplay(
              propsSelected
            )}
            selected={propsSelected}
            onClick={this.onClick}
            onSelect={this.onSelect}
            handleError={handleError}
          />
        </TreeView>
        {updateModalStatus && (
          <CustomDialog
            open
            onClose={this.onClose}
            title={t('Update products assigned to Product manager')}
            actions={this.getSubmitBtnComponent()}
          >
            <TreeView
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              disableSelection
            >
              <ProductsTreeGroups
                categoriesObj={categoriesObj}
                productsData={productsData}
                selected={selected}
                onClick={this.onClick}
                onSelect={this.onSelect}
                handleError={handleError}
                selectMode
              />
            </TreeView>
          </CustomDialog>
        )}
      </Box>
    );
  }
}

const selectedPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  group_id: PropTypes.number,
  category_id: PropTypes.number,
  subcategory_id: PropTypes.number
});

ProductsTree.defaultProps = {
  updateActionDisableMsg: null
};

ProductsTree.propTypes = {
  categoriesObj: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.shape({
    groups: PropTypes.arrayOf(selectedPropType),
    categories: PropTypes.arrayOf(selectedPropType),
    subcategories: PropTypes.arrayOf(selectedPropType),
    products: PropTypes.arrayOf(selectedPropType)
  }).isRequired,
  handleError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateActionDisableStatus: PropTypes.bool.isRequired,
  updateActionDisableMsg: PropTypes.string
};

export default ProductsTree;
