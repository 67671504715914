import ApiModel from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class PostalCodesApi extends ApiModel {
  constructor() {
    super();

    this.postalCodesCountriesPath = 'postal-codes/countries';
    this.postalCodesRegionsPath = 'postal-codes/regions';
    this.postalCodesSubRegionsPath = 'postal-codes/subregions';
    this.postalCodesSubSubRegionsPath = 'postal-codes/subsubregions';
    this.citiesPath = 'postal-codes/cities';
    this.postalCodesPath = 'postal-codes';

    this.deleteCountryPath = `${this.postalCodesCountriesPath}/:id`;
    this.deleteRegionPath = `${this.postalCodesRegionsPath}/:id`;
    this.deleteSubRegionPath = `${this.postalCodesSubRegionsPath}/:id`;
    this.deleteSubSubRegionPath = `${this.postalCodesSubSubRegionsPath}/:id`;
    this.deleteCityPath = `${this.citiesPath}/:id`;
    this.deletePostalCodePath = `${this.postalCodesPath}/:id`;
  }

  getCountries(params) {
    return this.get(this.postalCodesCountriesPath, params);
  }

  getRegions(params) {
    return this.get(this.postalCodesRegionsPath, params);
  }

  getSubRegions(params) {
    return this.get(this.postalCodesSubRegionsPath, params);
  }

  getSubSubRegions(params) {
    return this.get(this.postalCodesSubSubRegionsPath, params);
  }

  getCities(params) {
    return this.get(this.citiesPath, params);
  }

  getPostalCodes(params) {
    return this.get(this.postalCodesPath, params);
  }

  deleteRegion(id) {
    return this.delete(insertPathParams(this.deleteRegionPath, { id }));
  }

  deleteSubRegion(id) {
    return this.delete(insertPathParams(this.deleteSubRegionPath, { id }));
  }

  deleteSubSubRegion(id) {
    return this.delete(insertPathParams(this.deleteSubSubRegionPath, { id }));
  }

  deleteCity(id) {
    return this.delete(insertPathParams(this.deleteCityPath, { id }));
  }

  deletePostalCode(id) {
    return this.delete(insertPathParams(this.deletePostalCodePath, { id }));
  }

  createItem(path, payload) {
    return this.post(`${path}`, payload);
  }

  updateItem(path, payload, id) {
    return this.put(`${path}/`, payload, id);
  }
}

export default new PostalCodesApi();
