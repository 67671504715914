import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Tab } from '@material-ui/core';
import Tabs from 'modules/Layout/component/Tabs';
import TabPanel from 'modules/Layout/component/TabPanel';
import t from 'translate/translate';
import VisitBasicData from 'modules/Visits/view/Details/BasicData';
import VisitNotesView from 'modules/Visits/view/Details/Notes';
import VisitDetailsFilesView from 'modules/Visits/view/Details/Files';
import VisitDetailsProductsView from 'modules/Visits/view/Details/Products/DetailsWrapper';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_VISITS_LIST } from 'routing/routes/Visits';
import PageLoader from 'modules/Layout/component/PageLoader';
import VisitDetailsOrders from 'modules/Visits/view/Details/Orders';

const VisitDetails = ({ contextMethods }) => {
  const { id: visitId } = useParams();
  const { history } = useHistory();
  const { setAlert, setCurrentPage } = contextMethods;

  const [visit, setVisit] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchVisit = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data: fetchedVisit }
      } = await VisitsApi.getVisit(visitId);

      setVisit(fetchedVisit);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err instanceof ApiError) {
        const { message } = err.getPayload();

        setAlert(message);

        if (err instanceof NotFoundApiError) {
          history.push(ROUTE_VISITS_LIST);
        }
      }
    }
  };

  useEffect(() => {
    setCurrentPage(t('Visit details'));
    fetchVisit();
  }, []);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Box width={1} display='flex' justifyContent='center'>
          <Box
            width={{ xs: 1, md: '100%' }}
            display='flex'
            flexDirection='column'
          >
            <Tabs>
              <Tab label={t('General data')} value='general_data' />
              <Tab label={t('Files')} value='files' />
              <Tab label={t('Notes')} value='notes' />
              <Tab
                label={t('Remembered products')}
                value='remembered_products'
              />
              <Tab label={t('Orders')} value='orders' />
            </Tabs>
            <TabPanel index='general_data'>
              <VisitBasicData
                visit={visit}
                setAlert={setAlert}
                fetchVisit={fetchVisit}
              />
            </TabPanel>
            <TabPanel index='files'>
              <VisitDetailsFilesView visit={visit} setAlert={setAlert} />
            </TabPanel>
            <TabPanel index='notes'>
              <VisitNotesView visit={visit} setAlert={setAlert} />
            </TabPanel>
            <TabPanel index='remembered_products'>
              <VisitDetailsProductsView
                visit={visit}
                setAlert={setAlert}
                fetchVisit={fetchVisit}
              />
            </TabPanel>
            <TabPanel index='orders'>
              <VisitDetailsOrders clientId={visit.client_id} />
            </TabPanel>
          </Box>
        </Box>
      )}
    </>
  );
};

VisitDetails.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default VisitDetails;
