import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ClientsNotesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.clientNotesPath = 'clients/:id/notes';
    this.clientNotePath = 'clients/:clientId/notes/:noteId';
  }

  getClientNotes(id, pathParams) {
    return this.get(insertPathParams(this.clientNotesPath, { id }), pathParams);
  }

  createClientNote(id, payload) {
    return this.post(insertPathParams(this.clientNotesPath, { id }), payload);
  }

  updateClientNote(ids, payload) {
    return this.put(insertPathParams(this.clientNotePath, ids), payload);
  }

  deleteClientNote(ids) {
    return this.delete(insertPathParams(this.clientNotePath, ids));
  }
}

export default new ClientsNotesApi();
