import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';
import insertPathParams from 'api/utils/insertPathParams';
import Table from 'modules/Layout/component/List/Table';
import { ROUTE_CLIENTS_BONUSES_DETAILS } from 'routing/routes/ClientsBonuses';
import { UNCONFIRMED_CLIENTS_CONTRACT_STATUS } from 'modules/ClientsContracts/clientContractStatuses';
import { useContext } from 'react';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { CLIENT } from 'api/auth/roles';
import renderPrice from 'modules/Products/utils/renderPrice';

const ClientsContractsDetailsBonuses = props => {
  const history = useHistory();

  const { hasRole } = useContext(AuthContext);

  const { status, client_id, bonuses_items } = props.clientContract;

  const onBonusDetailsCLick = id => {
    history.push(insertPathParams(ROUTE_CLIENTS_BONUSES_DETAILS, { id }));
  };

  const getCols = () => {
    let cols = [
      {
        property: 'client_name',
        label: 'Client name',
        sortable: false
      }
    ];

    if (status === UNCONFIRMED_CLIENTS_CONTRACT_STATUS) {
      if (bonuses_items[0].current_update_date) {
        cols = [
          ...cols,
          {
            property: 'current_value',
            label: 'Current purchase value',
            sortable: false,
            nullable: true,
            render: (value, { currency }) => renderPrice(value, currency)
          },
          {
            property: 'current_bonus',
            label: 'Current bonus value',
            sortable: false,
            nullable: true,
            render: (value, { currency }) => renderPrice(value, currency)
          },
          {
            property: 'current_estimation_value',
            label: 'Estimated purchase value',
            sortable: false,
            nullable: true,
            render: (value, { currency }) => renderPrice(value, currency)
          },
          {
            property: 'current_estimation_bonus',
            label: 'Estimated bonus value',
            sortable: false,
            nullable: true,
            render: (value, { currency }) => renderPrice(value, currency)
          },
          {
            property: 'current_update_date',
            label: 'Estimating date',
            sortable: false
          }
        ];
      } else {
        cols = [
          ...cols,
          {
            property: 'status',
            label: 'Estimated values',
            sortable: false,
            render: () =>
              t(
                'The system has not yet set an estimated contract achievement level.'
              )
          }
        ];
      }
    } else {
      cols = [
        ...cols,
        {
          property: 'result_value',
          label: 'Purchase value',
          sortable: false,
          nullable: true,
          render: (value, { currency }) => renderPrice(value, currency)
        },
        {
          property: 'final_value',
          label: 'Bonus value',
          sortable: false,
          nullable: true,
          render: (value, { currency }) => renderPrice(value, currency)
        }
      ];
    }

    if (!hasRole(CLIENT)) {
      cols = [
        ...cols,
        {
          property: 'ACTIONS',
          label: 'Actions',
          sortable: false,
          displayStatus: true,
          actionOptions: {
            details: {
              status: true,
              disable: false,
              disableMsg: null,
              onClick: ({ id }) => onBonusDetailsCLick(id)
            },
            update: {
              status: false,
              disable: false,
              disableMsg: null,
              onClick: null
            },
            remove: {
              status: false,
              disable: false,
              disableMsg: null,
              onClick: null
            }
          }
        }
      ];
    }

    return cols;
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Typography component='h6' variant='h6' style={{ marginBottom: '1rem' }}>
        {client_id ? t('Client bonus') : t('Clients bonuses')}
      </Typography>
      {isEmpty(bonuses_items) ? (
        <Typography variant='h6'>
          {t('The system did not create a bonuses yet')}
        </Typography>
      ) : (
        <Table cols={getCols()} rows={bonuses_items} actions dense />
      )}
    </Box>
  );
};

ClientsContractsDetailsBonuses.propTypes = {
  clientContract: PropTypes.shape({
    status: PropTypes.number.isRequired,
    client_id: PropTypes.number,
    bonuses_items: PropTypes.arrayOf(
      PropTypes.shape({
        current_update_date: PropTypes.string
      }).isRequired
    )
  }).isRequired
};

export default ClientsContractsDetailsBonuses;
