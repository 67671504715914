import { isEmpty } from 'lodash/lang';

export const prepareCategories = data => {
  const result = {
    productGroups: [],
    categories: [],
    subCategories: []
  };
  data.forEach(productGroup => {
    result.productGroups.push({
      id: productGroup.id,
      name: productGroup.name
    });
    if (!isEmpty(productGroup.categories)) {
      productGroup.categories.forEach(category => {
        result.categories.push({
          id: category.id,
          name: category.name
        });
        if (!isEmpty(category.subcategories)) {
          category.subcategories.forEach(subcategory => {
            result.subCategories.push({
              id: subcategory.id,
              name: subcategory.name
            });
          });
        }
      });
    }
  });

  return result;
};

export const addParentsIdsToCategoriesObj = categoriesObj => {
  return categoriesObj.map(group => {
    return {
      id: group.id,
      name: group.name,
      type: 'group',
      categories: group.categories.map(category => {
        return {
          id: category.id,
          name: category.name,
          group_id: group.id,
          type: 'category',
          subcategories: category.subcategories.map(subcategory => {
            return {
              id: subcategory.id,
              name: subcategory.name,
              group_id: group.id,
              category_id: category.id,
              type: 'subcategory'
            };
          })
        };
      })
    };
  });
};

export const formatProductCategoriesError = errors => {
  const newValidateState = {};
  /* eslint no-restricted-syntax: off */
  for (const [key, value] of Object.entries(errors)) {
    const splitResult = key.split('.');
    newValidateState[splitResult[1]] = {
      status: true,
      message: value
    };
  }
  return newValidateState;
};
