import { Component } from 'react';
import PropTypes from 'prop-types';
import ApiError from 'api/exceptions/ApiError';
import ProductsApi from 'api/connections/Products/ProductsApi';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ClientTopSellersFilter from 'modules/Clients/component/Details/TopSellers/Filter';
import ClientTopSellersTable from 'modules/Clients/component/Details/TopSellers/Table';
import { filterData } from 'modules/Products/view/TopSellers';

class ClientTopSellers extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      filter: {
        country_id: props.client.country_id,
        category_id: ''
      },
      sort: {
        sort_field: 'bought_quantity',
        sort_order: 'desc'
      },
      filterValidation: {},
      topSellers: [],
      categories: [],
      loading: false
    };

    this.setFilter = this.setFilter.bind(this);
    this.setSort = this.setSort.bind(this);
  }

  componentDidMount() {
    this.fetchTopSellers();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setFilter(filter) {
    const categoryChangeStatus =
      this.state.filter.category_id !== filter.category_id;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          ...filter
        }
      },
      () => {
        if (!categoryChangeStatus) this.fetchTopSellers();
      }
    );
  }

  setSort(sort) {
    this.setState({ sort: { ...this.state.sort, ...sort } });
  }

  filterTopSellers() {
    const { topSellers } = this.state;
    const { category_id } = this.state.filter;
    const { sort_order } = this.state.sort;

    let result = [...topSellers];

    if (category_id) {
      result = topSellers.filter(item => item.category_id === category_id);
    }

    result = result.sort((a, b) => {
      if (sort_order === 'asc') {
        return a.bought_quantity < b.bought_quantity ? -1 : 0;
      }
      return a.bought_quantity > b.bought_quantity ? -1 : 0;
    });

    return result;
  }

  async fetchTopSellers() {
    try {
      this.setState({ loading: true });

      const {
        data: { data }
      } = await ProductsApi.getTopSellers({
        client_id: this.props.client.id
      });

      const { categories, topSellers } = filterData(data);

      this.setState({ topSellers, categories, loading: false });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { filter, sort, categories, loading, countries } = this.state;

    const filteredTopSellers = this.filterTopSellers();

    return (
      <>
        <ClientTopSellersFilter
          filter={filter}
          validation={this.state.filterValidation}
          setFilter={this.setFilter}
          categories={categories}
          countries={countries}
        />
        <ClientTopSellersTable
          topSellers={filteredTopSellers}
          fetchProducts={this.fetchProducts}
          loading={loading}
          sort={sort}
          setSort={this.setSort}
        />
      </>
    );
  }
}

ClientTopSellers.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    country_id: PropTypes.number.isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default ClientTopSellers;
