import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import { ROUTE_SALES_RESULT_DETAILS } from 'routing/routes/SalesResults';

class SalesResultsTable extends Component {
  constructor(props) {
    super(props);

    this.onDetailsClick = this.onDetailsClick.bind(this);
  }

  onDetailsClick({ month, department_id }) {
    const path = `${ROUTE_SALES_RESULT_DETAILS}?department_id=${department_id}&month=${month}`;
    this.props.history.push(path);
  }

  getCols() {
    return [
      {
        property: 'month',
        label: 'Month',
        sortable: true
      },
      {
        property: 'department_name',
        label: 'Department name',
        sortable: true
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          }
        }
      }
    ];
  }

  render() {
    const { salesResults, sort, setSort, loading } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={salesResults}
          sort={sort}
          setSort={setSort}
          loading={loading}
          actions
        />
      </>
    );
  }
}

SalesResultsTable.defaultProps = {
  salesResults: []
};

SalesResultsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  salesResults: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(SalesResultsTable);
