import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import useStyles from 'modules/Auth/component/Login/Form/style';
import ApiError from 'api/exceptions/ApiError';
import { validate } from 'modules/Shared/utils/validator';
import t from 'translate/translate';
import { ROUTE_PASSWORD_RESET } from 'routing/routes/Auth';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const Form = () => {
  const classes = useStyles();
  const { login } = useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);

  const [user, setUser] = useState({
    username: '',
    password: ''
  });

  const [validation, setValidation] = useState({
    username: {
      status: false,
      message: t(
        'Pole <%= field %> is required, and should be in the correct format',
        { field: t('Email') }
      )
    },
    password: {
      status: false,
      message: t(
        'Password must contain at least 8 characters, including at least 1 uppercase letter, lowercase letter and a number.'
      )
    }
  });

  const [loading, setLoading] = useState(false);

  const onChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setValidation({
      ...validation,
      [e.target.name]: { ...validation[e.target.name], status: false }
    });
  };

  const handleResponse = res => {
    // TODO wyciągnąć do Api model
    if (res instanceof ApiError) {
      setAlert(res.getPayload().message);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const [isValid, newValidateState] = validate(user, validation);
    if (!isValid) {
      setValidation(newValidateState);
    } else {
      setLoading(true);
      const res = await login(user);
      setLoading(false);
      handleResponse(res);
    }
  };

  return (
    <form noValidate className={classes.form} onSubmit={onSubmit}>
      <Input
        name='username'
        label='Email'
        type='email'
        required
        value={user.username}
        onChange={onChange}
        errorStatus={validation.username.status}
        errorText={validation.username.message}
      />
      <Input
        name='password'
        label='Password'
        type='password'
        required
        value={user.password}
        onChange={onChange}
        errorStatus={validation.password.status}
        errorText={validation.password.message}
      />
      <Button
        type='submit'
        fullWidth
        text='Zaloguj'
        loading={loading}
        color='primary'
      />
      <Grid container>
        <Grid item xs>
          <Link to={ROUTE_PASSWORD_RESET} className='router-link'>
            <Typography color='primary'>{t('Forget password?')}</Typography>
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
