export default {
  Products: 'Produkty',
  Product: 'Produkt',
  EAN: 'EAN',
  SKU: 'SKU',
  Price: 'Cena',
  'Price (net)': 'Cena (netto)',
  'Batches total': 'Stan magazynowy',
  'Min batches': 'Minimalny stan magazynowy',
  'Max batches': 'Maksymalny stan magazynowy',
  'My products': 'Moje produkty',
  Index: 'Index',
  Taxes: 'Podatek',
  'Product details': 'Szczegóły produktu',
  'Adding an product': 'Dodawanie produktu',
  'Product update': 'Edycja produktu',
  Batches: 'Partie produktowe',
  'Create product batch': 'Dodawanie partii produktowej',
  'Update product batch': 'Edytowanie partii produktowej',
  'Create batch': 'Dodawanie partii',
  'Update batch': 'Edycja partii',
  Quantity: 'Ilość',
  'Expiration date': 'Termin ważności',
  'Are you sure you want to delete batch with batch number <%=number%> and expiration date equal to <%=expiration_date%>':
    'Czy na pewno chcesz usunać partię o numerze <%=number%> i terminie ważności równemu <%=expiration_date%>',
  Description: 'Opis',
  'Date of sale': 'Data wprowadzenia do sprzedaży',
  'Globally available': 'Dostępny globalnie',
  'Wrong Date format, correct one is dd.MM.yyyy':
    'Niewłaściwy format daty, poprawny to dd.MM.yyyy',
  'Product limited to countries': 'Produkt ograniczony do krajów',
  'Are you sure you want to delete product <%=sku%>':
    'Czy na pewno chcesz usunać produkt <%=sku%>',
  'Deleting product successfully finish':
    'Usuwanie produktu zakończone sukcesem',
  'Product Wholesaler Mapping': 'Mapowania produktów w hurtowniach',
  'External identifier': 'Zewnętrzny identyfikator',
  'Create product mapping': 'Dodawanie mapowania produktu',
  'Update product mapping': 'Edytowanie mapowania produktu',
  'Create mapping': 'Dodawanie mapowania',
  'Update mapping': 'Edytowanie mapowania',
  'Product name': 'Nazwa produktu',
  'Are you sure you want to delete mapping for product <%=productSku%> and wholesaler <%=wholesalerName%>':
    'Czy na pewno chcesz usunać mapowanie dla produktu <%=productSku%> i hurtowni <%=wholesalerName%>',
  Identification: 'Identyfikacja',
  Others: 'Inne',
  'Other identifications': 'Inne informacje',
  'Only available in countries': 'Wyłącznie dostępne w krajach',
  'Information about market availability': 'Informacje o dostępności na rynku',
  'The product is available worldwide by default. In order to limit the availability to selected countries, fill in the field "Product limited to countries". Then the product will be visible only to employees assigned to the indicated countries.':
    'Produkt domyślnie jest dostępny na całym świecie. W celu ograniczenia dostępności do wybranych krajów, należy uzupełnić pole "Produkt ograniczony do krajów". Wówczas produkt będzie widoczny tylko dla pracowników przypisanych do wskazanych krajów.',
  'Product available only in direct orders':
    'Produkt dostępny tylko w zamówieniach bezpośrednich',
  'Products available only in direct orders':
    'Produky dostępne tylko w zamówieniach bezpośrednich',
  'Category dont have any products':
    'Podkategoria nie posiada żadnego produktu',
  'Update products assigned to Product manager':
    'Edytuj produkty przypisane do Menadżera produktów',
  Priority: 'Priorytet',
  'All priorities': 'Wszystkie priorytety',
  'Show archived priorities': 'Pokaż zarchiwizowane priorytety',
  'Are you sure you want to delete priority?':
    'Czy na pewno chcesz usunać priorytet?',
  'Create priority': 'Dodaj priorytet',
  'Update priority': 'Edytuj priorytet',
  'Export products': 'Eksport produktów',
  'Based on the country you select, tax, price and other country-specific data will be selected.':
    'Na bazie wskazanego kraju wybrany zostanie podatek, cena i inne dane zależne od kraju.',
  'Products Categories': 'Kategorie Produktowe',
  'add product': 'dodaj produkt',
  'You cannot delete product when there are open sales targets for that product.':
    'Nie można usunąć produktu, kiedy są niezamknięte cele sprzedażowe dla tego produktu.',
  'Product code': 'Kod produktu',
  'Pick dates first': 'Wybierz najpierw daty',
  'Batch number': 'Numer partii',
  'Top sellers': 'Top sellery',
  'Product unavailable in promotions': 'Produkt niedostępny w promocjach'
};
