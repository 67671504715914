/* eslint no-return-assign: ["off"] */
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import { Box, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from 'modules/ProductCategories/view/List/style';
import { ROUTE_PRODUCT_CATEGORIES_CREATE } from 'routing/routes/ProductCategories';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import ApiError from 'api/exceptions/ApiError';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductCategoriesItem from 'modules/ProductCategories/components/Item';
import Authorize from 'modules/Auth/component/Authorize';
import ProductCategoriesDeleteDialog from 'modules/ProductCategories/components/DeleteDialog';
import {
  TYPE_GROUP,
  TYPE_CATEGORY,
  TYPE_SUBCATEGORY,
  ProductCategoriesContext
} from 'modules/ProductCategories/context/ProductCategoriesProvider';
import t from 'translate/translate';
import { PRODUCT_CATEGORIES_CREATE } from 'api/auth/permissions/Products';

const EmptyColumnInfo = ({ info }) => {
  return (
    <Box display='flex' justifyContent='center' mt={2}>
      <Typography variant='h6'>{t(info)}</Typography>
    </Box>
  );
};

class ProductCategoriesList extends Component {
  static contextType = ProductCategoriesContext;

  groupElRef = null;

  categoriesElRef = null;

  subCategoriesElRef = null;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      width: null
    };
    const {
      contextMethods: { setAlert, setCurrentPage },
      classes
    } = props;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
    this.classes = classes;

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onClick = this.onClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  async componentDidMount() {
    this.setCurrentPage('Product categories');
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    await this.fetchData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onClick(item) {
    const scrollTo = this.context.itemSelected(item);

    if (scrollTo === TYPE_GROUP) this.scrollToRef(this.groupElRef);
    if (scrollTo === TYPE_CATEGORY) this.scrollToRef(this.categoriesElRef);
    if (scrollTo === TYPE_SUBCATEGORY)
      this.scrollToRef(this.subCategoriesElRef);
  }

  onAddBtnClick(type) {
    this.context.addBtnClicked(type);
    this.props.history.push(ROUTE_PRODUCT_CATEGORIES_CREATE);
  }

  scrollToRef(ref) {
    const options = {
      top: this.state.width <= 960 ? ref.offsetTop : 0,
      left: 0,
      behavior: 'smooth'
    };

    window.scrollTo(options);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  async fetchData() {
    try {
      const { data } = await ProductCategoriesApi.getProductCategories();
      this.setState({ data });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      } else {
        console.error(err);
      }
    }
  }

  renderAddButton(type) {
    return (
      <Authorize permissions={[PRODUCT_CATEGORIES_CREATE]}>
        <Box display='flex' justifyContent='center'>
          <IconButton
            icon={<AddIcon fontSize='large' />}
            onClick={() => this.onAddBtnClick(type)}
            className='create-icon'
            alt='create'
          />
        </Box>
      </Authorize>
    );
  }

  renderGroup() {
    if (isEmpty(this.state.data)) {
      return (
        <Box display='flex' justifyContent='center' mt='3rem'>
          <CircularProgress />
        </Box>
      );
    }
    return (
      <Box>
        {this.state.data.map(productGroup => this.renderItem(productGroup))}
        {this.renderAddButton(TYPE_GROUP)}
      </Box>
    );
  }

  renderCategories() {
    if (!this.context.selectedGroupId || isEmpty(this.state.data)) return null;
    const items = this.state.data.find(
      productGroup => productGroup.id === this.context.selectedGroupId
    ).categories;

    if (isEmpty(items))
      return (
        <>
          <EmptyColumnInfo info='No categories' />
          {this.renderAddButton('category')}
        </>
      );

    return (
      <Box>
        {items.map(category => this.renderItem(category))}
        {this.renderAddButton(TYPE_CATEGORY)}
      </Box>
    );
  }

  renderSubCategories() {
    if (!this.context.selectedCategoryId || isEmpty(this.state.data))
      return null;

    const items = this.state.data
      .find(productGroup => productGroup.id === this.context.selectedGroupId)
      .categories.find(
        category => category.id === this.context.selectedCategoryId
      ).subcategories;

    if (isEmpty(items))
      return (
        <>
          <EmptyColumnInfo info='No subcategories' />
          {this.renderAddButton(TYPE_SUBCATEGORY)}
        </>
      );

    return (
      <Box>
        {items.map(subCategory => this.renderItem(subCategory))}
        {this.renderAddButton('subcategory')}
      </Box>
    );
  }

  renderItem(item) {
    return (
      <ProductCategoriesItem key={item.id} item={item} onClick={this.onClick} />
    );
  }

  render() {
    return (
      <Box className={this.classes.mainWrapper}>
        <Box className={this.classes.columnWrapper}>
          <Typography
            variant='h5'
            component='h5'
            className={this.classes.columnTitle}
          >
            {t('Product groups')}
          </Typography>
          <Box
            className={this.classes.column}
            ref={ref => (this.groupElRef = ref)}
          >
            {this.renderGroup()}
          </Box>
        </Box>
        <Box className={this.classes.columnWrapper}>
          <Typography
            variant='h5'
            component='h5'
            className={this.classes.columnTitle}
          >
            {t('Categories')}
          </Typography>
          <Box
            className={this.classes.column}
            ref={ref => (this.categoriesElRef = ref)}
          >
            {this.renderCategories()}
          </Box>
        </Box>
        <Box className={this.classes.columnWrapper}>
          <Typography
            variant='h5'
            component='h5'
            className={this.classes.columnTitle}
          >
            {t('Subcategories')}
          </Typography>
          <Box
            className={this.classes.column}
            ref={ref => (this.subCategoriesElRef = ref)}
          >
            {this.renderSubCategories()}
          </Box>
        </Box>
        <ProductCategoriesDeleteDialog fetchData={this.fetchData} />
      </Box>
    );
  }
}

EmptyColumnInfo.propTypes = {
  info: PropTypes.string.isRequired
};

ProductCategoriesList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withStyles(useStyles)(withRouter(ProductCategoriesList));
