import { COLOR_VARIANTS_ERROR } from 'modules/Shared/type';
import t from 'translate/translate';

export default class ApiError extends Error {
  constructor(message = 'ApiError.', error) {
    super(message);

    this.error = error;
  }

  getMessageValue() {
    const { data, statusText } = this.error.response;

    const { message } = data;

    return message || t(statusText);
  }

  getMessage(variant = COLOR_VARIANTS_ERROR) {
    const value = this.getMessageValue();

    if (value) {
      return { value, variant };
    }

    return null;
  }

  getErrors() {
    const { errors } = this.error.response.data;

    if (errors) {
      const object = {};

      Object.entries(this.error.response.data.errors).forEach(
        ([key, values]) => {
          object[key] = values.join(' ');
        }
      );

      return object;
    }

    return null;
  }

  getPayload() {
    return {
      message: this.getMessage(),
      errors: this.getErrors()
    };
  }
}
