import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import t from 'translate/translate';
import { Box, Typography } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import IconButton from 'modules/Layout/component/IconButton';

export const getFileNameAndExtension = file => {
  const parts = file.name.split('.');

  const fileName = parts.slice(0, -1).join();
  const fileExtension = parts.pop();

  return { fileName, fileExtension };
};

const FileUploadButton = props => {
  const [value, setValue] = useState('');
  const { name, onUpload, fullWidth } = props;

  const onChange = e => {
    const file = e.target.files[0];
    const fileNameAndExtension = getFileNameAndExtension(file);

    setValue(file.name);
    onUpload(file, fileNameAndExtension);
  };

  const removeValue = () => {
    setValue('');
    onUpload(null);
  };

  return (
    <>
      {value ? (
        <Box display='flex' alignItems='center'>
          <Typography
            variant='body1'
            component='h1'
            style={{ margin: '0.5rem 1rem' }}
          >
            {`${t('File')}: `}
          </Typography>
          <Typography
            variant='body1'
            component='h1'
            style={{ margin: '0.5rem 1rem' }}
          >
            {value && `${value}`}
          </Typography>
          <IconButton
            className='delete-icon'
            onClick={() => removeValue()}
            icon={<DeleteForeverIcon />}
            size='small'
            alt='delete'
          />
        </Box>
      ) : (
        <>
          <input
            id='upload-file-button'
            name={name}
            type='file'
            style={{ display: 'none' }}
            onChange={onChange}
          />
          <label htmlFor='upload-file-button'>
            <Button
              variant='contained'
              color='primary'
              component='span'
              fullWidth={fullWidth}
            >
              {t('Upload file')}
            </Button>
          </label>
        </>
      )}
    </>
  );
};

FileUploadButton.defaultProps = {
  fullWidth: true
};

FileUploadButton.propTypes = {
  name: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool
};

export default FileUploadButton;
