import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { ROUTE_VISITS_DETAILS } from 'routing/routes/Visits';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import insertPathParams from 'api/utils/insertPathParams';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DateTimePicker from 'modules/Layout/component/Date/DateTimePicker';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import GeocodeApi from 'api/connections/Geocode/GeocodeApi';
import debounce from 'lodash/debounce';
import ApiError from 'api/exceptions/ApiError';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AddClientModal from 'modules/Visits/components/AddClientModal';
import {
  CONFIRMED,
  FINISHED,
  getStatuses,
  PLANNED
} from 'modules/Visits/utils/visitStatuses';
import Select, { formatOptions } from 'modules/Layout/component/Select';

const setPlaceFromClient = client =>
  `${client.city || ''}, ${client.street || ''}, ${client.postal_code || ''}`;

const setPositionFromClient = client => ({
  lat: parseFloat(client.lat),
  long: parseFloat(client.long)
});

const defaultCenter = {
  lat: 52.0,
  lng: 19.5
};

class CreateVisitForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    const {
      setAlert,
      initDate,
      location: { state },
      clients
    } = props;

    this.setFetchPlaceDebounce = debounce(this.fetchPosition, 500);
    this.setAlert = setAlert;

    const clientPlace = clients.length === 1 && setPlaceFromClient(clients[0]);

    const clientPosition =
      clients.length === 1 && setPositionFromClient(clients[0]);

    this.state = {
      formData: {
        client_id: state?.client?.client_id || '',
        description: '',
        place: clientPlace || '',
        lat: clientPosition.lat || '',
        long: clientPosition.long || '',
        scheduled_at: initDate,
        meeting_point_at_the_clients: true,
        status: PLANNED
      },
      chosenClient: null,
      validation: {
        client_id: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Client') })
        },
        scheduled_at: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Visit time')
          })
        },
        place: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Country') })
        }
      },
      loading: false,
      addClientModalOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    const { clients } = this.props;
    if (prevProps.clients !== this.props.clients) {
      if (clients.length === 1) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            place: setPlaceFromClient(clients[0]),
            ...setPositionFromClient(clients[0])
          }
        }));
      }
    }
  }

  getClientName() {
    const { clients, notVisitedClients } = this.props;
    const { client_id } = this.state.formData;
    return [...clients, ...notVisitedClients].find(
      client => client.id === client_id
    )?.name;
  }

  handleResponse = res => {
    const { id } = res;
    this.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(insertPathParams(ROUTE_VISITS_DETAILS, { id }));
  };

  handleError = err => {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.setAlert({
        value: `${t(message.value)}`,
        variant: message.variant
      });
      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();

        this.setState(({ validation: validationState }) => {
          return {
            validation: {
              ...validationState,
              ...newValidateState
            }
          };
        });
      }
    } else {
      console.error(err);
    }
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  onSubmit = async e => {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  };

  onDescriptionChange = e => {
    const { value } = e.target;
    this.setState(prevState => ({
      formData: { ...prevState.formData, description: value }
    }));
  };

  onChange = e => {
    const { name, type } = e.target;
    const { clients } = this.props;

    this.setState(prevState => {
      const { formData, validation } = prevState;
      const value = type === 'checkbox' ? e.target.checked : e.target.value;
      let clientPlace = '';
      const clientsPlaceCheckboxTrue =
        name === 'meeting_point_at_the_clients' && e.target.checked;
      const chosenClient = clients.find(
        client => client.id === formData.client_id
      );

      if (clientsPlaceCheckboxTrue) {
        clientPlace =
          chosenClient &&
          `${chosenClient.city || ''}, ${chosenClient.street || ''}, ${
            chosenClient.postal_code || ''
          }`;
      }

      return {
        formData: {
          ...formData,
          [name]: value,
          place: clientPlace || e.target.value,
          lat: clientsPlaceCheckboxTrue ? chosenClient?.lat : formData.lat,
          long: clientsPlaceCheckboxTrue ? chosenClient?.long : formData.long
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });

    if (name === 'place') {
      this.setFetchPlaceDebounce();
    }
  };

  onStatusChange = e => {
    const { value } = e.target;
    this.setState(prevState => ({
      formData: { ...prevState.formData, status: value }
    }));
  };

  onDateChange = e => {
    const { name, value } = e.target;

    this.setState(prevState => {
      const { formData, validation } = prevState;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  };

  onClientChange = client => {
    const { city, street, postal_code, lat, long, id } = client;
    this.setState({
      chosenClient: {
        client_id: id,
        place: `${city || ''}, ${street || ''}, ${postal_code || ''}`,
        lat,
        long
      }
    });
  };

  onMapClick = async (lat, long) => {
    await this.fetchAddress(lat, long);
  };

  onClientModalClose = () => {
    this.setState({ addClientModalOpen: false, chosenClient: null });
  };

  onClientAddSuccess = () => {
    const { chosenClient } = this.state;
    if (!chosenClient) return;
    const { place, lat, long, client_id } = chosenClient;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        client_id,
        place,
        lat,
        long
      },
      addClientModalOpen: false,
      chosenClient: null
    }));
  };

  onClientModalOpen = () => {
    this.setState({ addClientModalOpen: true });
  };

  makeApiCall = async () => {
    const { formData } = this.state;
    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await VisitsApi.createVisit(formData);
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  fetchPosition = async () => {
    const {
      formData: { place }
    } = this.state;

    if (!place) return null;

    try {
      const {
        data: { results, status }
      } = await GeocodeApi.getAddress({
        address: place,
        key: process.env.REACT_APP_GOOGLE_MAP_KEY
      });

      if (status === 'OK') {
        const { lat, lng: long } = results[0].geometry.location;
        this.setState(prevState => ({
          formData: { ...prevState.formData, lat, long }
        }));
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  fetchAddress = async (lat, long) => {
    try {
      const {
        data: { results, status }
      } = await GeocodeApi.getAddress({
        latlng: `${lat},${long}`,
        key: process.env.REACT_APP_GOOGLE_MAP_KEY
      });

      if (status === 'OK') {
        const { formatted_address: place } = results[0];
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            place,
            meeting_point_at_the_clients: false,
            lat,
            long
          }
        }));
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  runValidation = () => {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  };

  render() {
    const {
      formData: {
        description,
        place,
        scheduled_at,
        meeting_point_at_the_clients,
        lat,
        long,
        client_id,
        status
      },
      validation,
      loading,
      addClientModalOpen,
      chosenClient
    } = this.state;
    const { state: routerState } = this.props;

    return (
      <>
        <form noValidate onSubmit={this.onSubmit}>
          <Box display='flex' alignItems='center'>
            {!routerState.client && (
              <IconButton onClick={this.onClientModalOpen}>
                {client_id ? (
                  <EditIcon className='update-icon' fontSize='inherit' />
                ) : (
                  <AddIcon color='primary' fontSize='large' />
                )}
              </IconButton>
            )}
            {client_id ? (
              <Typography>{this.getClientName()}</Typography>
            ) : (
              <Typography>{t('Add client')}</Typography>
            )}
          </Box>
          <DateTimePicker
            label='Visit time'
            name='scheduled_at'
            required
            onChange={this.onDateChange}
            value={scheduled_at}
            errorStatus={validation.scheduled_at.status}
            errorText={validation.scheduled_at.message}
          />
          <Input
            name='description'
            label='Description'
            value={description}
            multiline
            inputProps={{ maxLength: 500 }}
            onChange={this.onDescriptionChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                name='meeting_point_at_the_clients'
                onChange={this.onChange}
                checked={meeting_point_at_the_clients}
              />
            }
            label={t("Meeting point at the client's")}
          />

          <Input
            name='place'
            label='Place'
            value={place}
            onChange={this.onChange}
            errorStatus={validation.place.status}
            errorText={validation.place.message}
            disabled={meeting_point_at_the_clients}
          />
          <Select
            name='status'
            label='Status'
            value={status}
            required
            options={formatOptions(
              getStatuses([PLANNED, CONFIRMED, FINISHED]),
              'status',
              'name',
              true
            )}
            onChange={this.onStatusChange}
          />
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-around'
            width={1}
          >
            <Box>
              <Button onClick={this.handleCancel} text={t('Cancel')} />
            </Box>
            <Box>
              <Button
                type='submit'
                fullWidth
                color='primary'
                text={t('Save')}
                loading={loading}
                disabled={!client_id}
                tooltipMsg='Choose client first'
              />
            </Box>
          </Box>
        </form>
        <div style={{ width: '100%', height: '60vh' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_KEY
            }}
            defaultCenter={{
              lat: routerState?.client
                ? parseFloat(routerState.client.lat)
                : defaultCenter.lat,
              lng: routerState?.client
                ? parseFloat(routerState.client.long)
                : defaultCenter.lng
            }}
            center={{
              lat: parseFloat(lat) || defaultCenter.lat,
              lng: parseFloat(long) || defaultCenter.lng
            }}
            defaultZoom={routerState?.client ? 8 : 6}
            zoom={lat ? 8 : 6}
            onClick={({ lat: placeLat, lng: placeLong }) =>
              this.onMapClick(placeLat, placeLong)
            }
          >
            <IconButton
              lat={parseFloat(lat) || 0.0}
              lng={parseFloat(long) || 0.0}
              style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <RoomIcon fontSize='large' style={{ color: '#c73a3a' }} />
            </IconButton>
          </GoogleMapReact>
        </div>
        {addClientModalOpen && (
          <AddClientModal
            onSuccess={this.onClientAddSuccess}
            open={addClientModalOpen}
            onClose={this.onClientModalClose}
            onClientChangeFn={this.onClientChange}
            chosenClient={chosenClient}
          />
        )}
      </>
    );
  }
}

CreateVisitForm.defaultProps = {
  location: {},
  state: {}
};

CreateVisitForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      street: PropTypes.string,
      postal_code: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      long: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  notVisitedClients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAlert: PropTypes.func.isRequired,
  initDate: PropTypes.string.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      client: PropTypes.shape({
        client_id: PropTypes.number
      })
    })
  }),
  state: PropTypes.shape({ client: PropTypes.shape({}) })
};

export default withRouter(CreateVisitForm);
