import PropTypes from 'prop-types';
import { Box, List, Typography } from '@material-ui/core';
import ListElement from 'modules/Visits/components/RoutePlanning/VisitsList/ListItem';
import { SortableContainer } from 'react-sortable-hoc';
import useStyles from 'modules/Visits/components/RoutePlanning/VisitsList/styles';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';

const SortableList = SortableContainer(({ visits, onVisitDelete }) => {
  const classes = useStyles();

  return (
    <List className={classes.column}>
      {visits.map((visit, index) => (
        <ListElement
          key={visit.id}
          index={index}
          visit={visit}
          onVisitDelete={() => onVisitDelete(visit.id)}
          disabled={Boolean(visit.scheduled_at)}
        />
      ))}
    </List>
  );
});

const VisitsList = ({
  visits,
  onSortEnd,
  onVisitDelete,
  onPlanningModalOpen
}) => (
  <>
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Typography variant='h5'>{t('Visits list')}</Typography>
      <Button
        color='primary'
        text={t('Route planning')}
        onClick={onPlanningModalOpen}
        style={{ marginLeft: 'auto' }}
      />
    </Box>
    <SortableList
      visits={visits}
      onSortEnd={onSortEnd}
      lockAxis='y'
      onVisitDelete={onVisitDelete}
      pressDelay={80}
    />
  </>
);

VisitsList.propTypes = {
  visits: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string }))
    .isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onVisitDelete: PropTypes.func.isRequired,
  onPlanningModalOpen: PropTypes.func.isRequired
};

export default VisitsList;
