import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import t from 'translate/translate';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import ordersStatuses, {
  ORDER_STATUS_PREPARING,
  ORDER_STATUS_SENT_TO_PROCESSING
} from 'modules/Orders/ordersStatuses';
import ApiError from 'api/exceptions/ApiError';
import OrdersApi from 'api/connections/Orders/OrdersApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

class OrderChangeStatusDialog extends Component {
  static contextType = AlertContext;

  static filterStatusOptions() {
    return ordersStatuses.filter(
      s =>
        s.status !== ORDER_STATUS_PREPARING &&
        s.status !== ORDER_STATUS_SENT_TO_PROCESSING
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      status: props.order.status,
      loading: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onChange(e) {
    const { value } = e.target;

    this.setState({ status: value });
  }

  async onSave() {
    const {
      order: { id },
      onClose,
      onSuccess
    } = this.props;
    const { status } = this.state;

    try {
      this.setState({ loading: true });

      await OrdersApi.changeStatus(id, { status });

      this.context.setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });

      onClose();
      onSuccess(status, id);
    } catch (err) {
      err instanceof ApiError
        ? this.context.setAlert(err.getPayload().message)
        : console.error(err);
      onClose();
    }
  }

  render() {
    const { status, loading } = this.state;
    const { onClose } = this.props;

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Change order status')}</DialogTitle>
        <DialogContent>
          <Select
            name='status'
            onChange={this.onChange}
            label='Status'
            value={status}
            options={formatOptions(
              OrderChangeStatusDialog.filterStatusOptions(),
              'status',
              'name',
              true
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button
            color='primary'
            text={t('Save')}
            loading={loading}
            onClick={this.onSave}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

OrderChangeStatusDialog.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default OrderChangeStatusDialog;
