import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import { withRouter } from 'react-router-dom';
import { ROUTE_TARGETS_LIST } from 'routing/routes/Targets';

import CreateTargetBonusForm from 'modules/TargetsBonuses/component/Create/Form';
import TargetsApi from 'api/connections/Targets/TargetsApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';

class TargetsBonusesCreate extends Component {
  constructor(props) {
    super(props);

    this.targetId = parseInt(this.props.match.params.targetId, 10);
    this.state = { target: null };
  }

  componentDidMount() {
    if (!this.targetId) this.props.history.push(ROUTE_TARGETS_LIST);
    this.props.contextMethods.setCurrentPage('Create target bonus');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_TARGETS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: target }
      } = await TargetsApi.getTarget(this.targetId);

      this.setState({ target });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { target } = this.state;

    if (!target) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <CreateTargetBonusForm
              target={target}
              setAlert={this.props.contextMethods.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TargetsBonusesCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      targetId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(TargetsBonusesCreate);
