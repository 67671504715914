import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ClientsFilesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.clientFilesPath = 'clients/:id/files';
    this.clientFilePath = 'clients/:clientId/files/:fileId';
    this.exportClientsPath = 'clients/export';
    this.exportInternetClientsPath = 'clients/internet/export';
  }

  getClientFiles(id, pathParams) {
    return this.get(insertPathParams(this.clientFilesPath, { id }), pathParams);
  }

  getClientFile(ids) {
    const options = {
      responseType: 'blob'
    };
    return this.get(insertPathParams(this.clientFilePath, ids), {}, options);
  }

  createClientFile(id, payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return this.post(
      insertPathParams(this.clientFilesPath, { id }),
      payload,
      options
    );
  }

  updateClientFile(ids, payload) {
    return this.put(insertPathParams(this.clientFilePath, ids), payload);
  }

  deleteClientFile(ids) {
    return this.delete(insertPathParams(this.clientFilePath, ids));
  }

  exportClients(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportClientsPath, pathParams, options);
  }

  exportInternetClients(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportInternetClientsPath, pathParams, options);
  }
}

export default new ClientsFilesApi();
