import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import t from 'translate/translate';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import {
  ROUTE_DISCOUNTS_LIST,
  ROUTE_DISCOUNTS_UPDATE
} from 'routing/routes/Discounts';
import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';
import DiscountsApi from 'api/connections/Discounts/DiscountsApi';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import discountsTypes, {
  PERCENTAGE_DISCOUNTS,
  THRESHOLD_DISCOUNTS,
  PAYMENT_TYPE_DISCOUNTS
} from 'modules/Discounts/discountsTypes';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_SHOPPING_GROUPS_DETAILS } from 'routing/routes/ShoppingGroups';
import { ROUTE_USERS_DETAILS } from 'routing/routes/Users';
import Authorize from 'modules/Auth/component/Authorize';
import {
  DISCOUNTS_DELETE,
  DISCOUNTS_UPDATE
} from 'api/auth/permissions/Discounts';
import IconButton from 'modules/Layout/component/IconButton';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import DiscountsDeleteDialog from 'modules/Discounts/component/DeleteDialog';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class DiscountDetails extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.discountId = parseInt(props.match.params.id, 10);

    this.state = {
      discount: null,
      deleteDialogStatus: false
    };

    this.fetchData = this.fetchData.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  componentDidMount() {
    if (!this.discountId) {
      this.props.history.push(ROUTE_DISCOUNTS_LIST);
    }
    this.setCurrentPage('Discount details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_DISCOUNTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onUpdateClick() {
    const { id } = this.state.discount;

    this.props.history.push(insertPathParams(ROUTE_DISCOUNTS_UPDATE, { id }));
  }

  onDeleteClick() {
    this.setState({ deleteDialogStatus: true });
  }

  onDeleteSuccess() {
    this.props.history.push(ROUTE_DISCOUNTS_LIST);
  }

  onDialogClose() {
    this.setState({
      deleteDialogStatus: false
    });
  }

  async fetchData() {
    try {
      const {
        data: { data: discount }
      } = await DiscountsApi.getDiscount(this.discountId);

      this.setState({ discount });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { discount, deleteDialogStatus } = this.state;

    if (!discount) return <Loader />;

    const {
      name,
      type: typeId,
      client_id,
      client_name,
      sales_group_id,
      sales_group_name,
      created_by,
      created_name,
      created_at,
      updated_by,
      updated_name,
      updated_at,
      type_data,
      can_modify,
      groups,
      categories,
      subcategories
    } = discount;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={8}>
          <Paper className='p1'>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Typography
                  component='h6'
                  variant='h6'
                  style={{ marginBottom: '.5rem' }}
                >
                  {t('Basic data')}
                </Typography>
                <TextDataSet label='Name' data={name} />
                <TextDataSet
                  label='Type'
                  data={t(discountsTypes.find(type => type.id === typeId).name)}
                />
                {client_name && (
                  <TextDataSet
                    label='Client name'
                    data={
                      <Link
                        to={insertPathParams(ROUTE_CLIENTS_DETAILS, {
                          id: client_id
                        })}
                        className='router-link router-link-underline'
                      >
                        {client_name}
                      </Link>
                    }
                  />
                )}
                {sales_group_name && (
                  <TextDataSet
                    label='Shopping group name'
                    data={
                      <Link
                        to={insertPathParams(ROUTE_SHOPPING_GROUPS_DETAILS, {
                          id: sales_group_id
                        })}
                        className='router-link router-link-underline'
                      >
                        {sales_group_name}
                      </Link>
                    }
                  />
                )}
                {created_by && (
                  <Typography>
                    <>
                      {t('Discount created by')}{' '}
                      {
                        <Link
                          to={insertPathParams(ROUTE_USERS_DETAILS, {
                            id: created_by
                          })}
                          className='router-link router-link-underline'
                        >
                          {created_name}
                        </Link>
                      }{' '}
                      {created_at}
                    </>
                  </Typography>
                )}
                {updated_by && (
                  <Typography>
                    <>
                      {t('Last modifications was made by')}{' '}
                      {
                        <Link
                          to={insertPathParams(ROUTE_USERS_DETAILS, {
                            id: updated_by
                          })}
                          className='router-link router-link-underline'
                        >
                          {updated_name}
                        </Link>
                      }{' '}
                      {updated_at}
                    </>
                  </Typography>
                )}
                <Typography
                  component='h6'
                  variant='h6'
                  style={{ marginTop: '1rem', marginBottom: '.5rem' }}
                >
                  {t('Restrictions to categories')}
                </Typography>
                <TextDataSet
                  label='Product groups'
                  data={groups.map(g => g.name)}
                />
                <TextDataSet
                  label='Categories'
                  data={categories.map(c => c.name)}
                />
                <TextDataSet
                  label='Subcategories'
                  data={subcategories.map(s => s.name)}
                />

                <Typography
                  component='h6'
                  variant='h6'
                  style={{ marginTop: '1rem', marginBottom: '.5rem' }}
                >
                  {t('Value of the discount')}
                </Typography>
                {typeId === PERCENTAGE_DISCOUNTS && (
                  <Typography>
                    {t(
                      'A discount of <%=discount%>% of the order is added to each direct order',
                      { discount: (type_data.percentage * 100).toFixed(0) }
                    )}
                  </Typography>
                )}
                {typeId === PAYMENT_TYPE_DISCOUNTS && (
                  <Typography>
                    {t(
                      'A discount of <%=discount%>% of the order is added to the selected direct order',
                      { discount: (type_data.percentage * 100).toFixed(0) }
                    )}
                  </Typography>
                )}
                {typeId === THRESHOLD_DISCOUNTS && (
                  <>
                    <Typography style={{ marginBottom: '1rem' }}>
                      {t(
                        'Discount is applied to each direct order based on the order value'
                      )}
                    </Typography>
                    <Table
                      cols={[
                        {
                          property: 'threshold',
                          label: 'Order value',
                          sortable: false
                        },
                        {
                          property: 'percentage',
                          label: 'Discount value',
                          sortable: false,
                          render: value => `${(value * 100).toFixed(0)}%`
                        }
                      ]}
                      rows={type_data}
                      dense
                    />
                  </>
                )}
              </Box>
              <Box display='flex' alignItems='flex-start'>
                <Authorize permissions={[DISCOUNTS_UPDATE]}>
                  <IconButton
                    className='update-icon'
                    onClick={this.onUpdateClick}
                    icon={<EditIcon />}
                    alt='update'
                    disabled={!can_modify}
                    tooltipMsg='You can only modify discounts assigned to your clients.'
                  />
                </Authorize>
                <Authorize permissions={[DISCOUNTS_DELETE]}>
                  <IconButton
                    className='delete-icon'
                    onClick={this.onDeleteClick}
                    icon={<DeleteForeverIcon />}
                    alt='delete'
                    disabled={!can_modify}
                    tooltipMsg='You can only modify discounts assigned to your clients.'
                  />
                </Authorize>
              </Box>
            </Box>
          </Paper>
        </Grid>
        {deleteDialogStatus && (
          <DiscountsDeleteDialog
            onClose={this.onDialogClose}
            discount={discount}
            onSuccess={this.onDeleteSuccess}
          />
        )}
      </Grid>
    );
  }
}

DiscountDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(DiscountDetails);
