import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ImportsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.importsPath = 'imports';
    this.importFileDownloadPath = 'imports/:id/download';
  }

  getImports(pathParams) {
    return this.get(this.importsPath, pathParams);
  }

  startImport(payload) {
    return this.post(this.importsPath, payload);
  }

  downloadImportFile(id) {
    const options = {
      responseType: 'blob'
    };
    return this.get(
      insertPathParams(this.importFileDownloadPath, { id }),
      {},
      options
    );
  }
}

export default new ImportsApi();
