import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import t from 'translate/translate';
import { CreateVisitContext } from 'modules/Visits/view/Create';
import Checkbox from 'modules/Layout/component/Checkbox';
import Input from 'modules/Layout/component/Input';
import ClientsTable from 'modules/Visits/components/ClientsTable';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = {
  dialogPaper: {
    minHeight: '70vh',
    maxHeight: '70vh'
  }
};

const useStyles = makeStyles(styles);

const filteredClients = (clients, inputs) =>
  clients
    .filter(client =>
      client.name
        .toLowerCase()
        .trim()
        .includes(inputs.name.toLowerCase().trim())
    )
    .filter(client =>
      client.city
        ?.toLowerCase()
        .trim()
        .includes(inputs.city.toLowerCase().trim())
    )
    .filter(client =>
      client.street
        ?.toLowerCase()
        .trim()
        .includes(inputs.street.toLowerCase().trim())
    );

const AddClientModal = ({
  onSuccess,
  onClose,
  open,
  onClientChangeFn,
  chosenClient
}) => {
  const { notVisitedClients, clients, loading } = useContext(
    CreateVisitContext
  );

  const classes = useStyles();

  const [inputs, setInputs] = useState({
    notVisitedClientsCheckbox: true,
    name: '',
    city: '',
    street: ''
  });

  const onCheckboxChange = e => {
    const { checked } = e.target;
    setInputs({ ...inputs, notVisitedClientsCheckbox: checked });
  };

  const onChange = e => {
    const { value, name } = e.target;

    setInputs({ ...inputs, [name]: value });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      maxWidth='md'
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      transitionDuration={{
        enter: 200,
        exit: 100
      }}
    >
      <DialogTitle>{t('Add client')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Input
              onChange={onChange}
              label='Name'
              name='name'
              value={inputs.name}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              onChange={onChange}
              label='City'
              name='city'
              value={inputs.city}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              onChange={onChange}
              label='Street'
              name='street'
              value={inputs.street}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              label='Long ago visited clients'
              onChange={onCheckboxChange}
              checked={inputs.notVisitedClientsCheckbox}
              name='latest_visit_date'
            />
          </Grid>
        </Grid>
        <ClientsTable
          clients={filteredClients(
            inputs.notVisitedClientsCheckbox ? notVisitedClients : clients,
            inputs
          )}
          notVisitedClientsCheckbox={inputs.notVisitedClientsCheckbox}
          loading={loading}
          onClientChangeFn={onClientChangeFn}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          color='primary'
          text={t('Close')}
          onClick={onClose}
        />
        <Button
          type='button'
          color='primary'
          text={t('Add')}
          onClick={onSuccess}
          disabled={!chosenClient}
        />
      </DialogActions>
    </Dialog>
  );
};

AddClientModal.defaultProps = {
  chosenClient: null
};

AddClientModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClientChangeFn: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  chosenClient: PropTypes.shape({})
};

export default AddClientModal;
