import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';

import { ROUTE_SHOPPING_GROUPS_LIST } from 'routing/routes/ShoppingGroups';
import SalesGroupUpdateForm from 'modules/SalesGroups/component/Update/Form';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { withRouter } from 'react-router-dom';
import Loader from 'modules/Layout/component/Loader';

class SalesGroupUpdate extends Component {
  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.salesGroupId = parseInt(props.match.params.id, 10);
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      salesGroup: null
    };
  }

  componentDidMount() {
    this.setCurrentPage('Update shopping group');
    this.fetchData();
  }

  async fetchData() {
    try {
      const [
        {
          data: { data: salesGroup }
        }
      ] = await Promise.all([SalesGroupsApi.getSalesGroup(this.salesGroupId)]);

      this.setState({ salesGroup });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_SHOPPING_GROUPS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { salesGroup } = this.state;

    if (!salesGroup) return <Loader />;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <SalesGroupUpdateForm
              salesGroup={salesGroup}
              setAlert={this.setAlert}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

SalesGroupUpdate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(SalesGroupUpdate);
