import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import Loader from 'modules/Layout/component/Loader';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  History as HistoryIcon
} from '@material-ui/icons';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import {
  CLIENTS_UPDATE,
  CLIENTS_USERS_MANAGEMENT
} from 'api/auth/permissions/Clients';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import {
  ROUTE_CLIENTS_UPDATE,
  ROUTE_CLIENTS_ACCOUNT_CREATE,
  ROUTE_CLIENTS_ACCOUNT_UPDATE,
  ROUTE_INTERNET_CLIENTS_UPDATE
} from 'routing/routes/Clients';
import ClientContactsList from 'modules/Clients/component/Details/Contacts/List';
import ClientAccountDeleteDialog from 'modules/Clients/component/Account/Delete/Dialog';
import AddIcon from '@material-ui/icons/Add';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN
} from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { isEmpty } from 'lodash/lang';
import ClientEvaluationModal from 'modules/Clients/component/Details/BasicData/ClientEvaluationModal';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ApiError from 'api/exceptions/ApiError';
import ClientsUsersApi from 'api/connections/Clients/ClientsUsersApi';
import ClientPreviousUsersModal from 'modules/Clients/component/Details/BasicData/ClientPreviousUsersModal';
import getUsersGroupedByDepartments from 'modules/Clients/utils/getUsersGroupedByDepartments';
import Dialog from 'modules/Layout/component/Dialog';
import { clientBusinessStatuses } from 'modules/Clients/utils/clientBusinessStatuses';

class ClientDetailsBasicData extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const { departments } = this.context.user;

    this.state = {
      accountDeleteDialogStatus: false,
      openEvaluationDialog: false,
      openClientUsersHistoryDialog: false,
      clientUserDeleteDialogStatus: false,
      clientUserToDelete: null,
      departments
    };

    this.onClientAccountCreateClick = this.onClientAccountCreateClick.bind(
      this
    );
    this.onClientAccountUpdateClick = this.onClientAccountUpdateClick.bind(
      this
    );
    this.onClientUsersHistoryClick = this.onClientUsersHistoryClick.bind(this);
    this.onClientUsersHistoryClose = this.onClientUsersHistoryClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDialogOpen = this.onDialogOpen.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onEvaluationDialogClose = this.onEvaluationDialogClose.bind(this);
    this.onEvaluationSuccess = this.onEvaluationSuccess.bind(this);
    this.onClientUserDeleteSuccess = this.onClientUserDeleteSuccess.bind(this);
    this.onClientEvaluationUpdateClick = this.onClientEvaluationUpdateClick.bind(
      this
    );
  }

  componentDidMount() {
    if (this.context.hasRole([SUPER_ADMIN, ADMIN])) {
      this.fetchDepartments();
    }
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  }

  onEvaluationDialogClose() {
    this.setState({ openEvaluationDialog: false });
  }

  onClientEvaluationUpdateClick() {
    this.setState({ openEvaluationDialog: true });
  }

  onClientUsersHistoryClose() {
    this.setState({ openClientUsersHistoryDialog: false });
  }

  onClientUsersHistoryClick() {
    this.setState({ openClientUsersHistoryDialog: true });
  }

  onEditClick() {
    const { id, is_internet } = this.props.client;

    const path = insertPathParams(
      is_internet ? ROUTE_INTERNET_CLIENTS_UPDATE : ROUTE_CLIENTS_UPDATE,
      { id }
    );

    this.props.history.push(path);
  }

  onClientAccountCreateClick() {
    const { id } = this.props.client;

    this.props.history.push(
      insertPathParams(ROUTE_CLIENTS_ACCOUNT_CREATE, { id })
    );
  }

  onClientAccountUpdateClick() {
    const { id } = this.props.client;

    this.props.history.push(
      insertPathParams(ROUTE_CLIENTS_ACCOUNT_UPDATE, { id })
    );
  }

  onClientUserDeleteClick(user) {
    this.setState({
      clientUserDeleteDialogStatus: true,
      clientUserToDelete: { ...user }
    });
  }

  onDialogOpen() {
    this.setState({ accountDeleteDialogStatus: true });
  }

  onDialogClose() {
    this.setState({
      accountDeleteDialogStatus: false,
      clientUserDeleteDialogStatus: false
    });
  }

  onEvaluationSuccess() {
    this.props.fetchClient();
  }

  async onClientUserDeleteSuccess() {
    const { id } = this.state.clientUserToDelete;

    const payload = {
      user_id: id,
      clients: [this.props.client.id]
    };

    try {
      await ClientsUsersApi.deleteManyClientsFromUser(payload);

      this.setState({
        clientUserDeleteDialogStatus: false,
        clientUserToDelete: null
      });

      this.props.fetchClient();
    } catch (err) {
      this.handleError(err);
    }
  }

  getCountry() {
    const {
      client: { country_id: clientCountryId },
      countries
    } = this.props;

    if (!clientCountryId) return null;
    const result = countries.find(country => country.id === clientCountryId);
    return result && result.name;
  }

  setDeleteUserDisableStatus(departmentId, managerId) {
    const { id: loggedUserId, role, departments } = this.context.user;

    if (role === DIRECTOR) {
      return !departments.map(({ id }) => id).includes(departmentId);
    }

    if (role === DEPARTMENT_MANAGER) {
      return loggedUserId !== managerId;
    }

    return false;
  }

  getActiveDepartments() {
    const { activity } = this.props.client;

    return activity.map(d => d.department_name);
  }

  getInactiveDepartments() {
    const { activity, country_id } = this.props.client;
    const { departments } = this.state;
    const activeDepartmentIds = activity.map(item => item.department_id);

    return departments
      .filter(
        department =>
          department.country_id === country_id &&
          !activeDepartmentIds.includes(department.id)
      )
      .map(d => d.name);
  }

  async fetchDepartments() {
    try {
      const {
        data: { data: departments }
      } = await DepartmentsApi.getDepartments({
        per_page: Number.MAX_SAFE_INTEGER,
        country_id: this.context.user.country_id
      });
      this.setState({ departments });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      client: {
        id: clientId,
        name,
        recipient_number,
        tax_number,
        city,
        street,
        postal_code,
        hours,
        contacts,
        sales_group_name: clientSalesGroupName,
        account,
        users,
        evaluation,
        can_update,
        status,
        bank_account_for_bonuses,
        email,
        phone,
        is_internet
      },
      onContactDeleteSuccess,
      onAccountDeleteSuccess,
      setAlert
    } = this.props;

    const {
      accountDeleteDialogStatus,
      openEvaluationDialog,
      openClientUsersHistoryDialog,
      clientUserDeleteDialogStatus,
      clientUserToDelete,
      departments
    } = this.state;

    if (!clientId) return <Loader />;

    const groupedUsers = getUsersGroupedByDepartments(users);

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} md={is_internet ? 12 : 6}>
          <Paper className='p1'>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <TextDataSet label='Name' data={name} />
                <TextDataSet label='Recipient number' data={recipient_number} />
                <TextDataSet label='Tax number' data={tax_number} />
                <TextDataSet label='City' data={city} />
                <TextDataSet label='Street' data={street} />
                <TextDataSet label='Postal code' data={postal_code} />
                <TextDataSet label='Hours' data={hours} />
                <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
                  <TextDataSet label='Country' data={this.getCountry()} />
                </Authorize>
                <TextDataSet label='Main email' data={email} />
                <TextDataSet label='Main phone number' data={phone} />
                {!is_internet && (
                  <TextDataSet
                    label='Shopping group'
                    data={clientSalesGroupName}
                  />
                )}
                <TextDataSet
                  label='Client business status'
                  data={t(clientBusinessStatuses[status])}
                />
                {!is_internet && (
                  <>
                    <TextDataSet
                      label='Client active in department'
                      data={this.getActiveDepartments()}
                      join
                    />
                    <TextDataSet
                      label='Client inactive in department'
                      data={this.getInactiveDepartments()}
                      join
                    />
                  </>
                )}
                <TextDataSet
                  label='Bank account for bonuses'
                  data={bank_account_for_bonuses}
                />
              </Box>
              <Box display='flex' alignItems='flex-start'>
                <Authorize permissions={[CLIENTS_UPDATE]}>
                  <IconButton
                    className='update-icon'
                    onClick={this.onEditClick}
                    icon={<EditIcon />}
                    alt='update'
                    disabled={!can_update}
                    tooltipMsg='The client is not assigned to you or to your subordinate'
                  />
                </Authorize>
              </Box>
            </Box>
          </Paper>
        </Grid>
        {!is_internet && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={3} justify='center'>
              <Grid item xs={12}>
                <Paper className='p1'>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Typography
                      component='h6'
                      variant='h6'
                      style={{ marginBottom: '1rem' }}
                    >
                      {t('Client account')}
                    </Typography>
                    <Authorize permissions={[CLIENTS_UPDATE]}>
                      {account ? (
                        <Box display='flex' alignItems='flex-start'>
                          <IconButton
                            className='update-icon'
                            onClick={this.onClientAccountUpdateClick}
                            icon={<EditIcon />}
                            disabled={!can_update}
                            tooltipMsg='The client is not assigned to you or to your subordinate'
                            alt='update'
                          />
                          <IconButton
                            className='delete-icon'
                            onClick={this.onDialogOpen}
                            icon={<DeleteForeverIcon />}
                            disabled={!can_update}
                            tooltipMsg='The client is not assigned to you or to your subordinate'
                            alt='delete'
                          />
                        </Box>
                      ) : (
                        <IconButton
                          className='create-icon'
                          onClick={this.onClientAccountCreateClick}
                          icon={<AddIcon />}
                          disabled={!can_update}
                          tooltipMsg='The client is not assigned to you or to your subordinate'
                          alt='create'
                        />
                      )}
                    </Authorize>
                  </Box>

                  <Box display='flex' justifyContent='space-between'>
                    {account ? (
                      <Box
                        width={1}
                        display='flex'
                        justifyContent='space-between'
                      >
                        <Box>
                          <TextDataSet
                            label='First name'
                            data={account.first_name}
                          />
                          <TextDataSet
                            label='Last name'
                            data={account.last_name}
                          />
                          <TextDataSet label='Email' data={account.email} />
                          <TextDataSet label='Phone' data={account.phone} />
                        </Box>
                      </Box>
                    ) : (
                      <Typography>
                        {t(`Client doesn't have account`)}
                      </Typography>
                    )}
                  </Box>

                  {accountDeleteDialogStatus && (
                    <ClientAccountDeleteDialog
                      clientId={clientId}
                      clientName={name}
                      onClose={this.onDialogClose}
                      onSuccess={onAccountDeleteSuccess}
                    />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className='p1'>
            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '1rem' }}
            >
              {t('Contacts data')}
            </Typography>
            <ClientContactsList
              clientId={clientId}
              contacts={contacts}
              onContactDeleteSuccess={onContactDeleteSuccess}
            />
          </Paper>
        </Grid>
        {!is_internet && (
          <>
            <Grid item xs={12} md={6}>
              <Paper className='p1'>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography
                    component='h6'
                    variant='h6'
                    style={{ marginBottom: '1rem' }}
                  >
                    {t("Client's users")}
                  </Typography>
                  <Box display='flex' alignItems='flex-start'>
                    <IconButton
                      className='details-icon'
                      onClick={this.onClientUsersHistoryClick}
                      icon={<HistoryIcon />}
                      alt="client's users history"
                    />
                  </Box>
                </Box>
                {groupedUsers.map(department => (
                  <div key={`department-${department.id}`}>
                    <Typography>
                      <strong>{department.name}:</strong>
                    </Typography>
                    <Box ml={3} mb={2}>
                      {department.users.map(user => (
                        <Box
                          key={user.id}
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Typography>{`${t(user.role)}: ${
                            user.name
                          }`}</Typography>
                          <Authorize permissions={[CLIENTS_USERS_MANAGEMENT]}>
                            <IconButton
                              className='delete-icon'
                              onClick={() => this.onClientUserDeleteClick(user)}
                              icon={<DeleteForeverIcon />}
                              size='small'
                              alt='delete'
                              disabled={this.setDeleteUserDisableStatus(
                                department.id,
                                user.manager_id
                              )}
                              tooltipMsg={
                                this.context.user.role === DIRECTOR
                                  ? 'User does not belong to your department'
                                  : 'User is not your subordinate'
                              }
                            />
                          </Authorize>
                        </Box>
                      ))}
                    </Box>
                  </div>
                ))}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className='p1'>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography
                    component='h6'
                    variant='h6'
                    style={{ marginBottom: '1rem' }}
                  >
                    {t("Client's evaluation")}
                  </Typography>
                  <Box display='flex' alignItems='flex-start'>
                    <IconButton
                      className='update-icon'
                      onClick={this.onClientEvaluationUpdateClick}
                      icon={<EditIcon />}
                      disabled={!can_update}
                      tooltipMsg='The client is not assigned to you or to your subordinate'
                      alt='update'
                    />
                  </Box>
                </Box>
                {!isEmpty(evaluation) ? (
                  evaluation.map(
                    ({
                      department_name,
                      cooperation_evaluation,
                      potential_evaluation
                    }) => (
                      <div key={`${department_name}-evaluation`}>
                        <Typography>
                          <strong>{department_name}:</strong>
                        </Typography>
                        <Box ml={3} mb={2}>
                          <Typography>
                            {t('Cooperation')}: {cooperation_evaluation}
                          </Typography>
                          <Typography>
                            {t('Client category')}: {potential_evaluation}
                          </Typography>
                        </Box>
                      </div>
                    )
                  )
                ) : (
                  <Typography>{t('No data')}</Typography>
                )}
              </Paper>
            </Grid>
          </>
        )}
        <ClientEvaluationModal
          open={openEvaluationDialog}
          client={{ client_id: clientId, evaluation }}
          onClose={this.onEvaluationDialogClose}
          onSuccess={this.onEvaluationSuccess}
          setAlert={setAlert}
          departments={departments}
        />
        {openClientUsersHistoryDialog && (
          <ClientPreviousUsersModal
            client={{ client_id: clientId, evaluation }}
            onClose={this.onClientUsersHistoryClose}
            onSuccess={this.onEvaluationSuccess}
            setAlert={setAlert}
            departments={departments}
          />
        )}
        {clientUserDeleteDialogStatus && (
          <Dialog
            open
            title={t('Deletion confirmation')}
            description={t(
              'Are you sure you want to delete user <%=userName%> from client?',
              { userName: clientUserToDelete.name }
            )}
            onApprove={this.onClientUserDeleteSuccess}
            onCancel={this.onDialogClose}
          />
        )}
      </Grid>
    );
  }
}

ClientDetailsBasicData.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number,
    is_internet: PropTypes.number,
    name: PropTypes.string,
    recipient_number: PropTypes.string,
    tax_number: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    postal_code: PropTypes.string,
    hours: PropTypes.string,
    country_id: PropTypes.number,
    contacts: PropTypes.arrayOf(PropTypes.shape({})),
    sales_group_name: PropTypes.string,
    sales_group_id: PropTypes.number,
    account: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string
    }),
    users: PropTypes.arrayOf(PropTypes.shape({})),
    evaluation: PropTypes.arrayOf(PropTypes.shape({})),
    can_update: PropTypes.bool.isRequired,
    status: PropTypes.number.isRequired,
    activity: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    bank_account_for_bonuses: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onContactDeleteSuccess: PropTypes.func.isRequired,
  onAccountDeleteSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  fetchClient: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(ClientDetailsBasicData);
