import PropTypes from 'prop-types';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from 'modules/Layout/component/Accordion/style';
import { isObject } from 'lodash/lang';

const Accordion = props => {
  const { label, children, defaultExpanded } = props;
  const classes = useStyles();

  return (
    <MuiAccordion
      classes={{
        root: classes.accordionRoot,
        rounded: classes.accordionRounded
      }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionSummaryContent
        }}
      >
        {isObject(label) ? label : <Typography>{label}</Typography>}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.defaultProps = {
  children: <></>,
  defaultExpanded: false
};

Accordion.propTypes = {
  label: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
    .isRequired,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool
};

export default Accordion;
