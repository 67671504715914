import { Route } from 'react-router-dom';
import {
  SALES_IMPORTS_LIST,
  SALES_IMPORTS_UPDATE
} from 'api/auth/permissions/SalesImports';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import SalesImportsList from 'modules/SalesImports/view/List';
import SalesImportsDetails from 'modules/SalesImports/view/Details';
import SalesImportsCreate from 'modules/SalesImports/view/Create';

export const ROUTE_SALES_IMPORTS_LIST = '/sales/sales_imports';
export const ROUTE_SALES_IMPORTS_DETAILS = '/sales/sales_imports/details/:id';
export const ROUTE_SALES_IMPORTS_CREATE = '/sales/sales_imports/create';

export const permMap = {
  [ROUTE_SALES_IMPORTS_LIST]: [SALES_IMPORTS_LIST],
  [ROUTE_SALES_IMPORTS_DETAILS]: [SALES_IMPORTS_LIST],
  [ROUTE_SALES_IMPORTS_CREATE]: [SALES_IMPORTS_UPDATE]
};

const routes = {
  protected: [
    <Route key='sales-imports-list' exact path={ROUTE_SALES_IMPORTS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_SALES_IMPORTS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesImportsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='sales-imports-details' exact path={ROUTE_SALES_IMPORTS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_SALES_IMPORTS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesImportsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='sales-imports-create' exact path={ROUTE_SALES_IMPORTS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_SALES_IMPORTS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesImportsCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
