import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class PaymentMethodsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.paymentMethodsPath = 'orders/payment-methods';
    this.paymentMethodPath = 'orders/payment-methods/:id';
  }

  getPaymentMethods(pathParams) {
    return this.get(this.paymentMethodsPath, pathParams);
  }

  createPaymentMethod(payload) {
    return this.post(this.paymentMethodsPath, payload);
  }

  updatePaymentMethod(id, payload) {
    return this.put(insertPathParams(this.paymentMethodPath, { id }), payload);
  }

  deletePaymentMethod(id) {
    return this.delete(insertPathParams(this.paymentMethodPath, { id }));
  }
}

export default new PaymentMethodsApi();
