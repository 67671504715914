import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_TARGETS_LIST } from 'routing/routes/Targets';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import TargetsApi from 'api/connections/Targets/TargetsApi';
import TargetForm from 'modules/Targets/component/Form';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';

class TargetsUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.targetId = parseInt(props.match.params.id, 10);
    this.setAlert = props.contextMethods.setAlert;
    this.setCurrentPage = props.contextMethods.setCurrentPage;

    this.state = { target: null, categoriesObj: null };
  }

  componentDidMount() {
    this.setCurrentPage('Update target');
    this.fetchData();
  }

  async fetchData() {
    try {
      const [
        {
          data: { data: target }
        },
        { data: categories }
      ] = await Promise.all([
        TargetsApi.getTarget(this.targetId),
        ProductCategoriesApi.getProductCategories()
      ]);

      this.setState({ target, categoriesObj: prepareCategories(categories) });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_TARGETS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { target, categoriesObj } = this.state;

    if (!target) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <TargetForm
              target={target}
              categoriesObj={categoriesObj}
              setAlert={this.props.contextMethods.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TargetsUpdate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired
};

export default withRouter(TargetsUpdate);
