import { Component } from 'react';
import PropTypes from 'prop-types';
import insertPathParams from 'api/utils/insertPathParams';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import Loader from 'modules/Layout/component/Loader';
import DashboardListItem from 'modules/Dashboard/component/Layout/ListItem';
import DashboardList from 'modules/Dashboard/component/Layout/List';
import SalesImportsApi from 'api/connections/SalesImports/SalesImportsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  UNKNOWN_WHOLESALER_SALE_IMPORT_STATUS,
  VALIDATION_ERROR_SALE_IMPORT_STATUS,
  UNMAPPED_PRODUCTS_SALE_IMPORT_STATUS,
  SALES_IMPORTS_STATUSES
} from 'modules/SalesImports/constants';
import { ROUTE_SALES_IMPORTS_DETAILS } from 'routing/routes/SalesImports';

class DashboardAdminFailedImports extends Component {
  static contextType = AuthContext;

  static getInformationBox(singleImport) {
    const { wholesaler_name, date, status } = singleImport;

    return (
      <Box display='flex' flexDirection='column'>
        <Typography style={{ fontWeight: '600' }}>
          {wholesaler_name} - {date}
        </Typography>
        <Typography color='error'>
          {t(SALES_IMPORTS_STATUSES.find(obj => obj.id === status).name)}
        </Typography>
      </Box>
    );
  }

  static getRedirectLink({ id }) {
    return insertPathParams(ROUTE_SALES_IMPORTS_DETAILS, { id });
  }

  static renderItem(singleImport) {
    const informationBox = DashboardAdminFailedImports.getInformationBox(
      singleImport
    );
    const redirectLink = DashboardAdminFailedImports.getRedirectLink(
      singleImport
    );

    return (
      <DashboardListItem
        key={singleImport.id}
        informationBox={informationBox}
        redirectLink={redirectLink}
      />
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      imports: undefined,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const pathParams = {
      status: [
        UNKNOWN_WHOLESALER_SALE_IMPORT_STATUS,
        VALIDATION_ERROR_SALE_IMPORT_STATUS,
        UNMAPPED_PRODUCTS_SALE_IMPORT_STATUS
      ],
      sort_field: 'date',
      per_page: Number.MAX_SAFE_INTEGER
    };

    try {
      const {
        data: { data: imports }
      } = await SalesImportsApi.getSalesImports(pathParams);

      this.setState({ imports, loading: false });
    } catch (err) {
      this.props.handleError(err);
      this.setState({ imports: undefined, loading: false });
    }
  }

  render() {
    const { loading, imports } = this.state;

    if (loading) return <Loader />;

    return (
      <DashboardList
        data={imports}
        renderItem={DashboardAdminFailedImports.renderItem}
      />
    );
  }
}

DashboardAdminFailedImports.defaultProps = {
  handleError: null
};

DashboardAdminFailedImports.propTypes = {
  handleError: PropTypes.func
};

export default DashboardAdminFailedImports;
