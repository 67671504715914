import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  listItem: {
    width: '100%',
    borderRadius: 5,
    '&:hover': { backgroundColor: '#59e2cb' },
    backgroundColor: 'white',
    marginBottom: theme.spacing(1),
    padding: '0.5rem 1rem'
  },
  box: {
    marginRight: theme.spacing(2)
  },
  column: {
    border: '1px solid #bdbdbd',
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1)
  }
}));
