import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import {
  roles,
  DEPARTMENT_MANAGER,
  TELEMARKETER,
  TRADER,
  KEY_ACCOUNT_MANAGER
} from 'api/auth/roles';
import parse from 'date-fns/parse';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/DatePicker';
import addYears from 'date-fns/addYears';

class StatisticsUsersVisitsClientsFilter extends Filter {
  filterOptions() {
    return this.props.filter.country_id
      ? this.props.departments.filter(
          department => department.country_id === this.props.filter.country_id
        )
      : this.props.departments;
  }

  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'autocomplete',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasPermission([
              POSTAL_CODES_COUNTRIES_SHOW
            ])
          },
          {
            ...this.getBasicInputValues('role'),
            label: 'Role',
            type: 'select',
            options: formatOptions(
              roles.filter(role =>
                [
                  DEPARTMENT_MANAGER,
                  TELEMARKETER,
                  TRADER,
                  KEY_ACCOUNT_MANAGER
                ].includes(role.slug)
              ),
              'slug',
              'name',
              true
            )
          },
          {
            ...this.getBasicInputValues('first_name'),
            label: 'First name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('last_name'),
            label: 'Last name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('department_id'),
            label: 'Departments',
            type: 'autocomplete',
            options: formatOptions(this.filterOptions(), 'id', 'name', false)
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('date_from'),
            label: 'Date from',
            type: 'date',
            maxDate: this.props.filter.date_to
              ? parse(this.props.filter.date_to, API_DATE_FORMAT, new Date())
              : addYears(new Date(), 20)
          },
          {
            ...this.getBasicInputValues('date_to'),
            label: 'Date to',
            type: 'date',
            minDate: this.props.filter.date_from
              ? parse(this.props.filter.date_from, API_DATE_FORMAT, new Date())
              : addYears(new Date(), -20)
          }
        ]
      }
    ];
  }
}

export default StatisticsUsersVisitsClientsFilter;
