import { Route } from 'react-router-dom';
import {
  CLIENTS_CREATE,
  CLIENTS_LIST,
  CLIENTS_UPDATE,
  INTERNET_CLIENTS_LIST
} from 'api/auth/permissions/Clients';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';
import ClientsList from 'modules/Clients/view/List';
import InternetClientsList from 'modules/Clients/view/InternetList';
import ClientDetailsWrapper from 'modules/Clients/view/Details/DetailsWrapper';
import ClientCreate from 'modules/Clients/view/Create';
import ClientUpdate from 'modules/Clients/view/Update';
import ClientContactCreate from 'modules/Clients/view/Contact/Create';
import ClientContactUpdate from 'modules/Clients/view/Contact/Update';
import ClientFileCreate from 'modules/Clients/view/File/Create';
import ClientFileUpdate from 'modules/Clients/view/File/Update';
import ClientAccountCreate from 'modules/Clients/view/Account/Create';
import ClientAccountUpdate from 'modules/Clients/view/Account/Update';
import ClientsUnassignedToEmployee from 'modules/Clients/view/UnassignedToEmployee';
import ClientWholesalerMappingCreate from 'modules/Clients/view/Details/WholesalersMappings/Create';
import ClientWholesalerMappingUpdate from 'modules/Clients/view/Details/WholesalersMappings/Update';

export const ROUTE_CLIENTS_LIST = '/clients';
export const ROUTE_INTERNET_CLIENTS_LIST = '/internet_clients';
export const ROUTE_CLIENTS_DETAILS = '/clients/details/:id';
export const ROUTE_INTERNET_CLIENTS_DETAILS = '/internet_clients/details/:id';
export const ROUTE_CLIENTS_CREATE = '/clients/create';
export const ROUTE_INTERNET_CLIENTS_CREATE = '/internet_clients/create';
export const ROUTE_CLIENTS_UPDATE = '/clients/update/:id';
export const ROUTE_INTERNET_CLIENTS_UPDATE = '/internet_clients/update/:id';
export const ROUTE_CLIENTS_CONTACT_CREATE =
  '/clients/details/:id/create_contact';
export const ROUTE_CLIENTS_CONTACT_UPDATE =
  '/clients/details/:clientId/update_contact/:contactId';
export const ROUTE_CLIENTS_FILE_CREATE = '/clients/details/:id/create_file';
export const ROUTE_CLIENTS_FILE_UPDATE =
  '/clients/details/:clientId/update_file/:fileId';
export const ROUTE_CLIENTS_ACCOUNT_CREATE =
  '/clients/details/:id/create_account';
export const ROUTE_CLIENTS_ACCOUNT_UPDATE =
  '/clients/details/:id/update_account';
export const ROUTE_CLIENTS_UNASSIGNED_TO_EMPLOYEE =
  '/clients/unassigned_to_employee';
export const ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE =
  '/clients/details/:id/create_wholesaler_mapping';
export const ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE =
  '/clients/details/:client_id/update_wholesaler_mapping/:wholesaler_id';

export const permMap = {
  [ROUTE_CLIENTS_LIST]: [CLIENTS_LIST],
  [ROUTE_INTERNET_CLIENTS_LIST]: [INTERNET_CLIENTS_LIST],
  [ROUTE_CLIENTS_DETAILS]: [CLIENTS_LIST],
  [ROUTE_INTERNET_CLIENTS_DETAILS]: [INTERNET_CLIENTS_LIST],
  [ROUTE_CLIENTS_CREATE]: [CLIENTS_CREATE],
  [ROUTE_INTERNET_CLIENTS_CREATE]: [CLIENTS_CREATE],
  [ROUTE_CLIENTS_UPDATE]: [CLIENTS_UPDATE],
  [ROUTE_INTERNET_CLIENTS_UPDATE]: [CLIENTS_UPDATE],
  [ROUTE_CLIENTS_CONTACT_CREATE]: [CLIENTS_UPDATE],
  [ROUTE_CLIENTS_CONTACT_UPDATE]: [CLIENTS_UPDATE],
  [ROUTE_CLIENTS_FILE_CREATE]: [CLIENTS_UPDATE],
  [ROUTE_CLIENTS_FILE_UPDATE]: [CLIENTS_UPDATE],
  [ROUTE_CLIENTS_ACCOUNT_CREATE]: [CLIENTS_UPDATE],
  [ROUTE_CLIENTS_ACCOUNT_UPDATE]: [CLIENTS_UPDATE],
  [ROUTE_CLIENTS_UNASSIGNED_TO_EMPLOYEE]: [DEPARTMENTS_LIST],
  [ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE]: [CLIENTS_UPDATE],
  [ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE]: [CLIENTS_UPDATE]
};

const routes = {
  protected: [
    <Route key='clients' exact path={ROUTE_CLIENTS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='internet-clients' exact path={ROUTE_INTERNET_CLIENTS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_INTERNET_CLIENTS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <InternetClientsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='clients-details' exact path={ROUTE_CLIENTS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientDetailsWrapper contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='internet-clients-details'
      exact
      path={ROUTE_INTERNET_CLIENTS_DETAILS}
    >
      <Authorize redirect permissions={permMap[ROUTE_INTERNET_CLIENTS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientDetailsWrapper contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='clients-create' exact path={ROUTE_CLIENTS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='clients-create' exact path={ROUTE_INTERNET_CLIENTS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_INTERNET_CLIENTS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientCreate contextMethods={fn} isInternet />}
        />
      </Authorize>
    </Route>,
    <Route key='clients-update' exact path={ROUTE_CLIENTS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,

    <Route key='clients-update' exact path={ROUTE_INTERNET_CLIENTS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_INTERNET_CLIENTS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientUpdate contextMethods={fn} isInternet />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-contact-create'
      exact
      path={ROUTE_CLIENTS_CONTACT_CREATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_CONTACT_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientContactCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-contact-update'
      exact
      path={ROUTE_CLIENTS_CONTACT_UPDATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_CONTACT_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientContactUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='clients-file-create' exact path={ROUTE_CLIENTS_FILE_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_FILE_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientFileCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='clients-file-update' exact path={ROUTE_CLIENTS_FILE_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_FILE_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientFileUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-account-create'
      exact
      path={ROUTE_CLIENTS_ACCOUNT_CREATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_ACCOUNT_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientAccountCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-account-update'
      exact
      path={ROUTE_CLIENTS_ACCOUNT_UPDATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_ACCOUNT_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientAccountUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-unassigned-to-employee'
      exact
      path={ROUTE_CLIENTS_UNASSIGNED_TO_EMPLOYEE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_CLIENTS_UNASSIGNED_TO_EMPLOYEE]}
      >
        <BasicContextConsumerWrapper
          component={fn => <ClientsUnassignedToEmployee contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='client-wholesaler-mapping-create'
      exact
      path={ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <ClientWholesalerMappingCreate contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='client-wholesaler-mapping-update'
      exact
      path={ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <ClientWholesalerMappingUpdate contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
