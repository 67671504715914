import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';

class PriceListsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'autocomplete',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasPermission([
              POSTAL_CODES_COUNTRIES_SHOW
            ]),
            loading: this.props.loadingCountries
          }
        ]
      },
      {
        groupLabel: 'Assigned to',
        children: [
          {
            ...this.getBasicInputValues('client_id'),
            label: 'Clients',
            type: 'autocomplete',
            emptyValueText: 'None client',
            options: formatOptions(this.props.clients, 'id', 'name'),
            loading: this.props.loadingClients
          },
          {
            ...this.getBasicInputValues('department_id'),
            label: 'Departments',
            type: 'autocomplete',
            emptyValueText: 'None department',
            options: formatOptions(this.props.departments, 'id', 'name'),
            displayStatus: this.context.hasPermission([DEPARTMENTS_LIST]),
            loading: this.props.loadingDepartments
          }
        ]
      },
      {
        groupLabel: 'Available for',
        children: [
          {
            ...this.getBasicInputValues('for_client_id'),
            label: 'Clients',
            type: 'autocomplete',
            emptyValueText: 'None client',
            options: formatOptions(this.props.clients, 'id', 'name'),
            loading: this.props.loadingClients
          },
          {
            ...this.getBasicInputValues('for_department_id'),
            label: 'Departments',
            type: 'autocomplete',
            emptyValueText: 'None department',
            options: formatOptions(this.props.departments, 'id', 'name'),
            displayStatus: this.context.hasPermission([DEPARTMENTS_LIST]),
            loading: this.props.loadingDepartments
          }
        ]
      }
    ];
  }
}

export default PriceListsFilter;
