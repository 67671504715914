import IconButtonLayout from 'modules/Layout/component/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import t from 'translate/translate';
import PropTypes from 'prop-types';

const ExtendButton = ({ open, onClick, tooltipMsg, disabled }) => {
  return (
    <IconButtonLayout
      icon={
        open ? (
          <KeyboardArrowUpIcon fontSize='small' />
        ) : (
          <KeyboardArrowDownIcon fontSize='small' />
        )
      }
      alt=''
      className='create-icon'
      onClick={onClick}
      disabled={disabled}
      tooltipMsg={t(tooltipMsg)}
    />
  );
};

ExtendButton.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipMsg: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default ExtendButton;
