import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import NavigatorList from 'modules/Layout/component/Navigator/List';

export const drawerWidth = 256;

const Navigator = props => {
  const { variant, open, onClose } = props;

  return (
    <Drawer
      PaperProps={{ style: { width: drawerWidth } }}
      variant={variant}
      open={open}
      onClose={onClose}
    >
      <NavigatorList />
    </Drawer>
  );
};

Navigator.defaultProps = {
  variant: 'permanent',
  open: true,
  onClose: null
};

Navigator.propTypes = {
  variant: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default Navigator;
