import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { Box } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import IconButton from 'modules/Layout/component/IconButton';
import { downloadFile } from 'modules/Shared/utils/file';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import OrdersApi from 'api/connections/Orders/OrdersApi';
import OrdersTable from 'modules/Orders/component/List/Table';
import OrdersFilter from 'modules/Orders/component/List/Filter';
import { ORDERS_CREATE } from 'api/auth/permissions/Orders';
import AddIcon from '@material-ui/icons/Add';
import Authorize from 'modules/Auth/component/Authorize';
import {
  ROUTE_ORDERS_CREATE,
  ROUTE_ORDERS_DETAILS,
  ROUTE_ORDERS_UPDATE
} from 'routing/routes/Orders';
import OrderDeleteDialog from 'modules/Orders/component/DeleteDialog';
import OrderChangeStatusDialog from 'modules/Orders/component/ChangeStatusDialog';
import insertPathParams from 'api/utils/insertPathParams';

class OrdersList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      filter: {},
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      orders: [],
      loading: true,
      deleteDialogStatus: false,
      statusChangeDialogStatus: false,
      orderToModify: null
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchOrders = this.fetchOrders.bind(this);
    this.exportOrders = this.exportOrders.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onChangeStatusClick = this.onChangeStatusClick.bind(this);
    this.onChangeStatusSuccess = this.onChangeStatusSuccess.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Orders');
    this.fetchOrders();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();

        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  onCreateClick() {
    this.props.history.push(ROUTE_ORDERS_CREATE);
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_ORDERS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_ORDERS_UPDATE, { id }));
  }

  onDeleteClick(data) {
    this.setState({ deleteDialogStatus: true, orderToModify: data });
  }

  onChangeStatusClick(data) {
    this.setState({ statusChangeDialogStatus: true, orderToModify: data });
  }

  onChangeStatusSuccess(status, id) {
    this.setState(state => {
      return {
        orders: state.orders.map(order =>
          order.id === id ? { ...order, status } : order
        )
      };
    });
  }

  onDialogClose() {
    this.setState({
      deleteDialogStatus: false,
      statusChangeDialogStatus: false,
      orderToModify: null
    });
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchOrders()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchOrders()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchOrders()
    );
  }

  async fetchOrders() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: orders, meta }
      } = await OrdersApi.getOrders({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        orders,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportOrders() {
    try {
      const { filter, sort } = this.state;

      const res = await OrdersApi.exportOrders({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          <Authorize permissions={[ORDERS_CREATE]}>
            <IconButton
              onClick={this.onCreateClick}
              icon={<AddIcon color='primary' fontSize='large' />}
              alt='create'
            />
          </Authorize>
          <IconButton
            onClick={this.exportOrders}
            icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
            alt='export table'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const {
      filter,
      sort,
      orders,
      loading,
      filterValidation,
      deleteDialogStatus,
      statusChangeDialogStatus,
      orderToModify
    } = this.state;

    return (
      <>
        <OrdersFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderActions()}
        <OrdersTable
          sort={sort}
          setSort={this.setSort}
          orders={orders}
          loading={loading}
          onDetailsClick={this.onDetailsClick}
          onUpdateClick={this.onUpdateClick}
          onDeleteClick={this.onDeleteClick}
          onChangeStatusClick={this.onChangeStatusClick}
        />
        {this.renderActions()}
        {deleteDialogStatus && (
          <OrderDeleteDialog
            order={orderToModify}
            onClose={this.onDialogClose}
            onSuccess={this.fetchOrders}
          />
        )}
        {statusChangeDialogStatus && (
          <OrderChangeStatusDialog
            order={orderToModify}
            onClose={this.onDialogClose}
            onSuccess={this.onChangeStatusSuccess}
          />
        )}
      </>
    );
  }
}

OrdersList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(OrdersList);
