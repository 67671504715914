import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper, Tab } from '@material-ui/core';
import ProductsApi from 'api/connections/Products/ProductsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import TaxesApi from 'api/connections/Taxes/TaxesApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Tabs from 'modules/Layout/component/Tabs';
import TabPanel from 'modules/Layout/component/TabPanel';
import Loader from 'modules/Layout/component/Loader';
import { ROUTE_PRODUCTS_LIST } from 'routing/routes/Products';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import t from 'translate/translate';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';

import WholesalersMappingsApi from 'api/connections/Wholesalers/WholesalersMappingsApi';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import ProductBatchesList from 'modules/Products/component/Batches/List';
import ProductWholesalersMappingsList from 'modules/Products/component/WholesalersMappings/List/Table';
import ProductWholesalersMappingsFilter from 'modules/Products/component/WholesalersMappings/List/Filter';
import ProductDetailsBasicData from 'modules/Products/view/Details/BasicData';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN
} from 'api/auth/roles';
import { WHOLESALERS_UPDATE } from 'api/auth/permissions/Wholesalers';

class ProductDetails extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const { id: productId } = props.match.params;
    this.productId = parseInt(productId, 10) || null;

    this.state = {
      product: null,
      countries: [],
      taxes: [],
      wholesalers: [],
      wholesalersMappings: [],
      filter: {
        wholesaler_id: ''
      },
      filterValidation: {}
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.onDeleteMappingSuccess = this.onDeleteMappingSuccess.bind(this);
    this.fetchProduct = this.fetchProduct.bind(this);
    this.setFilter = this.setFilter.bind(this);
  }

  componentDidMount() {
    if (!this.productId) {
      this.props.history.push(ROUTE_PRODUCTS_LIST);
    }
    this.setCurrentPage('Product details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.setAlert(message);
      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_PRODUCTS_LIST);
      }
      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onDeleteMappingSuccess(mappingId) {
    this.setState(prevState => {
      return {
        ...prevState,
        wholesalersMappings: prevState.wholesalersMappings.filter(
          mapping => mapping.id !== mappingId
        )
      };
    });
  }

  setFilter({ wholesaler_id }) {
    this.setState({ filter: { wholesaler_id } });
    if (
      this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR, DEPARTMENT_MANAGER])
    ) {
      this.fetchMappings();
    }
  }

  async fetchMappings() {
    const {
      product: { sku },
      filter: { wholesaler_id }
    } = this.state;

    try {
      const {
        data: { data: wholesalersMappings }
      } = await WholesalersMappingsApi.getWholesalersMappings({
        sku,
        wholesaler_id
      });

      this.setState({ wholesalersMappings });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    try {
      let countries = [];
      let wholesalersMappings = [];
      const [
        {
          data: { data: product }
        },
        {
          data: { data: taxes }
        },
        {
          data: { data: wholesalers }
        }
      ] = await Promise.all([
        ProductsApi.getProduct(this.productId),
        TaxesApi.getTaxes(),
        WholesalersApi.getWholesalers({
          per_page: Number.MAX_SAFE_INTEGER
        })
      ]);

      if (
        this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR, DEPARTMENT_MANAGER])
      ) {
        const {
          data: { data: wholesalersMappingsData }
        } = await WholesalersMappingsApi.getWholesalersMappings({
          sku: product.sku
        });
        wholesalersMappings = wholesalersMappingsData;
      }

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        product,
        countries,
        taxes,
        wholesalers,
        wholesalersMappings
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchProduct() {
    try {
      const {
        data: { data: product }
      } = await ProductsApi.getProduct(this.productId);

      this.setState({
        product
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      product,
      countries,
      taxes,
      filter,
      filterValidation,
      wholesalers,
      wholesalersMappings
    } = this.state;

    if (!product) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Tabs>
            <Tab label={t('General data')} value='general_data' />
            <Tab label={t('Batches')} value='batches' />
            {this.context.hasPermission([WHOLESALERS_UPDATE]) && (
              <Tab
                label={t('Product Wholesaler Mapping')}
                value='product_wholesaler_mapping'
              />
            )}
          </Tabs>
          <TabPanel index='general_data'>
            <ProductDetailsBasicData
              product={product}
              countries={countries}
              taxes={taxes}
              fetchProduct={this.fetchProduct}
            />
          </TabPanel>
          <TabPanel index='batches'>
            <Grid container spacing={3} justify='center'>
              <Grid item xs={12} lg={7}>
                <Paper className='p1'>
                  <ProductBatchesList batches={product.batches} />
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
          {this.context.hasPermission([WHOLESALERS_UPDATE]) && (
            <TabPanel index='product_wholesaler_mapping'>
              <Grid container spacing={3} justify='center'>
                <Grid item xs={12} lg={7}>
                  <Paper className='p1'>
                    <ProductWholesalersMappingsFilter
                      filter={filter}
                      validation={filterValidation}
                      setFilter={this.setFilter}
                      wholesalers={wholesalers}
                    />
                    <ProductWholesalersMappingsList
                      productId={this.productId}
                      sku={product.sku}
                      mappings={wholesalersMappings}
                      onDeleteMappingSuccess={this.onDeleteMappingSuccess}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
          )}
        </Grid>
      </Grid>
    );
  }
}

ProductDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ProductDetails);
