import { useContext } from 'react';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { ProductCategoriesContext } from 'modules/ProductCategories/context/ProductCategoriesProvider';
import PropTypes from 'prop-types';

const ProductCategoriesDeleteDialog = props => {
  const { fetchData } = props;
  const { setAlert } = useContext(AlertContext);
  const {
    deleteDialogOpenStatus,
    item,
    clearItem,
    clearAfterSuccessfulDelete
  } = useContext(ProductCategoriesContext);

  const deleteItem = async () => {
    try {
      await ProductCategoriesApi.deleteProductCategories(item.id);
      setAlert({
        value: t('Deleting product category successfully finish'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      clearAfterSuccessfulDelete();
      fetchData();
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      }
      clearItem();
    }
  };

  if (!item) return null;

  return (
    <Dialog
      open={deleteDialogOpenStatus}
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete category <%= name %>', {
        name: item.name
      })}
      onApprove={deleteItem}
      onCancel={clearItem}
    />
  );
};

ProductCategoriesDeleteDialog.propTypes = {
  fetchData: PropTypes.func.isRequired
};

export default ProductCategoriesDeleteDialog;
