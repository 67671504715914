export default {
  Profile: 'Profil',
  'Edit Profile': 'Edycja Profilu',
  'First name': 'Imię',
  'Last name': 'Nazwisko',
  Email: 'Email',
  'Phone number': 'Numer telefonu',
  Language: 'Język',
  'Email to verify': 'Email do weryfikacji',
  'Email confirmed, You can log in now':
    'Email potwierdzony, możesz się teraz zalogować',
  'My Profile': 'Mój Profil',
  'Trader results report - settings': 'Raport wizyt handlowców - ustawienia',
  'At this point, it is possible to disable the automatic e-mailing of a report on the traders results. By default, you will receive data from all departments available to you every Monday. You can turn this of  by unchecking the selected departments.':
    'W tym miejscu możliwe jest wyłączenie automatycznego wysyłania emailem raportu o wynikach handlowców. Domyślnie co poniedziałek otrzymasz dane ze wszystkich dostępnych dla Ciebie działów. Odznaczając zaznaczenie przy wybranych działach możesz z tego zrezygnować.'
};
