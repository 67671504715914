import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import getPathQuery from 'api/utils/getPathQuery';
import { ROUTE_SALES_RESULT_LIST } from 'routing/routes/SalesResults';
import SalesResultsDepartmentWrapper from 'modules/SalesResults/component/ResultsDetails/DepartmentWrapper';
import SalesResultsUserWrapper from 'modules/SalesResults/component/ResultsDetails/UserWrapper';
import { Grid, Paper } from '@material-ui/core';

class SalesPeriodsDetails extends Component {
  constructor(props) {
    super(props);

    this.departmentId =
      parseInt(this.props.match.params.department_id, 10) ||
      parseInt(getPathQuery(this.props.location.search, 'department_id'), 10);

    this.userId = parseInt(this.props.match.params.user_id, 10);

    this.advancedDetails = Boolean(
      getPathQuery(this.props.location.search, 'advanced')
    );

    this.month = getPathQuery(this.props.location.search, 'month');
  }

  componentDidMount() {
    if (!this.departmentId && !this.userId) {
      return this.props.history.push(ROUTE_SALES_RESULT_LIST);
    }
    this.props.contextMethods.setCurrentPage(
      this.userId
        ? 'User sales results details'
        : 'Department sales results details'
    );
  }

  render() {
    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Paper className='p1'>
            {this.userId ? (
              <SalesResultsUserWrapper
                month={this.month}
                departmentId={this.departmentId}
                userId={this.userId}
              />
            ) : (
              <SalesResultsDepartmentWrapper
                advancedDetails={this.advancedDetails}
                month={this.month}
                departmentId={this.departmentId}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

SalesPeriodsDetails.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      department_id: PropTypes.string,
      user_id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(SalesPeriodsDetails);
