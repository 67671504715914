import { Component } from 'react';
import { compareAsc } from 'date-fns';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import {
  PRODUCTS_DELETE,
  PRODUCTS_UPDATE
} from 'api/auth/permissions/Products';
import Table from 'modules/Layout/component/List/Table';
import { Box, Paper } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Accordion from 'modules/Layout/component/Accordion';

import ProductsTable from 'modules/Products/component/List/Table';
import ProductPriorityDeleteDialog from 'modules/Products/component/Details/BasicData/Priorities/Dialogs/Delete';
import ProductPriorityFormDialog from 'modules/Products/component/Details/BasicData/Priorities/Dialogs/Form';
import Authorize from 'modules/Auth/component/Authorize';

class ProductDetailsPriorities extends Component {
  static contextType = AuthContext;

  static separatePriorities(priorities) {
    const isPriorityActive = priority => {
      if (!priority.date_to) return true;

      const [day, month, year] = priority.date_to.split('.');
      const priorityDate = new Date(
        year,
        Number.parseInt(month, 10) - 1,
        Number.parseInt(day, 10)
      );

      return compareAsc(priorityDate, new Date()) === 1;
    };

    return priorities.reduce(
      (previousValue, currentValue) => {
        const result = {
          activePriorities: [...previousValue.activePriorities],
          archivedPriorities: [...previousValue.archivedPriorities]
        };

        isPriorityActive(currentValue)
          ? result.activePriorities.push(currentValue)
          : result.archivedPriorities.push(currentValue);

        return result;
      },
      {
        activePriorities: [],
        archivedPriorities: []
      }
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      modifiedPriority: null,
      formDialogStatus: false,
      deleteDialogStatus: false
    };

    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeAllDialogs = this.closeAllDialogs.bind(this);
  }

  onCreateClick() {
    this.setState({ modifiedPriority: null, formDialogStatus: true });
  }

  onUpdateClick(priority) {
    this.setState({ modifiedPriority: priority, formDialogStatus: true });
  }

  onDeleteClick(priority) {
    this.setState({ modifiedPriority: priority, deleteDialogStatus: true });
  }

  getCols(withActions) {
    const cols = [
      {
        property: 'value',
        label: 'Priority',
        sortable: false,
        render: ProductsTable.renderPriority
      },
      {
        property: 'date_from',
        label: 'Date from',
        sortable: false
      },
      {
        property: 'date_to',
        label: 'Date to',
        sortable: false
      }
    ];

    if (withActions)
      cols.push({
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasSomeOfPermission([
          PRODUCTS_UPDATE,
          PRODUCTS_DELETE
        ]),
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: this.context.hasPermission([PRODUCTS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([PRODUCTS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      });

    return cols;
  }

  closeAllDialogs() {
    this.setState({
      formDialogStatus: false,
      deleteDialogStatus: false,
      modifiedPriority: null
    });
  }

  render() {
    const {
      formDialogStatus,
      deleteDialogStatus,
      modifiedPriority
    } = this.state;
    const {
      product: { id: productId, priorities },
      fetchProduct
    } = this.props;

    const {
      activePriorities,
      archivedPriorities
    } = ProductDetailsPriorities.separatePriorities(priorities);

    return (
      <>
        <Paper className='p1'>
          <Table
            cols={this.getCols(true)}
            rows={activePriorities}
            dense
            actions={this.context.hasSomeOfPermission([
              PRODUCTS_UPDATE,
              PRODUCTS_DELETE
            ])}
          />
          <Authorize permissions={[PRODUCTS_UPDATE]}>
            <Box display='flex' alignItems='center'>
              <IconButton
                className='create-icon'
                onClick={this.onCreateClick}
                icon={<AddIcon fontSize='large' />}
                alt='create'
              />
            </Box>
          </Authorize>
        </Paper>
        <Accordion label={t('Show archived priorities')}>
          <Table cols={this.getCols()} rows={archivedPriorities} dense />
        </Accordion>
        {formDialogStatus && (
          <ProductPriorityFormDialog
            productId={productId}
            priority={modifiedPriority}
            onClose={this.closeAllDialogs}
            onSuccess={fetchProduct}
          />
        )}
        {deleteDialogStatus && (
          <ProductPriorityDeleteDialog
            productId={productId}
            priorityId={modifiedPriority.id}
            onClose={this.closeAllDialogs}
            onSuccess={fetchProduct}
          />
        )}
      </>
    );
  }
}

ProductDetailsPriorities.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    priorities: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  fetchProduct: PropTypes.func.isRequired
};

export default ProductDetailsPriorities;
