import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class WholesalersNotesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.wholesalerNotesPath = 'wholesalers/:id/notes';
    this.wholesalerNotePath = 'wholesalers/:wholesalerId/notes/:noteId';
  }

  getWholesalerNotes(id, pathParams) {
    return this.get(
      insertPathParams(this.wholesalerNotesPath, { id }),
      pathParams
    );
  }

  createWholesalerNote(id, payload) {
    return this.post(
      insertPathParams(this.wholesalerNotesPath, { id }),
      payload
    );
  }

  updateWholesalerNote(ids, payload) {
    return this.put(insertPathParams(this.wholesalerNotePath, ids), payload);
  }

  deleteWholesalerNote(ids) {
    return this.delete(insertPathParams(this.wholesalerNotePath, ids));
  }
}

export default new WholesalersNotesApi();
