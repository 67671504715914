import {
  DEPARTMENT_MANAGER,
  DIRECTOR,
  KEY_ACCOUNT_MANAGER,
  PRODUCT_MANAGER,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';

export default function (users, forManagement, loggedUser) {
  if (forManagement) {
    return users.filter(user => {
      return [DEPARTMENT_MANAGER, DIRECTOR].includes(user.role);
    });
  }

  if (loggedUser.role === DEPARTMENT_MANAGER) {
    return users.filter(user => {
      return user.departments.map(d => d.manager_id).includes(loggedUser.id);
    });
  }

  return users.filter(user => {
    return [
      TRADER,
      TELEMARKETER,
      KEY_ACCOUNT_MANAGER,
      PRODUCT_MANAGER,
      DEPARTMENT_MANAGER
    ].includes(user.role);
  });
}
