import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import t from 'translate/translate';
import PropTypes from 'prop-types';
import Button from '../../../../Layout/component/Button';
import Select, { formatOptions } from '../../../../Layout/component/Select';
import { validateBaseOnFormInputs } from '../../../../Shared/utils/validator';

class ExportProductsDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        country_id: null
      },
      validation: {
        country_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Country')
          })
        }
      }
    };

    this.renderDialogTitle = this.renderDialogTitle.bind(this);
    this.renderDialogContent = this.renderDialogContent.bind(this);
    this.renderDialogActions = this.renderDialogActions.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.runValidation = this.runValidation.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.runValidation(e.target.elements);
    if (isValid) {
      const { onSuccess } = this.props;
      const {
        formData: { country_id }
      } = this.state;
      onSuccess(country_id);
    }
  }

  onChange(e) {
    this.setState(state => ({
      ...state,
      formData: {
        country_id: e.target.value
      }
    }));
  }

  runValidation(formElements) {
    const { validation } = this.state;
    const [isValid, validateBaseOnFormInputsState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!isValid) {
      this.setState({ validation: validateBaseOnFormInputsState });
    }

    return isValid;
  }

  // eslint-disable-next-line class-methods-use-this
  renderDialogTitle() {
    return t('Export products');
  }

  renderDialogContent() {
    const { countries } = this.props;
    const {
      formData: { country_id },
      validation
    } = this.state;

    return (
      <form id='form' noValidate onSubmit={this.onSubmit}>
        <DialogContentText>
          {t(
            'Based on the country you select, tax, price and other country-specific data will be selected.'
          )}
        </DialogContentText>
        <Select
          name='country_id'
          label='Country'
          value={country_id}
          required
          options={formatOptions(countries, 'id', 'name')}
          onChange={this.onChange}
          errorStatus={validation.country_id.status}
          errorText={validation.country_id.message}
        />
      </form>
    );
  }

  renderDialogActions() {
    const { onClose } = this.props;

    return (
      <>
        <Button text={t('Cancel')} onClick={onClose} />
        <Button
          form='form'
          type='submit'
          color='primary'
          text={t('export table')}
        />
      </>
    );
  }

  render() {
    return (
      <Dialog open>
        <DialogTitle>{this.renderDialogTitle()}</DialogTitle>
        <DialogContent>{this.renderDialogContent()}</DialogContent>
        <DialogActions>{this.renderDialogActions()}</DialogActions>
      </Dialog>
    );
  }
}

ExportProductsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ExportProductsDialog;
