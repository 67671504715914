import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class PriceListsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.priceListsPath = 'price-lists';
    this.priceListPath = 'price-lists/:id';
    this.assignItemsToPriceListPath = 'price-lists/:id/assign';
    this.defaultPriceList = 'price-lists/default';
  }

  getPricesLists(pathParams) {
    return this.get(this.priceListsPath, pathParams);
  }

  getPricesList(id) {
    return this.get(insertPathParams(this.priceListPath, { id }));
  }

  createPricesList(payload) {
    return this.post(this.priceListsPath, payload);
  }

  updatePricesList(id, payload) {
    return this.put(insertPathParams(this.priceListPath, { id }), payload);
  }

  deletePricesList(id) {
    return this.delete(insertPathParams(this.priceListPath, { id }));
  }

  assignItemsToPricesList(id, payload) {
    return this.post(
      insertPathParams(this.assignItemsToPriceListPath, { id }),
      payload
    );
  }

  unassignItemsToPricesList(id, payload) {
    const options = {
      data: payload
    };

    return this.delete(
      insertPathParams(this.assignItemsToPriceListPath, { id }),
      options
    );
  }

  setDefaultPriceList(payload) {
    return this.post(this.defaultPriceList, payload);
  }

  unsetDefaultPriceList(payload) {
    const options = {
      data: payload
    };

    return this.delete(this.defaultPriceList, options);
  }
}

export default new PriceListsApi();
