import { Component } from 'react';
import { Grid, Tab } from '@material-ui/core';
import t from 'translate/translate';
import PropTypes from 'prop-types';
import Tabs from 'modules/Layout/component/Tabs';
import TabPanel from 'modules/Layout/component/TabPanel';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN
} from 'api/auth/roles';
import Profile from 'modules/Profile/view/Details/Profile';
import TraderResultsReportNotificationSettings from 'modules/Profile/view/Details/TraderResultsReport';

class ProfileDetailsWrapper extends Component {
  static contextType = AuthContext;

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Profile');
  }

  render() {
    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Tabs>
            <Tab label={t('Profile')} value='profile' />
            {this.context.hasRole([
              SUPER_ADMIN,
              ADMIN,
              DIRECTOR,
              DEPARTMENT_MANAGER
            ]) && (
              <Tab
                label={t('Trader results report - settings')}
                value='trader_results_report'
              />
            )}
          </Tabs>
          <TabPanel index='profile'>
            <Profile />
          </TabPanel>
          <TabPanel index='trader_results_report'>
            <TraderResultsReportNotificationSettings
              setAlert={this.props.contextMethods.setAlert}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

ProfileDetailsWrapper.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired
};

export default ProfileDetailsWrapper;
