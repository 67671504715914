import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import DiscountForm from 'modules/Discounts/component/Form';
import getPathQuery from 'api/utils/getPathQuery';

class DiscountCreate extends Component {
  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Create discount');
  }

  render() {
    const clientId = parseInt(
      getPathQuery(this.props.location.search, 'client_id'),
      10
    );
    const salesGroupId = parseInt(
      getPathQuery(this.props.location.search, 'sales_group_id'),
      10
    );
    const salesGroupName = getPathQuery(
      this.props.location.search,
      'sales_group_name'
    );
    const clientName = getPathQuery(this.props.location.search, 'client_name');

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <DiscountForm
              clientId={clientId}
              clientName={clientName}
              salesGroupId={salesGroupId}
              salesGroupName={salesGroupName}
              setAlert={this.props.contextMethods.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

DiscountCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(DiscountCreate);
