import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import TargetsApi from 'api/connections/Targets/TargetsApi';

const TargetConfirmDialog = props => {
  const {
    target: { id: targetId, name: targetName },
    onClose,
    onSuccess
  } = props;

  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const onApprove = async () => {
    setLoading(true);
    try {
      await TargetsApi.confirmTarget(targetId);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
    } catch (err) {
      if (err instanceof ApiError) {
        setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    } finally {
      setLoading(false);
      onClose();
      onSuccess();
    }
  };

  return (
    <Dialog
      open
      title={t('Confirm target')}
      description={t(
        'Are you sure you want to confirm target <%=targetName%>',
        {
          targetName
        }
      )}
      onApprove={onApprove}
      onCancel={onClose}
      loading={loading}
    />
  );
};

TargetConfirmDialog.propTypes = {
  target: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default TargetConfirmDialog;
