import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Tab } from '@material-ui/core';
import TabPanel from 'modules/Layout/component/TabPanel';
import Tabs from 'modules/Layout/component/Tabs';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';

import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import UsersApi from 'api/connections/Users/UsersApi';

import {
  PRODUCT_MANAGER,
  DEPARTMENT_MANAGER,
  KEY_ACCOUNT_MANAGER,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { ROUTE_USERS_LIST } from 'routing/routes/Users';
import UserDetailsBasicData from 'modules/Users/view/Details/BasicData';
import UserClients from 'modules/Users/view/Details/UserClients';
import ProductManagerProducts from 'modules/Users/view/Details/ProductManagerProducts';
import setUpdateActionDisableStatus from 'modules/Users/utils/setUpdateActionDisableStatus';
import setUpdateActionDisableMsg from 'modules/Users/utils/setUpdateActionDisableMsg';

class UserDetailsWrapper extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.userId = parseInt(props.match.params.id, 10);
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      user: null,
      countries: null
    };
  }

  componentDidMount() {
    if (!this.userId) {
      this.props.history.push(ROUTE_USERS_LIST);
    }
    this.setCurrentPage('User details');
    this.fetchData();
  }

  async fetchData() {
    try {
      let countries = [];
      const {
        data: { data: user }
      } = await UsersApi.getUser(this.userId);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({ user, countries });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { user, countries } = this.state;

    if (!user) return <Loader />;

    const updateActionDisableStatus = setUpdateActionDisableStatus(
      this.context.user,
      user.departments
    );
    const updateActionDisableMsg = setUpdateActionDisableMsg(
      this.context.user.role
    );

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Tabs>
            <Tab label={t('General data')} value='general_data' />
            {[
              DEPARTMENT_MANAGER,
              KEY_ACCOUNT_MANAGER,
              TELEMARKETER,
              TRADER
            ].includes(user.role) && (
              <Tab label={t('Clients')} value='clients' />
            )}
            {user.role === PRODUCT_MANAGER && (
              <Tab label={t('Products')} value='products' />
            )}
          </Tabs>
          <TabPanel index='general_data'>
            <UserDetailsBasicData
              user={user}
              countries={countries}
              updateActionDisableStatus={updateActionDisableStatus}
              updateActionDisableMsg={updateActionDisableMsg}
              setAlert={this.setAlert}
            />
          </TabPanel>
          {[
            DEPARTMENT_MANAGER,
            KEY_ACCOUNT_MANAGER,
            TELEMARKETER,
            TRADER
          ].includes(user.role) && (
            <TabPanel index='clients'>
              <UserClients
                userDepartments={user.departments}
                userCountryId={user.country_id}
                updateActionDisableStatus={updateActionDisableStatus}
                updateActionDisableMsg={updateActionDisableMsg}
              />
            </TabPanel>
          )}
          {user.role === PRODUCT_MANAGER && (
            <TabPanel index='products'>
              <ProductManagerProducts
                userId={this.userId}
                setAlert={this.setAlert}
                updateActionDisableStatus={updateActionDisableStatus}
                updateActionDisableMsg={updateActionDisableMsg}
              />
            </TabPanel>
          )}
        </Grid>
      </Grid>
    );
  }
}

UserDetailsWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(UserDetailsWrapper);
