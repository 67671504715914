import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import Loader from 'modules/Layout/component/Loader';
import t from 'translate/translate';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { addParentsIdsToCategoriesObj } from 'modules/ProductCategories/utils/formater';
import { withRouter } from 'react-router-dom';
import AddVisitProductForm from 'modules/Visits/components/Details/Products/Add/Form';
import VisitProductsApi from 'api/connections/Visits/VisitProductsApi';
import { validate } from 'modules/Shared/utils/validator';
import ValidationApiError from 'api/exceptions/ValidationApiError';

class AddProductView extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.visitId = props.match.params.id;

    this.setCurrentPage = props.contextMethods.setCurrentPage;

    this.state = {
      categoriesObj: null,
      formData: { product_id: null, note: '' },
      loading: false,
      validation: {
        product_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Product')
          })
        },
        note: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Note')
          })
        }
      }
    };

    this.handleError = this.handleError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Add product');
    this.fetchData();
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.goBack();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onSelect(e, item) {
    const { id: productId } = item;
    this.setState(prevState => ({
      ...prevState,
      formData: { ...prevState.formData, product_id: productId }
    }));
  }

  onChange(e) {
    const { value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      formData: { ...prevState.formData, note: value }
    }));
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  async fetchData() {
    try {
      const {
        data: categoriesObj
      } = await ProductCategoriesApi.getProductCategories();
      this.setState({
        categoriesObj: addParentsIdsToCategoriesObj(categoriesObj)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async makeApiCall() {
    const { formData } = this.state;
    this.setState({ loading: true });
    try {
      await VisitProductsApi.createVisitProduct(this.visitId, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  render() {
    const { categoriesObj, loading, formData } = this.state;

    if (!categoriesObj) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} md={7}>
          <Paper className='p1'>
            <AddVisitProductForm
              categoriesObj={categoriesObj}
              onSubmit={this.onSubmit}
              onSelect={this.onSelect}
              onChange={this.onChange}
              selected={formData}
              handleError={this.handleError}
              loading={loading}
              note={formData.note}
              visitId={this.visitId}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

AddProductView.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.number }) })
    .isRequired,
  contextMethods: PropTypes.shape({ setCurrentPage: PropTypes.func }).isRequired
};

export default withRouter(AddProductView);
