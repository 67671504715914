import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: '80px',
    right: '15px',
    zIndex: 3000,
    width: '300px',
    maxWidth: '60%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default useStyles;
