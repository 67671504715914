import BaseApiConfig from 'api/connections/BaseApiConfig';

class StatisticsExportApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    const statisticsPath = 'statistics';

    this.options = {
      responseType: 'blob'
    };

    this.statisticsClientsOrdersRankingPath = `${statisticsPath}/clients-orders-ranking-export`;
    this.statisticsClientsSalesRankingPath = `${statisticsPath}/clients-sales-ranking-export`;
    this.statisticsInternetClientsSalesRankingPath = `${statisticsPath}/internet-clients-report-export`;
    this.statisticsProductsSalesExportPath = `${statisticsPath}/products-ranking-export`;
    this.statisticsPriorityProductsSalesExportPath = `${statisticsPath}/priority-products-export`;
    this.statisticsUsersFinishedVisitsExportPath = `${statisticsPath}/users-finished-visits-ranking-export`;
    this.statisticsWholesalersSalesExportPath = `${statisticsPath}/wholesalers-ranking-export`;
    this.statisticsUsersOrdersValueExportPath = `${statisticsPath}/users-orders-value-ranking-export`;
    this.statisticsUsersVisitsExportPath = `${statisticsPath}/users-visits-ranking-export`;
    this.statisticsUsersOrdersVisitsExportPath = `${statisticsPath}/users-orders-visits-ranking-export`;
    this.statisticsUsersVisitsClientsExportPath = `${statisticsPath}/users-visited-clients-ranking-export`;
    this.statisticsUsersVisitsComparisonExportPath = `${statisticsPath}/users-visits-comparison-export`;
    this.statisticsSalesComparisonExportPath = `${statisticsPath}/wholesalers-sales-comparison-export`;
    this.statisticsTradersResultsExportPath = `${statisticsPath}/traders-results/export`;
    this.clientsTargetsReportExportPath = `${statisticsPath}/clients-targets-ranking-export`;
    this.gratisProductsReportExportPath = `${statisticsPath}/users-free-products-report-export`;
  }

  exportClientsOrdersRanking(pathParams) {
    return this.get(
      this.statisticsClientsOrdersRankingPath,
      pathParams,
      this.options
    );
  }

  exportClientsSalesRanking(pathParams) {
    return this.get(
      this.statisticsClientsSalesRankingPath,
      pathParams,
      this.options
    );
  }

  exportInternetClientsSalesReport(pathParams) {
    return this.get(
      this.statisticsInternetClientsSalesRankingPath,
      pathParams,
      this.options
    );
  }

  exportProductsSalesRanking(pathParams) {
    return this.get(
      this.statisticsProductsSalesExportPath,
      pathParams,
      this.options
    );
  }

  exportPriorityProductsSalesRanking(pathParams) {
    return this.get(
      this.statisticsPriorityProductsSalesExportPath,
      pathParams,
      this.options
    );
  }

  exportUsersFinishedVisitsRanking(pathParams) {
    return this.get(
      this.statisticsUsersFinishedVisitsExportPath,
      pathParams,
      this.options
    );
  }

  exportWholesalersSalesRanking(pathParams) {
    return this.get(
      this.statisticsWholesalersSalesExportPath,
      pathParams,
      this.options
    );
  }

  exportUsersOrdersValueRanking(pathParams) {
    return this.get(
      this.statisticsUsersOrdersValueExportPath,
      pathParams,
      this.options
    );
  }

  exportUsersVisitsRanking(pathParams) {
    return this.get(
      this.statisticsUsersVisitsExportPath,
      pathParams,
      this.options
    );
  }

  exportUsersOrdersVisitsRanking(pathParams) {
    return this.get(
      this.statisticsUsersOrdersVisitsExportPath,
      pathParams,
      this.options
    );
  }

  exportUsersVisitsClientsRanking(pathParams) {
    return this.get(
      this.statisticsUsersVisitsClientsExportPath,
      pathParams,
      this.options
    );
  }

  exportUsersVisitsComparisonRanking(pathParams) {
    return this.get(
      this.statisticsUsersVisitsComparisonExportPath,
      pathParams,
      this.options
    );
  }

  exportSalesComparisonRanking(pathParams) {
    return this.get(
      this.statisticsSalesComparisonExportPath,
      pathParams,
      this.options
    );
  }

  exportTraderVisitsReport(pathParams) {
    return this.get(
      this.statisticsTradersResultsExportPath,
      pathParams,
      this.options
    );
  }

  exportClientsTargetsReport(pathParams) {
    return this.get(
      this.clientsTargetsReportExportPath,
      pathParams,
      this.options
    );
  }

  exportGratisProductsReport(pathParams) {
    return this.get(
      this.gratisProductsReportExportPath,
      pathParams,
      this.options
    );
  }
}

export default new StatisticsExportApi();
