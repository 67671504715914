import {
  ROUTE_IMPORTS_CLIENTS,
  ROUTE_IMPORTS_PRODUCTS,
  ROUTE_IMPORTS_PRICE_LISTS,
  ROUTE_IMPORTS_PRODUCT_BATCHES,
  permMap
} from 'routing/routes/Imports';
import ListIcon from '@material-ui/icons/List';

const menu = [
  {
    key: 'menu-imports',
    label: 'Imports',
    icon: <ListIcon />,
    path: ROUTE_IMPORTS_CLIENTS,
    anyPermissions: permMap[ROUTE_IMPORTS_CLIENTS],
    children: [
      {
        key: 'menu-imports-clients',
        label: 'Imports clients',
        icon: <ListIcon />,
        path: ROUTE_IMPORTS_CLIENTS,
        permissions: permMap[ROUTE_IMPORTS_CLIENTS]
      },
      {
        key: 'menu-imports-products',
        label: 'Imports products',
        icon: <ListIcon />,
        path: ROUTE_IMPORTS_PRODUCTS,
        permissions: permMap[ROUTE_IMPORTS_PRODUCTS]
      },
      {
        key: 'menu-imports-price-lists',
        label: 'Imports price lists',
        icon: <ListIcon />,
        path: ROUTE_IMPORTS_PRICE_LISTS,
        permissions: permMap[ROUTE_IMPORTS_PRICE_LISTS]
      },
      {
        key: 'menu-imports-product-batches',
        label: 'Imports product batches',
        icon: <ListIcon />,
        path: ROUTE_IMPORTS_PRODUCT_BATCHES,
        permissions: permMap[ROUTE_IMPORTS_PRODUCT_BATCHES]
      }
    ]
  }
];

export default menu;
