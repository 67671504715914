import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ProductCategoriesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.productCategoriesPath = 'products/categories';
    this.updateProductCategoiesPath = 'products/categories/:id';
    this.deleteProductCategoiesPath = 'products/categories/:id';
  }

  getProductCategories() {
    return this.get(this.productCategoriesPath);
  }

  createProductCategories(payload) {
    return this.post(this.productCategoriesPath, payload);
  }

  updateProductCategories(id, payload) {
    return this.put(
      insertPathParams(this.updateProductCategoiesPath, { id }),
      payload
    );
  }

  deleteProductCategories(id) {
    return this.delete(
      insertPathParams(this.deleteProductCategoiesPath, { id })
    );
  }
}

export default new ProductCategoriesApi();
