import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { SALES_IMPORTS_STATUSES } from 'modules/SalesImports/constants';
import parse from 'date-fns/parse';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/DatePicker';
import addYears from 'date-fns/addYears';

class SalesImportsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('wholesaler_name'),
            label: 'Wholesaler name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('status'),
            label: 'Status',
            type: 'select',
            emptyValueText: 'All statuses',
            options: formatOptions(SALES_IMPORTS_STATUSES, 'id', 'name', true)
          }
        ]
      },
      {
        groupLabel: 'Date',
        children: [
          {
            property: 'quantity-valueOrRange',
            type: 'valueOrRange',
            options: {
              value: {
                ...this.getBasicInputValues('date'),
                label: 'Date',
                type: 'date'
              },
              rangeFrom: {
                ...this.getBasicInputValues('date_from'),
                label: 'Date from',
                type: 'date',
                maxDate: this.props.filter.date_to
                  ? parse(
                      this.props.filter.date_to,
                      API_DATE_FORMAT,
                      new Date()
                    )
                  : addYears(new Date(), 20)
              },
              rangeTo: {
                ...this.getBasicInputValues('date_to'),
                label: 'Date to',
                type: 'date',
                minDate: this.props.filter.date_from
                  ? parse(
                      this.props.filter.date_from,
                      API_DATE_FORMAT,
                      new Date()
                    )
                  : addYears(new Date(), -20)
              }
            }
          }
        ]
      }
    ];
  }
}

export default SalesImportsFilter;
