import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import renderPrice from 'modules/Products/utils/renderPrice';

class StatisticsClientsOrdersTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'country_id',
        label: 'Country',
        sortable: false,
        displayStatus: this.context.hasPermission([
          POSTAL_CODES_COUNTRIES_SHOW
        ]),
        render: id => this.props.countries.find(c => c.id === id).name
      },
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'tax_number',
        label: 'Tax number',
        sortable: false
      },
      {
        property: 'city',
        label: 'City',
        sortable: false
      },
      {
        property: 'street',
        label: 'Street',
        sortable: false
      },
      {
        property: 'postal_code',
        label: 'Postal code',
        sortable: false
      },
      {
        property: 'price_sum',
        label: 'Value',
        sortable: false,
        render: (value, { currency }) => renderPrice(value, currency)
      }
    ];
  }

  render() {
    const { statistics, sort, setSort, loading, bodyText } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={statistics}
          sort={sort}
          setSort={setSort}
          loading={loading}
          bodyText={bodyText}
        />
      </>
    );
  }
}

StatisticsClientsOrdersTable.defaultProps = {
  statistics: [],
  countries: [],
  bodyText: null
};

StatisticsClientsOrdersTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  statistics: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  bodyText: PropTypes.string
};

export default StatisticsClientsOrdersTable;
