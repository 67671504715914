import { Component } from 'react';
import PropTypes from 'prop-types';
import SalesGroupsTable from 'modules/SalesGroups/component/List/Table';
import SalesGroupsFilter from 'modules/SalesGroups/component/List/Filter';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import { Box } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import AddIcon from '@material-ui/icons/Add';
import { SHOPPING_GROUPS_CREATE } from 'api/auth/permissions/SalesGroups';
import { ROUTE_SHOPPING_GROUPS_CREATE } from 'routing/routes/ShoppingGroups';
import { withRouter } from 'react-router-dom';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SalesGroupsFilesApi from 'api/connections/SalesGroups/SalesGroupsFilesApi';
import { downloadFile } from 'modules/Shared/utils/file';

class SalesGroupsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      filter: {
        name: '',
        country_id: ''
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      salesGroups: [],
      countries: [],
      loading: true
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchSalesGroups = this.fetchSalesGroups.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportSalesGroups = this.exportSalesGroups.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Shopping groups');
    this.fetchData();
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchSalesGroups()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchSalesGroups()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchSalesGroups()
    );
  }

  async fetchSalesGroups() {
    const {
      filter,
      sort,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: salesGroups, meta }
      } = await SalesGroupsApi.getSalesGroups({
        ...sort,
        ...filter,
        per_page,
        page
      });
      this.setState({
        salesGroups,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchData() {
    const {
      filter,
      sort,
      pagination: { per_page, page }
    } = this.state;

    try {
      let countries = [];

      const {
        data: { data: salesGroups, meta }
      } = await SalesGroupsApi.getSalesGroups({
        ...sort,
        ...filter,
        per_page,
        page
      });

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        salesGroups,
        countries,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) this.setAlert(err.getPayload().message);
      else console.error(err);
    }
  }

  async exportSalesGroups() {
    try {
      const { filter, sort } = this.state;

      const res = await SalesGroupsFilesApi.exportSalesGroups({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      if (err instanceof ApiError) {
        this.props.contextMethods.setAlert(err.getPayload().message);
      }
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          <Authorize permissions={[SHOPPING_GROUPS_CREATE]}>
            <IconButton
              icon={<AddIcon fontSize='large' />}
              onClick={() =>
                this.props.history.push(ROUTE_SHOPPING_GROUPS_CREATE)
              }
              className='create-icon'
              alt='create'
            />
          </Authorize>
          <IconButton
            onClick={this.exportSalesGroups}
            icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
            alt='export table'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const {
      filter,
      filterValidation,
      sort,
      salesGroups,
      countries,
      loading
    } = this.state;
    return (
      <div>
        <SalesGroupsFilter
          filter={filter}
          countries={countries}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderActions()}
        <SalesGroupsTable
          sort={sort}
          setSort={this.setSort}
          salesGroups={salesGroups}
          countries={countries}
          loading={loading}
          onDeleteSalesGroupSuccess={this.fetchSalesGroups}
        />
        {this.renderActions()}
      </div>
    );
  }
}

SalesGroupsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(SalesGroupsList);
