export const setImpersonateRootId = id => {
  localStorage.setItem('impersonate_root_id', id);
};

export const getImpersonateRootId = () => {
  const rootId = localStorage.getItem('impersonate_root_id');
  return rootId && parseInt(rootId, 10);
};

export const clearImpersonateRootId = () => {
  localStorage.removeItem('impersonate_root_id');
};
