import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ProductPriorityApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.createProductPriorityPath = 'products/:productId/priorities';
    this.productPriorityPath = 'products/:productId/priorities/:priorityId';
  }

  createProductPriority(id, payload) {
    return this.post(
      insertPathParams(this.createProductPriorityPath, { productId: id }),
      payload
    );
  }

  updateProductPriority(ids, payload) {
    return this.put(insertPathParams(this.productPriorityPath, ids), payload);
  }

  deleteProductPriority(ids) {
    return this.delete(insertPathParams(this.productPriorityPath, ids));
  }
}

export default new ProductPriorityApi();
