import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import IconButton from 'modules/Layout/component/IconButton';
import { Block as BlockIcon } from '@material-ui/icons';
import Loader from 'modules/Layout/component/Loader';

class ClientComments extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      enova_notes: props.client.enova_notes ?? '',
      formData: {},
      updateStatus: false,
      loading: false
    };

    this.handleError = this.handleError.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onUpdateCancelClick = this.onUpdateCancelClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Comments updated successfully'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.setState(state => {
      return {
        updateStatus: false,
        enova_notes: state.formData.enova_notes,
        formData: undefined
      };
    });
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    await this.makeApiCall();
  }

  onChange(e) {
    const { value } = e.target;

    this.setState({ formData: { enova_notes: value } });
  }

  onUpdateClick() {
    this.setState(state => {
      return {
        updateStatus: true,
        formData: { enova_notes: state.enova_notes }
      };
    });
  }

  onUpdateCancelClick() {
    this.setState({
      updateStatus: false,
      formData: undefined
    });
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      await ClientsApi.updateClientComment(this.props.client.id, formData);

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  renderData() {
    const { enova_notes, formData, updateStatus } = this.state;

    if (updateStatus)
      return (
        <Input
          name='enova_notes'
          label='Comments'
          value={formData.enova_notes}
          autoFocus
          multiline
          onChange={this.onChange}
        />
      );

    return enova_notes ? (
      <Typography>{enova_notes}</Typography>
    ) : (
      <Box display='flex' justifyContent='center'>
        <Typography color='textSecondary'>
          {t("Client don't have any comments")}
        </Typography>
      </Box>
    );
  }

  render() {
    const { updateStatus, loading } = this.state;

    if (loading) return <Loader />;

    return (
      <Grid container justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <Box width={1} display='flex' flexDirection='row-reverse' mb={2}>
              {updateStatus ? (
                <>
                  <IconButton
                    className='impersonate-icon'
                    onClick={this.onSubmit}
                    icon={<SaveIcon />}
                    alt='save'
                  />
                  <IconButton
                    className='delete-icon'
                    onClick={this.onUpdateCancelClick}
                    icon={<BlockIcon />}
                    alt='cancel'
                  />
                </>
              ) : (
                <IconButton
                  className='update-icon'
                  onClick={this.onUpdateClick}
                  icon={<EditIcon />}
                  alt='update'
                />
              )}
            </Box>
            {this.renderData()}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ClientComments.propTypes = {
  setAlert: PropTypes.func.isRequired,
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    enova_notes: PropTypes.string
  }).isRequired
};

export default ClientComments;
