import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import SalesPeriodsApi from 'api/connections/SalesResults/SalesResultsApi';

const SalesPeriodSummaryRecalculateDialog = props => {
  const {
    department_id,
    department_name,
    month,
    monthName,
    type,
    onClose,
    onSuccess
  } = props;

  const { setAlert } = useContext(AlertContext);

  const onApprove = async () => {
    try {
      await SalesPeriodsApi.closeSalesPeriod({
        department_id,
        month
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onSuccess();
    } catch (err) {
      if (err instanceof ApiError) {
        setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    } finally {
      onClose();
    }
  };

  const dialogTitle = `${
    type === 'summarize' ? 'Summary' : 'Recalculate'
  } confirmation`;
  const dialogDescription =
    type === 'summarize'
      ? 'Are you sure you want to summary sales result for department <%=department_name%> in month <%=monthName%>?'
      : "Are you sure you want to recalculate sales result for department <%=department_name%> in month <%=monthName%>? There may be changes in the structure of the category tree or the department's categories, which will have an impact on the sales data included in the department's month summary.";

  return (
    <Dialog
      open
      title={t(dialogTitle)}
      description={t(dialogDescription, {
        department_name,
        monthName
      })}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

SalesPeriodSummaryRecalculateDialog.propTypes = {
  department_name: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  monthName: PropTypes.string.isRequired,
  department_id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default SalesPeriodSummaryRecalculateDialog;
