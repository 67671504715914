export default {
  // LANG
  Polish: 'Polski',
  English: 'Angielski',
  // ERRORS
  'Unprocessable Entity': 'Błąd walidacji',
  'Server Error': 'Błąd serwera',
  // BASIC
  Cancel: 'Anuluj',
  Save: 'Zapisz',
  Send: 'Wyślij',
  Update: 'Edycja',
  Details: 'Szczegóły',
  'Something goes wrong, try again later':
    'Coś poszło nie tak, spróbuj ponownie później',
  Create: 'Dodawanie',
  List: 'Lista',
  Actions: 'Akcje',
  'No data': 'Brak danych',
  'Deletion confirmation': 'Potwierdzenie usunięcia',
  Approve: 'Zatwierdz',
  Filters: 'Filtry',
  'Rows per page': 'Ilość na stronie',
  of: 'z',
  Success: 'Sukces',
  'Go back to own account': 'Wróć do swojego konta',
  // VALIDATION
  'Field <%= field %> is required': 'Pole <%= field %> jest wymagane',
  'Pole <%= field %> should be in the correct format':
    'Pole <%= field %> powinno mieć odpowiedni format',
  'Pole <%= field %> is required, and should be in the correct format': `Pole <%= field %> jest wymagane, oraz powinno mieć odpowiedni format`,
  'Password must contain at least 8 characters, including at least 1 uppercase letter, lowercase letter and a number.':
    'Hasło musi zawierać przynajmniej 8 znaków, w tym minimum 1 wielką literę, małą literę i cyfrę',
  'Passwords are not equal': 'Hasła nie zgadzają się',
  'Wrong format': 'Niewłaściwy format',
  'One of this field is required': 'Jedno z tych pól jest wymagane',
  Filter: 'Filtry',
  create: 'dodawanie',
  details: 'szczegóły',
  impersonate: 'impersonate',
  download: 'pobieranie',
  cancel: 'wycofanie',
  'set wholesaler': 'przypisanie hurtowni',
  map: 'mapuj',
  update: 'edycja',
  delete: 'usuwanie',
  'User does not have the right permissions.':
    'Użytkownik nie posiada odpowiednich uprawnień.',
  '404 Page not found': '404 Nie znaleziono strony',
  'Go to home page': 'Przejdź do strony głównej',
  'close window': 'zamknij okno',
  postponement: 'przekładanie',
  'No options': 'Brak opcji',
  'export table': 'Eksportuj tabele',
  'Administration and configuration': 'Administracja i konfiguracja',
  'You do not have the proper permissions':
    'Nie posiadasz właściwych uprawnień',
  'clear inputs': 'wyczyść wartości',
  All: 'Wszystko',
  Author: 'Autor',
  open: 'otwarty',
  close: 'zamknięty',
  copy: 'kopiuj',
  Selected: 'Zaznaczono',
  Comparison: 'Porównanie',
  'Field Threshold value is required and should be more than 0':
    'Pole Wartość progu jest wymagane i wartość powinna być większa niż 0',
  'Field Percentage of bonuses is required and should be more than 0':
    'Pole Wartość premii jest wymagane i wartość powinna być większa niż 0',
  'Threshold value': 'Wartość progu',
  'Percentage of bonuses': 'Wartość premii',
  unconfirmed: 'niezatwierdzona',
  'Pick department': 'Wybierz dział',
  'Pick dates': 'Wybierz daty',
  'Date to should not be later than date from.':
    'Data od nie powinna być późniejsza niż data do.',
  'u.': 'szt.',
  'Pick country': 'Wybierz kraj',
  clear: 'wyczyść',
  'set time': 'ustaw czas',
  'Pick date first': 'Wybierz najpierw datę',
  'Approve (<%=count%>)': 'Zatwierdź (<%=count%>)',
  'This field is required': 'To pole jest wymagane',
  Other: 'Inne'
};
