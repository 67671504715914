import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash/lang';
import t from 'translate/translate';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';

class OrderTypeConfiguration extends Component {
  constructor(props) {
    super(props);

    this.onIsDirectChange = this.onIsDirectChange.bind(this);
    this.getOrderTypesOptions = this.getOrderTypesOptions.bind(this);
  }

  onIsDirectChange(e) {
    const { value: type } = e.target;

    const event = {
      target: {
        name: 'is_direct',
        value: type === 'direct'
      }
    };

    this.props.onChange(event);
  }

  getOrderTypesOptions() {
    const { orderTypes } = this.props;

    if (!orderTypes) return [];

    return orderTypes
      .map(orderType => ({
        key: orderType.id,
        value: orderType.name,
        department_name: orderType.department_name,
        department_id: orderType.department_id
      }))
      .sort((a, b) => (a.department_id > b.department_id ? -1 : 0));
  }

  render() {
    const {
      clientId,
      wholesalerId,
      wholesalerAgencyId,
      typeId,
      isDirect,
      products,
      promotions,
      wholesalers,
      agencies,
      orderTypes,
      onAutocompleteChange,
      loadingAgencies
    } = this.props;

    if (!clientId) {
      return (
        <Typography variant='subtitle1' color='textSecondary'>
          {t('Select client first')}
        </Typography>
      );
    }

    return (
      <>
        <Box>
          <Box display='flex' flexDirection='column' width={1}>
            <Box width={1} display='flex' flexDirection='row'>
              <FormControl
                component='fieldset'
                style={{
                  width: '100%'
                }}
              >
                <RadioGroup
                  name='isDirect'
                  value={isDirect === true ? 'direct' : 'wholesaler'}
                  onChange={this.onIsDirectChange}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <Box width={1 / 2}>
                    <FormControlLabel
                      value='direct'
                      control={<Radio />}
                      label={t('Direct')}
                    />
                  </Box>
                  <Box width={1 / 2}>
                    {!isEmpty(products) || !isEmpty(promotions) ? (
                      <Tooltip
                        title={t(
                          'It is not possible to change the type of order to a wholesaler when products are selected.'
                        )}
                      >
                        <FormControlLabel
                          value='wholesaler'
                          control={<Radio />}
                          label={t('Wholesaler')}
                          disabled
                        />
                      </Tooltip>
                    ) : (
                      <FormControlLabel
                        value='wholesaler'
                        control={<Radio />}
                        label={t('Wholesaler')}
                      />
                    )}
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
            <Box width={1} display='flex'>
              <Box width={1 / 2} pr={2}>
                <Autocomplete
                  name='type_id'
                  label='Order type'
                  value={typeId}
                  options={this.getOrderTypesOptions()}
                  groupBy={option => option.department_name}
                  onChange={(_, v) => onAutocompleteChange('type_id', v)}
                  loading={!orderTypes}
                  disabled={!isDirect}
                  required={isDirect}
                  errorStatus={isDirect && !typeId}
                  errorText={t(
                    'The order type must be selected. If there are no types to choose from, the order cannot be saved.'
                  )}
                />
              </Box>
              <Box width={1 / 2}>
                <Autocomplete
                  name='wholesaler_id'
                  label='Wholesaler'
                  value={wholesalerId}
                  options={formatOptions(wholesalers || [], 'id', 'name')}
                  onChange={(_, v) => onAutocompleteChange('wholesaler_id', v)}
                  disabled={isDirect}
                  required={!isDirect}
                  loading={!wholesalers}
                  errorStatus={!isDirect && !wholesalerId}
                  errorText={t('Field <%= field %> is required', {
                    field: t('Wholesaler')
                  })}
                />
                <Autocomplete
                  name='wholesaler_agency_id'
                  label='Agency'
                  value={wholesalerAgencyId}
                  options={formatOptions(agencies || [], 'id', 'name')}
                  onChange={(_, v) =>
                    onAutocompleteChange('wholesaler_agency_id', v)
                  }
                  disabled={isDirect || (!loadingAgencies && isEmpty(agencies))}
                  tooltipMsg={
                    !isDirect && wholesalerId && isEmpty(agencies)
                      ? 'Wholesaler dont have agencies'
                      : null
                  }
                  required={!isDirect && !isEmpty(agencies)}
                  loading={loadingAgencies}
                  errorStatus={
                    !isDirect && !isEmpty(agencies) && !wholesalerAgencyId
                  }
                  errorText={t('Field <%= field %> is required', {
                    field: t('Agency')
                  })}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

OrderTypeConfiguration.defaultProps = {
  clientId: null,
  wholesalerId: null,
  wholesalerAgencyId: null,
  typeId: null,
  wholesalers: undefined,
  agencies: [],
  orderTypes: undefined
};

OrderTypeConfiguration.propTypes = {
  clientId: PropTypes.number,
  wholesalerId: PropTypes.number,
  typeId: PropTypes.number,
  wholesalerAgencyId: PropTypes.number,
  wholesalers: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  agencies: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  orderTypes: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  isDirect: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onAutocompleteChange: PropTypes.func.isRequired,
  loadingAgencies: PropTypes.bool.isRequired
};

export default OrderTypeConfiguration;
