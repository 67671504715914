import { Component } from 'react';
import { Box, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { ROUTE_DEPARTMENTS_CREATE } from 'routing/routes/Departments';
import DepartmentsTable from 'modules/Departments/component/List/Table';
import DepartmentsFilter from 'modules/Departments/component/List/Filter';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';
import ApiError from 'api/exceptions/ApiError';
import { DEPARTMENTS_CREATE } from 'api/auth/permissions/Departments';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import Authorize from 'modules/Auth/component/Authorize';
import PropTypes from 'prop-types';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ValidationApiError from 'api/exceptions/ValidationApiError';

class DepartmentsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        name: '',
        country_id: ''
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      departments: [],
      countries: [],
      categoriesObj: {
        productGroups: [],
        categories: []
      },
      loading: true
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchDepartments = this.fetchDepartments.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Departments');
    this.fetchData();
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchDepartments()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchDepartments()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchDepartments()
    );
  }

  async fetchDepartments() {
    const { sort, filter, pagination } = this.state;

    try {
      const {
        data: { data: departmentsData, meta }
      } = await DepartmentsApi.getDepartments({
        ...sort,
        ...filter,
        ...pagination
      });
      this.setState({
        departments: departmentsData,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchData() {
    const { sort, filter, pagination } = this.state;

    try {
      let countries = [];
      const [
        {
          data: { data: departmentsData, meta }
        },
        { data: categoriesData }
      ] = await Promise.all([
        DepartmentsApi.getDepartments({ ...sort, ...filter, ...pagination }),
        ProductCategoriesApi.getProductCategories()
      ]);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        countries,
        departments: departmentsData,
        categoriesObj: prepareCategories(categoriesData),
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        console.error(message);
      }
    }
  }

  renderActions() {
    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Authorize permissions={[DEPARTMENTS_CREATE]}>
            <Link to={ROUTE_DEPARTMENTS_CREATE} className='router-button'>
              <IconButton>
                <AddIcon color='primary' fontSize='large' />
              </IconButton>
            </Link>
          </Authorize>
        </Box>
        <Pagination
          pagination={this.state.pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    return (
      <div>
        <DepartmentsFilter
          filter={this.state.filter}
          validation={this.state.filterValidation}
          setFilter={this.setFilter}
          countries={this.state.countries}
        />
        {this.renderActions()}
        <DepartmentsTable
          sort={this.state.sort}
          setSort={this.setSort}
          departments={this.state.departments}
          countries={this.state.countries}
          categoriesObj={this.state.categoriesObj}
          fetchDepartments={this.fetchDepartments}
          loading={this.state.loading}
        />
        {this.renderActions()}
      </div>
    );
  }
}

DepartmentsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default DepartmentsList;
