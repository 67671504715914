import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class DepartmentsOrderTypesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.departmentOrderTypesPath = 'orders/types';
    this.departmentOrderTypePath = 'orders/types/:id';
    this.departmentOrderTypeSortPath = 'orders/types/sort';
  }

  getDepartmentOrderTypes(pathParams) {
    return this.get(this.departmentOrderTypesPath, pathParams);
  }

  createDepartmentOrderType(payload) {
    return this.post(this.departmentOrderTypesPath, payload);
  }

  updateDepartmentOrderType(id, payload) {
    return this.put(
      insertPathParams(this.departmentOrderTypePath, { id }),
      payload
    );
  }

  updateDepartmentOrderTypeSort(payload) {
    return this.post(this.departmentOrderTypeSortPath, payload);
  }

  deleteDepartmentOrderType(id) {
    return this.delete(insertPathParams(this.departmentOrderTypePath, { id }));
  }
}

export default new DepartmentsOrderTypesApi();
