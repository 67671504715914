import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { Box, Typography } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import ClientsEvaluationApi from 'api/connections/Clients/ClientsEvaluationApi';
import { isEmpty } from 'lodash/lang';
import PageLoader from 'modules/Layout/component/PageLoader';

class ClientEvaluationModal extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.onClose = props.onClose;
    this.onSuccess = props.onSuccess;
    this.client = props.client;
    this.departments = props.departments;

    this.state = {
      formData: { client_id: this.client.client_id },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.onClose();
    this.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.props.setAlert(message);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    await this.makeApiCall();
  }

  onChange(e) {
    const { value, name } = e.target;
    const { evaluation } = this.props.client;

    if (name === 'department_evaluation') {
      const departmentEvaluation = evaluation.find(
        item => item.department_id === value
      );

      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          department_id: value,
          cooperation_evaluation:
            departmentEvaluation?.cooperation_evaluation ?? '',
          potential_evaluation: departmentEvaluation?.potential_evaluation ?? ''
        }
      }));
    } else {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value
        }
      }));
    }
  }

  async makeApiCall() {
    this.setState({ loading: true });
    try {
      await ClientsEvaluationApi.createEvaluation({
        ...this.state.formData,
        client_id: this.client.client_id
      });
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    const { open, departments } = this.props;

    if (isEmpty(departments)) {
      return <PageLoader />;
    }

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t("Client's evaluation")}</DialogTitle>
        <DialogContent>
          <form id='evaluation-form' noValidate onSubmit={this.onSubmit}>
            {departments.length > 1 ? (
              <Select
                name='department_evaluation'
                label='Department'
                value={this.state.formData.department_id}
                required
                options={formatOptions(departments, 'id', 'name')}
                onChange={this.onChange}
              />
            ) : (
              <Typography>
                <strong>{departments[0].name}</strong>
              </Typography>
            )}

            <Box display='flex'>
              <Select
                name='cooperation_evaluation'
                label='Cooperation'
                value={this.state.formData?.cooperation_evaluation}
                required
                options={['A', 'B', 'C']}
                onChange={this.onChange}
              />
              <Select
                name='potential_evaluation'
                label='Potential'
                value={this.state.formData?.potential_evaluation}
                required
                options={['A', 'B', 'C']}
                onChange={this.onChange}
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            form='evaluation-form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
          <Button text={t('Cancel')} onClick={this.onClose} />
        </DialogActions>
      </Dialog>
    );
  }
}

ClientEvaluationModal.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number,
    evaluation: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string }))
    .isRequired,
  setAlert: PropTypes.func.isRequired
};

export default ClientEvaluationModal;
