import { Component } from 'react';
import PropTypes from 'prop-types';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import UsersApi from 'api/connections/Users/UsersApi';
import VisitsTable from 'modules/Visits/components/VisitsTable';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  TRADER,
  TELEMARKETER,
  KEY_ACCOUNT_MANAGER,
  ADMIN,
  DIRECTOR,
  SUPER_ADMIN,
  DEPARTMENT_MANAGER
} from 'api/auth/roles';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import t from 'translate/translate';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import {
  ROUTE_VISITS_CREATE,
  ROUTE_VISITS_CALENDAR
} from 'routing/routes/Visits';
import { Link, withRouter } from 'react-router-dom';
import { Box, IconButton } from '@material-ui/core';
import LayoutIconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import {
  PLANNED,
  CONFIRMED,
  FINISHED,
  POSTPONED
} from 'modules/Visits/utils/visitStatuses';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import VisitFilesApi from 'api/connections/Visits/VisitFilesApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import { downloadFile } from 'modules/Shared/utils/file';
import Button from 'modules/Layout/component/Button';
import VisitsTableFilter from 'modules/Visits/components/VisitsTable/Filter';

const managerUsers = (users = [], managerId) => {
  return users
    .map(user => ({
      ...user,
      managersIds: user.departments?.map(({ manager_id }) => manager_id)
    }))
    .filter(user => user.managersIds.includes(managerId));
};

class Visits extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      visits: [],
      users: [],
      clients: [],
      open: false,
      chosenItem: {},
      openVisitModal: true,
      usersFilter: {
        roles: [TRADER, TELEMARKETER, KEY_ACCOUNT_MANAGER],
        per_page: Number.MAX_SAFE_INTEGER,
        country_id: context.hasRole([ADMIN]) ? context.user.country_id : ''
      },
      filter: {
        statuses: [PLANNED, CONFIRMED, FINISHED, POSTPONED],
        my_clients: context.hasRole([DEPARTMENT_MANAGER]),
        my_visits: !context.hasRole([SUPER_ADMIN, ADMIN]),
        date_from: '',
        date_to: '',
        client_id: ''
      },
      filterValidation: {},
      sort: { sort_field: 'scheduled_at', sort_order: 'asc' },
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      isLoading: true
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportVisits = this.exportVisits.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const { hasRole } = this.context;

    this.setCurrentPage('Visits');

    if (hasRole([ADMIN, DIRECTOR, SUPER_ADMIN, DEPARTMENT_MANAGER])) {
      this.fetchData();
    } else if (hasRole([TRADER, TELEMARKETER, KEY_ACCOUNT_MANAGER])) {
      this.fetchClients();
      this.fetchVisits();
    }
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.setAlert({ value: t(message.value), variant: message.variant });

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  setSort(sort, type, fetchItems) {
    this.setState({ [type]: { ...this.state[type], ...sort } }, () =>
      fetchItems()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination }
      },
      () => this.fetchVisits()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchVisits()
    );
  }

  async fetchData() {
    try {
      this.setState({ isLoading: true });

      const [
        {
          data: { data: fetchedVisits }
        },
        {
          data: { data: fetchedUsers }
        },
        {
          data: { data: clients }
        }
      ] = await Promise.all([
        VisitsApi.getVisits({
          ...this.state.sort,
          ...this.state.filter,
          per_page: this.state.pagination.per_page,
          page: this.state.pagination.page
        }),
        UsersApi.getUsers({ ...this.state.usersFilter }),
        ClientsApi.getAllClients()
      ]);

      fetchedUsers.forEach(user => {
        // eslint-disable-next-line no-param-reassign
        user.name = `${user.first_name} ${user.last_name}`;
      });
      this.setState({
        clients,
        visits: fetchedVisits,
        users: this.context.hasRole([DEPARTMENT_MANAGER])
          ? managerUsers(fetchedUsers, this.context.user.id)
          : fetchedUsers
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async fetchVisits() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    this.setState({ isLoading: true });

    try {
      const {
        data: { data: fetchedVisits, meta }
      } = await VisitsApi.getVisits({
        ...sort,
        ...filter,
        per_page,
        page
      });
      this.setState({
        visits: fetchedVisits,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async fetchClients() {
    const pathParams = {
      my_clients: this.context.hasRole([
        TRADER,
        TELEMARKETER,
        KEY_ACCOUNT_MANAGER
      ])
    };

    try {
      const {
        data: { data: clients }
      } = await ClientsApi.getAllClients(pathParams);

      this.setState({ clients });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportVisits() {
    try {
      const { filter, sort } = this.state;

      const res = await VisitFilesApi.exportVisits({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert({ value: t(message.value), variant: message.variant });
      }
    }
  }

  renderActions() {
    const { hasRole } = this.context;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          {hasRole([
            TRADER,
            KEY_ACCOUNT_MANAGER,
            TELEMARKETER,
            DEPARTMENT_MANAGER
          ]) && (
            <Link to={ROUTE_VISITS_CREATE} className='router-button'>
              <IconButton>
                <AddIcon color='primary' fontSize='large' />
              </IconButton>
            </Link>
          )}
          <LayoutIconButton
            onClick={this.exportVisits}
            icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
            alt='export table'
          />
        </Box>
        <Pagination
          pagination={this.state.pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Box>
    );
  }

  render() {
    const {
      visits,
      users,
      clients,
      sort: sortData,
      filter,
      filterValidation,
      isLoading
    } = this.state;

    return (
      <>
        <VisitsTableFilter
          setFilter={this.setFilter}
          filter={filter}
          validation={filterValidation}
          users={users}
          clients={clients}
        />
        <Button
          color='primary'
          text={t('Calendar')}
          onClick={() => this.props.history.push(ROUTE_VISITS_CALENDAR)}
          style={{ marginLeft: 'auto', display: 'block' }}
        />
        {this.renderActions()}
        <VisitsTable
          sort={sortData}
          setSort={sort => this.setSort(sort, 'sort', () => this.fetchVisits())}
          visits={visits}
          users={users}
          loading={isLoading}
        />
        {this.renderActions()}
      </>
    );
  }
}

Visits.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default withRouter(Visits);
