import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from 'modules/ProductCategories/view/List/style';
import { Box, Paper, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import {
  PRODUCT_CATEGORIES_UPDATE,
  PRODUCT_CATEGORIES_DELETE
} from 'api/auth/permissions/Products';
import Authorize from 'modules/Auth/component/Authorize';
import { ProductCategoriesContext } from 'modules/ProductCategories/context/ProductCategoriesProvider';
import { ROUTE_PRODUCT_CATEGORIES_UPDATE } from 'routing/routes/ProductCategories';
import AuthContext from 'modules/Auth/context/Auth/authContext';

const ProductCategoriesItem = props => {
  const { item, onClick, classes } = props;
  const {
    selectedGroupId,
    selectedCategoryId,
    setDeleteItem,
    setItem
  } = useContext(ProductCategoriesContext);
  const history = useHistory();
  const { hasSomeOfPermission } = useContext(AuthContext);

  const onEditBtnClick = e => {
    e.stopPropagation();
    setItem(item);
    history.push(ROUTE_PRODUCT_CATEGORIES_UPDATE);
  };

  const showActionsBtnStatus = hasSomeOfPermission([
    PRODUCT_CATEGORIES_UPDATE,
    PRODUCT_CATEGORIES_DELETE
  ]);

  const selectedStatus =
    selectedGroupId === item.id || selectedCategoryId === item.id;

  return (
    <Paper variant='outlined' className={clsx(classes.item)}>
      <Box display='flex' flexDirection='row'>
        <Box
          className={clsx(
            classes.itemContentText,
            selectedStatus && classes.selectedItem,
            selectedStatus && !showActionsBtnStatus && classes.borderRadius
          )}
          onClick={() => onClick(item)}
        >
          <Typography>{item.name}</Typography>
        </Box>
        {showActionsBtnStatus && (
          <Box className={classes.itemContentBtn}>
            <Authorize permissions={[PRODUCT_CATEGORIES_UPDATE]}>
              <IconButton
                className='update-icon'
                onClick={onEditBtnClick}
                icon={<EditIcon />}
                size='small'
                alt='update'
              />
            </Authorize>
            <Authorize permissions={[PRODUCT_CATEGORIES_DELETE]}>
              <IconButton
                className='delete-icon'
                onClick={e => setDeleteItem(e, item)}
                icon={<DeleteForeverIcon />}
                disabled={!item.can_delete}
                size='small'
                alt='delete'
                tooltipMsg='Category/product group cannot be deleted when has at least one department or product'
              />
            </Authorize>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

ProductCategoriesItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    can_delete: PropTypes.bool.isRequired
  }).isRequired,
  classes: PropTypes.shape({
    item: PropTypes.string.isRequired,
    selectedItem: PropTypes.string.isRequired,
    borderRadius: PropTypes.string.isRequired,
    itemContentText: PropTypes.string.isRequired,
    itemContentBtn: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(useStyles)(ProductCategoriesItem);
