import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import { Box, Tooltip, Typography } from '@material-ui/core';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import t, { availableLocals } from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import { validate } from 'modules/Shared/utils/validator';
import formatFormMultiLangObj from 'modules/Shared/utils/formatFormMultiLangObj';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ProductsApi from 'api/connections/Products/ProductsApi';
import {
  ROUTE_PRODUCTS_DETAILS,
  ROUTE_PRODUCTS_LIST
} from 'routing/routes/Products';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import Authorize from 'modules/Auth/component/Authorize';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import DatePicker from 'modules/Layout/component/Date/DatePicker';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Checkbox from '../../../../Layout/component/Checkbox';

class ProductUpdateForm extends Component {
  constructor(props) {
    super(props);

    const { product } = props;

    this.polandId = props.countries.find(c => c.language === 'pl')?.id;

    const {
      sku: formattedSku,
      prices: formattedPrices,
      taxes: formattedTaxes
    } = this.formatSkuPricesTaxes();

    this.state = {
      productId: product.id,
      formData: {
        sku: isEmpty(product.all_sku) ? formattedSku : product.all_sku,
        description: formatFormMultiLangObj(product.description),
        ean: product.ean || '',
        index: product.index || '',
        category_id: product.subcategory_id || '',
        prices: isEmpty(product.prices) ? formattedPrices : product.prices,
        taxes: isEmpty(product.taxes) ? formattedTaxes : product.taxes,
        countries: product.countries || [],
        sale_start_date: product.sale_start_date || '',
        direct_order_only: product.direct_order_only,
        promotions_blocked: product.promotions_blocked
      },
      validation: {
        sku: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Name') })
        },
        category_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Product subcategory')
          })
        },
        sale_start_date: {
          status: false,
          message: t('Wrong Date format, correct one is dd.MM.yyyy')
        },
        index: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Index')
          })
        }
      },
      loading: false,
      categoryGroupId: props.product.group_id,
      categoryId: props.product.category_id
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
  }

  handleResponse() {
    const { productId } = this.state;

    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(
      insertPathParams(ROUTE_PRODUCTS_DETAILS, { id: productId })
    );
  }

  handleError(err) {
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      this.setState(({ validation: validationState }) => {
        return {
          validation: { ...validationState, ...newValidateState }
        };
      });
    }
    this.props.setAlert(message);
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;
      const [nameKey, nameDetails] = name.split('.');

      const newState = {
        formData: {
          ...formData
        },
        validation: {
          ...validation
        }
      };

      switch (nameKey) {
        case 'countries': {
          const {
            formData: { sku: formSku, prices: formPrices, taxes: formTaxes }
          } = state;
          const { sku, prices, taxes } = this.formatSkuPricesTaxes(
            formSku,
            formPrices,
            formTaxes,
            value
          );
          newState.formData.countries = value;
          newState.formData.sku = sku;
          newState.formData.prices = prices;
          newState.formData.taxes = taxes;
          break;
        }
        case 'description': {
          newState.formData[nameKey] = {
            ...formData[nameKey],
            [nameDetails]: value
          };
          if (validation[nameKey] && validation[nameKey][nameDetails]) {
            newState.validation[nameKey] = {
              ...validation[nameKey],
              [nameDetails]: {
                ...validation[nameKey][nameDetails],
                status: false
              }
            };
          }
          break;
        }
        case 'sku': {
          newState.formData.sku = formData.sku.map(sku =>
            sku.country_id === parseInt(nameDetails, 10)
              ? { ...sku, value }
              : sku
          );

          if (this.polandId && parseInt(nameDetails, 10) !== this.polandId)
            break;

          newState.validation.sku = {
            ...validation.sku,
            status: false
          };
          break;
        }
        case 'price': {
          newState.formData.prices = formData.prices.map(price =>
            price.country_id === parseInt(nameDetails, 10)
              ? { ...price, price: value }
              : price
          );
          break;
        }
        case 'tax': {
          newState.formData.taxes = formData.taxes.map(tax =>
            tax.country_id === parseInt(nameDetails, 10)
              ? { ...tax, tax_id: value }
              : tax
          );
          break;
        }
        case 'direct_order_only': {
          newState.formData[nameKey] = +!state.formData[nameKey];
          break;
        }
        default: {
          newState.formData[nameKey] = value;
          if (newState.validation[nameKey]) {
            newState.validation[nameKey].status = validation[nameKey] && false;
          }
        }
      }

      return newState;
    });
  }

  onCheckboxChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, checked } = e.target;

      return {
        formData: {
          ...formData,
          [name]: checked
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onCategoryChange(name, inputValue) {
    const value = inputValue ? inputValue.key : null;

    this.setState(state => {
      const { formData, validation, categoryGroupId, categoryId } = state;

      const newState = {
        categoryGroupId,
        categoryId,
        formData: {
          ...formData,
          category_id: ''
        },
        validation: {
          ...validation,
          category_id: {
            ...validation.category_id,
            status: false
          }
        }
      };

      if (name === 'category_group') {
        newState.categoryGroupId = value;
        newState.categoryId = '';
      } else if (name === 'category') {
        newState.categoryId = value;
      } else {
        newState.formData.category_id = value;
      }

      return newState;
    });
  }

  formatSkuPricesTaxes(
    formSku = [],
    formPrices = [],
    formTaxes = [],
    formExclusiveCountries = []
  ) {
    const {
      user: { country_id: userCountryId },
      hasPermission
    } = this.props.authContext;
    const { countries: countriesData } = this.props;

    const newSku = [];
    const newPrices = [];
    const newTaxes = [];

    const getFormSkuValue = countryId =>
      formSku.find(sku => sku.country_id === countryId)?.value;
    const getFormPriceValue = countryId =>
      formPrices.find(price => price.country_id === countryId)?.price;
    const getFormTaxValue = countryId =>
      formTaxes.find(tax => tax.country_id === countryId)?.tax_id;

    if (hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
      const countriesIds = isEmpty(formExclusiveCountries)
        ? countriesData.map(country => country.id)
        : formExclusiveCountries;

      newSku.push({
        country_id: this.polandId,
        value: getFormSkuValue(this.polandId) || ''
      });

      countriesIds.forEach(countryId => {
        countryId !== this.polandId &&
          newSku.push({
            country_id: countryId,
            value: getFormSkuValue(countryId) || ''
          });
        newPrices.push({
          country_id: countryId,
          price: getFormPriceValue(countryId) || ''
        });
        newTaxes.push({
          country_id: countryId,
          tax_id: getFormTaxValue(countryId) || ''
        });
      });
    } else {
      newSku.push({
        country_id: userCountryId,
        value: ''
      });
      newPrices.push({
        country_id: userCountryId,
        price: ''
      });
      newTaxes.push({
        country_id: userCountryId,
        tax_id: null
      });
    }

    return { sku: newSku, prices: newPrices, taxes: newTaxes };
  }

  runValidation() {
    const {
      user: { country_id: userCountryId }
    } = this.props.authContext;
    const { formData, validation } = this.state;
    const skuValue = this.polandId
      ? formData.sku.find(sku => sku.country_id === this.polandId)?.value
      : formData.sku.find(sku => sku.country_id === userCountryId)?.value;

    /* eslint prefer-const: "off" */
    let [isValid, newValidateState] = validate(formData, validation);

    if (!skuValue) {
      isValid = false;
      newValidateState.sku.status = true;
    }

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData, productId } = this.state;
    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await ProductsApi.updateProduct(productId, formData);
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  renderCategorySection() {
    const {
      formData: { category_id },
      validation,
      categoryGroupId,
      categoryId
    } = this.state;

    const {
      categories,
      product: { can_change_category }
    } = this.props;

    const section = (
      <Box className='language-form-box'>
        <Autocomplete
          label='Product group'
          name='category_group'
          value={categoryGroupId}
          options={formatOptions(categories, 'id', 'name')}
          onChange={(e, value) =>
            this.onCategoryChange('category_group', value)
          }
          disabled={!can_change_category}
        />
        <Autocomplete
          label='Category'
          name='category'
          value={categoryId}
          options={formatOptions(
            categories.find(g => g.id === categoryGroupId)?.categories || [],
            'id',
            'name'
          )}
          onChange={(e, value) => this.onCategoryChange('category', value)}
          disabled={!can_change_category}
        />
        <Autocomplete
          label='Product subcategory'
          name='category_id'
          value={category_id}
          required
          options={formatOptions(
            categories
              .find(g => g.id === categoryGroupId)
              ?.categories.find(c => c.id === categoryId)?.subcategories || [],
            'id',
            'name'
          )}
          onChange={(e, v) => this.onCategoryChange('category_id', v)}
          errorStatus={validation.category_id.status}
          errorText={validation.category_id.message}
          disabled={!can_change_category}
        />
      </Box>
    );

    return can_change_category ? (
      section
    ) : (
      <Tooltip
        title={t(
          'You cannot change the category when there are open sales targets for that category.'
        )}
      >
        {section}
      </Tooltip>
    );
  }

  render() {
    const {
      formData: {
        sku,
        description,
        ean,
        index,
        prices,
        taxes,
        countries,
        sale_start_date,
        direct_order_only,
        promotions_blocked
      },
      validation,
      loading
    } = this.state;

    const {
      countries: countriesData,
      taxes: taxesData,
      product: { can_change_category }
    } = this.props;

    const {
      user: { country_id: userCountryId },
      hasPermission
    } = this.props.authContext;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Typography component='h6' variant='h6'>
          {t('Name')}
        </Typography>
        <Box className='language-form-box'>
          {hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) ? (
            <>
              {sku.map(skuObj => {
                const { country_id, value } = skuObj;
                return (
                  <Input
                    key={`sku.${country_id}`}
                    name={`sku.${country_id}`}
                    label={
                      countriesData.find(country => country.id === country_id)
                        .name
                    }
                    value={value}
                    onChange={this.onChange}
                    errorStatus={
                      country_id === this.polandId && validation.sku.status
                    }
                    errorText={
                      country_id === this.polandId ? validation.sku.message : ''
                    }
                  />
                );
              })}
            </>
          ) : (
            <Input
              name={`sku.${userCountryId}`}
              label='Name'
              value={sku.find(s => s.country_id === userCountryId)?.value}
              onChange={this.onChange}
              errorStatus={validation.sku.status}
              errorText={validation.sku.message}
            />
          )}
        </Box>
        <Typography component='h6' variant='h6'>
          {t('Other identifications')}
        </Typography>
        <Box className='language-form-box'>
          <Input name='ean' label='EAN' value={ean} onChange={this.onChange} />
          <Input
            name='index'
            label='Index'
            value={index}
            onChange={this.onChange}
            required
            errorStatus={validation.index.status}
            errorText={validation.index.message}
          />
          <Checkbox
            onChange={this.onCheckboxChange}
            checked={promotions_blocked}
            name='promotions_blocked'
            label='Product unavailable in promotions'
          />
        </Box>
        <Typography component='h6' variant='h6'>
          {t('Information about market availability')}
        </Typography>
        <Box className='language-form-box'>
          <Checkbox
            name='direct_order_only'
            label='Product available only in direct orders'
            onChange={this.onChange}
            checked={Boolean(direct_order_only)}
          />
          <DatePicker
            name='sale_start_date'
            label='Date of sale'
            value={sale_start_date}
            initValueNow
            onChange={this.onChange}
          />
          <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
            <Select
              name='countries'
              label='Product limited to countries'
              value={countries}
              options={formatOptions(countriesData, 'id', 'name')}
              onChange={this.onChange}
              multiple
            />
            <Typography variant='caption' align='justify' display='block'>
              {t(
                'The product is available worldwide by default. In order to limit the availability to selected countries, fill in the field "Product limited to countries". Then the product will be visible only to employees assigned to the indicated countries.'
              )}
            </Typography>
          </Authorize>
        </Box>
        <Typography component='h6' variant='h6'>
          {t('Description')}
        </Typography>
        <Box className='language-form-box'>
          {availableLocals.map(local => {
            const { slug: localSlug, name: localName } = local;
            return (
              <Input
                key={`description.${localSlug}`}
                name={`description.${localSlug}`}
                multiline
                label={localName}
                value={description[localSlug]}
                onChange={this.onChange}
              />
            );
          })}
        </Box>
        <Typography
          component='h6'
          variant='h6'
          color={can_change_category ? 'initial' : 'textSecondary'}
        >
          {t('Category')}
        </Typography>
        {this.renderCategorySection()}
        {hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) ? (
          <>
            <Typography component='h6' variant='h6'>
              {t('Price')}
            </Typography>
            <Box className='language-form-box'>
              {prices.map(priceObj => {
                const { country_id, price } = priceObj;
                return (
                  <Input
                    key={`price.${country_id}`}
                    name={`price.${country_id}`}
                    label={
                      countriesData.find(country => country.id === country_id)
                        .name
                    }
                    value={price || ''}
                    type='number'
                    onChange={this.onChange}
                  />
                );
              })}
            </Box>
            <Typography component='h6' variant='h6'>
              {t('Taxes')}
            </Typography>
            <Box className='language-form-box'>
              {taxes.map(taxObj => {
                const { country_id, tax_id } = taxObj;
                return (
                  <Select
                    key={`tax.${country_id}`}
                    name={`tax.${country_id}`}
                    label={
                      countriesData.find(country => country.id === country_id)
                        .name
                    }
                    value={tax_id || ''}
                    options={formatOptions(
                      taxesData.filter(tax => tax.country_id === country_id) ||
                        [],
                      'id',
                      'name'
                    )}
                    onChange={this.onChange}
                  />
                );
              })}
            </Box>
          </>
        ) : (
          <>
            <Input
              name={`price.${userCountryId}`}
              label='Price'
              value={
                prices.find(price => price.country_id === userCountryId)?.price
              }
              type='number'
              onChange={this.onChange}
            />
            <Select
              name={`tax.${userCountryId}`}
              label='Taxes'
              value={
                taxes.find(tax => tax.country_id === userCountryId)?.tax_id
              }
              options={formatOptions(
                taxesData.filter(tax => tax.country_id === userCountryId) || [],
                'id',
                'name'
              )}
              onChange={this.onChange}
            />
          </>
        )}
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link to={ROUTE_PRODUCTS_LIST} className='router-button'>
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

ProductUpdateForm.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    all_sku: PropTypes.arrayOf(PropTypes.shape({})),
    description: PropTypes.shape({}),
    ean: PropTypes.string,
    index: PropTypes.string,
    group_id: PropTypes.number,
    category_id: PropTypes.number,
    subcategory_id: PropTypes.number,
    prices: PropTypes.arrayOf(PropTypes.shape({})),
    taxes: PropTypes.arrayOf(PropTypes.shape({})),
    countries: PropTypes.arrayOf(PropTypes.number),
    sale_start_date: PropTypes.string,
    direct_order_only: PropTypes.oneOf([0, 1]).isRequired,
    can_change_category: PropTypes.bool.isRequired,
    can_delete: PropTypes.bool.isRequired,
    promotions_blocked: PropTypes.bool.isRequired
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  taxes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  authContext: PropTypes.shape({
    user: PropTypes.shape({
      country_id: PropTypes.number
    }).isRequired,
    hasPermission: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(ProductUpdateForm);
