import ListIcon from '@material-ui/icons/List';
import BarChartIcon from '@material-ui/icons/BarChart';
import {
  ROUTE_ORDERS_LIST,
  permMap as ordersPermMap
} from 'routing/routes/Orders';
import {
  ROUTE_SALES_LIST,
  permMap as salesPermMap
} from 'routing/routes/Sales';
import {
  ROUTE_SALES_IMPORTS_LIST,
  permMap as salesImportsPermMap
} from 'routing/routes/SalesImports';
import {
  ROUTE_SALES_RESULT_LIST,
  ROUTE_SALES_RESULT_CURRENT_COMPARISON,
  permMap as salesPeriodsPermMap,
  ROUTE_CLIENT_SALES_RESULT_CURRENT_COMPARISON
} from 'routing/routes/SalesResults';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { ADMIN, CLIENT, SUPER_ADMIN } from 'api/auth/roles';

const menu = [
  {
    key: 'menu-sales',
    label: 'Sales',
    icon: <BarChartIcon />,
    path: ROUTE_SALES_LIST,
    anyPermissions: [
      ...ordersPermMap[ROUTE_ORDERS_LIST],
      ...salesImportsPermMap[ROUTE_SALES_IMPORTS_LIST],
      ...salesPeriodsPermMap[ROUTE_SALES_RESULT_LIST],
      ...salesPermMap[ROUTE_SALES_LIST]
    ],
    roles: [CLIENT],
    children: [
      {
        key: 'menu-orders',
        label: 'Orders',
        icon: <ListIcon />,
        path: ROUTE_ORDERS_LIST,
        permissions: ordersPermMap[ROUTE_ORDERS_LIST]
      },
      {
        key: 'menu-sales-imports-list',
        label: 'Data imports',
        icon: <ListIcon />,
        path: ROUTE_SALES_IMPORTS_LIST,
        permissions: salesImportsPermMap[ROUTE_SALES_IMPORTS_LIST]
      },
      {
        key: 'menu-sales-results-list',
        label: 'Sales results archive',
        icon: <ListIcon />,
        path: ROUTE_SALES_RESULT_LIST,
        permissions: salesPeriodsPermMap[ROUTE_SALES_RESULT_LIST]
      },
      {
        key: 'menu-sales-results-current-comparison',
        label: 'Comparison of sales results',
        icon: <EqualizerIcon />,
        path: ROUTE_SALES_RESULT_CURRENT_COMPARISON,
        permissions: salesPeriodsPermMap[ROUTE_SALES_RESULT_CURRENT_COMPARISON]
      },
      {
        key: 'menu-sales-list',
        label: 'Data set',
        icon: <ListIcon />,
        path: ROUTE_SALES_LIST,
        roles: [SUPER_ADMIN, ADMIN]
      },
      {
        key: 'menu-clients-sales-results',
        label: 'Comparison of sales results',
        icon: <EqualizerIcon />,
        path: ROUTE_CLIENT_SALES_RESULT_CURRENT_COMPARISON,
        roles: [CLIENT]
      }
    ]
  }
];

export default menu;
