import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import OrderForm from 'modules/Orders/component/OrderPreparing/Form';
import OrdersApi from 'api/connections/Orders/OrdersApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_ORDERS_LIST } from 'routing/routes/Orders';
import Loader from 'modules/Layout/component/Loader';

class OrderCreate extends Component {
  constructor(props) {
    super(props);

    this.orderId = props.match.params.id;

    this.state = {
      order: null
    };
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Update order');
    if (!this.orderId) {
      this.redirectToList();
    }
    this.fetchOrder();
  }

  redirectToList() {
    this.props.history.push(ROUTE_ORDERS_LIST);
  }

  async fetchOrder() {
    try {
      const {
        data: { data: order }
      } = await OrdersApi.getOrder(this.orderId);

      this.setState({ order });
    } catch (err) {
      if (err instanceof ApiError) {
        this.props.contextMethods.setAlert(err.getPayload().message);

        if (err instanceof NotFoundApiError) {
          this.redirectToList();
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { order } = this.state;

    if (!order) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} xl={10}>
          <Paper className='p1'>
            <OrderForm
              orderData={order}
              setAlert={this.props.contextMethods.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

OrderCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(OrderCreate);
