import { Box, Paper } from '@material-ui/core';
import CreatePostalCodesForm from 'modules/PostalCodes/component/CreatePostalCodesForm';
import { useContext } from 'react';
import { PostalCodesContext } from 'modules/PostalCodes/context/PostalCodesProvider';
import { useCurrentPage } from 'modules/Shared/hook';
import t from 'translate/translate';

const PostalCodesCreateView = () => {
  const { chosenItem } = useContext(PostalCodesContext);
  useCurrentPage(t(`Adding a ${chosenItem.type}`));

  return (
    <Box width={1} display='flex' justifyContent='center'>
      <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
        <Paper style={{ padding: '1rem' }}>
          <CreatePostalCodesForm item={chosenItem} />
        </Paper>
      </Box>
    </Box>
  );
};

export default PostalCodesCreateView;
