import { Component } from 'react';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import PropTypes from 'prop-types';
import Loader from 'modules/Layout/component/Loader';
import { isEmpty } from 'lodash/lang';
import capitalize from 'modules/Shared/utils/capitalize';
import ClientsTreeSubregions from 'modules/Clients/component/ClientsTree/Subregions';
import ClientsTreeCities from 'modules/Clients/component/ClientsTree/Cities';

class ClientsTreeRegions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataToRender: undefined
    };
  }

  async componentDidMount() {
    const dataToRender = await this.props.getDataToRender('regions', 'regions');

    this.setState({ dataToRender });
  }

  render() {
    const { dataToRender } = this.state;
    const { selectMode, depth } = this.props;

    if (dataToRender === undefined) return <Loader />;

    if (isEmpty(dataToRender)) return null;

    return dataToRender.map(region => {
      const isVisible = this.props.isVisible('regions', region.id);
      const isSelected = this.props.isSelected('regions', region.id);

      if (!isVisible && !isSelected && !selectMode) return null;

      const item = {
        postalCodeLevel: 'regions',
        ...region
      };

      return (
        <TreeItem
          itemData={item}
          key={`region-${region.id}`}
          nodeId={`region-${region.id}`}
          labelText={capitalize(region.name)}
          labelInfo={
            selectMode ? region.clients_number : region.assigned_clients_number
          }
          selectMode={selectMode}
          isSelected={isSelected}
          onSelect={this.props.onSelect}
          actionBtn={
            !selectMode && isSelected && this.props.renderDeleteItemBtn(item)
          }
        >
          {depth === 0 && (
            <ClientsTreeCities
              regionId={region.id}
              getDataToRender={this.props.getDataToRender}
              isVisible={this.props.isVisible}
              isParentSelected={isSelected}
              isSelected={this.props.isSelected}
              selectMode={selectMode}
              onSelect={this.props.onSelect}
              renderDeleteItemBtn={this.props.renderDeleteItemBtn}
            />
          )}
          {(depth === 1 || depth === 2) && (
            <ClientsTreeSubregions
              regionId={region.id}
              getDataToRender={this.props.getDataToRender}
              isVisible={this.props.isVisible}
              isParentSelected={isSelected}
              isSelected={this.props.isSelected}
              selectMode={selectMode}
              onSelect={this.props.onSelect}
              depth={depth}
              renderDeleteItemBtn={this.props.renderDeleteItemBtn}
            />
          )}
        </TreeItem>
      );
    });
  }
}

ClientsTreeRegions.defaultProps = {
  selectMode: false,
  depth: 2
};

ClientsTreeRegions.propTypes = {
  getDataToRender: PropTypes.func.isRequired,
  isVisible: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  selectMode: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  depth: PropTypes.number,
  renderDeleteItemBtn: PropTypes.func.isRequired
};

export default ClientsTreeRegions;
