import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import PageLoader from 'modules/Layout/component/PageLoader';
import { hasToken } from 'api/auth/services/token';
import { ROUTE_DASHBOARD } from 'routing/routes/Dashboard';
import { ROUTE_LOGIN, ROUTE_FORCED_CHANGE_PASSWORD } from 'routing/routes/Auth';

import AuthContext from 'modules/Auth/context/Auth/authContext';

const Guard = ({ children, unguarded }) => {
  const { user, getUser, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const loadUser = async () => {
    await getUser();
    setLoading(false);
  };

  const logoutUser = async () => {
    logout();
    setLoading(false);
  };

  /* eslint no-unused-expressions: off */
  useEffect(() => {
    if (!user && hasToken()) {
      loadUser();
    }
    if (!hasToken()) {
      logoutUser();
    }
    if (user && hasToken()) {
      setLoading(false);
    }
  }, [user]);

  if (!loading) {
    if (user && user.must_change_password) {
      return <Redirect to={ROUTE_FORCED_CHANGE_PASSWORD} />;
    }
    if (!unguarded && !user) {
      return <Redirect to={ROUTE_LOGIN} />;
    }
    if (unguarded && user) {
      return <Redirect to={ROUTE_DASHBOARD} />;
    }

    return children;
  }

  return <PageLoader />;
};

Guard.defaultProps = {
  unguarded: false
};

Guard.propTypes = {
  children: PropTypes.node.isRequired,
  unguarded: PropTypes.bool
};

export default Guard;
