import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import VisitProductsApi from 'api/connections/Visits/VisitProductsApi';

const DeleteVisitProductDialog = props => {
  const {
    product: { id: productId, product_sku: sku },
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteMapping = async () => {
    try {
      await VisitProductsApi.deleteVisitProduct(productId);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess(productId);
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete product "<%=sku%>" from current visit?',
        {
          sku
        }
      )}
      onApprove={deleteMapping}
      onCancel={onClose}
    />
  );
};

DeleteVisitProductDialog.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_sku: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default DeleteVisitProductDialog;
