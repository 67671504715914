import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import { withRouter } from 'react-router-dom';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DeleteVisitProductDialog from 'modules/Visits/components/Details/Products/DeleteDialog';
import UpdateVisitProductDialog from 'modules/Visits/components/Details/Products/UpdateDialog';

class VisitProductsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.visitId = this.props.visitId;
    this.state = {
      deleteDialogOpen: false,
      updateDialogOpen: false,
      chosenProduct: null
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
    this.onUpdateDialogClose = this.onUpdateDialogClose.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
  }

  onDeleteClick(product) {
    this.setState({ deleteDialogOpen: true, chosenProduct: product });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, chosenProduct: null });
  }

  onUpdateClick(product) {
    this.setState({ updateDialogOpen: true, chosenProduct: product });
  }

  onUpdateDialogClose() {
    this.setState({ updateDialogOpen: false, chosenProduct: null });
  }

  getCols() {
    return [
      {
        property: 'product_sku',
        label: 'Name',
        sortable: false,
        link: { route: '/products/details/', param: 'product_id' }
      },
      {
        property: 'category_name',
        label: 'Category',
        sortable: false
      },
      {
        property: 'group_name',
        label: 'Product group name',
        sortable: false
      },
      {
        property: 'note',
        label: 'Note',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: false,
            disable: () => true,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: true,
            disable: false,
            disableMsg: 'You are not creator of this note',
            onClick: this.onUpdateClick
          },
          remove: {
            status: true,
            disable: false,
            disableMsg: 'You are not creator of this note',
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const {
      products,
      onDeleteProductSuccess,
      onUpdateProductSuccess
    } = this.props;
    const { deleteDialogOpen, updateDialogOpen, chosenProduct } = this.state;

    return (
      <>
        <Table cols={this.getCols()} rows={products} dense actions />
        {deleteDialogOpen && (
          <DeleteVisitProductDialog
            product={chosenProduct}
            onClose={this.onDeleteDialogClose}
            onSuccess={onDeleteProductSuccess}
          />
        )}
        {updateDialogOpen && (
          <UpdateVisitProductDialog
            open={updateDialogOpen}
            product={chosenProduct}
            visitId={this.visitId}
            onClose={this.onUpdateDialogClose}
            onSuccess={onUpdateProductSuccess}
          />
        )}
      </>
    );
  }
}

VisitProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  visitId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  onDeleteProductSuccess: PropTypes.func.isRequired,
  onUpdateProductSuccess: PropTypes.func.isRequired
};

export default withRouter(VisitProductsList);
