import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import t from 'translate/translate';
import {
  FINISHED,
  statusesObj as visitStatuses
} from 'modules/Visits/utils/visitStatuses';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_VISITS_DETAILS,
  ROUTE_VISITS_UPDATE,
  ROUTE_VISITS_POSTPONE
} from 'routing/routes/Visits';
import {
  VISITS_SHOW,
  VISITS_UPDATE,
  VISITS_POSTPONE,
  VISITS_CANCEL
} from 'api/auth/permissions/Visits';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  ADMIN,
  DIRECTOR,
  SUPER_ADMIN,
  DEPARTMENT_MANAGER
} from 'api/auth/roles';
import CancelVisitModal from 'modules/Visits/components/CancelVisitModal';

class VisitsTable extends Component {
  static contextType = AuthContext;

  static setDate = dateData => {
    if (dateData === '-') return '-';

    const [date] = dateData.split(' ');

    return date;
  };

  constructor(props) {
    super(props);

    this.state = {
      openVisitModal: false,
      chosenVisit: {}
    };
  }

  getCols() {
    return [
      {
        property: 'scheduled_at',
        label: 'Date',
        sortable: true,
        render: value => VisitsTable.setDate(value)
      },
      {
        property: 'time',
        label: 'Time',
        sortable: false
      },
      {
        property: 'client_name',
        label: 'Client',
        sortable: false
      },
      {
        property: 'user_name',
        label: 'User',
        sortable: false
      },
      {
        property: 'place',
        label: 'Place',
        sortable: false
      },
      {
        property: 'status',
        label: 'Status',
        sortable: true,
        render: value => t(visitStatuses[value])
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasPermission([VISITS_SHOW]),
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([VISITS_UPDATE]),
            disable: this.isUpdateDisabled,
            disableMsg: t(
              'You can update your or your employee visit which is not finished.'
            ),
            onClick: this.onUpdateClick
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: () => {}
          },
          postpone: {
            status: this.context.hasPermission([VISITS_POSTPONE]),
            disable: this.isUpdateDisabled,
            disableMsg: t(
              'You can postpone your or your employee visit which is not finished.'
            ),
            onClick: this.onPostponeClick
          },
          cancel: {
            status: this.context.hasPermission([VISITS_CANCEL]),
            disable: this.isUpdateDisabled,
            disableMsg: t(
              'You can cancel your or your employee visit which is not finished.'
            ),
            onClick: this.onCancelVisitClick
          }
        }
      }
    ];
  }

  onDetailsClick = ({ id }) => {
    this.props.history.push(insertPathParams(ROUTE_VISITS_DETAILS, { id }));
  };

  onUpdateClick = ({ id }) => {
    this.props.history.push(insertPathParams(ROUTE_VISITS_UPDATE, { id }));
  };

  onPostponeClick = ({ id }) => {
    this.props.history.push(insertPathParams(ROUTE_VISITS_POSTPONE, { id }));
  };

  onCancelVisitClick = item => {
    this.setState({ openVisitModal: true, chosenVisit: item });
  };

  onCancelVisitSuccess = () => {
    const { chosenVisit } = this.state;
    this.props.history.push(
      insertPathParams(ROUTE_VISITS_DETAILS, { id: chosenVisit.id })
    );
  };

  onCancelVisitModalClose = () => {
    this.setState({ openVisitModal: false });
  };

  isUpdateDisabled = row => {
    const { hasRole, user } = this.context;

    const { status, user_id } = row;

    if (status === FINISHED) return true;
    if (user_id === user.id) return false;

    if (hasRole([DIRECTOR])) {
      const { departments } = user;
      const { users } = this.props;
      const departmentsIds = departments.map(({ id }) => id);
      const employee = users.find(
        // eslint-disable-next-line no-shadow
        user => user.id === user_id
      );
      if (employee && employee.departments) {
        const employeeDepartmentsIds = employee.departments.map(({ id }) => id);
        const isUsersEmployee = employeeDepartmentsIds.some(
          employeeDepartmentId => departmentsIds.includes(employeeDepartmentId)
        );
        if (isUsersEmployee) return false;
      }
      return true;
    }

    if (hasRole([DEPARTMENT_MANAGER])) {
      const managerId = user.id;
      const userData = this.props.users.find(({ id }) => id === row.user_id);
      const userManagersIds = userData?.departments.map(
        ({ manager_id }) => manager_id
      );

      return !userManagersIds?.includes(managerId);
    }

    if (!hasRole([ADMIN, SUPER_ADMIN, DIRECTOR])) {
      return user_id !== user.id;
    }
    return false;
  };

  prepareVisits = allVisits => {
    return allVisits.map(visit => {
      const time = visit.scheduled_at.substring(11, 16);

      return { ...visit, time };
    });
  };

  isRowDisabled = row => {
    const { status } = row;
    return status === FINISHED;
  };

  render() {
    const { visits, sort, setSort, loading } = this.props;
    const { openVisitModal, chosenVisit } = this.state;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={this.prepareVisits(visits)}
          sort={sort}
          setSort={setSort}
          hover
          isRowDisabled={this.isRowDisabled}
          actions={this.context.hasPermission([VISITS_SHOW])}
          loading={loading}
        />
        {openVisitModal && (
          <CancelVisitModal
            visit={chosenVisit}
            onSuccess={this.onCancelVisitSuccess}
            onClose={this.onCancelVisitModalClose}
          />
        )}
      </>
    );
  }
}

VisitsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  visits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired
};

export default withRouter(VisitsTable);
