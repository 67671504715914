import { Route } from 'react-router-dom';
import {
  POSTAL_CODES_SHOW,
  POSTAL_CODES_CREATE,
  POSTAL_CODES_UPDATE
} from 'api/auth/permissions/PostalCodes';

import PostalCodesView from 'modules/PostalCodes/view/PostalCodesView';
import CreateView from 'modules/PostalCodes/view/CreateView';
import UpdateView from 'modules/PostalCodes/view/UpdateView';

import Authorize from 'modules/Auth/component/Authorize';

export const ROUTE_POSTAL_CODES_LIST = '/postal_codes';
export const ROUTE_POSTAL_CODES_CREATE = '/postal_codes/create';
export const ROUTE_POSTAL_CODES_UPDATE = '/postal_codes/update';

export const permMap = {
  [ROUTE_POSTAL_CODES_LIST]: [POSTAL_CODES_SHOW],
  [ROUTE_POSTAL_CODES_CREATE]: [POSTAL_CODES_CREATE],
  [ROUTE_POSTAL_CODES_UPDATE]: [POSTAL_CODES_UPDATE]
};

const routes = {
  protected: [
    <Route key='postal_codes' exact path={ROUTE_POSTAL_CODES_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_POSTAL_CODES_LIST]}>
        <PostalCodesView />
      </Authorize>
    </Route>,
    <Route key='postal_codes_create' exact path={ROUTE_POSTAL_CODES_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_POSTAL_CODES_CREATE]}>
        <CreateView />
      </Authorize>
    </Route>,
    <Route key='postal_codes_update' exact path={ROUTE_POSTAL_CODES_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_POSTAL_CODES_UPDATE]}>
        <UpdateView />
      </Authorize>
    </Route>
  ]
};

export default routes;
