import { Component } from 'react';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import PropTypes from 'prop-types';
import Loader from 'modules/Layout/component/Loader';
import { isEmpty } from 'lodash/lang';
import capitalize from 'modules/Shared/utils/capitalize';

class ClientsTreePostalClients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataToRender: undefined
    };
  }

  async componentDidMount() {
    const dataToRender = await this.props.getDataToRender(
      'clients',
      this.props.postalCodeId
    );

    this.setState({ dataToRender });
  }

  render() {
    const { dataToRender } = this.state;
    const { selectMode, isParentSelected } = this.props;

    if (dataToRender === undefined) return <Loader />;

    if (isEmpty(dataToRender)) return null;

    return dataToRender.map(client => {
      const isVisible = this.props.isVisible('clients', client.id);
      const isSelected =
        isParentSelected || this.props.isSelected('clients', client.id);

      if (!isVisible && !isSelected && !selectMode) return null;

      const item = {
        postalCodeLevel: 'clients',
        region_id: this.props.regionId,
        subregion_id: this.props.subregionId,
        subsubregion_id: this.props.subsubregionId,
        ciyty_id: this.props.cityId,
        postal_code_id: this.props.postalCodeId,
        ...client
      };

      return (
        <TreeItem
          itemData={item}
          key={`client-${client.id}`}
          nodeId={`client-${client.id}`}
          labelText={capitalize(client.name)}
          selectMode={selectMode}
          isSelected={isSelected}
          onSelect={this.props.onSelect}
          actionBtn={
            !selectMode && isSelected && this.props.renderDeleteItemBtn(item)
          }
        />
      );
    });
  }
}

ClientsTreePostalClients.defaultProps = {
  subregionId: null,
  subsubregionId: null
};

ClientsTreePostalClients.propTypes = {
  regionId: PropTypes.number.isRequired,
  subregionId: PropTypes.number,
  subsubregionId: PropTypes.number,
  cityId: PropTypes.number.isRequired,
  postalCodeId: PropTypes.number.isRequired,
  getDataToRender: PropTypes.func.isRequired,
  isVisible: PropTypes.func.isRequired,
  isParentSelected: PropTypes.bool.isRequired,
  isSelected: PropTypes.func.isRequired,
  selectMode: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  renderDeleteItemBtn: PropTypes.func.isRequired
};

export default ClientsTreePostalClients;
