import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import t from 'translate/translate';
import PieChart from 'modules/Layout/component/Charts/PieChart';
import BarChart from 'modules/Layout/component/Charts/BarChart';
import ResultsTable from 'modules/SalesResults/component/ResultsDetails/Table';
import { useResize } from 'modules/Shared/hook';

const SalesComparisonCharts = props => {
  const {
    data: { wholesalersSales, directSales },
    viewType,
    onDrillDown,
    nameSuffix,
    thirdSection
  } = props;

  const componentRef = useRef();
  const { width } = useResize(componentRef, thirdSection);

  return (
    <Box display='flex' justifyContent='space-around' ref={componentRef}>
      {viewType === 'pie' && (
        <>
          <Box>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Wholesalers sales')}
              </Typography>
            </Box>
            <PieChart
              data={wholesalersSales}
              valuePropertyName='quantity_sum'
              title={t('Number of wholesalers sales')}
              outerRadius={width / 8}
              innerRadius={15}
              onDrillDown={data =>
                onDrillDown(data, 'wholesalersSales', 'directSales')
              }
              nameSuffix={nameSuffix}
              suffix='szt.'
            />
            <PieChart
              data={wholesalersSales}
              valuePropertyName='price_sum'
              title={t('Sum of wholesalers sales')}
              outerRadius={width / 8}
              innerRadius={15}
              onDrillDown={data =>
                onDrillDown(data, 'wholesalersSales', 'directSales')
              }
              nameSuffix={nameSuffix}
              suffix='PLN'
            />
          </Box>
          <Box>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Direct sales')}
              </Typography>
            </Box>
            <PieChart
              data={directSales}
              valuePropertyName='quantity_sum'
              title={t('Number of direct sales')}
              outerRadius={width / 8}
              innerRadius={15}
              onDrillDown={data =>
                onDrillDown(data, 'directSales', 'wholesalersSales')
              }
              nameSuffix={nameSuffix + 1}
              suffix='szt.'
            />

            <PieChart
              data={directSales}
              valuePropertyName='price_sum'
              title={t('Sum of direct sales')}
              outerRadius={width / 8}
              innerRadius={15}
              onDrillDown={data =>
                onDrillDown(data, 'directSales', 'wholesalersSales')
              }
              nameSuffix={nameSuffix + 1}
              suffix='PLN'
            />
          </Box>
        </>
      )}
      {viewType === 'bar' && (
        <>
          <Box>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Wholesalers sales')}
              </Typography>
            </Box>
            <BarChart
              data={wholesalersSales}
              valuePropertyName='quantity_sum'
              title={t('Number of wholesalers sales')}
              onDrillDown={data =>
                onDrillDown(data, 'wholesalersSales', 'directSales')
              }
              nameSuffix={nameSuffix}
              width={260}
              suffix='szt.'
            />
            <BarChart
              data={wholesalersSales}
              valuePropertyName='price_sum'
              title={t('Sum of wholesalers sales')}
              onDrillDown={data =>
                onDrillDown(data, 'wholesalersSales', 'directSales')
              }
              nameSuffix={nameSuffix}
              width={260}
              suffix='PLN'
            />
          </Box>
          <Box>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Direct sales')}
              </Typography>
            </Box>
            <BarChart
              data={directSales}
              valuePropertyName='quantity_sum'
              title={t('Number of direct sales')}
              onDrillDown={data =>
                onDrillDown(data, 'directSales', 'wholesalersSales')
              }
              nameSuffix={nameSuffix + 1}
              width={260}
              suffix='szt.'
            />
            <BarChart
              data={directSales}
              valuePropertyName='price_sum'
              title={t('Sum of direct sales')}
              onDrillDown={data =>
                onDrillDown(data, 'directSales', 'wholesalersSales')
              }
              nameSuffix={nameSuffix + 1}
              width={260}
              suffix='PLN'
            />
          </Box>
        </>
      )}
      {viewType === 'table' && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Wholesalers sales')}
              </Typography>
            </Box>
            <ResultsTable
              data={wholesalersSales}
              onDrillDown={data =>
                onDrillDown(data, 'wholesalersSales', 'directSales')
              }
              nameSuffix={nameSuffix}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Direct sales')}
              </Typography>
            </Box>
            <ResultsTable
              data={directSales}
              onDrillDown={data =>
                onDrillDown(data, 'directSales', 'wholesalersSales')
              }
              nameSuffix={nameSuffix + 1}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

SalesComparisonCharts.propTypes = {
  data: PropTypes.shape({
    wholesalersSales: PropTypes.arrayOf(PropTypes.shape({})),
    directSales: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  viewType: PropTypes.string.isRequired,
  onDrillDown: PropTypes.func.isRequired,
  nameSuffix: PropTypes.number.isRequired,
  thirdSection: PropTypes.bool.isRequired
};

export default SalesComparisonCharts;
