import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import t from 'translate/translate';
import Table from 'modules/Layout/component/List/Table';
import clientBonusStatuses from 'modules/ClientsBonuses/clientBonusStatuses';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_CLIENTS_BONUSES_DETAILS } from 'routing/routes/ClientsBonuses';
import renderPrice from 'modules/Products/utils/renderPrice';

class ClientsContractsBonusesTable extends Component {
  getCols() {
    return [
      {
        property: 'target_name',
        label: 'Contract name',
        sortable: true
      },
      {
        property: 'client_name',
        label: 'Client name',
        sortable: true
      },
      {
        property: 'status',
        label: 'Bonus status',
        sortable: true,
        render: status => t(clientBonusStatuses[status])
      },
      {
        property: 'final_value',
        label: 'Value',
        sortable: false,
        nullable: true,
        render: (final_value, { currency }) =>
          renderPrice(final_value, currency)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: ({ id }) => {
              this.props.history.push(
                insertPathParams(ROUTE_CLIENTS_BONUSES_DETAILS, { id })
              );
            }
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          }
        }
      }
    ];
  }

  render() {
    const { bonuses, sort, setSort, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={bonuses}
        sort={sort}
        setSort={setSort}
        loading={loading}
        actions
      />
    );
  }
}

ClientsContractsBonusesTable.defaultProps = {
  bonuses: []
};

ClientsContractsBonusesTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  bonuses: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(ClientsContractsBonusesTable);
