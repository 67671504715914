import PropTypes from 'prop-types';
import useStyles from 'modules/Layout/component/TreeView/TreeItem/style';
import { Box, Typography } from '@material-ui/core';
import { TreeItem as MuiTreeItem } from '@material-ui/lab';
import Checkbox from 'modules/Layout/component/Checkbox';

const TreeItem = props => {
  const classes = useStyles();
  const {
    itemData,
    nodeId,
    parentNodeId,
    labelText,
    labelInfo,
    onIconClick,
    actionBtn,
    children,
    selectMode,
    isSelected,
    onSelect
  } = props;

  const onLabelClick = e => {
    e.preventDefault();
  };

  return (
    <MuiTreeItem
      nodeId={nodeId}
      onLabelClick={onLabelClick}
      onIconClick={onIconClick}
      className={classes.root}
      label={
        <Box className={classes.labelRoot}>
          <Box display='flex'>
            {selectMode && (
              <Checkbox
                name={`${nodeId}-checkbox`}
                checked={isSelected}
                onChange={() =>
                  itemData
                    ? onSelect(isSelected, itemData)
                    : onSelect(!isSelected, nodeId, parentNodeId)
                }
                style={{ padding: '1px' }}
                useInternalState={false}
              />
            )}
            <Box display='flex' alignItems='center'>
              <Typography variant='body2'>{labelText}</Typography>
              {(Boolean(labelInfo) || labelInfo === 0) && (
                <Box className={classes.customBadge}>{labelInfo}</Box>
              )}
            </Box>
          </Box>
          {actionBtn}
        </Box>
      }
    >
      {children}
    </MuiTreeItem>
  );
};

TreeItem.defaultProps = {
  itemData: null,
  parentNodeId: null,
  labelInfo: null,
  labelText: '',
  actionBtn: <></>,
  onIconClick: () => {},
  children: null,
  selectMode: false,
  isSelected: false,
  onSelect: () => {}
};

TreeItem.propTypes = {
  itemData: PropTypes.shape({}),
  nodeId: PropTypes.string.isRequired,
  parentNodeId: PropTypes.string,
  labelInfo: PropTypes.number,
  labelText: PropTypes.string,
  onIconClick: PropTypes.func,
  actionBtn: PropTypes.node,
  children: PropTypes.node,
  selectMode: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func
};

export default TreeItem;
