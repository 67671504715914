import PropTypes from 'prop-types';
import {
  Box,
  IconButton as MaterialUiIconButton,
  Tooltip
} from '@material-ui/core';
import t from 'translate/translate';

const IconButton = props => {
  const {
    onClick,
    icon,
    disabled,
    className,
    tooltipMsg,
    size,
    alt,
    boxStyles
  } = props;

  const btn = (
    <Tooltip title={t(alt)} placement='top'>
      <Box style={boxStyles}>
        <MaterialUiIconButton
          classes={{
            label: !disabled && className && className
          }}
          disabled={disabled}
          onClick={onClick && onClick}
          size={size}
        >
          {icon}
        </MaterialUiIconButton>
      </Box>
    </Tooltip>
  );

  const btnWithTooltip = (
    <Tooltip title={t(tooltipMsg)} placement='bottom'>
      <Box>{btn}</Box>
    </Tooltip>
  );

  if (disabled && tooltipMsg) return btnWithTooltip;

  return btn;
};

IconButton.defaultProps = {
  onClick: null,
  disabled: false,
  className: null,
  tooltipMsg: null,
  size: 'medium',
  boxStyles: {}
};

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipMsg: PropTypes.string,
  size: PropTypes.string,
  alt: PropTypes.string.isRequired,
  boxStyles: PropTypes.shape({})
};

export default IconButton;
