import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const DeleteDepartmentDialog = props => {
  const { department, onClose, fetchDepartments } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteDepartment = async () => {
    try {
      await DepartmentsApi.deleteDepartment(department.id);
      setAlert({
        value: t('Deleting department successfully finish'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      fetchDepartments();
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      }
      onClose();
    }
  };

  if (!department) return null;

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete department <%=name%>', {
        name: department.name
      })}
      onApprove={deleteDepartment}
      onCancel={onClose}
    />
  );
};

DeleteDepartmentDialog.defaultProps = {
  department: null
};

DeleteDepartmentDialog.propTypes = {
  department: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  onClose: PropTypes.func.isRequired,
  fetchDepartments: PropTypes.func.isRequired
};

export default DeleteDepartmentDialog;
