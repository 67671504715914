import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { quarters, months, periodTypes } from 'modules/Shared/dateConsts';
import { WHOLESALERS_LIST } from 'api/auth/permissions/Wholesalers';
import { comparisonTypesSelect } from 'modules/Statistics/SalesComparison/utils/comparisonTypesUtils';

const setPeriodItemsType = type => {
  switch (type) {
    case 'month':
      return ['Month', months];
    case 'quarter':
      return ['Quarter', quarters];
    default:
      return ['', []];
  }
};

class StatisticsSalesComparisonFilter extends Filter {
  getInputs() {
    const [label, data] = setPeriodItemsType(this.props.filter.period);

    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('type'),
            label: 'Type',
            type: 'select',
            options: formatOptions(comparisonTypesSelect, 'slug', 'label', true)
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('year'),
            label: 'Year',
            type: 'year'
          },
          {
            ...this.getBasicInputValues('period'),
            label: 'Period',
            type: 'select',
            options: formatOptions(periodTypes, 'slug', 'name', true)
          },
          {
            ...this.getBasicInputValues(this.props.filter.period),
            label,
            type: 'select',
            options: formatOptions(data, 'id', 'name', true),
            displayStatus: this.props.filter.period !== 'year'
          }
        ]
      },
      {
        groupLabel: 'Products categories',
        children: [
          {
            ...this.getBasicInputValues('group_id'),
            label: 'Group',
            type: 'autocomplete',
            options: formatOptions(this.props.groups, 'id', 'name', false),
            emptyValueText: 'All groups',
            disabled: Boolean(
              this.props.filter.category_id || this.props.filter.subcategory_id
            ),
            tooltipMsg:
              'Sales data can be filtered by only one of options at a time: group, category or subcategory'
          },
          {
            ...this.getBasicInputValues('category_id'),
            label: 'Category',
            type: 'autocomplete',
            options: formatOptions(this.props.categories, 'id', 'name', false),
            emptyValueText: 'All categories',
            disabled: Boolean(
              this.props.filter.group_id || this.props.filter.subcategory_id
            ),
            tooltipMsg:
              'Sales data can be filtered by only one of options at a time: group, category or subcategory'
          },
          {
            ...this.getBasicInputValues('subcategory_id'),
            label: 'Subcategory',
            type: 'autocomplete',
            options: formatOptions(
              this.props.subcategories,
              'id',
              'name',
              false
            ),
            emptyValueText: 'All subcategories',
            disabled: Boolean(
              this.props.filter.group_id || this.props.filter.category_id
            ),
            tooltipMsg:
              'Sales data can be filtered by only one of options at a time: group, category or subcategory'
          }
        ]
      },
      {
        groupLabel: 'Wholesalers',
        children: [
          {
            ...this.getBasicInputValues('wholesaler_id'),
            label: 'Wholesalers',
            type: 'multiAutocomplete',
            value: this.props.filter.wholesaler_id,
            options: formatOptions(this.props.wholesalers, 'id', 'name', false),
            emptyValueText: 'Wholesalers',
            displayStatus: this.context.hasPermission([WHOLESALERS_LIST])
          }
        ]
      }
    ];
  }
}

export default StatisticsSalesComparisonFilter;
