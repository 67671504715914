import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  renderBodyText,
  renderData,
  HeaderCell
} from 'modules/Statistics/SalesComparison/component/List/Table/Rows/Rows';
import t from 'translate/translate';

const StatisticsSalesComparisonTable = ({
  rows,
  loading,
  bodyText,
  currency
}) => {
  const renderBody = () => {
    if (loading)
      return (
        <TableRow>
          <TableCell align='center' colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );

    if (bodyText) return renderBodyText(bodyText);

    if (rows.length <= 0) {
      return (
        <TableRow>
          <TableCell align='center' colSpan={4}>
            {t('No data')}
          </TableCell>
        </TableRow>
      );
    }

    return renderData(rows, currency);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <HeaderCell text='Group' />
            <HeaderCell text='Price sum' />
            <HeaderCell text='Quantity sum' />
          </TableRow>
        </TableHead>
        <TableBody>{renderBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

StatisticsSalesComparisonTable.defaultProps = {
  rows: [],
  bodyText: null,
  currency: undefined
};

StatisticsSalesComparisonTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  currency: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  bodyText: PropTypes.string
};

export default StatisticsSalesComparisonTable;
