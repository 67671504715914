import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import VisitMarker from 'modules/Visits/components/shared/VisitMarker';
import Typography from '@material-ui/core/Typography';
import t from 'translate/translate';

const VisitsMapRoutes = ({ visits }) => {
  const [firstVisit, ...restVisits] = visits;

  const defaultCenter = {
    lat: parseFloat(firstVisit?.lat) || 52.0,
    lng: parseFloat(firstVisit?.long) || 19.5
  };

  const handleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();

    const wayPoints = restVisits
      .filter((_, index) => index < restVisits.length - 1)
      .map(({ lat, long }) => ({
        location: `${lat}, ${long}`,
        stopover: true
      }));

    const firstVisitCoords = `${firstVisit?.lat}, ${firstVisit?.long}`;
    const lastVisitCoords = `${visits[visits.length - 1]?.lat}, ${
      visits[visits.length - 1]?.long
    }`;

    directionsService.route(
      {
        travelMode: maps.TravelMode.DRIVING,
        origin: firstVisitCoords,
        waypoints: wayPoints,
        destination: lastVisitCoords
      },
      (response, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(response);
          const routePolyline = new maps.Polyline({
            path: response.routes[0].overview_path
          });
          routePolyline.setMap(map);
        }
      }
    );
  };

  return (
    <>
      {!firstVisit && (
        <Typography align='center' variant='h5' gutterBottom>
          {t('No visits')}
        </Typography>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_KEY
        }}
        defaultCenter={defaultCenter}
        defaultZoom={firstVisit ? 8 : 6}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {visits.map((visit, index) => {
          return (
            <VisitMarker
              key={visit.id}
              visit={visit}
              lat={visit.lat}
              lng={visit.long}
              order={index + 1}
            />
          );
        })}
      </GoogleMapReact>
    </>
  );
};

VisitsMapRoutes.propTypes = {
  visits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      lat: PropTypes.string,
      long: PropTypes.string
    })
  ).isRequired
};

export default VisitsMapRoutes;
