import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { SUPER_ADMIN } from 'api/auth/roles';
import { clientBusinessStatusesArr } from 'modules/Clients/utils/clientBusinessStatuses';

class InternetClientsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: 'Organizational data',
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('recipient_number'),
            label: 'Recipient number',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('tax_number'),
            label: 'Tax number',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: 'Address',
        children: [
          {
            ...this.getBasicInputValues('address'),
            label: 'Address',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'select',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasRole([SUPER_ADMIN])
          }
        ]
      },
      {
        groupLabel: 'Client activity',
        children: [
          {
            ...this.getBasicInputValues('status'),
            label: 'Client business status',
            type: 'select',
            emptyValueText: 'All',
            options: formatOptions(
              clientBusinessStatusesArr,
              'value',
              'status',
              true
            )
          }
        ]
      }
    ];
  }
}

export default InternetClientsFilter;
