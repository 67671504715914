/* eslint no-return-assign: ["off"] */
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import t from 'translate/translate';

const capitalize = s => {
  return s.charAt(0).toUpperCase() + s.slice(1).replace(/_/g, ' ');
};

const Breadcrumb = () => {
  const location = useLocation();
  const pathNames = location.pathname
    .replace(/[0-9]/g, '')
    .split('/')
    .filter(x => x.length > 0);

  const pathItemIdMap = {};

  const pathItem = location.pathname.split('/').filter(x => x.length > 0);

  pathItem.forEach((item, index) => {
    const id = parseInt(item, 10);
    if (id) pathItemIdMap[pathItem[index - 1]] = id;
  });

  const renderLink = index => {
    let link = `/${pathNames.slice(0, index + 1).join('/')}`;

    const pathItemIdMapKeys = Object.keys(pathItemIdMap);
    pathItemIdMapKeys.forEach(
      pathName =>
        (link = link.replace(
          `${pathName}`,
          `${pathName}/${pathItemIdMap[pathName]}`
        ))
    );

    return link;
  };

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      <Link color='inherit' to='/' component={RouterLink}>
        {t('Dashboard')}
      </Link>
      {pathNames.map((value, index) => {
        const last = index === pathNames.length - 1;
        const to = renderLink(index);

        return last ? (
          <Typography key={to}>{t(capitalize(value))}</Typography>
        ) : (
          <Link color='inherit' to={to} key={to} component={RouterLink}>
            {t(capitalize(value))}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
