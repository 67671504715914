import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import ProductsApi from 'api/connections/Products/ProductsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const DeleteProductDialog = props => {
  const {
    product: { id, sku },
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteProduct = async () => {
    try {
      await ProductsApi.deleteProduct(id);
      setAlert({
        value: t('Deleting product successfully finish'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      }
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete product <%=sku%>', {
        sku
      })}
      onApprove={deleteProduct}
      onCancel={onClose}
    />
  );
};

DeleteProductDialog.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default DeleteProductDialog;
