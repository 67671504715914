import { useReducer } from 'react';
import PropTypes from 'prop-types';

import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';
import currentPageReducer, {
  SET_CURRENT_PAGE
} from 'modules/Shared/context/CurrentPage/currentPageReducer';

const CurrentPageState = props => {
  const initialState = {
    currentPage: null
  };

  const [state, dispatch] = useReducer(currentPageReducer, initialState);

  const setCurrentPage = pageName => {
    dispatch({
      type: SET_CURRENT_PAGE,
      payload: pageName
    });
  };

  const { children } = props;

  return (
    <CurrentPageContext.Provider
      value={{
        currentPage: state.currentPage,
        setCurrentPage
      }}
    >
      {children}
    </CurrentPageContext.Provider>
  );
};

CurrentPageState.propTypes = {
  children: PropTypes.node.isRequired
};

export default CurrentPageState;
