export default {
  Targets: 'Cele',
  'Targets and bonuses': 'Cele i premie',
  'Sell targets': 'Cele Sprzedażowe',
  'Workers bonuses': 'Premie Pracowników',
  Target: 'Cel',
  'Target details': 'Szczegóły celu',
  'Update target': 'Edycja celu',
  valuable: 'wartościowy',
  quantitative: 'ilościowy',
  'Period from': 'Okres od',
  'Period to': 'Okres do',
  'Create target': 'Dodawanie celu',
  'Result value': 'Wartość wyniku w momencie zatwierdzneia',
  'Result percent': 'Procent wyniku w momencie zatwierdzenia',
  'Current value': 'Aktualna wartość',
  'Current percent': 'Aktualny procent',
  'Current estimation value': 'Szacowana wartość',
  'Current estimation percent': 'Szacowany procent',
  'Update date': 'Data aktualizacji',
  'Are you sure you want to delete target <%=targetName%>':
    'Czy na pewno chcesz usunać cel <%=targetName%>',
  'Confirm target': 'Zatwierdzenie celu',
  'Are you sure you want to confirm target <%=targetName%>':
    'Czy na pewno chcesz zatwierdzić cel <%=targetName%>',
  'Target for managers only': 'Cel wyłącznie dla kierownictwa',
  true: 'tak',
  'quantitative for managers': 'ilościowy kierowniczy',
  'valuable for managers': 'wartościowy kierowniczy',
  'Target definition': 'Definicja celu',
  'Products included in target': 'Produkty uwzględniane w celu',
  'The target includes all products': 'Cel uwzględnia wszystkie produkty',
  'Limitation on clients': 'Ograniczenie co do klientów',
  'Limitation on regions': 'Ograniczenie co do regionów',
  'No restrictions': 'Brak ograniczeń',
  'As part of the target, products were sold for value':
    'W ramach celu sprzedano produktów za wartość',
  'Quantities of products were sold within the target':
    'W ramach celu sprzedano produktów w ilosci',
  'The goal was met on the level': 'Cel został spełniony na poziomie',
  'The system has not yet set an estimated target achievement level':
    'System nie wyznaczył jeszcze szacowanego poziomu realizacji celu',
  'Date of estimating the level of target achievement':
    'Data oszacowania poziomu realizacji celu',
  'Current target achievement level': 'Aktualny poziom realizacji celu',
  'Estimated target achievement level': 'Szacowany poziom realizacji celu',
  'Current number of products sold': 'Aktualna ilość sprzedanych produktów',
  'Current value of products sold': 'Aktualna wartość sprzedanych produktów',
  'Estimated number of products sold': 'Szacowana ilość sprzedanych produktów',
  'Estimated value of products sold': 'Szacowana wartość sprzedanych produktów',
  'Select department and user first': 'Wybierz najpierw dział oraz użytkownika',
  'Target name': 'Nazwa celu',
  'Target status': 'Status celu',
  'Copy target': 'Kopiuj cel',
  'All bonus values are required': 'Wszystkie wartości premii są wymagane',
  'My targets': 'Moje cele',
  'Target is closed': 'Cel jest zamknięty'
};
