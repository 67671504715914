import axios from 'axios';
import { isEmpty } from 'lodash/lang';

class BaseApiConfig {
  constructor() {
    this.basicPath = process.env.REACT_APP_API_URL;
  }

  get(path, pathParams = {}, options) {
    const paramsPath = BaseApiConfig.formatPathParams(pathParams);
    return axios.get(`${this.basicPath}${path}${paramsPath}`, options);
  }

  post(path, payload, options) {
    return axios.post(`${this.basicPath}${path}`, payload, options);
  }

  put(path, payload, id = '') {
    return axios.put(`${this.basicPath}${path}${id}`, payload);
  }

  delete(path, options) {
    return axios.delete(`${this.basicPath}${path}`, options);
  }

  static formatPathParams(pathParams) {
    const paramsSets = [];
    let path = '';
    paramsSets.push(...Object.entries(pathParams));

    if (!isEmpty(paramsSets)) {
      path = '?';
      paramsSets.forEach(([key, value]) => {
        if (value !== '' && value !== null && value !== undefined) {
          path += `${key}=${value}&`;
        }
      });
      path = path.slice(0, -1);
    }

    return path;
  }
}

export default BaseApiConfig;
