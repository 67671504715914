import DashboardIcon from '@material-ui/icons/Dashboard';
import { ROUTE_DASHBOARD } from 'routing/routes/Dashboard';

const menu = [
  {
    key: 'menu-dashboard',
    label: 'Dashboard',
    icon: <DashboardIcon />,
    path: ROUTE_DASHBOARD,
    permissions: []
  }
];

export default menu;
