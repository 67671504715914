import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { getTargetOptionsAvailableForRole } from 'modules/Targets/targetTypes';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN,
  TELEMARKETER
} from 'api/auth/roles';

class TargetsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('user_id'),
            label: 'My targets',
            type: 'checkbox',
            displayStatus: this.context.hasRole([DIRECTOR, DEPARTMENT_MANAGER])
          }
        ]
      },
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('type'),
            label: 'Type',
            type: 'select',
            emptyValueText: 'All',
            options: formatOptions(
              getTargetOptionsAvailableForRole(this.context.user.role, true),
              'id',
              'name',
              true
            )
          },
          {
            ...this.getBasicInputValues('department_id'),
            label: 'Department',
            type: 'autocomplete',
            displayStatus: this.context.hasRole([
              SUPER_ADMIN,
              ADMIN,
              DIRECTOR,
              TELEMARKETER
            ]),
            options: formatOptions(this.props.departmentsData, 'id', 'name')
          },
          {
            ...this.getBasicInputValues('user_name'),
            label: 'User name',
            type: 'text',
            displayStatus: this.context.hasRole([
              SUPER_ADMIN,
              ADMIN,
              DIRECTOR,
              DEPARTMENT_MANAGER
            ])
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('period'),
            label: 'Period',
            type: 'periodPicker'
          }
        ]
      }
    ];
  }
}

export default TargetsFilter;
