import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Checkbox from 'modules/Layout/component/Checkbox';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import Table from 'modules/Layout/component/List/Table';
import { DialogContentText, Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash/lang';

class DefinePromotionDialog extends Component {
  static contextType = AlertContext;

  static getProductsTableCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false
      }
    ];
  }

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      percentage: '',
      validation: {
        percentage: {
          status: false,
          message: t(
            'Field Promotion value is required and should be between 0-99'
          )
        },
        selected: {
          status: false,
          message: t('At least one product must be selected')
        }
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onChange(e) {
    this.setState(state => {
      const { validation } = state;
      const { value } = e.target;

      return {
        ...state,
        percentage: value,
        validation: {
          ...validation,
          percentage: {
            ...validation.percentage,
            status: false
          }
        }
      };
    });
  }

  onAccept() {
    const { selected, percentage } = this.state;

    if (!percentage || isEmpty(selected)) {
      return this.setState(state => {
        return {
          ...state,
          validation: {
            ...state.validation,
            percentage: {
              ...state.validation.percentage,
              status: Boolean(percentage) === false
            },
            selected: {
              ...state.validation.selected,
              status: isEmpty(selected)
            }
          }
        };
      });
    }

    const payload = {
      percent: parseInt(percentage, 10),
      products: selected
    };

    this.props.onPromotionDefineSuccess(payload);
    this.props.onClose();
  }

  onSelect(orderProductId) {
    this.setState(state => {
      const { selected: prevSelected, validation } = state;

      const selected = prevSelected.includes(orderProductId)
        ? prevSelected.filter(selectedId => selectedId !== orderProductId)
        : [...prevSelected, orderProductId];

      return {
        selected,
        validation: {
          ...validation,
          selected: {
            ...validation.selected,
            status: false
          }
        }
      };
    });
  }

  render() {
    const { selected, percentage, validation } = this.state;
    const { onClose, products } = this.props;

    return (
      <Dialog
        open
        onClose={onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle id='form-dialog-title'>
          {t('Define percentage promotion')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              'Select the products covered by the promotion and then enter the value of the promotion.'
            )}
          </DialogContentText>
          <Box mt={2}>
            <Checkbox
              name='define-promotion-dialog-select-all'
              useInternalState={false}
              checked={selected.length === products.length}
              label={t('Select all list items')}
              onChange={() =>
                this.setState({
                  selected:
                    selected.length === products.length
                      ? []
                      : products.map(p => p.id)
                })
              }
            />
            <Table
              cols={DefinePromotionDialog.getProductsTableCols()}
              rows={products}
              dense
              selected={selected}
              onSelect={this.onSelect}
              isRowDisabled={({ promotions_blocked }) => promotions_blocked}
              rowDisabledTooltip='The product is unavailable in promotions'
            />
            {validation.selected.status && (
              <Box ml={2}>
                <Typography variant='caption' color='error'>
                  {validation.selected.message}
                </Typography>
              </Box>
            )}
            <Input
              name='percentage'
              label='Percentage promotion value'
              type='number'
              inputProps={{
                min: 0,
                max: 99
              }}
              value={percentage}
              required
              onChange={this.onChange}
              errorStatus={validation.percentage.status}
              errorText={validation.percentage.message}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button
            type='button'
            color='primary'
            text={t('Save')}
            onClick={this.onAccept}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DefinePromotionDialog.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  onPromotionDefineSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DefinePromotionDialog;
