import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import t from 'translate/translate';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import Authorize from 'modules/Auth/component/Authorize';
import { PRICE_LISTS_MANAGEMENT } from 'api/auth/permissions/PriceLists';
import { ROUTE_PRICE_LISTS_LIST } from 'routing/routes/PriceLists';
import PriceListFormModal from 'modules/PriceLists/component/Modals/Form';
import PriceListDeleteModal from 'modules/PriceLists/component/Modals/Delete';
import PriceListsDetailsDepartmentsList from 'modules/PriceLists/component/Details/DepartmentsList';
import PriceListsDetailsProductsList from 'modules/PriceLists/component/Details/ProductsList';

class PriceListBasicData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionModals: {
        formModalStatus: false,
        deleteModalStatus: false
      }
    };

    this.onChangeNameClick = this.onChangeNameClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  onChangeNameClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true
      }
    }));
  }

  onDeleteClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        deleteModalStatus: true
      }
    }));
  }

  closeAllModals() {
    this.setState({
      actionModals: {
        updateModalStatus: false,
        deleteModalStatus: false
      }
    });
  }

  redirect() {
    return this.props.history.push(ROUTE_PRICE_LISTS_LIST);
  }

  render() {
    const { formModalStatus, deleteModalStatus } = this.state.actionModals;
    const { name, products, departments, country_id } = this.props.priceList;
    const { currency } = this.props;

    return (
      <>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Box display='flex' alignItems='center'>
                  <Box mr={1}>
                    <Typography component='h6' variant='h6'>
                      {name}
                    </Typography>
                  </Box>
                  <Authorize permissions={[PRICE_LISTS_MANAGEMENT]}>
                    <IconButton
                      onClick={this.onChangeNameClick}
                      className='update-icon'
                      icon={<EditIcon />}
                      alt='change name'
                    />
                  </Authorize>
                </Box>
                <Box>
                  <Authorize permissions={[PRICE_LISTS_MANAGEMENT]}>
                    <IconButton
                      onClick={this.onDeleteClick}
                      className='delete-icon'
                      icon={<DeleteForeverIcon />}
                      alt='delete'
                    />
                  </Authorize>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper className='p1'>
                  <Typography
                    component='h6'
                    variant='h6'
                    style={{ marginBottom: '1rem' }}
                  >
                    {t('Products')}
                  </Typography>
                  <PriceListsDetailsProductsList
                    products={products}
                    currency={currency}
                    handleError={this.props.handleError}
                    onProductAddSuccess={this.props.onProductAddSuccess}
                    onProductDeleteSuccess={this.props.onProductDeleteSuccess}
                    onProductChangePriceSuccess={
                      this.props.onProductChangePriceSuccess
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className='p1'>
                  <Typography
                    component='h6'
                    variant='h6'
                    style={{ marginBottom: '1rem' }}
                  >
                    {t('Departments assign to price list')}
                  </Typography>
                  <PriceListsDetailsDepartmentsList
                    departments={departments}
                    countryId={country_id}
                    onDepartmentAddSuccess={this.props.onDepartmentAddSuccess}
                    onDepartmentDeleteSuccess={
                      this.props.onDepartmentDeleteSuccess
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {formModalStatus && (
          <PriceListFormModal
            priceList={this.props.priceList}
            setAlert={this.props.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.props.fetchPriceList}
          />
        )}
        {deleteModalStatus && (
          <PriceListDeleteModal
            priceList={this.props.priceList}
            handleError={this.props.handleError}
            setAlert={this.props.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.redirect}
          />
        )}
      </>
    );
  }
}

PriceListBasicData.propTypes = {
  priceList: PropTypes.shape({
    name: PropTypes.string.isRequired,
    clients: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    departments: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    country_id: PropTypes.number
  }).isRequired,
  currency: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  fetchPriceList: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  onProductAddSuccess: PropTypes.func.isRequired,
  onProductDeleteSuccess: PropTypes.func.isRequired,
  onProductChangePriceSuccess: PropTypes.func.isRequired,
  onDepartmentAddSuccess: PropTypes.func.isRequired,
  onDepartmentDeleteSuccess: PropTypes.func.isRequired
};

export default withRouter(PriceListBasicData);
