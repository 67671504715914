import { useReducer } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import AlertContext from 'modules/Shared/context/Alert/alertContext';
import alertReducer, {
  SET_ALERT,
  REMOVE_ALERT
} from 'modules/Shared/context/Alert/alertReducer';

const AlertState = props => {
  const initialState = {
    alerts: []
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  const setAlert = ({ value, variant }, timeout = 5000) => {
    const id = uuidv4();
    dispatch({
      type: SET_ALERT,
      payload: { value, variant, id }
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };

  const { children } = props;

  return (
    <AlertContext.Provider
      value={{
        alerts: state.alerts,
        setAlert
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

AlertState.propTypes = {
  children: PropTypes.node.isRequired
};

export default AlertState;
