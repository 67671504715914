import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { Box } from '@material-ui/core';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import TargetsBonusesApi from 'api/connections/TargetsBonuses/TargetsBonusesApi';
import TargetsBonusesTable from 'modules/TargetsBonuses/component/List/Table';
import TargetsBonusesFilter from 'modules/TargetsBonuses/component/List/Filter';

class TargetsBonusesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      filter: {
        period: {
          period_type: '',
          period_year: null,
          period_quarter: '',
          period_month: ''
        }
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      targetsBonuses: [],
      loading: true
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchTargetsBonuses = this.fetchTargetsBonuses.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Targets bonuses');
    this.fetchTargetsBonuses();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchTargetsBonuses()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchTargetsBonuses()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchTargetsBonuses()
    );
  }

  async fetchTargetsBonuses() {
    const {
      sort,
      filter: {
        period: { period_type, period_year, period_quarter, period_month }
      },
      pagination: { per_page, page }
    } = this.state;

    const pathParams = {
      ...sort,
      per_page,
      page,
      ...this.state.filter,
      period_type,
      period_year
    };

    if (sort && sort.sort_field === 'period_to') {
      pathParams.sort_field = 'date';
    }

    if (period_year) {
      if (period_quarter) pathParams.period_quarter = period_quarter;
      if (period_month) pathParams.period_month = period_month;
    }

    try {
      const {
        data: { data: targetsBonuses, meta }
      } = await TargetsBonusesApi.getTargetsBonuses(pathParams);

      this.setState({
        targetsBonuses,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row-reverse'>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const {
      filter,
      sort,
      targetsBonuses,
      loading,
      filterValidation
    } = this.state;

    return (
      <div>
        <TargetsBonusesFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderActions()}
        <TargetsBonusesTable
          sort={sort}
          setSort={this.setSort}
          targetsBonuses={targetsBonuses}
          loading={loading}
        />
        {this.renderActions()}
      </div>
    );
  }
}

TargetsBonusesList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(TargetsBonusesList);
