import PropTypes from 'prop-types';
import {
  Dialog as MaterialUiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box
} from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const CustomDialog = props => {
  const {
    open,
    title,
    description,
    actions,
    onClose,
    children,
    maxWidth
  } = props;

  return (
    <div>
      <MaterialUiDialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box>
              <Typography variant='h6'>{title}</Typography>
              {description && (
                <Typography variant='body2' color='textSecondary'>
                  {description}
                </Typography>
              )}
            </Box>
            <IconButton
              onClick={onClose}
              icon={<CloseIcon />}
              alt='close window'
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </MaterialUiDialog>
    </div>
  );
};

CustomDialog.defaultProps = {
  description: null,
  children: <></>,
  actions: <></>,
  maxWidth: 'md'
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string
};

export default CustomDialog;
