import { Route } from 'react-router-dom';
import {
  WHOLESALERS_LIST,
  WHOLESALERS_CREATE,
  WHOLESALERS_UPDATE
} from 'api/auth/permissions/Wholesalers';
import Authorize from 'modules/Auth/component/Authorize';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import WholesalersList from 'modules/Wholesalers/view/List';
import WholesalersDetails from 'modules/Wholesalers/view/Details/DetailsWrapper';
import WholesalersCreate from 'modules/Wholesalers/view/Create';
import WholesalersUpdate from 'modules/Wholesalers/view/Update';
import WholesalersContactCreate from 'modules/Wholesalers/view/Contact/Create';
import WholesalersContactUpdate from 'modules/Wholesalers/view/Contact/Update';
import WholesalerProductsMappingCreate from 'modules/Wholesalers/view/Details/ProductsMapping/Create';
import WholesalerProductsMappingUpdate from 'modules/Wholesalers/view/Details/ProductsMapping/Update';
import WholesalerClientsMappingCreate from 'modules/Wholesalers/view/Details/ClientsMapping/Create';
import WholesalerClientsMappingUpdate from 'modules/Wholesalers/view/Details/ClientsMapping/Update';
import WholesalerFileCreate from 'modules/Wholesalers/view/File/Create';
import WholesalerFileUpdate from 'modules/Wholesalers/view/File/Update';
import WholesalerAgencyCreate from 'modules/Wholesalers/view/Details/Agencies/Create';
import WholesalerAgencyUpdate from 'modules/Wholesalers/view/Details/Agencies/Update';

export const ROUTE_WHOLESALERS_LIST = '/wholesalers';
export const ROUTE_WHOLESALERS_DETAILS = '/wholesalers/details/:id';
export const ROUTE_WHOLESALERS_CREATE = '/wholesalers/create';
export const ROUTE_WHOLESALERS_UPDATE = '/wholesalers/update/:id';
export const ROUTE_WHOLESALERS_CONTACT_CREATE =
  '/wholesalers/details/:id/create_contact';
export const ROUTE_WHOLESALERS_CONTACT_UPDATE =
  '/wholesalers/details/:wholesalerId/update_contact/:contactId';
export const ROUTE_WHOLESALER_MAPPING_CREATE =
  '/wholesalers/details/:id/create_mapping';
export const ROUTE_WHOLESALER_MAPPING_UPDATE =
  '/wholesalers/details/:id/update_mapping/:mapping_id';
export const ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE =
  '/wholesalers/details/:id/create_client_mapping';
export const ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE =
  '/wholesalers/details/:id/update_client_mapping/:mapping_id';
export const ROUTE_WHOLESALER_FILE_UPDATE =
  '/wholesalers/:wholesalerId/update_file/:fileId';
export const ROUTE_WHOLESALER_FILE_CREATE = '/wholesalers/:id/create_file';
export const ROUTE_WHOLESALER_AGENCY_CREATE =
  '/wholesalers/details/:id/create_agency';
export const ROUTE_WHOLESALER_AGENCY_UPDATE =
  '/wholesalers/details/:id/update_agency/:agency_id';

export const permMap = {
  [ROUTE_WHOLESALERS_LIST]: [WHOLESALERS_LIST],
  [ROUTE_WHOLESALERS_DETAILS]: [WHOLESALERS_LIST],
  [ROUTE_WHOLESALERS_CREATE]: [WHOLESALERS_CREATE],
  [ROUTE_WHOLESALERS_UPDATE]: [WHOLESALERS_UPDATE],
  [ROUTE_WHOLESALERS_CONTACT_CREATE]: [WHOLESALERS_LIST],
  [ROUTE_WHOLESALERS_CONTACT_UPDATE]: [WHOLESALERS_LIST],
  [ROUTE_WHOLESALER_MAPPING_CREATE]: [WHOLESALERS_UPDATE],
  [ROUTE_WHOLESALER_MAPPING_UPDATE]: [WHOLESALERS_UPDATE],
  [ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE]: [WHOLESALERS_UPDATE],
  [ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE]: [WHOLESALERS_UPDATE],
  [ROUTE_WHOLESALER_FILE_CREATE]: [WHOLESALERS_LIST],
  [ROUTE_WHOLESALER_FILE_UPDATE]: [WHOLESALERS_LIST],
  [ROUTE_WHOLESALER_AGENCY_CREATE]: [WHOLESALERS_UPDATE],
  [ROUTE_WHOLESALER_AGENCY_UPDATE]: [WHOLESALERS_UPDATE]
};

const routes = {
  protected: [
    <Route key='wholesalers' exact path={ROUTE_WHOLESALERS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_WHOLESALERS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <WholesalersList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='wholesalers-details' exact path={ROUTE_WHOLESALERS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_WHOLESALERS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <WholesalersDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='wholesalers-create' exact path={ROUTE_WHOLESALERS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_WHOLESALERS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <WholesalersCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='wholesalers-update' exact path={ROUTE_WHOLESALERS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_WHOLESALERS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <WholesalersUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesalers-contact-create'
      exact
      path={ROUTE_WHOLESALERS_CONTACT_CREATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_WHOLESALERS_CONTACT_CREATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => <WholesalersContactCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesalers-contact-update'
      exact
      path={ROUTE_WHOLESALERS_CONTACT_UPDATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_WHOLESALERS_CONTACT_UPDATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => <WholesalersContactUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesaler-mapping-create'
      exact
      path={ROUTE_WHOLESALER_MAPPING_CREATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_WHOLESALER_MAPPING_CREATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <WholesalerProductsMappingCreate contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesaler-mapping-update'
      exact
      path={ROUTE_WHOLESALER_MAPPING_UPDATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_WHOLESALER_MAPPING_UPDATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <WholesalerProductsMappingUpdate contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesaler-mapping-create'
      exact
      path={ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <WholesalerClientsMappingCreate contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesaler-mapping-update'
      exact
      path={ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <WholesalerClientsMappingUpdate contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesaler-file-create'
      exact
      path={ROUTE_WHOLESALER_FILE_CREATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_WHOLESALER_FILE_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <WholesalerFileCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesaler-file-update'
      exact
      path={ROUTE_WHOLESALER_FILE_UPDATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_WHOLESALER_FILE_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <WholesalerFileUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesaler-agency-create'
      exact
      path={ROUTE_WHOLESALER_AGENCY_CREATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_WHOLESALER_AGENCY_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <WholesalerAgencyCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='wholesaler-agency-update'
      exact
      path={ROUTE_WHOLESALER_AGENCY_UPDATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_WHOLESALER_AGENCY_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <WholesalerAgencyUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
