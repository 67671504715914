import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import { Box, Paper } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import TaxesApi from 'api/connections/Taxes/TaxesApi';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import ProductCreateForm from 'modules/Products/component/Create/Form';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';

class ProductCreate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      categories: [],
      taxes: []
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  async componentDidMount() {
    this.setCurrentPage('Adding an product');
    await this.fetchData();
  }

  async fetchData() {
    try {
      let countries = [];
      const [
        { data: categories },
        {
          data: { data: taxes }
        }
      ] = await Promise.all([
        ProductCategoriesApi.getProductCategories(),
        TaxesApi.getTaxes()
      ]);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        countries,
        taxes,
        categories
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { countries, categories, taxes } = this.state;
    if (
      this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) &&
      isEmpty(countries)
    )
      return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ProductCreateForm
              countries={countries}
              categories={categories}
              taxes={taxes}
              setAlert={this.setAlert}
              authContext={this.context}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ProductCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default ProductCreate;
