import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ORDER_PAYMENT_METHODS_MANAGEMENT } from 'api/auth/permissions/PaymentMethods';
import PaymentMethodsApi from 'api/connections/PaymentMethods/PaymentMethodsApi';
import PaymentMethodsTable from 'modules/PaymentMethods/component/List/Table';
import PaymentMethodFormModal from 'modules/PaymentMethods/component/Modals/Form';
import PaymentMethodDeleteModal from 'modules/PaymentMethods/component/Modals/Delete';

class PaymentMethodsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      paymentMethods: [],
      loading: true,
      actionModals: {
        modifyPaymentMethod: null,
        formModalStatus: false,
        deleteModalStatus: false
      }
    };

    this.setPagination = this.setPagination.bind(this);
    this.fetchPaymentMethods = this.fetchPaymentMethods.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Payment methods');
    this.fetchPaymentMethods();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  }

  onCreateClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true
      }
    }));
  }

  onUpdateClick(data) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        modifyPaymentMethod: data,
        formModalStatus: true
      }
    }));
  }

  onDeleteClick(modifyPaymentMethod) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        deleteModalStatus: true,
        modifyPaymentMethod
      }
    }));
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchPaymentMethods()
    );
  }

  async fetchPaymentMethods() {
    const { per_page, page } = this.state.pagination;

    try {
      const {
        data: { data: paymentMethods, meta }
      } = await PaymentMethodsApi.getPaymentMethods({
        per_page,
        page
      });

      this.setState({
        paymentMethods,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  closeAllModals() {
    this.setState({
      actionModals: {
        modifyPaymentMethod: null,
        updateModalStatus: false,
        deleteModalStatus: false
      }
    });
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Authorize permissions={[ORDER_PAYMENT_METHODS_MANAGEMENT]}>
            <IconButton
              className='create-icon'
              onClick={this.onCreateClick}
              icon={<AddIcon fontSize='large' />}
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const {
      paymentMethods,
      loading,
      actionModals: { modifyPaymentMethod, formModalStatus, deleteModalStatus }
    } = this.state;

    return (
      <>
        {this.renderActions()}
        <PaymentMethodsTable
          paymentMethods={paymentMethods}
          loading={loading}
          onUpdateClick={this.onUpdateClick}
          onDeleteClick={this.onDeleteClick}
        />
        {this.renderActions()}
        {formModalStatus && (
          <PaymentMethodFormModal
            paymentMethod={modifyPaymentMethod}
            setAlert={this.props.contextMethods.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchPaymentMethods}
          />
        )}
        {deleteModalStatus && (
          <PaymentMethodDeleteModal
            paymentMethod={modifyPaymentMethod}
            handleError={this.handleError}
            setAlert={this.props.contextMethods.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchPaymentMethods}
          />
        )}
      </>
    );
  }
}

PaymentMethodsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default PaymentMethodsList;
