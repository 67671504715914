import { differenceInWeeks, parse } from 'date-fns';
import { green, orange, red } from '@material-ui/core/colors';

const API_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const differenceInWeeksFn = latestVisitDate => {
  const parsedLatestVisitDate = parse(
    latestVisitDate,
    API_DATE_TIME_FORMAT,
    new Date()
  );

  return differenceInWeeks(new Date(), parsedLatestVisitDate);
};

export const setColor = latestVisitDate => {
  const weeksDifference = differenceInWeeksFn(latestVisitDate);

  if (weeksDifference >= 12) {
    return red[500];
  }

  if (weeksDifference >= 6) {
    return orange[500];
  }

  if (weeksDifference >= 4) {
    return green[500];
  }
};
