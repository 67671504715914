import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import WholesalerFileUpdateForm from 'modules/Wholesalers/component/Details/Files/Update/Form';
import { withRouter } from 'react-router-dom';
import WholesalersFilesApi from 'api/connections/Wholesalers/WholesalersFilesApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_WHOLESALERS_DETAILS,
  ROUTE_WHOLESALERS_LIST
} from 'routing/routes/Wholesalers';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class WholesalerFileUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.wholesalerId = parseInt(this.props.match.params.wholesalerId, 10);
    this.fileId = parseInt(this.props.match.params.fileId, 10);

    this.state = {
      wholesalerFile: null
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  componentDidMount() {
    if (!this.wholesalerId || !this.fileId) {
      this.props.history.push(ROUTE_WHOLESALERS_LIST);
    }
    this.setCurrentPage('Update wholesaler file');
    this.fetchData();
  }

  redirectToWholesalerFiles(wholesalerFile = {}) {
    if (
      !wholesalerFile ||
      (wholesalerFile && wholesalerFile.creator_id !== this.context.user.id)
    ) {
      let path = insertPathParams(ROUTE_WHOLESALERS_DETAILS, {
        id: this.wholesalerId
      });
      path += '?tab_index=wholesaler_files';
      this.props.history.push(path);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: files }
      } = await WholesalersFilesApi.getWholesalerFiles(this.wholesalerId);

      const wholesalerFile = files.find(file => file.id === this.fileId);

      this.redirectToWholesalerFiles(wholesalerFile);

      this.setState({ wholesalerFile });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
        if (err instanceof NotFoundApiError) {
          this.redirectToWholesalerFiles();
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { wholesalerFile } = this.state;
    if (!wholesalerFile) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <WholesalerFileUpdateForm
              wholesalerId={this.wholesalerId}
              fileId={this.fileId}
              wholesalerFile={wholesalerFile}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalerFileUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      wholesalerId: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerFileUpdate);
