import PropTypes from 'prop-types';
import { Box, TextField, Tooltip } from '@material-ui/core';
import t from 'translate/translate';

const Input = props => {
  const {
    name,
    label,
    type,
    required,
    inputProps,
    autoFocus,
    value,
    onChange,
    errorStatus,
    errorText,
    fullWidth,
    multiline,
    disabled,
    tooltipMsg,
    margin
  } = props;

  const input = (
    <TextField
      variant='outlined'
      margin={margin}
      required={required}
      inputProps={inputProps}
      fullWidth={fullWidth}
      id={name}
      label={t(label)}
      name={name}
      type={type}
      autoFocus={autoFocus}
      value={value}
      onChange={onChange}
      error={errorStatus}
      helperText={errorStatus && errorText}
      multiline={multiline}
      disabled={disabled}
    />
  );

  const inputWithTooltip = (
    <Tooltip title={t(tooltipMsg)}>
      <Box>{input}</Box>
    </Tooltip>
  );

  if (disabled && tooltipMsg) return inputWithTooltip;

  return input;
};

Input.defaultProps = {
  type: 'text',
  label: null,
  required: false,
  inputProps: {},
  autoFocus: false,
  value: '',
  errorStatus: false,
  errorText: '',
  fullWidth: true,
  multiline: false,
  disabled: false,
  tooltipMsg: '',
  margin: 'normal'
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  autoFocus: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  errorStatus: PropTypes.bool,
  errorText: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  margin: PropTypes.string
};

export default Input;
