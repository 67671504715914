import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FilterRenderer from 'modules/Layout/component/List/FilterRenderer';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class Filter extends Component {
  static contextType = AuthContext;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.validation !== nextProps.validation) {
      return {
        validation: nextProps.validation
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    const { filter, validation } = props;
    this.state = {
      filter,
      validation
    };

    this.setFilter = this.setFilter.bind(this);

    this.setFilterDebounce = _.debounce(
      () => props.setFilter(this.state.filter),
      500
    );
  }

  setFilter(property, value) {
    let isValid = true;

    this.setState(
      prevState => {
        const { filter: prevStateFilter, validation } = prevState;

        const newState = {
          filter: {
            ...prevStateFilter,
            [property]: value
          },
          validation: {
            ...validation
          }
        };

        if (newState.validation[property])
          newState.validation[property].status = false;

        /* eslint no-restricted-syntax: off */
        for (const propertyName in newState.validation) {
          if (newState.validation[propertyName].status) {
            isValid = false;
            break;
          }
        }

        return newState;
      },
      () => isValid && this.setFilterDebounce()
    );
  }

  getBasicInputValues(property) {
    const {
      filter: { [property]: value },
      validation
    } = this.state;

    return {
      property,
      value,
      errorStatus: validation[property]?.status,
      errorText: validation[property]?.message
    };
  }

  render() {
    return (
      <FilterRenderer
        inputs={this.getInputs()}
        setFilter={this.setFilter}
        onMultiselectFilterChange={this.props.onMultiselectFilterChange}
        defaultExpanded={this.props.defaultExpanded}
      />
    );
  }
}

Filter.defaultProps = {
  onMultiselectFilterChange: () => {},
  defaultExpanded: false
};

Filter.propTypes = {
  filter: PropTypes.shape({}).isRequired,
  validation: PropTypes.shape({}).isRequired,
  setFilter: PropTypes.func.isRequired,
  onMultiselectFilterChange: PropTypes.func,
  defaultExpanded: PropTypes.bool
};

export default Filter;
