import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import { ROUTE_WHOLESALERS_LIST } from 'routing/routes/Wholesalers';
import WholesalerClientsMappingCreateForm from 'modules/Wholesalers/component/Details/ClientsMapping/Create/Form';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { DEPARTMENT_MANAGER, DIRECTOR } from 'api/auth/roles';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';

class WholesalerClientsMappingCreate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.wholesalerId = parseInt(this.props.match.params.id, 10);

    this.state = {
      clients: [],
      wholesalerName: undefined
    };
  }

  async componentDidMount() {
    if (!this.wholesalerId) {
      this.props.history.push(ROUTE_WHOLESALERS_LIST);
    }

    this.props.contextMethods.setCurrentPage('Create client mapping');

    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_WHOLESALERS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: {
          data: { country_id, name: wholesalerName }
        }
      } = await WholesalersApi.getWholesaler(this.wholesalerId);

      const {
        data: { data: clients }
      } = await ClientsApi.getAllClients({
        my_clients: this.context.hasRole([DIRECTOR, DEPARTMENT_MANAGER]),
        per_page: Number.MAX_SAFE_INTEGER,
        country_id
      });

      this.setState({ clients, wholesalerName });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { clients, wholesalerName } = this.state;

    if (!clients) return <Loader />;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <WholesalerClientsMappingCreateForm
              wholesalerId={this.wholesalerId}
              wholesalerName={wholesalerName}
              clients={clients}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalerClientsMappingCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerClientsMappingCreate);
