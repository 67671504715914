export default {
  'Imports clients': 'Importy klientów',
  'Imports products': 'Importy produktów',
  'Imports price lists': 'Importy cenników',
  'Imports product batches': 'Importy stanów magazynowych',
  'start import': ' rozpocznij import',
  'refresh list': 'odśwież listę',
  new: 'nowy',
  'no file (manual import)': 'brak pliku (ręczny import)',
  'successfully completed': 'zakończony sukcesem',
  failed: 'zakończony niepowodzeniem',
  'import have wrong status': 'import ma niewłaściwy status',
  'Imports operate only in Poland': 'Importy działają jedynie w Polsce'
};
