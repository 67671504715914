import { useContext } from 'react';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_CREATE } from 'api/auth/permissions/PostalCodes';

const useTypes = () => {
  const { hasPermission } = useContext(AuthContext);

  return [
    hasPermission([POSTAL_CODES_COUNTRIES_CREATE]) && {
      id: 1,
      name: 'Country',
      path: 'postal-codes/countries'
    },
    { id: 2, name: 'Region', path: 'postal-codes/regions' },
    {
      id: 3,
      name: 'Subregion',
      path: 'postal-codes/subregions'
    },
    {
      id: 4,
      name: 'Sub subregion',
      path: 'postal-codes/subsubregions'
    },
    { id: 5, name: 'City', path: 'postal-codes/cities' },
    { id: 6, name: 'Postal code', path: 'postal-codes' }
  ];
};

export default useTypes;
