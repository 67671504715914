import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class KnowledgeBaseApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.knowledgeBasePath = 'knowledge';
    this.knowledgeBaseCreateCategoryPath = 'knowledge/categories';
    this.knowledgeBaseDeleteCategoryPath = 'knowledge/categories/:id';
    this.knowledgeBaseUpdateCategoryPath = 'knowledge/categories/:id';
    this.knowledgeBaseDeleteNotePath = 'knowledge/:id';
    this.knowledgeBaseUpdateNotePath = 'knowledge/:id';
    this.knowledgeBaseGetNoteFilePath = 'knowledge/:id';
    this.knowledgeSendFilesPath = 'knowledge/send';
  }

  getKnowledge(pathParams) {
    return this.get(this.knowledgeBasePath, pathParams);
  }

  createCategory(payload) {
    return this.post(this.knowledgeBaseCreateCategoryPath, payload);
  }

  deleteCategory(id) {
    return this.delete(
      insertPathParams(this.knowledgeBaseDeleteCategoryPath, { id })
    );
  }

  updateCategory(id, payload) {
    return this.put(
      insertPathParams(this.knowledgeBaseDeleteCategoryPath, { id }),
      payload
    );
  }

  createNote(payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      }
    };
    return this.post(this.knowledgeBasePath, payload, options);
  }

  deleteNote(id) {
    return this.delete(
      insertPathParams(this.knowledgeBaseDeleteNotePath, { id })
    );
  }

  updateNote(id, payload) {
    return this.post(
      insertPathParams(this.knowledgeBaseUpdateNotePath, { id }),
      payload
    );
  }

  getNoteFile(id) {
    const options = {
      responseType: 'blob'
    };
    return this.get(
      insertPathParams(this.knowledgeBaseGetNoteFilePath, { id }),
      {},
      options
    );
  }

  sendFiles(payload) {
    return this.post(this.knowledgeSendFilesPath, payload);
  }
}

export default new KnowledgeBaseApi();
