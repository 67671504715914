import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { Box } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import AddIcon from '@material-ui/icons/Add';
import IconButton from 'modules/Layout/component/IconButton';
import { downloadFile } from 'modules/Shared/utils/file';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { PROMOTIONS_CREATE } from 'api/auth/permissions/Promotions';
import {
  ROUTE_PROMOTIONS_CREATE,
  ROUTE_PROMOTIONS_UPDATE,
  ROUTE_PROMOTIONS_DETAILS
} from 'routing/routes/Promotions';
import Authorize from 'modules/Auth/component/Authorize';
import PromotionsTable from 'modules/Promotions/component/List/Table';
import PromotionsFilter from 'modules/Promotions/component/List/Filter';
import PromotionsApi from 'api/connections/Promotions/PromotionsApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import insertPathParams from 'api/utils/insertPathParams';
import PromotionDeleteDialog from 'modules/Promotions/component/DeleteDialog';

class PromotionsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      promotions: [],
      clients: [],
      loading: true,
      deleteDialogStatus: false,
      promotionToDelete: null
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchPromotions = this.fetchPromotions.bind(this);
    this.exportPromotions = this.exportPromotions.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Promotions');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.proos.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_PROMOTIONS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_PROMOTIONS_UPDATE, { id }));
  }

  onDeleteClick(data) {
    this.setState({ deleteDialogStatus: true, promotionToDelete: data });
  }

  onDialogClose() {
    this.setState({
      deleteDialogStatus: false,
      promotionToDelete: null
    });
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchPromotions()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchPromotions()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchPromotions()
    );
  }

  async fetchData() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;
    let countries = [];

    try {
      const [
        {
          data: { data: promotions, meta }
        },
        {
          data: { data: clients }
        }
      ] = await Promise.all([
        PromotionsApi.getPromotions({
          ...sort,
          ...filter,
          per_page,
          page
        }),
        ClientsApi.getAllClients({ per_page: Number.MAX_SAFE_INTEGER })
      ]);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        promotions,
        countries,
        clients,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchPromotions() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: promotions, meta }
      } = await PromotionsApi.getPromotions({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        promotions,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportPromotions() {
    try {
      const { filter, sort } = this.state;

      const res = await PromotionsApi.exportPromotions({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          <Authorize permissions={[PROMOTIONS_CREATE]}>
            <IconButton
              icon={<AddIcon fontSize='large' />}
              onClick={() => this.props.history.push(ROUTE_PROMOTIONS_CREATE)}
              className='create-icon'
              alt='create'
            />
          </Authorize>
          <IconButton
            onClick={this.exportPromotions}
            icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
            alt='export table'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const {
      filter,
      sort,
      promotions,
      countries,
      clients,
      loading,
      filterValidation,
      deleteDialogStatus,
      promotionToDelete
    } = this.state;

    return (
      <>
        <PromotionsFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          clients={clients}
        />
        {this.renderActions()}
        <PromotionsTable
          sort={sort}
          setSort={this.setSort}
          promotions={promotions}
          countries={countries}
          loading={loading}
          onDetailsClick={this.onDetailsClick}
          onUpdateClick={this.onUpdateClick}
          onDeleteClick={this.onDeleteClick}
        />
        {this.renderActions()}
        {deleteDialogStatus && (
          <PromotionDeleteDialog
            id={promotionToDelete.id}
            name={promotionToDelete.name}
            onClose={this.onDialogClose}
            onSuccess={this.fetchPromotions}
          />
        )}
      </>
    );
  }
}

PromotionsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(PromotionsList);
