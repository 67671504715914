import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';

import SalesResultsCharts from 'modules/SalesResults/component/ResultsDetails/Charts';
import SalesResultsNavigationBar from 'modules/SalesResults/component/ResultsDetails/NavigationBar';
import SalesResultsLegend from 'modules/SalesResults/component/ResultsDetails/Legend';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_SALES_RESULT_LIST } from 'routing/routes/SalesResults';
import SalesResultsApi from 'api/connections/SalesResults/SalesResultsApi';
import formatSalesResults from 'modules/SalesResults/utils/formatSalesResults';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import Loader from 'modules/Layout/component/Loader';
import { withRouter } from 'react-router-dom';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';

class SalesResultsUserWrapper extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      stack: [],
      breadcrumbs: [t('Comprehensive data')],
      viewType: 'pie',
      renderData: null,
      salesResultData: null
    };

    this.drillUp = this.drillUp.bind(this);
    this.drillDown = this.drillDown.bind(this);
    this.onViewTypeChange = this.onViewTypeChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_SALES_RESULT_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onViewTypeChange(viewType) {
    this.setState({ viewType });
  }

  drillDown(data) {
    if (data.children.length > 0) {
      this.setState(state => {
        return {
          stack: [...state.stack, state.renderData],
          renderData: data.children,
          breadcrumbs: [...state.breadcrumbs, data.name]
        };
      });
    }
  }

  drillUp(index) {
    this.setState(state => {
      if (state.breadcrumbs.length <= 1) return { ...state };
      return {
        renderData: state.stack[index],
        breadcrumbs: state.breadcrumbs.slice(0, index + 1),
        stack: state.stack.slice(0, index)
      };
    });
  }

  async fetchData() {
    const [month, year] = this.props.month.split('.');

    try {
      const {
        data: salesResultData
      } = await SalesResultsApi.getUserSalesResultsDetails({
        department_id: this.props.departmentId,
        user_id: this.props.userId,
        period_type: 'month',
        year,
        month
      });

      const formattedSalesResultsData = formatSalesResults(
        salesResultData.data
      );

      this.setState({
        salesResultData,
        renderData: formattedSalesResultsData,
        stack: [],
        breadcrumb: []
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { renderData, breadcrumbs, salesResultData } = this.state;
    const nameSuffix = Date.now();

    const viewType = this.props.viewType ?? this.state.viewType;

    if (!salesResultData) return <Loader />;

    return (
      <Box display='flex' flexDirection='column'>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          mt={1.2}
          ml={{ lg: 2 }}
        >
          <TextDataSet
            label='Department name'
            data={salesResultData.department_name}
          />
          <TextDataSet label='Period type' data={t('month')} />
          <TextDataSet label='Period' data={this.props.month} />
        </Box>
        {!renderData || renderData.length === 0 ? (
          <Box display='flex' justifyContent='center'>
            <Typography color='textPrimary'>{t('No data')}</Typography>
          </Box>
        ) : (
          <>
            <SalesResultsNavigationBar
              breadcrumbs={breadcrumbs}
              onDrillUp={this.drillUp}
              viewType={viewType}
              onViewTypeChange={this.onViewTypeChange}
            />
            <SalesResultsCharts
              salesResultsData={renderData}
              viewType={viewType}
              onDrillDown={this.drillDown}
              nameSuffix={nameSuffix}
            />
            <SalesResultsLegend nameSuffix={nameSuffix} />
          </>
        )}
      </Box>
    );
  }
}

SalesResultsUserWrapper.defaultProps = {
  month: null,
  viewType: null
};

SalesResultsUserWrapper.propTypes = {
  departmentId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  month: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  viewType: PropTypes.string
};

export default withRouter(SalesResultsUserWrapper);
