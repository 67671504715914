import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import { Box, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import {
  DIRECTOR,
  DEPARTMENT_MANAGER,
  TRADER,
  TELEMARKETER,
  KEY_ACCOUNT_MANAGER,
  roles
} from 'api/auth/roles';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import IconButton from 'modules/Layout/component/IconButton';

const segregateUsers = users => {
  const directors = users.filter(user => user.role === DIRECTOR);
  const managers = users.filter(user => user.role === DEPARTMENT_MANAGER);
  const usersWithoutManagers = users.filter(
    user =>
      [TRADER, TELEMARKETER, KEY_ACCOUNT_MANAGER].includes(user.role) &&
      !user.manager_id
  );

  return [directors, managers, usersWithoutManagers];
};

const userComponent = user => {
  const { id, role, first_name, last_name } = user;

  return (
    <TextDataSet
      key={id}
      label={roles.find(item => item.slug === role).name}
      data={`${first_name} ${last_name}`}
    />
  );
};

const DepartmentUserList = ({ users, exportDepartmentUsers }) => {
  if (isEmpty(users)) {
    return (
      <Typography component='h6' variant='h6'>
        {t('No user assign to department')}
      </Typography>
    );
  }

  const [directors, managers, usersWithoutManagers] = segregateUsers(users);

  return (
    <>
      <Box display='flex' alignItems='center' mb={1}>
        <Typography component='h6' variant='h6'>
          {t('Department user list')}
        </Typography>
        <IconButton
          onClick={() => exportDepartmentUsers()}
          icon={<GetAppOutlinedIcon color='primary' />}
          alt='export users list'
        />
      </Box>
      {directors.map(director => userComponent(director))}
      {managers.map(manager => (
        <Box key={`manager-${manager.id}`}>
          {userComponent(manager)}
          {!isEmpty(users.filter(user => user.manager_id === manager.id)) && (
            <Typography
              component='h6'
              variant='body1'
              style={{
                fontWeight: 600
              }}
            >
              {t('Users assigned to the manager')}
            </Typography>
          )}
          <Box style={{ marginLeft: '1rem' }}>
            {users
              .filter(user => user.manager_id === manager.id)
              .map(user => userComponent(user))}
          </Box>
        </Box>
      ))}
      {!isEmpty(usersWithoutManagers) && (
        <Box mt={2}>
          <Typography
            component='h6'
            variant='body1'
            style={{ fontWeight: 600 }}
          >
            {t('Users not assign to managers')}
          </Typography>
          <Box style={{ marginLeft: '1rem' }}>
            {usersWithoutManagers.map(user => userComponent(user))}
          </Box>
        </Box>
      )}
    </>
  );
};

DepartmentUserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      manager_id: PropTypes.number,
      role: PropTypes.string
    })
  ).isRequired,
  exportDepartmentUsers: PropTypes.func.isRequired
};

export default DepartmentUserList;
