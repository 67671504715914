import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ROUTE_PRODUCT_CATEGORIES_LIST } from 'routing/routes/ProductCategories';
import { Box, Paper } from '@material-ui/core';
import ProductCategoriesCreateForm from 'modules/ProductCategories/components/Create/Form';
import { ProductCategoriesContext } from 'modules/ProductCategories/context/ProductCategoriesProvider';

class ProductCategoriesCreate extends Component {
  static contextType = ProductCategoriesContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  async componentDidMount() {
    const { itemType } = this.context;
    if (!itemType) {
      this.props.history.push(ROUTE_PRODUCT_CATEGORIES_LIST);
    }
    this.setCurrentPage(`Create product ${itemType}`);
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ProductCategoriesCreateForm
              setAlert={this.setAlert}
              productCategoriesContext={this.context}
              language={this.props.user.language}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ProductCategoriesCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  user: PropTypes.shape({
    language: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ProductCategoriesCreate);
