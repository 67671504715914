import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Input from 'modules/Layout/component/Input';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Loader from 'modules/Layout/component/Loader';
import Button from 'modules/Layout/component/Button';
import { Box, Typography } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { isArray } from 'lodash/lang';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ClientsContractsApi from 'api/connections/ClientsContracts/ClientsContractsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import ProductsApi from 'api/connections/Products/ProductsApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import {
  ROUTE_CLIENTS_CONTRACTS_DETAILS,
  ROUTE_CLIENTS_CONTRACTS_LIST
} from 'routing/routes/ClientsContracts';
import PeriodPicker from 'modules/Layout/component/Date/PeriodPicker';
import Thresholds from 'modules/Shared/components/Thresholds';
import insertPathParams from 'api/utils/insertPathParams';
import { DEPARTMENT_MANAGER, TRADER } from 'api/auth/roles';

const recipientTypeOptions = [
  { key: 'client', name: 'Client' },
  { key: 'shopping_group', name: 'Shopping group' }
];

const pathParams = {
  per_page: Number.MAX_SAFE_INTEGER
};

class ClientsContractsForm extends Component {
  static contextType = AuthContext;

  static setRecipientType(clientContract, context) {
    if (clientContract?.client_id) return 'client';
    if (clientContract?.sales_group_id) return 'shopping_group';

    if (context.hasRole([DEPARTMENT_MANAGER, TRADER])) return 'client';

    return '';
  }

  constructor(props, context) {
    super(props, context);

    const { clientContract } = this.props;

    const {
      categoryGroups,
      categories,
      subCategories
    } = this.formatCategories();

    this.state = {
      formData: {
        type: 1,
        name: clientContract?.name ?? '',
        recipient_type: ClientsContractsForm.setRecipientType(
          clientContract,
          context
        ),
        client_id: clientContract?.client_id ?? '',
        sales_group_id: clientContract?.sales_group_id ?? '',
        period_type: clientContract?.period_type ?? '',
        period_from: clientContract?.period_from ?? '',
        products: clientContract?.products.map(p => p.id) ?? [],
        categoryGroups,
        categories,
        subCategories,
        bonuses: clientContract?.bonuses ?? []
      },
      data: null,
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        },
        recipient_type: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Recipient type')
          })
        },
        client_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Client')
          })
        },
        sales_group_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Shopping group')
          })
        },
        period_type: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Period')
          })
        },
        period_from: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Period')
          })
        },
        quarter: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Quarter')
          })
        },
        bonuses: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Bonus')
          })
        }
      },
      loading: false,
      loadingClients: false,
      loadingSalesGroups: false
    };

    this.handleError = this.handleError.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onRecipientTypeChange = this.onRecipientTypeChange.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.onBonusesChange = this.onBonusesChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    if (this.context.hasRole([DEPARTMENT_MANAGER, TRADER])) {
      this.fetchClients();
    }
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    if (this.props.clientContract) {
      const { id } = this.props.clientContract;

      return this.props.history.push(
        insertPathParams(ROUTE_CLIENTS_CONTRACTS_DETAILS, { id })
      );
    }
    this.props.history.push(ROUTE_CLIENTS_CONTRACTS_LIST);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        ...state,
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onRecipientTypeChange(e) {
    const { value } = e.target;

    this.setState(
      state => {
        return {
          ...state,
          formData: {
            ...state.formData,
            recipient_type: value,
            client_id: null,
            sales_group_id: null
          },
          validation: {
            ...state.validation,
            recipient_type: {
              ...state.validation.recipient_type,
              status: false
            }
          },
          loadingClients: value === 'client' && !state.data.clients,
          loadingSalesGroups:
            value === 'shopping_group' && !state.data.salesGroups
        };
      },
      () => {
        if (value === 'client' && !this.state.data.clients) {
          this.fetchClients();
        }
        if (value === 'shopping_group' && !this.state.data.salesGroups) {
          this.fetchSalesGroups();
        }
      }
    );
  }

  onPeriodChange(data) {
    const { period_type, period_from } = data;

    this.setState(state => {
      const { formData, validation } = state;

      return {
        ...state,
        formData: {
          ...formData,
          period_type,
          period_from
        },
        validation: {
          ...validation,
          period_type: {
            ...validation.period_type,
            status: false
          },
          period_from: {
            ...validation.period_from,
            status: false
          }
        }
      };
    });
  }

  onBonusesChange(bonuses) {
    this.setState(state => {
      return {
        ...state,
        formData: {
          ...state.formData,
          bonuses
        },
        validation: {
          ...state.validation,
          bonuses: {
            status: false
          }
        }
      };
    });
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onChange(event);
  }

  formatCategories() {
    const { clientContract, categoriesObj } = this.props;
    const clientContractCategories = clientContract?.categories ?? [];

    const categoryGroups = clientContractCategories
      .filter(i => categoriesObj.productGroups.map(d => d.id).includes(i.id))
      .map(i => i.id);
    const categories = clientContractCategories
      .filter(i => categoriesObj.categories.map(d => d.id).includes(i.id))
      .map(i => i.id);
    const subCategories = clientContractCategories
      .filter(i => categoriesObj.subCategories.map(d => d.id).includes(i.id))
      .map(i => i.id);

    return {
      categoryGroups,
      categories,
      subCategories
    };
  }

  async fetchData() {
    try {
      const {
        data: { data: products }
      } = await ProductsApi.getProducts(pathParams);

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          products
        },
        loading: false
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchClients() {
    try {
      const {
        data: { data: clients }
      } = await ClientsApi.getAllClients({
        ...pathParams,
        my_clients: this.context.hasRole([DEPARTMENT_MANAGER, TRADER])
      });

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          clients
        },
        loadingClients: false
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchSalesGroups() {
    try {
      const {
        data: { data: salesGroups }
      } = await SalesGroupsApi.getSalesGroups(pathParams);

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          salesGroups
        },
        loadingSalesGroups: false
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    formData.categories = [
      ...formData.categoryGroups,
      ...formData.categories,
      ...formData.subCategories
    ];

    try {
      this.setState({ loading: true });
      if (this.props.clientContract) {
        await ClientsContractsApi.updateClientContract(
          this.props.clientContract.id,
          formData
        );
      } else {
        await ClientsContractsApi.createClientContract(formData);
      }

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        name,
        sales_group_id,
        client_id,
        products,
        period_type,
        period_from,
        categoryGroups,
        categories,
        subCategories,
        bonuses,
        recipient_type
      },
      validation,
      data,
      loading,
      loadingClients,
      loadingSalesGroups
    } = this.state;

    const { categoriesObj } = this.props;

    if (!data) return <Loader />;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='name'
          label='Name'
          value={name}
          required
          onChange={this.onChange}
          errorStatus={validation.name.status}
          errorText={validation.name.message}
        />
        {!this.props.clientContract && (
          <>
            {!this.context.hasRole([DEPARTMENT_MANAGER, TRADER]) && (
              <Select
                name='recipient_type'
                label='Recipient type'
                value={recipient_type}
                options={formatOptions(
                  recipientTypeOptions,
                  'key',
                  'name',
                  true
                )}
                onChange={this.onRecipientTypeChange}
                required
                disabled={Boolean(this.props.clientContract)}
                tooltipMsg='Recipient selection disabled during editing'
                errorStatus={validation.recipient_type.status}
                errorText={validation.recipient_type.message}
              />
            )}
            {recipient_type === 'client' && (
              <Autocomplete
                name='client_id'
                label='Client'
                value={client_id}
                options={formatOptions(data.clients ?? [], 'id', 'name')}
                onChange={(_, v) => this.onAutocompleteChange('client_id', v)}
                loading={loadingClients}
                required
                errorStatus={validation.client_id.status}
                errorText={validation.client_id.message}
              />
            )}
            {recipient_type === 'shopping_group' && (
              <Autocomplete
                name='sales_group_id'
                label='Shopping group'
                value={sales_group_id}
                options={formatOptions(data.salesGroups ?? [], 'id', 'name')}
                onChange={(_, v) =>
                  this.onAutocompleteChange('sales_group_id', v)
                }
                loading={loadingSalesGroups}
                required
                errorStatus={validation.sales_group_id.status}
                errorText={validation.sales_group_id.message}
              />
            )}
          </>
        )}
        <Typography component='h6' variant='h6'>
          {t('Period')}
        </Typography>
        <Box className='language-form-box'>
          <PeriodPicker
            onChange={this.onPeriodChange}
            validation={validation}
            values={{ period_type, period_from }}
          />
        </Box>
        <Typography component='h6' variant='h6'>
          {t('Products')}
        </Typography>
        <Box className='language-form-box'>
          <Autocomplete
            label='Product group'
            name='categoryGroups'
            value={categoryGroups}
            options={formatOptions(
              categoriesObj.productGroups ?? [],
              'id',
              'name'
            )}
            onChange={(_, v) => this.onAutocompleteChange('categoryGroups', v)}
            multiple
          />
          <Autocomplete
            label='Category'
            name='categories'
            value={categories}
            options={formatOptions(categoriesObj.categories, 'id', 'name')}
            onChange={(_, v) => this.onAutocompleteChange('categories', v)}
            multiple
          />
          <Autocomplete
            label='Subcategory'
            name='subCategories'
            value={subCategories}
            options={formatOptions(categoriesObj.subCategories, 'id', 'name')}
            onChange={(_, v) => this.onAutocompleteChange('subCategories', v)}
            multiple
          />
          <Autocomplete
            label='Products'
            name='products'
            value={products}
            options={formatOptions(data.products ?? [], 'id', 'sku')}
            onChange={(_, v) => this.onAutocompleteChange('products', v)}
            multiple
          />
        </Box>
        <Typography component='h6' variant='h6'>
          {t('Thresholds')}
        </Typography>
        <Thresholds
          thresholds={bonuses}
          onChange={this.onBonusesChange}
          isAllInvalid={validation.bonuses.status}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button
            text={t('Cancel')}
            onClick={() =>
              this.props.history.push(ROUTE_CLIENTS_CONTRACTS_LIST)
            }
          />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

ClientsContractsForm.defaultProps = {
  clientContract: null
};

ClientsContractsForm.propTypes = {
  clientContract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    sales_group_id: PropTypes.number,
    client_id: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    period_type: PropTypes.string,
    period_from: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    bonuses: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  }),
  categoriesObj: PropTypes.shape({
    productGroups: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    categories: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    subCategories: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(ClientsContractsForm);
