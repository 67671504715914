import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class PromotionsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.promotionsPath = 'promotions';
    this.promotionPath = 'promotions/:id';
    this.exportPromotionsPath = 'promotions/export';
    this.percentagePromotionPath = 'promotions/percentage';
    this.updatePercentagePromotionPath = 'promotions/percentage/:id';
    this.groupPromotionPath = 'promotions/group';
    this.updateGroupPromotionPath = 'promotions/group/:id';
    this.freeproductPromotionPath = 'promotions/freeproduct';
    this.updateFreeproductPromotionPath = 'promotions/freeproduct/:id';
  }

  getPromotions(pathParams) {
    return this.get(this.promotionsPath, pathParams);
  }

  getPromotion(id) {
    return this.get(insertPathParams(this.promotionPath, { id }));
  }

  exportPromotions(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportPromotionsPath, pathParams, options);
  }

  createPercentagePromotion(payload) {
    return this.post(this.percentagePromotionPath, payload);
  }

  createGroupPromotion(payload) {
    return this.post(this.groupPromotionPath, payload);
  }

  createFreeproductPromotion(payload) {
    return this.post(this.freeproductPromotionPath, payload);
  }

  updatePercentagePromotion(id, payload) {
    return this.put(
      insertPathParams(this.updatePercentagePromotionPath, { id }),
      payload
    );
  }

  updateGroupPromotion(id, payload) {
    return this.put(
      insertPathParams(this.updateGroupPromotionPath, { id }),
      payload
    );
  }

  updateFreeproductPromotion(id, payload) {
    return this.put(
      insertPathParams(this.updateFreeproductPromotionPath, { id }),
      payload
    );
  }

  deletePromotion(id) {
    return this.delete(insertPathParams(this.promotionPath, { id }));
  }
}

export default new PromotionsApi();
