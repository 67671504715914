import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class OrdersApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.ordersPath = 'orders';
    this.orderPath = 'orders/:id';
    this.orderStatusPath = 'orders/status/:id';
    this.exportOrdersPath = 'orders/export';
    this.latestOrdersPath = 'orders/latest';
  }

  getOrders(pathParams) {
    return this.get(this.ordersPath, pathParams);
  }

  getOrder(id) {
    return this.get(insertPathParams(this.orderPath, { id }));
  }

  exportOrders(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportOrdersPath, pathParams, options);
  }

  createOrder(payload) {
    return this.post(this.ordersPath, payload);
  }

  updateOrder(id, payload) {
    return this.put(insertPathParams(this.orderPath, { id }), payload);
  }

  deleteOrder(id) {
    return this.delete(insertPathParams(this.orderPath, { id }));
  }

  changeStatus(id, payload) {
    return this.post(insertPathParams(this.orderStatusPath, { id }), payload);
  }

  getLatestOrders(pathParams) {
    return this.get(this.latestOrdersPath, pathParams);
  }
}

export default new OrdersApi();
