import PropTypes from 'prop-types';
import t from 'translate/translate';
import useStyles from 'modules/Layout/component/List/FilterRenderer/style';
import { Typography, Box } from '@material-ui/core';
import Accordion from 'modules/Layout/component/Accordion';
import Input from 'modules/Layout/component/Input';
import Select from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import AutocompleteBigData from 'modules/Layout/component/AutocompleteBigData';
import Checkbox from 'modules/Layout/component/Checkbox';
import DatePicker from 'modules/Layout/component/Date/DatePicker';
import DateTimePicker from 'modules/Layout/component/Date/DateTimePicker';
import MonthYearPicker from 'modules/Layout/component/Date/MonthYearPicker';
import YearPicker from 'modules/Layout/component/Date/YearPicker';
import ValueOrRange from 'modules/Layout/component/ValueOrRange';
import PeriodPicker from 'modules/Layout/component/Date/PeriodPicker';
import _ from 'lodash';

const FilterRenderer = props => {
  const {
    inputs,
    setFilter,
    onMultiselectFilterChange,
    defaultExpanded
  } = props;
  const classes = useStyles();

  const onFilter = e => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    setFilter(name, value);
  };

  const onAutocompleteFilter = (name, inputValue) => {
    const value = inputValue ? inputValue.key : null;
    setFilter(name, value);
  };

  const onMultipleAutocompleteFilter = (name, res, action) => {
    let value = null;

    if (res) {
      value = _.isArray(res) ? res.map(v => v.key) : res.key;
    }

    onMultiselectFilterChange(name, value);

    if (action === 'select-option') {
      return;
    }

    setFilter();
  };

  const onMultipleAutocompleteFilterClose = () => {
    setFilter();
  };

  const onPeriodFilter = values => {
    setFilter('period', values);
  };

  const renderInput = input => {
    const {
      displayStatus,
      property,
      label,
      value,
      emptyValueText,
      options,
      type,
      inputProps,
      component,
      errorStatus,
      errorText,
      disabled = false,
      loading,
      tooltipMsg,
      minDate,
      maxDate
    } = input;

    if (displayStatus !== undefined && displayStatus === false) {
      return null;
    }

    const inputComponent = (() => {
      switch (type) {
        case 'text':
        case 'number':
          return (
            <Input
              name={property}
              label={label}
              value={value}
              onChange={onFilter}
              type={type}
              inputProps={inputProps}
              errorStatus={errorStatus}
              errorText={errorText}
            />
          );
        case 'select':
          return (
            <Select
              name={property}
              label={label}
              value={value}
              emptyValueText={emptyValueText}
              options={options}
              onChange={onFilter}
            />
          );
        case 'autocomplete':
          return (
            <Autocomplete
              name={property}
              label={label}
              value={value}
              options={options}
              onChange={(e, inputValue) =>
                onAutocompleteFilter(property, inputValue)
              }
              disabled={disabled}
              tooltipMsg={tooltipMsg}
              loading={loading}
            />
          );
        case 'multiAutocomplete':
          return (
            <Autocomplete
              multiple
              name={property}
              label={label}
              value={value}
              options={options}
              onChange={(__, inputValue, action) =>
                onMultipleAutocompleteFilter(property, inputValue, action)
              }
              disabled={disabled}
              loading={loading}
              onClose={onMultipleAutocompleteFilterClose}
            />
          );
        case 'autocompleteBigData':
          return (
            <AutocompleteBigData
              name={property}
              label={label}
              value={value}
              options={options}
              onChange={(e, inputValue) =>
                onAutocompleteFilter(property, inputValue)
              }
            />
          );
        case 'checkbox':
          return (
            <Checkbox
              name={property}
              label={label}
              checked={value}
              onChange={onFilter}
              disabled={disabled}
              tooltipMsg={tooltipMsg}
            />
          );
        case 'date':
          return (
            <DatePicker
              name={property}
              label={label}
              value={value}
              onChange={onFilter}
              errorStatus={errorStatus}
              errorText={errorText}
              minDate={minDate}
              maxDate={maxDate}
            />
          );
        case 'dateTime':
          return (
            <DateTimePicker
              name={property}
              label={label}
              value={value}
              onChange={onFilter}
            />
          );
        case 'monthYear':
          return (
            <MonthYearPicker
              name={property}
              label={label}
              value={value}
              onChange={onFilter}
              minDate={minDate}
              maxDate={maxDate}
            />
          );
        case 'year':
          return (
            <YearPicker
              name={property}
              label={label}
              value={value}
              onChange={onFilter}
            />
          );
        case 'periodPicker':
          return (
            <PeriodPicker
              name={property}
              values={value}
              onChange={onPeriodFilter}
            />
          );
        case 'valueOrRange':
          return <ValueOrRange options={options} onFilter={onFilter} />;
        case 'component':
          return component;
        default:
          return null;
      }
    })();

    return (
      <Box
        key={`filter-${input.property}`}
        className={
          type === 'valueOrRange' || type === 'periodPicker'
            ? classes.valueOrRangeWrapper
            : classes.accordionDetailsBox
        }
      >
        {inputComponent}
      </Box>
    );
  };

  const renderGroupInputs = group => {
    const { groupLabel, children, displayStatus } = group;

    if (displayStatus !== undefined && displayStatus === false) return null;

    return (
      <Box
        key={`filter-group-${groupLabel}`}
        className={classes.accordionDetailsGroupWrapper}
      >
        <Typography>{t(groupLabel)}</Typography>
        <Box
          key={`filter-group-children-${groupLabel}`}
          className={classes.accordionDetailsGroupChildrenWrapper}
        >
          {children.map(input => renderInput(input))}
        </Box>
      </Box>
    );
  };

  const render = () => {
    return inputs.map(group => renderGroupInputs(group));
  };

  return (
    <Accordion label={t('Filter')} defaultExpanded={defaultExpanded}>
      <Box className={classes.accordionDetailsBoxWrapper}>{render()}</Box>
    </Accordion>
  );
};

FilterRenderer.defaultProps = {
  onMultiselectFilterChange: () => {},
  style: {},
  defaultExpanded: false
};

FilterRenderer.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilter: PropTypes.func.isRequired,
  onMultiselectFilterChange: PropTypes.func,
  style: PropTypes.shape({}),
  defaultExpanded: PropTypes.bool
};

export default FilterRenderer;
