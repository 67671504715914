import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import UpdateUserForm from 'modules/Users/component/Update/Form';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import PropTypes from 'prop-types';
import UsersApi from 'api/connections/Users/UsersApi';
import CircularProgress from '@material-ui/core/CircularProgress';
import ApiError from 'api/exceptions/ApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';

class UsersUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.userId = props.match.params.id;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
    this.state = null;
  }

  async componentDidMount() {
    this.setCurrentPage('User update');
    await this.fetchData();
  }

  async fetchData() {
    try {
      let countries = [];
      const {
        data: { data: user }
      } = await UsersApi.getUser(this.userId);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({ user, countries });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            {!this.state ? (
              <Box display='flex' justifyContent='center'>
                <CircularProgress />
              </Box>
            ) : (
              <UpdateUserForm
                user={this.state.user}
                countries={this.state.countries}
                setAlert={this.setAlert}
              />
            )}
          </Paper>
        </Box>
      </Box>
    );
  }
}

UsersUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(UsersUpdate);
