import { Box, Paper } from '@material-ui/core';
import Form from 'modules/Profile/component/Details/Form';
import { useCurrentPage } from 'modules/Shared/hook';

const Update = () => {
  useCurrentPage('Edit Profile');

  return (
    <Box width={1} display='flex' justifyContent='center'>
      <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
        <Paper style={{ padding: '1rem' }}>
          <Form />
        </Paper>
      </Box>
    </Box>
  );
};

export default Update;
