import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import StatisticsExportApi from 'api/connections/Statistics/StatisticsExportApi';
import StatisticsSalesComparisonTable from 'modules/Statistics/SalesComparison/component/List/Table';
import InternetClientsReportFilter from 'modules/Statistics/InternetClientsReport/component/List/Filter';
import { downloadFile } from 'modules/Shared/utils/file';
import IconButton from 'modules/Layout/component/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import { prepareStatisticsData } from 'modules/SalesGroups/view/Details/SalesSummary';

class StatisticsInternetClientsSalesList extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      filter: {},
      filterValidation: {},
      data: [],
      clientsData: [],
      clientsDataLoading: true,
      loading: false,
      currency: undefined
    };

    this.setFilter = this.setFilter.bind(this);
    this.fetchStatistics = this.fetchStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportData = this.exportData.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Internet clients report');
    this.fetchClients();
    this.fetchStatistics();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setFilter(filter) {
    this.setState(
      {
        filter
      },
      () => this.fetchStatistics()
    );
  }

  async fetchStatistics() {
    const { filter } = this.state;

    if (!filter.date_from || !filter.date_to || !filter.client_id) return null;

    try {
      this.setState({ loading: true });

      const {
        data: { data, currency }
      } = await StatisticsApi.getInternetClientsSales({
        ...filter
      });

      this.setState({
        data: prepareStatisticsData(data),
        currency,
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchClients() {
    try {
      const {
        data: { data: clientsData }
      } = await ClientsApi.getInternetClients({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        clientsData,
        clientsDataLoading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportData() {
    try {
      const { filter } = this.state;

      const res = await StatisticsExportApi.exportInternetClientsSalesReport(
        filter
      );

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { date_from, date_to, client_id } = this.state.filter;

    const isDisabled = !date_from || !date_to || !client_id;

    return (
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <IconButton
            onClick={this.exportData}
            icon={
              <GetAppOutlinedIcon
                color={isDisabled ? 'disabled' : 'primary'}
                fontSize='large'
              />
            }
            alt='export table'
            disabled={isDisabled}
            tooltipMsg='Pick dates and client first'
          />
        </Box>
      </Box>
    );
  }

  render() {
    const {
      data,
      clientsData,
      clientsDataLoading,
      filter,
      loading,
      filterValidation,
      currency
    } = this.state;

    return (
      <div>
        <InternetClientsReportFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          clientsData={clientsData}
          clientsDataLoading={clientsDataLoading}
          defaultExpanded
        />

        {this.renderActions()}
        <StatisticsSalesComparisonTable
          rows={data}
          currency={currency}
          loading={loading}
          bodyText={
            !filter.date_from || !filter.date_to || !filter.client_id
              ? 'Pick dates and client first'
              : null
          }
        />
        {this.renderActions()}
      </div>
    );
  }
}

StatisticsInternetClientsSalesList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default StatisticsInternetClientsSalesList;
