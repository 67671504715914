import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  COUNTRY,
  REGION,
  SUBREGION,
  SUBSUBREGION,
  CITY,
  POSTALCODE
} from 'modules/PostalCodes/constants';
import postalCodesReducer from 'modules/PostalCodes/context/reducer/reducer';

import {
  setCountryDepthAction,
  setSelectedItemAction,
  setOpenDialogAction,
  setCloseDialogAction,
  setDialogDataAction,
  setChosenItemAction
} from 'modules/PostalCodes/context/reducer/actions';

export const PostalCodesContext = React.createContext();

const PostalCodesProvider = ({ children }) => {
  const initState = {
    countryDepth: null,
    selectedItem: {
      [COUNTRY]: null,
      [REGION]: null,
      [SUBREGION]: null,
      [SUBSUBREGION]: null,
      [CITY]: null,
      [POSTALCODE]: null
    },
    openDialog: {},
    dialogData: { id: null, name: '' },
    chosenItem: {}
  };

  const [data, setData] = useReducer(postalCodesReducer, initState);

  const handleCountryDepth = depth => {
    setData(setCountryDepthAction(depth));
  };

  const handleListItemClick = item => {
    const { id, type } = item;
    const itemToUpdate = { [type]: id };

    const localState = {};

    // eslint-disable-next-line default-case
    switch (type) {
      case COUNTRY:
        localState[REGION] = null;
      // eslint-disable-next-line no-fallthrough
      case REGION:
        localState[SUBREGION] = null;
      // eslint-disable-next-line no-fallthrough
      case SUBREGION:
        localState[SUBSUBREGION] = null;
      // eslint-disable-next-line no-fallthrough
      case SUBSUBREGION:
        localState[CITY] = null;
    }

    setData(setSelectedItemAction({ ...localState, ...itemToUpdate }));
  };

  const handleOpenDialog = (id, name, e, type = false) => {
    e.stopPropagation();
    setData(setDialogDataAction({ id, name }));
    setData(setOpenDialogAction(type));
  };

  const handleCloseDialog = type => {
    setData(setCloseDialogAction(type));
  };

  const handleChosenItem = item => {
    setData(setChosenItemAction(item));
  };

  const {
    selectedItem,
    dialogData,
    openDialog,
    countryDepth,
    chosenItem
  } = data;

  const values = {
    countryDepth,
    selectedItem,
    openDialog,
    dialogData,
    chosenItem,
    handleCountryDepth,
    handleListItemClick,
    handleOpenDialog,
    handleCloseDialog,
    handleChosenItem
  };

  return (
    <PostalCodesContext.Provider value={values}>
      {children}
    </PostalCodesContext.Provider>
  );
};

PostalCodesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default PostalCodesProvider;
