import { Component } from 'react';
import PropTypes from 'prop-types';
import { ROUTE_PRODUCTS_LIST } from 'routing/routes/Products';
import { Box, Paper } from '@material-ui/core';
import ProductBatchCreateForm from 'modules/Products/component/Batches/Create/Form';
import { withRouter } from 'react-router-dom';
import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';

class ProductBatchCreate extends Component {
  static contextType = CurrentPageContext;

  async componentDidMount() {
    const productId = parseInt(this.props.match.params.id, 10);
    if (!productId) {
      this.props.history.push(ROUTE_PRODUCTS_LIST);
    }
    const { setCurrentPage } = this.context;
    setCurrentPage('Create product batch');
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ProductBatchCreateForm />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ProductBatchCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ProductBatchCreate);
