import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Table from 'modules/Layout/component/List/Table';
import promotionsTypes from 'modules/Promotions/promotionsTypes';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import {
  PROMOTIONS_UPDATE,
  PROMOTIONS_DELETE
} from 'api/auth/permissions/Promotions';

class PromotionsTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'country_id',
        label: 'Country',
        sortable: true,
        displayStatus: this.context.hasPermission([
          POSTAL_CODES_COUNTRIES_SHOW
        ]),
        render: id => this.props.countries.find(c => c.id === id).name
      },
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'start_date',
        label: 'Date from',
        sortable: true
      },
      {
        property: 'end_date',
        label: 'Date to',
        sortable: false
      },
      {
        property: 'type',
        label: 'Type',
        sortable: false,
        render: typeId =>
          t(promotionsTypes.find(type => type.id === typeId).name)
      },
      {
        property: 'clients',
        label: 'Clients',
        sortable: false,
        render: clients => clients.map(client => client.name).join(', ')
      },
      {
        property: 'sales_groups',
        label: 'Shopping groups',
        sortable: false,
        render: salesGroups => salesGroups.map(group => group.name).join(', ')
      },
      {
        property: 'regions',
        label: 'Regions',
        sortable: false,
        render: regions => regions.map(region => region.name).join(', ')
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.props.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([PROMOTIONS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.props.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([PROMOTIONS_DELETE]),
            disable: false,
            disableMsg: null,
            onClick: this.props.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { promotions, sort, setSort, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={promotions}
        sort={sort}
        setSort={setSort}
        loading={loading}
        actions
      />
    );
  }
}

PromotionsTable.defaultProps = {
  promotions: [],
  countries: []
};

PromotionsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  onDetailsClick: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default PromotionsTable;
