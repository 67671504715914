import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import {
  Table as MaterialUiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Typography,
  Tooltip
} from '@material-ui/core';
import { isFunction } from 'lodash/lang';
import Paper from '@material-ui/core/Paper';
import t from 'translate/translate';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import BlockIcon from '@material-ui/icons/Block';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsIcon from '@material-ui/icons/Settings';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import IconButton from 'modules/Layout/component/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import useStyles from 'modules/Layout/component/List/styles';

const Table = props => {
  const {
    cols,
    rows,
    sort,
    setSort,
    actions,
    hover,
    dense,
    loading,
    selected,
    onSelect,
    isRowDisabled,
    rowDisabledTooltip,
    bodyText
  } = props;
  const classes = useStyles();

  const onSort = ({ property }) => {
    if (property === sort.sort_field) {
      setSort({
        ...sort,
        sort_order: sort.sort_order === 'asc' ? 'desc' : 'asc'
      });
    } else {
      setSort({
        sort_field: property,
        sort_order: 'desc'
      });
    }
  };

  const onOneDirectionSort = ({ property }) => {
    setSort({
      sort_field: property,
      sort_order: 'asc'
    });
  };

  const actionsObj = actions && cols.find(col => col.property === 'ACTIONS');

  const renderActionBtn = row => {
    const {
      details: {
        status: detailsStatus,
        disable: detailsDisable,
        disableMsg: detailsDisableMsg,
        onClick: detailsOnCLick
      },
      update,
      remove,
      postpone,
      impersonate,
      download,
      cancel,
      mapItem,
      changeStatus,
      copy,
      createVisit
    } = actionsObj.actionOptions;

    const detailsActionDisableStatus =
      detailsStatus && detailsDisable ? detailsDisable(row) : false;

    let updateActionDisableStatus = null;
    let updateActionDisableMsg = null;
    if (update) {
      const { disable, disableMsg } = update;
      updateActionDisableStatus = disable ? disable(row) : false;
      updateActionDisableMsg = isFunction(disableMsg)
        ? disableMsg(row)
        : disableMsg;
    }

    let removeActionDisableStatus = null;
    let removeActionDisableMsg = null;
    if (remove) {
      const { disable, disableMsg } = remove;
      removeActionDisableStatus = disable ? disable(row) : false;
      removeActionDisableMsg = isFunction(disableMsg)
        ? disableMsg(row)
        : disableMsg;
    }

    const postponeActionDisableStatus =
      postpone?.status && postpone?.disable ? postpone.disable(row) : false;

    const createVisitActionDisableStatus =
      createVisit?.status && createVisit?.disable
        ? createVisit.disable(row)
        : false;

    const changeStatusActionDisableStatus =
      changeStatus?.status && changeStatus?.disable
        ? changeStatus.disable(row)
        : false;

    let impersonateActionDisableStatus = null;
    let impersonateActionDisableMsg = null;
    if (impersonate) {
      const { disable, disableMsg } = impersonate;
      impersonateActionDisableStatus = disable ? disable(row) : false;
      impersonateActionDisableMsg = disableMsg;
    }

    let downloadActionDisableStatus = null;
    let downloadActionDisableMsg = null;
    if (download) {
      const { disable, disableMsg } = download;
      downloadActionDisableStatus = disable ? disable(row) : false;
      downloadActionDisableMsg = disableMsg;
    }

    let cancelActionDisableStatus = null;
    let cancelActionDisableMsg = null;
    if (cancel) {
      const { disable, disableMsg } = cancel;
      cancelActionDisableStatus = disable ? disable(row) : false;
      cancelActionDisableMsg = disableMsg;
    }

    let mapItemActionDisableStatus = null;
    let mapItemActionDisableMsg = null;
    if (mapItem) {
      const { disable, disableMsg } = mapItem;
      mapItemActionDisableStatus = disable ? disable(row) : false;
      mapItemActionDisableMsg = disableMsg;
    }

    return (
      <TableCell align='center'>
        <Box display='flex' justifyContent='center'>
          {detailsStatus && (
            <IconButton
              className='details-icon'
              onClick={() => detailsOnCLick(row)}
              icon={<SearchIcon />}
              size='small'
              disabled={detailsActionDisableStatus}
              tooltipMsg={detailsDisableMsg}
              alt='details'
            />
          )}
          {impersonate?.status && (
            <IconButton
              onClick={() => impersonate?.onClick(row)}
              className='impersonate-icon'
              icon={<SyncIcon />}
              size='small'
              disabled={impersonateActionDisableStatus}
              tooltipMsg={impersonateActionDisableMsg}
              alt='impersonate'
            />
          )}
          {download?.status && (
            <IconButton
              onClick={() => download?.onClick(row)}
              className='download-icon'
              icon={<GetAppIcon />}
              size='small'
              disabled={downloadActionDisableStatus}
              tooltipMsg={downloadActionDisableMsg}
              alt='download'
            />
          )}
          {cancel?.status && (
            <IconButton
              onClick={() => cancel?.onClick(row)}
              className='delete-icon'
              icon={<BlockIcon />}
              size='small'
              disabled={cancelActionDisableStatus}
              tooltipMsg={cancelActionDisableMsg}
              alt='cancel'
            />
          )}
          {mapItem?.status && (
            <IconButton
              onClick={() => mapItem?.onClick(row)}
              className='map-product-icon'
              icon={<AddShoppingCartIcon />}
              size='small'
              disabled={mapItemActionDisableStatus}
              tooltipMsg={mapItemActionDisableMsg}
              alt='map'
            />
          )}
          {update?.status && (
            <IconButton
              className='update-icon'
              onClick={() => update?.onClick(row)}
              icon={<EditIcon />}
              size='small'
              disabled={updateActionDisableStatus}
              tooltipMsg={updateActionDisableMsg}
              alt='update'
            />
          )}
          {remove?.status && (
            <IconButton
              className='delete-icon'
              onClick={() => remove?.onClick(row)}
              icon={<DeleteForeverIcon />}
              size='small'
              disabled={removeActionDisableStatus}
              tooltipMsg={removeActionDisableMsg}
              alt='delete'
            />
          )}
          {postpone?.status && (
            <IconButton
              className='update-icon'
              onClick={() => postpone.onClick(row)}
              icon={<ScheduleIcon />}
              size='small'
              disabled={postponeActionDisableStatus}
              tooltipMsg={postpone.disableMsg}
              alt='postponement'
            />
          )}
          {changeStatus?.status && (
            <IconButton
              className='update-icon'
              onClick={() => changeStatus.onClick(row)}
              icon={<SettingsIcon />}
              size='small'
              disabled={changeStatusActionDisableStatus}
              tooltipMsg={changeStatus.disableMsg}
              alt='change status'
            />
          )}
          {copy?.status && (
            <IconButton
              className='update-icon'
              onClick={() => copy.onClick(row)}
              icon={<FileCopyIcon />}
              size='small'
              alt='copy'
            />
          )}
          {createVisit?.status && (
            <IconButton
              className='create-icon'
              onClick={() => createVisit.onClick(row)}
              icon={<AddIcon />}
              size='small'
              disabled={createVisitActionDisableStatus}
              alt='create visit'
            />
          )}
        </Box>
      </TableCell>
    );
  };

  const renderHeader = col => {
    return (
      <TableCell key={col.property} align='center'>
        <Typography variant='subtitle1' className={classes.cellHeader}>
          {t(col.label)}
        </Typography>
      </TableCell>
    );
  };

  const renderSortableHeader = col => {
    return (
      <TableCell
        key={col.property}
        align='center'
        sortDirection={
          col.property === sort.sort_field ? sort.sort_order : false
        }
      >
        <TableSortLabel
          active={col.property === sort.sort_field}
          direction={col.property === sort.sort_field ? sort.sort_order : 'asc'}
          onClick={() => onSort(col)}
        >
          <Typography variant='subtitle1' className={classes.cellHeader}>
            {t(col.label)}
          </Typography>
        </TableSortLabel>
      </TableCell>
    );
  };

  const renderOneDirectionSortableHeader = col => {
    return (
      <TableCell
        key={col.property}
        align='center'
        sortDirection={col.property === sort.sort_field ? 'asc' : false}
      >
        <TableSortLabel
          active={col.property === sort.sort_field}
          direction={col.property === sort.sort_field ? 'asc' : ''}
          onClick={() => onOneDirectionSort(col)}
        >
          <Typography variant='subtitle1' className={classes.cellHeader}>
            {t(col.label)}
          </Typography>
        </TableSortLabel>
      </TableCell>
    );
  };

  const renderHeaders = () => {
    return cols.map(col => {
      const { displayStatus, sortable, oneDirection } = col;

      if (displayStatus !== undefined && displayStatus === false) {
        return null;
      }

      if (oneDirection && sortable) {
        return renderOneDirectionSortableHeader(col);
      }

      return sortable ? renderSortableHeader(col) : renderHeader(col);
    });
  };

  const validateValue = (value, colConfig, row, index) => {
    let renderValue = value;

    if (
      !colConfig ||
      (colConfig.displayStatus !== undefined &&
        colConfig.displayStatus === false)
    ) {
      return undefined;
    }
    if (
      (!colConfig.nullable && !value && value !== 0) ||
      (_.isArray(value) && _.isEmpty(value))
    ) {
      renderValue = null;
    } else if (colConfig.render) {
      renderValue = colConfig.render(value, row, index);
    } else if (_.isArray(value)) {
      renderValue = value.join(', ');
    }

    return renderValue;
  };

  const renderCell = (col, value, row, index, isDisabled) => {
    const renderValue = validateValue(value, col, row, index);

    if (renderValue === undefined) return null;

    return (
      <TableCell
        align='center'
        key={`${col.property}-${row.id}`}
        className={clsx(classes.cell, isDisabled && classes.disableCell)}
      >
        {col.link ? (
          <Link to={`${col.link.route}${row[col.link.param]}`}>
            {renderValue}
          </Link>
        ) : (
          renderValue
        )}
      </TableCell>
    );
  };

  const renderRow = (row, index) => {
    if (isRowDisabled && isRowDisabled(row)) {
      const tableRow = (
        <TableRow key={`${row.id}-${index}`} hover={false}>
          {cols.map(col => {
            if (col.property === 'ACTIONS') return null;

            const value = row[col.property];

            return renderCell(col, value, row, index, true);
          })}
          {actionsObj && renderActionBtn(row)}
        </TableRow>
      );

      if (rowDisabledTooltip) {
        const msg = isFunction(rowDisabledTooltip)
          ? rowDisabledTooltip(row)
          : rowDisabledTooltip;

        return (
          <Tooltip key={`tooltip-${row.id}`} title={t(msg)}>
            {tableRow}
          </Tooltip>
        );
      }

      return tableRow;
    }

    return (
      <TableRow
        key={`${row.id}-${index}`}
        hover={hover}
        onClick={() => onSelect(row.id)}
        className={clsx(selected.indexOf(row.id) >= 0 && classes.selectedRow)}
      >
        {cols.map(col => {
          if (col.property === 'ACTIONS') return null;

          const value = row[col.property];

          return renderCell(col, value, row, index);
        })}
        {actionsObj && renderActionBtn(row)}
      </TableRow>
    );
  };

  const renderBody = () => {
    return rows.length > 0 ? (
      rows.map((row, index) => renderRow(row, index))
    ) : (
      <TableRow>
        <TableCell align='center' colSpan={cols.length}>
          {t('No data')}
        </TableCell>
      </TableRow>
    );
  };

  const renderBodyText = () => {
    return rows.length > 0 ? (
      rows.map((row, index) => renderRow(row, index))
    ) : (
      <TableRow>
        <TableCell align='center' colSpan={cols.length}>
          <Typography variant='subtitle1' color='textSecondary'>
            {t(bodyText)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <MaterialUiTable
        aria-label='table'
        className={classes.table}
        size={dense ? 'small' : 'medium'}
      >
        <TableHead>
          <TableRow>{renderHeaders()}</TableRow>
        </TableHead>
        <TableBody>
          {bodyText && renderBodyText()}
          {loading && (
            <TableRow>
              <TableCell align='center' colSpan={cols.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {!bodyText && !loading && renderBody()}
        </TableBody>
      </MaterialUiTable>
    </TableContainer>
  );
};

Table.defaultProps = {
  rows: [],
  sort: {
    sort_field: null,
    sort_order: 'asc'
  },
  setSort: () => {},
  actions: false,
  hover: false,
  dense: false,
  onSelect: () => {},
  isRowDisabled: null,
  selected: [],
  loading: false,
  bodyText: null,
  rowDisabledTooltip: null
};

Table.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool.isRequired,
      render: PropTypes.func,
      displayStatus: PropTypes.bool
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  sort: PropTypes.shape({
    sort_field: PropTypes.string,
    sort_order: PropTypes.string
  }),
  setSort: PropTypes.func,
  actions: PropTypes.bool,
  hover: PropTypes.bool,
  dense: PropTypes.bool,
  loading: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.number),
  onSelect: PropTypes.func,
  isRowDisabled: PropTypes.func,
  rowDisabledTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  bodyText: PropTypes.string
};

export default Table;
