import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class CurrenciesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.currenciesPath = 'currencies';
    this.currencyPath = 'currencies/:id';
  }

  getCurrencies(pathParams) {
    return this.get(this.currenciesPath, pathParams);
  }

  createCurrency(payload) {
    return this.post(this.currenciesPath, payload);
  }

  updateCurrency(id, payload) {
    return this.put(insertPathParams(this.currencyPath, { id }), payload);
  }

  deleteCurrency(id) {
    return this.delete(insertPathParams(this.currencyPath, { id }));
  }
}

export default new CurrenciesApi();
