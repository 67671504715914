import locals from 'translate/locals';
import lodashTemplate from 'lodash/template';

export const availableLocals = [
  { slug: 'pl', name: 'Polish' },
  { slug: 'en', name: 'English' }
];
export const availableLocalsArray = availableLocals.map(
  localObj => localObj.slug
);

export const setLangToLocalStorage = lang => {
  localStorage.setItem('lang', lang);
};

const getLangFromLocalStorage = () => {
  const lang = localStorage.getItem('lang');
  if (lang !== null && lang !== 'undefined') {
    return lang;
  }
  return 'pl';
};

const translate = (text, data = null) => {
  const lang = getLangFromLocalStorage();

  const content =
    lang !== 'en' && locals[lang][text] ? locals[lang][text] : text;

  if (data) {
    const template = lodashTemplate(content);
    return template(data);
  }

  return content;
};

export default translate;
