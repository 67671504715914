import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import { Box } from '@material-ui/core';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import insertPathParams from 'api/utils/insertPathParams';

import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';
import ClientsAccountApi from 'api/connections/Clients/ClientsAccountApi';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

class ClientAccountUpdateForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    const {
      client: { id: clientId, account }
    } = props;

    this.clientId = clientId;

    this.state = {
      formData: {
        first_name: account.first_name || '',
        last_name: account.last_name || '',
        email: account.email || '',
        phone: account.phone || '',
        password: '',
        password_confirmation: ''
      },
      validation: {
        first_name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('First name')
          })
        },
        last_name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Last name')
          })
        },
        email: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Email') })
        },
        phone: {
          status: false,
          message: t('Selected product group or category is required')
        },
        password: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('password') })
        },
        password_confirmation: {
          status: false,
          message: t('Passwords are not equal')
        }
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  handleResponse() {
    this.context.setAlert({
      value: 'Success',
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(
      insertPathParams(ROUTE_CLIENTS_DETAILS, { id: this.clientId })
    );
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState(prevState => {
      const { formData: prevFormData, validation: prevValidation } = prevState;

      return {
        formData: {
          ...prevFormData,
          [name]: value
        },
        validation: {
          ...prevValidation,
          [name]: {
            ...prevValidation[name],
            status: false
          }
        }
      };
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      await ClientsAccountApi.updateClientAccount(this.clientId, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  runValidation() {
    const { formData, validation, assignPassword } = this.state;

    /* eslint prefer-const: "off" */
    let [isValid, newValidateState] = validate(formData, validation);

    if (assignPassword && formData.password.length <= 0) {
      isValid = false;
      newValidateState.password.status = true;
    } else if (
      assignPassword &&
      formData.password.length &&
      formData.password !== formData.password_confirmation
    ) {
      isValid = false;
      newValidateState.password_confirmation.status = true;
    }

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  render() {
    const {
      formData: {
        first_name,
        last_name,
        email,
        phone,
        password,
        password_confirmation
      },
      validation,
      loading
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='first_name'
          label='First name'
          value={first_name}
          onChange={this.onChange}
          errorStatus={validation.first_name.status}
          errorText={validation.first_name.message}
        />
        <Input
          name='last_name'
          label='Last name'
          value={last_name}
          onChange={this.onChange}
          errorStatus={validation.last_name.status}
          errorText={validation.last_name.message}
        />
        <Input
          name='email'
          label='Email'
          value={email}
          required
          onChange={this.onChange}
          errorStatus={validation.email.status}
          errorText={validation.email.message}
        />
        <Input
          name='phone'
          label='Phone number'
          inputProps={{ maxLength: 14 }}
          value={phone}
          onChange={this.onChange}
          errorStatus={validation.phone.status}
          errorText={validation.phone.message}
        />
        <Input
          name='password'
          label='Password'
          type='password'
          value={password}
          onChange={this.onChange}
          errorStatus={validation.password.status}
          errorText={validation.password.message}
        />
        <Input
          name='password_confirmation'
          label='Repeat password'
          type='password'
          value={password_confirmation}
          onChange={this.onChange}
          errorStatus={validation.password_confirmation.status}
          errorText={validation.password_confirmation.message}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link
              to={insertPathParams(ROUTE_CLIENTS_DETAILS, {
                id: this.clientId
              })}
              className='router-button'
            >
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

ClientAccountUpdateForm.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ClientAccountUpdateForm);
