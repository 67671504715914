import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';
import { ROUTE_VISITS_LIST } from 'routing/routes/Visits';
import VisitFileCreateForm from 'modules/Visits/components/Details/Files/Create/Form';

class VisitFileCreate extends Component {
  static contextType = CurrentPageContext;

  constructor(props) {
    super(props);

    this.visitId = parseInt(this.props.match.params.id, 10);
  }

  async componentDidMount() {
    if (!this.visitId) {
      this.props.history.push(ROUTE_VISITS_LIST);
    }
    this.context.setCurrentPage('Add visit file');
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper className='p1'>
            <VisitFileCreateForm visitId={this.visitId} />
          </Paper>
        </Box>
      </Box>
    );
  }
}

VisitFileCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(VisitFileCreate);
