export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };
    default:
      return state;
  }
};
