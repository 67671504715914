import { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ClientsTreeRegions from 'modules/Clients/component/ClientsTree/Regions';
import TreeView from '@material-ui/lab/TreeView';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ApiError from 'api/exceptions/ApiError';
import ClientsUsersApi from 'api/connections/Clients/ClientsUsersApi';
import { isArray, isEmpty } from 'lodash/lang';
import { Box, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import _ from 'lodash';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CustomDialog from 'modules/Layout/component/CustomDialog';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';
import ClientsTreeUnrelated from 'modules/Clients/component/ClientsTree/Unrelated';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import ClientsTreeDeleteDialog from 'modules/Clients/component/ClientsTree/DeleteDialog';

class ClientsTree extends Component {
  static contextType = AlertContext;

  static getIdPropName(postalCodeLevel) {
    return postalCodeLevel === 'cities'
      ? 'city_id'
      : `${postalCodeLevel.slice(0, -1)}_id`;
  }

  static setTreeItemsToDisplay(selected) {
    return {
      regions: _.uniq([
        ...selected.regions.map(i => i.id),
        ...selected.subregions.map(i => i.region_id),
        ...selected.subsubregions.map(i => i.region_id),
        ...selected.cities.map(i => i.region_id),
        ...selected.postal_codes.map(i => i.region_id),
        ...selected.clients.map(i => i.region_id)
      ]),
      subregions: _.uniq([
        ...selected.subregions.map(i => i.id),
        ...selected.subsubregions.map(i => i.subregion_id),
        ...selected.cities.map(i => i.subregion_id),
        ...selected.postal_codes.map(i => i.subregion_id),
        ...selected.clients.map(i => i.subregion_id)
      ]),
      subsubregions: _.uniq([
        ...selected.subsubregions.map(i => i.id),
        ...selected.cities.map(i => i.subsubregion_id),
        ...selected.postal_codes.map(i => i.subsubregion_id),
        ...selected.clients.map(i => i.subsubregion_id)
      ]),
      cities: _.uniq([
        ...selected.cities.map(i => i.id),
        ...selected.postal_codes.map(i => i.city_id),
        ...selected.clients.map(i => i.city_id)
      ]),
      postal_codes: _.uniq([
        ...selected.postal_codes.map(i => i.id),
        ...selected.clients.map(i => i.postal_code_id)
      ]),
      clients: _.uniq([...selected.clients.map(i => i.id)])
    };
  }

  static removeSelectedChildren(item, newSelected) {
    const result = { ...newSelected };

    const {
      postalCodeLevel,
      id,
      subregion_id,
      subsubregion_id,
      city_id,
      postal_code_id
    } = item;

    const removeSelections = childLevel => {
      if (childLevel === postalCodeLevel) return null;

      const parentIdPropName = ClientsTree.getIdPropName(postalCodeLevel);

      result[childLevel] = result[childLevel].filter(p => {
        return p[parentIdPropName] !== id;
      });
    };

    if (!postal_code_id) removeSelections('clients');
    if (!postal_code_id) removeSelections('postal_codes');
    if (!city_id) removeSelections('cities');
    if (!subsubregion_id) removeSelections('subsubregions');
    if (!subregion_id) removeSelections('subregions');

    return result;
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        regions: [],
        subregions: [],
        subsubregions: [],
        cities: [],
        postal_codes: [],
        clients: [],
        unrelated: undefined
      },
      selected: undefined,
      treeItemToDisplay: undefined,
      updateDialogOpenStatus: false,
      deleteDialogOpenStatus: false,
      itemToDelete: undefined,
      loading: false
    };

    this.getDataToRender = this.getDataToRender.bind(this);
    this.renderTreeView = this.renderTreeView.bind(this);
    this.isVisible = this.isVisible.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.renderDeleteItemBtn = this.renderDeleteItemBtn.bind(this);
    this.onItemDeleteDialogClose = this.onItemDeleteDialogClose.bind(this);
    this.onItemDeleteApprove = this.onItemDeleteApprove.bind(this);
  }

  componentDidMount() {
    this.fetchAssignClients();
    this.fetchUnrelatedClients();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  }

  onUpdateClick() {
    this.setState({ updateDialogOpenStatus: true });
  }

  onClose() {
    this.setState({
      updateDialogOpenStatus: false,
      treeItemToDisplay: undefined,
      selected: undefined
    });

    this.fetchAssignClients();
  }

  onItemDeleteClick(item) {
    this.setState({ deleteDialogOpenStatus: true, itemToDelete: item });
  }

  onItemDeleteDialogClose() {
    this.setState({
      deleteDialogOpenStatus: false,
      itemToDelete: undefined,
      treeItemToDisplay: undefined
    });
  }

  onItemDeleteApprove() {
    this.deleteItemFromUser(this.state.itemToDelete);
    this.onItemDeleteDialogClose();
  }

  onSelect(prevCheckedStatus, item) {
    const { postalCodeLevel, id } = item;

    this.setState(state => {
      let newSelected = { ...state.selected };

      if (prevCheckedStatus) {
        newSelected = this.removeSelectedParents(item, newSelected);

        newSelected[postalCodeLevel] = newSelected[postalCodeLevel].filter(
          i => i.id !== id
        );
      } else {
        newSelected[postalCodeLevel] = [...newSelected[postalCodeLevel], item];

        newSelected = ClientsTree.removeSelectedChildren(item, newSelected);
      }

      return { ...state, selected: newSelected };
    });
  }

  async onSubmit() {
    const payload = {
      ...this.props.fetchParams,
      regions: this.state.selected.regions.map(i => i.id),
      subregions: this.state.selected.subregions.map(i => i.id),
      subsubregions: this.state.selected.subsubregions.map(i => i.id),
      cities: this.state.selected.cities.map(i => i.id),
      postal_codes: this.state.selected.postal_codes.map(i => i.id),
      clients: this.state.selected.clients.map(i => i.id)
    };

    try {
      this.setState({ loading: true });

      const status = await ClientsUsersApi.assignManyClientsToUserByCodes(
        payload
      );

      if (status) this.onClose();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  async getDataToRender(postalCodeLevel, parentId, parentIdPropName) {
    const data = this.state.data[postalCodeLevel].find(
      obj => obj.parentId === parentId
    )?.data;

    if (!data) {
      return this.fetchData(postalCodeLevel, parentId, parentIdPropName);
    }

    return data;
  }

  removeSelectedParents(item, newSelected) {
    const result = { ...newSelected };

    const {
      region_id,
      subregion_id,
      subsubregion_id,
      city_id,
      postal_code_id
    } = item;

    const modifySelections = (parentLevel, childLevel) => {
      const parentIdPropName = ClientsTree.getIdPropName(parentLevel);

      const isSelected = Boolean(
        result[parentLevel].find(
          selected => selected.id === item[parentIdPropName]
        )
      );

      if (isSelected) {
        result[parentLevel] = result[parentLevel].filter(
          p => p.id !== item[parentIdPropName]
        );

        const children = this.state.data[childLevel].find(
          childItem => childItem.parentId === item[parentIdPropName]
        ).data;

        const formattedChildren = children.map(child => ({
          ...child,
          region_id,
          subregion_id,
          subsubregion_id,
          city_id,
          postal_code_id
        }));

        result[childLevel] = [...result[childLevel], ...formattedChildren];
      }
    };

    if (region_id) modifySelections('regions', 'subregions');
    if (subregion_id) modifySelections('subregions', 'subsubregions');
    if (subsubregion_id) modifySelections('subsubregions', 'cities');
    if (city_id) modifySelections('cities', 'postal_codes');
    if (postal_code_id) modifySelections('postal_codes', 'clients');

    return result;
  }

  isVisible(postalCodeLevel, id) {
    return this.state.treeItemToDisplay[postalCodeLevel].includes(id);
  }

  isSelected(postalCodeLevel, id) {
    return Boolean(
      this.state.selected[postalCodeLevel].find(selected => selected.id === id)
    );
  }

  async fetchData(postalCodeLevel, parentId, parentIdPropName) {
    try {
      const {
        data: { data, depth }
      } = await (() => {
        if (postalCodeLevel === 'regions')
          return ClientsUsersApi.getClientsFromRegions(this.props.fetchParams);
        if (postalCodeLevel === 'subregions')
          return ClientsUsersApi.getClientsFromSubregions({
            ...this.props.fetchParams,
            region_id: parentId
          });
        if (postalCodeLevel === 'subsubregions')
          return ClientsUsersApi.getClientsFromSubsubregions({
            ...this.props.fetchParams,
            [parentIdPropName]: parentId
          });
        if (postalCodeLevel === 'cities')
          return ClientsUsersApi.getClientsFromCities({
            ...this.props.fetchParams,
            [parentIdPropName]: parentId
          });
        if (postalCodeLevel === 'postal_codes')
          return ClientsUsersApi.getClientsFromPostalCodes({
            ...this.props.fetchParams,
            city_id: parentId
          });
        if (postalCodeLevel === 'clients')
          return ClientsUsersApi.getClientsFromClients({
            ...this.props.fetchParams,
            postal_code_id: parentId
          });
      })();

      this.setState(state => {
        let resultData = [];

        if (isArray(state.data[postalCodeLevel])) {
          resultData = [...state.data[postalCodeLevel]];
        }

        if (!isEmpty(data)) {
          resultData = [...resultData, { parentId, data }];
        }

        return {
          ...state,
          data: {
            ...state.data,
            [postalCodeLevel]: resultData
          },
          depth: depth ?? state.depth
        };
      });

      return data;
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchAssignClients() {
    try {
      const {
        data: { data: selected }
      } = await ClientsUsersApi.getClientsAssignToUser(this.props.fetchParams);

      const treeItemToDisplay = ClientsTree.setTreeItemsToDisplay(selected);

      this.setState({ selected, treeItemToDisplay });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchUnrelatedClients() {
    try {
      const {
        data: { data: unrelated }
      } = await ClientsUsersApi.getUnknownClients(this.props.fetchParams);

      this.setState(state => {
        return {
          ...state,
          data: {
            ...state.data,
            unrelated
          }
        };
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async deleteItemFromUser(item) {
    const payload = {
      ...this.props.fetchParams,
      [item.postalCodeLevel]: [item.id]
    };

    try {
      await ClientsUsersApi.deleteClientsFromUser(payload);
      await this.fetchAssignClients();
      await this.fetchUnrelatedClients();
    } catch (err) {
      this.handleError(err);
    }
  }

  renderTreeView(isUpdate) {
    const { unrelated } = this.state.data;

    return (
      <>
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          disableSelection
        >
          <ClientsTreeRegions
            getDataToRender={this.getDataToRender}
            isVisible={this.isVisible}
            isSelected={this.isSelected}
            selectMode={isUpdate}
            onSelect={this.onSelect}
            depth={this.state.depth}
            renderDeleteItemBtn={this.renderDeleteItemBtn}
          />
        </TreeView>
        <Box mt={1}>
          <Typography variant='h6'>
            {t('Unrelated clients divided into cities')}
          </Typography>
          <ClientsTreeUnrelated
            unrelated={unrelated}
            selectedClients={this.state.treeItemToDisplay.clients}
            isSelected={this.isSelected}
            selectMode={isUpdate}
            onSelect={this.onSelect}
            renderDeleteItemBtn={this.renderDeleteItemBtn}
          />
        </Box>
      </>
    );
  }

  renderDeleteItemBtn(item) {
    return (
      <IconButton
        className='delete-icon'
        size='small'
        onClick={() => this.onItemDeleteClick(item)}
        icon={<DeleteForeverIcon />}
        alt={t('remove assignment')}
      />
    );
  }

  render() {
    const {
      treeItemToDisplay,
      updateDialogOpenStatus,
      deleteDialogOpenStatus,
      loading
    } = this.state;

    if (!treeItemToDisplay) return <Loader />;

    return (
      <Box width={1} display='flex' flexDirection='column'>
        <Box
          width={1}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6'>
            {t('Clients divided into regions')}
          </Typography>
          <IconButton
            size='small'
            className='update-icon'
            onClick={this.onUpdateClick}
            icon={<EditIcon />}
            alt='update'
            disabled={this.props.disableAddBtnStatus}
            tooltipMsg={this.props.disableAddBtnMsg}
          />
        </Box>
        {this.renderTreeView()}
        {updateDialogOpenStatus && (
          <CustomDialog
            open
            onClose={() => this.onClose()}
            title={t('Assign clients to user')}
            actions={
              <Button
                color='primary'
                text={t('Save')}
                onClick={() => {
                  this.onSubmit();
                }}
                loading={loading}
              />
            }
          >
            {this.renderTreeView(true)}
          </CustomDialog>
        )}
        {deleteDialogOpenStatus && (
          <ClientsTreeDeleteDialog
            item={this.state.itemToDelete}
            onClose={this.onItemDeleteDialogClose}
            onApprove={this.onItemDeleteApprove}
          />
        )}
      </Box>
    );
  }
}

ClientsTree.defaultProps = {
  disableAddBtnStatus: false,
  disableAddBtnMsg: null
};

ClientsTree.propTypes = {
  fetchParams: PropTypes.shape({
    user_id: PropTypes.number.isRequired,
    department_id: PropTypes.number.isRequired,
    country_id: PropTypes.number.isRequired
  }).isRequired,
  disableAddBtnStatus: PropTypes.bool,
  disableAddBtnMsg: PropTypes.string
};

export default ClientsTree;
