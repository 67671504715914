import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  accordionRoot: {
    margin: theme.spacing(2, 0),
    position: 'unset'
  },
  accordionRounded: {
    borderRadius: '8px'
  },
  accordionSummaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'unset'
    }
  },
  accordionSummaryContent: {
    justifyContent: 'center',
    '&.Mui-expanded': {
      margin: theme.spacing(1.5, 0)
    }
  }
}));

export default useStyles;
