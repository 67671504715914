import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class UsersApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.usersPath = 'users';
    this.userPath = 'users/:id';
  }

  getUsers(pathParams) {
    return this.get(this.usersPath, pathParams);
  }

  getUser(id) {
    return this.get(insertPathParams(this.userPath, { id }));
  }

  createUser(payload) {
    return this.post(this.usersPath, payload);
  }

  updateUser(id, payload) {
    return this.put(insertPathParams(this.userPath, { id }), payload);
  }

  deleteUser(id) {
    return this.delete(insertPathParams(this.userPath, { id }));
  }
}

export default new UsersApi();
