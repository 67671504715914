import authRoutes from 'routing/routes/Auth';
import clientsRoutes from 'routing/routes/Clients';
import clientsContractsRoutes from 'routing/routes/ClientsContracts';
import clientsBonusesRoutes from 'routing/routes/ClientsBonuses';
import dashboardRoutes from 'routing/routes/Dashboard';
import departmentsRoutes from 'routing/routes/Departments';
import productCategoriesRoutes from 'routing/routes/ProductCategories';
import productsRoutes from 'routing/routes/Products';
import profileRoutes from 'routing/routes/Profile';
import usersRoutes from 'routing/routes/Users';
import wholesalersRoutes from 'routing/routes/Wholesalers';
import postalCodesRoutes from 'routing/routes/PostalCodes';
import salesRoutes from 'routing/routes/Sales';
import salesGroupsRoutes from 'routing/routes/ShoppingGroups';
import salesImportsRoutes from 'routing/routes/SalesImports';
import salesResultsRoutes from 'routing/routes/SalesResults';
import visitsRoutes from 'routing/routes/Visits';
import targetsRoutes from 'routing/routes/Targets';
import targetsBonusesRoutes from 'routing/routes/TargetsBonuses';
import taxesRoutes from 'routing/routes/Taxes';
import discountsRoutes from 'routing/routes/Discounts';
import ordersRoutes from 'routing/routes/Orders';
import statisticsRoutes from 'routing/routes/Statistics';
import promotionsRoutes from 'routing/routes/Promotions';
import knowledgeBaseRoutes from 'routing/routes/KnowledgeBase';
import affiliationRoutes from 'routing/routes/Affiliation';
import priceListsRoutes from 'routing/routes/PriceLists';
import currenciesRoutes from 'routing/routes/Currencies';
import importsRoutes from 'routing/routes/Imports';
import paymentMethodsRoutes from 'routing/routes/PaymentMethods';

export default [
  authRoutes,
  clientsRoutes,
  clientsContractsRoutes,
  clientsBonusesRoutes,
  dashboardRoutes,
  departmentsRoutes,
  productCategoriesRoutes,
  productsRoutes,
  profileRoutes,
  usersRoutes,
  wholesalersRoutes,
  postalCodesRoutes,
  salesRoutes,
  salesGroupsRoutes,
  salesImportsRoutes,
  salesResultsRoutes,
  visitsRoutes,
  targetsRoutes,
  targetsBonusesRoutes,
  taxesRoutes,
  discountsRoutes,
  ordersRoutes,
  statisticsRoutes,
  promotionsRoutes,
  knowledgeBaseRoutes,
  affiliationRoutes,
  priceListsRoutes,
  currenciesRoutes,
  importsRoutes,
  paymentMethodsRoutes
];
