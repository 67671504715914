import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import IconButton from 'modules/Layout/component/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_TARGETS_DETAILS } from 'routing/routes/Targets';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import renderPrice from 'modules/Products/utils/renderPrice';
import { VALUABLE_TARGET_TYPE } from 'modules/Targets/targetTypes';

class TargetBonusesTargetDataSets extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.getEstimatedBonusValue = this.getEstimatedBonusValue.bind(this);
    this.onTargetDetailsCLick = this.onTargetDetailsCLick.bind(this);
  }

  onTargetDetailsCLick() {
    const { id } = this.props.bonus.target;
    this.props.history.push(insertPathParams(ROUTE_TARGETS_DETAILS, { id }));
  }

  getEstimatedBonusValue() {
    const {
      target: { current_estimation_percent: percent },
      value_level_1,
      value_level_2,
      value_level_3,
      value_level_4
    } = this.props.bonus;

    if (percent >= 90 && percent < 100) return value_level_1;
    if (percent >= 100 && percent < 110) return value_level_2;
    if (percent >= 110 && percent < 120) return value_level_3;
    if (percent >= 120) return value_level_4;
  }

  render() {
    const {
      current_estimation_percent,
      current_update_date,
      name: targetName,
      result_percent,
      result_value,
      type
    } = this.props.bonus.target;
    const { currency } = this.props.bonus;

    return (
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Typography
            component='h6'
            variant='h6'
            style={{ marginBottom: '.5rem' }}
          >
            {t('Target')}
          </Typography>
          <TextDataSet label='Name' data={targetName} />
          <TextDataSet
            label='Target status'
            data={
              result_value || result_value === 0
                ? t('Confirmed')
                : t('Unconfirmed')
            }
          />
          {result_value || result_value === 0 ? (
            <>
              <TextDataSet
                label='Value of the goal achieved '
                data={
                  type === VALUABLE_TARGET_TYPE
                    ? renderPrice(result_value, currency)
                    : result_value
                }
              />
              <TextDataSet
                label='The goal was met on the level'
                data={`${result_percent}%`}
              />
            </>
          ) : (
            <Box mb={2}>
              <Typography
                component='h6'
                variant='h6'
                style={{ marginBottom: '.5rem', marginTop: '1rem' }}
              >
                {t('Goal achievement forecasts')}
              </Typography>

              {current_update_date ? (
                <>
                  <TextDataSet
                    label='Date of estimating the level of target achievement'
                    data={current_update_date}
                  />
                  <TextDataSet
                    label='Estimated target achievement level'
                    data={`${current_estimation_percent}%`}
                  />
                  <TextDataSet
                    label='Estimated bonus value'
                    data={renderPrice(this.getEstimatedBonusValue(), currency)}
                  />
                </>
              ) : (
                <Typography variant='body1'>
                  {t(
                    'The system has not yet set an estimated target achievement level'
                  )}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box display='flex' alignItems='flex-start'>
          <IconButton
            className='details-icon'
            onClick={this.onTargetDetailsCLick}
            icon={<SearchIcon />}
            alt='details'
          />
        </Box>
      </Box>
    );
  }
}

TargetBonusesTargetDataSets.propTypes = {
  bonus: PropTypes.shape({
    target: PropTypes.shape({
      id: PropTypes.number,
      current_estimation_percent: PropTypes.number,
      current_update_date: PropTypes.string,
      name: PropTypes.string,
      result_percent: PropTypes.number,
      result_value: PropTypes.number,
      type: PropTypes.number.isRequired
    }).isRequired,
    value_level_1: PropTypes.number,
    value_level_2: PropTypes.number,
    value_level_3: PropTypes.number,
    value_level_4: PropTypes.number,
    currency: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(TargetBonusesTargetDataSets);
