import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import WholesalerClientsMappingUpdateForm from 'modules/Wholesalers/component/Details/ClientsMapping/Update/Form';
import Loader from 'modules/Layout/component/Loader';

class WholesalerClientsMappingUpdate extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id: wholesalerId }
      },
      location: {
        state: { mapping }
      }
    } = props;

    this.wholesalerId = parseInt(wholesalerId, 10);
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      mapping
    };
  }

  async componentDidMount() {
    this.setCurrentPage('Update client mapping');
  }

  render() {
    const { mapping } = this.state;

    if (!mapping.id) return <Loader />;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <WholesalerClientsMappingUpdateForm
              wholesalerId={this.wholesalerId}
              wholesalerName={mapping.wholesaler_name}
              mapping={mapping}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalerClientsMappingUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({ mapping: PropTypes.shape({}) })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerClientsMappingUpdate);
