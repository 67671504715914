import PropTypes from 'prop-types';
import CustomDialog from 'modules/Layout/component/CustomDialog';

const PlanningModal = ({ children, open, onClose }) => (
  <CustomDialog open={open} onClose={onClose} title='Planowanie trasy'>
    {children}
  </CustomDialog>
);

PlanningModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PlanningModal;
