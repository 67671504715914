import { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from 'modules/Layout/view/Wrapper/style';
import { CssBaseline, Hidden } from '@material-ui/core';
import Header from 'modules/Layout/component/Header';
import Navigator from 'modules/Layout/component/Navigator';
import Alerts from 'modules/Layout/component/Alerts';
import clsx from 'clsx';

const Wrapper = ({ children }) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(true);

  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Alerts />
      <nav>
        <Hidden smUp implementation='js'>
          <Navigator
            variant='temporary'
            open={menuOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Navigator variant='persistent' open={menuOpen} />
        </Hidden>
      </nav>
      <div
        className={clsx(classes.app, {
          [classes.appShift]: menuOpen
        })}
      >
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>{children}</main>
      </div>
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default Wrapper;
