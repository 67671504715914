import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Input from 'modules/Layout/component/Input';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Loader from 'modules/Layout/component/Loader';
import Button from 'modules/Layout/component/Button';
import { Box, Typography } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { isArray } from 'lodash/lang';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import { ROUTE_TARGETS_LIST } from 'routing/routes/Targets';
import {
  getTargetOptionsAvailableForRole,
  QUANTITATIVE_TARGET_TYPE,
  VALUABLE_TARGET_TYPE,
  QUANTITATIVE_MANAGERS_TARGET_TYPE,
  VALUABLE_MANAGERS_TARGET_TYPE
} from 'modules/Targets/targetTypes';

import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import UsersApi from 'api/connections/Users/UsersApi';
import TargetsApi from 'api/connections/Targets/TargetsApi';

import formatUsersOptions from 'modules/Targets/formatters/formatUsersOptions';
import formatDepartmentsOptions from 'modules/Targets/formatters/formatDepartmentsOptions';
import formatUsersWithFullNames from 'modules/Targets/formatters/formatUsersWithFullNames';
import formatSpecificTargetTypeData from 'modules/Targets/formatters/formatSpecificTargetTypeData';

import ValuableTargetForm from 'modules/Targets/component/SpecificTargetTypesForms/Valuable';
import QuantitativeTargetForm from 'modules/Targets/component/SpecificTargetTypesForms/Quantitative';
import { DEPARTMENT_MANAGER } from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class TargetForm extends Component {
  static contextType = AuthContext;

  static getSelectedTargetType(target) {
    const { type, for_management } = target;

    if (for_management) {
      if (type === VALUABLE_MANAGERS_TARGET_TYPE) return VALUABLE_TARGET_TYPE;
      if (type === QUANTITATIVE_MANAGERS_TARGET_TYPE)
        return QUANTITATIVE_TARGET_TYPE;
    }

    return type;
  }

  static getInitDepartmentId(target, loggedUser) {
    if (target?.department_id) return target.department_id;

    if (loggedUser.role === DEPARTMENT_MANAGER) {
      return loggedUser.departments[0].id;
    }

    return null;
  }

  constructor(props, context) {
    super(props, context);

    const { target, categoriesObj } = props;

    this.loggedUser = context.user;

    this.state = {
      formData: {
        name: target?.name || '',
        type: target?.type || '',
        for_management: target?.for_management || '',
        department_id: TargetForm.getInitDepartmentId(target, this.loggedUser),
        user_id: target?.user_id || null,
        bonus_value_level_1: '',
        bonus_value_level_2: '',
        bonus_value_level_3: '',
        bonus_value_level_4: ''
      },
      specificTargetTypeData: target
        ? formatSpecificTargetTypeData(target.type, target, { categoriesObj })
        : {},
      selectedType: target ? TargetForm.getSelectedTargetType(target) : '',
      data: null,
      validation: {
        type: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Type')
          })
        },
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        },
        department_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Department')
          })
        },
        user_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('User')
          })
        },
        value: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Value')
          })
        },
        period_type: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Period')
          })
        },
        period_from: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Period')
          })
        },
        quarter: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Quarter')
          })
        },
        bonus_value_level_1: { status: false },
        bonus_value_level_2: { status: false },
        bonus_value_level_3: { status: false },
        bonus_value_level_4: { status: false }
      },
      loading: false
    };

    this.onChange = this.onChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.onSpecificTargetTypeChange = this.onSpecificTargetTypeChange.bind(
      this
    );
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.history.push(ROUTE_TARGETS_LIST);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        ...state,
        formData: {
          ...formData,
          user_id: name === 'for_management' ? null : formData.user_id,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onDepartmentChange(e) {
    this.setState(
      state => {
        const { formData, validation } = state;
        const { value } = e.target;

        return {
          ...state,
          formData: {
            ...formData,
            department_id: value
          },
          specificTargetTypeData: {
            ...state.specificTargetTypeData,
            categories: [],
            categoryGroups: [],
            subCategories: [],
            products: []
          },
          validation: {
            ...validation,
            department_id: {
              ...validation.department_id,
              status: false
            }
          }
        };
      },
      () => this.fetchUsers()
    );
  }

  onTypeChange(e) {
    this.setState(
      state => {
        const { formData, validation } = state;
        let { value } = e.target;

        let type = value;

        if (value === VALUABLE_MANAGERS_TARGET_TYPE) {
          type = VALUABLE_TARGET_TYPE;
        }
        if (value === QUANTITATIVE_MANAGERS_TARGET_TYPE) {
          type = QUANTITATIVE_TARGET_TYPE;
        }

        return {
          ...state,
          formData: {
            ...formData,
            type,
            for_management: [
              VALUABLE_MANAGERS_TARGET_TYPE,
              QUANTITATIVE_MANAGERS_TARGET_TYPE
            ].includes(value)
          },
          specificTargetTypeData: formatSpecificTargetTypeData(value),
          validation: {
            ...validation,
            type: {
              ...validation.type,
              status: false
            }
          },
          selectedType: value
        };
      },
      () => this.fetchUsers()
    );
  }

  onSpecificTargetTypeChange(e) {
    this.setState(state => {
      const { validation } = state;
      const { name, value } = e.target;

      return {
        ...state,
        specificTargetTypeData: {
          ...state.specificTargetTypeData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onPeriodChange(data) {
    const { period_type, period_from } = data;

    this.setState(state => {
      const { specificTargetTypeData, validation } = state;

      return {
        ...state,
        specificTargetTypeData: {
          ...specificTargetTypeData,
          period_type,
          period_from
        },
        validation: {
          ...validation,
          period_type: {
            ...validation.period_type,
            status: false
          },
          period_from: {
            ...validation.period_from,
            status: false
          }
        }
      };
    });
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    name === 'department_id'
      ? this.onDepartmentChange(event)
      : this.onChange(event);
  }

  getDepartmentData() {
    if (this.loggedUser.role === DEPARTMENT_MANAGER) {
      const { departments, country_id } = this.loggedUser;

      return {
        country_id,
        ...departments[0]
      };
    }

    const { department_id } = this.state.formData;
    const { departments } = this.state.data;

    if (department_id) {
      return departments.find(d => d.id === department_id);
    }

    return null;
  }

  async fetchData() {
    const { department_id } = this.state.formData;
    let departments = [];
    let users = [];

    try {
      if (this.loggedUser.role !== DEPARTMENT_MANAGER) {
        const {
          data: { data: departmentsData }
        } = await DepartmentsApi.getDepartments({
          per_page: Number.MAX_SAFE_INTEGER
        });

        departments = departmentsData;
      }

      if (department_id) {
        const {
          data: { data: usersData }
        } = await UsersApi.getUsers({
          per_page: Number.MAX_SAFE_INTEGER,
          department_id
        });

        users = formatUsersWithFullNames(usersData);
      }

      this.setState(state => ({
        ...state,
        data: {
          departments,
          users
        },
        loading: false
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchUsers() {
    const { department_id } = this.state.formData;

    if (!department_id) {
      return this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          users: []
        }
      }));
    }

    try {
      const {
        data: { data: users }
      } = await UsersApi.getUsers({
        per_page: Number.MAX_SAFE_INTEGER,
        department_id
      });

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          users: formatUsersWithFullNames(users)
        }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  runValidation() {
    /* eslint prefer-const: "off" */
    const { formData, specificTargetTypeData, validation } = this.state;
    let [isValid, newValidateState] = validate(
      { ...formData, ...specificTargetTypeData },
      validation
    );

    if (!specificTargetTypeData?.period_type) {
      newValidateState.period_type.status = true;
      isValid = false;
    }

    if (!specificTargetTypeData?.period_from) {
      newValidateState.period_from.status = true;
      isValid = false;
    }

    const {
      bonus_value_level_1,
      bonus_value_level_2,
      bonus_value_level_3,
      bonus_value_level_4
    } = formData;

    if (
      (bonus_value_level_2 || bonus_value_level_3 || bonus_value_level_4) &&
      !bonus_value_level_1
    ) {
      newValidateState.bonus_value_level_1.status = true;
      isValid = false;
    }

    if (
      (bonus_value_level_1 || bonus_value_level_3 || bonus_value_level_4) &&
      !bonus_value_level_2
    ) {
      newValidateState.bonus_value_level_2.status = true;
      isValid = false;
    }

    if (
      (bonus_value_level_1 || bonus_value_level_2 || bonus_value_level_4) &&
      !bonus_value_level_3
    ) {
      newValidateState.bonus_value_level_3.status = true;
      isValid = false;
    }

    if (
      (bonus_value_level_1 || bonus_value_level_2 || bonus_value_level_3) &&
      !bonus_value_level_4
    ) {
      newValidateState.bonus_value_level_4.status = true;
      isValid = false;
    }

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData: data, specificTargetTypeData } = this.state;
    const formData = {
      ...data,
      ...specificTargetTypeData
    };

    if (
      specificTargetTypeData.categoryGroups &&
      specificTargetTypeData.categories &&
      specificTargetTypeData.subCategories
    ) {
      formData.categories = [
        ...specificTargetTypeData.categoryGroups,
        ...specificTargetTypeData.categories,
        ...specificTargetTypeData.subCategories
      ];
    }

    this.setState({ loading: true });
    try {
      if (this.props.target) {
        await TargetsApi.updateTarget(this.props.target.id, formData);
      } else {
        await TargetsApi.createTarget(formData);
      }

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        name,
        type,
        department_id,
        user_id,
        for_management,
        bonus_value_level_1,
        bonus_value_level_2,
        bonus_value_level_3,
        bonus_value_level_4
      },
      selectedType,
      specificTargetTypeData,
      validation,
      data,
      loading
    } = this.state;

    const { target } = this.props;

    if (!data) return <Loader />;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        {!target && (
          <>
            <Input
              name='name'
              label='Name'
              value={name}
              required
              onChange={this.onChange}
              errorStatus={validation.name.status}
              errorText={validation.name.message}
            />
            <Select
              name='type'
              label='Type'
              value={selectedType}
              required
              options={formatOptions(
                getTargetOptionsAvailableForRole(this.loggedUser.role),
                'id',
                'name',
                true
              )}
              onChange={this.onTypeChange}
              errorStatus={validation.type.status}
              errorText={validation.type.message}
            />
            {this.loggedUser.role !== DEPARTMENT_MANAGER && (
              <Autocomplete
                name='department_id'
                label='Department'
                value={department_id}
                options={formatOptions(
                  formatDepartmentsOptions(data.departments, this.loggedUser),
                  'id',
                  'name'
                )}
                onChange={(_, v) =>
                  this.onAutocompleteChange('department_id', v)
                }
                required
                errorStatus={validation.department_id.status}
                errorText={validation.department_id.message}
                disabled={Boolean(this.targetId)}
              />
            )}
            <Autocomplete
              name='user_id'
              label='User'
              value={user_id}
              options={formatOptions(
                formatUsersOptions(data.users, for_management, this.loggedUser),
                'id',
                'full_name'
              )}
              onChange={(_, v) => this.onAutocompleteChange('user_id', v)}
              required
              errorStatus={validation.user_id.status}
              errorText={validation.user_id.message}
              disabled={!department_id}
              tooltipMsg='Select department first'
            />
          </>
        )}
        {type === VALUABLE_TARGET_TYPE && (
          <ValuableTargetForm
            onChange={this.onSpecificTargetTypeChange}
            onPeriodChange={this.onPeriodChange}
            validation={validation}
            handleError={this.handleError}
            user={data.users.find(u => u.id === user_id)}
            department={this.getDepartmentData()}
            specificTargetTypeData={specificTargetTypeData}
          />
        )}
        {type === QUANTITATIVE_TARGET_TYPE && (
          <QuantitativeTargetForm
            onChange={this.onSpecificTargetTypeChange}
            onPeriodChange={this.onPeriodChange}
            validation={validation}
            handleError={this.handleError}
            user={data.users.find(u => u.id === user_id)}
            department={this.getDepartmentData()}
            specificTargetTypeData={specificTargetTypeData}
          />
        )}
        {!target && (
          <>
            <Typography component='h6' variant='h6'>
              {t('Bonuses')}
            </Typography>
            <Box className='language-form-box'>
              <Input
                name='bonus_value_level_1'
                label='Bonus at 90%'
                value={bonus_value_level_1}
                type='number'
                onChange={this.onChange}
                errorStatus={validation.bonus_value_level_1.status}
                errorText={t('All bonus values are required')}
              />
              <Input
                name='bonus_value_level_2'
                label='Bonus at 100%'
                value={bonus_value_level_2}
                type='number'
                onChange={this.onChange}
                errorStatus={validation.bonus_value_level_2.status}
                errorText={t('All bonus values are required')}
              />
              <Input
                name='bonus_value_level_3'
                label='Bonus at 110%'
                value={bonus_value_level_3}
                type='number'
                onChange={this.onChange}
                errorStatus={validation.bonus_value_level_3.status}
                errorText={t('All bonus values are required')}
              />
              <Input
                name='bonus_value_level_4'
                label='Bonus at 120%'
                value={bonus_value_level_4}
                type='number'
                onChange={this.onChange}
                errorStatus={validation.bonus_value_level_4.status}
                errorText={t('All bonus values are required')}
              />
            </Box>
          </>
        )}
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button
            text={t('Cancel')}
            onClick={() => this.props.history.push(ROUTE_TARGETS_LIST)}
          />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

TargetForm.defaultProps = {
  target: null,
  categoriesObj: null
};

TargetForm.propTypes = {
  target: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired,
    department_id: PropTypes.number.isRequired,
    name: PropTypes.string,
    type: PropTypes.number.isRequired,
    for_management: PropTypes.bool.isRequired
  }),
  categoriesObj: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(TargetForm);
