import { Route } from 'react-router-dom';
import {
  CLIENTS_CONTRACTS_LIST,
  CLIENTS_CONTRACTS_CREATE,
  CLIENTS_CONTRACTS_UPDATE
} from 'api/auth/permissions/ClientsContracts';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import ClientsContractsList from 'modules/ClientsContracts/view/List';
import ClientsContractsDetails from 'modules/ClientsContracts/view/Details';
import ClientsContractsCreate from 'modules/ClientsContracts/view/Create';
import ClientsContractsUpdate from 'modules/ClientsContracts/view/Update';

export const ROUTE_CLIENTS_CONTRACTS_LIST = '/clients_contracts';
export const ROUTE_CLIENTS_CONTRACTS_DETAILS = '/clients_contracts/details/:id';
export const ROUTE_CLIENTS_CONTRACTS_CREATE = '/clients_contracts/create';
export const ROUTE_CLIENTS_CONTRACTS_UPDATE = '/clients_contracts/update/:id';

export const permMap = {
  [ROUTE_CLIENTS_CONTRACTS_LIST]: [CLIENTS_CONTRACTS_LIST],
  [ROUTE_CLIENTS_CONTRACTS_DETAILS]: [CLIENTS_CONTRACTS_LIST],
  [ROUTE_CLIENTS_CONTRACTS_CREATE]: [CLIENTS_CONTRACTS_CREATE],
  [ROUTE_CLIENTS_CONTRACTS_UPDATE]: [CLIENTS_CONTRACTS_UPDATE]
};

const routes = {
  protected: [
    <Route
      key='clients-contracts-list'
      exact
      path={ROUTE_CLIENTS_CONTRACTS_LIST}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_CONTRACTS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientsContractsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-contracts-details'
      exact
      path={ROUTE_CLIENTS_CONTRACTS_DETAILS}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_CLIENTS_CONTRACTS_DETAILS]}
      >
        <BasicContextConsumerWrapper
          component={fn => <ClientsContractsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-contracts-create'
      exact
      path={ROUTE_CLIENTS_CONTRACTS_CREATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_CONTRACTS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientsContractsCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='clients-contracts-update'
      exact
      path={ROUTE_CLIENTS_CONTRACTS_UPDATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_CLIENTS_CONTRACTS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ClientsContractsUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
