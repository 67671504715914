import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';

import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ClientsNotesApi from 'api/connections/Clients/ClientsNotesApi';

class ClientNoteCreateDialog extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.clientId = this.props.clientId;
    this.state = {
      formData: {
        content: ''
      },
      validation: {
        content: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Content')
          })
        }
      },
      loading: false
    };

    this.onSuccess = props.onSuccess;
    this.onClose = props.onClose;

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse(res) {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.onSuccess(res);
    this.onClose();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { validation } = state;
      const { value } = e.target;

      return {
        formData: {
          content: value
        },
        validation: {
          content: {
            ...validation.content,
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await ClientsNotesApi.createClientNote(this.clientId, formData);
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { content },
      validation,
      loading
    } = this.state;

    return (
      <Dialog
        open
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle id='form-dialog-title'>{t('Create note')}</DialogTitle>
        <DialogContent>
          <form id='create-note-form' noValidate onSubmit={this.onSubmit}>
            <Input
              name='content'
              label='Content'
              value={content}
              errorStatus={validation.content.status}
              errorText={validation.content.message}
              onChange={this.onChange}
              margin='none'
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.onClose} />
          <Button
            form='create-note-form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

ClientNoteCreateDialog.propTypes = {
  clientId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ClientNoteCreateDialog;
