import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import {
  ROUTE_WHOLESALERS_DETAILS,
  ROUTE_WHOLESALERS_LIST
} from 'routing/routes/Wholesalers';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import insertPathParams from 'api/utils/insertPathParams';
import { SUPER_ADMIN } from 'api/auth/roles';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import Checkbox from 'modules/Layout/component/Checkbox';
import ChipInput from 'modules/Layout/component/ChipInput';
import { formatWholesalersEmailDomainsError } from 'modules/Wholesalers/utils/formatter';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import debounce from 'lodash/debounce';

const MAX_AUTOCOMPLETE_RESULTS = 100;

class CreateWholesalersForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    const { setAlert } = props;
    const countryId = context.hasRole([SUPER_ADMIN])
      ? ''
      : context.user.country_id;

    this.setAlert = setAlert;

    this.state = {
      formData: {
        name: '',
        country_id: countryId,
        city: '',
        street: '',
        postal_code: '',
        phone: '',
        tax_number: '',
        main_contact_email: '',
        orders_emails: [],
        orders_email_same_as_main: false,
        emails: [],
        domains: [],
        provides_sales_data: true
      },
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Name') })
        },
        country_id: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Country') })
        },
        main_contact_email: {
          status: false,
          message: t('Wrong format')
        }
      },
      loading: false,
      cities: [],
      autocompleteLoading: false
    };

    this.setFetchDebounce = debounce(name => this.fetchCities(name), 500);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChipAdd = this.onChipAdd.bind(this);
    this.onChipDelete = this.onChipDelete.bind(this);
    this.onAutocompleteInputChange = this.onAutocompleteInputChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
  }

  handleResponse(res) {
    const { id } = res;

    this.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(
      insertPathParams(ROUTE_WHOLESALERS_DETAILS, { id })
    );
  }

  handleError(err) {
    const { errors, message } = err.getPayload();
    this.setAlert(message);
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      let emailDomainsValidState = {};

      const { emails, domains, orders_emails } = this.state.formData;
      emailDomainsValidState = formatWholesalersEmailDomainsError(errors, {
        emails,
        domains,
        orders_emails
      });

      this.setState(({ validation: validationState }) => {
        return {
          validation: {
            ...validationState,
            ...newValidateState,
            ...emailDomainsValidState
          }
        };
      });
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, type } = e.target;
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onChipAdd(name, chip) {
    this.setState(prevState => {
      const { formData } = prevState;

      return {
        formData: {
          ...formData,
          [name]: [...formData[name], chip]
        }
      };
    });
  }

  onChipDelete(name, chip) {
    this.setState(prevState => {
      const { formData, validation } = prevState;

      return {
        formData: {
          ...formData,
          [name]: formData[name].filter(data => data !== chip)
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onAutocompleteInputChange(e, newItem) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        city: newItem
      }
    }));
    this.setFetchDebounce(newItem);
  }

  onAutocompleteChange(e, newItem) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        city: newItem?.key
      }
    }));
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    const payload = {
      ...formData,
      emails: formData.provides_sales_data ? formData.emails : [],
      domains: formData.provides_sales_data ? formData.domains : []
    };

    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await WholesalersApi.createWholesaler(payload);
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  async fetchCities(name = '') {
    const {
      formData: { country_id }
    } = this.state;
    this.setState({ autocompleteLoading: true });
    try {
      const {
        data: { data: cities }
      } = await PostalCodesApi.getCities({
        country_id,
        name,
        per_page: MAX_AUTOCOMPLETE_RESULTS
      });
      this.setState({ cities, autocompleteLoading: false });
    } catch (err) {
      this.setState({ autocompleteLoading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        name,
        country_id,
        street,
        postal_code,
        phone,
        tax_number,
        main_contact_email,
        orders_emails,
        orders_email_same_as_main,
        emails,
        domains,
        provides_sales_data
      },
      validation,
      loading,
      cities,
      autocompleteLoading
    } = this.state;
    const { countries } = this.props;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='name'
          label='Name'
          value={name}
          required
          multiline
          autoFocus
          inputProps={{ maxLength: 500 }}
          onChange={this.onChange}
          errorStatus={validation.name.status}
          errorText={validation.name.message}
        />
        {this.context.hasRole([SUPER_ADMIN]) && (
          <Select
            name='country_id'
            label='Country'
            value={country_id}
            required
            options={formatOptions(countries, 'id', 'name')}
            onChange={this.onChange}
            errorStatus={validation.country_id.status}
            errorText={validation.country_id.message}
          />
        )}
        <Autocomplete
          name='city'
          label='City'
          onChange={this.onAutocompleteChange}
          onInputChange={this.onAutocompleteInputChange}
          options={formatOptions(cities, 'name', 'name')}
          disabled={country_id === ''}
          loading={autocompleteLoading}
          freeSolo
        />
        <Input
          name='street'
          label='Street'
          value={street}
          onChange={this.onChange}
        />
        <Input
          name='postal_code'
          label='Postal code'
          value={postal_code}
          onChange={this.onChange}
        />
        <Input
          name='phone'
          label='Phone number'
          value={phone}
          onChange={this.onChange}
        />
        <Input
          name='tax_number'
          label='Tax number'
          value={tax_number}
          onChange={this.onChange}
          errorStatus={validation.tax_number?.status}
          errorText={validation.tax_number?.message}
        />
        <Input
          name='main_contact_email'
          label='Main contact email'
          type='email'
          value={main_contact_email}
          onChange={this.onChange}
          errorStatus={validation.main_contact_email.status}
          errorText={validation.main_contact_email.message}
        />
        <Checkbox
          name='orders_email_same_as_main'
          label='Orders email same as main'
          onChange={this.onChange}
          checked={orders_email_same_as_main}
        />
        {!orders_email_same_as_main && (
          <Box mb={validation.orders_emails?.status ? 2 : 0}>
            <ChipInput
              name='orders_emails'
              label='Orders email'
              value={orders_emails}
              onAdd={this.onChipAdd}
              onDelete={this.onChipDelete}
              errorStatus={validation.orders_emails?.status}
              errorText={validation.orders_emails?.message}
            />
          </Box>
        )}
        <Checkbox
          name='provides_sales_data'
          label='The warehouse provides sales data'
          onChange={this.onChange}
          checked={provides_sales_data}
        />
        {provides_sales_data && (
          <>
            <Box mb={validation.emails?.status ? 2 : 0}>
              <ChipInput
                name='emails'
                label='Import emails'
                value={emails}
                onAdd={this.onChipAdd}
                onDelete={this.onChipDelete}
                errorStatus={validation.emails?.status}
                errorText={validation.emails?.message}
              />
            </Box>
            <ChipInput
              name='domains'
              label='Import domains'
              value={domains}
              onAdd={this.onChipAdd}
              onDelete={this.onChipDelete}
              errorStatus={validation.domains?.status}
              errorText={validation.domains?.message}
            />
          </>
        )}
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link to={ROUTE_WHOLESALERS_LIST} className='router-button'>
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

CreateWholesalersForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(CreateWholesalersForm);
