import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import SalesGroupsFilesApi from 'api/connections/SalesGroups/SalesGroupsFilesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const SalesGroupFileDeleteDialog = props => {
  const {
    file: { id: fileId, name: fileName },
    groupId,
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteFile = async () => {
    try {
      await SalesGroupsFilesApi.deleteSalesGroupFile({
        groupId,
        fileId
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess(fileId);
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete file <%=fileName%>', {
        fileName
      })}
      onApprove={deleteFile}
      onCancel={onClose}
    />
  );
};

SalesGroupFileDeleteDialog.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  groupId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default SalesGroupFileDeleteDialog;
