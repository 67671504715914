import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ApiError from 'api/exceptions/ApiError';
import Loader from 'modules/Layout/component/Loader';
import Table from 'modules/Layout/component/List/Table';
import t from 'translate/translate';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import IconButton from 'modules/Layout/component/IconButton';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import Authorize from 'modules/Auth/component/Authorize';
import OrdersApi from 'api/connections/Orders/OrdersApi';
import ordersStatuses from 'modules/Orders/ordersStatuses';
import { ORDERS_CREATE } from 'api/auth/permissions/Orders';
import ClientDetailsOrderFilter from 'modules/Clients/component/Details/Orders/Filter';
import {
  ROUTE_ORDERS_CREATE,
  ROUTE_ORDERS_DETAILS
} from 'routing/routes/Orders';
import insertPathParams from 'api/utils/insertPathParams';

class VisitDetailsOrders extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.visitId = parseInt(props.match.params.id, 10);
    this.clientId = props.clientId;

    this.state = {
      orders: null,
      filter: {},
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      loading: false
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onCreateClick() {
    const path = `${ROUTE_ORDERS_CREATE}?client_id=${this.clientId}&visit_id=${this.visitId}`;

    this.props.history.push(path);
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_ORDERS_DETAILS, { id }));
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchData()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchData()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchData()
    );
  }

  getOrdersTableCols() {
    return [
      {
        property: 'status',
        label: 'Status',
        sortable: false,
        render: status => t(ordersStatuses.find(o => o.status === status).name)
      },
      {
        property: 'total_price',
        label: 'Price',
        sortable: false
      },
      {
        property: 'wholesaler_name',
        label: 'Wholesaler name',
        sortable: true
      },
      {
        property: 'user_name',
        label: 'User name',
        sortable: true
      },
      {
        property: 'order_date',
        label: 'Order date',
        sortable: true
      },
      {
        property: 'is_direct',
        label: 'Direct order',
        sortable: false,
        render: status => (status ? <CheckIcon color='primary' /> : null)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          }
        }
      }
    ];
  }

  async fetchData() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    this.setState({ loading: true });

    try {
      const {
        data: { data: orders, meta }
      } = await OrdersApi.getOrders({
        visit_id: this.visitId,
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        orders,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        this.context.setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const {
      orders,
      loading,
      pagination,
      filter,
      filterValidation,
      sort
    } = this.state;

    if (!orders) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <ClientDetailsOrderFilter
            filter={filter}
            validation={filterValidation}
            setFilter={this.setFilter}
          />
          <Table
            cols={this.getOrdersTableCols()}
            rows={orders}
            sort={sort}
            setSort={this.setSort}
            actions
            dense
            loading={loading}
          />
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <Box display='flex'>
              <Authorize permissions={[ORDERS_CREATE]}>
                <IconButton
                  onClick={this.onCreateClick}
                  icon={<AddIcon color='primary' fontSize='large' />}
                  alt='create'
                />
              </Authorize>
            </Box>
            <Pagination
              pagination={pagination}
              setPagination={this.setPagination}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

VisitDetailsOrders.propTypes = {
  clientId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(VisitDetailsOrders);
