export default {
  Orders: 'Zamówienia',
  'Direct order': 'Bezpośrednie zamówienie',
  'Order date': 'Data zamówienia',
  preparing: 'przygotowanie',
  'sent for processing': 'wysłane do realizacji',
  'accepted for processing': 'zaakceptowane do realizacji',
  denied: 'anulowane',
  processing: 'realizowane',
  processed: 'zrealizowane',
  'processed partially': 'częściowo zrealizowane',
  'Create order': 'Dodawanie zamówienia',
  'Client address': 'Adres klienta',
  'change client': 'zmiana klienta',
  'Change client': 'Zmiana klienta',
  'Adding product': 'Dodawanie produktu',
  Amount: 'Ilość',
  'Select product first': 'Wybierz najpierw produkt',
  'Field Quantity is required, should be more than 0 and less than <%=maxQuantity%>':
    'Pole Ilość jest wymagane, powinno być większe niż 0 oraz mniejsze niż <%=maxQuantity%>',
  'Limit product list (optional)': 'Ogranicz listę produktów (opcjonalnie)',
  'Summary price': 'Cena sumaryczna',
  'Select client first': 'Wybierz najpierw klienta',
  'Client have no discounts': 'Klient nie posiada rabatów',
  'Client have no promotions': 'Klient nie posiada promocji',
  Configuration: 'Konfiguracja',
  Summary: 'Podsumowanie',
  'Total value without discounts':
    'Sumaryczna wartość przed uwzględnieniem rabatów',
  'Total value with discounts': 'Sumaryczna wartość po uwzględnieniu rabatów',
  'Net order value': 'Wartość zamówienia netto',
  'Gross order value': 'Wartość zamówienia brutto',
  'Payment date discount': 'Rabat od terminu płatności',
  'Selected client have no payment date discounts':
    'Wybrany klient nie ma żadnych rabatów od terminu płatności',
  'Payment method and date': 'Forma i termin płatności',
  'Payment date': 'Termin płatności',
  Transfer: 'Przelew',
  transfer: 'przelew',
  Cash: 'Gotówka',
  cash: 'gotówka',
  'Order type': 'Rodzaj zamówienia',
  Direct: 'Bezpośrednie',
  Wholesaler: 'Hurtownia',
  'Wholesaler order type is selected, make sure you can ad selected discount.':
    'Zamówienie składane jest do hurtowni, upewnij się że możesz naliczyć wybrany rabat dla klienta.',
  'Discounts available only on direct order.':
    'Rabaty dostępne tylko przy zamówieniach bezpośrednich.',
  'Order details': 'Szczegóły zamówienia',
  Purchaser: 'Zamawiający',
  'Order status': 'Status zamówienia',
  'Order prepared by <%=user_name%>': 'Zamówienie przygotował <%=user_name%>',
  'Order prepared at': 'Data przygotowania',
  'Order sended at': 'Data wysłania',
  'Status history': 'Historia statusów',
  'Changed by': 'Zmieniony przez',
  'Changed at': 'Data zmiany',
  'discount of <%=percentage%> was applied.':
    'rabat o wartości <%=percentage%> został nałożony.',
  'discount of <%=percentage%> was applied after reaching threshold of <%=threshold%>.':
    'rabat o wartości <%=percentage%> został nałożony po osiągnięciu progu <%=threshold%>.',
  'No discount will apply to order':
    'Na zamówienie nie obowiązuje żadna zniżka',
  'Payment type': 'Forma płatności',
  'The order was prepared on <%=date%>. At that time, the order value was <%=previousPrice%> <%=currency%>. Currently, the order value is <%=currentPrice%> <%=currency%>. This is due to the change in discounts, promotions and product prices. Please make sure the current price is acceptable before shipping your order.':
    'Zamówienie było szykowane dnia <%=date%>. Wówczas wartość zamówienia wynosiła <%=previousPrice%> <%=currency%>. Aktualnie wartość zamówienia wynosi <%=currentPrice%> <%=currency%>. Wynika to ze zmiany rabatów, promocji i ceny produktów. Przed wysłaniem zamówienia upewnij się, że aktualna cena jest akceptowalna.',
  'Order cannot be deleted': 'Zamówienie nie może być usunięte',
  'Order cannot be updated': 'Zamówienie nie może być edytowane',
  'Are you sure you want to delete order for client <%=client%>?':
    'Czy napewno chcesz usunąć zamówienia dla klienta <%=client%>?',
  'Update order': 'Edycja zamówienia',
  'Update product quantity': 'Zmień ilość produktu',
  'Save and send': 'Zapisz i wyślij',
  'Send copy of order to client': 'Wyślij kopie zamówienia do klienta',
  'Confirm sending an order': 'Potwierdź wysłanie zamówienia',
  'change status': 'Zmień status',
  'Change order status': 'Zmień status zamówienia',
  'Status of order cannot be changed':
    'Status zamówienia nie może być zmieniony',
  'Click on the add button to set a visit.':
    'Kliknij przycisk dodawania aby dodać wizytę.',
  'Click on the add button to add promotion.':
    'Kliknij przycisk dodawania aby dodać promocje.',
  'Click on the add button to add product.':
    'Kliknij przycisk dodawania aby dodać produkt.',
  'Click on the add button to define promotion.':
    'Kliknij przycisk aby zdefiniować promocje.',
  'add visit': 'dodaj wizytę',
  'change visit': 'zmień wizytę',
  'delete promotion': 'usuń promocje',
  'Client dont have any visits.': 'Klient nie posiada żadnej wizyty.',
  'Set Visit': 'Wybierz wizytę',
  'Percentage promotions': 'Promocje procentowe',
  'Select promotion': 'Wybierz promocje',
  'Order have no promotions': 'Zamówienia nie ma żadnych promocji',
  'Group promotions': 'Promocje grupowe',
  'add promotion': 'dodaj promocje',
  'Total amount of products': 'Łączna ilość produktów',
  'You need exactly <%=amount%> products to meet the promotion conditions.':
    'Aby spełnić warunki promocji potrzeba dokładnie <%=amount%> produktów.',
  'To qualify for the promotion you need at least <%=amount%> products.':
    'Aby spełnić warunki promocji potrzeba co najmniej <%=amount%> produktów.',
  'The same one products must be ordered.': 'Należy zamówić ten sam produkt.',
  'Free product: <%=sku%>': 'Darmowy produkt: <%=sku%>',
  'Click to add promotion.': 'Kliknij by dodać promocje.',
  'Click to define promotion.': 'Kliknij by zdefiniować promocje.',
  'Promotions define in order': 'Promocje definiowane w zamówieniu',
  'Free product': 'Darmowy produkt',
  'No product selected': 'Nie wybrano produktu',
  'Define percentage promotion': 'Zdefiniuj promocję procentową',
  Comment: 'Komentarz',
  'define promotion': 'zdefiniuj promocje',
  'Select the products covered by the promotion and then enter the value of the promotion.':
    'Wybierz produkty objętę promocją a następnie podaj wartość promocji.',
  'At least one product must be selected':
    'Nalezy wybrać przynajmniej jeden produkt',
  'Select all list items': 'Zaznacz wszystkie elementy listy',
  'Max batches count is <%=count%>': 'Stan magazynowy wynosi <%=count%>',
  'Quantity is required': 'Ilość jest wymagana',
  'Discounts and promotions': 'Rabaty i promocje',
  'It is not possible to change the type of order to a wholesaler when products are selected.':
    'Zmiana typu zamówienia na zamówienie do hurtowni nie jest mozliwa w momencie gdy wybrane są produkty.',
  'The product cannot be selected because the batch count is 0 or price is undefined.':
    'Nie można wybrać produktu ponieważ stan magazynowy wynosi 0 lub cena jest nieznana.',
  'Product promotions': 'Promocje dla produktu',
  'Click on the button to copy products from last order.':
    'Kliknij przycisk aby skopiować produkty z ostatniej wizyty.',
  'copy products': 'kopiuj produkty',
  'Copy products from last order': 'Kopiowanie produktów z ostatniej wizyty',
  'The order has no products to copy.':
    'Zamówienie nie posiada produktów do skopiowania.',
  'The product batch count is 0.': 'Stan magazynowy wynosi 0.',
  'The product only available on direct order.':
    'Produkt dostępny tylko w zamówieniach bezpośrednich.',
  'The order type must be selected. If there are no types to choose from, the order cannot be saved.':
    'Typ zamówienia musi zostać wybrany. Jeśli nie ma typów do wyboru to zamówienie nie może być zapisane.',
  'The product is unavailable in promotions':
    'Produkt jest niedostępny w promocjach',
  'This promotion includes a product that is not available in the promotions':
    'W tej promocji znajduje się produkt który jest niedostępny w promocjach',
  'Order value without promotions and discounts':
    'Wartość zamówienia bez promocji i rabatów',
  'Promotions and discounts value': 'Wartość promocji i rabatów',
  'Gratis products before discount':
    'Wartość produktów gratisowych przed rabatem',
  'Total order value': 'Całkowita wartość zamówienia',
  'Order cannot be saved because of invalid fields':
    'Nie można zapisać zamówienia z powodu nieprawidłowych pól'
};
