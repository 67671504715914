import parse from 'date-fns/parse';
import format from 'date-fns/format';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/DatePicker';
import { API_DATE_TIME_FORMAT } from 'modules/Layout/component/Date/DateTimePicker';
import addBusinessDays from 'date-fns/addBusinessDays';
import setHours from 'date-fns/setHours';
import getHours from 'date-fns/getHours';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';

const HOURS_WORK = 8;
const BEGIN_OF_WORK = 9;

const getVisitTime = (visits, visit, index, shift = -1) => {
  const visitToCheck = visits[index + shift];

  if (visitToCheck) {
    return visitToCheck.scheduled_at || visitToCheck.new_scheduled_at;
  }

  return visit.scheduled_at || visit.new_scheduled_at;
};

export default (visits, days) => {
  const { date, endDate } = days;
  const numberOfVisits = visits.length;
  const numberOfDays =
    differenceInBusinessDays(
      parse(endDate, API_DATE_FORMAT, new Date()),
      parse(date, API_DATE_FORMAT, new Date())
    ) + 1;
  const visitsInterval =
    (HOURS_WORK / Math.ceil(numberOfVisits / numberOfDays)) * 60;

  let beginningTime = setHours(
    parse(date, API_DATE_FORMAT, new Date()),
    BEGIN_OF_WORK
  );

  return visits.map((visit, index) => {
    if (index) {
      beginningTime = addMinutes(beginningTime, visitsInterval);
    }

    if (
      visits[index - 1]?.scheduled_at &&
      visits[index + 1]?.scheduled_at &&
      visit.new_scheduled_at
    ) {
      const firstVisitTime = parse(
        visits[index - 1].scheduled_at,
        API_DATE_TIME_FORMAT,
        new Date()
      );
      const secondVisitTime = parse(
        visits[index + 1].scheduled_at,
        API_DATE_TIME_FORMAT,
        new Date()
      );
      const differenceBetweenVisits = differenceInMinutes(
        secondVisitTime,
        firstVisitTime
      );
      beginningTime = addMinutes(
        firstVisitTime,
        Math.floor(differenceBetweenVisits / 2)
      );
    }

    if (
      visits[index + 1]?.scheduled_at <= visit.new_scheduled_at ||
      visits[index + 1]?.new_scheduled_at <= visit.new_scheduled_at
    ) {
      beginningTime = parse(
        getVisitTime(visits, visit, index, 1),
        API_DATE_TIME_FORMAT,
        new Date()
      );
    }

    if (getHours(beginningTime) >= 17) {
      beginningTime = addBusinessDays(setHours(beginningTime, 9), 1);
    }

    return visit.scheduled_at
      ? visit
      : {
          ...visit,
          new_scheduled_at: format(beginningTime, API_DATE_TIME_FORMAT)
        };
  });
};
