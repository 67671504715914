import { useEffect, useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CreateVisitForm from 'modules/Visits/components/CreateVisitForm';
import { Box, Paper } from '@material-ui/core';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import ApiError from 'api/exceptions/ApiError';
import authContext from 'modules/Auth/context/Auth/authContext';
import { ADMIN, SUPER_ADMIN } from 'api/auth/roles';
import format from 'date-fns/format';
import { API_DATE_TIME_FORMAT } from 'modules/Layout/component/Date/DateTimePicker';

export const CreateVisitContext = createContext({});

const CreateVisitView = props => {
  const { setAlert, setCurrentPage } = props.contextMethods;
  const { user, hasRole } = useContext(authContext);
  const { state } = useLocation();
  const countryId = !hasRole([SUPER_ADMIN]) ? user.country_id : '';

  const [clients, setClients] = useState([]);
  const [notVisitedClients, setNotVisitedClients] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchClients = async (options = {}) => {
    setLoading(true);
    try {
      if (state?.client) {
        const {
          data: { data: fetchedClient }
        } = await ClientsApi.getClient(state.client.client_id);
        setClients([fetchedClient]);
      } else {
        const [
          {
            data: { data: fetchedClients }
          },
          {
            data: { data: fetchedNotVisitedClients }
          }
        ] = await Promise.all([
          ClientsApi.getClients({
            country_id: countryId,
            my_clients: !hasRole([SUPER_ADMIN, ADMIN]),
            per_page: Number.MAX_SAFE_INTEGER,
            ...options
          }),
          ClientsApi.getNotVisitedClients({
            country_id: countryId,
            my_clients: !hasRole([SUPER_ADMIN, ADMIN]),
            per_page: Number.MAX_SAFE_INTEGER,
            ...options
          })
        ]);
        setClients(fetchedClients);
        setNotVisitedClients(
          fetchedNotVisitedClients.map(client => ({
            ...client,
            iconStatus: client.latest_visit_date
          }))
        );
      }
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage('Create visit');
    fetchClients();
  }, []);

  const formattedDate = () =>
    format(state.date.fullDate, API_DATE_TIME_FORMAT, new Date());

  return (
    <CreateVisitContext.Provider
      value={{ clients, notVisitedClients, loading }}
    >
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <CreateVisitForm
              setAlert={setAlert}
              clients={clients}
              notVisitedClients={notVisitedClients}
              initDate={state?.date ? formattedDate() : ''}
              state={state}
            />
          </Paper>
        </Box>
      </Box>
    </CreateVisitContext.Provider>
  );
};

CreateVisitView.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default CreateVisitView;
