import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import t from 'translate/translate';
import Table from 'modules/Layout/component/List/Table';
import targetBonusesTypes from 'modules/TargetsBonuses/targetBonusesTypes';
import {
  SUPER_ADMIN,
  ADMIN,
  DIRECTOR,
  DEPARTMENT_MANAGER
} from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_TARGETS_BONUSES_DETAILS } from 'routing/routes/TargetsBonuses';
import renderPrice from 'modules/Products/utils/renderPrice';

class TargetsBonusesTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.onDetailsClick = this.onDetailsClick.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_TARGETS_BONUSES_DETAILS, { id })
    );
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'user_name',
        label: 'User name',
        sortable: true,
        displayStatus: this.context.hasRole([
          SUPER_ADMIN,
          ADMIN,
          DIRECTOR,
          DEPARTMENT_MANAGER
        ])
      },
      {
        property: 'status',
        label: 'Status',
        sortable: true,
        render: value =>
          t(targetBonusesTypes.find(type => type.id === value).name)
      },
      {
        property: 'final_value',
        label: 'Value',
        sortable: true,
        nullable: true,
        render: (final_value, { currency }) =>
          renderPrice(final_value, currency)
      },
      {
        property: 'period_to',
        label: 'Date to',
        sortable: true
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          }
        }
      }
    ];
  }

  render() {
    const { targetsBonuses, sort, setSort, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={targetsBonuses}
        sort={sort}
        setSort={setSort}
        loading={loading}
        actions
      />
    );
  }
}

TargetsBonusesTable.defaultProps = {
  targetsBonuses: []
};

TargetsBonusesTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  targetsBonuses: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(TargetsBonusesTable);
