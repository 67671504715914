import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import { SUPER_ADMIN } from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_SHOPPING_GROUPS_DETAILS,
  ROUTE_SHOPPING_GROUPS_UPDATE
} from 'routing/routes/ShoppingGroups';
import {
  SHOPPING_GROUPS_DELETE,
  SHOPPING_GROUPS_UPDATE
} from 'api/auth/permissions/SalesGroups';
import DeleteSalesGroupDialog from 'modules/SalesGroups/component/Delete/Dialog';

class SalesGroupsTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
      salesGroupDelete: null
    };

    this.renderCountry = this.renderCountry.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_SHOPPING_GROUPS_DETAILS, { id })
    );
  }

  onUpdateClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_SHOPPING_GROUPS_UPDATE, { id })
    );
  }

  onDeleteClick(salesGroup) {
    this.setState({ deleteDialogOpen: true, salesGroupDelete: salesGroup });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, salesGroupDelete: null });
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'description',
        label: 'Description',
        sortable: false
      },
      {
        property: 'country_id',
        label: 'Country',
        sortable: true,
        render: this.renderCountry,
        displayStatus: this.context.hasRole([SUPER_ADMIN])
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([SHOPPING_GROUPS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([SHOPPING_GROUPS_DELETE]),
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  renderCountry(id) {
    const { countries } = this.props;

    return id ? countries.find(item => item.id === id)?.name : null;
  }

  render() {
    const {
      salesGroups,
      sort,
      setSort,
      loading,
      onDeleteSalesGroupSuccess
    } = this.props;
    const { deleteDialogOpen, salesGroupDelete } = this.state;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={salesGroups}
          sort={sort}
          setSort={setSort}
          loading={loading}
          actions
        />
        {deleteDialogOpen && (
          <DeleteSalesGroupDialog
            salesGroup={salesGroupDelete}
            onClose={this.onDeleteDialogClose}
            onSuccess={onDeleteSalesGroupSuccess}
          />
        )}
      </>
    );
  }
}

SalesGroupsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  salesGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDeleteSalesGroupSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(SalesGroupsTable);
