import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import t from 'translate/translate';
import PieChart from 'modules/Layout/component/Charts/PieChart';
import BarChart from 'modules/Layout/component/Charts/BarChart';
import ResultsTable from 'modules/SalesResults/component/ResultsDetails/Table';
import { useHistory } from 'react-router-dom';
import { useResize } from 'modules/Shared/hook';

const SalesResultsCharts = props => {
  const {
    salesResultsData,
    viewType,
    quantityOrValue,
    onDrillDown,
    nameSuffix
  } = props;
  const { location } = useHistory();
  const isComparison = Boolean(location.pathname.search('comparison') > 0);

  const componentRef = useRef();
  const { width } = useResize(componentRef);

  return (
    <Box
      display='flex'
      flexDirection={{ xs: 'column', lg: isComparison ? 'column' : 'row' }}
      justifyContent='space-around'
      ref={componentRef}
    >
      {viewType === 'pie' && (
        <>
          {(!quantityOrValue || quantityOrValue === 'quantity') && (
            <PieChart
              data={salesResultsData}
              valuePropertyName='quantity_sum'
              title={t('Number of products sold')}
              outerRadius={isComparison ? width / 2 : 200}
              innerRadius={15}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix}
            />
          )}
          {(!quantityOrValue || quantityOrValue === 'value') && (
            <PieChart
              data={salesResultsData}
              valuePropertyName='price_sum'
              title={t('Value of products sold')}
              outerRadius={isComparison ? width / 2 : 200}
              innerRadius={15}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix}
            />
          )}
        </>
      )}
      {viewType === 'bar' && (
        <>
          {(!quantityOrValue || quantityOrValue === 'quantity') && (
            <BarChart
              data={salesResultsData}
              valuePropertyName='quantity_sum'
              title={t('Number of products sold')}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix}
              width={isComparison ? width - 20 : 400}
            />
          )}
          {(!quantityOrValue || quantityOrValue === 'value') && (
            <BarChart
              data={salesResultsData}
              valuePropertyName='price_sum'
              title={t('Value of products sold')}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix}
              width={isComparison ? width - 20 : 400}
            />
          )}
        </>
      )}
      {viewType === 'table' && (
        <ResultsTable
          data={salesResultsData}
          onDrillDown={data => onDrillDown(data)}
          nameSuffix={nameSuffix}
        />
      )}
    </Box>
  );
};

SalesResultsCharts.propTypes = {
  salesResultsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  viewType: PropTypes.string.isRequired,
  quantityOrValue: PropTypes.string.isRequired,
  onDrillDown: PropTypes.func.isRequired,
  nameSuffix: PropTypes.number.isRequired
};

export default SalesResultsCharts;
