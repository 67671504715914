import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import * as d3 from 'd3';

class SalesResultsTable extends Component {
  static removeLegend() {
    d3.selectAll('.legend-container').select('g').remove();
  }

  static getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'quantity_sum',
        label: 'Quantity',
        sortable: true
      },
      {
        property: 'price_sum',
        label: 'Value',
        sortable: true,
        render: value => `${value} PLN`
      }
    ];
  }

  constructor(props) {
    super(props);

    this.state = {
      sort: {
        sort_field: 'name',
        sort_order: 'asc'
      }
    };

    this.onSelect = this.onSelect.bind(this);
    this.setSort = this.setSort.bind(this);
  }

  componentDidMount() {
    SalesResultsTable.removeLegend();
  }

  onSelect(id) {
    const { data, onDrillDown } = this.props;

    const children = data.find(d => d.id === id);

    onDrillDown(children);
  }

  setSort(sort) {
    this.setState({ sort });
  }

  sortData() {
    const { data } = this.props;

    const { sort_field, sort_order } = this.state.sort;

    return data.sort((a, b) => {
      if (sort_order === 'asc') {
        return a[sort_field] < b[sort_field] ? -1 : 0;
      }

      return a[sort_field] > b[sort_field] ? -1 : 0;
    });
  }

  render() {
    const { sort } = this.state;
    const data = this.sortData();

    return (
      <Table
        cols={SalesResultsTable.getCols()}
        rows={data}
        sort={sort}
        setSort={this.setSort}
        hover
        onSelect={id => this.onSelect(id)}
      />
    );
  }
}

SalesResultsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDrillDown: PropTypes.func.isRequired
};

export default SalesResultsTable;
