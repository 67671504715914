import { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import { formatOptions } from 'modules/Layout/component/Select';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import {
  prepareCategories,
  addParentsIdsToCategoriesObj
} from 'modules/ProductCategories/utils/formater';

class CategoryPicker extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      categoriesData: {
        productGroups: [],
        categories: [],
        subCategories: []
      },
      categoriesObj: null,
      loading: true
    };

    this.getGroupOptions = this.getGroupOptions.bind(this);
    this.getCategoriesOptions = this.getCategoriesOptions.bind(this);
    this.getSubcategoriesOptions = this.getSubcategoriesOptions.bind(this);
  }

  componentDidMount() {
    this.fetchCategories();
  }

  getGroupOptions() {
    const { categoriesData } = this.state;

    return categoriesData.productGroups;
  }

  getCategoriesOptions() {
    const { categoriesData, categoriesObj, groupId } = this.state;

    if (!categoriesObj) {
      return [];
    }

    if (groupId) {
      return categoriesObj.find(group => group.id === groupId).categories;
    }

    return categoriesData.categories;
  }

  getSubcategoriesOptions() {
    const { categoriesData, categoriesObj, groupId, categoryId } = this.state;

    if (!categoriesObj) {
      return [];
    }

    if (categoryId) {
      return categoriesObj
        .find(group =>
          group.categories.find(category => category.id === categoryId)
        )
        .categories.find(category => category.id === categoryId)?.subcategories;
    }

    if (groupId) {
      const { categories } = categoriesObj.find(group => group.id === groupId);

      return categories.reduce((acc, category) => {
        return [...acc, ...category.subcategories];
      }, []);
    }

    return categoriesData.subCategories;
  }

  async fetchCategories() {
    try {
      const {
        data: categoriesData
      } = await ProductCategoriesApi.getProductCategories();

      this.setState({
        categoriesData: prepareCategories(categoriesData),
        categoriesObj: addParentsIdsToCategoriesObj(categoriesData),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { loading } = this.state;
    const { groupsIds, categoriesIds, subcategoriesIds } = this.props;

    return (
      <>
        <Autocomplete
          label='Product group'
          name='group'
          value={groupsIds}
          multiple
          options={formatOptions(this.getGroupOptions(), 'id', 'name')}
          onChange={(e, value) => this.props.onChange('group', value)}
          loading={loading}
        />
        <Autocomplete
          label='Product category'
          name='category'
          value={categoriesIds}
          multiple
          options={formatOptions(this.getCategoriesOptions(), 'id', 'name')}
          onChange={(e, value) => this.props.onChange('category', value)}
          loading={loading}
        />
        <Autocomplete
          label='Product subcategory'
          name='subcategory'
          value={subcategoriesIds}
          multiple
          options={formatOptions(this.getSubcategoriesOptions(), 'id', 'name')}
          onChange={(e, value) => this.props.onChange('subcategory', value)}
          loading={loading}
        />
      </>
    );
  }
}

CategoryPicker.defaultProps = {
  groupsIds: [],
  categoriesIds: [],
  subcategoriesIds: []
};

CategoryPicker.propTypes = {
  groupsIds: PropTypes.arrayOf(PropTypes.number),
  categoriesIds: PropTypes.arrayOf(PropTypes.number),
  subcategoriesIds: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired
};

export default CategoryPicker;
