import BaseApiConfig from 'api/connections/BaseApiConfig';

class ClientsPreviousUsers extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.clientsPreviousUsersPath = 'clients/users/history';
  }

  getClientsPreviousUsers(pathParams) {
    return this.get(this.clientsPreviousUsersPath, pathParams);
  }
}

export default new ClientsPreviousUsers();
