import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';
import CustomDialog from 'modules/Layout/component/CustomDialog';
import UsersApi from 'api/connections/Users/UsersApi';
import Table from 'modules/Layout/component/List/Table';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';

class UnassignedToEmployeeUsersList extends Component {
  static getCols() {
    return [
      {
        property: 'first_name',
        label: 'First name',
        sortable: false
      },
      {
        property: 'last_name',
        label: 'Last name',
        sortable: false
      },
      {
        property: 'email',
        label: 'Email',
        sortable: false
      }
    ];
  }

  constructor(props) {
    super(props);

    this.state = {
      users: undefined,
      selected: [],
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      loading: true
    };

    this.setPagination = this.setPagination.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  onSelect(id) {
    this.setState({ selected: id ? [id] : [] });
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchUsers()
    );
  }

  async fetchUsers() {
    const { department_id, role } = this.props;
    const { per_page, page } = this.state.pagination;
    const pathParams = { department_id, role, per_page, page };

    try {
      const {
        data: { data: users, meta }
      } = await UsersApi.getUsers(pathParams);

      this.setState({
        users,
        loading: false,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  render() {
    const { users, selected, pagination, loading } = this.state;
    const { onDialogClose, onSubmit } = this.props;

    return (
      <CustomDialog
        open
        onClose={onDialogClose}
        title={t('Select the user that will be assigned to the client')}
        actions={
          <Button
            color='primary'
            text={t('Save')}
            onClick={() => {
              onSubmit(selected[0]);
            }}
          />
        }
      >
        <Table
          cols={UnassignedToEmployeeUsersList.getCols()}
          rows={users}
          loading={loading}
          selected={selected}
          onSelect={this.onSelect}
        />
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </CustomDialog>
    );
  }
}

UnassignedToEmployeeUsersList.propTypes = {
  department_id: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired
};

export default UnassignedToEmployeeUsersList;
