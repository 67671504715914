export default {
  0: 'new',
  1: 'no file (manual import)',
  2: 'in progress',
  3: 'successfully completed',
  4: 'failed'
};

export const statusesArray = [
  { status: 0, name: 'new' },
  { status: 1, name: 'no file (manual import)' },
  { status: 2, name: 'in progress' },
  { status: 3, name: 'successfully completed' },
  { status: 4, name: 'failed' }
];
