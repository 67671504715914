import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Button from 'modules/Layout/component/Button';
import t from 'translate/translate';

const UnassignedToEmployeeButtons = props => {
  const { role, isAnySelected, onClick } = props;

  return (
    <Box display='flex' justifyContent='center'>
      <Button
        onClick={onClick}
        color='primary'
        text={role === 'trader' ? t('Assign trader') : t('Assign telemarketer')}
        disabled={!isAnySelected}
        tooltipMsg={t('Select client first')}
      />
    </Box>
  );
};

UnassignedToEmployeeButtons.propTypes = {
  role: PropTypes.string.isRequired,
  isAnySelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default UnassignedToEmployeeButtons;
