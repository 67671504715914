import { ROUTE_AFFILIATION } from 'routing/routes/Affiliation';
import { CLIENT } from 'api/auth/roles';
import PeopleIcon from '@material-ui/icons/People';

const menu = [
  {
    key: 'menu-affiliation',
    label: 'Affiliation',
    icon: <PeopleIcon />,
    path: ROUTE_AFFILIATION,
    roles: [CLIENT]
  }
];

export default menu;
