import formatCategoriesOnTargetEdit from 'modules/Targets/formatters/formatCategoriesOnTargetEdit';

const model = {
  value: '',
  sales_groups: [],
  clients: [],
  period_type: '',
  period_from: '',
  products: [],
  categoryGroups: [],
  categories: [],
  subCategories: [],
  regions: [],
  subregions: [],
  subsubregions: []
};

const map = data => {
  const { target } = data;
  const result = {};

  /* eslint no-restricted-syntax: "off" */
  for (const [key, value] of Object.entries(model)) {
    if (key === 'sales_groups' || key === 'clients' || key === 'products') {
      result[key] = target[key].map(i => i.id) || value;
    } else {
      result[key] = target[key] || value;
    }
  }

  return {
    ...result,
    ...formatCategoriesOnTargetEdit(data)
  };
};

export default {
  data: model,
  map: data => map(data)
};
