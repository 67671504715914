import { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';

const margin = {
  top: 10,
  right: 10,
  bottom: 10,
  left: 10
};

const PieChart = props => {
  const {
    data,
    valuePropertyName,
    title,
    outerRadius,
    innerRadius,
    onDrillDown,
    nameSuffix,
    prefix,
    suffix
  } = props;

  const width = 2 * outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;

  const colorScale = d3
    .scaleSequential()
    .interpolator(d3.interpolateCool)
    .domain([0, data.length]);

  const renderValue = value => {
    if (suffix) {
      return `${value} ${t(suffix)}`;
    }

    if (prefix) {
      return `${t(prefix)} ${value}`;
    }

    return valuePropertyName === 'price_sum' ? `${value} PLN` : value;
  };

  const drawChart = () => {
    d3.select(`#pie-container-${valuePropertyName}-${nameSuffix}`)
      .select('svg')
      .remove();

    const div = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip-donut')
      .style('opacity', 0);

    const svg = d3
      .select(`#pie-container-${valuePropertyName}-${nameSuffix}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .style('display', 'flex')
      .style('justify-content', 'center')
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const arcGenerator = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(10);

    const pieGenerator = d3
      .pie()
      .value(d => d[valuePropertyName])
      .padAngle(0.02);

    const arc = svg.selectAll().data(pieGenerator(data)).enter();

    arc
      .append('path')
      .attr('d', arcGenerator)
      .on('click', (_, d) => {
        div.transition().duration('50').style('opacity', 0);
        onDrillDown(d.data);
      })
      .style('fill', (_, i) => colorScale(i))
      .style('stroke', '#ffffff')
      .style('stroke-width', 0)
      .style('cursor', 'pointer')
      .on('mouseover', function (_, d) {
        d3.select(this).transition().duration('50').attr('opacity', '.85');
        div.transition().duration(50).style('opacity', 1);
        div.html(`<strong>${renderValue(d.value)}</strong> ${d.data.name}`);
      })
      .on('mousemove', event => {
        div
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 15}px`);
      })
      .on('mouseout', function () {
        d3.select(this).transition().duration('50').attr('opacity', '1');
        div.transition().duration('50').style('opacity', 0);
      });

    const legend = d3.select(`#legend-container-${nameSuffix}`);

    legend.select('g').remove();
    legend
      .append('g')
      .attr('class', 'legend')
      .selectAll('text')
      .data(pieGenerator(data))
      .enter()
      .append('div')
      .attr('class', 'legend-item');

    legend
      .selectAll('.legend-item')
      .append('div')
      .attr('class', 'legend-dot')
      .style('background', (_, i) => {
        return colorScale(i);
      });

    legend
      .selectAll('.legend-item')
      .append('text')
      .text(d => d.data.name);
  };

  useEffect(() => {
    drawChart();
  }, [data, outerRadius]);

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='body1'>{title}</Typography>
      <div id={`pie-container-${valuePropertyName}-${nameSuffix}`} />
    </Box>
  );
};

PieChart.defaultProps = {
  outerRadius: 300,
  innerRadius: 150,
  prefix: '',
  suffix: ''
};

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  valuePropertyName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  outerRadius: PropTypes.number,
  innerRadius: PropTypes.number,
  onDrillDown: PropTypes.func.isRequired,
  nameSuffix: PropTypes.number.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string
};

export default PieChart;
