import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import t from 'translate/translate';
import useStyles from 'modules/Layout/component/List/styles';
import Row from 'modules/Statistics/ClientsOrders/component/List/Table/Row';
import CircularProgress from '@material-ui/core/CircularProgress';
import renderPrice from 'modules/Products/utils/renderPrice';

const columns = [
  { label: 'Client', property: 'name' },
  { label: 'City', property: 'city' },
  { label: 'Street', property: 'street' },
  { label: 'Postal code', property: 'postal_code' },
  {
    label: 'Value',
    property: 'price_sum',
    sortable: true,
    render: (value, { currency }) => renderPrice(value, currency)
  },
  { label: 'Orders number', property: 'quantity_sum', sortable: true }
];

const StatisticsClientsOrdersTable = ({
  statistics,
  bodyText,
  loading,
  sort,
  setSort
}) => {
  const classes = useStyles();

  const renderRows = () =>
    statistics.map(row => <Row key={row.id} row={row} columns={columns} />);

  const onSort = property => {
    if (property === sort.sort_field) {
      setSort({
        ...sort,
        sort_order: sort.sort_order === 'asc' ? 'desc' : 'asc'
      });
    } else {
      setSort({
        sort_field: property,
        sort_order: 'desc'
      });
    }
  };

  const renderCircularProgress = () => (
    <TableRow>
      <TableCell align='center' colSpan={columns.length + 1}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table' className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map(column => (
              <TableCell
                align='center'
                sortDirection={
                  column.property === sort.sort_field ? sort.sort_order : false
                }
                key={column.property}
              >
                {column.sortable ? (
                  <TableSortLabel
                    active={column.property === sort.sort_field}
                    direction={
                      column.property === sort.sort_field
                        ? sort.sort_order
                        : 'asc'
                    }
                    onClick={() => onSort(column.property)}
                  >
                    <Typography
                      variant='subtitle1'
                      className={classes.cellHeader}
                    >
                      {t(column.label)}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <Typography
                    variant='subtitle1'
                    className={classes.cellHeader}
                  >
                    {t(column.label)}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyText && (
            <TableRow>
              <TableCell align='center' colSpan={columns.length + 1}>
                <Typography variant='subtitle1' color='textSecondary'>
                  {t(bodyText)}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {loading ? renderCircularProgress() : renderRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

StatisticsClientsOrdersTable.defaultProps = {
  bodyText: '',
  sort: {
    sort_field: null,
    sort_order: 'asc'
  }
};

StatisticsClientsOrdersTable.propTypes = {
  statistics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bodyText: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  sort: PropTypes.shape({
    sort_field: PropTypes.string,
    sort_order: PropTypes.string
  }),
  setSort: PropTypes.func.isRequired
};

export default StatisticsClientsOrdersTable;
