import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ProductsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.productsPath = 'products';
    this.allProductsPath = 'products/all';
    this.productPath = 'products/:id';
    this.productAssignToUserPath = 'products/users/:userId';
    this.exportProductsPath = 'products/export';
    this.topSellersPath = 'products/top-sellers';
    this.notSoldTopSellersPath = 'products/not-sold-top-sellers';
  }

  getProducts(pathParams) {
    return this.get(this.productsPath, pathParams);
  }

  getAllProducts() {
    return this.get(this.allProductsPath);
  }

  getProduct(id) {
    return this.get(insertPathParams(this.productPath, { id }));
  }

  createProduct(payload) {
    return this.post(this.productsPath, payload);
  }

  updateProduct(id, payload) {
    return this.put(insertPathParams(this.productPath, { id }), payload);
  }

  deleteProduct(id) {
    return this.delete(insertPathParams(this.productPath, { id }));
  }

  getProductsAssignToUser(userId) {
    return this.get(insertPathParams(this.productAssignToUserPath, { userId }));
  }

  assignProductsToUser(userId, payload) {
    return this.put(
      insertPathParams(this.productAssignToUserPath, { userId }),
      payload
    );
  }

  exportProducts(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportProductsPath, pathParams, options);
  }

  getTopSellers(pathParams) {
    return this.get(this.topSellersPath, pathParams);
  }

  getNotSoldTopSellers() {
    return this.get(this.notSoldTopSellersPath);
  }
}

export default new ProductsApi();
