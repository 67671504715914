import { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from 'modules/Layout/component/Loader';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ProductsApi from 'api/connections/Products/ProductsApi';
import ProductsTreeGroups from 'modules/Visits/components/Details/Products/Add/ProductsTreeGroups';
import { Box } from '@material-ui/core';

class ProductsTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productsData: {}
    };

    this.onClick = this.onClick.bind(this);
  }

  async onClick(item) {
    const { group_id, category_id, id: subcategory_id } = item;

    const { productsData } = this.state;

    if (productsData[subcategory_id]) return null;

    this.setState(state => ({
      productsData: {
        ...state.productsData,
        [subcategory_id]: 'loading'
      }
    }));

    try {
      const {
        data: { data: products }
      } = await ProductsApi.getProducts({
        subcategory_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState(state => ({
        productsData: {
          ...state.productsData,
          [subcategory_id]: products.map(p => ({
            ...p,
            group_id,
            category_id,
            subcategory_id,
            type: 'product'
          }))
        }
      }));
    } catch (err) {
      this.props.handleError(err);
    }
  }

  render() {
    const { categoriesObj, handleError, onSelect, selected } = this.props;
    const { productsData } = this.state;

    if (!categoriesObj) return <Loader />;

    return (
      <Box width={1} display='flex' flexDirection='column'>
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          disableSelection
        >
          <ProductsTreeGroups
            categoriesObj={categoriesObj}
            productsData={productsData}
            selected={selected}
            onClick={this.onClick}
            onSelect={onSelect}
            handleError={handleError}
          />
        </TreeView>
      </Box>
    );
  }
}

ProductsTree.propTypes = {
  categoriesObj: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  handleError: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default ProductsTree;
