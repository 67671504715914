import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { formatOptions } from 'modules/Layout/component/Select';
import {
  ROUTE_DEPARTMENTS_DETAILS,
  ROUTE_DEPARTMENTS_LIST
} from 'routing/routes/Departments';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import { formatCategoriesError } from 'modules/Departments/utils/formater';
import { isEmpty } from 'lodash/lang';

import AlertContext from 'modules/Shared/context/Alert/alertContext';
import insertPathParams from 'api/utils/insertPathParams';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Checkbox from 'modules/Layout/component/Checkbox';

const UpdateDepartmentForm = props => {
  const { department, categoriesObj } = props;

  const history = useHistory();
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: department.name,
    product_groups: department.product_groups,
    categories: department.categories,
    has_all_categories: department.has_all_categories
  });

  const [validation, setValidation] = useState({
    name: {
      status: false,
      message: t('Field <%= field %> is required', { field: t('Name') })
    },
    product_groups: {
      status: false,
      message: t('Selected product group or category is required')
    },
    categories: {
      status: false,
      message: t('Selected product group or category is required')
    }
  });

  const onChange = e => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    setValidation({
      ...validation,
      [name]: { ...validation[name], status: false }
    });
  };

  const onAllCategoriesChange = e => {
    const { checked } = e.target;

    setFormData({
      ...formData,
      has_all_categories: checked,
      product_groups: [],
      categories: []
    });
    setValidation({
      ...validation,
      has_all_categories: { ...validation.has_all_categories, status: false },
      product_groups: { ...validation.product_groups, status: false },
      categories: { ...validation.categories, status: false }
    });
  };

  const onAutocompleteChange = (name, value) => {
    setFormData({ ...formData, [name]: value.map(v => v.key) });
    setValidation({
      ...validation,
      product_groups: { ...validation.product_groups, status: false },
      categories: { ...validation.categories, status: false }
    });
  };

  const runValidation = () => {
    /* eslint prefer-const: "off" */
    let [isValid, newValidateState] = validate(formData, validation);

    if (
      !formData.has_all_categories &&
      isEmpty(formData.product_groups) &&
      isEmpty(formData.categories)
    ) {
      isValid = false;
      newValidateState.product_groups.status = true;
      newValidateState.categories.status = true;
    }

    if (!isValid) {
      setValidation(newValidateState);
    }

    return isValid;
  };

  const handleResponse = () => {
    setAlert({ value: 'Success', variant: COLOR_VARIANTS_SUCCESS });
    history.push(
      insertPathParams(ROUTE_DEPARTMENTS_DETAILS, { id: department.id })
    );
  };

  const handleError = err => {
    const { message, errors } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const { groupError, categoriesError } = formatCategoriesError(
        errors,
        categoriesObj
      );

      setValidation({
        ...validation,
        product_groups: { status: groupError.length > 0, message: groupError },
        categories: {
          status: categoriesError.length > 0,
          message: categoriesError
        }
      });
      setAlert(message);
    } else if (err instanceof ApiError) {
      setAlert(message);
    }
  };

  const makeApiCall = async () => {
    setLoading(true);
    try {
      await DepartmentsApi.updateDepartment(formData, department.id);
      handleResponse();
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const isValid = runValidation();
    if (isValid) await makeApiCall();
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Input
        name='name'
        label='Name'
        value={formData.name}
        required
        onChange={onChange}
        errorStatus={validation.name.status}
        errorText={validation.name.message}
      />
      <Checkbox
        label='Department assigned to all categories'
        name='has_all_categories'
        checked={formData.has_all_categories}
        onChange={onAllCategoriesChange}
        disabled={!department.can_change_categories}
        tooltipMsg='You cannot change the category when there are open sales targets for that category.'
      />
      <Autocomplete
        name='product_groups'
        label='Product group'
        multiple
        value={formData.product_groups}
        options={formatOptions(categoriesObj.productGroups, 'id', 'name')}
        onChange={(e, v) => onAutocompleteChange('product_groups', v)}
        disabled={
          !department.can_change_categories || formData.has_all_categories
        }
        tooltipMsg={
          formData.has_all_categories
            ? 'Department assigned to all categories'
            : 'You cannot change the category when there are open sales targets for that category.'
        }
        errorStatus={validation.product_groups.status}
        errorText={validation.product_groups.message}
      />
      <Autocomplete
        name='categories'
        label='Category'
        multiple
        value={formData.categories}
        options={formatOptions(categoriesObj.categories, 'id', 'name')}
        onChange={(e, v) => onAutocompleteChange('categories', v)}
        disabled={
          !department.can_change_categories || formData.has_all_categories
        }
        tooltipMsg={
          formData.has_all_categories
            ? 'Department assigned to all categories'
            : 'You cannot change the category when there are open sales targets for that category.'
        }
        errorStatus={validation.categories.status}
        errorText={validation.categories.message}
      />
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
        width={1}
      >
        <Box>
          <Link to={ROUTE_DEPARTMENTS_LIST} className='router-button'>
            <Button text={t('Cancel')} />
          </Link>
        </Box>
        <Box>
          <Button
            type='submit'
            fullWidth
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </Box>
      </Box>
    </form>
  );
};

UpdateDepartmentForm.defaultProps = {
  categoriesObj: {
    productGroups: [],
    categories: []
  }
};

UpdateDepartmentForm.propTypes = {
  department: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    product_groups: PropTypes.arrayOf(PropTypes.number),
    categories: PropTypes.arrayOf(PropTypes.number),
    can_change_categories: PropTypes.bool.isRequired,
    has_all_categories: PropTypes.bool.isRequired
  }).isRequired,
  categoriesObj: PropTypes.shape({
    productGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  })
};

export default UpdateDepartmentForm;
