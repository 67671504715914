import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import ProductsApi from 'api/connections/Products/ProductsApi';
import { ROUTE_WHOLESALERS_LIST } from 'routing/routes/Wholesalers';
import WholesalerProductsMappingCreateForm from 'modules/Wholesalers/component/Details/ProductsMapping/Create/Form';

class WholesalerProductMappingCreate extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id: wholesalerId }
      }
    } = props;
    this.wholesalerId = parseInt(wholesalerId, 10);
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      products: []
    };
  }

  async componentDidMount() {
    if (!this.wholesalerId) {
      this.props.history.push(ROUTE_WHOLESALERS_LIST);
    }

    this.setCurrentPage('Create product mapping');

    this.fetchData();
  }

  async fetchData() {
    try {
      const [
        {
          data: { data: products }
        }
      ] = await Promise.all([
        ProductsApi.getProducts({
          per_page: Number.MAX_SAFE_INTEGER
        })
      ]);

      this.setState({ products });
    } catch (err) {
      this.setAlert(err.getPayload().message);
    }
  }

  render() {
    const { products } = this.state;

    const {
      location: { state: wholesalerName }
    } = this.props;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <WholesalerProductsMappingCreateForm
              wholesalerId={this.wholesalerId}
              wholesalerName={wholesalerName}
              products={products}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalerProductMappingCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerProductMappingCreate);
