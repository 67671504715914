/* eslint-disable */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import format from 'date-fns/format';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Button from 'modules/Layout/component/Button';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import {
  COLOR_VARIANTS_ERROR,
  COLOR_VARIANTS_SUCCESS
} from 'modules/Shared/type';
import { isArray, isEmpty } from 'lodash/lang';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_ORDERS_DETAILS, ROUTE_ORDERS_LIST } from 'routing/routes/Orders';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import WholesalersAgenciesApi from 'api/connections/Wholesalers/WholesalersAgenciesApi';
import DepartmentsOrderTypesApi from 'api/connections/Departments/DepartmentsOrderTypesApi';
import OrdersApi from 'api/connections/Orders/OrdersApi';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import PaymentMethodsApi from 'api/connections/PaymentMethods/PaymentMethodsApi';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import OrderProductsTable from 'modules/Orders/component/OrderPreparing/ProductsTable';
import OrderConfigurations from 'modules/Orders/component/OrderPreparing/Configurations';
import OrderTypeConfiguration from 'modules/Orders/component/OrderPreparing/OrderTypeConfiguration';
import OrderDiscounts from 'modules/Orders/component/OrderPreparing/Discounts';
import OrderPromotions from 'modules/Orders/component/Promotions';
import OrderDefinedPromotions from 'modules/Orders/component/DefinedPromotions';
import OrderSummary from 'modules/Orders/component/Summary';
import DiscountsApi from 'api/connections/Discounts/DiscountsApi';
import TaxesApi from 'api/connections/Taxes/TaxesApi';
import {
  DEPARTMENT_MANAGER,
  KEY_ACCOUNT_MANAGER,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import ProductsApi from 'api/connections/Products/ProductsApi';
import Loader from 'modules/Layout/component/Loader';
import insertPathParams from 'api/utils/insertPathParams';
import SaveAndSendDialog from 'modules/Orders/component/OrderPreparing/SaveAndSendDialog';
import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';
import { ROUTE_VISITS_DETAILS } from 'routing/routes/Visits';
import PromotionsApi from 'api/connections/Promotions/PromotionsApi';
import calculateOrderValues from 'modules/Orders/utils/calculations';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';
import AddIcon from '@material-ui/icons/Add';
import SelectClientDialog from 'modules/Orders/component/OrderPreparing/SelectClientDialog';
import PriceListsApi from 'api/connections/PriceLists/PriceListsApi';
import { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import getDefaultPriceList from 'modules/PriceLists/utils/getDefaultPriceList';

class OrderForm extends Component {
  static contextType = AuthContext;

  static formatClientInitialData(updateOrderData, clientData) {
    if (!isEmpty(updateOrderData))
      return {
        id: updateOrderData.client_id,
        name: updateOrderData.client_name,
        city: updateOrderData.client_city,
        street: updateOrderData.client_street,
        postal_code: updateOrderData.client_postal_code,
        country_id: updateOrderData.client_country_id
      };
    if (!isEmpty(clientData))
      return {
        id: clientData.id,
        name: clientData.name,
        city: clientData.city,
        street: clientData.street,
        postal_code: clientData.postal_code,
        country_id: clientData.country_id
      };
    return {
      id: null,
      name: null,
      city: null,
      street: null,
      postal_code: null,
      country_id: null
    };
  }

  constructor(props, context) {
    super(props, context);

    const { orderData, client, visitId } = this.props;

    this.isUpdate = this.props.orderData.client_id || null;

    this.state = {
      formData: {
        client_id: orderData.client_id || client?.id,
        visit_id: orderData.visit_id || visitId,
        products: orderData.products || [],
        payment_discount_id: orderData.payment_discount_id || null,
        payment_method_id: orderData.payment_method_id || null,
        payment_date: orderData.payment_date || null,
        is_direct: Boolean(orderData.is_direct) || true,
        wholesaler_id: orderData.wholesaler_id || null,
        wholesaler_agency_id: orderData.wholesaler_agency_id || null,
        order_date: orderData.order_date || null,
        publish: false,
        copy_to_client: false,
        promotions: orderData.promotions || [],
        description: orderData.description || '',
        price_list_id: orderData.price_list_id || '',
        type_id: orderData.type_id || null
      },
      client: OrderForm.formatClientInitialData(orderData, client),
      data: {
        discounts: orderData.discounts || [],
        promotions: null,
        visit: props.visit || undefined,
        priceLists: [],
        agencies: [],
        wholesalers: undefined
      },
      validation: {
        client_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Client')
          })
        },
        wholesaler_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Client')
          })
        }
      },
      loading: false,
      loadingPriceLists: false,
      loadingAgencies: false,
      selectClientDialogStatus: false,
      saveAndSendDialog: false,
      calculationResult: {
        totalNetValueBeforeReduction: 0,
        totalGrossValueBeforeReduction: 0,
        totalGrossReductionValue: 0,
        totalGrossGratisProductsValue: 0,
        totalNetValueBeforeDiscount: 0,
        totalNetValue: 0,
        totalGrossValue: 0
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onClientChange = this.onClientChange.bind(this);
    this.onProductsChange = this.onProductsChange.bind(this);
    this.onPromotionDefineSuccess = this.onPromotionDefineSuccess.bind(this);
    this.onPromotionDelete = this.onPromotionDelete.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onsSveAndSendSuccess = this.onsSveAndSendSuccess.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onSelectClientDialogOpen = this.onSelectClientDialogOpen.bind(this);
    this.onSelectClientDialogClose = this.onSelectClientDialogClose.bind(this);
    this.getPriceLimitValidStatus = this.getPriceLimitValidStatus.bind(this);
  }

  async componentDidMount() {
    const { formData, visit_id } = this.state;

    if (formData.client_id) {
      this.fetchWholesalers();
      if (formData.wholesaler_id) {
        await this.fetchAgencies(formData.wholesaler_id);
      }
      this.fetchPromotions();
      await this.fetchPriceLists(true);
      await this.fetchData();

      this.calculateOrderResult();
    } else {
      await this.fetchClients();
    }
    if (visit_id && !this.data.visit) this.fetchVisit();
  }

  //BASIC
  redirect(orderId) {
    const id = orderId || this.props.orderData.id;

    if (this.props.visit) {
      let path = insertPathParams(ROUTE_VISITS_DETAILS, {
        id: this.props.visit.id
      });
      path += '?tab_index=orders';

      return this.props.history.push(path);
    }

    if (this.props.client) {
      let path = insertPathParams(ROUTE_CLIENTS_DETAILS, {
        id: this.props.client.id
      });
      path += '?tab_index=orders';

      return this.props.history.push(path);
    }

    if (!this.isUpdate && !orderId) {
      return this.props.history.push(ROUTE_ORDERS_LIST);
    }

    return this.props.history.push(
      insertPathParams(ROUTE_ORDERS_DETAILS, { id })
    );
  }

  handleResponse(res) {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.redirect(res.data.data.id);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState(
      state => {
        const { formData, validation } = state;

        return {
          ...state,
          formData: {
            ...formData,
            wholesaler_agency_id:
              name === 'wholesaler_id'
                ? null
                : state.formData.wholesaler_agency_id,
            [name]: value
          },
          validation: {
            ...validation,
            wholesaler_id: {
              ...validation.wholesaler_id,
              status:
                name === 'is_direct' ? false : validation.wholesaler_id.status
            },
            [name]: {
              ...validation[name],
              status: false
            }
          }
        };
      },
      async () => {
        if (
          name === 'is_direct' &&
          value === false &&
          isEmpty(this.state.data.wholesalers)
        ) {
          this.fetchWholesalers();
        }
        if (name === 'payment_discount_id' || name === 'is_direct') {
          this.calculateOrderResult();
        }
        if (name === 'price_list_id') {
          await this.fetchPriceList(value);
          this.calculateOrderResult();
        }
        if (name === 'wholesaler_id') {
          this.fetchAgencies(value);
        }
      }
    );
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onChange(event);
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onSubmitAndSend() {
    const isValid = this.runValidation();
    if (isValid) {
      this.setState({ saveAndSendDialog: true });
    }
  }

  onsSveAndSendSuccess(copyToClient) {
    this.setState(
      state => {
        return {
          ...state,
          formData: {
            ...state.formData,
            copy_to_client: copyToClient,
            publish: true
          },
          saveAndSendDialog: false
        };
      },
      () => this.makeApiCall()
    );
  }

  onDialogClose() {
    this.setState({
      saveAndSendDialog: false,
      copy_to_client: false,
      publish: false
    });
  }

  //CLIENT
  onClientChange(client_id) {
    this.setState(
      state => {
        const { formData, validation } = state;

        return {
          ...state,
          formData: {
            ...formData,
            client_id,
            visit_id: null,
            wholesaler_id: null,
            wholesaler_agency_id: null,
            promotions: []
          },
          client: state.data.clients.find(c => c.id === client_id),
          validation: {
            ...validation,
            client_id: {
              ...validation.client_id,
              status: false
            }
          },
          selectClientDialogStatus: false
        };
      },
      async () => {
        this.fetchPromotions();
        await this.fetchPriceLists();
        await this.fetchDiscounts();
        await this.fetchData();

        this.calculateOrderResult();
        if (!this.state.data.wholesalers) this.fetchWholesalers();
      }
    );
  }

  onSelectClientDialogOpen() {
    this.setState({ selectClientDialogStatus: true });
  }

  onSelectClientDialogClose() {
    this.setState({ selectClientDialogStatus: false });
  }

  renderClientDetails() {
    const {
      client: { id, name, city, street, postal_code }
    } = this.state;

    let clientAddress = '';

    if (city) clientAddress += `${city} `;
    if (postal_code) clientAddress += `${postal_code} `;
    if (street) clientAddress += `${street} `;

    if (clientAddress.length === 0) clientAddress = t('No data');

    const clientNameLink = (
      <Link
        to={insertPathParams(ROUTE_CLIENTS_DETAILS, { id })}
        className='router-link router-link-underline'
        target='_blank'
      >
        {name}
      </Link>
    );

    return (
      <Box
        mt={1}
        display='flex'
        justifyContent='space-between'
        alignContent='flex-start'
      >
        <Box>
          <TextDataSet data={clientNameLink} label='Client name' />
          <TextDataSet data={clientAddress} label='Client address' />
        </Box>
        {!this.isUpdate && !this.props.client && (
          <IconButton
            onClick={this.onSelectClientDialogOpen}
            icon={<EditIcon />}
            alt='change client'
            className='update-icon'
          />
        )}
      </Box>
    );
  }

  //PRODUCTS
  onProductsChange(
    products,
    recalculateStatus = true,
    deletedProductId = null
  ) {
    const promotions = this.checkPromotionsAfterProductChange(deletedProductId);

    this.setState(
      state => {
        return {
          ...state,
          formData: {
            ...state.formData,
            products,
            promotions
          }
        };
      },
      () => {
        if (recalculateStatus) this.calculateOrderResult();
      }
    );
  }

  //PROMOTIONS
  onPromotionDefineSuccess(promotion) {
    this.setState(
      state => {
        const { promotions: prevPromotions } = state.formData;

        return {
          ...state,
          formData: {
            ...state.formData,
            promotions: [...prevPromotions, promotion]
          }
        };
      },
      () => this.calculateOrderResult()
    );
  }

  onPromotionDelete(index) {
    this.setState(
      state => {
        const promotions = [...state.formData.promotions];
        promotions.splice(index, 1);

        return {
          ...state,
          formData: {
            ...state.formData,
            promotions
          }
        };
      },
      () => this.calculateOrderResult()
    );
  }

  checkPromotionsAfterProductChange(deletedProductId) {
    const { promotions } = this.state.formData;

    if (!deletedProductId) return promotions;

    const filteredPromotions = promotions.filter(promotion => {
      return !(
        promotion.products.length === 1 &&
        promotion.products.includes(deletedProductId)
      );
    });

    return filteredPromotions.map(promotion => {
      return {
        ...promotion,
        products: promotion.products.filter(
          productId => productId !== deletedProductId
        )
      };
    });
  }

  //OTHERS
  calculateOrderResult() {
    const {
      formData: { products, promotions, is_direct, payment_discount_id },
      client: { country_id },
      data: { discounts, taxes: taxesData }
    } = this.state;

    const calculationResult = calculateOrderValues({
      products,
      discounts,
      paymentDiscountId: payment_discount_id,
      promotions,
      taxesData,
      countryId: country_id,
      isDirect: is_direct,
      priceList: this.getPriceList()
    });

    this.setState(state => {
      return {
        ...state,
        calculationResult
      };
    });
  }

  getPriceLimitValidStatus() {
    const {
      data: { paymentMethods },
      calculationResult: { totalGrossValue },
      formData: { payment_method_id: paymentMethod }
    } = this.state;

    if (!paymentMethod) {
      return { priceLimitValidStatus: true, priceLimit: null };
    }

    const { price_limit: priceLimit } = paymentMethods.find(
      method => method.id === paymentMethod
    );

    const priceLimitValidStatus = Boolean(priceLimit >= totalGrossValue);

    return { priceLimitValidStatus, priceLimit };
  }

  //FETCH DATA
  async fetchData() {
    const { country_id } = this.state.client;

    try {
      const [
        {
          data: { data: taxes }
        },
        {
          data: { data: productsData }
        },
        { data: categoriesData },
        {
          data: { data: orderTypes }
        },
        {
          data: { data: paymentMethods }
        }
      ] = await Promise.all([
        TaxesApi.getTaxes({
          per_page: Number.MAX_SAFE_INTEGER,
          country_id
        }),
        ProductsApi.getProducts({
          per_page: Number.MAX_SAFE_INTEGER,
          country_id
        }),
        ProductCategoriesApi.getProductCategories(),
        DepartmentsOrderTypesApi.getDepartmentOrderTypes({
          per_page: Number.MAX_SAFE_INTEGER,
          country_id
        }),
        PaymentMethodsApi.getPaymentMethods({
          per_page: Number.MAX_SAFE_INTEGER
        })
      ]);

      this.setState(state => ({
        data: {
          ...state.data,
          taxes,
          productsData,
          orderTypes,
          paymentMethods,
          categoriesObj: prepareCategories(categoriesData)
        }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchClients() {
    try {
      const {
        data: { data: clients }
      } = await ClientsApi.getAllClients({
        my_clients: this.context.hasRole([
          DEPARTMENT_MANAGER,
          KEY_ACCOUNT_MANAGER,
          TELEMARKETER,
          TRADER
        ])
      });

      this.setState(state => ({
        data: {
          ...state.data,
          clients
        }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchWholesalers() {
    const { country_id } = this.state.client;

    try {
      const {
        data: { data: wholesalers }
      } = await WholesalersApi.getWholesalers({
        country_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState(state => ({
        data: { ...state.data, wholesalers }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchAgencies(wholesalerId) {
    if (!wholesalerId) {
      return this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          agencies: []
        }
      }));
    }

    try {
      this.setState({ loadingAgencies: true });

      const {
        data: { data: agencies }
      } = await WholesalersAgenciesApi.getWholesalerAgencies(wholesalerId, {
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState(state => ({
        ...state,
        loadingAgencies: false,
        data: {
          ...state.data,
          agencies
        }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchDiscounts() {
    const { client_id } = this.state.formData;

    try {
      const {
        data: { data: discounts }
      } = await DiscountsApi.getDiscounts({
        per_page: Number.MAX_SAFE_INTEGER,
        dedicated_to: client_id
      });

      this.setState(state => ({
        data: {
          ...state.data,
          discounts
        }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchPromotions() {
    const { client_id } = this.state.formData;

    try {
      const {
        data: { data: promotions }
      } = await PromotionsApi.getPromotions({
        per_page: Number.MAX_SAFE_INTEGER,
        dedicated_to: client_id
      });

      this.setState(state => ({
        data: {
          ...state.data,
          promotions
        }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchPriceLists(init = false) {
    const { client_id, price_list_id } = this.state.formData;

    let department_id = null;

    const { departments } = this.context.user;

    if (departments.length === 1) {
      department_id = departments[0].id;
    }

    try {
      this.setState({ loadingPriceLists: true });

      const {
        data: { data: priceLists }
      } = await PriceListsApi.getPricesLists({
        for_client_id: client_id,
        for_department_id: department_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      const defaultPriceList = getDefaultPriceList(priceLists);

      const priceListId =
        init && price_list_id ? price_list_id : defaultPriceList?.id ?? null;

      this.setState(state => {
        return {
          ...state,
          formData: {
            ...state.formData,
            price_list_id: priceListId
          },
          data: {
            ...state.data,
            priceLists
          },
          loadingPriceLists: false
        };
      });

      if (priceListId) await this.fetchPriceList(priceListId);
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchPriceList(id) {
    if (!id) return null;

    try {
      this.setState({ loadingPriceLists: true });

      const {
        data: { data: priceList }
      } = await PriceListsApi.getPricesList(id);

      this.setState(state => {
        return {
          ...state,
          data: {
            ...state.data,
            priceLists: state.data.priceLists.map(pl =>
              pl.id === id ? priceList : pl
            )
          },
          loadingPriceLists: false
        };
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchVisit() {
    const { visit_id } = this.state.formData;

    try {
      const {
        data: { data: visit }
      } = await VisitsApi.getVisit({ visit_id });

      this.setState(state => ({
        data: {
          ...state.data,
          visit
        }
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  //FINAL
  runValidation() {
    const { formData, validation } = this.state;

    /* eslint prefer-const: "off" */
    let [isValid, newValidateState] = validate(formData, validation);

    const { priceLimitValidStatus } = this.getPriceLimitValidStatus();

    if (!priceLimitValidStatus) isValid = false;

    if (!isValid) {
      this.setState({ validation: newValidateState });

      this.props.setAlert({
        value: t('Order cannot be saved because of invalid fields'),
        variant: COLOR_VARIANTS_ERROR
      });
    }

    return isValid;
  }

  async makeApiCall() {
    let { formData } = this.state;
    const { client } = this.state;
    let res;

    formData = { ...formData, client_id: client.id };

    try {
      this.setState({ loading: true });

      if (this.isUpdate) {
        res = await OrdersApi.updateOrder(this.props.orderData.id, formData);
      } else {
        res = await OrdersApi.createOrder({
          ...formData,
          order_date: format(new Date(), 'dd.MM.yyyy HH:mm')
        });
      }

      this.setState({ loading: false });
      this.handleResponse(res);
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  getPriceList() {
    const {
      formData: { price_list_id },
      data: { priceLists }
    } = this.state;

    return priceLists.find(priceList => priceList.id === price_list_id);
  }

  render() {
    const {
      formData: {
        visit_id,
        products,
        payment_discount_id,
        payment_method_id,
        payment_date,
        is_direct,
        wholesaler_id,
        wholesaler_agency_id,
        promotions,
        description,
        price_list_id,
        type_id
      },
      client: {
        id: client_id,
        country_id: clientCountryId,
        currency: clientCurrency
      },
      data,
      loading,
      loadingPriceLists,
      loadingAgencies,
      selectClientDialogStatus,
      saveAndSendDialog,
      calculationResult
    } = this.state;
    const { visit } = this.props;

    if (this.isUpdate && (!data.productsData || !data.promotions))
      return <Loader />;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <>
          <Typography component='h6' variant='h6'>
            {t('Client')}
          </Typography>
          <Box className='language-form-box' pt={1} mb={3}>
            {client_id ? (
              this.renderClientDetails()
            ) : (
              <Box display='flex' alignItems='center'>
                <IconButton
                  onClick={this.onSelectClientDialogOpen}
                  icon={<AddIcon color='primary' fontSize='large' />}
                  alt='select client'
                />
                <Typography>{t('Select client')}</Typography>
              </Box>
            )}
          </Box>
          <Typography component='h6' variant='h6'>
            {t('Order type')}
          </Typography>
          <Box className='language-form-box' pt={1} mb={3}>
            <OrderTypeConfiguration
              clientId={client_id}
              wholesalerId={wholesaler_id}
              wholesalerAgencyId={wholesaler_agency_id}
              typeId={type_id}
              wholesalers={data.wholesalers}
              agencies={data.agencies}
              orderTypes={data.orderTypes}
              isDirect={is_direct}
              products={products}
              promotions={promotions}
              onChange={this.onChange}
              onAutocompleteChange={this.onAutocompleteChange}
              loadingAgencies={loadingAgencies}
            />
          </Box>
          <Typography component='h6' variant='h6'>
            {t('Products')}
          </Typography>
          <Box className='language-form-box' pt={1} mb={3}>
            <Box width={1 / 2} mb={3}>
              <Autocomplete
                name='price_list_id'
                label='Price list'
                value={price_list_id}
                options={formatOptions(data.priceLists || [], 'id', 'name')}
                onChange={(_, v) =>
                  this.onAutocompleteChange('price_list_id', v)
                }
                disabled={!client_id}
                tooltipMsg='Select client first'
                loading={loadingPriceLists}
              />
            </Box>
            <OrderProductsTable
              isDirect={is_direct}
              clientId={client_id}
              clientCountryId={clientCountryId}
              products={products}
              productsData={data.productsData}
              promotions={promotions}
              promotionsData={data.promotions}
              priceList={this.getPriceList()}
              onChange={this.onProductsChange}
              handleError={this.handleError}
            />
          </Box>
          <Box display='flex' mb={3}>
            <Box width={1} mr={1}>
              <OrderDiscounts
                clientId={client_id}
                discounts={data.discounts}
                products={products}
                netValue={calculationResult.totalNetValueBeforeDiscount}
                isDirect={is_direct}
              />
            </Box>
            <Box width={1}>
              <OrderPromotions
                clientId={client_id}
                promotionsData={data.promotions}
              />
            </Box>
          </Box>
          <Box mb={3}>
            <OrderDefinedPromotions
              promotions={promotions}
              products={products}
              onPromotionDefineSuccess={this.onPromotionDefineSuccess}
              onPromotionDelete={this.onPromotionDelete}
            />
          </Box>
          <Typography component='h6' variant='h6'>
            {t('Configuration')}
          </Typography>
          <Box className='language-form-box' pt={1} mb={3}>
            <OrderConfigurations
              clientId={client_id}
              visitId={visit_id}
              visits={data.visit}
              visit={visit}
              description={description}
              paymentDiscountId={payment_discount_id}
              discounts={data.discounts}
              taxesData={data.taxes}
              paymentMethods={data.paymentMethods}
              paymentMethod={payment_method_id}
              paymentDate={payment_date}
              isDirect={is_direct}
              onChange={this.onChange}
              onAutocompleteChange={this.onAutocompleteChange}
              getPriceLimitValidStatus={this.getPriceLimitValidStatus}
            />
          </Box>
          <Typography component='h6' variant='h6'>
            {t('Summary')}
          </Typography>
          <Box className='language-form-box' pt={1}>
            <OrderSummary
              calculationResult={calculationResult}
              currency={clientCurrency}
            />
          </Box>
        </>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button text={t('Cancel')} onClick={() => this.redirect()} />
          <Box>
            <Button
              fullWidth
              color='primary'
              text={t('Save and send')}
              onClick={() => this.onSubmitAndSend()}
            />
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
        {saveAndSendDialog && (
          <SaveAndSendDialog
            onSuccess={this.onsSveAndSendSuccess}
            onClose={this.onDialogClose}
          />
        )}
        {selectClientDialogStatus && (
          <SelectClientDialog
            clientId={client_id}
            clients={data.clients}
            onSuccess={this.onClientChange}
            onClose={this.onSelectClientDialogClose}
          />
        )}
      </form>
    );
  }
}

OrderForm.defaultProps = {
  orderData: {},
  client: undefined,
  visit: undefined,
  visitId: undefined
};

OrderForm.propTypes = {
  orderData: PropTypes.shape({
    id: PropTypes.number,
    client_id: PropTypes.number,
    visit_id: PropTypes.number,
    client_country_id: PropTypes.number,
    client_name: PropTypes.string,
    client_city: PropTypes.string,
    client_street: PropTypes.string,
    client_postal_code: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({})),
    payment_discount_id: PropTypes.number,
    payment_method_id: PropTypes.number,
    payment_date: PropTypes.string,
    is_direct: PropTypes.number,
    wholesaler_id: PropTypes.number,
    order_date: PropTypes.string,
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
    promotions: PropTypes.arrayOf(PropTypes.shape({})),
    promotion_packages: PropTypes.arrayOf(PropTypes.shape({})),
    manual_promotions: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  client: PropTypes.shape({
    id: PropTypes.number,
    country_id: PropTypes.number,
    name: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    postal_code: PropTypes.string
  }),
  visitId: PropTypes.number,
  visit: PropTypes.shape({
    id: PropTypes.number.isRequired,
    place: PropTypes.string.isRequired,
    scheduled_at: PropTypes.string.isRequired
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(OrderForm);
