import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ProductsApi from 'api/connections/Products/ProductsApi';
import UsersApi from 'api/connections/Users/UsersApi';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';
import StatisticsExportApi from 'api/connections/Statistics/StatisticsExportApi';
import { downloadFile } from 'modules/Shared/utils/file';
import IconButton from 'modules/Layout/component/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import StatisticsGratisProductsReportFilter from 'modules/Statistics/GratisProductsReport/component/List/Filter';
import StatisticsGratisProductsReportTable from 'modules/Statistics/GratisProductsReport/component/List/Table';

class StatisticsGratisProductsReport extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const { user } = context;

    this.state = {
      filter: {
        date_from: '',
        date_to: '',
        role: 'trader',
        department_id: context.hasPermission([DEPARTMENTS_LIST])
          ? null
          : user.departments[0]?.id,
        user_id: null,
        products: []
      },
      filterValidation: {},
      statistics: [],
      departments: context.hasPermission([DEPARTMENTS_LIST])
        ? []
        : user.departments,
      users: [],
      products: [],
      loading: false,
      loadingDepartments: false,
      loadingUsers: false,
      loadingProducts: false
    };

    this.setFilter = this.setFilter.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.fetchStatistics = this.fetchStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportStatistics = this.exportStatistics.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Gratis products report');

    if (this.context.hasPermission([DEPARTMENTS_LIST])) {
      this.fetchDepartments();
      this.fetchProducts();
    }

    this.fetchStatistics();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setFilter(filter) {
    const isDepartmentOrRoleChange =
      this.state.filter.department_id !== filter.department_id ||
      this.state.filter.role !== filter.role;

    this.setState(
      state => ({
        filter: {
          ...state.filter,
          ...filter,
          products: state.filter.products,
          user_id: isDepartmentOrRoleChange ? null : filter.user_id
        },
        pagination: { ...state.pagination, page: 1 },
        users: isDepartmentOrRoleChange ? [] : state.users
      }),
      () => {
        if (isDepartmentOrRoleChange) {
          this.fetchUsers();
        }

        this.fetchStatistics();
      }
    );
  }

  updateProducts(name, value) {
    this.setState({
      filter: { ...this.state.filter, [name]: value }
    });
  }

  async fetchDepartments() {
    try {
      this.setState({ loadingDepartments: true });

      const {
        data: { data: departments }
      } = await DepartmentsApi.getDepartments({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        departments,
        loadingDepartments: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchProducts() {
    try {
      this.setState({ loadingProducts: true });

      const {
        data: { data: products }
      } = await ProductsApi.getAllProducts();

      this.setState({
        products,
        loadingProducts: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchUsers() {
    const { role, department_id } = this.state.filter;

    if (!role || !department_id) return null;

    try {
      this.setState({ loadingUsers: true });

      const {
        data: { data: users }
      } = await UsersApi.getUsers({
        role,
        department_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        users: users.map(user => ({
          ...user,
          full_name: `${user.first_name} ${user.last_name}`
        })),
        loadingUsers: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchStatistics() {
    const { sort, filter } = this.state;

    try {
      if (!filter.date_from || !filter.date_to || !filter.department_id)
        return null;

      this.setState({ loading: true });

      const {
        data: { data: statistics }
      } = await StatisticsApi.getGratisProductsReport({
        ...sort,
        ...filter
      });

      this.setState({
        statistics,
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportStatistics() {
    try {
      const { filter, sort } = this.state;

      const res = await StatisticsExportApi.exportGratisProductsReport({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { date_from, date_to, department_id } = this.state.filter;

    return (
      <Box display='flex'>
        <IconButton
          onClick={this.exportStatistics}
          icon={
            <GetAppOutlinedIcon
              color={
                date_from && date_to && department_id ? 'primary' : 'disabled'
              }
              fontSize='large'
            />
          }
          alt='export table'
          disabled={!date_from || !date_to || !department_id}
          tooltipMsg='Pick dates and department'
        />
      </Box>
    );
  }

  render() {
    const {
      statistics,
      departments,
      products,
      users,
      filter,
      filterValidation,
      loading,
      loadingDepartments,
      loadingProducts,
      loadingUsers
    } = this.state;

    const { date_from, date_to, department_id } = this.state.filter;

    return (
      <div>
        <StatisticsGratisProductsReportFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          onMultiselectFilterChange={this.updateProducts}
          departments={departments}
          products={products}
          users={users}
          loadingDepartments={loadingDepartments}
          loadingProducts={loadingProducts}
          loadingUsers={loadingUsers}
        />

        {this.renderActions()}
        <StatisticsGratisProductsReportTable
          statistics={statistics}
          loading={loading}
          bodyText={
            !date_from || !date_to || !department_id
              ? 'Pick dates and department'
              : null
          }
        />
        {this.renderActions()}
      </div>
    );
  }
}

StatisticsGratisProductsReport.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default StatisticsGratisProductsReport;
