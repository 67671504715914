import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import Checkbox from 'modules/Layout/component/Checkbox';
import { Box } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

import AlertContext from 'modules/Shared/context/Alert/alertContext';
import SalesImportsApi from 'api/connections/SalesImports/SalesImportsApi';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';

class SetWholesalerDialog extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.onSuccess = props.onSuccess;
    this.onClose = props.onClose;
    this.salesImport = props.salesImport;

    this.state = {
      formData: {
        wholesaler_id: '',
        save_email: false
      },
      validation: {
        wholesaler_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Wholesaler')
          })
        }
      },
      loading: false,
      wholesalers: []
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.fetchWholesaler();
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.onSuccess();
    this.onClose();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, type } = e.target;
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async fetchWholesaler() {
    try {
      const {
        data: { data: wholesalers }
      } = await WholesalersApi.getWholesalers({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({ wholesalers });
    } catch (err) {
      this.handleError(err);
    }
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      await SalesImportsApi.setWholesaler(this.salesImport.id, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { wholesaler_id, save_email },
      validation,
      loading,
      wholesalers
    } = this.state;

    const { message_from, message_date } = this.salesImport;

    return (
      <Dialog
        open
        onClose={this.onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Set wholesaler')}</DialogTitle>
        <DialogContent>
          <Box>
            <TextDataSet
              label='Data received from address'
              data={message_from}
            />
            <TextDataSet label='Import file receipt date' data={message_date} />
          </Box>
          <form id='set-wholesaler-form' noValidate onSubmit={this.onSubmit}>
            <Select
              name='wholesaler_id'
              label='Wholesaler'
              value={wholesaler_id}
              required
              options={formatOptions(wholesalers, 'id', 'name')}
              onChange={this.onChange}
              errorStatus={validation.wholesaler_id.status}
              errorText={validation.wholesaler_id.message}
            />
            <Checkbox
              name='save_email'
              label='Remember the email address for the selected warehouse'
              checked={save_email}
              onChange={this.onChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.onClose} />
          <Button
            form='set-wholesaler-form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

SetWholesalerDialog.propTypes = {
  salesImport: PropTypes.shape({}).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SetWholesalerDialog;
