export default {
  Imports: 'Importy',
  'Data imports': 'Importy Danych Sprzedażowych',
  'Sales imports data': 'Importy danych sprzedażowych',
  'Sales import details': 'Szczegóły importu danych sprzedażowych',
  'Sales imports': 'Importy',
  'Started by': 'Rozpoczęty przez',
  'Data received from address': 'Dane otrzymany z adresu',
  'Import file receipt date': 'Data otrzymania pliku importu',
  'Processed count': 'Liczba poprawnych wpisów',
  'Wrong count': 'Liczba błędnych wpisów',
  'Temporary count': 'Liczba tymczasowych wpisów',
  'Number of withdrawn import data': 'Liczba wycofanych danych importu',
  'Import data withdrawn by': 'Dane importu wycofany przez',
  'Reason for withdrawal': 'Powód wycofania',
  'Date of withdrawal': 'Data wycofania',
  'Download import log': 'Pobierz log importu',
  'New clients': 'Nowi klienci',
  'Temporary products (ready to map)':
    'Tymczasowe produkty (gotowe do mapowania)',
  'Temporary clients (ready to map)':
    'Tymczasowi klienci (gotowe do mapowania)',
  'All statuses': 'Wszystkie statusy',
  'Unknown wholesaler': 'Nierozpoznana hurtownia',
  'Waiting for validation': 'Oczekuje na walidację',
  'Validation success': 'Walidacja pozytywna',
  'Validation error': 'Walidacja negatywna',
  'Unmapped products or clients': 'Niezmapowane produkty lub klienci',
  Imported: 'Zaimportowane',
  Canceled: 'Wycofany',
  'Import have wrong status': 'Import posiada nieodpowiedni status',
  'Cancel confirmation': 'Potwierdzenie wycofania',
  Reason: 'Powód',
  'Are you sure you want to cancel sales import from wholesaler <%=wholesalerName%>, Processed count is <%=processedCount%>':
    'Czy jesteś pewien że chcesz wycofać dane importu z hurtowni <%=wholesalerName%>, Liczba poprawnych wpisów wynosi <%=processedCount%>',
  'Remember the email address for the selected warehouse':
    'Zapamiętaj adres email dla wybranej hurtowni',
  'Wholesaler is known': 'Hurtownia jest znana',
  'Set wholesaler': 'Przypisz hurtownie',
  'Map temporary product': 'Mapowanie tymczasowych produktów',
  'Map temporary client': 'Mapowanie tymczasowych klientów',
  'Create sales import': 'Dodawanie importu sprzedażowego',
  'File is required': 'Plik jest wymagany',
  'Wrong file type, valid types are: csv, ods, xls, xslx':
    'Błędny typ pliku, poprawne typy to: csv, ods, xls, xslx',
  'Data received by': 'Dane otrzymane poprzez',
  'Manual import': 'Import ręczny',
  'Import start date': 'Data uruchomienia importu',
  'Download import original file': 'Pobierz oryginalny plik importu',
  'Invalid import': 'Błędny import',
  'Missing import': 'Brakujący import',
  'Wholesaler client number': 'Numer klienta hurtowni',
  'Wholesaler client name': 'Nazwa klienta hurtowni',
  'Client mapped': 'Klient zmapowany',
  'withdraw data': 'wycofaj dane',
  'Error type': 'Typ błędu',
  'mapping missing': 'brak mapowania',
  'wrong name': 'błędna nazwa',
  'wrong tax number': 'błędny NIP',
  'Use the product in identical cases in this import':
    'Użyj produktu w identycznych przypadkach w tym imporcie',
  'Use the client in identical cases in this import':
    'Użyj klienta w identycznych przypadkach w tym imporcie',
  'Save and use mapping in identical cases in all imports':
    'Zapisz i użyj mapowanie w identycznych przypadkach we wszystkich importach'
};
