import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import FileUploadButton from 'modules/Layout/component/FileUploadButton';

import { validate } from 'modules/Shared/utils/validator';
import { ROUTE_VISITS_DETAILS } from 'routing/routes/Visits';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import insertPathParams from 'api/utils/insertPathParams';
import t from 'translate/translate';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import VisitFilesApi from 'api/connections/Visits/VisitFilesApi';

class VisitFileCreateForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.visitId = this.props.visitId;
    this.state = {
      formData: {
        file: '',
        name: '',
        description: ''
      },
      extension: null,
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.redirectToVisitDetailsFiles = this.redirectToVisitDetailsFiles.bind(
      this
    );
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.redirectToVisitDetailsFiles();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onUpload(file, fileNameAndExtension = {}) {
    const { fileName, fileExtension } = fileNameAndExtension;

    this.setState(prevState => {
      const { formData } = prevState;
      return {
        formData: {
          ...formData,
          name: fileName || '',
          file: file || ''
        },
        extension: fileExtension || ''
      };
    });
  }

  redirectToVisitDetailsFiles() {
    let path = insertPathParams(ROUTE_VISITS_DETAILS, {
      id: this.visitId
    });
    path += '?tab_index=general_data';
    this.props.history.push(path);
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const {
      formData: { file, name, description }
    } = this.state;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('description', description);

    this.setState({ loading: true });
    try {
      await VisitFilesApi.createVisitFile(this.visitId, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { name, description },
      extension,
      validation,
      loading
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <FileUploadButton name='file' onUpload={this.onUpload} />

        {extension && (
          <Box display='flex' alignItems='flex-end'>
            <Input
              name='name'
              label='Name'
              value={name}
              required
              autoFocus
              onChange={this.onChange}
              errorStatus={validation.name.status}
              errorText={validation.name.message}
            />

            <Typography
              variant='h5'
              component='h1'
              style={{ margin: '0.5rem 1rem' }}
            >
              {`.${extension}`}
            </Typography>
          </Box>
        )}
        <Input
          name='description'
          label='Description'
          value={description}
          onChange={this.onChange}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button
            text={t('Cancel')}
            onClick={this.redirectToVisitDetailsFiles}
          />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

VisitFileCreateForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  visitId: PropTypes.number.isRequired
};

export default withRouter(VisitFileCreateForm);
