import { Component } from 'react';
import { Box, Paper } from '@material-ui/core';
import CreateUserForm from 'modules/Users/component/Create/Form';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ApiError from 'api/exceptions/ApiError';
import PropTypes from 'prop-types';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';

class UsersCreate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      countries: []
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  async componentDidMount() {
    this.setCurrentPage('Adding an user');
    if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
      await this.fetchData();
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: countriesData }
      } = await DepartmentsApi.getCountries();
      this.setState({ countries: countriesData });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <CreateUserForm
              countries={this.state.countries}
              setAlert={this.setAlert}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

UsersCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default UsersCreate;
