import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class VisitFilesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.visitFilesPath = 'visits/:id/files';
    this.visitFilePath = 'visits/:visitId/files/:fileId';
    this.exportVisitsPath = 'visits/export';
  }

  getVisitFiles(id, pathParams) {
    return this.get(insertPathParams(this.visitFilesPath, { id }), pathParams);
  }

  createVisitFile(id, payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return this.post(
      insertPathParams(this.visitFilesPath, { id }),
      payload,
      options
    );
  }

  updateVisitFile(ids, payload) {
    return this.put(insertPathParams(this.visitFilePath, ids), payload);
  }

  deleteVisitFile(ids) {
    return this.delete(insertPathParams(this.visitFilePath, ids));
  }

  getVisitFile(ids) {
    const options = {
      responseType: 'blob'
    };
    return this.get(insertPathParams(this.visitFilePath, ids), {}, options);
  }

  exportVisits(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportVisitsPath, pathParams, options);
  }
}

export default new VisitFilesApi();
