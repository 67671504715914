import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

import plLocale from 'date-fns/locale/pl';
import enLocale from 'date-fns/locale/en-US';

class LocalizedUtils extends DateFnsUtils {
  dateFormat = this.locale.formatLong.date({ width: 'short' });

  time24hFormat = this.locale.formatLong.time({ width: 'medium' });

  dateTime24hFormat = this.locale.formatLong
    .dateTime({ width: 'full' })
    .replace('{{date}}', this.dateFormat)
    .replace('{{time}}', this.time24hFormat);

  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const MuiPickersProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [locale, setLocale] = useState(plLocale);

  useEffect(() => {
    if (user && user.language === 'en') {
      setLocale(enLocale);
    }
  }, [user]);

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

MuiPickersProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default MuiPickersProvider;
