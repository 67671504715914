import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import SalesResultsCharts from 'modules/SalesResults/component/ResultsDetails/Charts';
import SalesResultsNavigationBar from 'modules/SalesResults/component/ResultsDetails/NavigationBar';
import SalesResultsLegend from 'modules/SalesResults/component/ResultsDetails/Legend';
import SalesResultsApi from 'api/connections/SalesResults/SalesResultsApi';
import formatSalesResults from 'modules/SalesResults/utils/formatSalesResults';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import Loader from 'modules/Layout/component/Loader';
import { isEqual } from 'lodash/lang';
import IconButton from 'modules/Layout/component/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class InDepartmentComparisonSalesResultsWrapper extends Component {
  static contextType = AlertContext;

  static renderLoading() {
    return <Loader />;
  }

  static renderNoData() {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='textPrimary'>{t('No data')}</Typography>
      </Box>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      stack: [],
      breadcrumbs: [t('Comprehensive data')],
      viewType: 'pie',
      renderData: null,

      salesResultData: {}
    };

    this.drillUp = this.drillUp.bind(this);
    this.drillDown = this.drillDown.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.filter, this.props.filter)) {
      this.fetchData();
    }
  }

  drillDown(data) {
    if (data.children.length > 0) {
      this.setState(state => {
        return {
          stack: [...state.stack, state.renderData],
          renderData: data.children,
          breadcrumbs: [...state.breadcrumbs, data.name]
        };
      });
    }
  }

  drillUp(index) {
    this.setState(state => {
      if (state.breadcrumbs.length <= 1) return { ...state };
      return {
        renderData: state.stack[index],
        breadcrumbs: state.breadcrumbs.slice(0, index + 1),
        stack: state.stack.slice(0, index)
      };
    });
  }

  async fetchData() {
    const { period_type, period, quarter } = this.props.filter;

    if (period_type === 'quarter') {
      if (!period || !quarter) return null;
    }

    if (!period) {
      return null;
    }

    try {
      const {
        data: salesResultData
      } = await SalesResultsApi.getSalesResultsDetails({
        department_id: this.props.departmentId,
        period_type,
        period,
        quarter
      });

      const formattedSalesResultsData = formatSalesResults(
        salesResultData.data
      );

      this.setState({
        salesResultData,
        renderData: formattedSalesResultsData,
        stack: [],
        breadcrumb: []
      });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  renderData() {
    const { breadcrumbs, renderData } = this.state;
    const { viewType, quantityOrValue } = this.props;
    const nameSuffix = Date.now();

    return (
      <>
        <Box display='flex' flexDirection='row-reverse'>
          <IconButton
            className='delete-icon'
            onClick={this.props.onDeleteClick}
            icon={<CloseIcon />}
            size='small'
            alt='close chart'
          />
        </Box>
        <SalesResultsNavigationBar
          breadcrumbs={breadcrumbs}
          onDrillUp={this.drillUp}
          viewType={viewType}
        />
        <SalesResultsCharts
          salesResultsData={renderData}
          viewType={viewType}
          quantityOrValue={quantityOrValue}
          onDrillDown={this.drillDown}
          nameSuffix={nameSuffix}
        />
        <SalesResultsLegend nameSuffix={nameSuffix} />
      </>
    );
  }

  renderContent() {
    const { loading, renderData } = this.state;

    if (loading) {
      return InDepartmentComparisonSalesResultsWrapper.renderLoading();
    }

    if (!renderData || renderData.length === 0) {
      return InDepartmentComparisonSalesResultsWrapper.renderNoData();
    }

    return this.renderData();
  }

  render() {
    return this.renderContent();
  }
}

InDepartmentComparisonSalesResultsWrapper.propTypes = {
  departmentId: PropTypes.number.isRequired,
  filter: PropTypes.shape({
    period_type: PropTypes.string,
    quarter: PropTypes.string,
    period: PropTypes.string
  }).isRequired,
  viewType: PropTypes.string.isRequired,
  quantityOrValue: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default InDepartmentComparisonSalesResultsWrapper;
