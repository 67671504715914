import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';

import ProductsTreeCategories from 'modules/Visits/components/Details/Products/Add/ProductsTreeCategories';

class ProductsTreeGroups extends Component {
  render() {
    const {
      categoriesObj,
      productsData,
      treeItemsToDisplay,
      selected,
      onClick,
      onSelect
    } = this.props;

    return categoriesObj.map(item => {
      return (
        <TreeItem
          itemData={item}
          key={item.id}
          nodeId={item.id.toString()}
          labelText={item.name}
        >
          {!isEmpty(item.categories) && (
            <ProductsTreeCategories
              categoriesObj={item.categories}
              productsData={productsData}
              treeItemsToDisplay={treeItemsToDisplay}
              selected={selected}
              onClick={onClick}
              onSelect={onSelect}
            />
          )}
        </TreeItem>
      );
    });
  }
}

ProductsTreeGroups.propTypes = {
  categoriesObj: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsData: PropTypes.shape({}).isRequired,
  treeItemsToDisplay: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.number),
    categories: PropTypes.arrayOf(PropTypes.number),
    subcategories: PropTypes.arrayOf(PropTypes.number),
    products: PropTypes.arrayOf(PropTypes.number)
  }),
  selected: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

ProductsTreeGroups.defaultProps = {
  treeItemsToDisplay: null
};

export default ProductsTreeGroups;
