import BaseApiConfig from 'api/connections/BaseApiConfig';

class SalesResults extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.salesResultsPath = 'sales/periods';
    this.salesResultsDetailsPath = 'sales/periods/details';
    this.userSalesResultsDetailsPath = 'sales/users-results/details';
    this.closeSalesResultsPath = 'sales/periods/close';
    this.currentSalesResultsPath = 'sales/current-details';
    this.exportCurrentSalesResultsPath = 'sales/current-details/export';
  }

  getSalesResults(pathParams) {
    return this.get(this.salesResultsPath, pathParams);
  }

  getSalesResultsDetails(pathParams) {
    return this.get(this.salesResultsDetailsPath, pathParams);
  }

  getUserSalesResultsDetails(pathParams) {
    return this.get(this.userSalesResultsDetailsPath, pathParams);
  }

  getCurrentSalesResults(pathParams) {
    return this.get(this.currentSalesResultsPath, pathParams);
  }

  exportCurrentSalesResults(pathParams) {
    return this.get(this.exportCurrentSalesResultsPath, pathParams);
  }

  closeSalesPeriod(payload) {
    return this.post(this.closeSalesResultsPath, payload);
  }
}

export default new SalesResults();
