import { Component } from 'react';
import PropTypes from 'prop-types';
import insertPathParams from 'api/utils/insertPathParams';
import { Box, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import DashboardListItem from 'modules/Dashboard/component/Layout/ListItem';
import DashboardList from 'modules/Dashboard/component/Layout/List';
import ProductsApi from 'api/connections/Products/ProductsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';

class DashboardTopSellers extends Component {
  static contextType = AuthContext;

  static getInformationBox(topSeller) {
    const { sku, bought_quantity } = topSeller;

    return (
      <Box display='flex' flexDirection='column'>
        <Typography style={{ fontWeight: '600' }}>
          {sku} - {bought_quantity}
        </Typography>
      </Box>
    );
  }

  static getRedirectLink({ id }) {
    return insertPathParams(ROUTE_PRODUCTS_DETAILS, { id });
  }

  static renderItem(topSeller) {
    const informationBox = DashboardTopSellers.getInformationBox(topSeller);
    const redirectLink = DashboardTopSellers.getRedirectLink(topSeller);

    return (
      <DashboardListItem
        key={topSeller.id}
        informationBox={informationBox}
        redirectLink={redirectLink}
      />
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      topSellers: undefined,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: { data: topSellers }
      } = await ProductsApi.getNotSoldTopSellers();

      this.setState({ topSellers, loading: false });
    } catch (err) {
      this.setState({ topSellers: undefined, loading: false });
      this.props.handleError(err);
    }
  }

  render() {
    const { loading, topSellers } = this.state;

    if (loading) return <Loader />;

    return (
      <DashboardList
        data={topSellers}
        renderItem={DashboardTopSellers.renderItem}
      />
    );
  }
}

DashboardTopSellers.defaultProps = {
  handleError: null
};

DashboardTopSellers.propTypes = {
  handleError: PropTypes.func
};

export default DashboardTopSellers;
