import { Route } from 'react-router-dom';
import {
  USERS_LIST,
  USERS_CREATE,
  USERS_UPDATE
} from 'api/auth/permissions/Users';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';
import UsersList from 'modules/Users/view/List';
import UsersCreate from 'modules/Users/view/Create';
import UsersDetailsWrapper from 'modules/Users/view/Details/DetailsWrapper';
import UsersUpdate from 'modules/Users/view/Update';
import { SALES_PERIODS_READ } from 'api/auth/permissions/SalesResults';
import SalesResultsDetails from 'modules/SalesResults/view/Details';

export const ROUTE_USERS_LIST = '/users';
export const ROUTE_USERS_CREATE = '/users/create';
export const ROUTE_USERS_DETAILS = '/users/details/:id';
export const ROUTE_USERS_UPDATE = '/users/update/:id';
export const ROUTE_USERS_SALES_RESULT = '/users/details/:user_id/sales_results';

export const permMap = {
  [ROUTE_USERS_LIST]: [USERS_LIST],
  [ROUTE_USERS_CREATE]: [USERS_CREATE],
  [ROUTE_USERS_UPDATE]: [USERS_UPDATE],
  [ROUTE_USERS_DETAILS]: [USERS_LIST],
  [ROUTE_USERS_SALES_RESULT]: [SALES_PERIODS_READ]
};

const routes = {
  protected: [
    <Route key='users' exact path={ROUTE_USERS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_USERS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <UsersList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='users-create' exact path={ROUTE_USERS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_USERS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <UsersCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='users-update' exact path={ROUTE_USERS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_USERS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <UsersUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='users-details' exact path={ROUTE_USERS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_USERS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <UsersDetailsWrapper contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='users-sales-result' exact path={ROUTE_USERS_SALES_RESULT}>
      <Authorize redirect permissions={permMap[ROUTE_USERS_SALES_RESULT]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesResultsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
