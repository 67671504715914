import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import { Box, Grid, Paper } from '@material-ui/core';
import SalesResultsWrapper from 'modules/SalesResults/component/CurrentComparison/Wrapper';
import ApiError from 'api/exceptions/ApiError';
import SalesResultsViewTypeButtons from 'modules/SalesResults/component/ResultsDetails/ViewTypeButtons';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import {
  POSTAL_CODES_COUNTRIES_SHOW,
  POSTAL_CODES_SHOW,
  POSTAL_CODES_REGIONS_SHOW
} from 'api/auth/permissions/PostalCodes';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import CurrentComparisonSalesResultsFilter from 'modules/SalesResults/component/CurrentComparison/Filter';
import Typography from '@material-ui/core/Typography';

const pathParam = { per_page: Number.MAX_SAFE_INTEGER };

class SalesResultsCurrentComparison extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      viewType: 'pie',
      quantityOrValue: 'quantity',
      filters: {
        filterOne: {
          date_from: null,
          date_to: null,
          client_id: null,
          sales_group_id: null,
          regions: [],
          subregions: [],
          subsubregions: []
        },
        filterTwo: {
          date_from: null,
          date_to: null,
          client_id: null,
          sales_group_id: null,
          regions: [],
          subregions: [],
          subsubregions: []
        },
        filterThree: {
          date_from: null,
          date_to: null,
          client_id: null,
          sales_group_id: null,
          regions: [],
          subregions: [],
          subsubregions: []
        }
      },
      filterData: {
        regionsData: undefined,
        subregionsData: undefined,
        subsubregionsData: undefined,
        clientsData: undefined,
        salesGroupsData: undefined
      },
      regionsLoading: true,
      clientsLoading: true
    };

    this.onViewTypeChange = this.onViewTypeChange.bind(this);
    this.onQuantityOrValueChange = this.onQuantityOrValueChange.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Comparison of sales results');
    this.fetchRegions();
    this.fetchClients();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  }

  onViewTypeChange(viewType) {
    this.setState({ viewType });
  }

  onQuantityOrValueChange(quantityOrValue) {
    this.setState({ quantityOrValue });
  }

  onFilterChange(e, filterName) {
    const { name, value } = e.target;

    this.setState(state => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterName]: {
            ...state.filters[filterName],
            [name]: value
          }
        }
      };
    });
  }

  onDeleteClick(filterName) {
    this.setState(state => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterName]: {
            ...state.filters[filterName],
            date_from: null,
            date_to: null,
            client_id: null,
            sales_group_id: null,
            regions: [],
            subregions: [],
            subsubregions: []
          }
        }
      };
    });
  }

  async fetchRegions() {
    let countriesData;
    let regionsData;
    let subregionsData;
    let subsubregionsData;

    try {
      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countries }
        } = await PostalCodesApi.getCountries();
        countriesData = countries;
      }

      if (this.context.hasPermission([POSTAL_CODES_REGIONS_SHOW])) {
        const {
          data: { data: regions }
        } = await PostalCodesApi.getRegions(pathParam);
        regionsData = regions;
      }

      if (this.context.hasPermission([POSTAL_CODES_SHOW])) {
        const [
          {
            data: { data: subregions }
          },
          {
            data: { data: subsubregions }
          }
        ] = await Promise.all([
          PostalCodesApi.getSubRegions(pathParam),
          PostalCodesApi.getSubSubRegions(pathParam)
        ]);

        subregionsData = subregions;
        subsubregionsData = subsubregions;
      }

      this.setState(state => {
        return {
          ...state,
          filterData: {
            ...state.filterData,
            countriesData,
            regionsData,
            subregionsData,
            subsubregionsData
          },
          regionsLoading: false
        };
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchClients() {
    try {
      const [
        {
          data: { data: clientsData }
        },
        {
          data: { data: salesGroupsData }
        }
      ] = await Promise.all([
        ClientsApi.getAllClients(pathParam),
        SalesGroupsApi.getSalesGroups(pathParam)
      ]);

      this.setState(state => {
        return {
          ...state,
          filterData: {
            ...state.filterData,
            clientsData,
            salesGroupsData
          },
          clientsLoading: false
        };
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      viewType,
      quantityOrValue,
      filterData,
      regionsLoading,
      clientsLoading
    } = this.state;
    const { filterOne, filterTwo, filterThree } = this.state.filters;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Paper className='p1'>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
            >
              <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }}>
                <Box mr={1}>
                  <CurrentComparisonSalesResultsFilter
                    filterTitle='First chart'
                    filter={filterOne}
                    filterData={filterData}
                    regionsLoading={regionsLoading}
                    clientsLoading={clientsLoading}
                    onFilterChange={e => this.onFilterChange(e, 'filterOne')}
                  />
                </Box>
                <Box mr={1}>
                  <CurrentComparisonSalesResultsFilter
                    filterTitle='Second chart'
                    filter={filterTwo}
                    filterData={filterData}
                    regionsLoading={regionsLoading}
                    clientsLoading={clientsLoading}
                    onFilterChange={e => this.onFilterChange(e, 'filterTwo')}
                  />
                </Box>
                <Box>
                  <CurrentComparisonSalesResultsFilter
                    filterTitle='Third chart'
                    filter={filterThree}
                    filterData={filterData}
                    regionsLoading={regionsLoading}
                    clientsLoading={clientsLoading}
                    onFilterChange={e => this.onFilterChange(e, 'filterThree')}
                  />
                </Box>
              </Box>
              <Box width='40%'>
                <SalesResultsViewTypeButtons
                  viewType={viewType}
                  quantityOrValue={quantityOrValue}
                  onViewTypeChange={this.onViewTypeChange}
                  onQuantityOrValueChange={this.onQuantityOrValueChange}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className='p1'>
            <Grid container spacing={3} justify='center'>
              {(!filterOne.date_from || !filterOne.date_to) &&
                (!filterTwo.date_from || !filterTwo.date_to) &&
                (!filterThree.date_from || !filterThree.date_to) && (
                  <Box p={1}>
                    <Typography variant='body1' color='textSecondary'>
                      {t('Select the date from and to for the first chart')}
                    </Typography>
                  </Box>
                )}
              {filterOne.date_from && filterOne.date_to && (
                <Grid item xs={4}>
                  <SalesResultsWrapper
                    filter={filterOne}
                    viewType={viewType}
                    quantityOrValue={quantityOrValue}
                    onDeleteClick={() => this.onDeleteClick('filterOne')}
                  />
                </Grid>
              )}
              {filterTwo.date_from && filterTwo.date_to && (
                <Grid item xs={4}>
                  <SalesResultsWrapper
                    filter={filterTwo}
                    viewType={viewType}
                    quantityOrValue={quantityOrValue}
                    onDeleteClick={() => this.onDeleteClick('filterTwo')}
                  />
                </Grid>
              )}
              {filterThree.date_from && filterThree.date_to && (
                <Grid item xs={4}>
                  <SalesResultsWrapper
                    filter={filterThree}
                    viewType={viewType}
                    quantityOrValue={quantityOrValue}
                    onDeleteClick={() => this.onDeleteClick('filterThree')}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

SalesResultsCurrentComparison.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default SalesResultsCurrentComparison;
