import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Tab } from '@material-ui/core';
import TabPanel from 'modules/Layout/component/TabPanel';
import Tabs from 'modules/Layout/component/Tabs';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import t from 'translate/translate';

import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { ROUTE_SHOPPING_GROUPS_LIST } from 'routing/routes/ShoppingGroups';
import SalesGroupsDetailsBasicData from 'modules/SalesGroups/view/Details/BasicData';
import SalesGroupDetailFiles from 'modules/SalesGroups/view/Details/Files';
import SalesGroupDetailsNotes from 'modules/SalesGroups/view/Details/Notes';
import SalesGroupDetailsDiscounts from 'modules/SalesGroups/view/Details/Discounts';
import SalesGroupDetailsSalesSummary from 'modules/SalesGroups/view/Details/SalesSummary';

class SalesGroupDetailsWrapper extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.salesGroupId = parseInt(props.match.params.id, 10);
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      salesGroup: {},
      countries: [],
      isUserAssignToGroupClient: true
    };

    this.onAssignClientToSalesGroupSuccess = this.onAssignClientToSalesGroupSuccess.bind(
      this
    );
    this.onDetachClientFromSalesGroupSuccess = this.onDetachClientFromSalesGroupSuccess.bind(
      this
    );
  }

  componentDidMount() {
    if (!this.salesGroupId) {
      this.props.history.push(ROUTE_SHOPPING_GROUPS_LIST);
    }
    this.setCurrentPage('Shopping group details');
    this.fetchData();
  }

  onAssignClientToSalesGroupSuccess(client) {
    const { clients } = this.state.salesGroup;

    const clientAlreadyAssignStatus = clients.find(c => c.id === client.id);

    if (!clientAlreadyAssignStatus) {
      this.setState(prevState => {
        return {
          ...prevState,
          salesGroup: {
            ...prevState.salesGroup,
            clients: [...prevState.salesGroup.clients, client]
          }
        };
      });
    }
  }

  onDetachClientFromSalesGroupSuccess(client) {
    this.setState(prevState => {
      return {
        ...prevState,
        salesGroup: {
          ...prevState.salesGroup,
          clients: prevState.salesGroup.clients.filter(c => c.id !== client.id)
        }
      };
    });
  }

  async fetchData() {
    try {
      let countries = [];
      const {
        data: { data: salesGroup }
      } = await SalesGroupsApi.getSalesGroup(this.salesGroupId);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        salesGroup,
        countries
      });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_SHOPPING_GROUPS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { salesGroup, countries, isUserAssignToGroupClient } = this.state;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Tabs>
            <Tab label={t('General data')} value='general_data' />
            <Tab label={t('Files')} value='files' />
            <Tab label={t('Notes')} value='notes' />
            <Tab label={t('Discounts')} value='discounts' />
            <Tab label={t('Sales summary')} value='sales_summary' />
          </Tabs>
          <TabPanel index='general_data'>
            <SalesGroupsDetailsBasicData
              salesGroup={salesGroup}
              countries={countries}
              onAssignClientToSalesGroupSuccess={
                this.onAssignClientToSalesGroupSuccess
              }
              onDetachClientFromSalesGroupSuccess={
                this.onDetachClientFromSalesGroupSuccess
              }
            />
          </TabPanel>
          <TabPanel index='files'>
            <SalesGroupDetailFiles
              isUserAssignToGroupClient={isUserAssignToGroupClient}
              setAlert={this.setAlert}
            />
          </TabPanel>
          <TabPanel index='notes'>
            <SalesGroupDetailsNotes
              isUserAssignToGroupClient={isUserAssignToGroupClient}
              setAlert={this.setAlert}
            />
          </TabPanel>
          <TabPanel index='discounts'>
            <SalesGroupDetailsDiscounts
              setAlert={this.setAlert}
              salesGroupName={salesGroup.name}
            />
          </TabPanel>
          <TabPanel index='sales_summary'>
            <SalesGroupDetailsSalesSummary
              salesGroupId={this.salesGroupId}
              setAlert={this.setAlert}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

SalesGroupDetailsWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(SalesGroupDetailsWrapper);
