import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validateBaseOnFormInputs } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ProductPriorityApi from 'api/connections/Products/ProductPriorityApi';
import priorityOptions from 'modules/Products/priorityTypes';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import DatePicker from 'modules/Layout/component/Date/DatePicker';

class ProductPriorityFormDialog extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.priority = props.priority;

    this.state = {
      formData: {
        value: this.priority?.value || '',
        date_from: this.priority?.date_from || '',
        date_to: this.priority?.date_to || ''
      },
      validation: {
        value: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Priority')
          })
        },
        date_from: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Date from')
          })
        },
        date_to: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Date to')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onClose();
    this.props.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const validateBaseOnFormInputsState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...validateBaseOnFormInputsState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation(e.target.elements);
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation(formElements) {
    const { validation } = this.state;
    const [isValid, validateBaseOnFormInputsState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!isValid) {
      this.setState({ validation: validateBaseOnFormInputsState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    const { productId } = this.props;
    this.setState({ loading: true });

    try {
      if (this.priority) {
        const ids = {
          productId,
          priorityId: this.priority.id
        };
        await ProductPriorityApi.updateProductPriority(ids, formData);
      } else {
        await ProductPriorityApi.createProductPriority(productId, formData);
      }

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { value, date_from, date_to },
      validation,
      loading
    } = this.state;

    const { onClose } = this.props;

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>
          {t(this.priority ? 'Update priority' : 'Create priority')}
        </DialogTitle>
        <DialogContent>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            <Select
              name='value'
              label='Priority'
              value={value}
              required
              options={formatOptions(priorityOptions, 'id', 'icon')}
              onChange={this.onChange}
              errorStatus={validation.value.status}
              errorText={validation.value.message}
            />
            <DatePicker
              name='date_from'
              label='Date from'
              value={date_from}
              onChange={this.onChange}
              errorStatus={validation.date_from.status}
              errorText={validation.date_from.message}
            />
            <DatePicker
              name='date_to'
              label='Date to'
              value={date_to}
              onChange={this.onChange}
              errorStatus={validation.date_to.status}
              errorText={validation.date_to.message}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button
            form='form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

ProductPriorityFormDialog.defaultProps = {
  priority: null
};

ProductPriorityFormDialog.propTypes = {
  productId: PropTypes.number.isRequired,
  priority: PropTypes.shape({}),
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ProductPriorityFormDialog;
