import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import {
  ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_CREATE,
  ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_UPDATE
} from 'routing/routes/Products';
import insertPathParams from 'api/utils/insertPathParams';
import ProductBatchDeleteDialog from 'modules/Products/component/WholesalersMappings/DeleteDialog';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { PRODUCTS_LIST } from 'api/auth/permissions/Products';
import { Box } from '@material-ui/core';

class ProductWholesalersMappingsList extends Component {
  static contextType = AuthContext;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.mappings !== nextProps.mappings) {
      return {
        mappings: nextProps.mappings
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    const { sku, productId, mappings } = props;
    this.sku = sku;
    this.productId = productId;

    this.state = {
      mappings,
      deleteDialogOpen: false,
      mappingToDelete: null
    };
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  onUpdateClick(mapping) {
    const {
      id: mappingId,
      wholesaler_id: wholesalerId,
      wholesaler_name: wholesalerName,
      external_identifier: externalIdentifier
    } = mapping;
    let path = insertPathParams(ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_UPDATE, {
      productId: this.productId,
      mappingId
    });
    path += `?sku=${this.sku}`;
    path += `&wholesalerId=${wholesalerId}`;
    path += `&wholesalerName=${wholesalerName}`;
    path += `&externalIdentifier=${externalIdentifier}`;
    this.props.history.push(path);
  }

  onCreateClick() {
    let path = insertPathParams(ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_CREATE, {
      id: this.productId
    });
    path += `?sku=${this.sku}`;
    this.props.history.push(path);
  }

  onDeleteClick(mapping) {
    this.setState({ deleteDialogOpen: true, mappingToDelete: mapping });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, mappingToDelete: null });
  }

  getCols() {
    return [
      {
        property: 'wholesaler_name',
        label: 'Wholesaler Name',
        sortable: false
      },
      {
        property: 'external_identifier',
        label: 'External identifier',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: this.context.hasPermission([PRODUCTS_LIST]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([PRODUCTS_LIST]),
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { mappings, deleteDialogOpen, mappingToDelete } = this.state;
    const { onDeleteMappingSuccess } = this.props;

    return (
      <>
        <Table cols={this.getCols()} rows={mappings} dense actions />
        <Box display='flex' alignItems='center'>
          <IconButton
            className='create-icon'
            onClick={this.onCreateClick}
            icon={<AddIcon fontSize='large' />}
            alt='create'
          />
        </Box>
        {deleteDialogOpen && (
          <ProductBatchDeleteDialog
            mapping={mappingToDelete}
            productSku={this.sku}
            onClose={this.onDeleteDialogClose}
            onSuccess={onDeleteMappingSuccess}
          />
        )}
      </>
    );
  }
}

ProductWholesalersMappingsList.propTypes = {
  productId: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  mappings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDeleteMappingSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(ProductWholesalersMappingsList);
