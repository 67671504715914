import { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import { formatOptions } from 'modules/Layout/component/Select';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import {
  prepareCategories,
  addParentsIdsToCategoriesObj
} from 'modules/ProductCategories/utils/formater';
import Loader from 'modules/Layout/component/Loader';

class CategoryPicker extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    const { promotion } = props;

    this.state = {
      groupId: promotion?.group_id ?? null,
      categoryId: promotion?.category_id ?? null,
      subcategoryId: promotion?.subcategory_id ?? null,
      categoriesData: {
        productGroups: [],
        categories: [],
        subCategories: []
      },
      categoriesObj: null,
      loading: true
    };

    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.getGroupOptions = this.getGroupOptions.bind(this);
    this.getCategoriesOptions = this.getCategoriesOptions.bind(this);
    this.getSubcategoriesOptions = this.getSubcategoriesOptions.bind(this);
  }

  componentDidMount() {
    this.fetchCategories();
  }

  onCategoryChange(name, inputValue) {
    const value = inputValue?.key || null;

    let newState;

    this.setState(
      state => {
        const { groupId, categoryId, subcategoryId } = state;

        newState = {
          groupId,
          categoryId,
          subcategoryId
        };

        if (name === 'group') {
          newState.groupId = value;
          newState.categoryId = null;
          newState.subcategoryId = null;
        } else if (name === 'category') {
          newState.categoryId = value;
          newState.subcategoryId = null;
        } else {
          newState.subcategoryId = value;
        }

        return newState;
      },
      () => this.props.onChange(newState)
    );
  }

  getGroupOptions() {
    const { categoriesData } = this.state;

    return categoriesData.productGroups;
  }

  getCategoriesOptions() {
    const { categoriesData, categoriesObj, groupId } = this.state;

    if (!categoriesObj) {
      return [];
    }

    if (groupId) {
      return categoriesObj.find(group => group.id === groupId).categories;
    }

    return categoriesData.categories;
  }

  getSubcategoriesOptions() {
    const { categoriesData, categoriesObj, groupId, categoryId } = this.state;

    if (!categoriesObj) {
      return [];
    }

    if (categoryId) {
      return categoriesObj
        .find(group =>
          group.categories.find(category => category.id === categoryId)
        )
        .categories.find(category => category.id === categoryId)?.subcategories;
    }

    if (groupId) {
      const { categories } = categoriesObj.find(group => group.id === groupId);

      return categories.reduce((acc, category) => {
        return [...acc, ...category.subcategories];
      }, []);
    }

    return categoriesData.subCategories;
  }

  async fetchCategories() {
    try {
      const {
        data: categoriesData
      } = await ProductCategoriesApi.getProductCategories();

      this.setState({
        categoriesData: prepareCategories(categoriesData),
        categoriesObj: addParentsIdsToCategoriesObj(categoriesData),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { groupId, categoryId, subcategoryId, loading } = this.state;

    if (loading) return <Loader />;

    return (
      <>
        <Autocomplete
          label='Product group'
          name='group'
          value={groupId}
          options={formatOptions(this.getGroupOptions(), 'id', 'name')}
          onChange={(e, value) => this.onCategoryChange('group', value)}
          loading={loading}
        />
        <Autocomplete
          label='Product category'
          name='category'
          value={categoryId}
          options={formatOptions(this.getCategoriesOptions(), 'id', 'name')}
          onChange={(e, value) => this.onCategoryChange('category', value)}
          loading={loading}
        />
        <Autocomplete
          label='Product subcategory'
          name='subcategory'
          value={subcategoryId}
          options={formatOptions(this.getSubcategoriesOptions(), 'id', 'name')}
          onChange={(e, v) => this.onCategoryChange('subcategory', v)}
          loading={loading}
        />
      </>
    );
  }
}

CategoryPicker.defaultProps = {
  promotion: null
};

CategoryPicker.propTypes = {
  promotion: PropTypes.shape({
    group_id: PropTypes.number,
    category_id: PropTypes.number,
    subcategory_id: PropTypes.number
  }),
  onChange: PropTypes.func.isRequired
};

export default CategoryPicker;
