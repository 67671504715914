import { Route } from 'react-router-dom';
import { SALES_PERIODS_READ } from 'api/auth/permissions/SalesResults';
import { CLIENT } from 'api/auth/roles';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import SalesResultsList from 'modules/SalesResults/view/List';
import SalesResultsDetails from 'modules/SalesResults/view/Details';
import SalesResultsCurrentComparison from 'modules/SalesResults/view/CurrentComparison';
import ClientSalesResultsCurrentComparison from 'modules/SalesResults/view/ClientComparison';

export const ROUTE_SALES_RESULT_LIST = '/sales/sales_results';
export const ROUTE_SALES_RESULT_DETAILS = '/sales/sales_results/details';
export const ROUTE_SALES_RESULT_CURRENT_COMPARISON =
  '/sales/sales_results/comparison';
export const ROUTE_CLIENT_SALES_RESULT_CURRENT_COMPARISON =
  '/client_sales_result_comparison';

export const permMap = {
  [ROUTE_SALES_RESULT_LIST]: [SALES_PERIODS_READ],
  [ROUTE_SALES_RESULT_DETAILS]: [SALES_PERIODS_READ],
  [ROUTE_SALES_RESULT_CURRENT_COMPARISON]: [SALES_PERIODS_READ]
};

const routes = {
  protected: [
    <Route key='sales-results-list' exact path={ROUTE_SALES_RESULT_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_SALES_RESULT_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesResultsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='sales-results-details' exact path={ROUTE_SALES_RESULT_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_SALES_RESULT_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesResultsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='sales-results-current-comparison'
      exact
      path={ROUTE_SALES_RESULT_CURRENT_COMPARISON}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_SALES_RESULT_CURRENT_COMPARISON]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <SalesResultsCurrentComparison contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='sales-client-results-current-comparison'
      exact
      path={ROUTE_CLIENT_SALES_RESULT_CURRENT_COMPARISON}
    >
      <Authorize redirect roles={[CLIENT]}>
        <BasicContextConsumerWrapper
          component={fn => (
            <ClientSalesResultsCurrentComparison contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
