import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { Box, Grid, Paper } from '@material-ui/core';
import WholesalersAgenciesApi from 'api/connections/Wholesalers/WholesalersAgenciesApi';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_WHOLESALER_AGENCY_CREATE,
  ROUTE_WHOLESALER_AGENCY_UPDATE
} from 'routing/routes/Wholesalers';
import Authorize from 'modules/Auth/component/Authorize';
import { WHOLESALERS_UPDATE } from 'api/auth/permissions/Wholesalers';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';

import WholesalersAgenciesTable from 'modules/Wholesalers/component/Details/Agencies/Table';
import WholesalerAgencyDeleteDialog from 'modules/Wholesalers/component/Details/Agencies/DeleteDialog';

class WholesalersAgenciesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      agencies: [],
      pagination: {
        total: 0,
        page: 1,
        per_page: 15
      },
      loading: false,
      deleteDialogStatus: false,
      agencyToDelete: undefined
    };

    this.setPagination = this.setPagination.bind(this);
    this.fetchAgencies = this.fetchAgencies.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentDidMount() {
    this.fetchAgencies();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);
    }
  }

  onCreateClick() {
    const { wholesalerId, wholesalerName } = this.props;

    let path = insertPathParams(ROUTE_WHOLESALER_AGENCY_CREATE, {
      id: wholesalerId
    });

    path += `?wholesaler_name=${wholesalerName}`;

    this.props.history.push(path);
  }

  onUpdateClick(agency) {
    const { wholesalerId, wholesalerName } = this.props;

    let path = insertPathParams(ROUTE_WHOLESALER_AGENCY_UPDATE, {
      id: wholesalerId,
      agency_id: agency.id
    });

    path += `?wholesaler_name=${wholesalerName}`;

    this.props.history.push(path);
  }

  onDeleteClick(agencyToDelete) {
    this.setState({
      deleteDialogStatus: true,
      agencyToDelete
    });
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchAgencies()
    );
  }

  closeDialog() {
    this.setState({ deleteDialogStatus: false, agencyToDelete: undefined });
  }

  async fetchAgencies() {
    const { page, per_page } = this.state.pagination;

    try {
      this.setState({ loading: true });

      const {
        data: { data: agencies, meta }
      } = await WholesalersAgenciesApi.getWholesalerAgencies(
        this.props.wholesalerId,
        { per_page, page }
      );

      this.setState({
        agencies,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Authorize permissions={[WHOLESALERS_UPDATE]}>
            <IconButton
              onClick={this.onCreateClick}
              icon={<AddIcon color='primary' fontSize='large' />}
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const {
      agencies,
      pagination,
      loading,
      deleteDialogStatus,
      agencyToDelete
    } = this.state;

    return (
      <>
        <Grid container justify='center'>
          <Grid item xs={12} lg={10}>
            <Paper className='p1'>
              {this.renderActions()}
              <WholesalersAgenciesTable
                agencies={agencies}
                pagination={pagination}
                setPagination={this.setPagination}
                loading={loading}
                onUpdateClick={this.onUpdateClick}
                onDeleteClick={this.onDeleteClick}
              />
              {this.renderActions()}
            </Paper>
          </Grid>
        </Grid>
        {deleteDialogStatus && (
          <WholesalerAgencyDeleteDialog
            wholesalerId={this.props.wholesalerId}
            wholesalerName={this.props.wholesalerName}
            agency={agencyToDelete}
            onClose={this.closeDialog}
            onSuccess={this.fetchAgencies}
          />
        )}
      </>
    );
  }
}

WholesalersAgenciesList.propTypes = {
  wholesalerId: PropTypes.number.isRequired,
  wholesalerName: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(WholesalersAgenciesList);
