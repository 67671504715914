import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import getPathQuery from 'api/utils/getPathQuery';
import { ROUTE_PRODUCTS_LIST } from 'routing/routes/Products';
import { Box, Paper } from '@material-ui/core';
import ProductWholesalersMappingsUpdateForm from 'modules/Products/component/WholesalersMappings/Update/Form';
import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';

class ProductWholesalersMappingsUpdate extends Component {
  static contextType = CurrentPageContext;

  constructor(props) {
    super(props);
    const {
      match: {
        params: { productId, mappingId }
      },
      location: { search }
    } = props;
    this.productId = parseInt(productId, 10);
    this.mappingId = parseInt(mappingId, 10);
    this.sku = getPathQuery(search, 'sku');
    this.wholesalerId = getPathQuery(search, 'wholesalerId');
    this.wholesalerName = getPathQuery(search, 'wholesalerName');
    this.externalIdentifier = getPathQuery(search, 'externalIdentifier');
  }

  async componentDidMount() {
    if (!this.productId || !this.mappingId) {
      this.props.history.push(ROUTE_PRODUCTS_LIST);
    }

    this.context.setCurrentPage('Update product mapping');
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ProductWholesalersMappingsUpdateForm
              productId={this.productId}
              sku={this.sku}
              mappingId={this.mappingId}
              wholesalerId={this.wholesalerId}
              wholesalerName={this.wholesalerName}
              externalIdentifier={this.externalIdentifier}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ProductWholesalersMappingsUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      mappingId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ProductWholesalersMappingsUpdate);
