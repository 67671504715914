import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class TaxesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.taxesPath = 'taxes';
    this.taxPath = 'taxes/:id';
  }

  getTaxes(pathParams) {
    return this.get(this.taxesPath, pathParams);
  }

  createTax(payload) {
    return this.post(this.taxesPath, payload);
  }

  updateTax(id, payload) {
    return this.put(insertPathParams(this.taxPath, { id }), payload);
  }

  deleteTax(id) {
    return this.delete(insertPathParams(this.taxPath, { id }));
  }
}

export default new TaxesApi();
