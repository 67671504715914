import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PostalCodesBox from 'modules/PostalCodes/component/shared/PostalCodesBox';
import { ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import IconButton from 'modules/Layout/component/IconButton';
import Dialog from 'modules/Layout/component/Dialog';
import { useStyles } from 'modules/PostalCodes/component/shared/style';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import ApiError from 'api/exceptions/ValidationApiError';
import { PostalCodesContext } from 'modules/PostalCodes/context/PostalCodesProvider';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import t from 'translate/translate';
import { SUBREGION, SUBSUBREGION } from 'modules/PostalCodes/constants';
import EditIcon from '@material-ui/icons/Edit';
import {
  ROUTE_POSTAL_CODES_CREATE,
  ROUTE_POSTAL_CODES_UPDATE
} from 'routing/routes/PostalCodes';

const SubSubregionsList = () => {
  const classes = useStyles();

  const {
    selectedItem,
    handleListItemClick,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    dialogData,
    countryDepth,
    handleChosenItem
  } = useContext(PostalCodesContext);
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();

  const [subSubRegions, setSubSubRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSubSubRegions = async id => {
    try {
      setIsLoading(true);
      const {
        data: { data: subSubRegionsData }
      } = await PostalCodesApi.getSubSubRegions({
        subregion_id: id,
        per_page: Number.MAX_SAFE_INTEGER
      });
      setSubSubRegions(subSubRegionsData);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const removeItem = async () => {
    try {
      const { id } = dialogData;
      await PostalCodesApi.deleteSubSubRegion(id);
      setSubSubRegions(prevState =>
        prevState.filter(subSubRegion => subSubRegion.id !== id)
      );
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const addSubSubregion = () => {
    handleChosenItem({ type: 'sub subregion' });
    history.push(ROUTE_POSTAL_CODES_CREATE);
  };

  const updateSubSubRegion = (e, id, name) => {
    e.stopPropagation();
    handleChosenItem({
      type: 'sub subregion',
      name,
      id,
      parentType: 'subregion'
    });
    history.push(ROUTE_POSTAL_CODES_UPDATE);
  };

  useEffect(() => {
    if (selectedItem[SUBREGION]) {
      fetchSubSubRegions(selectedItem[SUBREGION]);
    }
  }, [selectedItem[SUBREGION]]);

  return (
    selectedItem[SUBREGION] &&
    countryDepth === 2 &&
    (isLoading ? (
      <CircularProgress style={{ marginLeft: 30 }} />
    ) : (
      <>
        <PostalCodesBox
          title='Sub subregion'
          data={subSubRegions}
          addFn={addSubSubregion}
        >
          {subSubRegions.map(({ name, id, cities_count }) => (
            <ListItem
              onClick={() => {
                handleListItemClick({ id, type: SUBSUBREGION });
              }}
              className={classes.listItem}
              selected={selectedItem[SUBSUBREGION] === id}
              key={`${name}${id}`}
            >
              <ListItemText
                style={{ cursor: 'default', marginRight: 20 }}
                primary={name}
              />
              <IconButton
                className='update-icon'
                onClick={e => updateSubSubRegion(e, id, name)}
                icon={<EditIcon fontSize='small' />}
                alt='update'
              />
              <IconButton
                className='delete-icon'
                onClick={e => handleOpenDialog(id, name, e, SUBSUBREGION)}
                icon={<DeleteForeverIcon />}
                tooltipMsg='Delete all the cities of this sub subregion, first.'
                disabled={cities_count > 0}
                alt='delete'
              />
            </ListItem>
          ))}
        </PostalCodesBox>
        <Dialog
          open={openDialog[SUBSUBREGION]}
          title={t('Deletion confirmation')}
          description={`${t(
            'Are you sure you want to delete sub subregion: '
          )}${dialogData.name}?`}
          onApprove={async () => {
            await removeItem();
            handleCloseDialog();
          }}
          onCancel={handleCloseDialog}
        />
      </>
    ))
  );
};

export default SubSubregionsList;
