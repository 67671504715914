import { Box, Typography } from '@material-ui/core';
import useStyles from 'modules/Visits/components/Calendar/Header/styles';
import t from 'translate/translate';

const CalendarHeader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Typography>{t('Mon.')}</Typography>
      <Typography>{t('Tue.')}</Typography>
      <Typography>{t('Wed.')}</Typography>
      <Typography>{t('Thu.')}</Typography>
      <Typography>{t('Fri.')}</Typography>
      <Typography>{t('Sat.')}</Typography>
      <Typography>{t('Sun.')}</Typography>
    </Box>
  );
};

export default CalendarHeader;
