import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class WholesalersApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.wholesalersPath = 'wholesalers';
    this.wholesalerPath = 'wholesalers/:id';
    this.exportWholesalersPath = 'wholesalers/export';
    this.importWholesalerMappingsPath = 'wholesalers/:id/mappings/import';
    this.importWholesalerClientMappingsPath =
      'wholesalers/:id/client-mappings/import';
  }

  getWholesalers(pathParams) {
    return this.get(this.wholesalersPath, pathParams);
  }

  getWholesaler(id) {
    return this.get(insertPathParams(this.wholesalerPath, { id }));
  }

  createWholesaler(payload) {
    return this.post(this.wholesalersPath, payload);
  }

  updateWholesaler(id, payload) {
    return this.put(insertPathParams(this.wholesalerPath, { id }), payload);
  }

  deleteWholesaler(id) {
    return this.delete(insertPathParams(this.wholesalerPath, { id }));
  }

  exportWholesalers(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportWholesalersPath, pathParams, options);
  }

  importMappings(id, payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return this.post(
      insertPathParams(this.importWholesalerMappingsPath, { id }),
      payload,
      options
    );
  }

  importClientMappings(id, payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return this.post(
      insertPathParams(this.importWholesalerClientMappingsPath, { id }),
      payload,
      options
    );
  }
}

export default new WholesalersApi();
