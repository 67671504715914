import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import MuiPickersProvider from 'modules/Layout/component/Date/MuiPickersProvider';
import t from 'translate/translate';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import addYears from 'date-fns/addYears';

export const API_DATE_FORMAT = 'MM.yyyy';

const parseDateString = dateStr => {
  return parse(dateStr, API_DATE_FORMAT, new Date());
};

const MonthYearPicker = props => {
  const {
    name,
    label,
    value,
    initValueNow,
    onChange,
    errorStatus,
    errorText,
    minDate,
    maxDate,
    variant,
    margin,
    fullWidth
  } = props;

  const [selectedDate, handleDateChange] = useState(() => {
    if (initValueNow) return new Date();
    if (value) return parseDateString(value);
    return null;
  });

  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  const OnDateChange = date => {
    handleDateChange(date);
    const formattedDate = format(date, API_DATE_FORMAT);
    onChange({
      target: {
        name,
        value: formattedDate
      }
    });
  };

  useEffect(() => {
    if (didMount) {
      handleDateChange(parseDateString(value));
    }
  }, [value]);

  return (
    <MuiPickersProvider>
      <MuiDatePicker
        margin={margin}
        inputVariant='outlined'
        variant={variant}
        views={['year', 'month']}
        autoOk
        fullWidth={fullWidth}
        invalidDateMessage={t(
          `Wrong Date format, correct one is ${API_DATE_FORMAT}`
        )}
        label={t(label)}
        value={selectedDate}
        onChange={OnDateChange}
        error={errorStatus}
        helperText={errorStatus && errorText}
        minDate={minDate}
        maxDate={maxDate}
      />
    </MuiPickersProvider>
  );
};

MonthYearPicker.defaultProps = {
  value: null,
  initValueNow: false,
  errorStatus: false,
  errorText: '',
  minDate: addYears(new Date(), -20),
  maxDate: addYears(new Date(), 20),
  variant: 'inline',
  margin: 'normal',
  fullWidth: true
};

MonthYearPicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  initValueNow: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errorStatus: PropTypes.bool,
  errorText: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  variant: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default MonthYearPicker;
