import { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { ROUTE_PROFILE } from 'routing/routes/Profile';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ProfileApi from 'api/connections/Users/ProfileApi';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { Box } from '@material-ui/core';
import t, { availableLocals } from 'translate/translate';

const Form = () => {
  const history = useHistory();
  const { user, updateUser } = useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);

  const initState = {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    language: '',
    password: '',
    password_confirmation: ''
  };

  const [profile, setProfile] = useState(initState);

  const [validation, setValidation] = useState({
    email: {
      status: false,
      message: t(
        'Pole <%= field %> is required, and should be in the correct format',
        { field: t('Email') }
      )
    },
    first_name: {
      status: false,
      message: t('Field <%= field %> is required', { field: t('First name') })
    },
    last_name: {
      status: false,
      message: t('Field <%= field %> is required', { field: t('Last name') })
    },
    phone: {
      status: false,
      message: t('Pole <%= field %> should be in the correct format', {
        field: t('Phone number')
      })
    },
    password: {
      status: false,
      message: t(
        'Password must contain at least 8 characters, including at least 1 uppercase letter, lowercase letter and a number.'
      )
    },
    password_confirmation: {
      status: false,
      message: t('Passwords are not equal')
    }
  });

  useEffect(() => {
    if (user) {
      const objKeys = Object.keys(user);
      const newProfileState = { ...initState };

      objKeys.forEach(key => {
        if (key in profile && user[key] !== null) {
          newProfileState[key] = user[key];
        }
      });

      setProfile(newProfileState);
    }
  }, [user]);

  const onChange = e => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
    setValidation({
      ...validation,
      [e.target.name]: { ...validation[e.target.name], status: false }
    });
  };

  const handleResponse = res => {
    updateUser(res.data.data);
    setAlert({ value: 'Success', variant: COLOR_VARIANTS_SUCCESS });
    history.push(ROUTE_PROFILE);
  };

  const handleError = err => {
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      setValidation({ ...validation, ...newValidateState });
      setAlert(message);
    } else if (err instanceof ApiError) {
      setAlert(message);
    }
  };

  const runValidation = () => {
    /* eslint prefer-const: "off" */
    let [isValid, newValidateState] = validate(profile, validation);
    if (
      profile.password.length &&
      profile.password !== profile.password_confirmation
    ) {
      isValid = false;
      newValidateState.password_confirmation.status = true;
    }

    if (!isValid) {
      setValidation(newValidateState);
    }

    return isValid;
  };

  const makeApiCall = async () => {
    setLoading(true);
    try {
      const res = await ProfileApi.updateProfile(profile);
      handleResponse(res);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const isValid = runValidation();
    if (isValid) await makeApiCall();
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Input
        name='first_name'
        label='First name'
        value={profile.first_name}
        required
        onChange={onChange}
        errorStatus={validation.first_name.status}
        errorText={validation.first_name.message}
      />
      <Input
        name='last_name'
        label='Last name'
        value={profile.last_name}
        required
        onChange={onChange}
        errorStatus={validation.last_name.status}
        errorText={validation.last_name.message}
      />
      <Input
        name='email'
        label='Email'
        type='email'
        value={profile.email}
        required
        onChange={onChange}
        errorStatus={validation.email.status}
        errorText={validation.email.message}
      />
      <Input
        name='phone'
        label='Phone number'
        inputProps={{ maxLength: 14 }}
        value={profile.phone}
        onChange={onChange}
        errorStatus={validation.phone.status}
        errorText={validation.phone.message}
      />
      <Select
        name='language'
        label='Language'
        value={profile.language}
        options={formatOptions(availableLocals, 'slug', 'name', true)}
        onChange={onChange}
        style={{ marginTop: '2rem' }}
      />
      <Input
        name='password'
        label='Password'
        type='password'
        value={profile.password}
        onChange={onChange}
        errorStatus={validation.password.status}
        errorText={validation.password.message}
      />
      <Input
        name='password_confirmation'
        label='Repeat password'
        type='password'
        value={profile.password_confirmation}
        onChange={onChange}
        errorStatus={validation.password_confirmation.status}
        errorText={validation.password_confirmation.message}
      />
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
        width={1}
      >
        <Box>
          <Link to={ROUTE_PROFILE} className='router-button'>
            <Button text={t('Cancel')} />
          </Link>
        </Box>
        <Box>
          <Button
            type='submit'
            fullWidth
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </Box>
      </Box>
    </form>
  );
};

export default Form;
