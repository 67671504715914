import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class CurrenciesRatesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.currencyRatesPath = 'currencies/:id/rates';
    this.currencyUpdateRatesPath = 'currencies/:currencyId/rates/:rateId';
  }

  getCurrencyRates(id) {
    return this.get(insertPathParams(this.currencyRatesPath, { id }));
  }

  createCurrencyRate(id, payload) {
    return this.post(insertPathParams(this.currencyRatesPath, { id }), payload);
  }

  updateCurrencyRate(ids, payload) {
    return this.put(
      insertPathParams(this.currencyUpdateRatesPath, ids),
      payload
    );
  }

  deleteCurrencyRate(ids) {
    return this.delete(insertPathParams(this.currencyUpdateRatesPath, ids));
  }
}

export default new CurrenciesRatesApi();
