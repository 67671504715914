import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Grid, Paper } from '@material-ui/core';
import getPathQuery from 'api/utils/getPathQuery';
import { ROUTE_SALES_RESULT_LIST } from 'routing/routes/SalesResults';
import InDepartmentComparisonSalesResultsWrapper from 'modules/SalesResults/component/InDepartmentComparison/Wrapper';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import SalesResultsViewTypeButtons from 'modules/SalesResults/component/ResultsDetails/ViewTypeButtons';
import InDepartmentComparisonSalesResultsFilter from 'modules/SalesResults/component/InDepartmentComparison/Filter';
import Typography from '@material-ui/core/Typography';
import t from 'translate/translate';

const checkIfFilterIsFilled = filter => {
  if (filter.period_type === 'quarter') {
    if (filter.period && filter.quarter) return true;
  }

  if (filter.period) return true;
};

class InDepartmentSalesResultsComparison extends Component {
  constructor(props) {
    super(props);

    this.departmentId =
      parseInt(this.props.match.params.id, 10) ||
      parseInt(getPathQuery(this.props.location.search, 'department_id'), 10);

    this.state = {
      viewType: 'pie',
      quantityOrValue: 'quantity',
      filters: {
        filterOne: {
          period_type: '',
          quarter: '',
          period: ''
        },
        filterTwo: {
          period_type: '',
          quarter: '',
          period: ''
        },
        filterThree: {
          period_type: '',
          quarter: '',
          period: ''
        }
      }
    };

    this.handleError = this.handleError.bind(this);
    this.onViewTypeChange = this.onViewTypeChange.bind(this);
    this.onQuantityOrValueChange = this.onQuantityOrValueChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    if (!this.departmentId) {
      this.redirect();
    }
    this.props.contextMethods.setCurrentPage(
      'Department sales results comparison'
    );
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.redirect();
      }
    } else {
      console.error(err);
    }
  }

  onViewTypeChange(viewType) {
    this.setState({ viewType });
  }

  onQuantityOrValueChange(quantityOrValue) {
    this.setState({ quantityOrValue });
  }

  onFilterChange(e, filterName) {
    const { name, value } = e.target;

    this.setState(prevState => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          [filterName]: {
            ...prevState.filters[filterName],
            period:
              name === 'period_type'
                ? ''
                : prevState.filters[filterName].period,
            quarter:
              name === 'period_type'
                ? ''
                : prevState.filters[filterName].quarter,
            [name]: value
          }
        }
      };
    });
  }

  onDeleteClick(filterName) {
    this.setState(state => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterName]: {
            ...state.filters[filterName],
            period_type: '',
            quarter: '',
            period: ''
          }
        }
      };
    });
  }

  checkIfAnyFilterIsFilled() {
    const { filterOne, filterTwo, filterThree } = this.state.filters;

    return (
      checkIfFilterIsFilled(filterOne) ||
      checkIfFilterIsFilled(filterTwo) ||
      checkIfFilterIsFilled(filterThree)
    );
  }

  redirect() {
    return this.props.history.push(ROUTE_SALES_RESULT_LIST);
  }

  render() {
    const { viewType, quantityOrValue } = this.state;
    const { filterOne, filterTwo, filterThree } = this.state.filters;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Paper className='p1'>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
            >
              <Box
                display='flex'
                flexDirection={{ xs: 'column', lg: 'row' }}
                width={1}
              >
                <Box width={1} mr={1}>
                  <InDepartmentComparisonSalesResultsFilter
                    filterTitle='First chart'
                    filter={filterOne}
                    onFilterChange={e => this.onFilterChange(e, 'filterOne')}
                  />
                </Box>
                <Box width={1} mr={1}>
                  <InDepartmentComparisonSalesResultsFilter
                    filterTitle='Second chart'
                    filter={filterTwo}
                    onFilterChange={e => this.onFilterChange(e, 'filterTwo')}
                  />
                </Box>
                <Box width={1}>
                  <InDepartmentComparisonSalesResultsFilter
                    filterTitle='Third chart'
                    filter={filterThree}
                    onFilterChange={e => this.onFilterChange(e, 'filterThree')}
                  />
                </Box>
              </Box>
              <Box width='40%'>
                <SalesResultsViewTypeButtons
                  viewType={viewType}
                  quantityOrValue={quantityOrValue}
                  onViewTypeChange={this.onViewTypeChange}
                  onQuantityOrValueChange={this.onQuantityOrValueChange}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className='p1'>
            <Grid container spacing={3} justify='center'>
              {!this.checkIfAnyFilterIsFilled() && (
                <Box p={1}>
                  <Typography variant='body1' color='textSecondary'>
                    {t('Select the period for the first chart')}
                  </Typography>
                </Box>
              )}
              {checkIfFilterIsFilled(filterOne) && (
                <Grid item xs={4}>
                  <InDepartmentComparisonSalesResultsWrapper
                    departmentId={this.departmentId}
                    filter={filterOne}
                    viewType={viewType}
                    quantityOrValue={quantityOrValue}
                    handleError={this.handleError}
                    onDeleteClick={() => this.onDeleteClick('filterOne')}
                  />
                </Grid>
              )}
              {checkIfFilterIsFilled(filterTwo) && (
                <Grid item xs={4}>
                  <InDepartmentComparisonSalesResultsWrapper
                    departmentId={this.departmentId}
                    filter={filterTwo}
                    viewType={viewType}
                    quantityOrValue={quantityOrValue}
                    handleError={this.handleError}
                    onDeleteClick={() => this.onDeleteClick('filterTwo')}
                  />
                </Grid>
              )}
              {checkIfFilterIsFilled(filterThree) && (
                <Grid item xs={4}>
                  <InDepartmentComparisonSalesResultsWrapper
                    departmentId={this.departmentId}
                    filter={filterThree}
                    viewType={viewType}
                    quantityOrValue={quantityOrValue}
                    handleError={this.handleError}
                    onDeleteClick={() => this.onDeleteClick('filterThree')}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

InDepartmentSalesResultsComparison.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(InDepartmentSalesResultsComparison);
