import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import renderPrice from 'modules/Products/utils/renderPrice';

class ClientsTargetsReportTable extends Component {
  static getCols() {
    return [
      {
        property: 'client_name',
        label: 'Client name',
        sortable: false
      },
      {
        property: 'client_city',
        label: 'City',
        sortable: false
      },
      {
        property: 'client_street',
        label: 'Street',
        sortable: false
      },
      {
        property: 'target_name',
        label: 'Contract name',
        sortable: false
      },
      {
        property: 'current_value',
        label: 'Current value',
        sortable: false,
        render: (value, { currency }) => renderPrice(value, currency)
      },
      {
        property: 'current_estimation_value',
        label: 'Current estimation value',
        sortable: false,
        render: (value, { currency }) => renderPrice(value, currency)
      }
    ];
  }

  render() {
    const { statistics, loading } = this.props;

    return (
      <>
        <Table
          cols={ClientsTargetsReportTable.getCols()}
          rows={statistics}
          loading={loading}
        />
      </>
    );
  }
}

ClientsTargetsReportTable.defaultProps = {
  statistics: []
};

ClientsTargetsReportTable.propTypes = {
  statistics: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired
};

export default ClientsTargetsReportTable;
