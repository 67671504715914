import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import { Grid, Paper } from '@material-ui/core';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import ApiError from 'api/exceptions/ApiError';
import Loader from 'modules/Layout/component/Loader';
import Accordion from 'modules/Layout/component/Accordion';
import Checkbox from 'modules/Layout/component/Checkbox';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import InfoTooltip from 'modules/Layout/component/InfoTooltip';

const getDisabledDepartments = settings => {
  let departments = settings.map(country => country.departments);

  departments = departments.reduce((department, acc) => {
    return [...acc, ...department];
  }, []);

  const disabledDepartments = departments.filter(
    department => department.emails_enabled === false
  );

  return disabledDepartments.map(item => item.id);
};

class TraderResultsReportNotificationSettings extends Component {
  static contextType = AuthContext;

  static getDisabledDepartments(settings) {
    const departments = settings.map(country => country.departments);

    const disabledDepartments = departments.filter(
      department => department.emails_enabled === false
    );

    return disabledDepartments.map(item => item.id);
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      settings: undefined,
      disabledDepartments: []
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  }

  onChange(e) {
    this.setState(
      state => {
        const { name, checked } = e.target;
        const departmentId = Number.parseInt(name.split('-')[1], 10);

        const disabledDepartments = checked
          ? state.disabledDepartments.filter(id => id !== departmentId)
          : [...state.disabledDepartments, departmentId];

        return {
          ...state,
          disabledDepartments
        };
      },
      () => this.makeApiCall()
    );
  }

  async fetchData() {
    try {
      const {
        data: { data: settings }
      } = await StatisticsApi.getTradersResultsSendingSettings();

      const disabledDepartments = getDisabledDepartments(settings);

      this.setState({ settings, disabledDepartments });
    } catch (err) {
      this.handleError(err);
    }
  }

  async makeApiCall() {
    const { disabledDepartments: disabled_departments } = this.state;

    try {
      await StatisticsApi.updateTradersResultsSendingSettings({
        disabled_departments
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderDepartments(departments) {
    return (
      <Grid container>
        {departments.map(department => {
          return (
            <Grid item lg={4} xs={12} key={department.id}>
              <Checkbox
                name={`department-${department.id}`}
                label={department.name}
                checked={department.emails_enabled}
                onChange={this.onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  renderCountries() {
    const { settings } = this.state;

    return settings.map(country => {
      return (
        <Grid item xs={12} key={country.country_id}>
          <Accordion label={country.country_name}>
            {this.renderDepartments(country.departments)}
          </Accordion>
        </Grid>
      );
    });
  }

  render() {
    const { settings } = this.state;

    if (!settings) return <Loader />;

    return (
      <>
        <InfoTooltip
          float
          text={t(
            'At this point, it is possible to disable the automatic e-mailing of a report on the traders results. By default, you will receive data from all departments available to you every Monday. You can turn this of  by unchecking the selected departments.'
          )}
        />
        <Grid container spacing={3} justify='center'>
          {this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) ? (
            this.renderCountries()
          ) : (
            <Grid item xs={12}>
              <Paper className='p1'>
                {this.renderDepartments(settings[0].departments)}
              </Paper>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

TraderResultsReportNotificationSettings.propTypes = {
  setAlert: PropTypes.func.isRequired
};

export default TraderResultsReportNotificationSettings;
