import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import Input from 'modules/Layout/component/Input';
import { Box } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import WholesalersMappingsApi from 'api/connections/Wholesalers/WholesalersMappingsApi';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { ROUTE_WHOLESALERS_DETAILS } from 'routing/routes/Wholesalers';

class WholesalerProductsMappingUpdateForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    const {
      mapping: { product_id, external_identifier, external_name }
    } = props;

    this.state = {
      formData: {
        product_id,
        external_identifier,
        external_name
      },
      validation: {
        product_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Product')
          })
        },
        external_identifier: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('External identifier')
          })
        },
        external_name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('External name')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.redirect();
  }

  handleError(err) {
    const { setAlert } = this.context;
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      this.setState(({ validation: validationState }) => {
        return {
          validation: { ...validationState, ...newValidateState }
        };
      });
    }
    setAlert(message);
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(prevState => {
      const { formData, validation } = prevState;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onAutocompleteChange(event, value) {
    this.setState(prevState => {
      const { formData, validation } = prevState;

      return {
        formData: {
          ...formData,
          product_id: value ? value.key : ''
        },
        validation: {
          ...validation,
          product_id: {
            ...validation.productId,
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  redirect() {
    const { wholesalerId } = this.props;

    let path = insertPathParams(ROUTE_WHOLESALERS_DETAILS, {
      id: wholesalerId
    });

    path += '?tab_index=products_mapping';

    this.props.history.push(path);
  }

  async makeApiCall() {
    const { formData } = this.state;
    const {
      wholesalerId,
      mapping: { id: mappingId }
    } = this.props;
    this.setState({ loading: true });
    try {
      await WholesalersMappingsApi.updateWholesalerMapping(
        { wholesalerId, mappingId },
        formData
      );
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { external_identifier, external_name },
      validation,
      loading
    } = this.state;
    const { mapping, wholesalerName } = this.props;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <TextDataSet label='Wholesaler' data={wholesalerName} />
        <TextDataSet label='Product' data={mapping.product_sku} />
        <Input
          name='external_identifier'
          label='External identifier'
          required
          value={external_identifier}
          onChange={this.onChange}
          errorStatus={validation.external_identifier.status}
          errorText={validation.external_identifier.message}
        />
        <Input
          name='external_name'
          label='External name'
          required
          value={external_name}
          onChange={this.onChange}
          errorStatus={validation.external_name.status}
          errorText={validation.external_name.message}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button text={t('Cancel')} onClick={this.redirect} />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

WholesalerProductsMappingUpdateForm.propTypes = {
  wholesalerId: PropTypes.number.isRequired,
  wholesalerName: PropTypes.string.isRequired,
  mapping: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(WholesalerProductsMappingUpdateForm);
