import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import { ROUTE_WHOLESALERS_LIST } from 'routing/routes/Wholesalers';
import getPathQuery from 'api/utils/getPathQuery';

import WholesalerAgencyForm from 'modules/Wholesalers/component/Details/Agencies/Form';

class WholesalerAgencyCreate extends Component {
  constructor(props) {
    super(props);

    this.wholesalerId = parseInt(props.match.params.id, 10);
    this.wholesalerName = getPathQuery(
      props.location.search,
      'wholesaler_name'
    );
  }

  componentDidMount() {
    if (!this.wholesalerId) {
      this.props.history.push(ROUTE_WHOLESALERS_LIST);
    }

    this.props.contextMethods.setCurrentPage('Create wholesaler agency');
  }

  render() {
    return (
      <Grid container justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <WholesalerAgencyForm
              wholesalerId={this.wholesalerId}
              wholesalerName={this.wholesalerName}
              setAlert={this.props.contextMethods.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

WholesalerAgencyCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerAgencyCreate);
