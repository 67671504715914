import { Component } from 'react';
import PropTypes from 'prop-types';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ClientsUsersApi from 'api/connections/Clients/ClientsUsersApi';
import ApiError from 'api/exceptions/ApiError';
import { Grid, Paper } from '@material-ui/core';
import UnassignedToEmployeeFilter from 'modules/Clients/component/UnassignedToEmployee/Filter';
import UnassignedToEmployeeClientsList from 'modules/Clients/component/UnassignedToEmployee/ClientsList';
import UnassignedToEmployeeUsersList from 'modules/Clients/component/UnassignedToEmployee/UsersList';
import UnassignedToEmployeeButtons from 'modules/Clients/component/UnassignedToEmployee/Buttons';
import t from 'translate/translate';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

class ClientsUnassignedToEmployee extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      department_id: undefined,
      address: '',
      role: 'trader',
      departments: undefined,
      selected: [],
      usersDialogOpenStatus: false
    };

    this.handleError = this.handleError.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAssignClick = this.onAssignClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Clients unassigned to employee');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  onAssignClick() {
    this.setState({ usersDialogOpenStatus: true });
  }

  onDialogClose() {
    this.setState({ usersDialogOpenStatus: false });
  }

  async onSubmit(user_id) {
    const { department_id, selected } = this.state;

    const payload = { user_id, department_id, client_id: selected };

    try {
      await ClientsUsersApi.assignClientsToUser(payload);

      this.setState({
        department_id: undefined,
        address: '',
        usersDialogOpenStatus: false
      });

      this.props.contextMethods.setAlert({
        value: t('Successfully assigning a user to a client'),
        variant: COLOR_VARIANTS_SUCCESS
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: departments }
      } = await DepartmentsApi.getDepartments({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({ departments });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      department_id,
      address,
      role,
      departments,
      selected,
      usersDialogOpenStatus
    } = this.state;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Paper className='p1'>
            <UnassignedToEmployeeFilter
              department_id={department_id}
              role={role}
              address={address}
              departments={departments}
              onChange={this.onChange}
            />
            <UnassignedToEmployeeClientsList
              department_id={department_id}
              role={role}
              address={address}
              onChange={this.onChange}
              handleError={this.handleError}
            />
            <UnassignedToEmployeeButtons
              role={role}
              isAnySelected={Boolean(selected.length)}
              onClick={this.onAssignClick}
            />
            {usersDialogOpenStatus && (
              <UnassignedToEmployeeUsersList
                usersDialogOpenStatus={usersDialogOpenStatus}
                department_id={department_id}
                role={role}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                onDialogClose={this.onDialogClose}
                handleError={this.handleError}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ClientsUnassignedToEmployee.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default ClientsUnassignedToEmployee;
