import Filter from 'modules/Layout/component/List/Filter';

class WholesalerClientsMappingFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('wholesaler_name'),
            label: 'Wholesaler',
            type: 'text'
          }
        ]
      }
    ];
  }
}

export default WholesalerClientsMappingFilter;
