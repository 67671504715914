import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';

import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import insertPathParams from 'api/utils/insertPathParams';
import t from 'translate/translate';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import SalesGroupsFilesApi from 'api/connections/SalesGroups/SalesGroupsFilesApi';
import { ROUTE_SHOPPING_GROUPS_DETAILS } from 'routing/routes/ShoppingGroups';

export const separateFileExtension = fileName => {
  const parts = fileName.split('.');
  const extension = parts[parts.length - 1];
  const name = parts.slice(0, -1).join();

  return { name, extension };
};

class SalesGroupFileUpdateForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    const { salesGroupFile } = this.props;
    const { name, extension } = separateFileExtension(salesGroupFile.name);

    this.groupId = parseInt(this.props.match.params.groupId, 10);
    this.fileId = parseInt(this.props.match.params.fileId, 10);

    this.state = {
      formData: {
        name,
        description: salesGroupFile.description || ''
      },
      extension,
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.redirectToSalesGroupDetailsFiles = this.redirectToSalesGroupDetailsFiles.bind(
      this
    );
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.redirectToSalesGroupDetailsFiles();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  redirectToSalesGroupDetailsFiles() {
    let path = insertPathParams(ROUTE_SHOPPING_GROUPS_DETAILS, {
      id: this.groupId
    });
    path += '?tab_index=general_data';
    this.props.history.push(path);
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    const ids = {
      groupId: this.groupId,
      fileId: this.fileId
    };
    this.setState({ loading: true });
    try {
      await SalesGroupsFilesApi.updateSalesGroupFile(ids, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { name, description },
      extension,
      validation,
      loading
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Box display='flex' alignItems='flex-end'>
          <Input
            name='name'
            label='Name'
            value={name}
            required
            autoFocus
            onChange={this.onChange}
            errorStatus={validation.name.status}
            errorText={validation.name.message}
          />
          <Typography
            variant='h5'
            component='h1'
            style={{ margin: '0.5rem 1rem' }}
          >{`.${extension}`}</Typography>
        </Box>
        <Input
          name='description'
          label='Description'
          value={description}
          onChange={this.onChange}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button
            text={t('Cancel')}
            onClick={this.redirectToSalesGroupDetailsFiles}
          />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

SalesGroupFileUpdateForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  salesGroupFile: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired
};

export default withRouter(SalesGroupFileUpdateForm);
