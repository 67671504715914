import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import PublicWrapper from 'modules/Layout/view/PublicWrapper';
import Form from 'modules/Auth/component/Password/Reset/Form';
import { ROUTE_LOGIN } from 'routing/routes/Auth';
import t from 'translate/translate';

const Reset = () => {
  const [status, setStatus] = useState(false);

  return (
    <PublicWrapper>
      {status ? (
        <Box my={5}>
          <Typography component='h1' variant='h5'>
            {t(
              'Request for password reset was made successfully, Link to the change password form was sent to the e-mail address provided'
            )}
          </Typography>
        </Box>
      ) : (
        <Form setStatus={setStatus} />
      )}
      <Box>
        <Link to={ROUTE_LOGIN} className='router-link'>
          <Typography color='primary'>{t('Go back to login')}</Typography>
        </Link>
      </Box>
    </PublicWrapper>
  );
};

export default Reset;
