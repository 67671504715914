import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import CustomDialog from 'modules/Layout/component/CustomDialog';
import Input from 'modules/Layout/component/Input';
import { Box, Typography } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { languages } from 'modules/KnowledgeBase/view';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';
import KnowledgeBaseApi from 'api/connections/KnowledgeBase/KnowledgeBaseApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import FileUploadButton from 'modules/Layout/component/FileUploadButton';
import { validate } from 'modules/Shared/utils/validator';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import Checkbox from 'modules/Layout/component/Checkbox';

const UpdateNoteModal = ({
  open,
  toggleModalOpenFn,
  onUpdateNoteSuccess,
  categoryId,
  note
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: note.name || '',
    description: note.description || '',
    language: note.language || '',
    link: note.link || '',
    category_id: categoryId,
    remove_file: false
  });
  const [validation, setValidation] = useState({
    name: {
      status: false,
      message: t('Field <%= field %> is required', { field: t('Name') })
    },
    language: {
      status: false,
      message: t('Field <%= field %> is required', { field: t('Language') })
    },
    link: {
      status: false,
      message: t('Field <%= field %> is required', { field: t('Link') })
    }
  });
  const { setAlert } = useContext(AlertContext);

  const onChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidation({
      ...validation,
      [name]: {
        ...validation[name],
        status: false
      }
    });
  };

  const onLanguageChange = e => {
    setFormData({ ...formData, language: e.target.value });
    setValidation({
      ...validation,
      language: {
        ...validation.language,
        status: false
      }
    });
  };

  const onUpload = file => {
    setFormData({ ...formData, file: file || '' });
  };

  const onRemoveFileCheckboxChange = e => {
    const { checked } = e.target;

    setFormData({ ...formData, remove_file: checked });
  };

  const runValidation = () => {
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      setValidation(newValidateState);
    }

    return isValid;
  };

  const handleError = err => {
    if (err instanceof ApiError) {
      setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        setValidation({
          ...validation,
          ...newValidateState
        });
      }
    } else {
      console.error(err);
    }
  };

  const makeApiCall = async () => {
    const isValid = runValidation();
    if (!isValid) return;

    setButtonLoading(true);
    const {
      name,
      description,
      language,
      file: noteFile,
      link,
      category_id,
      remove_file
    } = formData;

    const newFormData = new FormData();
    newFormData.append('name', name);
    newFormData.append('description', description);
    newFormData.append('language', language);
    newFormData.append('link', link);
    newFormData.append('remove_file', remove_file);
    if (noteFile) {
      newFormData.append('file', noteFile);
    }
    newFormData.append('category_id', category_id);

    try {
      await KnowledgeBaseApi.updateNote(note.id, newFormData);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onUpdateNoteSuccess();
    } catch (err) {
      handleError(err);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={toggleModalOpenFn}
      title={t('Update note')}
    >
      <Input
        onChange={onChange}
        label='Name'
        name='name'
        value={formData.name}
        required
        errorStatus={validation.name.status}
        errorText={validation.name.message}
      />
      <Input
        onChange={onChange}
        label='Description'
        name='description'
        multiline
        value={formData.description}
      />
      <Select
        name='language'
        label='Language'
        value={formData.language}
        options={formatOptions(languages, 'slug', 'name')}
        onChange={onLanguageChange}
        emptyValueText='Choose language'
        required
        errorStatus={validation.language.status}
        errorText={validation.language.message}
      />
      <Input
        onChange={onChange}
        label='Link'
        name='link'
        value={formData.link}
        errorStatus={validation.link.status}
        errorText={validation.link.message}
      />
      {note.file ? (
        <Checkbox
          checked={formData.remove_file}
          onChange={onRemoveFileCheckboxChange}
          name='file'
          label={t('Remove file')}
        />
      ) : (
        <Typography>Brak pliku</Typography>
      )}
      <FileUploadButton onUpload={onUpload} name='file' />
      <Box display='flex' justifyContent='flex-end' alignItems='center'>
        <Button
          text={t('Save')}
          color='primary'
          onClick={makeApiCall}
          loading={buttonLoading}
        />
      </Box>
    </CustomDialog>
  );
};

UpdateNoteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModalOpenFn: PropTypes.func.isRequired,
  onUpdateNoteSuccess: PropTypes.func.isRequired,
  categoryId: PropTypes.number.isRequired,
  note: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    language: PropTypes.string,
    link: PropTypes.string,
    file: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

export default UpdateNoteModal;
