import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';

import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import TargetsBonusesApi from 'api/connections/TargetsBonuses/TargetsBonusesApi';
import { DialogContentText } from '@material-ui/core';

class SuspendBonusModal extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        reason: ''
      },
      validation: {
        reason: {
          status: false,
          message: null
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onClose();
    this.props.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { validation } = state;
      const { value } = e.target;

      return {
        formData: {
          reason: value
        },
        validation: {
          reason: {
            ...validation.reason,
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      await TargetsBonusesApi.suspendBonus(this.props.bonusId, formData);

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { reason },
      loading
    } = this.state;

    return (
      <Dialog
        open
        onClose={this.props.onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Suspend confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure you want to suspend bonus')}
          </DialogContentText>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            <Input
              name='reason'
              label='Reason'
              value={reason}
              onChange={this.onChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.props.onClose} />
          <Button
            form='form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

SuspendBonusModal.propTypes = {
  bonusId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SuspendBonusModal;
