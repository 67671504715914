import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { clientContractStatusesArr } from 'modules/ClientsContracts/clientContractStatuses';

class TargetsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('client_name'),
            label: 'Client name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('sales_group_name'),
            label: 'Shopping group name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('status'),
            label: 'Status',
            type: 'select',
            emptyValueText: 'All statuses',
            options: formatOptions(
              clientContractStatusesArr,
              'key',
              'name',
              true
            )
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('period'),
            label: 'Period',
            type: 'periodPicker'
          }
        ]
      }
    ];
  }
}

export default TargetsFilter;
