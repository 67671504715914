import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ProductsBatchesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.createproductBatchPath = 'products/:id/batches';
    this.productBatchPath = 'products/:productId/batches/:batchId';
  }

  createProductBatch(id, payload) {
    return this.post(
      insertPathParams(this.createproductBatchPath, { id }),
      payload
    );
  }

  updateProductBatch(ids, payload) {
    return this.put(insertPathParams(this.productBatchPath, ids), payload);
  }

  deleteProductBatch(ids) {
    return this.delete(insertPathParams(this.productBatchPath, ids));
  }
}

export default new ProductsBatchesApi();
