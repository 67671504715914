import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import TargetForm from 'modules/Targets/component/Form';

class TargetsCreate extends Component {
  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Create target');
  }

  render() {
    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <TargetForm setAlert={this.props.contextMethods.setAlert} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TargetsCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired
};

export default TargetsCreate;
