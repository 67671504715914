import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { CLIENTS_LIST } from 'api/auth/permissions/Clients';
import { Box } from '@material-ui/core';
import WholesalerClientsMappingDeleteDialog from 'modules/Wholesalers/component/Details/ClientsMapping/DeleteDialog';
import {
  ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE,
  ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE
} from 'routing/routes/Clients';
import insertPathParams from 'api/utils/insertPathParams';
import Pagination from 'modules/Layout/component/List/Pagination';

class ClientsMappingList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      deleteDialogOpen: false,
      mappingToDelete: null
    };

    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  onUpdateClick(mapping) {
    const { wholesaler_id } = mapping;
    const { id: client_id } = this.props.client;

    const path = insertPathParams(ROUTE_WHOLESALER_CLIENT_MAPPING_UPDATE, {
      client_id,
      wholesaler_id
    });

    this.props.history.push(path);
  }

  onCreateClick() {
    const { id } = this.props.client;

    const path = insertPathParams(ROUTE_WHOLESALER_CLIENT_MAPPING_CREATE, {
      id
    });

    this.props.history.push(path);
  }

  onDeleteClick(mapping) {
    this.setState({ deleteDialogOpen: true, mappingToDelete: mapping });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, mappingToDelete: null });
  }

  getCols() {
    return [
      {
        property: 'wholesaler_name',
        label: 'Wholesaler',
        sortable: false
      },
      {
        property: 'client_number',
        label: 'Client number',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: this.context.hasPermission([CLIENTS_LIST]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([CLIENTS_LIST]),
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { deleteDialogOpen, mappingToDelete } = this.state;
    const {
      onDeleteMappingSuccess,
      mappings,
      pagination,
      setPagination
    } = this.props;

    return (
      <>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <IconButton
            className='create-icon'
            onClick={this.onCreateClick}
            icon={<AddIcon fontSize='large' />}
            alt='create'
          />
          <Pagination pagination={pagination} setPagination={setPagination} />
        </Box>
        <Table cols={this.getCols()} rows={mappings} dense actions />
        {deleteDialogOpen && (
          <WholesalerClientsMappingDeleteDialog
            mapping={mappingToDelete}
            onClose={this.onDeleteDialogClose}
            onSuccess={onDeleteMappingSuccess}
          />
        )}
      </>
    );
  }
}

ClientsMappingList.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  mappings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  setPagination: PropTypes.func.isRequired,
  onDeleteMappingSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(ClientsMappingList);
