import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import SubcategoryPicker from 'modules/ProductCategories/components/SubcategoryPicker';
import { DIRECT_ONLY_TYPE } from 'modules/Orders/ordersTypes';
import Table from 'modules/Layout/component/List/Table';
import AddIcon from '@material-ui/icons/Add';
import IconButton from 'modules/Layout/component/IconButton';
import Pagination from 'modules/Layout/component/List/Pagination';
import Input from 'modules/Layout/component/Input';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';
import { Link } from 'react-router-dom';
import ProductPromotionsTooltip from 'modules/Orders/component/OrderPreparing/ProductPromotionsTooltip';

class AddProductDialog extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      sku: '',
      groupId: undefined,
      categoryId: undefined,
      subcategoryId: undefined,
      pagination: {
        per_page: 5,
        page: 1,
        total: props.productsData.length
      }
    };

    this.onSubcategoryPick = this.onSubcategoryPick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setProductDisabledStatus = this.setProductDisabledStatus.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.isRowDisable = this.isRowDisable.bind(this);
  }

  onSubcategoryPick(result) {
    this.setState({
      ...result,
      pagination: { ...this.state.pagination, page: 1 }
    });
  }

  onChange(e) {
    const { value: sku } = e.target;

    this.setState({ sku, pagination: { ...this.state.pagination, page: 1 } });
  }

  setPagination(pagination) {
    this.setState({
      pagination: { ...this.state.pagination, ...pagination }
    });
  }

  setProductDisabledStatus(key) {
    const { productsOptions } = this.state;

    const { batches_count, direct_order_only } = productsOptions.find(
      p => p.id === key
    );

    if (
      this.props.promotionOrderType === DIRECT_ONLY_TYPE &&
      !direct_order_only
    ) {
      return true;
    }

    if (parseInt(batches_count, 10) <= 0) {
      return true;
    }
  }

  getCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false,
        render: (_, row) => this.renderProductName(row)
      },
      {
        property: 'subcategory_name',
        label: 'Subcategory',
        sortable: false
      },
      {
        property: 'batches_count',
        label: 'Batches total',
        sortable: true,
        displayStatus: this.props.isDirect
      },
      {
        property: 'id',
        label: 'Actions',
        sortable: true,
        render: productId => this.renderAddBtn(productId)
      }
    ];
  }

  getProductsToRender() {
    const { productsData, isDirect } = this.props;
    const { sku, groupId, categoryId, subcategoryId } = this.state;
    const { per_page, page } = this.state.pagination;

    let filteredProducts = [...productsData];

    if (!isDirect) {
      filteredProducts = filteredProducts.filter(p => !p.direct_order_only);
    }

    if (subcategoryId) {
      filteredProducts = filteredProducts.filter(
        o => o.subcategory_id === subcategoryId
      );
    } else if (categoryId) {
      filteredProducts = filteredProducts.filter(
        o => o.category_id === categoryId
      );
    } else if (groupId) {
      filteredProducts = filteredProducts.filter(o => o.group_id === groupId);
    }

    if (sku) {
      filteredProducts = filteredProducts.filter(product =>
        product.sku.toLowerCase().includes(sku.toLowerCase())
      );
    }

    const paginationFrom = (page - 1) * per_page;
    const paginationTo = paginationFrom + per_page;

    return {
      data: filteredProducts.slice(paginationFrom, paginationTo),
      total: filteredProducts.length
    };
  }

  isRowDisable(product) {
    const { batches_count, prices } = product;
    const { isDirect, clientCountryId } = this.props;
    let isDisable = false;

    const price = prices.find(p => p.country_id === clientCountryId)?.price;

    if ((isDirect && batches_count <= 0) || !price) {
      isDisable = true;
    }

    return isDisable;
  }

  renderAddBtn(productId) {
    return (
      <IconButton
        onClick={() => this.props.onProductAddSuccess(productId)}
        icon={<AddIcon color='primary' fontSize='large' />}
        alt='add product'
      />
    );
  }

  renderProductName(product) {
    const { id, sku } = product;
    const { promotionsData } = this.props;

    return (
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Link
          to={insertPathParams(ROUTE_PRODUCTS_DETAILS, { id })}
          className='router-link router-link-underline'
          target='_blank'
        >
          {sku}
        </Link>
        <ProductPromotionsTooltip
          product={product}
          promotions={promotionsData}
        />
      </Box>
    );
  }

  render() {
    const { sku } = this.state;
    const { data: productsToRender, total } = this.getProductsToRender();

    return (
      <Dialog
        open
        onClose={this.props.onClose}
        maxWidth='lg'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Adding product')}</DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            {t('Limit product list (optional)')}
          </Typography>
          <Box
            mb={4}
            display='flex'
            flexDirection={{ xs: 'column', lg: 'row' }}
          >
            <Box width={1} mr={{ xs: 0, lg: 1 }}>
              <Input
                onChange={this.onChange}
                label='Name'
                name='name'
                value={sku}
              />
            </Box>
            <SubcategoryPicker onSuccess={this.onSubcategoryPick} />
          </Box>
          <Table
            cols={this.getCols()}
            rows={productsToRender}
            hover
            dense
            isRowDisabled={this.isRowDisable}
            rowDisabledTooltip='The product cannot be selected because the batch count is 0 or price is undefined.'
          />
          <Box display='flex' flexDirection='row-reverse'>
            <Pagination
              pagination={{ ...this.state.pagination, total }}
              setPagination={this.setPagination}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            text={t('Close')}
            color='primary'
            onClick={this.props.onClose}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

AddProductDialog.defaultProps = {
  promotionOrderType: null
};

AddProductDialog.propTypes = {
  isDirect: PropTypes.bool.isRequired,
  productsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  promotionsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  promotionOrderType: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onProductAddSuccess: PropTypes.func.isRequired,
  clientCountryId: PropTypes.number.isRequired
};

export default AddProductDialog;
