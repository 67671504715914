import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import t from 'translate/translate';

class StatisticsUsersVisitsTable extends Component {
  static contextType = AuthContext;

  static getCols() {
    return [
      {
        property: 'first_name',
        label: 'First name',
        sortable: true
      },
      {
        property: 'last_name',
        label: 'Last name',
        sortable: true
      },
      {
        property: 'role',
        label: 'Role',
        sortable: false,
        render: role => t(role)
      },
      {
        property: 'value',
        label: 'Visits number',
        sortable: true
      }
    ];
  }

  render() {
    const { statistics, sort, setSort, loading, bodyText } = this.props;

    return (
      <>
        <Table
          cols={StatisticsUsersVisitsTable.getCols()}
          rows={statistics}
          sort={sort}
          setSort={setSort}
          loading={loading}
          bodyText={bodyText}
        />
      </>
    );
  }
}

StatisticsUsersVisitsTable.defaultProps = {
  statistics: [],
  bodyText: null
};

StatisticsUsersVisitsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  statistics: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  bodyText: PropTypes.string
};

export default StatisticsUsersVisitsTable;
