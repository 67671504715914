import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import { ROUTE_SHOPPING_GROUPS_DETAILS } from 'routing/routes/ShoppingGroups';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Table from 'modules/Layout/component/List/Table';
import t from 'translate/translate';
import discountsTypes from 'modules/Discounts/discountsTypes';
import DiscountsApi from 'api/connections/Discounts/DiscountsApi';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_DISCOUNTS_CREATE,
  ROUTE_DISCOUNTS_DETAILS,
  ROUTE_DISCOUNTS_UPDATE
} from 'routing/routes/Discounts';
import { ROUTE_CLIENTS_LIST } from 'routing/routes/Clients';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from 'modules/Layout/component/IconButton';
import {
  DISCOUNTS_CREATE,
  DISCOUNTS_UPDATE,
  DISCOUNTS_DELETE
} from 'api/auth/permissions/Discounts';
import Authorize from 'modules/Auth/component/Authorize';
import AddIcon from '@material-ui/icons/Add';
import DiscountsDeleteDialog from 'modules/Discounts/component/DeleteDialog';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';

class ClientDetailsDiscounts extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.clientId = parseInt(props.match.params.id, 10);
    this.setAlert = props.setAlert;

    this.canModifyAllDiscounts = [SUPER_ADMIN, ADMIN, DIRECTOR].includes(
      context.user.role
    );

    this.state = {
      clientDiscounts: [],
      salesGroupsDiscounts: [],
      clientPagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      salesGroupsPagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      clientLoading: true,
      salesGroupsLoading: true,
      deleteDialogStatus: false,
      discountToDelete: null
    };

    this.setPagination = this.setPagination.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onSalesGroupDetailsClick = this.onSalesGroupDetailsClick.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.fetchClientsDiscounts = this.fetchClientsDiscounts.bind(this);
  }

  componentDidMount() {
    this.fetchClientsDiscounts();
    if (this.props.salesGroupId) {
      this.fetchSalesGroupsDiscounts();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.salesGroupId && this.props.salesGroupId) {
      this.fetchSalesGroupsDiscounts();
    }
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_CLIENTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_DISCOUNTS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    let path = insertPathParams(ROUTE_DISCOUNTS_UPDATE, { id });
    path += `?client_id=${this.clientId}&client_name=${this.props.clientName}`;

    this.props.history.push(path);
  }

  onCreateClick() {
    let path = `${ROUTE_DISCOUNTS_CREATE}`;
    path += `?client_id=${this.clientId}&client_name=${this.props.clientName}`;

    this.props.history.push(path);
  }

  onSalesGroupDetailsClick() {
    let path = insertPathParams(ROUTE_SHOPPING_GROUPS_DETAILS, {
      id: this.props.salesGroupId
    });
    path += '?tab_index=discounts';

    this.props.history.push(path);
  }

  setPagination(pagination, isClientPagination) {
    this.setState(
      state => {
        const newState = { ...state };

        if (isClientPagination) {
          newState.clientPagination = {
            ...state.clientPagination,
            ...pagination
          };
          newState.clientLoading = true;
        } else {
          newState.salesGroupsPagination = {
            ...state.salesGroupsPagination,
            ...pagination
          };
          newState.salesGroupsLoading = true;
        }

        return newState;
      },
      () =>
        isClientPagination
          ? this.fetchClientsDiscounts()
          : this.fetchSalesGroupsDiscounts()
    );
  }

  getModifyDisableStatus(isClientTable) {
    const { updateStatus } = this.props;

    if (!isClientTable && !this.canModifyAllDiscounts) return true;

    return isClientTable && !this.canModifyAllDiscounts && !updateStatus;
  }

  getCols(isClientTable) {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'type',
        label: 'Type',
        sortable: false,
        render: typeId =>
          t(discountsTypes.find(type => type.id === typeId).name)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: isClientTable || this.canModifyAllDiscounts,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status:
              isClientTable && this.context.hasPermission([DISCOUNTS_UPDATE]),
            disable: () => this.getModifyDisableStatus(isClientTable),
            disableMsg:
              'You can only modify discounts assigned to your clients.',
            onClick: this.onUpdateClick
          },
          remove: {
            status:
              isClientTable && this.context.hasPermission([DISCOUNTS_DELETE]),
            disable: () => this.getModifyDisableStatus(isClientTable),
            disableMsg:
              'You can only modify discounts assigned to your clients.',
            onClick: this.openDeleteDialog
          }
        }
      }
    ];
  }

  closeDeleteDialog() {
    this.setState({
      deleteDialogStatus: false,
      discountToDelete: null
    });
  }

  openDeleteDialog(discount) {
    this.setState({
      deleteDialogStatus: true,
      discountToDelete: discount
    });
  }

  async fetchClientsDiscounts() {
    const { per_page, page } = this.state.clientPagination;

    try {
      const {
        data: { data: clientDiscounts, meta: clientDiscountsMeta }
      } = await DiscountsApi.getDiscounts({
        per_page,
        page,
        client_id: this.clientId
      });

      this.setState({
        clientDiscounts,
        clientPagination: formatMetaToPagination(clientDiscountsMeta),
        clientLoading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchSalesGroupsDiscounts() {
    const { per_page, page } = this.state.salesGroupsPagination;

    try {
      const {
        data: { data: salesGroupsDiscounts, meta: salesGroupsDiscountsMeta }
      } = await DiscountsApi.getDiscounts({
        per_page,
        page,
        sales_group_id: this.props.salesGroupId
      });

      this.setState({
        salesGroupsDiscounts,
        salesGroupsPagination: formatMetaToPagination(salesGroupsDiscountsMeta),
        salesGroupsLoading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      clientDiscounts,
      salesGroupsDiscounts,
      clientPagination,
      salesGroupsPagination,
      clientLoading,
      salesGroupsLoading,
      deleteDialogStatus,
      discountToDelete
    } = this.state;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} md={6}>
          <Paper className='p1'>
            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '1rem' }}
            >
              {t('Clients discounts')}
            </Typography>
            <Table
              cols={this.getCols(true)}
              rows={clientDiscounts}
              loading={clientLoading}
              actions
              dense
            />
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
            >
              <Authorize permissions={[DISCOUNTS_CREATE]}>
                <Box>
                  {!this.getModifyDisableStatus(true) && (
                    <IconButton
                      className='create-icon'
                      onClick={this.onCreateClick}
                      icon={<AddIcon fontSize='large' />}
                      alt='create'
                    />
                  )}
                </Box>
              </Authorize>
              <Pagination
                pagination={clientPagination}
                setPagination={pagination =>
                  this.setPagination(pagination, true)
                }
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className='p1'>
            <Box display='flex'>
              <Typography
                component='h6'
                variant='h6'
                style={{ marginBottom: '1rem', marginRight: '.5rem' }}
              >
                {t('Shopping group discounts')}
              </Typography>
              {this.props.salesGroupId && (
                <IconButton
                  className='details-icon'
                  onClick={this.onSalesGroupDetailsClick}
                  icon={<SearchIcon />}
                  size='small'
                  alt='sales group details'
                />
              )}
            </Box>
            {this.props.salesGroupId ? (
              <>
                <Table
                  cols={this.getCols()}
                  rows={salesGroupsDiscounts}
                  loading={salesGroupsLoading}
                  actions={this.canModifyAllDiscounts}
                  dense
                />
                <Box display='flex' flexDirection='row-reverse'>
                  <Pagination
                    pagination={salesGroupsPagination}
                    setPagination={this.setPagination}
                  />
                </Box>
              </>
            ) : (
              <Typography>
                {t('Client are not assign to any shopping group')}
              </Typography>
            )}
          </Paper>
          {deleteDialogStatus && (
            <DiscountsDeleteDialog
              onClose={this.closeDeleteDialog}
              discount={discountToDelete}
              onSuccess={this.fetchClientsDiscounts}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

ClientDetailsDiscounts.defaultProps = {
  salesGroupId: null,
  clientName: ''
};

ClientDetailsDiscounts.propTypes = {
  salesGroupId: PropTypes.number,
  clientName: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  updateStatus: PropTypes.bool.isRequired
};

export default withRouter(ClientDetailsDiscounts);
