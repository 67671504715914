import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import DatePicker, {
  API_DATE_FORMAT
} from 'modules/Layout/component/Date/DatePicker';
import format from 'date-fns/format';

class CopyVisitsModal extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.onSuccess = props.onSuccess;
    this.onClose = props.onClose;

    this.state = {
      formData: {
        copy_from: format(props.copyFrom, API_DATE_FORMAT),
        copy_to: format(props.copyTo, API_DATE_FORMAT)
      },
      validation: {
        start_date: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Date from')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.onSuccess();
    this.onClose();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onDateChange(e) {
    this.setState(state => {
      const { validation } = state;
      const { value } = e.target;

      return {
        formData: {
          ...state.formData,
          start_date: value
        },
        validation: {
          start_date: {
            ...validation.start_date,
            status: false
          }
        }
      };
    });
  }

  countVisits() {
    const { days } = this.props;

    return days
      .filter(day => day.isClicked)
      .map(day => day.visits.length)
      .reduce((total, value) => total + value);
  }

  countChosenDays() {
    const { days } = this.props;

    return days.filter(day => day.isClicked).length;
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      await VisitsApi.copyVisits(formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      validation,
      loading,
      formData: { start_date }
    } = this.state;

    const { open } = this.props;

    this.countVisits();

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Copying visits')}</DialogTitle>
        <DialogContent>
          <TextDataSet
            label={t('Number of visits')}
            data={this.countVisits()}
          />
          <TextDataSet
            label={t('Number of days')}
            data={this.countChosenDays()}
          />
          <form id='copy-visit-form' noValidate onSubmit={this.onSubmit}>
            <DatePicker
              label='Date from'
              onChange={this.onDateChange}
              name='start_date'
              required
              errorStatus={validation.start_date.status}
              errorText={validation.start_date.message}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.onClose} />
          <Button
            form='copy-visit-form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
            disabled={!start_date}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

CopyVisitsModal.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  copyFrom: PropTypes.instanceOf(Date).isRequired,
  copyTo: PropTypes.instanceOf(Date).isRequired
};

export default CopyVisitsModal;
