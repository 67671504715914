import { Component } from 'react';
import PropTypes from 'prop-types';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import ApiError from 'api/exceptions/ApiError';
import { Grid, Paper } from '@material-ui/core';

import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import SalesImportCreateForm from 'modules/SalesImports/component/Create/Form';
import Loader from 'modules/Layout/component/Loader';

class SalesImportsCreate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      countries: [],
      wholesalers: []
    };
  }

  async componentDidMount() {
    this.setCurrentPage('Create sales import');
    await this.fetchData();
  }

  async fetchData() {
    try {
      let countries = null;

      const {
        data: { data: wholesalers }
      } = await WholesalersApi.getWholesalers({
        per_page: Number.MAX_SAFE_INTEGER
      });

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await PostalCodesApi.getCountries();

        countries = countriesData;
      }

      this.setState({ wholesalers, countries });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { countries, wholesalers } = this.state;

    if (!wholesalers) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <SalesImportCreateForm
              countries={countries}
              wholesalers={wholesalers}
              setAlert={this.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

SalesImportsCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default SalesImportsCreate;
