import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import Authorize from 'modules/Auth/component/Authorize';
import t from 'translate/translate';
import { TARGET_CREATE } from 'api/auth/permissions/Targets';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_TARGETS_BONUSES_CREATE,
  ROUTE_TARGETS_BONUSES_DETAILS
} from 'routing/routes/TargetsBonuses';
import SearchIcon from '@material-ui/icons/Search';
import renderPrice from 'modules/Products/utils/renderPrice';

const TargetBonusesDataSets = props => {
  const history = useHistory();

  const {
    id: targetId,
    bonus_id,
    bonus_value_level_1,
    bonus_value_level_2,
    bonus_value_level_3,
    bonus_value_level_4,
    currency
  } = props.data;

  const onCreateClick = () => {
    history.push(insertPathParams(ROUTE_TARGETS_BONUSES_CREATE, { targetId }));
  };

  const onBonusDetailsCLick = () => {
    history.push(
      insertPathParams(ROUTE_TARGETS_BONUSES_DETAILS, { id: bonus_id })
    );
  };

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box>
        <Typography
          component='h6'
          variant='h6'
          style={{ marginBottom: '.5rem' }}
        >
          {t('Bonus')}
        </Typography>
        {bonus_id ? (
          <>
            <TextDataSet
              label='Bonus at 90%'
              data={renderPrice(bonus_value_level_1, currency)}
            />
            <TextDataSet
              label='Bonus at 100%'
              data={renderPrice(bonus_value_level_2, currency)}
            />
            <TextDataSet
              label='Bonus at 110%'
              data={renderPrice(bonus_value_level_3, currency)}
            />
            <TextDataSet
              label='Bonus at 120%'
              data={renderPrice(bonus_value_level_4, currency)}
            />
          </>
        ) : (
          <Typography variant='body1'>{t('Target dont have bonus')}</Typography>
        )}
      </Box>
      <Box display='flex' alignItems='flex-start'>
        {bonus_id ? (
          <IconButton
            className='details-icon'
            onClick={onBonusDetailsCLick}
            icon={<SearchIcon />}
            alt='details'
          />
        ) : (
          <Authorize permissions={[TARGET_CREATE]}>
            <IconButton
              className='create-icon'
              onClick={onCreateClick}
              icon={<AddIcon />}
              alt='create'
            />
          </Authorize>
        )}
      </Box>
    </Box>
  );
};

TargetBonusesDataSets.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    bonus_id: PropTypes.number,
    bonus_value_level_1: PropTypes.number,
    bonus_value_level_2: PropTypes.number,
    bonus_value_level_3: PropTypes.number,
    bonus_value_level_4: PropTypes.number,
    currency: PropTypes.string.isRequired
  }).isRequired
};

export default TargetBonusesDataSets;
