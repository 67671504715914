export default {
  Dashboard: 'Dashboard',
  Welcome: 'Witaj',
  "Today's visits": 'Dzisiejsze wizyty',
  'Long-lost clients': 'Dawno nieodwiedzani klienci',
  'Show more': 'Pokaż więcej',
  'Show less': 'Pokaż mniej',
  'Imports needing attention': 'Importy wymagajace uwagi',
  'Outstanding imports': 'Zalegajace importy',
  'Top sellers not ordered for a long time':
    'Top sellery niezamawiane od dłuższego czasu',
  'Priority products with lowest sales':
    'Priorytetowe produkty z najniższą sprzedażą'
};
