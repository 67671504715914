import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class SalesGroupsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.salesGroupsPath = 'sales/groups';
    this.salesGroupPath = 'sales/groups/:id';
    this.salesGroupClientPath = 'sales/groups/:groupId/clients/:clientId';
  }

  getSalesGroups(pathParams) {
    return this.get(this.salesGroupsPath, pathParams);
  }

  getSalesGroup(id) {
    return this.get(insertPathParams(this.salesGroupPath, { id }));
  }

  createSalesGroup(payload) {
    return this.post(this.salesGroupsPath, payload);
  }

  updateSalesGroup(id, payload) {
    return this.put(insertPathParams(this.salesGroupPath, { id }), payload);
  }

  deleteSalesGroup(id) {
    return this.delete(insertPathParams(this.salesGroupPath, { id }));
  }

  assignSalesGroupToClient(ids) {
    return this.post(insertPathParams(this.salesGroupClientPath, ids));
  }

  detachSalesGroupFromClient(ids) {
    return this.delete(insertPathParams(this.salesGroupClientPath, ids));
  }
}

export default new SalesGroupsApi();
