import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import ProductsTree from 'modules/Visits/components/Details/Products/Add/TreeView';
import Input from 'modules/Layout/component/Input';
import { Link } from 'react-router-dom';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_VISITS_DETAILS } from 'routing/routes/Visits';
import Button from 'modules/Layout/component/Button';
import t from 'translate/translate';

const AddVisitProductForm = ({
  categoriesObj,
  onSubmit,
  onSelect,
  selected,
  handleError,
  loading,
  onChange,
  note,
  visitId
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Paper className='p1'>
        <ProductsTree
          categoriesObj={categoriesObj}
          onSelect={onSelect}
          selected={selected}
          handleError={handleError}
        />
      </Paper>
      <Input name='note' label='Note' value={note} onChange={onChange} />
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
        width={1}
      >
        <Box>
          <Link
            to={insertPathParams(ROUTE_VISITS_DETAILS, {
              id: visitId
            })}
            className='router-button'
          >
            <Button text={t('Cancel')} />
          </Link>
        </Box>
        <Box>
          <Button
            type='submit'
            fullWidth
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </Box>
      </Box>
    </form>
  );
};

AddVisitProductForm.defaultProps = {
  loading: false
};

AddVisitProductForm.propTypes = {
  categoriesObj: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({}).isRequired,
  handleError: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  note: PropTypes.string.isRequired,
  visitId: PropTypes.number.isRequired
};

export default AddVisitProductForm;
