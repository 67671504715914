import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import VisitMarker from 'modules/Visits/components/shared/VisitMarker';
import ClientMarker from 'modules/Visits/components/RoutePlanning/ClientMarker';
import { isEmpty } from 'lodash/lang';

const PlanningMap = ({
  visits,
  clients,
  onClientMarkerClick,
  mapRenderKey
}) => {
  const [firstVisit, ...restVisits] = visits;

  const defaultCenter = {
    lat: parseFloat(firstVisit?.lat) || parseFloat(clients[0]?.lat) || 52.0,
    lng: parseFloat(firstVisit?.long) || parseFloat(clients[0]?.long) || 19.5
  };

  const handleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();

    const wayPoints = restVisits
      .filter((_, index) => index < restVisits.length - 1)
      .map(({ lat, long }) => ({
        location: `${lat}, ${long}`,
        stopover: true
      }));

    const firstVisitCoords = `${firstVisit?.lat}, ${firstVisit?.long}`;
    const lastVisitCoords = `${visits[visits.length - 1]?.lat}, ${
      visits[visits.length - 1]?.long
    }`;

    directionsService.route(
      {
        travelMode: maps.TravelMode.DRIVING,
        origin: firstVisitCoords,
        waypoints: wayPoints,
        destination: lastVisitCoords
      },
      (response, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(response);
          const routePolyline = new maps.Polyline({
            path: response.routes[0].overview_path
          });
          routePolyline.setMap(map);
        }
      }
    );
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_KEY
        }}
        defaultCenter={defaultCenter}
        defaultZoom={isEmpty(visits) ? 6 : 8}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChildClick={onClientMarkerClick}
        key={mapRenderKey}
      >
        {clients.map(client => {
          return (
            <ClientMarker
              key={client.id}
              client={client}
              lat={client.lat}
              lng={client.long}
            />
          );
        })}
        {visits.map((visit, index) => {
          return (
            <VisitMarker
              key={visit.id}
              visit={visit}
              lat={visit.lat}
              lng={visit.long}
              order={index + 1}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

PlanningMap.propTypes = {
  visits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ).isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      long: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onClientMarkerClick: PropTypes.func.isRequired,
  mapRenderKey: PropTypes.number.isRequired
};

export default PlanningMap;
