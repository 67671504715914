export default function renderAddress(data, countries = []) {
  const { city, street, postal_code, country_id } = data;

  const countryName = countries.find(item => item.id === country_id)?.name;

  const result = [];

  if (city) result.push(city);
  if (street) result.push(street);
  if (postal_code) result.push(postal_code);
  if (countryName) result.push(countryName);

  return result.join(', ');
}
