import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import t from 'translate/translate';
import { ROUTE_USERS_LIST } from 'routing/routes/Users';
import UsersApi from 'api/connections/Users/UsersApi';
import Dialog from 'modules/Layout/component/Dialog';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import UserDataSets from 'modules/Users/component/Details/DataSets';
import UserSalesPeriodArchives from 'modules/Users/component/Details/SalesPeriodArchives';
import { DEPARTMENT_MANAGER, TELEMARKETER, TRADER } from 'api/auth/roles';

class UserDetailsBasicData extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      dialog: {
        open: false,
        description: ''
      }
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  onDeleteClick() {
    const description = t(
      'Are you sure you want to delete the user <%= first_name %> <%= last_name %>',
      {
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name
      }
    );
    this.setState({
      dialog: { open: true, description }
    });
  }

  closeDialog() {
    this.setState({
      dialog: { open: false }
    });
  }

  async deleteUser() {
    try {
      await UsersApi.deleteUser(this.props.user.id);

      this.props.setAlert({
        value: t('Deleting user successfully finish'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      this.closeDialog();
      this.props.history.push(ROUTE_USERS_LIST);
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.closeDialog();
        this.props.setAlert(message);
      }
    }
  }

  render() {
    if (!this.props.user) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: '1rem' }}>
            <UserDataSets
              user={this.props.user}
              countries={this.props.countries}
              onDeleteClick={this.onDeleteClick}
              updateActionDisableStatus={this.props.updateActionDisableStatus}
              updateActionDisableMsg={this.props.updateActionDisableMsg}
            />
          </Paper>
        </Grid>
        {[TRADER, TELEMARKETER, DEPARTMENT_MANAGER].includes(
          this.props.user.role
        ) && (
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: '1rem' }}>
              <UserSalesPeriodArchives
                setAlert={this.props.setAlert}
                user={this.props.user}
              />
            </Paper>
          </Grid>
        )}
        <Dialog
          open={this.state.dialog.open}
          title={t('Deletion confirmation')}
          description={this.state.dialog.description}
          onApprove={() => this.deleteUser()}
          onCancel={() => this.closeDialog()}
        />
      </Grid>
    );
  }
}

UserDetailsBasicData.defaultProps = {
  updateActionDisableMsg: null
};

UserDetailsBasicData.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string.isRequired
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  updateActionDisableStatus: PropTypes.bool.isRequired,
  updateActionDisableMsg: PropTypes.string
};

export default withRouter(UserDetailsBasicData);
