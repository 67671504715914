import { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from 'modules/Layout/component/IconButton';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import { ADMIN, SUPER_ADMIN } from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: '1rem'
  },
  signatureWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.5rem'
  },
  signature: {
    fontSize: 14
  }
});

const SalesGroupNote = props => {
  const { user, hasRole } = useContext(AuthContext);
  const classes = useStyles();
  const {
    note,
    note: { creator_id, content, creator_name, created_at },
    isUserAssignToGroupClient,
    onUpdateClick,
    onDeleteClick
  } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display='flex' flexDirection='column'>
          <Box className={classes.signatureWrapper}>
            <Typography className={classes.signature} color='textSecondary'>
              {creator_name} {created_at}
            </Typography>
            <Box display='flex' alignItems='flex-start'>
              <IconButton
                className='update-icon'
                onClick={() => onUpdateClick(note)}
                icon={<EditIcon />}
                disabled={creator_id !== user.id}
                tooltipMsg='You are not a creator of this file'
                alt='update'
              />
              <IconButton
                className='delete-icon'
                onClick={() => onDeleteClick(note)}
                icon={<DeleteForeverIcon />}
                disabled={
                  !(hasRole([SUPER_ADMIN, ADMIN]) || isUserAssignToGroupClient)
                }
                alt='delete'
              />
            </Box>
          </Box>
          <Typography variant='body2' component='p'>
            {content}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

SalesGroupNote.propTypes = {
  note: PropTypes.shape({
    creator_id: PropTypes.number,
    content: PropTypes.string,
    creator_name: PropTypes.string,
    created_at: PropTypes.string
  }).isRequired,
  isUserAssignToGroupClient: PropTypes.bool.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default SalesGroupNote;
