import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';
import ProductsBatchesApi from 'api/connections/Products/ProductsBatchesApi';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import insertPathParams from 'api/utils/insertPathParams';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import DatePicker from 'modules/Layout/component/Date/DatePicker';

class ProductBatchUpdateForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    const { productId, batchId, productBatch } = this.props;

    this.productId = productId;
    this.batchId = batchId;

    this.state = {
      formData: {
        number: productBatch.number,
        quantity: productBatch.quantity,
        expiration_date: productBatch.expiration_date
      },
      validation: {
        number: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Batch number')
          })
        },
        quantity: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Quantity') })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.redirect();
  }

  handleError(err) {
    const { setAlert } = this.context;
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      this.setState(({ validation: validationState }) => {
        return {
          validation: { ...validationState, ...newValidateState }
        };
      });
      setAlert(message);
    } else if (err instanceof ApiError) {
      setAlert(message);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(prevState => {
      const { formData, validation } = prevState;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  redirect() {
    let path = insertPathParams(ROUTE_PRODUCTS_DETAILS, { id: this.productId });
    path += '?tab_index=batches';

    this.props.history.push(path);
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    const ids = {
      productId: this.productId,
      batchId: this.batchId
    };
    this.setState({ loading: true });
    try {
      await ProductsBatchesApi.updateProductBatch(ids, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { number, quantity, expiration_date },
      validation,
      loading
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='number'
          label='Batch number'
          value={number}
          required
          autoFocus
          onChange={this.onChange}
          errorStatus={validation.number.status}
          errorText={validation.number.message}
        />
        <Input
          name='quantity'
          label='Quantity'
          value={quantity}
          type='number'
          inputProps={{
            min: 0
          }}
          required
          onChange={this.onChange}
          errorStatus={validation.quantity.status}
          errorText={validation.quantity.message}
        />
        <DatePicker
          name='expiration_date'
          label='Expiration date'
          value={expiration_date}
          onChange={this.onChange}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button text={t('Cancel')} onClick={this.redirect} />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

ProductBatchUpdateForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  productId: PropTypes.number.isRequired,
  batchId: PropTypes.number.isRequired,
  productBatch: PropTypes.shape({
    number: PropTypes.string,
    quantity: PropTypes.number,
    expiration_date: PropTypes.string
  }).isRequired
};

export default withRouter(ProductBatchUpdateForm);
