import ValuableTargetModel from 'modules/Targets/models/ValuableTargetModel';
import QuantitativeTargetModel from 'modules/Targets/models/QuantitativeTargetModel';

import {
  VALUABLE_TARGET_TYPE,
  QUANTITATIVE_TARGET_TYPE,
  VALUABLE_MANAGERS_TARGET_TYPE,
  QUANTITATIVE_MANAGERS_TARGET_TYPE
} from 'modules/Targets/targetTypes';

const getTargetModel = type => {
  switch (type) {
    case VALUABLE_TARGET_TYPE:
    case VALUABLE_MANAGERS_TARGET_TYPE:
      return ValuableTargetModel;
    case QUANTITATIVE_TARGET_TYPE:
    case QUANTITATIVE_MANAGERS_TARGET_TYPE:
      return QuantitativeTargetModel;
    default:
      return {};
  }
};

export default function formatSpecificTargetTypeData(
  type,
  target,
  additionalData
) {
  const model = getTargetModel(type);

  if (target) {
    return model.map({ target, additionalData });
  }

  return model.data;
}
