export default {
  Contract: 'Kontrakt',
  Contracts: 'Kontrakty',
  'Clients contracts': 'Kontrakty klientów',
  'Client contract details': 'Szczegóły kontraktu klientów',
  'Create client contract': 'Dodawanie kontraktu klienta',
  'Update client contract': 'Edycja kontraktu klienta',
  Thresholds: 'Progi',
  'Client contract dont have thresholds': 'Kontrakt klienta nie posiada progów',
  'Bonus at the threshold <%=value%>': 'Premia przy progu <%=value%>',
  'Products included in contract': 'Produkty uwzględnione w kontrakcie',
  'Clients bonuses': 'Premie klientów',
  'Close client contract': 'Zamknij kontrakt klienta',
  'Are you sure you want to close client contract <%=contractName%>?':
    'Czy na pewno chcesz zamknać kontrakt klienta <%=contractName%>?',
  'Are you sure you want to recalculate and close client contract <%=contractName%>?':
    'Czy na pewno chcesz przeliczyć i zamknać kontrakt klienta <%=contractName%>?',
  'Are you sure you want to delete client contract <%=contractName%>?':
    'Czy na pewno chcesz usunąć kontrakt klienta <%=contractName%>?',
  'Client contract closed': 'Kontrakt klienta zamknięty',
  'Contract name': 'Nazwa kontraktu',
  'Client bonus details': 'Szczegóły premii klienta',
  'It cannot be closed once the bonus is approved.':
    'Nie mozna ponownie przeliczyć i zamknąć gdy premia jest zatwierdzona.',
  'close contract': 'zamknij kontrakt',
  'recalculate and close': 'przelicz i zamknij'
};
