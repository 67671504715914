import makeStyles from '@material-ui/core/styles/makeStyles';

const setDayBoxColor = (disabled, isClicked, copyVisits) => {
  if (isClicked && !copyVisits) return 'white';
  if (isClicked) return '#00af9a';
  if (disabled) return '#fafafa';
  return 'white';
};

const useStyles = makeStyles(theme => ({
  dayBox: {
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ({ disabled, isClicked, copyVisits }) =>
      setDayBoxColor(disabled, isClicked, copyVisits),
    cursor: ({ copyVisits }) => copyVisits && 'pointer'
  },
  dayButton: {
    display: 'block',
    margin: '0 auto',
    border: 'none',
    cursor: ({ disabled }) => (disabled ? 'normal' : 'pointer'),
    pointerEvents: ({ disabled }) => disabled && 'none',
    backgroundColor: 'transparent',
    transition: 'color 0.2s',
    '&:hover': {
      color: 'gray'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  listItem: {
    padding: theme.spacing(0.3, 2, 0.3, 0)
  }
}));

export const buttonLinkStyle = {
  width: '100%',
  borderRadius: '0 5px 5px 0',
  paddingLeft: 5,
  margin: 0,
  justifyContent: 'unset',
  lineHeight: 1,
  fontSize: 13,
  textAlign: 'left'
};

export default useStyles;
