import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import t from 'translate/translate';

import ClientsApi from 'api/connections/Clients/ClientsApi';
import ClientAccountCreateForm from 'modules/Clients/component/Account/Create/Form';
import { ROUTE_CLIENTS_LIST } from 'routing/routes/Clients';

class ClientAccountCreate extends Component {
  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.clientId = parseInt(props.match.params.id, 10);
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      client: null
    };
  }

  async componentDidMount() {
    this.setCurrentPage(t('Add account for client'));
    this.fetchData();
  }

  componentDidUpdate(preProps, prevState) {
    if (!prevState.client && this.state.client) {
      const { name } = this.state.client;

      this.setCurrentPage(t('Create account for <%=name%>', { name }));
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: client }
      } = await ClientsApi.getClient(this.clientId);

      this.setState({ client });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_CLIENTS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { client } = this.state;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            {client ? (
              <ClientAccountCreateForm clientId={this.clientId} />
            ) : (
              <Box width={1} display='flex' justifyContent='center'>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    );
  }
}

ClientAccountCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ClientAccountCreate);
