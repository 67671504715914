import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import { Link } from 'react-router-dom';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';
import { Box } from '@material-ui/core';
import Authorize from 'modules/Auth/component/Authorize';
import { PRICE_LISTS_MANAGEMENT } from 'api/auth/permissions/PriceLists';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Pagination from 'modules/Layout/component/List/Pagination';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import PriceListsAddProductModal from 'modules/PriceLists/component/Modals/AddProduct';
import PriceListsChangePriceModal from 'modules/PriceLists/component/Modals/ChangeProductPrice';
import renderPrice from 'modules/Products/utils/renderPrice';

class PriceListsDetailsProductsList extends Component {
  static contextType = AuthContext;

  static renderSku(sku, { id }) {
    return (
      <Link
        to={insertPathParams(ROUTE_PRODUCTS_DETAILS, {
          id
        })}
        className='router-link router-link-underline'
        target='_blank'
      >
        {sku}
      </Link>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      actionModals: {
        productToModify: null,
        formModalStatus: false,
        changePriceModalStatus: false
      }
    };

    this.setPagination = this.setPagination.bind(this);
    this.onAddProductClick = this.onAddProductClick.bind(this);
    this.onChangePriceClick = this.onChangePriceClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
  }

  onAddProductClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true
      }
    }));
  }

  onChangePriceClick(productToModify) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        changePriceModalStatus: true,
        productToModify
      }
    }));
  }

  setPagination(pagination) {
    this.setState({
      pagination: { ...this.state.pagination, ...pagination }
    });
  }

  getDataToRender() {
    const { products } = this.props;
    const { per_page, page } = this.state.pagination;

    const paginationFrom = (page - 1) * per_page;
    const paginationTo = paginationFrom + per_page;

    return {
      data: products.slice(paginationFrom, paginationTo),
      total: products.length
    };
  }

  getCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false,
        render: PriceListsDetailsProductsList.renderSku
      },
      {
        property: 'price',
        label: 'Price',
        sortable: false,
        render: price => renderPrice(price, this.props.currency)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasPermission([PRICE_LISTS_MANAGEMENT]),
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onChangePriceClick
          },
          remove: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: ({ id }) => this.props.onProductDeleteSuccess(id)
          }
        }
      }
    ];
  }

  closeAllModals() {
    this.setState({
      actionModals: {
        productToModify: null,
        formModalStatus: false,
        deleteModalStatus: false
      }
    });
  }

  render() {
    const { pagination } = this.state;

    const {
      formModalStatus,
      changePriceModalStatus,
      productToModify
    } = this.state.actionModals;

    const { data, total } = this.getDataToRender();

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={data}
          dense
          actions={this.context.hasPermission([PRICE_LISTS_MANAGEMENT])}
        />
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>
            <Authorize permissions={[PRICE_LISTS_MANAGEMENT]}>
              <IconButton
                className='create-icon'
                onClick={this.onAddProductClick}
                icon={<AddIcon fontSize='large' />}
                alt='create'
              />
            </Authorize>
          </Box>
          <Pagination
            pagination={{ ...pagination, total }}
            setPagination={this.setPagination}
          />
        </Box>
        {formModalStatus && (
          <PriceListsAddProductModal
            products={this.props.products}
            onClose={this.closeAllModals}
            onSuccess={this.props.onProductAddSuccess}
          />
        )}
        {changePriceModalStatus && (
          <PriceListsChangePriceModal
            product={productToModify}
            onClose={this.closeAllModals}
            onSuccess={this.props.onProductChangePriceSuccess}
          />
        )}
      </>
    );
  }
}

PriceListsDetailsProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  currency: PropTypes.string.isRequired,
  onProductAddSuccess: PropTypes.func.isRequired,
  onProductDeleteSuccess: PropTypes.func.isRequired,
  onProductChangePriceSuccess: PropTypes.func.isRequired
};

export default PriceListsDetailsProductsList;
