import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';

class WholesalerProductsMappingFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('product_sku'),
            label: 'Name',
            type: 'select',
            emptyValueText: 'All products',
            options: formatOptions(
              this.props.mapping,
              'product_sku',
              'product_sku'
            )
          }
        ]
      }
    ];
  }
}

export default WholesalerProductsMappingFilter;
