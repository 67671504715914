export default {
  'Price lists': 'Cenniki',
  'Price list': 'Cennik',
  'Price list details': 'Szczegóły cennika',
  'Assigned to': 'Przypisane do',
  'Available for': 'Dostępne dla',
  'Create price list': 'Dodaj cennik',
  'Update price list': 'Edytuj cennik',
  'Are you sure you want to delete price list <%=priceListName%>?':
    'Czy na pewno chcesz usunąć cennik <%=priceListName%>?',
  'Update price for product <%=sku%>': 'Zmień cenę dla produktu <%=sku%>',
  'Add clients to price lists': 'Dodawanie klientów do cennika',
  'Add departments to price lists': 'Dodawanie działów do cennika',
  'Client is already added': 'Klient jest juz dodany',
  'Department is already added': 'Dział jest juz dodany',
  'Departments assign to price list': 'Działy przypisane do cennika',
  'Clients assign to price list': 'Klienci przypisani do cennika',
  'set as default': 'ustaw jako domyślny',
  'remove default': 'usuń domyślny',
  'Default price list': 'Domyślny cennik',
  'Price list used in order': 'Cennik użyty w zamówieniu'
};
