export const PLANNED = 0;
export const CONFIRMED = 1;
export const FINISHED = 2;
export const CANCELED = 3;
export const POSTPONED = 4;

export const statusesObj = {
  [PLANNED]: 'Planned',
  [CONFIRMED]: 'Confirmed',
  [FINISHED]: 'Finished',
  [CANCELED]: 'Canceled',
  [POSTPONED]: 'Postponed'
};

export const visitStatusesList = [
  { status: PLANNED, name: 'Planned' },
  { status: CONFIRMED, name: 'Confirmed' },
  { status: FINISHED, name: 'Finished' },
  { status: CANCELED, name: 'Canceled' },
  { status: POSTPONED, name: 'Postponed' }
];

export const getStatuses = statuses =>
  statuses.map(status => ({ status, name: statusesObj[status] }));
