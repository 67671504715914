import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import TargetsApi from 'api/connections/Targets/TargetsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import TargetsTable from 'modules/Targets/component/List/Table';
import TargetsFilter from 'modules/Targets/component/List/Filter';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import { TARGET_CREATE } from 'api/auth/permissions/Targets';
import { ROUTE_TARGETS_CREATE } from 'routing/routes/Targets';
import {
  QUANTITATIVE_TARGET_TYPE,
  VALUABLE_TARGET_TYPE,
  QUANTITATIVE_MANAGERS_TARGET_TYPE,
  VALUABLE_MANAGERS_TARGET_TYPE
} from 'modules/Targets/targetTypes';
import TargetCopyDialog from 'modules/Targets/component/CopyDialog';
import { DEPARTMENT_MANAGER, DIRECTOR, TELEMARKETER } from 'api/auth/roles';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';
import TargetDeleteDialog from 'modules/Targets/component/TargetDeleteDialog';

class TargetsList extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.loggedUserId = context.user.id;

    this.state = {
      filter: {
        period: {
          period_type: '',
          period_year: null,
          period_quarter: '',
          period_month: ''
        },
        name: null,
        type: null,
        department_id: '',
        user_id: context.hasRole([DIRECTOR, DEPARTMENT_MANAGER])
          ? this.loggedUserId
          : null
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      targets: [],
      departmentsData: context.hasRole([TELEMARKETER])
        ? context.user.departments
        : [],
      loading: true,
      copyDialogOpenStatus: false,
      deleteDialogOpenStatus: false,
      targetToModify: undefined
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchTargets = this.fetchTargets.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.onCopyClick = this.onCopyClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Targets');
    if (this.context.hasPermission([DEPARTMENTS_LIST])) {
      this.fetchDepartments();
    }
    this.fetchTargets();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  onCreateClick() {
    this.props.history.push(ROUTE_TARGETS_CREATE);
  }

  onCopyClick(target) {
    this.setState({
      copyDialogOpenStatus: true,
      targetToModify: target
    });
  }

  onDeleteClick(target) {
    this.setState({
      deleteDialogOpenStatus: true,
      targetToModify: target
    });
  }

  onDialogClose() {
    this.setState({
      copyDialogOpenStatus: false,
      deleteDialogOpenStatus: false,
      targetToModify: undefined
    });
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchTargets()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchTargets()
    );
  }

  setFilter(filter) {
    const formattedFilter = {
      ...filter,
      user_id: filter.user_id ? this.loggedUserId : null
    };

    this.setState(
      {
        filter: { ...this.state.filter, ...formattedFilter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchTargets()
    );
  }

  getPathParams() {
    const {
      sort,
      filter: {
        department_id,
        user_name,
        name,
        type,
        user_id,
        period: { period_type, period_year, period_quarter, period_month }
      },
      pagination: { per_page, page }
    } = this.state;

    const setType = () => {
      const res = {};

      if (type) {
        if (type === QUANTITATIVE_MANAGERS_TARGET_TYPE) {
          res.type = QUANTITATIVE_TARGET_TYPE;
          res.for_management = true;
        } else if (type === VALUABLE_MANAGERS_TARGET_TYPE) {
          res.type = VALUABLE_TARGET_TYPE;
          res.for_management = true;
        } else {
          res.type = type;
        }

        return res;
      }

      return null;
    };

    const setPeriod = () => {
      const res = {
        period_type,
        period_year
      };

      if (period_year) {
        if (period_quarter) res.period_quarter = period_quarter;
        if (period_month) res.period_month = period_month;
      }

      return res;
    };

    return {
      ...sort,
      per_page,
      page,
      department_id: department_id || '',
      user_name: user_name || '',
      name: name || '',
      user_id,
      ...setType(),
      ...setPeriod()
    };
  }

  async fetchTargets() {
    try {
      const {
        data: { data: targets, meta }
      } = await TargetsApi.getTargets(this.getPathParams());

      this.setState({
        targets,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchDepartments() {
    try {
      const {
        data: { data: departmentsData }
      } = await DepartmentsApi.getDepartments({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({ departmentsData });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Authorize permissions={[TARGET_CREATE]}>
            <IconButton
              onClick={this.onCreateClick}
              icon={<AddIcon color='primary' fontSize='large' />}
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const {
      filter,
      sort,
      targets,
      departmentsData,
      loading,
      filterValidation,
      copyDialogOpenStatus,
      deleteDialogOpenStatus,
      targetToModify
    } = this.state;

    return (
      <div>
        <TargetsFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          departmentsData={departmentsData}
        />
        {this.renderActions()}
        <TargetsTable
          sort={sort}
          setSort={this.setSort}
          targets={targets}
          loading={loading}
          onCopyClick={this.onCopyClick}
          onDeleteClick={this.onDeleteClick}
        />
        {this.renderActions()}
        {copyDialogOpenStatus && (
          <TargetCopyDialog
            targetToCopy={targetToModify}
            onSuccess={this.fetchTargets}
            onClose={this.onDialogClose}
            setAlert={this.props.contextMethods.setAlert}
          />
        )}
        {deleteDialogOpenStatus && (
          <TargetDeleteDialog
            target={targetToModify}
            onClose={this.onDialogClose}
            onSuccess={this.fetchTargets}
          />
        )}
      </div>
    );
  }
}

TargetsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(TargetsList);
