import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class DepartmentsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.departmentsPath = 'departments';
    this.departmentPath = 'departments/:id';
    this.exportDepartmentUsersPath = 'departments/:id/users-export';
    // TODO move to PostalCodes module
    this.postalCodesCountriesPath = 'postal-codes/countries';
  }

  getDepartments(pathParams) {
    return this.get(this.departmentsPath, pathParams);
  }

  getDepartment(id) {
    return this.get(insertPathParams(this.departmentPath, { id }));
  }

  createDepartment(payload) {
    return this.post(this.departmentsPath, payload);
  }

  updateDepartment(payload, id) {
    return this.put(insertPathParams(this.departmentPath, { id }), payload);
  }

  deleteDepartment(id) {
    return this.delete(insertPathParams(this.departmentPath, { id }));
  }

  exportDepartmentUsers(id) {
    const options = {
      responseType: 'blob'
    };
    return this.get(
      insertPathParams(this.exportDepartmentUsersPath, { id }),
      {},
      options
    );
  }

  // TODO move to PostalCodes module
  getCountries() {
    return this.get(this.postalCodesCountriesPath);
  }
}

export default new DepartmentsApi();
