import { formatOptions } from 'modules/Layout/component/Select';
import { SUPER_ADMIN } from 'api/auth/roles';
import Filter from 'modules/Layout/component/List/Filter';

class WholesalersFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: 'Identification',
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('tax_number'),
            label: 'Tax number',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: 'Contact',
        children: [
          {
            ...this.getBasicInputValues('phone'),
            label: 'Phone number',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('main_contact_email'),
            label: 'Email',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: 'Address',
        children: [
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'select',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasRole([SUPER_ADMIN])
          },
          {
            ...this.getBasicInputValues('city'),
            label: 'City',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('street'),
            label: 'Street',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('postal_code'),
            label: 'Postal code',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: 'Imports',
        children: [
          {
            ...this.getBasicInputValues('invalid_import'),
            label: 'Invalid import',
            type: 'checkbox'
          },
          {
            ...this.getBasicInputValues('missing_import'),
            label: 'Missing import',
            type: 'checkbox'
          }
        ]
      }
    ];
  }
}

export default WholesalersFilter;
