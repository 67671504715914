import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const DeleteSalesGroupDialog = props => {
  const {
    salesGroup: { id, name },
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteSalesGroup = async () => {
    try {
      await SalesGroupsApi.deleteSalesGroup(id);
      setAlert({
        value: t('Deleting product successfully finish'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      if (err instanceof ApiError) {
        setAlert(err.getPayload().message);
      }
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete shopping group <%=name%>',
        {
          name
        }
      )}
      onApprove={deleteSalesGroup}
      onCancel={onClose}
    />
  );
};

DeleteSalesGroupDialog.propTypes = {
  salesGroup: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default DeleteSalesGroupDialog;
