import { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from 'modules/Layout/component/List/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from 'modules/Layout/component/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isEmpty } from 'lodash/lang';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import t from 'translate/translate';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import renderPrice from 'modules/Products/utils/renderPrice';

const Row = ({ row, columns }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            icon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            alt=''
            onClick={() => setOpen(!open)}
            disabled={isEmpty(row.orders)}
            tooltipMsg='No orders'
          />
        </TableCell>
        {columns.map(column => (
          <TableCell
            align='center'
            className={classes.cell}
            key={`${row.id}-${column.property}`}
          >
            {column.render
              ? column.render(row[column.property], row)
              : row[column.property]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('Orders')}
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Date')}</TableCell>
                    <TableCell>{t('Price')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orders.map(order => (
                    <TableRow key={order.id}>
                      <TableCell component='th' scope='row'>
                        {order.date}
                      </TableCell>
                      <TableCell>
                        {renderPrice(order.total_price, row.currency)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    orders: PropTypes.arrayOf(PropTypes.shape({})),
    date: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    postal_code: PropTypes.string,
    price_sum: PropTypes.number,
    quantity_sum: PropTypes.number,
    id: PropTypes.number,
    currency: PropTypes.string.isRequired
  }).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Row;
