import { useContext } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from 'modules/Layout/component/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Authorize from 'modules/Auth/component/Authorize';
import {
  KNOWLEDGE_NOTES_DELETE,
  KNOWLEDGE_NOTES_UPDATE,
  KNOWLEDGE_NOTES_CREATE
} from 'api/auth/permissions/KnowledgeBase';
import { Box, Divider } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import { KnowledgeBaseContext } from 'modules/KnowledgeBase/view';
import Checkbox from '@material-ui/core/Checkbox';

const CategoryBoxItem = ({
  note,
  dividerStatus,
  onDeleteNoteClick,
  onUpdateNoteClick,
  fetchNoteFile
}) => {
  const {
    sendFiles,
    toggleFileToSend,
    toggleLinkToSend,
    filesToSend,
    linksToSend
  } = useContext(KnowledgeBaseContext);

  return (
    <>
      <ListItem
        style={{
          padding: 0,
          marginBottom: '.5rem'
        }}
      >
        <Box width={1} display='flex'>
          <Box display='flex' alignItems='center'>
            <Avatar
              variant='rounded'
              style={{
                width: 20,
                height: 20,
                fontSize: '0.7rem',
                marginRight: 10
              }}
            >
              {note.language}
            </Avatar>
          </Box>
          <Box width={1}>
            <Box display='flex' alignItems='center'>
              <Box width={1}>
                <ListItem
                  component='div'
                  button
                  onClick={() => fetchNoteFile(note.id, note.file)}
                  disabled={!note.file}
                >
                  <ListItemText
                    primary={note.name}
                    secondary={note.description}
                  />
                </ListItem>
              </Box>
              {sendFiles && note.file && (
                <Box>
                  <Checkbox
                    onChange={e => toggleFileToSend(e.target.checked, note.id)}
                    name='note'
                    checked={filesToSend.includes(note.id)}
                  />
                </Box>
              )}
            </Box>
            {note.link && (
              <Box display='flex' alignItems='center'>
                <Box width={1}>
                  <Link
                    href={note.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ padding: 5, marginLeft: '.5rem' }}
                  >
                    {note.link}
                  </Link>
                </Box>
                {sendFiles && (
                  <Box>
                    <Checkbox
                      onChange={e =>
                        toggleLinkToSend(e.target.checked, note.id)
                      }
                      name='note'
                      checked={linksToSend.includes(note.id)}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {!sendFiles && (
            <Authorize
              permissions={[
                KNOWLEDGE_NOTES_DELETE,
                KNOWLEDGE_NOTES_UPDATE,
                KNOWLEDGE_NOTES_CREATE
              ]}
            >
              <Box display='flex' alignItems='center'>
                <IconButton
                  className='update-icon'
                  style={{ marginRight: 0 }}
                  icon={<EditIcon />}
                  alt='update'
                  onClick={() => onUpdateNoteClick(note.id)}
                />

                <IconButton
                  className='delete-icon'
                  icon={<DeleteIcon />}
                  alt='delete'
                  onClick={() => onDeleteNoteClick(note.id)}
                />
              </Box>
            </Authorize>
          )}
        </Box>
      </ListItem>
      {dividerStatus && <Divider />}
    </>
  );
};

CategoryBoxItem.propTypes = {
  note: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    language: PropTypes.string,
    id: PropTypes.number,
    link: PropTypes.string,
    file: PropTypes.string
  }).isRequired,
  dividerStatus: PropTypes.bool.isRequired,
  onDeleteNoteClick: PropTypes.func.isRequired,
  onUpdateNoteClick: PropTypes.func.isRequired,
  fetchNoteFile: PropTypes.func.isRequired
};

export default CategoryBoxItem;
