import PropTypes from 'prop-types';
import { useState } from 'react';
import CustomDialog from 'modules/Layout/component/CustomDialog';
import Input from 'modules/Layout/component/Input';
import { Box } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { languages } from 'modules/KnowledgeBase/view';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';
import KnowledgeBaseApi from 'api/connections/KnowledgeBase/KnowledgeBaseApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';

const UpdateCategoryModal = ({
  open,
  toggleModalOpenFn,
  onUpdateSuccess,
  categoriesNames,
  categoryToUpdate,
  setAlert
}) => {
  const [inputs, setInputs] = useState(
    Object.entries(categoriesNames).map(([key, value]) => ({
      id: Math.random().toString(36).substr(2, 9),
      language: key,
      value
    }))
  );
  const [buttonLoading, setButtonLoading] = useState(false);

  const onLanguageChange = (e, inputId) => {
    setInputs(
      inputs.map(input =>
        input.id === inputId ? { ...input, language: e.target.value } : input
      )
    );
  };

  const onChange = (e, inputId) => {
    setInputs(
      inputs.map(input =>
        input.id === inputId ? { ...input, value: e.target.value } : input
      )
    );
  };

  const addField = () => {
    setInputs([
      ...inputs,
      { id: Math.random().toString(36).substr(2, 9), language: '', value: '' }
    ]);
  };

  const removeField = (e, inputId) => {
    setInputs(inputs.filter(input => input.id !== inputId));
  };

  const updateCategory = async () => {
    setButtonLoading(true);
    try {
      await KnowledgeBaseApi.updateCategory(categoryToUpdate.id, {
        name: inputs
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onUpdateSuccess(inputs);
    } catch (err) {
      if (err instanceof ValidationApiError || err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      } else {
        console.error(err);
      }
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={toggleModalOpenFn}
      title={t('Update category')}
    >
      {inputs.map(input => (
        <Box display='flex' alignItems='center' key={input.id}>
          <Select
            name='language'
            label='Language'
            value={input.language}
            options={formatOptions(languages, 'slug', 'name')}
            onChange={e => onLanguageChange(e, input.id)}
            emptyValueText='Choose language'
            style={{ input: { marginRight: 10 } }}
          />
          <Input
            onChange={e => onChange(e, input.id)}
            label='Name'
            name='value'
            value={input.value}
          />
          <IconButton
            className='delete-icon'
            icon={<DeleteIcon />}
            alt='delete'
            onClick={e => removeField(e, input.id)}
          />
        </Box>
      ))}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <IconButton
          className='create-icon'
          icon={<AddIcon />}
          alt='create'
          onClick={addField}
          disabled={inputs.length === languages.length}
        />
        <Button
          text={t('Save')}
          color='primary'
          onClick={updateCategory}
          disabled={!inputs.length}
          loading={buttonLoading}
        />
      </Box>
    </CustomDialog>
  );
};

UpdateCategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModalOpenFn: PropTypes.func.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  categoriesNames: PropTypes.shape({}).isRequired,
  categoryToUpdate: PropTypes.shape({ id: PropTypes.number }).isRequired
};

export default UpdateCategoryModal;
