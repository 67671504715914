import IconButton from 'modules/Layout/component/IconButton';
import ListIcon from '@material-ui/icons/List';
import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';

const SalesResultsViewTypeButtons = props => {
  const {
    viewType,
    quantityOrValue,
    onViewTypeChange,
    onQuantityOrValueChange
  } = props;

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='center'>
        <IconButton
          onClick={() => onViewTypeChange('pie')}
          icon={
            <PieChartIcon color={viewType === 'pie' ? 'primary' : 'inherit'} />
          }
          alt='switch to pie chart'
        />
        <IconButton
          onClick={() => onViewTypeChange('bar')}
          icon={
            <BarChartIcon color={viewType === 'bar' ? 'primary' : 'inherit'} />
          }
          alt='switch to bar chart'
        />
        <IconButton
          onClick={() => onViewTypeChange('table')}
          icon={
            <ListIcon color={viewType === 'table' ? 'primary' : 'inherit'} />
          }
          alt='switch to table'
        />
      </Box>
      {onQuantityOrValueChange && (
        <Box display='flex' flexDirection='row' justifyContent='space-around'>
          <Button
            text={t('Quantity')}
            onClick={() => onQuantityOrValueChange('quantity')}
            color={quantityOrValue === 'quantity' ? 'primary' : 'inherit'}
            alt='switch to quantity'
            disabled={viewType === 'table'}
          />
          <Button
            text={t('Value')}
            onClick={() => onQuantityOrValueChange('value')}
            color={quantityOrValue === 'value' ? 'primary' : 'inherit'}
            alt='switch to values'
            disabled={viewType === 'table'}
          />
        </Box>
      )}
    </Box>
  );
};

SalesResultsViewTypeButtons.defaultProps = {
  onQuantityOrValueChange: null,
  quantityOrValue: null
};

SalesResultsViewTypeButtons.propTypes = {
  viewType: PropTypes.string.isRequired,
  quantityOrValue: PropTypes.string,
  onViewTypeChange: PropTypes.func.isRequired,
  onQuantityOrValueChange: PropTypes.func
};

export default SalesResultsViewTypeButtons;
