import { Route } from 'react-router-dom';
import { ORDER_PAYMENT_METHODS_SHOW } from 'api/auth/permissions/PaymentMethods';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import PaymentMethodsList from 'modules/PaymentMethods/view/List';

export const ROUTE_PAYMENT_METHODS_LIST = '/payment_methods';

export const permMap = {
  [ROUTE_PAYMENT_METHODS_LIST]: [ORDER_PAYMENT_METHODS_SHOW]
};

const routes = {
  protected: [
    <Route key='payment-methods-list' exact path={ROUTE_PAYMENT_METHODS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_PAYMENT_METHODS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <PaymentMethodsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
