import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEmpty } from 'lodash/lang';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import t from 'translate/translate';
import Authorize from 'modules/Auth/component/Authorize';
import useStyles from 'modules/Layout/component/Navigator/style';

const NavigatorListItem = props => {
  const {
    nested,
    item: { path, icon, label, permissions, roles }
  } = props;
  const { pathname: locationPath } = useLocation();
  const classes = useStyles();

  const renderItem = () => {
    return (
      <Link to={path} className='router-button'>
        <ListItem
          button
          className={clsx(
            classes.item,
            path === locationPath && classes.itemActiveItem,
            nested && classes.nested
          )}
        >
          <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
          <ListItemText primary={t(label)} />
        </ListItem>
      </Link>
    );
  };

  if (isEmpty(permissions) && isEmpty(roles)) {
    return renderItem();
  }

  return (
    <Authorize permissions={permissions} roles={roles}>
      {renderItem()}
    </Authorize>
  );
};

NavigatorListItem.defaultProps = {
  nested: false
};

NavigatorListItem.propTypes = {
  item: PropTypes.shape({
    path: PropTypes.string.isRequired,
    icon: PropTypes.node,
    label: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  nested: PropTypes.bool
};

export default NavigatorListItem;
