import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import getPathQuery from 'api/utils/getPathQuery';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import {
  ROUTE_CURRENCIES_DETAILS,
  ROUTE_CURRENCIES_LIST
} from 'routing/routes/Currencies';
import { CURRENCIES_MANAGEMENT } from 'api/auth/permissions/Currencies';
import CurrencyFormModal from 'modules/Currencies/component/Modals/Form';
import CurrencyDeleteModal from 'modules/Currencies/component/Modals/Delete';
import CurrencyDetailsRatesList from 'modules/Currencies/component/Details/RatesList';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';

class CurrencyDetails extends Component {
  constructor(props) {
    super(props);

    this.currencyId = parseInt(props.match.params.id, 10);
    this.currencyName = getPathQuery(
      this.props.location.search,
      'currency_name'
    );

    this.state = {
      currencyName: this.currencyName ?? '',
      actionModals: {
        formModalStatus: false,
        deleteModalStatus: false
      }
    };

    this.redirect = this.redirect.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onChangeNameClick = this.onChangeNameClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onChangeNameSuccess = this.onChangeNameSuccess.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
  }

  componentDidMount() {
    if (!this.currencyId || !this.currencyName || this.currencyName === 'PLN') {
      return this.redirect();
    }
    this.props.contextMethods.setCurrentPage('Currency details');
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        return this.redirect();
      }
    } else {
      console.error(err);
    }
  }

  onChangeNameClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true
      }
    }));
  }

  onDeleteClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        deleteModalStatus: true
      }
    }));
  }

  onChangeNameSuccess(currencyName) {
    this.setState(state => ({
      ...state,
      currencyName
    }));

    const path = `${insertPathParams(ROUTE_CURRENCIES_DETAILS, {
      id: this.currencyId
    })}?currency_name=${currencyName}`;

    return this.props.history.push(path);
  }

  redirect() {
    return this.props.history.push(ROUTE_CURRENCIES_LIST);
  }

  closeAllModals() {
    this.setState({
      actionModals: {
        updateModalStatus: false,
        deleteModalStatus: false
      }
    });
  }

  render() {
    const {
      currencyName,
      actionModals: { formModalStatus, deleteModalStatus }
    } = this.state;

    return (
      <>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Box display='flex' alignItems='center'>
                  <Box mr={1}>
                    <Typography component='h6' variant='h6'>
                      {currencyName}
                    </Typography>
                  </Box>
                  <Authorize permissions={[CURRENCIES_MANAGEMENT]}>
                    <IconButton
                      onClick={this.onChangeNameClick}
                      className='update-icon'
                      icon={<EditIcon />}
                      alt='change name'
                    />
                  </Authorize>
                </Box>
                <Box>
                  <Authorize permissions={[CURRENCIES_MANAGEMENT]}>
                    <IconButton
                      onClick={this.onDeleteClick}
                      className='delete-icon'
                      icon={<DeleteForeverIcon />}
                      alt='delete'
                    />
                  </Authorize>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Typography
                component='h6'
                variant='h6'
                style={{ marginBottom: '1rem' }}
              >
                {t('Currency rates')}
              </Typography>
              <CurrencyDetailsRatesList
                currencyId={this.currencyId}
                handleError={this.handleError}
                setAlert={this.props.contextMethods.setAlert}
              />
            </Paper>
          </Grid>
        </Grid>
        {formModalStatus && (
          <CurrencyFormModal
            currency={{ id: this.currencyId, name: currencyName }}
            setAlert={this.props.contextMethods.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.onChangeNameSuccess}
          />
        )}
        {deleteModalStatus && (
          <CurrencyDeleteModal
            currency={{ id: this.currencyId, name: currencyName }}
            handleError={this.handleError}
            setAlert={this.props.contextMethods.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.redirect}
          />
        )}
      </>
    );
  }
}

CurrencyDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(CurrencyDetails);
