import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCurrentPage } from 'modules/Shared/hook';
import { ROUTE_DEPARTMENTS_LIST } from 'routing/routes/Departments';
import { Box, Paper } from '@material-ui/core';
import UpdateDepartmentForm from 'modules/Departments/component/Update/Form';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';
import Loader from 'modules/Layout/component/Loader';

const DepartmentsUpdate = () => {
  const { id: departmentId } = useParams();
  const history = useHistory();

  useCurrentPage('Update department');

  const [data, setData] = useState({
    categoriesObj: null,
    department: null
  });

  const fetchData = async () => {
    const [
      { data: categoriesData },
      {
        data: { data: department }
      }
    ] = await Promise.all([
      ProductCategoriesApi.getProductCategories(),
      DepartmentsApi.getDepartment(departmentId)
    ]);

    setData({ categoriesObj: prepareCategories(categoriesData), department });
  };

  useEffect(() => {
    if (!departmentId) {
      history.push(ROUTE_DEPARTMENTS_LIST);
    } else {
      fetchData();
    }
  }, []);

  if (!data.department) return <Loader />;

  return (
    <Box width={1} display='flex' justifyContent='center'>
      <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
        <Paper style={{ padding: '1rem' }}>
          <UpdateDepartmentForm
            categoriesObj={data.categoriesObj}
            department={data.department}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default DepartmentsUpdate;
