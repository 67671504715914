import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import t from 'translate/translate';
import Table from 'modules/Layout/component/List/Table';
import discountsTypes from 'modules/Discounts/discountsTypes';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_DISCOUNTS_DETAILS,
  ROUTE_DISCOUNTS_UPDATE
} from 'routing/routes/Discounts';
import {
  DISCOUNTS_DELETE,
  DISCOUNTS_UPDATE
} from 'api/auth/permissions/Discounts';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class DiscountsTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_DISCOUNTS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_DISCOUNTS_UPDATE, { id }));
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Discount name',
        sortable: true
      },
      {
        property: 'client_name',
        label: 'Client name',
        sortable: true
      },
      {
        property: 'sales_group_name',
        label: 'Shopping group name',
        sortable: true
      },
      {
        property: 'type',
        label: 'Type',
        sortable: false,
        render: typeId =>
          t(discountsTypes.find(type => type.id === typeId).name)
      },
      {
        property: 'client_address',
        label: 'Client address',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([DISCOUNTS_UPDATE]),
            disable: ({ can_modify }) => !can_modify,
            disableMsg:
              'You can only modify discounts assigned to your clients.',
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([DISCOUNTS_DELETE]),
            disable: ({ can_modify }) => !can_modify,
            disableMsg:
              'You can only modify discounts assigned to your clients.',
            onClick: this.props.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { discounts, sort, setSort, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={discounts}
        sort={sort}
        setSort={setSort}
        loading={loading}
        actions
      />
    );
  }
}

DiscountsTable.defaultProps = {
  discounts: []
};

DiscountsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  discounts: PropTypes.arrayOf(PropTypes.shape({})),
  onDeleteClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(DiscountsTable);
