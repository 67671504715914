import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CategoryBoxItem from 'modules/KnowledgeBase/components/CategoryBoxItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import {
  KNOWLEDGE_CATEGORIES_CREATE,
  KNOWLEDGE_CATEGORIES_UPDATE,
  KNOWLEDGE_CATEGORIES_DELETE
} from 'api/auth/permissions/KnowledgeBase';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Authorize from 'modules/Auth/component/Authorize';
import AddIcon from '@material-ui/icons/Add';
import CreateNoteModal from 'modules/KnowledgeBase/components/CreateNoteModal';
import DeleteNoteModal from 'modules/KnowledgeBase/components/DeleteNoteModal';
import UpdateNoteModal from 'modules/KnowledgeBase/components/UpdateNoteModal';
import fileDownload from 'js-file-download';
import KnowledgeBaseApi from 'api/connections/KnowledgeBase/KnowledgeBaseApi';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import { isEmpty } from 'lodash/lang';
import { KnowledgeBaseContext } from 'modules/KnowledgeBase/view';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';

const CategoryBox = ({
  name,
  notes,
  id,
  deleteCategoryModalOpenFn,
  updateCategoryModalOpenFn,
  onCreateNoteSuccess,
  onDeleteNoteSuccess,
  onUpdateNoteSuccess,
  setAlert
}) => {
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
  const [deleteNoteModalOpen, setDeleteNoteModalOpen] = useState(false);
  const [updateNoteModalOpen, setUpdateNoteModalOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [noteToUpdate, setNoteToUpdate] = useState(null);
  const [showMoreStatus, setShowMoreStatus] = useState(false);
  const { sendFiles } = useContext(KnowledgeBaseContext);

  const onDeleteNoteClick = noteId => {
    setNoteToDelete(notes.find(note => note.id === noteId));
    setDeleteNoteModalOpen(true);
  };

  const onUpdateNoteClick = noteId => {
    setNoteToUpdate(notes.find(note => note.id === noteId));
    setUpdateNoteModalOpen(true);
  };

  const fetchNoteFile = async (noteId, fileName) => {
    try {
      const res = await KnowledgeBaseApi.getNoteFile(noteId);

      const file = new Blob([res.data]);

      fileDownload(file, fileName);
    } catch (err) {
      if (err instanceof ValidationApiError || err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      } else {
        console.error(err);
      }
    }
  };

  const notesToRender = showMoreStatus ? notes : notes.slice(0, 5);

  return (
    <>
      <Paper className='p1'>
        <Box display='flex' alignItems='center'>
          <Typography variant='h5' style={{ marginRight: '10px' }}>
            {name}
          </Typography>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!sendFiles && (
            <Authorize
              permissions={[
                KNOWLEDGE_CATEGORIES_CREATE,
                KNOWLEDGE_CATEGORIES_UPDATE,
                KNOWLEDGE_CATEGORIES_DELETE
              ]}
            >
              <IconButton
                className='create-icon'
                icon={<AddIcon />}
                alt='create file'
                onClick={() => {
                  setCreateNoteModalOpen(true);
                }}
              />
              <IconButton
                className='update-icon'
                style={{ marginRight: 0 }}
                icon={<EditIcon />}
                alt='update'
                onClick={() => updateCategoryModalOpenFn(id)}
              />
              <IconButton
                className='delete-icon'
                icon={<DeleteIcon />}
                alt='delete'
                onClick={() => deleteCategoryModalOpenFn(id)}
                disabled={!isEmpty(notes)}
                tooltipMsg='Category cannot be deleted if there are notes.'
              />
            </Authorize>
          )}
        </Box>
        <List>
          {notesToRender.map((note, index) => (
            <CategoryBoxItem
              key={note.id}
              note={note}
              dividerStatus={index !== notesToRender.length - 1}
              onDeleteNoteClick={onDeleteNoteClick}
              onUpdateNoteClick={onUpdateNoteClick}
              fetchNoteFile={fetchNoteFile}
            />
          ))}
        </List>
        {notes.length > 5 && (
          <Box display='flex' justifyContent='center'>
            <Button
              onClick={() => setShowMoreStatus(!showMoreStatus)}
              color='primary'
              text={t(showMoreStatus ? 'Show less' : 'Show more')}
              style={{ margin: 0, marginTop: '.4rem' }}
            />
          </Box>
        )}
      </Paper>
      <CreateNoteModal
        open={createNoteModalOpen}
        toggleModalOpenFn={() => {
          setCreateNoteModalOpen(!createNoteModalOpen);
        }}
        onCreateNoteSuccess={() => {
          onCreateNoteSuccess();
          setCreateNoteModalOpen(false);
        }}
        categoryId={id}
      />
      {deleteNoteModalOpen && (
        <DeleteNoteModal
          open={deleteNoteModalOpen}
          onCancel={() => setDeleteNoteModalOpen(false)}
          noteToDelete={noteToDelete}
          onDeleteSuccess={onDeleteNoteSuccess}
        />
      )}
      {updateNoteModalOpen && (
        <UpdateNoteModal
          open={updateNoteModalOpen}
          toggleModalOpenFn={() => {
            setUpdateNoteModalOpen(!updateNoteModalOpen);
          }}
          onUpdateNoteSuccess={() => {
            onUpdateNoteSuccess();
            setUpdateNoteModalOpen(false);
          }}
          categoryId={id}
          note={noteToUpdate}
          fetchNoteFile={fetchNoteFile}
        />
      )}
    </>
  );
};

CategoryBox.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  deleteCategoryModalOpenFn: PropTypes.func.isRequired,
  updateCategoryModalOpenFn: PropTypes.func.isRequired,
  onCreateNoteSuccess: PropTypes.func.isRequired,
  onDeleteNoteSuccess: PropTypes.func.isRequired,
  onUpdateNoteSuccess: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default CategoryBox;
