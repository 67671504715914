import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getYear } from 'date-fns';
import { Grid, Paper } from '@material-ui/core';
import t from 'translate/translate';
import Loader from 'modules/Layout/component/Loader';
import { ROUTE_DEPARTMENTS_LIST } from 'routing/routes/Departments';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import SalesResultsApi from 'api/connections/SalesResults/SalesResultsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';

import Dialog from 'modules/Layout/component/Dialog';
import DepartmentDataSet from 'modules/Departments/component/Details/DepartmentDataSet';
import DepartmentUserList from 'modules/Departments/component/Details/DepartmentUserList';
import DepartmentYearSalesResults from 'modules/Departments/component/Details/SalesPeriodArchives';
import { downloadFile } from 'modules/Shared/utils/file';

class DepartmentsDetailsBasicData extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      department: null,
      deleteDepartmentDialogOpen: false,
      categoriesObj: null,
      salesResults: null,
      loading: false
    };

    this.onDeleteDepartmentClick = this.onDeleteDepartmentClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.exportDepartmentUsers = this.exportDepartmentUsers.bind(this);
    this.deleteDepartment = this.deleteDepartment.bind(this);
    this.onPeriodDateChange = this.onPeriodDateChange.bind(this);
    this.checkIfUserIsDepartmentDirector = this.checkIfUserIsDepartmentDirector.bind(
      this
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  onDeleteDepartmentClick() {
    this.setState({ deleteDepartmentDialogOpen: true });
  }

  onDialogClose() {
    this.setState({ deleteDepartmentDialogOpen: false });
  }

  async onPeriodDateChange(year) {
    try {
      const {
        data: salesResults
      } = await SalesResultsApi.getSalesResultsDetails({
        department_id: this.props.departmentId,
        period_type: 'year',
        period: year
      });

      this.setState({ salesResults });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  async deleteDepartment() {
    try {
      this.setState({ loading: true });
      await DepartmentsApi.deleteDepartment(this.props.departmentId);
      this.setState({ deleteDepartmentDialogOpen: false, loading: false }, () =>
        this.props.history.push(ROUTE_DEPARTMENTS_LIST)
      );
    } catch (err) {
      this.props.handleError(err);
    }
  }

  async fetchData() {
    try {
      const [
        {
          data: { data: department }
        },
        { data: categoriesData },
        { data: salesResults }
      ] = await Promise.all([
        DepartmentsApi.getDepartment(this.props.departmentId),
        ProductCategoriesApi.getProductCategories(),
        SalesResultsApi.getSalesResultsDetails({
          department_id: this.props.departmentId,
          period_type: 'year',
          period: getYear(new Date())
        })
      ]);

      this.setState({
        department,
        categoriesObj: prepareCategories(categoriesData),
        salesResults
      });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  async exportDepartmentUsers() {
    try {
      const res = await DepartmentsApi.exportDepartmentUsers(
        this.props.departmentId
      );

      downloadFile(res);
    } catch (err) {
      this.props.handleError(err);
    }
  }

  checkIfUserIsDepartmentDirector() {
    const { id: userId } = this.context.user;
    const { users } = this.state.department;

    return users.find(user => user.id === userId);
  }

  render() {
    const {
      department,
      categoriesObj,
      deleteDepartmentDialogOpen,
      salesResults,
      loading
    } = this.state;

    if (!department) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={12}>
              <Paper className='p1'>
                <DepartmentDataSet
                  department={department}
                  onDeleteClick={this.onDeleteDepartmentClick}
                  categoriesObj={categoriesObj}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className='p1'>
                <DepartmentYearSalesResults
                  salesResults={salesResults}
                  onPeriodDateChange={this.onPeriodDateChange}
                  checkIfUserIsDepartmentDirector={
                    this.checkIfUserIsDepartmentDirector
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <DepartmentUserList
              users={department.users}
              exportDepartmentUsers={this.exportDepartmentUsers}
            />
          </Paper>
        </Grid>
        <Dialog
          open={deleteDepartmentDialogOpen}
          title={t('Deletion confirmation')}
          description={t(
            'Are you sure you want to delete department <%=name%>',
            { name: department.name }
          )}
          onApprove={this.deleteDepartment}
          onCancel={this.onDialogClose}
          loading={loading}
        />
      </Grid>
    );
  }
}

DepartmentsDetailsBasicData.propTypes = {
  departmentId: PropTypes.number.isRequired,
  handleError: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(DepartmentsDetailsBasicData);
