import { Route } from 'react-router-dom';
import {
  PRODUCT_CATEGORIES_LIST,
  PRODUCT_CATEGORIES_CREATE,
  PRODUCT_CATEGORIES_UPDATE
} from 'api/auth/permissions/Products';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ProductCategoriesList from 'modules/ProductCategories/view/List';
import ProductCategoriesCreate from 'modules/ProductCategories/view/Create';
import ProductCategoriesUpdate from 'modules/ProductCategories/view/Update';

export const ROUTE_PRODUCT_CATEGORIES_LIST = '/product_categories';
export const ROUTE_PRODUCT_CATEGORIES_CREATE = '/product_categories/create';
export const ROUTE_PRODUCT_CATEGORIES_UPDATE = '/product_categories/update';

export const permMap = {
  [ROUTE_PRODUCT_CATEGORIES_LIST]: [PRODUCT_CATEGORIES_LIST],
  [ROUTE_PRODUCT_CATEGORIES_CREATE]: [PRODUCT_CATEGORIES_CREATE],
  [ROUTE_PRODUCT_CATEGORIES_UPDATE]: [PRODUCT_CATEGORIES_UPDATE]
};

const routes = {
  protected: [
    <Route key='product-categories' exact path={ROUTE_PRODUCT_CATEGORIES_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_PRODUCT_CATEGORIES_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <ProductCategoriesList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='product-categories-create'
      exact
      path={ROUTE_PRODUCT_CATEGORIES_CREATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_PRODUCT_CATEGORIES_CREATE]}
      >
        <AuthContext.Consumer>
          {({ user }) => (
            <BasicContextConsumerWrapper
              component={fn => (
                <ProductCategoriesCreate contextMethods={fn} user={user} />
              )}
            />
          )}
        </AuthContext.Consumer>
      </Authorize>
    </Route>,
    <Route
      key='product-categories-update'
      exact
      path={ROUTE_PRODUCT_CATEGORIES_UPDATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_PRODUCT_CATEGORIES_UPDATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => <ProductCategoriesUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
