import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton as MaterialUiIconButton
} from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import t from 'translate/translate';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'modules/Layout/component/Button';
import Dialog from '@material-ui/core/Dialog';
import Input from 'modules/Layout/component/Input';
import format from 'date-fns/format';
import { API_DATE_TIME_FORMAT } from 'modules/Layout/component/Date/DateTimePicker';

class TimePicker extends Component {
  static getInitTime(date) {
    if (!date) {
      return {
        hourFirst: 0,
        hourSecond: 0,
        minutesFirst: 0,
        minutesSecond: 0
      };
    }

    const time = format(date, API_DATE_TIME_FORMAT).split(' ')[1];
    const [hour, minute] = time.split(':');

    return {
      hourFirst: parseInt(hour[0], 10),
      hourSecond: parseInt(hour[1], 10),
      minutesFirst: parseInt(minute[0], 10),
      minutesSecond: parseInt(minute[1], 10)
    };
  }

  constructor(props) {
    super(props);

    const initTime = TimePicker.getInitTime(props.date);

    this.state = {
      ...initTime,
      dialogStatus: false
    };

    this.onTimeChange = this.onTimeChange.bind(this);
    this.onTimeSave = this.onTimeSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.date && this.props.date) {
      this.setInitTime();
    }
  }

  onTimeChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: parseInt(value, 10) });
  }

  onTimeSave() {
    const { hourFirst, hourSecond, minutesFirst, minutesSecond } = this.state;
    const hours = parseInt(`${hourFirst}${hourSecond}`, 10);
    const minutes = parseInt(`${minutesFirst}${minutesSecond}`, 10);

    this.setState({ dialogStatus: false });
    this.props.onChange({ hours, minutes });
  }

  setInitTime() {
    const initTime = TimePicker.getInitTime(this.props.date);

    this.setState({
      ...initTime,
      dialogStatus: false
    });
  }

  getLimits() {
    return {
      hourFirst: [0, 2],
      hourSecond: this.state.hourFirst === 2 ? [0, 3] : [0, 9],
      minutesFirst: [0, 5],
      minutesSecond: [0, 9]
    };
  }

  getValidation() {
    const { hourFirst, hourSecond, minutesFirst, minutesSecond } = this.state;

    const setHourSecond = () => {
      return hourFirst === 2
        ? hourSecond >= 0 && hourSecond <= 3
        : hourSecond >= 0 && hourSecond <= 9;
    };

    return {
      hourFirst: hourFirst >= 0 && hourFirst <= 2,
      hourSecond: setHourSecond(),
      minutesFirst: minutesFirst >= 0 && minutesFirst <= 5,
      minutesSecond: minutesSecond >= 0 && minutesSecond <= 9
    };
  }

  renderTimeItem(name, limits, validation) {
    return (
      <Box display='flex' flexDirection='column' alignItems='center'>
        <MaterialUiIconButton
          disabled={this.state[name] >= limits[name][1]}
          onClick={() =>
            this.onTimeChange({ target: { name, value: this.state[name] + 1 } })
          }
        >
          <KeyboardArrowUpIcon />
        </MaterialUiIconButton>
        <Input
          name={name}
          value={this.state[name]}
          onChange={this.onTimeChange}
          inputProps={{
            min: limits[name][0],
            max: limits[name][1],
            style: { width: '20px' }
          }}
          errorStatus={!validation[name]}
        />
        <MaterialUiIconButton
          disabled={this.state[name] <= limits[name][0]}
          onClick={() =>
            this.onTimeChange({ target: { name, value: this.state[name] - 1 } })
          }
        >
          <KeyboardArrowDownIcon />
        </MaterialUiIconButton>
      </Box>
    );
  }

  render() {
    const {
      hourFirst,
      hourSecond,
      minutesFirst,
      minutesSecond,
      dialogStatus
    } = this.state;

    const limits = this.getLimits();
    const validation = this.getValidation();

    return (
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Typography style={{ whiteSpace: 'nowrap' }}>
          {`${hourFirst}${hourSecond} : ${minutesFirst}${minutesSecond}`}
        </Typography>
        <IconButton
          className='update-icon'
          onClick={() => this.setState({ dialogStatus: true })}
          icon={<AccessTimeIcon />}
          alt='set time'
          disabled={!this.props.date}
          tooltipMsg='Pick date first'
        />
        <Dialog
          open={dialogStatus}
          onClose={() => {
            this.setState({ dialogStatus: false });
          }}
          aria-labelledby='form'
          transitionDuration={{
            enter: 200,
            exit: 100
          }}
        >
          <DialogContent>
            <Box display='flex' alignItems='center'>
              {this.renderTimeItem('hourFirst', limits, validation)}
              {this.renderTimeItem('hourSecond', limits, validation)}
              <Box m={1}>
                <Typography>:</Typography>
              </Box>
              {this.renderTimeItem('minutesFirst', limits, validation)}
              {this.renderTimeItem('minutesSecond', limits, validation)}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              form='form'
              color='primary'
              onClick={this.onTimeSave}
              disabled={Object.values(validation).includes(false)}
              text={t('Save')}
            />
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

TimePicker.defaultProps = {
  date: null
};

TimePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired
};

export default TimePicker;
