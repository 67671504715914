import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class WholesalersImportColumnsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.wholesalersImportColumnsPath = 'wholesalers/import-columns';
    this.wholesalersImportColumnsIdPath = 'wholesalers/:id/import-columns';
  }

  getWholesalersImportColumns(pathParams) {
    return this.get(this.wholesalersImportColumnsPath, pathParams);
  }

  updateWholesalersImportColumns(id, payload) {
    return this.put(
      insertPathParams(this.wholesalersImportColumnsIdPath, { id }),
      payload
    );
  }
}

export default new WholesalersImportColumnsApi();
