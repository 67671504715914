import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import VisitProductsList from 'modules/Visits/components/Details/Products/List';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import { VISITS_UPDATE } from 'api/auth/permissions/Visits';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_VISIT_PRODUCT_ADD } from 'routing/routes/Visits';

class VisitDetailsProducts extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.visitId = parseInt(props.match.params.id, 10);

    const { visit_products: products } = this.props.visit;

    this.state = {
      products
    };

    const { setAlert } = props;
    this.setAlert = setAlert;

    this.onDeleteProductSuccess = this.onDeleteProductSuccess.bind(this);
    this.onUpdateProductSuccess = this.onUpdateProductSuccess.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
  }

  onDeleteProductSuccess(productId) {
    this.setState(prevState => {
      return {
        ...prevState,
        products: prevState.products.filter(product => product.id !== productId)
      };
    });
  }

  onUpdateProductSuccess(productId, note) {
    this.setState(prevState => {
      const updatedProduct = prevState.products.find(
        ({ id }) => productId === id
      );
      updatedProduct.note = note;
      return {
        ...prevState,
        products: [
          ...prevState.products.filter(product => product.id !== productId),
          updatedProduct
        ]
      };
    });
  }

  onCreateClick() {
    this.props.history.push(
      insertPathParams(ROUTE_VISIT_PRODUCT_ADD, { id: this.visitId })
    );
  }

  render() {
    const { products, sort } = this.state;
    const {
      visit: { id: visitId, user_id }
    } = this.props;
    const { user } = this.context;

    return (
      <Paper style={{ padding: '1rem' }}>
        <VisitProductsList
          products={products}
          visitId={visitId}
          sort={sort}
          onDeleteProductSuccess={this.onDeleteProductSuccess}
          onUpdateProductSuccess={this.onUpdateProductSuccess}
        />
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>
            <Authorize permissions={[VISITS_UPDATE]}>
              <IconButton
                className='create-icon'
                onClick={this.onCreateClick}
                icon={<AddIcon fontSize='large' />}
                disabled={
                  !(
                    this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) ||
                    user.id === user_id
                  )
                }
                tooltipMsg='You are not assign to this visit'
                alt='create'
              />
            </Authorize>
          </Box>
        </Box>
      </Paper>
    );
  }
}

VisitDetailsProducts.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  visit: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    visit_products: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

export default withRouter(VisitDetailsProducts);
