import Filter from 'modules/Layout/component/List/Filter';

class ClientNotesFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('creator_name'),
            label: 'Author',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('created_at_from'),
            label: 'Created at from',
            type: 'dateTime'
          },
          {
            ...this.getBasicInputValues('created_at_to'),
            label: 'Created at to',
            type: 'dateTime'
          }
        ]
      }
    ];
  }
}

export default ClientNotesFilter;
