import { Box } from '@material-ui/core';
import CountriesList from 'modules/PostalCodes/component/CountriesList';
import RegionsList from 'modules/PostalCodes/component/RegionsList';
import SubregionsList from 'modules/PostalCodes/component/SubregionsList';
import SubSubregionsList from 'modules/PostalCodes/component/SubSubregionsList';
import CitiesList from 'modules/PostalCodes/component/CitiesList';
import PostalCodesList from 'modules/PostalCodes/component/PostalCodesList';
import Authorize from 'modules/Auth/component/Authorize';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { useCurrentPage } from 'modules/Shared/hook';
import t from 'translate/translate';

const PostalCodesView = () => {
  useCurrentPage(t('Postal codes'));
  return (
    <Box key='box' display='flex' justifyContent='flex-start'>
      <Authorize
        key='postal_codes_countries'
        permissions={[POSTAL_CODES_COUNTRIES_SHOW]}
      >
        <CountriesList />
      </Authorize>
      <RegionsList />
      <SubregionsList />
      <SubSubregionsList />
      <CitiesList />
      <PostalCodesList />
    </Box>
  );
};

export default PostalCodesView;
