import { Component } from 'react';
import PropTypes from 'prop-types';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import { ROUTE_WHOLESALERS_LIST } from 'routing/routes/Wholesalers';
import getPathQuery from 'api/utils/getPathQuery';
import Loader from 'modules/Layout/component/Loader';
import WholesalersAgenciesApi from 'api/connections/Wholesalers/WholesalersAgenciesApi';

import WholesalerAgencyForm from 'modules/Wholesalers/component/Details/Agencies/Form';

class WholesalerAgencyUpdate extends Component {
  constructor(props) {
    super(props);

    this.wholesalerId = parseInt(props.match.params.id, 10);
    this.agencyId = parseInt(props.match.params.agency_id, 10);
    this.wholesalerName = getPathQuery(
      props.location.search,
      'wholesaler_name'
    );

    this.state = {
      agency: undefined
    };
  }

  componentDidMount() {
    if (!this.wholesalerId) {
      this.props.history.push(ROUTE_WHOLESALERS_LIST);
    }

    this.props.contextMethods.setCurrentPage('Update wholesaler agency');

    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_WHOLESALERS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: agency }
      } = await WholesalersAgenciesApi.getWholesalerAgency({
        wholesalerId: this.wholesalerId,
        agencyId: this.agencyId
      });

      this.setState({ agency });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { agency } = this.state;

    if (!agency) return <Loader />;

    return (
      <Grid container justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <WholesalerAgencyForm
              agency={agency}
              wholesalerId={this.wholesalerId}
              wholesalerName={this.wholesalerName}
              setAlert={this.props.contextMethods.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

WholesalerAgencyUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      agency_id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerAgencyUpdate);
