import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { validateBaseOnFormInputs } from 'modules/Shared/utils/validator';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import CurrenciesApi from 'api/connections/Currencies/CurrenciesApi';

class CurrencyFormModal extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.currency = props.currency;

    this.state = {
      formData: {
        name: this.currency?.name ?? ''
      },
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse(res) {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onClose();
    this.props.onSuccess(res.data.data.name);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.props.setAlert(message);

      if (err instanceof ValidationApiError) {
        const validateBaseOnFormInputsState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...validateBaseOnFormInputsState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation(e.target.elements);
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation(formElements) {
    const { validation } = this.state;
    const [isValid, validateBaseOnFormInputsState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!isValid) {
      this.setState({ validation: validateBaseOnFormInputsState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });

    try {
      let res;

      if (this.currency) {
        res = await CurrenciesApi.updateCurrency(this.currency.id, formData);
      } else {
        res = await CurrenciesApi.createCurrency(formData);
      }

      this.setState({ loading: false });
      this.handleResponse(res);
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { name },
      validation,
      loading
    } = this.state;

    const { onClose } = this.props;

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>
          {t(this.currency ? 'Update currency' : 'Create currency')}
        </DialogTitle>
        <DialogContent>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            <Input
              name='name'
              label='Name'
              value={name}
              onChange={this.onChange}
              required
              autoFocus
              errorStatus={validation.name.status}
              errorText={validation.name.message}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button
            form='form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

CurrencyFormModal.defaultProps = {
  currency: null
};

CurrencyFormModal.propTypes = {
  currency: PropTypes.shape({}),
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default CurrencyFormModal;
