import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ClientsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.clientsPath = 'clients';
    this.internetClientsPath = 'clients/internet';
    this.notVisitedClientsPath = 'clients/not-visited';
    this.allClientsPath = 'clients/all';
    this.clientPath = 'clients/:id';
    this.updateClientCommentPath = 'clients/:id/enova-notes';
    this.unassignedClientPath = 'clients/unassigned';
  }

  getClients(pathParams) {
    const params = {
      active: true,
      status: 1,
      ...pathParams
    };
    return this.get(this.clientsPath, params);
  }

  getInternetClients(pathParams) {
    const params = {
      status: 1,
      ...pathParams
    };
    return this.get(this.internetClientsPath, params);
  }

  getNotVisitedClients(pathParams) {
    return this.get(this.notVisitedClientsPath, pathParams);
  }

  getAllClients(pathParams) {
    const params = {
      active: true,
      status: 1,
      ...pathParams
    };
    return this.get(this.allClientsPath, params);
  }

  getClient(id) {
    return this.get(insertPathParams(this.clientPath, { id }));
  }

  createClient(payload) {
    return this.post(this.clientsPath, payload);
  }

  updateClient(id, payload) {
    return this.put(insertPathParams(this.clientPath, { id }), payload);
  }

  updateClientComment(id, payload) {
    return this.put(
      insertPathParams(this.updateClientCommentPath, { id }),
      payload
    );
  }

  getUnassignedClient(payload) {
    return this.get(this.unassignedClientPath, payload);
  }
}

export default new ClientsApi();
