import { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { Box } from '@material-ui/core';
import YearPicker from 'modules/Layout/component/Date/YearPicker';
import IconButton from 'modules/Layout/component/IconButton';
import SyncIcon from '@material-ui/icons/Sync';
import { months, periodTypes, quarters } from 'modules/Shared/dateConsts';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 1 250px',
      marginRight: theme.spacing(1.5)
    }
  }
});

class PeriodPicker extends Component {
  static getPeriodDataFromProps(values) {
    const result = { ...values };

    const { period_type, period_from } = values;

    if (period_from && period_type) {
      const [firsSplitPart, secondSplitPart] = period_from.split('.');
      result.period_year = secondSplitPart;

      if (period_type === 'quarter') {
        result.period_quarter = `${parseInt(firsSplitPart, 10)}`;
      }
      if (period_type === 'month') {
        result.period_month = `${parseInt(firsSplitPart, 10)}`;
      }
    }

    return result;
  }

  constructor(props) {
    super(props);

    this.state = PeriodPicker.getPeriodDataFromProps(props.values);

    this.onChange = this.onChange.bind(this);
    this.clearInputs = this.clearInputs.bind(this);
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState(
      state => {
        if (name === 'period_type') {
          return {
            period_type: value,
            period_year: null,
            period_quarter: '',
            period_month: '',
            period_from: null
          };
        }
        return {
          ...state,
          [name]: value
        };
      },
      () => this.changePeriodFrom()
    );
  }

  changePeriodFrom() {
    this.setState(
      state => {
        return {
          ...state,
          period_from: this.formatPeriodFrom()
        };
      },
      () => this.props.onChange(this.state)
    );
  }

  formatPeriodFrom() {
    const {
      period_type,
      period_year,
      period_quarter,
      period_month
    } = this.state;

    if (period_type === 'year' && period_year) return `1.${period_year}`;
    if (period_type === 'quarter' && period_quarter && period_year) {
      let period = 1;

      if (period_quarter === 2) period = 4;
      if (period_quarter === 3) period = 7;
      if (period_quarter === 4) period = 10;

      return `${period}.${period_year}`;
    }
    if (period_type === 'month' && period_year && period_month)
      return `${period_month}.${period_year}`;

    return null;
  }

  clearInputs() {
    this.onChange({ target: { name: 'period_type', value: '' } });
  }

  render() {
    const {
      period_type,
      period_year,
      period_quarter,
      period_month
    } = this.state;
    const { validation, classes } = this.props;

    return (
      <Box className={classes.wrapper}>
        <Box display='flex' alignItems='center' className={classes.item}>
          <Box mt={1}>
            <IconButton
              onClick={this.clearInputs}
              className='clear-inputs'
              icon={<SyncIcon />}
              size='small'
              alt='clear inputs'
            />
          </Box>
          <Select
            name='period_type'
            label='Period type'
            value={period_type}
            fullWidth
            options={formatOptions(periodTypes, 'slug', 'name', true)}
            onChange={this.onChange}
            required
            errorStatus={validation.period_type?.status}
            errorText={validation.period_type?.message}
          />
        </Box>
        {period_type && (
          <Box className={classes.item}>
            <YearPicker
              name='period_year'
              label='Year'
              value={period_year}
              onChange={this.onChange}
              required
              errorStatus={validation.period_from?.status}
              errorText={validation.period_from?.message}
            />
          </Box>
        )}
        {period_type === 'quarter' && (
          <Box className={classes.item}>
            <Select
              name='period_quarter'
              label='Quarter'
              value={period_quarter}
              fullWidth
              options={formatOptions(quarters, 'id', 'name')}
              onChange={this.onChange}
              required
              errorStatus={validation.period_from?.status}
              errorText={validation.period_from?.message}
            />
          </Box>
        )}
        {period_type === 'month' && (
          <Box className={classes.item}>
            <Select
              name='period_month'
              label='Month'
              value={period_month}
              fullWidth
              options={formatOptions(months, 'id', 'name', true)}
              onChange={this.onChange}
              required
              errorStatus={validation.period_from?.status}
              errorText={validation.period_from?.message}
            />
          </Box>
        )}
      </Box>
    );
  }
}

PeriodPicker.defaultProps = {
  values: {
    period_type: '',
    period_year: null,
    period_quarter: '',
    period_month: '',
    period_from: ''
  },
  validation: {
    period_type: {
      status: false,
      message: null
    },
    period_year: {
      status: false,
      message: null
    },
    period_quarter: {
      status: false,
      message: null
    },
    period_month: {
      status: false,
      message: null
    }
  }
};

PeriodPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.shape({
    period_type: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string
    }),
    period_from: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string
    })
  }),
  values: PropTypes.shape({
    period_type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    period_year: PropTypes.string,
    period_quarter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    period_month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    period_from: PropTypes.string
  }),
  classes: PropTypes.shape({
    wrapper: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(useStyles)(PeriodPicker);
