import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import { Box, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import {
  CLIENTS_CONTRACTS_UPDATE,
  CLIENTS_CONTRACTS_DELETE,
  CLIENTS_CONTRACTS_CONFIRM
} from 'api/auth/permissions/ClientsContracts';
import {
  ROUTE_CLIENTS_CONTRACTS_UPDATE,
  ROUTE_CLIENTS_CONTRACTS_LIST
} from 'routing/routes/ClientsContracts';
import {
  BONUS_TYPE_CONFIRMED,
  BONUS_TYPE_PAID,
  BONUS_TYPE_SUSPENDED
} from 'modules/ClientsBonuses/clientBonusStatuses';
import insertPathParams from 'api/utils/insertPathParams';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import CheckIcon from '@material-ui/icons/Check';
import clientContractStatuses, {
  CONFIRMED_CLIENTS_CONTRACT_STATUS
} from 'modules/ClientsContracts/clientContractStatuses';

import ClientsContractsDeleteDialog from 'modules/ClientsContracts/component/DeleteDialog';
import ClientsContractsConfirmDialog from 'modules/ClientsContracts/component/ConfirmDialog';
import { ADMIN, SUPER_ADMIN } from 'api/auth/roles';

class ClientsContractsDataSets extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      deleteDialogOpen: false,
      confirmDialogOpen: false
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.isCloseDisable = this.isCloseDisable.bind(this);
  }

  onDeleteClick() {
    this.setState({ deleteDialogOpen: true });
  }

  onConfirmClick() {
    this.setState({ confirmDialogOpen: true });
  }

  onDialogClose() {
    this.setState({ deleteDialogOpen: false, confirmDialogOpen: false });
  }

  onDeleteSuccess() {
    this.props.history.push(ROUTE_CLIENTS_CONTRACTS_LIST);
  }

  onUpdateClick() {
    const { id } = this.props.data;
    this.props.history.push(
      insertPathParams(ROUTE_CLIENTS_CONTRACTS_UPDATE, { id })
    );
  }

  isCloseDisable() {
    const { bonuses_items } = this.props.data;

    return Boolean(
      bonuses_items.find(bonus =>
        [BONUS_TYPE_CONFIRMED, BONUS_TYPE_PAID, BONUS_TYPE_SUSPENDED].includes(
          bonus.status
        )
      )
    );
  }

  renderCloseContractButton() {
    if (!this.context.hasPermission([CLIENTS_CONTRACTS_CONFIRM])) return;

    const isClose =
      this.props.data.status === CONFIRMED_CLIENTS_CONTRACT_STATUS;

    if (isClose && !this.context.hasRole([SUPER_ADMIN, ADMIN])) return;

    return (
      <IconButton
        onClick={this.onConfirmClick}
        className='details-icon'
        icon={<CheckIcon />}
        alt={isClose ? 'recalculate and close' : 'close contract'}
        disabled={this.isCloseDisable()}
        tooltipMsg={t('It cannot be closed once the bonus is approved.')}
      />
    );
  }

  render() {
    const {
      data: {
        id,
        name,
        period,
        categories,
        products,
        client_name,
        sales_group_name,
        status
      },
      fetchSalesContract
    } = this.props;

    const { deleteDialogOpen, confirmDialogOpen } = this.state;

    return (
      <>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem' }}
            >
              {t('Basic data')}
            </Typography>
            <TextDataSet label='Name' data={name} />
            {client_name && <TextDataSet label='Client' data={client_name} />}
            {sales_group_name && (
              <TextDataSet label='Shopping group' data={sales_group_name} />
            )}
            <TextDataSet label='Period' data={period} />
            <TextDataSet
              label='Status'
              data={t(clientContractStatuses[status])}
            />

            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem', marginTop: '1rem' }}
            >
              {t('Products included in contract')}
            </Typography>
            {isEmpty(categories) && isEmpty(products) ? (
              <Typography variant='body1'>
                {t('The target includes all products')}
              </Typography>
            ) : (
              <>
                <TextDataSet
                  label='Categories'
                  data={categories.map(d => d.name)}
                />
                <TextDataSet label='Products' data={products.map(p => p.sku)} />
              </>
            )}
          </Box>
          <Box display='flex' alignItems='flex-start'>
            {this.renderCloseContractButton()}
            {status === 0 && (
              <>
                <Authorize permissions={[CLIENTS_CONTRACTS_UPDATE]}>
                  <IconButton
                    className='update-icon'
                    onClick={this.onUpdateClick}
                    icon={<EditIcon />}
                    alt='update'
                  />
                </Authorize>
                <Authorize permissions={[CLIENTS_CONTRACTS_DELETE]}>
                  <IconButton
                    className='delete-icon'
                    onClick={this.onDeleteClick}
                    icon={<DeleteForeverIcon />}
                    alt='delete'
                  />
                </Authorize>
              </>
            )}
          </Box>
        </Box>
        {deleteDialogOpen && (
          <ClientsContractsDeleteDialog
            data={{ id, name }}
            onClose={this.onDialogClose}
            onSuccess={this.onDeleteSuccess}
          />
        )}
        {confirmDialogOpen && (
          <ClientsContractsConfirmDialog
            data={{ id, name, status }}
            onClose={this.onDialogClose}
            onSuccess={fetchSalesContract}
          />
        )}
      </>
    );
  }
}

ClientsContractsDataSets.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    period: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    client_name: PropTypes.string,
    sales_group_name: PropTypes.string,
    status: PropTypes.number.isRequired,
    bonuses_items: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  fetchSalesContract: PropTypes.func.isRequired
};

export default withRouter(ClientsContractsDataSets);
