import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import { Grid, Tab } from '@material-ui/core';
import TabPanel from 'modules/Layout/component/TabPanel';
import Tabs from 'modules/Layout/component/Tabs';
import t from 'translate/translate';
import PriceListsApi from 'api/connections/PriceLists/PriceListsApi';
import { ROUTE_PRICE_LISTS_LIST } from 'routing/routes/PriceLists';
import Loader from 'modules/Layout/component/Loader';
import PriceListBasicData from 'modules/PriceLists/view/Details/BasicData';
import PriceListClients from 'modules/PriceLists/view/Details/Clients';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';

class PriceListsDetailsWrapper extends Component {
  constructor(props) {
    super(props);

    this.priceListId = parseInt(props.match.params.id, 10);

    this.state = {
      priceList: undefined,
      currency: undefined
    };

    this.handleError = this.handleError.bind(this);
    this.fetchPriceList = this.fetchPriceList.bind(this);
    this.onProductAddSuccess = this.onProductAddSuccess.bind(this);
    this.onProductDeleteSuccess = this.onProductDeleteSuccess.bind(this);
    this.onProductChangePriceSuccess = this.onProductChangePriceSuccess.bind(
      this
    );
    this.onDepartmentAddSuccess = this.onDepartmentAddSuccess.bind(this);
    this.onDepartmentDeleteSuccess = this.onDepartmentDeleteSuccess.bind(this);
    this.onClientsAddSuccess = this.onClientsAddSuccess.bind(this);
    this.onClientsDeleteSuccess = this.onClientsDeleteSuccess.bind(this);
  }

  componentDidMount() {
    if (!this.priceListId) {
      this.props.history.push(ROUTE_PRICE_LISTS_LIST);
    }
    this.props.contextMethods.setCurrentPage('Price list details');

    this.fetchPriceList();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_PRICE_LISTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onProductAddSuccess(data) {
    const formattedProduct = {
      id: data.product.id,
      sku: data.product.sku,
      price: data.price
    };

    this.setState(
      state => ({
        priceList: {
          ...state.priceList,
          products: [...state.priceList.products, formattedProduct]
        }
      }),
      () => this.updatePriceLists()
    );
  }

  onProductDeleteSuccess(productId) {
    this.setState(
      state => ({
        priceList: {
          ...state.priceList,
          products: state.priceList.products.filter(
            ({ id }) => id !== productId
          )
        }
      }),
      () => this.updatePriceLists()
    );
  }

  onProductChangePriceSuccess(productId, price) {
    this.setState(
      state => ({
        priceList: {
          ...state.priceList,
          products: state.priceList.products.map(product =>
            product.id === productId ? { ...product, price } : product
          )
        }
      }),
      () => this.updatePriceLists()
    );
  }

  onDepartmentAddSuccess(ids) {
    this.assignItemsToPriceLists('departments', ids);
  }

  onDepartmentDeleteSuccess(id) {
    this.setState(
      state => ({
        priceList: {
          ...state.priceList,
          departments: state.priceList.departments.filter(
            department => department.id !== id
          )
        }
      }),
      () => this.unassignItemsToPriceLists('departments', id)
    );
  }

  onClientsAddSuccess(ids) {
    this.assignItemsToPriceLists('clients', ids);
  }

  onClientsDeleteSuccess(id) {
    this.setState(
      state => ({
        priceList: {
          ...state.priceList,
          clients: state.priceList.clients.filter(client => client.id !== id)
        }
      }),
      () => this.unassignItemsToPriceLists('clients', id)
    );
  }

  async updatePriceLists() {
    try {
      await PriceListsApi.updatePricesList(
        this.priceListId,
        this.state.priceList
      );
    } catch (err) {
      this.handleError(err);
    }
  }

  async assignItemsToPriceLists(name, ids) {
    try {
      await PriceListsApi.assignItemsToPricesList(this.priceListId, {
        [name]: ids
      });

      await this.fetchPriceList();
    } catch (err) {
      this.handleError(err);
    }
  }

  async unassignItemsToPriceLists(name, id) {
    try {
      await PriceListsApi.unassignItemsToPricesList(this.priceListId, {
        [name]: [id]
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchPriceList() {
    try {
      const {
        data: { data: priceList, currency }
      } = await PriceListsApi.getPricesList(this.priceListId);

      this.setState({ priceList, currency });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { priceList, currency } = this.state;

    if (!priceList) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Tabs>
            <Tab label={t('General data')} value='general_data' />
            <Tab label={t('Clients')} value='clients' />
          </Tabs>
          <TabPanel index='general_data'>
            <PriceListBasicData
              priceList={priceList}
              currency={currency}
              handleError={this.handleError}
              setAlert={this.props.contextMethods.setAlert}
              fetchPriceList={this.fetchPriceList}
              onProductAddSuccess={this.onProductAddSuccess}
              onProductDeleteSuccess={this.onProductDeleteSuccess}
              onProductChangePriceSuccess={this.onProductChangePriceSuccess}
              onDepartmentAddSuccess={this.onDepartmentAddSuccess}
              onDepartmentDeleteSuccess={this.onDepartmentDeleteSuccess}
            />
          </TabPanel>
          <TabPanel index='clients'>
            <PriceListClients
              priceList={priceList}
              handleError={this.handleError}
              fetchPriceList={this.fetchPriceList}
              onClientsAddSuccess={this.onClientsAddSuccess}
              onClientsDeleteSuccess={this.onClientsDeleteSuccess}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

PriceListsDetailsWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(PriceListsDetailsWrapper);
