import Filter from 'modules/Layout/component/List/Filter';

class ClientsTargetsReportFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('target_name'),
            label: 'Contract name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('client_name'),
            label: 'Client name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('city'),
            label: 'City',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('with_archival'),
            label: 'Show archive',
            type: 'checkbox'
          }
        ]
      }
    ];
  }
}

export default ClientsTargetsReportFilter;
