import { Component } from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { ROUTE_PROFILE_UPDATE } from 'routing/routes/Profile';
import EditIcon from '@material-ui/icons/Edit';
import Loader from 'modules/Layout/component/Loader';
import IconButton from 'modules/Layout/component/IconButton';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t, { availableLocals } from 'translate/translate';
import PropTypes from 'prop-types';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class Profile extends Component {
  static contextType = AuthContext;

  render() {
    const {
      first_name,
      last_name,
      email,
      waiting_email,
      phone,
      language
    } = this.context.user;

    return (
      <Grid container spacing={3} justify='center'>
        {!email ? (
          <Loader />
        ) : (
          <Grid item xs={12} md={6}>
            <Paper className='p1'>
              <Box display='flex' justifyContent='space-between'>
                <Box>
                  <TextDataSet label='First name' data={first_name} />
                  <TextDataSet label='Last name' data={last_name} />
                  <TextDataSet label='Email' data={email} />
                  {waiting_email && (
                    <TextDataSet
                      label='Email to verify'
                      data={waiting_email}
                      color='error'
                    />
                  )}
                  <TextDataSet label='Phone number' data={phone} />
                  <TextDataSet
                    label='Language'
                    data={t(
                      availableLocals.find(lang => lang.slug === language).name
                    )}
                  />
                </Box>
                <Box display='flex' flexDirection='column'>
                  <IconButton
                    icon={<EditIcon />}
                    onClick={() =>
                      this.props.history.push(ROUTE_PROFILE_UPDATE)
                    }
                    className='update-icon'
                    alt='update'
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
}

Profile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(Profile);
