export default {
  Role: 'Rola',
  'All roles': 'Wszystkie role',
  'Super Admin': 'Super Admin',
  Admin: 'Admin',
  Director: 'Dyrektor',
  'Department Manager': 'Kierownik',
  Client: 'Klient',
  Trader: 'Handlowiec',
  Telemarketer: 'Telemarketer',
  Manager: 'Menadżer',
  'Key account manager': 'Menadżer kluczowych klientów',
  'Product manager': 'Menadżer produktów',
  Password: 'Hasło',
  'Repeat password': 'Powtórz hasło',
  'Log in': 'Zaloguj się',
  'Log out': 'Wyloguj',
  'Forget password?': 'Zapomniałeś hasła?',
  'Go back to login': 'Wróć do logowania',
  'If You would like to change password, insert Your email below':
    'Jeśli chcesz zresetować hasło, wprowadź swój adres email poniżej.',
  'Request for password reset was made successfully, Link to the change password form was sent to the e-mail address provided': `Prośba o reset hasła została zrealizowana, Link z przekierowaniem do formularza został wysłany na podany mail.`,
  'Changing password finish successfully, Try to login now with brand new password': `Zmiana hasła zakończona powodzeniem, Spróbuj się zalogować używająć nowego hasła.`,
  'Fill fields below to change Your password':
    'Wypełnij poniższe pola aby zmienić hasło.',
  'For security reasons, the password must be changed when logging in for the first time. Please enter your new password below': `Ze względów bezpieczeństwa podczas pierwszego logowania należy zmienić hasło. Podaj nowe hasło poniżej.`,
  'Password changed successfully': `Hasło zmienione pomyślnie, możesz korzystać bezpiecznie z systemu.`,
  'Changing password finish successfully, Try to log in now with brand new password.':
    'Zmiana hasła zakończyła się pomyślnie. Spróbuj zalogować się teraz przy użyciu nowego hasła.'
};
