import { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import Checkbox from 'modules/Layout/component/Checkbox';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import t, { availableLocals } from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';

import { ROUTE_USERS_DETAILS, ROUTE_USERS_LIST } from 'routing/routes/Users';
import {
  SUPER_ADMIN,
  PRODUCT_MANAGER,
  ADMIN,
  DIRECTOR,
  TELEMARKETER,
  KEY_ACCOUNT_MANAGER,
  TRADER,
  DEPARTMENT_MANAGER
} from 'api/auth/roles';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import formatRolesOptions from 'modules/Users/utils/getRolesOptions';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import UsersApi from 'api/connections/Users/UsersApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import insertPathParams from 'api/utils/insertPathParams';
import Autocomplete from 'modules/Layout/component/Autocomplete';

export class CreateUserForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.setAlert = props.setAlert;

    this.state = {
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        role: '',
        language: '',
        password: '',
        password_confirmation: '',
        country_id: context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])
          ? ''
          : context.user.country_id,
        assign_users_without_manager: false,
        departments: []
      },
      assignPassword: false,
      departmentsData: [],
      selectedDepartments: [],
      roles: formatRolesOptions(context.user.role),
      loading: false,
      validation: {
        first_name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('First name')
          })
        },
        last_name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Last name')
          })
        },
        email: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Email') })
        },
        phone: {
          status: false,
          message: t('Pole <%= field %> should be in the correct format', {
            field: t('Phone number')
          })
        },
        role: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Role') })
        },
        password: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('password') })
        },
        password_confirmation: {
          status: false,
          message: t('Passwords are not equal')
        },
        country_id: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Country') })
        },
        departments: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Department')
          })
        }
      }
    };

    this.onCountryChange = this.onCountryChange.bind(this);
    this.onAssignPassChange = this.onAssignPassChange.bind(this);
    this.onDepartmentChange = this.onDepartmentChange.bind(this);
    this.onDepartmentManagerChange = this.onDepartmentManagerChange.bind(this);
    this.onRoleChange = this.onRoleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.assignUsersWithoutManagers = this.assignUsersWithoutManagers.bind(
      this
    );
    this.clearDepartmentsError = this.clearDepartmentsError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
      this.fetchDepartments(this.context.user.country_id);
    }
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  handleResponse(res) {
    const { id } = res.data.data;
    this.setAlert({ value: 'Success', variant: COLOR_VARIANTS_SUCCESS });
    this.props.history.push(insertPathParams(ROUTE_USERS_DETAILS, { id }));
  }

  onCountryChange(e) {
    const { name, value } = e.target;

    this.setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          country_id: value
        },
        validation: {
          ...prevState.validation,
          [name]: { ...prevState.validation[name], status: false }
        }
      };
    });

    this.fetchDepartments(e.target.value);
  }

  onAssignPassChange() {
    this.setState(({ assignPassword: prevAssignPassword, formData }) => {
      return {
        assignPassword: !prevAssignPassword,
        formData: {
          ...formData,
          password: '',
          password_confirmation: ''
        }
      };
    });
  }

  onRoleChange(e) {
    const { name, value } = e.target;

    this.setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          role: value,
          departments: [],
          country_id:
            value === PRODUCT_MANAGER ? '' : prevState.formData.country_id
        },
        validation: {
          ...prevState.validation,
          [name]: { ...prevState.validation[name], status: false },
          departments: { ...prevState.validation.departments, status: false }
        }
      };
    });
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value
        },
        validation: {
          ...prevState.validation,
          [name]: { ...prevState.validation[name], status: false }
        }
      };
    });
  }

  onDepartmentChange(res) {
    let arrValues = [];

    if (res) {
      arrValues = _.isArray(res) ? res.map(v => v.key) : [res.key];
    }

    this.setState(prevState => {
      const { departments: prevDepartments } = prevState.formData;

      const filteredPrevDepartments = prevDepartments.filter(
        pD => arrValues.indexOf(pD.id) >= 0
      );

      const formattedArrValues = arrValues.map(v => {
        return { id: v, manager_id: null };
      });

      const newValues = _.differenceWith(
        formattedArrValues,
        filteredPrevDepartments,
        (a, b) => a.id === b.id
      );

      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          departments: [...filteredPrevDepartments, ...newValues]
        },
        validation: {
          ...prevState.validation,
          departments: {
            ...prevState.validation.departments,
            status: false
          }
        }
      };
    });
  }

  onDepartmentManagerChange(e, departmentId) {
    const { value } = e.target;

    this.setState(prevState => {
      const { departments: prevDepartments } = prevState.formData;

      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          departments: prevDepartments.map(pD =>
            pD.id === departmentId ? { id: pD.id, manager_id: value } : pD
          )
        }
      };
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  getAssignUsersWithoutManagerStatuses() {
    const { departments, role } = this.state.formData;
    const { departmentsData } = this.state;

    const result = {
      assignUsersWithoutManagerDisableStatus: false,
      assignUsersWithoutManagerDisableMsg: null
    };

    if (role !== DEPARTMENT_MANAGER) return result;

    if (_.isEmpty(departments)) {
      result.assignUsersWithoutManagerDisableStatus = true;
      result.assignUsersWithoutManagerDisableMsg = 'Select department first';
      return result;
    }

    const { id: departmentId } = departments[0];

    const { has_users_without_manager } = departmentsData.find(
      d => d.id === departmentId
    );

    if (!has_users_without_manager) {
      result.assignUsersWithoutManagerDisableStatus = true;
      result.assignUsersWithoutManagerDisableMsg =
        'All employees in the department are assigned to managers';

      return result;
    }

    return result;
  }

  assignUsersWithoutManagers() {
    this.setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          assign_users_without_manager: !prevState.formData
            .assign_users_without_manager
        }
      };
    });
  }

  async makeApiCall() {
    const { formData } = this.state;
    this.setState({ loading: true });
    try {
      const res = await UsersApi.createUser(formData);
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  runValidation() {
    const { formData, validation, assignPassword } = this.state;

    /* eslint prefer-const: "off" */
    let [isValid, newValidateState] = validate(formData, validation);

    if (assignPassword && formData.password.length <= 0) {
      isValid = false;
      newValidateState.password.status = true;
    } else if (
      assignPassword &&
      formData.password.length &&
      formData.password !== formData.password_confirmation
    ) {
      isValid = false;
      newValidateState.password_confirmation.status = true;
    }
    if (_.isEmpty(formData.departments)) {
      newValidateState.departments = {
        status: true,
        message: t('Field <%= field %> is required', {
          field: t('Selected departments')
        })
      };
    }
    if (!isValid) {
      this.setState(({ validation: prevValidation }) => {
        return { validation: { ...prevValidation, ...newValidateState } };
      });
    }

    return isValid;
  }

  clearDepartmentsError() {
    this.setState(({ validation: prevValidation }) => {
      return {
        ...prevValidation,
        departments: { ...prevValidation.validation.departments, status: false }
      };
    });
  }

  async fetchDepartments(id) {
    try {
      const {
        data: { data: departmentsData }
      } = await DepartmentsApi.getDepartments({
        country_id: id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({ departmentsData });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        first_name,
        last_name,
        email,
        phone,
        role,
        country_id,
        language,
        password,
        password_confirmation,
        departments,
        assign_users_without_manager
      },
      validation,
      roles,
      assignPassword,
      departmentsData,
      loading
    } = this.state;
    const { countries } = this.props;

    const {
      assignUsersWithoutManagerDisableStatus,
      assignUsersWithoutManagerDisableMsg
    } = this.getAssignUsersWithoutManagerStatuses();

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Typography component='h6' variant='h6'>
          {t('Basic data')}
        </Typography>
        <Box className='language-form-box'>
          <Input
            name='first_name'
            label='First name'
            value={first_name}
            required
            autoFocus
            onChange={this.onChange}
            errorStatus={validation.first_name.status}
            errorText={validation.first_name.message}
          />
          <Input
            name='last_name'
            label='Last name'
            value={last_name}
            required
            onChange={this.onChange}
            errorStatus={validation.last_name.status}
            errorText={validation.last_name.message}
          />
          <Input
            name='email'
            label='Email'
            value={email}
            required
            onChange={this.onChange}
            errorStatus={validation.email.status}
            errorText={validation.email.message}
          />
          <Input
            name='phone'
            label='Phone number'
            inputProps={{ maxLength: 14 }}
            value={phone}
            onChange={this.onChange}
            errorStatus={validation.phone.status}
            errorText={validation.phone.message}
          />
          <Select
            name='language'
            label='Language'
            value={language}
            options={formatOptions(availableLocals, 'slug', 'name', true)}
            onChange={this.onChange}
          />
          <Checkbox
            name='auto_generate'
            label={t('Assign password')}
            checked={assignPassword}
            onChange={this.onAssignPassChange}
            useInternalState={false}
          />
          {assignPassword && (
            <>
              <Input
                name='password'
                label='Password'
                type='password'
                value={password}
                onChange={this.onChange}
                errorStatus={validation.password.status}
                errorText={validation.password.message}
              />
              <Input
                name='password_confirmation'
                label='Repeat password'
                type='password'
                value={password_confirmation}
                onChange={this.onChange}
                errorStatus={validation.password_confirmation.status}
                errorText={validation.password_confirmation.message}
              />
            </>
          )}
        </Box>

        <Typography component='h6' variant='h6'>
          {t('User configuration')}
        </Typography>
        <Box className='language-form-box'>
          <Select
            name='role'
            label='Role'
            required
            value={role}
            options={formatOptions(roles, 'slug', 'name', true)}
            onChange={this.onRoleChange}
            errorStatus={validation.role.status}
            errorText={validation.role.message}
          />
          {role && ![SUPER_ADMIN, PRODUCT_MANAGER].includes(role) && (
            <>
              {this.context.hasRole([SUPER_ADMIN]) && (
                <Select
                  name='country_id'
                  label='Country'
                  value={country_id}
                  required={role !== SUPER_ADMIN}
                  options={formatOptions(countries, 'id', 'name')}
                  onChange={this.onCountryChange}
                  errorStatus={validation.country_id.status}
                  errorText={validation.country_id.message}
                />
              )}
              {role !== ADMIN && (
                <Autocomplete
                  name='departments'
                  label='Selected departments'
                  value={departments.map(d => d.id) || null}
                  multiple={[DIRECTOR, TELEMARKETER].includes(role)}
                  options={formatOptions(departmentsData, 'id', 'name')}
                  onChange={(e, res) => this.onDepartmentChange(res)}
                  disabled={!country_id || !role}
                  tooltipMsg='Select role and country first'
                  required={[
                    TRADER,
                    DEPARTMENT_MANAGER,
                    KEY_ACCOUNT_MANAGER
                  ].includes(role)}
                  errorStatus={validation.departments.status}
                  errorText={validation.departments.message}
                />
              )}
              {[TRADER, TELEMARKETER, KEY_ACCOUNT_MANAGER].includes(role) && (
                <>
                  {!_.isEmpty(departments) ? (
                    departments.map(department => {
                      const { managers, name } = departmentsData.find(
                        d => d.id === department.id
                      );

                      const managersOptions = managers.map(manager => {
                        return {
                          id: manager.id,
                          full_name: `${manager.first_name} ${manager.last_name}`
                        };
                      });

                      return (
                        <Box key={`${department.id}-label`}>
                          <Typography variant='subtitle1'>
                            {t(
                              'Choose a supervisor within the department: <%=name%>',
                              { name }
                            )}
                          </Typography>
                          <Select
                            key={department.id}
                            name='selected_manager'
                            label='Department Manager'
                            disabled={_.isEmpty(managersOptions)}
                            tooltipMsg='Department have no manager'
                            value={department.manager_id || ''}
                            emptyValueText="Don't choose a department manager"
                            options={formatOptions(
                              managersOptions,
                              'id',
                              'full_name'
                            )}
                            onChange={e =>
                              this.onDepartmentManagerChange(e, department.id)
                            }
                          />
                        </Box>
                      );
                    })
                  ) : (
                    <>
                      <Typography variant='subtitle1'>
                        {t(
                          'Choose a supervisor within the department: <%=name%>',
                          {
                            name: ''
                          }
                        )}
                      </Typography>
                      <Select
                        name='selected_manager'
                        label='Manager'
                        disabled
                        tooltipMsg='Select department first'
                        value=''
                        options={[]}
                        onChange={() => {}}
                      />
                    </>
                  )}
                </>
              )}
              {role === DEPARTMENT_MANAGER && (
                <Checkbox
                  name='assign_users_without_manager'
                  label={t(
                    'Assign employees who do not have a supervisor in the selected department'
                  )}
                  checked={assign_users_without_manager}
                  onChange={this.assignUsersWithoutManagers}
                  useInternalState={false}
                  disabled={assignUsersWithoutManagerDisableStatus}
                  tooltipMsg={assignUsersWithoutManagerDisableMsg}
                />
              )}
            </>
          )}
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link to={ROUTE_USERS_LIST} className='router-button'>
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

CreateUserForm.defaultProps = {
  countries: []
};

CreateUserForm.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  setAlert: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(CreateUserForm);
