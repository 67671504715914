import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import OrdersApi from 'api/connections/Orders/OrdersApi';
import PromotionsApi from 'api/connections/Promotions/PromotionsApi';
import { ROUTE_ORDERS_LIST, ROUTE_ORDERS_UPDATE } from 'routing/routes/Orders';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';
import { ROUTE_WHOLESALERS_DETAILS } from 'routing/routes/Wholesalers';
import { ROUTE_VISITS_DETAILS } from 'routing/routes/Visits';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Loader from 'modules/Layout/component/Loader';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import t from 'translate/translate';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import ordersStatuses, {
  ORDER_STATUS_PREPARING
} from 'modules/Orders/ordersStatuses';
import { statusesObj } from 'modules/Visits/utils/visitStatuses';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import { isEmpty } from 'lodash/lang';
import OrderDetailsDiscounts from 'modules/Orders/component/OrderDetails/Discounts';
import OrderSummary from 'modules/Orders/component/Summary';
import {
  PRODUCTS_CREATE,
  PRODUCTS_UPDATE
} from 'api/auth/permissions/Products';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import OrderDeleteDialog from 'modules/Orders/component/DeleteDialog';
import Authorize from 'modules/Auth/component/Authorize';
import { ORDERS_DELETE, ORDERS_UPDATE } from 'api/auth/permissions/Orders';
import IconButton from 'modules/Layout/component/IconButton';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import OrderChangeStatusDialog from 'modules/Orders/component/ChangeStatusDialog';
import SettingsIcon from '@material-ui/icons/Settings';
import OrderPromotions from 'modules/Orders/component/Promotions';
import calculateOrderValues from 'modules/Orders/utils/calculations';
import ProductsApi from 'api/connections/Products/ProductsApi';
import OrderDefinedPromotions from 'modules/Orders/component/DefinedPromotions';
import CheckIcon from '@material-ui/icons/Check';
import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';
import PriceListsApi from 'api/connections/PriceLists/PriceListsApi';
import { ROUTE_PRICE_LISTS_DETAILS } from 'routing/routes/PriceLists';
import renderPrice from 'modules/Products/utils/renderPrice';

class OrdersDetails extends Component {
  static contextType = AuthContext;

  static calculateOrderResult(order, priceList) {
    const {
      client_country_id,
      products,
      promotions,
      discounts,
      is_direct,
      payment_discount_id
    } = order;

    return calculateOrderValues({
      products,
      discounts,
      paymentDiscountId: payment_discount_id,
      promotions,
      taxesData: [],
      countryId: client_country_id,
      isDirect: is_direct,
      priceList
    });
  }

  static renderGratisRow(comment) {
    return (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
      >
        <CheckIcon color='primary' fontSize='small' />
        <Box ml={1}>
          <Typography>{comment}</Typography>
        </Box>
      </Box>
    );
  }

  constructor(props) {
    super(props);
    this.orderId = parseInt(props.match.params.id, 10);

    this.state = {
      order: null,
      promotionsData: [],
      deleteDialogStatus: false,
      statusChangeDialogStatus: false,
      calculationResult: {
        totalDiscount: 0,
        totalPromotion: 0,
        netValueBeforeReduction: 0,
        netValue: 0,
        netValueWithDiscount: 0,
        grossValue: 0
      }
    };

    this.setDeleteDisableStatus = this.setDeleteDisableStatus.bind(this);
    this.setUpdateDisableStatus = this.setUpdateDisableStatus.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onDialogSuccess = this.onDialogSuccess.bind(this);
    this.onChangeStatusClick = this.onChangeStatusClick.bind(this);
    this.onChangeStatusSuccess = this.onChangeStatusSuccess.bind(this);
  }

  componentDidMount() {
    if (!this.orderId) {
      this.props.history.push(ROUTE_ORDERS_LIST);
    }

    this.props.contextMethods.setCurrentPage('Order details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_ORDERS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onDeleteClick() {
    this.setState({ deleteDialogStatus: true });
  }

  onUpdateClick() {
    const { id } = this.state.order;

    this.props.history.push(insertPathParams(ROUTE_ORDERS_UPDATE, { id }));
  }

  onChangeStatusClick() {
    this.setState({ statusChangeDialogStatus: true });
  }

  onDialogClose() {
    this.setState({
      deleteDialogStatus: false,
      statusChangeDialogStatus: false
    });
  }

  onDialogSuccess() {
    this.props.history.push(ROUTE_ORDERS_LIST);
  }

  onChangeStatusSuccess(status) {
    this.setState(state => {
      return {
        order: {
          ...state.order,
          status
        }
      };
    });
  }

  getProductsTableCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false,
        render: (sku, { product_id }) => {
          if (
            this.context.hasSomeOfPermission([PRODUCTS_CREATE, PRODUCTS_UPDATE])
          ) {
            return (
              <Link
                to={insertPathParams(ROUTE_PRODUCTS_DETAILS, {
                  id: product_id
                })}
                className='router-link router-link-underline'
              >
                {sku}
              </Link>
            );
          }

          return sku;
        }
      },
      {
        property: 'quantity',
        label: 'Quantity',
        sortable: false
      },
      {
        property: 'price',
        label: 'Price',
        sortable: false,
        render: (_, { price, currency }) => renderPrice(price, currency)
      },
      {
        property: 'id',
        label: 'Summary price',
        sortable: false,
        render: (_, { price, currency, quantity }) =>
          renderPrice(price * quantity, currency)
      },
      {
        property: 'is_free',
        label: 'Gratis',
        sortable: false,
        render: (_, { free_comment }) =>
          OrdersDetails.renderGratisRow(free_comment)
      }
    ];
  }

  setDeleteDisableStatus() {
    const { status, user_id, user_departments } = this.state.order;

    if (status !== ORDER_STATUS_PREPARING) return true;

    const { role, id, departments } = this.context.user;

    if (user_id === id) return false;

    if ([ADMIN, SUPER_ADMIN].includes(role)) return false;

    if (role === DIRECTOR && user_departments) {
      return !departments
        .map(department => department.id)
        .some(d => user_departments.includes(d));
    }

    return true;
  }

  setUpdateDisableStatus() {
    const { status, user_id } = this.state.order;

    if (status !== ORDER_STATUS_PREPARING) return true;

    const { id } = this.context.user;

    if (user_id === id) return false;

    return true;
  }

  async fetchPriceList(id) {
    if (!id) return null;

    try {
      const {
        data: { data: priceList }
      } = await PriceListsApi.getPricesList(id);

      return priceList;
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    try {
      const [
        {
          data: { data: order }
        },
        {
          data: { data: promotionsData }
        },
        {
          data: { data: productsData }
        }
      ] = await Promise.all([
        OrdersApi.getOrder(this.orderId),
        PromotionsApi.getPromotions({
          per_page: Number.MAX_SAFE_INTEGER
        }),
        ProductsApi.getProducts({
          per_page: Number.MAX_SAFE_INTEGER
        })
      ]);

      const { price_list_id } = order;

      const priceList = await this.fetchPriceList(price_list_id);

      const calculationResult = OrdersDetails.calculateOrderResult(
        order,
        priceList
      );

      this.setState({
        order,
        promotionsData,
        productsData,
        calculationResult,
        priceList
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      order,
      promotionsData,
      deleteDialogStatus,
      statusChangeDialogStatus,
      calculationResult,
      productsData,
      priceList
    } = this.state;

    if (!order) return <Loader />;

    const {
      client_id,
      client_name,
      client_street,
      client_postal_code,
      client_city,
      status,
      status_history,
      user_name,
      send_date,
      order_date,
      products,
      promotions,
      discounts,
      payment_method_name,
      payment_date,
      is_direct,
      wholesaler_id,
      wholesaler_agency_name,
      wholesaler_name,
      payment_discount_id,
      payment_discount_name,
      payment_discount_value,
      visit_date,
      visit_id,
      visit_status,
      totalPrice,
      description,
      currency,
      type_name
    } = order;

    const clientAddress = `${client_street}, ${client_postal_code}, ${client_city}`;

    return (
      <>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12}>
            <Grid container spacing={3} justify='center'>
              <Grid item lg={6} xs={12}>
                <Paper className='p1'>
                  <Box display='flex'>
                    <Box width={1}>
                      <TextDataSet
                        data={t(
                          ordersStatuses.find(o => o.status === status).name
                        )}
                        label='Order status'
                      />
                      <Box mb={1}>
                        <Typography variant='body1'>
                          {t('Order prepared by <%=user_name%>', { user_name })}
                        </Typography>
                      </Box>
                      <TextDataSet
                        data={order_date}
                        label='Order prepared at'
                      />
                      {status !== 0 && (
                        <TextDataSet data={send_date} label='Order sended at' />
                      )}
                      <Box mt={2}>
                        <Typography component='h6' variant='h6'>
                          {t('Status history')}
                        </Typography>
                        {!isEmpty(status_history) ? (
                          status_history.map(
                            ({
                              status: historyStatus,
                              user_name: userName,
                              date
                            }) => (
                              <Box
                                boxShadow={3}
                                padding={1}
                                m={1}
                                my={2}
                                key={`${historyStatus}-${date}`}
                              >
                                <TextDataSet
                                  label={t('Status')}
                                  data={t(
                                    ordersStatuses.find(
                                      o => o.status === historyStatus
                                    ).name
                                  )}
                                />
                                <TextDataSet
                                  label={t('Changed by')}
                                  data={userName}
                                />
                                <TextDataSet
                                  label={t('Changed at')}
                                  data={date}
                                />
                              </Box>
                            )
                          )
                        ) : (
                          <Typography>{t('No data')}</Typography>
                        )}
                      </Box>
                    </Box>
                    <Box display='flex' alignItems='flex-start'>
                      <Authorize permissions={[ORDERS_UPDATE]}>
                        <IconButton
                          className='update-icon'
                          onClick={this.onUpdateClick}
                          icon={<EditIcon />}
                          disabled={this.setUpdateDisableStatus()}
                          alt='update'
                          tooltipMsg={t('Order cannot be updated')}
                        />
                        <IconButton
                          className='update-icon'
                          onClick={this.onChangeStatusClick}
                          icon={<SettingsIcon />}
                          disabled={status === ORDER_STATUS_PREPARING}
                          alt='change status'
                          tooltipMsg={t('Status of order cannot be changed')}
                        />
                      </Authorize>
                      <Authorize permissions={[ORDERS_DELETE]}>
                        <IconButton
                          className='delete-icon'
                          onClick={this.onDeleteClick}
                          icon={<DeleteForeverIcon />}
                          disabled={this.setDeleteDisableStatus()}
                          alt='delete'
                          tooltipMsg={t('Order cannot be deleted')}
                        />
                      </Authorize>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Paper className='p1'>
                  <Typography component='h6' variant='h6'>
                    {t('Purchaser')}
                  </Typography>
                  <TextDataSet
                    data={
                      <Link
                        to={insertPathParams(ROUTE_CLIENTS_DETAILS, {
                          id: client_id
                        })}
                        className='router-link router-link-underline'
                        target='_blank'
                      >
                        {client_name}
                      </Link>
                    }
                    label='Client name'
                  />
                  <TextDataSet data={clientAddress} label='Client address' />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className='p1'>
              {priceList && (
                <Box display='flex' mb={2}>
                  <TextDataSet
                    label='Price list used in order'
                    data={
                      <Link
                        to={insertPathParams(ROUTE_PRICE_LISTS_DETAILS, {
                          id: priceList.id
                        })}
                        className='router-link router-link-underline'
                        target='_blank'
                      >
                        <Typography>{priceList.name}</Typography>
                      </Link>
                    }
                  />
                </Box>
              )}
              <Table cols={this.getProductsTableCols()} rows={products} dense />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Typography component='h6' variant='h6'>
                {t('Discounts and promotions')}
              </Typography>
              <Box display='flex'>
                <Box width={1} mr={1}>
                  <OrderDetailsDiscounts
                    discounts={discounts}
                    netValue={calculationResult.totalNetValueBeforeDiscount}
                    status={status}
                    paymentDiscountId={payment_discount_id}
                    paymentDiscountValue={payment_discount_value}
                    paymentDiscountName={payment_discount_name}
                  />
                </Box>
                <Box width={1}>
                  <OrderPromotions
                    isDetails
                    clientId={client_id}
                    promotions={promotions}
                    promotionsData={promotionsData}
                    products={productsData}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className='p1'>
              <OrderDefinedPromotions
                isDetails
                promotions={promotions}
                products={products}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Typography component='h6' variant='h6'>
                {t('Summary')}
              </Typography>
              <Box width={1} display='flex'>
                <Box display='flex' flexDirection='column' width={1}>
                  <Box width={1}>
                    <TextDataSet
                      data={
                        is_direct
                          ? `${t('Direct')} - ${type_name}`
                          : t('Wholesaler')
                      }
                      label='Order type'
                    />
                  </Box>
                  <Box width={1}>
                    {!is_direct && (
                      <>
                        <TextDataSet
                          data={
                            <Link
                              to={insertPathParams(ROUTE_WHOLESALERS_DETAILS, {
                                id: wholesaler_id
                              })}
                              className='router-link router-link-underline'
                            >
                              <Typography variant='subtitle1'>
                                {wholesaler_name}
                              </Typography>
                            </Link>
                          }
                          label='Wholesaler'
                        />
                        <TextDataSet
                          data={wholesaler_agency_name}
                          label='Agency'
                        />
                      </>
                    )}
                  </Box>
                </Box>
                <Box display='flex' flexDirection='column' width={1}>
                  <Box width={1}>
                    <TextDataSet
                      data={payment_method_name}
                      label='Payment method'
                    />
                  </Box>
                  <Box width={1}>
                    <TextDataSet data={payment_date} label='Payment date' />
                  </Box>
                </Box>
              </Box>
              <Box width={1}>
                <TextDataSet
                  data={
                    visit_id ? (
                      <Link
                        to={insertPathParams(ROUTE_VISITS_DETAILS, {
                          id: visit_id
                        })}
                        className='router-link router-link-underline'
                      >
                        <Typography variant='subtitle1'>
                          {`${visit_date} - ${t(statusesObj[visit_status])}`}
                        </Typography>
                      </Link>
                    ) : null
                  }
                  label='Visit'
                />
              </Box>
              <Box width={1}>
                <TextDataSet data={description} label='Description' />
              </Box>
              <OrderSummary
                isDetails
                currency={currency}
                totalPrice={totalPrice}
                orderDate={order_date}
                calculationResult={calculationResult}
              />
            </Paper>
          </Grid>
        </Grid>
        {deleteDialogStatus && (
          <OrderDeleteDialog
            order={order}
            onClose={this.onDialogClose}
            onSuccess={this.onDialogSuccess}
          />
        )}
        {statusChangeDialogStatus && (
          <OrderChangeStatusDialog
            order={order}
            onClose={this.onDialogClose}
            onSuccess={this.onChangeStatusSuccess}
          />
        )}
      </>
    );
  }
}

OrdersDetails.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(OrdersDetails);
