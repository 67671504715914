import ListIcon from '@material-ui/icons/List';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { ROUTE_DISCOUNTS_LIST, permMap } from 'routing/routes/Discounts';
import {
  ROUTE_PROMOTIONS_LIST,
  permMap as promotionsPerMap
} from 'routing/routes/Promotions';

const menu = [
  {
    key: 'menu-discounts',
    label: 'Discounts and promotions',
    icon: <LocalOfferIcon />,
    path: ROUTE_DISCOUNTS_LIST,
    anyPermissions: permMap[ROUTE_DISCOUNTS_LIST],
    children: [
      {
        key: 'menu-discounts-list',
        label: 'Discounts',
        icon: <ListIcon />,
        path: ROUTE_DISCOUNTS_LIST,
        permissions: permMap[ROUTE_DISCOUNTS_LIST]
      },
      {
        key: 'menu-promotions-list',
        label: 'Promotions',
        icon: <ListIcon />,
        path: ROUTE_PROMOTIONS_LIST,
        permissions: promotionsPerMap[ROUTE_PROMOTIONS_LIST]
      }
    ]
  }
];

export default menu;
