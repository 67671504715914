import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  table: {
    [theme.breakpoints.up('md')]: {
      tableLayout: 'fixed'
    }
  },
  cell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  disableCell: {
    opacity: 0.4
  },
  cellHeader: {
    fontWeight: '600'
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.main,
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.primary.main
    },
    '& .MuiTableCell-body': {
      color: theme.palette.common.white
    }
  }
}));
