import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class SalesGroupsFilesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.salesGroupsFilesPath = 'sales/groups/:id/files';
    this.salesGroupsFilePath = 'sales/groups/:groupId/files/:fileId';
    this.exportSalesGroupsPath = 'sales/groups/export';
  }

  getSalesGroupFiles(id, pathParams) {
    return this.get(
      insertPathParams(this.salesGroupsFilesPath, { id }),
      pathParams
    );
  }

  getSalesGroupFile(ids) {
    const options = {
      responseType: 'blob'
    };
    return this.get(
      insertPathParams(this.salesGroupsFilePath, ids),
      {},
      options
    );
  }

  createSalesGroupFile(id, payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return this.post(
      insertPathParams(this.salesGroupsFilesPath, { id }),
      payload,
      options
    );
  }

  updateSalesGroupFile(ids, payload) {
    return this.put(insertPathParams(this.salesGroupsFilePath, ids), payload);
  }

  deleteSalesGroupFile(ids) {
    return this.delete(insertPathParams(this.salesGroupsFilePath, ids));
  }

  exportSalesGroups(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportSalesGroupsPath, pathParams, options);
  }
}

export default new SalesGroupsFilesApi();
