import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const SalesResultsLegend = ({ nameSuffix }) => {
  return (
    <Box
      className='legend-container'
      id={`legend-container-${nameSuffix}`}
      display='flex'
      justifyContent='center'
    />
  );
};

SalesResultsLegend.propTypes = {
  nameSuffix: PropTypes.number.isRequired
};

export default SalesResultsLegend;
