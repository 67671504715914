import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import WholesalersMappingsApi from 'api/connections/Wholesalers/WholesalersMappingsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const ProductWholesalersMappingsDialog = props => {
  const {
    mapping: {
      id: mappingId,
      wholesaler_id: wholesalerId,
      wholesaler_name: wholesalerName
    },
    productSku,
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteMapping = async () => {
    try {
      await WholesalersMappingsApi.deleteWholesalerMapping({
        wholesalerId,
        mappingId
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess(mappingId);
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete mapping for product <%=productSku%> and wholesaler <%=wholesalerName%>',
        {
          productSku,
          wholesalerName
        }
      )}
      onApprove={deleteMapping}
      onCancel={onClose}
    />
  );
};

ProductWholesalersMappingsDialog.propTypes = {
  mapping: PropTypes.shape({
    id: PropTypes.number.isRequired,
    wholesaler_id: PropTypes.number.isRequired,
    wholesaler_name: PropTypes.string.isRequired
  }).isRequired,
  productSku: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ProductWholesalersMappingsDialog;
