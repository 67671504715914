import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ClientsBonusesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.bonusesPath = 'targets/clients/bonuses';
    this.bonusPath = 'targets/clients/bonuses/:id';
    this.changeBonusValuePath = 'targets/clients/bonuses/:id/value';
    this.confirmBonusPath = 'targets/clients/bonuses/:id/confirm';
    this.paidBonusPath = 'targets/clients/bonuses/:id/paid';
    this.suspendBonusPath = 'targets/clients/bonuses/:id/suspend';
    this.unsuspendBonusPath = 'targets/clients/bonuses/:id/undo-suspension';
  }

  getBonuses(pathParams) {
    return this.get(this.bonusesPath, pathParams);
  }

  getBonus(id) {
    return this.get(insertPathParams(this.bonusPath, { id }));
  }

  updateBonusValue(id, payload) {
    return this.put(
      insertPathParams(this.changeBonusValuePath, { id }),
      payload
    );
  }

  confirmTargetBonus(id) {
    return this.put(insertPathParams(this.confirmBonusPath, { id }));
  }

  setPaidStatus(id) {
    return this.put(insertPathParams(this.paidBonusPath, { id }));
  }

  suspendBonus(id, payload) {
    return this.put(insertPathParams(this.suspendBonusPath, { id }), payload);
  }

  unsuspendBonus(id) {
    return this.put(insertPathParams(this.unsuspendBonusPath, { id }));
  }
}

export default new ClientsBonusesApi();
