import { Component } from 'react';
import PropTypes from 'prop-types';
import ProductsTable from 'modules/Products/component/List/Table';
import ProductsFilter from 'modules/Products/component/List/Filter';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ProductsApi from 'api/connections/Products/ProductsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import TaxesApi from 'api/connections/Taxes/TaxesApi';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { Box } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { PRODUCTS_CREATE } from 'api/auth/permissions/Products';
import { ROUTE_PRODUCTS_CREATE } from 'routing/routes/Products';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { ADMIN, SUPER_ADMIN } from 'api/auth/roles';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { downloadFile } from 'modules/Shared/utils/file';
import ExportProductsDialog from 'modules/Products/component/Dialog/ExportProducts';
import { isArray } from 'lodash/lang';
import debounce from 'lodash/debounce';

class ProductsList extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      filter: {
        my_products: !context.hasRole([SUPER_ADMIN, ADMIN]),
        sku: null,
        group_id: null,
        category_id: null,
        subcategory_id: null,
        batches_min: '',
        batches_max: '',
        direct_order_only: false,
        country_id: null,
        promotions_blocked: false
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      products: [],
      filterProducts: [],
      countries: [],
      categoriesObj: {
        productGroups: [],
        categories: [],
        subCategories: []
      },
      taxes: [],
      loading: true,
      loadingFilterProducts: false,
      dialog: {
        showExportDialog: false
      }
    };

    this.fetchProductsDebounce = debounce(this.fetchProducts, 500);

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onCategoryPick = this.onCategoryPick.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.fetchProducts = this.fetchProducts.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportProducts = this.exportProducts.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.showExportProductsDialog = this.showExportProductsDialog.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Products');
    this.fetchData();
    this.fetchFilterProducts();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onDialogClose() {
    this.setState({
      dialog: {
        showExportDialog: false
      }
    });
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onFilterChange(event);
  }

  onCategoryPick(name, value) {
    this.setState(
      state => {
        let { group_id, category_id, subcategory_id } = state.filter;

        if (name === 'group') {
          group_id = value?.key;
          category_id = null;
          subcategory_id = null;
        }
        if (name === 'category') {
          category_id = value?.key;
          subcategory_id = null;
        }
        if (name === 'subcategory') subcategory_id = value?.key;

        return {
          ...state,
          filter: {
            ...state.filter,
            group_id,
            category_id,
            subcategory_id,
            sku: null
          },
          validation: {
            ...state.validation,
            sku: {
              ...state.validation?.sku,
              status: false
            }
          }
        };
      },
      () => {
        this.fetchProducts();
        this.fetchFilterProducts();
      }
    );
  }

  onFilterChange(e) {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState(
      state => {
        return {
          ...state,
          filter: {
            ...state.filter,
            [name]: value
          },
          pagination: { ...state.pagination, page: 1 }
        };
      },
      () => this.fetchProductsDebounce()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination }
      },
      () => this.fetchProducts()
    );
  }

  setSort(sort) {
    this.setState({ sort: { ...this.state.sort, ...sort } }, () =>
      this.fetchProducts()
    );
  }

  async fetchFilterProducts() {
    const { group_id, category_id, subcategory_id } = this.state.filter;

    try {
      this.setState({ loadingFilterProducts: true });

      let res;

      if (group_id || category_id || subcategory_id) {
        res = await ProductsApi.getProducts({
          per_page: Number.MAX_SAFE_INTEGER,
          group_id,
          category_id,
          subcategory_id
        });
      } else {
        res = await ProductsApi.getAllProducts();
      }

      this.setState({
        filterProducts: res.data.data,
        loadingFilterProducts: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchProducts() {
    const { sort, filter } = this.state;
    const { per_page, page } = this.state.pagination;

    try {
      this.setState({ loading: true });

      const {
        data: { data: products, meta }
      } = await ProductsApi.getProducts({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        products,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    try {
      let countries = [];
      const [
        {
          data: { data: products, meta }
        },
        { data: categories },
        {
          data: { data: taxes }
        }
      ] = await Promise.all([
        ProductsApi.getProducts({
          ...this.state.sort,
          ...this.state.filter,
          per_page: this.state.pagination.per_page,
          page: this.state.pagination.page
        }),
        ProductCategoriesApi.getProductCategories(),
        TaxesApi.getTaxes()
      ]);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        products,
        countries,
        taxes,
        categoriesObj: prepareCategories(categories),
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportProducts(country_id) {
    try {
      this.setState({
        dialog: {
          showExportDialog: false
        }
      });
      const { filter, sort } = this.state;

      const res = await ProductsApi.exportProducts({
        ...filter,
        ...sort,
        export_for_country_id: country_id
      });

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  showExportProductsDialog() {
    this.setState({
      dialog: {
        showExportDialog: true
      }
    });
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          <Authorize permissions={[PRODUCTS_CREATE]}>
            <IconButton
              icon={<AddIcon fontSize='large' />}
              onClick={() => this.props.history.push(ROUTE_PRODUCTS_CREATE)}
              className='create-icon'
              alt='create'
            />
          </Authorize>
          <IconButton
            onClick={this.showExportProductsDialog}
            icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
            alt='export table'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const {
      filter,
      categoriesObj,
      sort,
      products,
      filterProducts,
      countries,
      taxes,
      loading,
      loadingFilterProducts,
      dialog: { showExportDialog }
    } = this.state;

    return (
      <div>
        <ProductsFilter
          filter={filter}
          validation={this.state.filterValidation}
          onFilterChange={this.onFilterChange}
          onAutocompleteChange={this.onAutocompleteChange}
          onCategoryPick={this.onCategoryPick}
          categoriesObj={categoriesObj}
          countries={countries}
          filterProducts={filterProducts}
          loadingFilterProducts={loadingFilterProducts}
        />
        {this.renderActions()}
        <ProductsTable
          sort={sort}
          setSort={this.setSort}
          products={products}
          countries={countries}
          taxes={taxes}
          fetchProducts={this.fetchProducts}
          loading={loading}
        />
        {this.renderActions()}
        {showExportDialog && (
          <ExportProductsDialog
            onClose={this.onDialogClose}
            onSuccess={country_id => this.exportProducts(country_id)}
            countries={countries}
          />
        )}
      </div>
    );
  }
}

ProductsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(ProductsList);
