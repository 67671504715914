import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_CURRENCIES_DETAILS } from 'routing/routes/Currencies';
import { CURRENCIES_MANAGEMENT } from 'api/auth/permissions/Currencies';
import CurrenciesApi from 'api/connections/Currencies/CurrenciesApi';
import CurrenciesTable from 'modules/Currencies/component/List/Table';
import CurrenciesFilter from 'modules/Currencies/component/List/Filter';
import CurrencyFormModal from 'modules/Currencies/component/Modals/Form';
import CurrencyDeleteModal from 'modules/Currencies/component/Modals/Delete';
import { withRouter } from 'react-router-dom';

class CurrenciesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      filterValidation: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      currencies: [],
      loading: true,
      actionModals: {
        modifyCurrency: null,
        formModalStatus: false,
        deleteModalStatus: false
      }
    };

    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchCurrencies = this.fetchCurrencies.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Currencies');
    this.fetchCurrencies();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onDetailsClick({ id, name }) {
    const path = `${insertPathParams(ROUTE_CURRENCIES_DETAILS, {
      id
    })}?currency_name=${name}`;

    return this.props.history.push(path);
  }

  onCreateClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true
      }
    }));
  }

  onUpdateClick(data) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        modifyCurrency: data,
        formModalStatus: true
      }
    }));
  }

  onDeleteClick(modifyCurrency) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        deleteModalStatus: true,
        modifyCurrency
      }
    }));
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchCurrencies()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchCurrencies()
    );
  }

  async fetchCurrencies() {
    const {
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: currencies, meta }
      } = await CurrenciesApi.getCurrencies({
        ...filter,
        per_page,
        page
      });

      this.setState({
        currencies,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  closeAllModals() {
    this.setState({
      actionModals: {
        modifyCurrency: null,
        updateModalStatus: false,
        deleteModalStatus: false
      }
    });
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Authorize permissions={[CURRENCIES_MANAGEMENT]}>
            <IconButton
              className='create-icon'
              onClick={this.onCreateClick}
              icon={<AddIcon fontSize='large' />}
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const {
      currencies,
      filter,
      loading,
      filterValidation,
      actionModals: { modifyCurrency, formModalStatus, deleteModalStatus }
    } = this.state;

    return (
      <div>
        <CurrenciesFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderActions()}
        <CurrenciesTable
          currencies={currencies}
          loading={loading}
          onDetailsClick={this.onDetailsClick}
          onUpdateClick={this.onUpdateClick}
          onDeleteClick={this.onDeleteClick}
        />
        {this.renderActions()}
        {formModalStatus && (
          <CurrencyFormModal
            currency={modifyCurrency}
            setAlert={this.props.contextMethods.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchCurrencies}
          />
        )}
        {deleteModalStatus && (
          <CurrencyDeleteModal
            currency={modifyCurrency}
            handleError={this.handleError}
            setAlert={this.props.contextMethods.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchCurrencies}
          />
        )}
      </div>
    );
  }
}

CurrenciesList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(CurrenciesList);
