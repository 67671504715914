import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Tabs as MaterialUiTabs } from '@material-ui/core';
import getPathQuery from 'api/utils/getPathQuery';

const useStyles = {
  root: {
    minHeight: 'unset'
  },
  indicator: {
    display: 'none'
  }
};

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.initTabIndex = props.children[0].props.value;
    this.tabIndex = getPathQuery(props.location.search, 'tab_index');

    this.state = {
      index: this.tabIndex || this.initTabIndex
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!this.tabIndex) {
      const { history, location } = this.props;

      history.replace({
        ...location,
        search: `?tab_index=${this.initTabIndex}`
      });
    }
  }

  onChange(e, index) {
    const { history, location } = this.props;

    history.replace({ ...location, search: `?tab_index=${index}` });

    this.setState({ index });
  }

  render() {
    const { classes, children } = this.props;
    const { index } = this.state;

    return (
      <MaterialUiTabs
        value={index}
        centered
        onChange={this.onChange}
        classes={{ root: classes.root, indicator: classes.indicator }}
      >
        {children}
      </MaterialUiTabs>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])
      .isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    indicator: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(useStyles)(withRouter(Tabs));
