import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import importStatuses from 'modules/Imports/importStatuses';
import t from 'translate/translate';

class ImportsTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'status',
        label: 'Status',
        sortable: false,
        render: status => t(importStatuses[status])
      },
      {
        property: 'user_name',
        label: 'User name',
        sortable: false
      },
      {
        property: 'start_date',
        label: 'Start date',
        sortable: false
      },
      {
        property: 'finish_date',
        label: 'End date',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          download: {
            status: true,
            disable: ({ status }) => ![2, 3, 4].includes(status),
            disableMsg: 'import have wrong status',
            onClick: this.props.onDownloadClick
          }
        }
      }
    ];
  }

  render() {
    const { data, loading } = this.props;

    return (
      <Table cols={this.getCols()} rows={data} loading={loading} actions />
    );
  }
}

ImportsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  onDownloadClick: PropTypes.func.isRequired
};

export default ImportsTable;
