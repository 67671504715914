import { Component } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import t from 'translate/translate';
import { Box, Grid } from '@material-ui/core';
import Table from 'modules/Layout/component/List/Table';
import Pagination from 'modules/Layout/component/List/Pagination';

const getCols = () => [
  {
    property: 'name',
    label: 'Name',
    sortable: false
  },
  {
    property: 'city',
    label: 'City',
    sortable: false
  },
  {
    property: 'street',
    label: 'Street',
    sortable: false
  }
];

class SelectClientDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: props.clientId,
      name: '',
      city: '',
      street: '',
      pagination: {
        per_page: 5,
        page: 1,
        total: props.clients.length
      }
    };

    this.onSelect = this.onSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setPagination = this.setPagination.bind(this);
  }

  onChange(e) {
    const { value, name } = e.target;

    this.setState({
      [name]: value,
      pagination: { ...this.state.pagination, page: 1 },
      clientId: null
    });
  }

  onSelect(client) {
    this.setState({ clientId: client });
  }

  setPagination(pagination) {
    this.setState({
      pagination: { ...this.state.pagination, ...pagination },
      clientId: null
    });
  }

  getClientsToRender() {
    const { clients } = this.props;
    const { name, city, street } = this.state;
    const { per_page, page } = this.state.pagination;

    const filteredClients = clients
      .filter(client =>
        client.name.toLowerCase().trim().includes(name.toLowerCase().trim())
      )
      .filter(
        client =>
          !client.city ||
          client.city.toLowerCase().trim().includes(city.toLowerCase().trim())
      )
      .filter(
        client =>
          !client.street ||
          client.street
            ?.toLowerCase()
            .trim()
            .includes(street.toLowerCase().trim())
      );

    const paginationFrom = (page - 1) * per_page;
    const paginationTo = paginationFrom + per_page;

    return filteredClients.slice(paginationFrom, paginationTo);
  }

  render() {
    const { clientId, name, city, street, pagination } = this.state;

    return (
      <Dialog
        open
        onClose={this.props.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='md'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Select client')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Input
                onChange={this.onChange}
                label='Name'
                name='name'
                value={name}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                onChange={this.onChange}
                label='City'
                name='city'
                value={city}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                onChange={this.onChange}
                label='Street'
                name='street'
                value={street}
              />
            </Grid>
          </Grid>
          <Table
            cols={getCols()}
            rows={this.getClientsToRender()}
            hover
            dense
            onSelect={this.onSelect}
            selected={[clientId]}
          />
          <Box display='flex' flexDirection='row-reverse'>
            <Pagination
              pagination={pagination}
              setPagination={this.setPagination}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type='button'
            text={t('Close')}
            onClick={this.props.onClose}
          />
          <Button
            type='button'
            color='primary'
            text={t('Save')}
            onClick={() => this.props.onSuccess(clientId)}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

SelectClientDialog.defaultProps = {
  clientId: null,
  clients: []
};

SelectClientDialog.propTypes = {
  clientId: PropTypes.number,
  clients: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SelectClientDialog;
