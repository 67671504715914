import { Route } from 'react-router-dom';
import { TAXES_LIST } from 'api/auth/permissions/Taxes';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import TaxesList from 'modules/Taxes/view/List';

export const ROUTE_TAXES_LIST = '/taxes';

export const permMap = {
  [ROUTE_TAXES_LIST]: [TAXES_LIST]
};

const routes = {
  protected: [
    <Route key='taxes-list' exact path={ROUTE_TAXES_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_TAXES_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <TaxesList contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
