import Filter from 'modules/Layout/component/List/Filter';
import { SUPER_ADMIN } from 'api/auth/roles';
import { formatOptions } from 'modules/Layout/component/Select';

class SalesFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'select',
            displayStatus: this.context.hasRole([SUPER_ADMIN]),
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name')
          }
        ]
      },
      {
        groupLabel: 'Discount',
        children: [
          {
            ...this.getBasicInputValues('has_discounts'),
            label: 'With a discount',
            type: 'checkbox'
          },
          {
            ...this.getBasicInputValues('has_no_discounts'),
            label: 'Without a discount',
            type: 'checkbox'
          }
        ]
      }
    ];
  }
}

export default SalesFilter;
