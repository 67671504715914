import { DIRECTOR } from 'api/auth/roles';

export default function (departments, loggedUser) {
  if (loggedUser.role === DIRECTOR) {
    const directorDepartmentsIds = loggedUser.departments.map(d => d.id);

    return departments.filter(department => {
      return directorDepartmentsIds.includes(department.id);
    });
  }

  return departments;
}
