import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import { isEmpty } from 'lodash';
import t from 'translate/translate';
import AddIcon from '@material-ui/icons/Add';
import Authorize from 'modules/Auth/component/Authorize';
import { useStyles } from 'modules/PostalCodes/component/shared/style';
import { POSTAL_CODES_COUNTRIES_CREATE } from 'api/auth/permissions/PostalCodes';

const PostalCodesBox = ({
  title,
  authorizeAddButton,
  data,
  addFn,
  children
}) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <Typography variant='h5' component='h5'>
        {t(title)}
      </Typography>
      <Box className={classes.column}>
        {authorizeAddButton ? (
          <Authorize
            key='postal_codes_countries'
            permissions={[POSTAL_CODES_COUNTRIES_CREATE]}
          >
            <IconButton
              icon={<AddIcon fontSize='large' />}
              onClick={addFn}
              className='create-icon'
              alt='create'
            />
          </Authorize>
        ) : (
          <IconButton
            icon={<AddIcon fontSize='large' />}
            onClick={addFn}
            className='create-icon'
            alt='create'
          />
        )}
        {!isEmpty(data) ? (
          <>{children}</>
        ) : (
          <Typography variant='subtitle1'>{t('No data')}</Typography>
        )}
      </Box>
    </div>
  );
};

PostalCodesBox.propTypes = {
  title: PropTypes.string.isRequired,
  authorizeAddButton: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  addFn: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

PostalCodesBox.defaultProps = {
  authorizeAddButton: false
};

export default PostalCodesBox;
