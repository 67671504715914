import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from 'modules/Layout/component/Checkbox';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { Box, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import SalesImportsApi from 'api/connections/SalesImports/SalesImportsApi';
import ProductsApi from 'api/connections/Products/ProductsApi';
import CategoryPicker from 'modules/Layout/component/CategoryPicker';

class MapProductDialog extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.onSuccess = props.onSuccess;
    this.onClose = props.onClose;

    this.state = {
      formData: {
        product_id: '',
        use_same: false
      },
      validation: {
        product_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Product')
          })
        }
      },
      loading: false,
      products: [],
      groupId: null,
      categoryId: null,
      subcategoryId: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCategoryPick = this.onCategoryPick.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.onSuccess();
    this.onClose();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, type } = e.target;
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onCategoryPick(name, value) {
    this.setState(
      state => {
        let { groupId, categoryId, subcategoryId } = state;

        if (name === 'group') groupId = value?.key;
        if (name === 'category') categoryId = value?.key;
        if (name === 'subcategory') subcategoryId = value?.key;

        return {
          ...state,
          groupId,
          categoryId,
          subcategoryId,
          validation: {
            product_id: {
              ...state.validation.product_id,
              status: false
            }
          }
        };
      },
      () => this.fetchProducts()
    );
  }

  runValidation() {
    const { formData, productSubCategoryId, validation } = this.state;
    const [isValid, newValidateState] = validate(
      { ...formData, productSubCategoryId },
      validation
    );

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async fetchProducts() {
    const { subcategoryId: subcategory_id } = this.state;

    if (subcategory_id) {
      try {
        const {
          data: { data: products }
        } = await ProductsApi.getProducts({ subcategory_id });

        this.setState({ products });
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  async makeApiCall() {
    const { formData } = this.state;
    const {
      importId,
      temporaryProduct: { id: tempId }
    } = this.props;

    this.setState({ loading: true });
    try {
      await SalesImportsApi.mapProduct({ importId, tempId }, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { product_id, use_same },
      validation,
      loading,
      products,
      groupId,
      categoryId,
      subcategoryId
    } = this.state;

    const {
      temporaryProduct: {
        wholesaler_product_code,
        wholesaler_product_name,
        error_type
      }
    } = this.props;

    return (
      <Dialog
        open
        onClose={this.onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Map temporary product')}</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <TextDataSet
              label='Wholesaler product code'
              data={wholesaler_product_code}
            />
            <TextDataSet
              label='Wholesaler product name'
              data={wholesaler_product_name}
            />
            <TextDataSet label='Error type' data={t(error_type)} />
          </Box>
          <form id='map-product-form' noValidate onSubmit={this.onSubmit}>
            <Typography component='h6' variant='h6'>
              {t('Category')}
            </Typography>
            <CategoryPicker
              onChange={this.onCategoryPick}
              groupId={groupId}
              categoryId={categoryId}
              subcategoryId={subcategoryId}
            />
            <Select
              name='product_id'
              label='Product'
              value={product_id}
              required
              options={formatOptions(products, 'id', 'sku')}
              onChange={this.onChange}
              errorStatus={validation.product_id.status}
              errorText={validation.product_id.message}
              disabled={!subcategoryId}
              tooltipMsg='Select product subcategory first'
            />
            <Box mt={2}>
              <Checkbox
                onChange={this.onChange}
                checked={use_same}
                name='use_same'
                label={
                  error_type === 'wrong name'
                    ? 'Use the product in identical cases in this import'
                    : 'Save and use mapping in identical cases in all imports'
                }
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.onClose} />
          <Button
            form='map-product-form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

MapProductDialog.propTypes = {
  importId: PropTypes.number.isRequired,
  temporaryProduct: PropTypes.shape({
    id: PropTypes.number.isRequired,
    wholesaler_product_code: PropTypes.string.isRequired,
    wholesaler_product_name: PropTypes.string.isRequired,
    error_type: PropTypes.string.isRequired
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MapProductDialog;
