import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import t from 'translate/translate';
import clsx from 'clsx';
import Input from 'modules/Layout/component/Input';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

class ThresholdsDiscounts extends Component {
  static sortArray(arr) {
    return arr.sort((a, b) => (a.threshold < b.threshold ? -1 : 0));
  }

  static validate(threshold) {
    const { threshold: thresholdValue, percentage } = threshold;

    const isThresholdValid = Boolean(thresholdValue && thresholdValue >= 0);
    const isPercentageValid = Boolean(
      percentage && percentage >= 0 && percentage <= 100
    );

    return [isThresholdValid, isPercentageValid];
  }

  constructor(props) {
    super(props);

    const { thresholds } = props;

    this.state = {
      thresholds,
      modifyThreshold: null
    };

    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onModifyThresholdChange = this.onModifyThresholdChange.bind(this);
    this.onModifyThresholdSave = this.onModifyThresholdSave.bind(this);
    this.renderThreshold = this.renderThreshold.bind(this);
  }

  onCreateClick() {
    this.setState({
      modifyThreshold: {
        index: 'new',
        threshold: '',
        percentage: '',
        isThresholdValid: true,
        isPercentageValid: true
      }
    });
  }

  onUpdateClick(index, threshold) {
    this.setState({
      modifyThreshold: {
        index,
        ...threshold,
        isThresholdValid: true,
        isPercentageValid: true
      }
    });
  }

  onDeleteClick(index) {
    this.setState(
      state => ({
        thresholds: state.thresholds.filter((el, i) => i !== index)
      }),
      () => this.props.onChange(this.state.thresholds)
    );
  }

  onChange(e) {
    this.setState(
      state => {
        const { thresholds } = state;
        const { name, value } = e.target;

        const [slug, index] = name.split('-');
        const parsedIndex = parseInt(index, 10);

        return {
          thresholds: ThresholdsDiscounts.sortArray(
            thresholds.map((threshold, i) => {
              return i === parsedIndex
                ? { ...threshold, [slug]: parseInt(value, 10) }
                : threshold;
            })
          )
        };
      },
      () => this.props.onChange(this.state.thresholds)
    );
  }

  onModifyThresholdChange(e) {
    this.setState(state => {
      const { name, value } = e.target;
      const [slug] = name.split('-');

      return {
        modifyThreshold: {
          ...state.modifyThreshold,
          [slug]: value,
          isThresholdValid:
            slug === 'threshold'
              ? true
              : state.modifyThreshold.isThresholdValid,
          isPercentageValid:
            slug === 'percentage'
              ? true
              : state.modifyThreshold.isPercentageValid
        }
      };
    });
  }

  onModifyThresholdSave() {
    this.setState(
      state => {
        const { thresholds, modifyThreshold } = state;

        const [
          isThresholdValid,
          isPercentageValid
        ] = ThresholdsDiscounts.validate(modifyThreshold);

        if (!(isThresholdValid && isPercentageValid)) {
          return {
            modifyThreshold: {
              ...modifyThreshold,
              isThresholdValid,
              isPercentageValid
            }
          };
        }

        const newState = {
          modifyThreshold: null
        };

        const {
          index: modifyThresholdIndex,
          threshold: modifyThresholdValue,
          percentage
        } = modifyThreshold;

        if (modifyThresholdIndex === 'new') {
          newState.thresholds = ThresholdsDiscounts.sortArray([
            ...thresholds,
            { threshold: modifyThresholdValue, percentage }
          ]);
        } else {
          newState.thresholds = ThresholdsDiscounts.sortArray(
            thresholds.map((threshold, index) =>
              index === modifyThresholdIndex
                ? { threshold: modifyThresholdValue, percentage }
                : threshold
            )
          );
        }

        return newState;
      },
      () => this.props.onChange(this.state.thresholds)
    );
  }

  renderThreshold(threshold, index) {
    const { threshold: thresholdValue, percentage } = threshold;
    const modifyThresholdIndex = this.state.modifyThreshold?.index;
    const modifyThresholdValue = this.state.modifyThreshold?.threshold;
    const isThresholdValid = this.state.modifyThreshold?.isThresholdValid;
    const isPercentageValid = this.state.modifyThreshold?.isPercentageValid;

    const isModify = index === 'new' || index === modifyThresholdIndex;

    return (
      <Box key={`${index}`} display='flex' alignItems='flex-start'>
        <Box width={1} mr={1}>
          <Input
            name={`threshold-${index}`}
            label='Order value'
            type='number'
            value={isModify ? modifyThresholdValue : thresholdValue}
            onChange={e => {
              isModify ? this.onModifyThresholdChange(e) : this.onChange(e);
            }}
            disabled={!isModify}
            errorStatus={isModify && !isThresholdValid}
            errorText={t(
              'Field Order value is required and should be more than 0'
            )}
          />
        </Box>
        <Box width={1}>
          <Input
            name={`percentage-${index}`}
            label='Discount value'
            type='number'
            inputProps={{
              min: 0,
              max: 100
            }}
            value={percentage}
            onChange={e =>
              isModify ? this.onModifyThresholdChange(e) : this.onChange(e)
            }
            disabled={index !== 'new'}
            errorStatus={isModify && !isPercentageValid}
            errorText={t(
              'Field Discount value is required and should be between 0-99'
            )}
          />
        </Box>
        <Box width='200px' mt={1.5} display='flex' justifyContent='center'>
          {isModify ? (
            <IconButton
              onClick={this.onModifyThresholdSave}
              icon={<CheckIcon color='primary' />}
              alt='save'
            />
          ) : (
            <>
              <IconButton
                className='update-icon'
                onClick={() => this.onUpdateClick(index, threshold)}
                icon={<EditIcon />}
                alt='update'
                disabled={Boolean(modifyThresholdIndex)}
                tooltipMsg={t('Save the currently edited discount threshold')}
              />
              <IconButton
                className='delete-icon'
                onClick={() => this.onDeleteClick(index)}
                icon={<DeleteForeverIcon />}
                alt='delete'
              />
            </>
          )}
        </Box>
      </Box>
    );
  }

  render() {
    const { thresholds, modifyThreshold } = this.state;
    const { isAllInvalid } = this.props;

    return (
      <>
        <Typography component='h6' variant='h6'>
          {t('Order value discounts')}
        </Typography>
        <Box
          className={clsx(
            'language-form-box',
            isAllInvalid && 'language-form-box-error'
          )}
        >
          {thresholds.map(this.renderThreshold)}
          {modifyThreshold &&
            modifyThreshold.index === 'new' &&
            this.renderThreshold(modifyThreshold, 'new')}
          <Box display='flex' justifyContent='center'>
            <IconButton
              icon={<AddIcon fontSize='large' />}
              onClick={this.onCreateClick}
              className='create-icon'
              alt='create'
              disabled={Boolean(modifyThreshold)}
              tooltipMsg={t('Save the currently edited discount threshold')}
            />
          </Box>
          {isAllInvalid && (
            <Box display='flex' justifyContent='center'>
              <Typography color='error'>
                {t('At least one discount threshold must be added')}
              </Typography>
            </Box>
          )}
        </Box>
      </>
    );
  }
}

ThresholdsDiscounts.propTypes = {
  thresholds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  isAllInvalid: PropTypes.bool.isRequired
};

export default ThresholdsDiscounts;
