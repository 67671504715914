import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { statusesArray } from 'modules/Imports/importStatuses';

class ImportsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('status'),
            label: 'Status',
            type: 'select',
            emptyValueText: 'Status',
            options: formatOptions(statusesArray, 'status', 'name', true)
          }
        ]
      }
    ];
  }
}

export default ImportsFilter;
