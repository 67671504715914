import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import PropTypes from 'prop-types';
import renderPrice from 'modules/Products/utils/renderPrice';

const ClientsBonusesDetailsHistory = ({ bonus }) => {
  const renderHistoryItems = () => {
    return bonus.history.map(item => {
      const { id, old_value, description, date, updated_by } = item;
      const { first_name, last_name } = updated_by;

      return (
        <Box boxShadow={3} padding={1} m={1} my={2} key={id}>
          <TextDataSet
            label={t('Old value')}
            data={renderPrice(old_value, bonus.currency)}
          />
          <TextDataSet label={t('Description')} data={description} />
          <TextDataSet
            label={t('Changed by')}
            data={`${first_name} ${last_name}`}
          />
          <TextDataSet label={t('Changed at')} data={date} />
        </Box>
      );
    });
  };

  return (
    <Box>
      <Typography component='h6' variant='h6'>
        {t('Change history')}
      </Typography>
      {isEmpty(bonus.history) ? (
        <Typography>{t('No data')}</Typography>
      ) : (
        renderHistoryItems()
      )}
    </Box>
  );
};

ClientsBonusesDetailsHistory.propTypes = {
  bonus: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  }).isRequired
};

export default ClientsBonusesDetailsHistory;
