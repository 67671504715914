import Filter from 'modules/Layout/component/List/Filter';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { formatOptions } from 'modules/Layout/component/Select';
import discountsTypes from 'modules/Discounts/discountsTypes';

class DiscountsFilter extends Filter {
  static contextType = AuthContext;

  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Discount name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('type'),
            label: 'Type',
            type: 'select',
            emptyValueText: 'All types',
            options: formatOptions(discountsTypes, 'id', 'name', true)
          }
        ]
      },
      {
        groupLabel: 'Client',
        children: [
          {
            ...this.getBasicInputValues('client_name'),
            label: 'Client name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('client_address'),
            label: 'Client address',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('sales_group_name'),
            label: 'Shopping group name',
            type: 'text'
          }
        ]
      }
    ];
  }
}

export default DiscountsFilter;
