import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import getPathQuery from 'api/utils/getPathQuery';
import { ROUTE_PRODUCTS_LIST } from 'routing/routes/Products';
import { Box, Paper } from '@material-ui/core';
import ProductWholesalersMappingsCreateForm from 'modules/Products/component/WholesalersMappings/Create/Form';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';

class ProductWholesalersMappingsCreate extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id: productId }
      },
      location: { search }
    } = props;
    this.productId = parseInt(productId, 10);
    this.sku = getPathQuery(search, 'sku');
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      wholesalers: []
    };
  }

  async componentDidMount() {
    if (!this.productId) {
      this.props.history.push(ROUTE_PRODUCTS_LIST);
    }

    this.setCurrentPage('Create product mapping');

    this.fetchData();
  }

  async fetchData() {
    try {
      const [
        {
          data: { data: wholesalers }
        }
      ] = await Promise.all([
        WholesalersApi.getWholesalers({
          per_page: Number.MAX_SAFE_INTEGER
        })
      ]);

      this.setState({ wholesalers });
    } catch (err) {
      this.setAlert(err.getPayload().message);
    }
  }

  render() {
    const { wholesalers } = this.state;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ProductWholesalersMappingsCreateForm
              productId={this.productId}
              sku={this.sku}
              wholesalers={wholesalers}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ProductWholesalersMappingsCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ProductWholesalersMappingsCreate);
