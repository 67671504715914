import { Route } from 'react-router-dom';
import {
  SHOPPING_GROUPS_CREATE,
  SHOPPING_GROUPS_LIST,
  SHOPPING_GROUPS_UPDATE
} from 'api/auth/permissions/SalesGroups';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';
import SalesGroupsList from 'modules/SalesGroups/view/List';
import SalesGroupsDetailsWrapper from 'modules/SalesGroups/view/Details/DetailsWrapper';
import SalesGroupCreate from 'modules/SalesGroups/view/Create';
import SalesGroupUpdate from 'modules/SalesGroups/view/Update';
import SalesGroupFileCreate from 'modules/SalesGroups/view/File/Create';
import SalesGroupFileUpdate from 'modules/SalesGroups/view/File/Update';

export const ROUTE_SHOPPING_GROUPS_LIST = '/shopping_groups';
export const ROUTE_SHOPPING_GROUPS_DETAILS = '/shopping_groups/details/:id';
export const ROUTE_SHOPPING_GROUPS_CREATE = '/shopping_groups/create';
export const ROUTE_SHOPPING_GROUPS_UPDATE = '/shopping_groups/update/:id';
export const ROUTE_SHOPPING_GROUPS_FILE_CREATE =
  '/shopping_groups/details/:id/create_file';
export const ROUTE_SHOPPING_GROUPS_FILE_UPDATE =
  '/shopping_groups/details/:groupId/update_file/:fileId';

export const permMap = {
  [ROUTE_SHOPPING_GROUPS_LIST]: [SHOPPING_GROUPS_LIST],
  [ROUTE_SHOPPING_GROUPS_DETAILS]: [SHOPPING_GROUPS_LIST],
  [ROUTE_SHOPPING_GROUPS_CREATE]: [SHOPPING_GROUPS_CREATE],
  [ROUTE_SHOPPING_GROUPS_UPDATE]: [SHOPPING_GROUPS_UPDATE],
  [ROUTE_SHOPPING_GROUPS_FILE_CREATE]: [SHOPPING_GROUPS_LIST],
  [ROUTE_SHOPPING_GROUPS_FILE_UPDATE]: [SHOPPING_GROUPS_LIST]
};

const routes = {
  protected: [
    <Route key='sales-groups' exact path={ROUTE_SHOPPING_GROUPS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_SHOPPING_GROUPS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesGroupsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='sales-groups-details'
      exact
      path={ROUTE_SHOPPING_GROUPS_DETAILS}
    >
      <Authorize redirect permissions={permMap[ROUTE_SHOPPING_GROUPS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesGroupsDetailsWrapper contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='sales-groups-create' exact path={ROUTE_SHOPPING_GROUPS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_SHOPPING_GROUPS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesGroupCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='sales-groups-update' exact path={ROUTE_SHOPPING_GROUPS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_SHOPPING_GROUPS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesGroupUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='sales-groups-file-create'
      exact
      path={ROUTE_SHOPPING_GROUPS_FILE_CREATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_SHOPPING_GROUPS_FILE_CREATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => <SalesGroupFileCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='sales-groups-file-update'
      exact
      path={ROUTE_SHOPPING_GROUPS_FILE_UPDATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_SHOPPING_GROUPS_FILE_UPDATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => <SalesGroupFileUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
