import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  accordionDetailsBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    width: '100%'
  },
  accordionDetailsGroupWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionDetailsGroupChildrenWrapper: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  accordionDetailsBox: {
    [theme.breakpoints.up('md')]: {
      flex: '0 1 250px',
      marginRight: theme.spacing(1.5)
    },
    display: 'flex',
    alignItems: 'center'
  },
  valueOrRangeWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  }
}));

export default useStyles;
