import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import Button from 'modules/Layout/component/Button';
import Authorize from 'modules/Auth/component/Authorize';
import t from 'translate/translate';
import IconButton from 'modules/Layout/component/IconButton';
import {
  Block as BlockIcon,
  LocalShipping as LocalShippingIcon
} from '@material-ui/icons';

import {
  SALES_IMPORTS_STATUSES,
  CANCELED_SALE_IMPORT_STATUS,
  UNKNOWN_WHOLESALER_SALE_IMPORT_STATUS,
  UNMAPPED_PRODUCTS_SALE_IMPORT_STATUS,
  IMPORTED_SALE_IMPORT_STATUS
} from 'modules/SalesImports/constants';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import SalesImportsApi from 'api/connections/SalesImports/SalesImportsApi';
import { downloadFile } from 'modules/Shared/utils/file';

const SalesImportDataSets = props => {
  const {
    data: {
      id,
      date,
      wholesaler_name,
      country_id,
      status,
      started_by_user_name,
      message_from,
      message_date,
      processed_count,
      temp_count,
      wrong_count,
      cancellation_count,
      cancellation_reason,
      cancellation_date,
      cancellation_user_name
    },
    onCancelClick,
    onSetWholesalerClick
  } = props;

  const { hasPermission } = useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);
  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    try {
      const {
        data: { data: countriesData }
      } = await DepartmentsApi.getCountries();

      setCountries(countriesData);
    } catch (err) {
      if (err instanceof ApiError) {
        setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && fetchCountries();
  }, []);

  const getStatus = () => {
    const statusName = SALES_IMPORTS_STATUSES.find(item => item.id === status)
      ?.name;
    return statusName && t(statusName);
  };

  const getCountry = () => {
    return countries.find(item => item.id === country_id)?.name;
  };

  const handleError = err => {
    if (err instanceof ApiError) {
      setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  };

  const onLogDownloadClick = async () => {
    try {
      const res = await SalesImportsApi.getSalesImportLog(id);

      downloadFile(res);
    } catch (err) {
      handleError(err);
    }
  };

  const onOriginalFileDownloadClick = async () => {
    try {
      const res = await SalesImportsApi.getSalesImportOriginalFile(id);

      downloadFile(res);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box width={1}>
        <TextDataSet label='Import start date' data={date} />
        <TextDataSet label='Wholesaler' data={wholesaler_name} />
        <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
          <TextDataSet label='Country' data={getCountry()} />
        </Authorize>
        <TextDataSet label='Status' data={getStatus()} />
        <TextDataSet label='Started by' data={started_by_user_name} />
        {message_from && message_date ? (
          <>
            <TextDataSet
              label='Data received from address'
              data={message_from}
            />
            <TextDataSet label='Import file receipt date' data={message_date} />
          </>
        ) : (
          <TextDataSet label='Data received by' data={t('Manual import')} />
        )}
        <TextDataSet label='Processed count' data={processed_count} />
        <TextDataSet label='Temporary count' data={temp_count} />
        <TextDataSet label='Wrong count' data={wrong_count} />
        {status === CANCELED_SALE_IMPORT_STATUS && (
          <>
            <TextDataSet
              label='Number of withdrawn import data'
              data={cancellation_count}
            />
            <TextDataSet
              label='Import data withdrawn by'
              data={cancellation_user_name}
            />
            <TextDataSet
              label='Reason for withdrawal'
              data={cancellation_reason}
            />
            <TextDataSet label='Date of withdrawal' data={cancellation_date} />
          </>
        )}
        {status !== UNKNOWN_WHOLESALER_SALE_IMPORT_STATUS && (
          <Box
            display='flex'
            flexDirection={{ xs: 'column', xl: 'row' }}
            width={1}
          >
            <Box width={1} mr={{ lg: 1 }}>
              <Button
                type='button'
                color='primary'
                text={t('Download import log')}
                onClick={onLogDownloadClick}
                fullWidth
              />
            </Box>
            <Box width={1}>
              <Button
                type='button'
                color='primary'
                text={t('Download import original file')}
                onClick={onOriginalFileDownloadClick}
                fullWidth
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box display='flex' alignItems='flex-start'>
        <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
          <IconButton
            className='delete-icon'
            onClick={onCancelClick}
            disabled={
              status !== UNMAPPED_PRODUCTS_SALE_IMPORT_STATUS &&
              status !== IMPORTED_SALE_IMPORT_STATUS
            }
            tooltipMsg='Import have wrong status'
            icon={<BlockIcon />}
            alt='withdraw data'
          />
          {status === UNKNOWN_WHOLESALER_SALE_IMPORT_STATUS && (
            <IconButton
              className='set-wholesaler-icon'
              onClick={onSetWholesalerClick}
              icon={<LocalShippingIcon />}
              alt='set wholesaler'
            />
          )}
        </Authorize>
      </Box>
    </Box>
  );
};

SalesImportDataSets.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    wholesaler_id: PropTypes.number,
    wholesaler_name: PropTypes.string,
    country_id: PropTypes.number,
    status: PropTypes.number,
    started_by_user_id: PropTypes.number,
    started_by_user_name: PropTypes.string,
    message_from: PropTypes.string,
    message_date: PropTypes.string,
    processed_count: PropTypes.number,
    temp_count: PropTypes.number,
    wrong_count: PropTypes.number,
    cancellation_count: PropTypes.number,
    cancellation_reason: PropTypes.string,
    cancellation_date: PropTypes.string,
    cancellation_user_id: PropTypes.number,
    cancellation_user_name: PropTypes.string,
    file_url: PropTypes.string,
    log_url: PropTypes.string
  }).isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSetWholesalerClick: PropTypes.func.isRequired
};

export default SalesImportDataSets;
