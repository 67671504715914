import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import InternetClientsTable from 'modules/Clients/component/InternetList/Table';
import InternetClientsFilter from 'modules/Clients/component/InternetList/Filter';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { Box } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import AddIcon from '@material-ui/icons/Add';
import {
  CLIENTS_CREATE,
  INTERNET_CLIENTS_LIST
} from 'api/auth/permissions/Clients';
import { ROUTE_INTERNET_CLIENTS_CREATE } from 'routing/routes/Clients';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { downloadFile } from 'modules/Shared/utils/file';
import ClientsFilesApi from 'api/connections/Clients/ClientsFilesApi';

class ClientsList extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      filter: {
        name: '',
        recipient_number: '',
        tax_number: '',
        address: '',
        country_id: '',
        status: 1
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      clients: [],
      countries: [],
      loading: true
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportClients = this.exportClients.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Internet clients');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onCreateClick() {
    this.props.history.push(ROUTE_INTERNET_CLIENTS_CREATE);
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchClients()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchClients()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchClients()
    );
  }

  async fetchClients() {
    const {
      filter,
      sort,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: clients, meta }
      } = await ClientsApi.getInternetClients({
        ...sort,
        ...filter,
        per_page,
        page
      });

      const formattedClients = clients.map(client => ({
        ...client,
        cooperation_evaluation: [...client.evaluation],
        potential_evaluation: [...client.evaluation]
      }));

      this.setState({
        clients: formattedClients,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    const {
      filter,
      sort,
      pagination: { per_page, page }
    } = this.state;

    try {
      let countries = [];

      const {
        data: { data: clients, meta }
      } = await ClientsApi.getInternetClients({
        ...sort,
        ...filter,
        per_page,
        page
      });

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await PostalCodesApi.getCountries();
        countries = countriesData;
      }

      const formattedClients = clients.map(client => ({
        ...client,
        cooperation_evaluation: [...client.evaluation],
        potential_evaluation: [...client.evaluation]
      }));

      this.setState({
        clients: formattedClients,
        countries,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportClients() {
    try {
      const { filter, sort } = this.state;

      const res = await ClientsFilesApi.exportInternetClients({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          <Authorize permissions={[CLIENTS_CREATE]}>
            <IconButton
              icon={<AddIcon fontSize='large' />}
              onClick={this.onCreateClick}
              className='create-icon'
              alt='create'
            />
          </Authorize>
          <Authorize permissions={[INTERNET_CLIENTS_LIST]}>
            <IconButton
              onClick={this.exportClients}
              icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
              alt='export table'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={this.state.pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    return (
      <div>
        <InternetClientsFilter
          filter={this.state.filter}
          validation={this.state.filterValidation}
          setFilter={this.setFilter}
          countries={this.state.countries}
          hasRole={this.context.hasRole}
        />
        {this.renderActions()}
        <InternetClientsTable
          sort={this.state.sort}
          setSort={this.setSort}
          clients={this.state.clients}
          countries={this.state.countries}
          loading={this.state.loading}
        />
        {this.renderActions()}
      </div>
    );
  }
}

ClientsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(ClientsList);
