import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ClientsContractsForm from 'modules/ClientsContracts/component/Form';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_CLIENTS_CONTRACTS_LIST } from 'routing/routes/ClientsContracts';
import Loader from 'modules/Layout/component/Loader';

class ClientsContractsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = { categoriesObj: undefined };
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Create client contract');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_CLIENTS_CONTRACTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: categories
      } = await ProductCategoriesApi.getProductCategories();

      this.setState({
        categoriesObj: prepareCategories(categories)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { categoriesObj } = this.state;

    if (!categoriesObj) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <ClientsContractsForm
              categoriesObj={categoriesObj}
              setAlert={this.props.contextMethods.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ClientsContractsCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ClientsContractsCreate);
