import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import ClientsBonusesApi from 'api/connections/ClientsBonuses/ClientsBonusesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const UnSuspendBonusDialog = props => {
  const { bonusId, onClose, onSuccess } = props;
  const { setAlert } = useContext(AlertContext);

  const unSuspend = async () => {
    try {
      await ClientsBonusesApi.unsuspendBonus(bonusId);
      setAlert({
        value: t('Bonus unsuspended'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Confirmation')}
      description={t('Are you sure you want to undo suspension for this bonus')}
      onApprove={unSuspend}
      onCancel={onClose}
    />
  );
};

UnSuspendBonusDialog.propTypes = {
  bonusId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default UnSuspendBonusDialog;
