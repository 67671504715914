import { Component } from 'react';
import PropTypes from 'prop-types';
import ApiError from 'api/exceptions/ApiError';
import ProductsApi from 'api/connections/Products/ProductsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ProductsTopSellersTable from 'modules/Products/component/TopSellers/Table';
import ProductsTopSellersFilter from 'modules/Products/component/TopSellers/Filter';
import { isEmpty } from 'lodash/lang';

export const filterData = data => {
  const categories = data
    .filter(({ products }) => !isEmpty(products))
    .map(({ id, name }) => ({
      id,
      name
    }));

  let topSellers = data.map(category =>
    category.products.map(product => {
      return {
        ...product,
        category_id: category.id,
        category_name: category.name
      };
    })
  );

  topSellers = topSellers.reduce((acc, arr) => {
    return [...acc, ...arr];
  }, []);

  return { categories, topSellers };
};

class ProductsTopSellersList extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      filter: {
        category_id: ''
      },
      sort: {
        sort_field: 'bought_quantity',
        sort_order: 'desc'
      },
      filterValidation: {},
      topSellers: [],
      categories: [],
      loading: false
    };

    this.setFilter = this.setFilter.bind(this);
    this.setSort = this.setSort.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Top sellers');

    this.fetchTopSellers();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setFilter(filter) {
    const categoryChangeStatus =
      this.state.filter.category_id !== filter.category_id;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          ...filter
        }
      },
      () => {
        if (!categoryChangeStatus) this.fetchTopSellers();
      }
    );
  }

  setSort(sort) {
    this.setState({ sort: { ...this.state.sort, ...sort } });
  }

  filterTopSellers() {
    const { topSellers } = this.state;
    const { category_id } = this.state.filter;
    const { sort_order } = this.state.sort;

    let result = [...topSellers];

    if (category_id) {
      result = topSellers.filter(item => item.category_id === category_id);
    }

    result = result.sort((a, b) => {
      if (sort_order === 'asc') {
        return a.bought_quantity < b.bought_quantity ? -1 : 0;
      }
      return a.bought_quantity > b.bought_quantity ? -1 : 0;
    });

    return result;
  }

  async fetchTopSellers() {
    try {
      this.setState({ loading: true });

      const {
        data: { data }
      } = await ProductsApi.getTopSellers();

      const { categories, topSellers } = filterData(data);

      this.setState({ topSellers, categories, loading: false });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { filter, sort, categories, loading } = this.state;

    const filteredTopSellers = this.filterTopSellers();

    return (
      <div>
        <ProductsTopSellersFilter
          filter={filter}
          validation={this.state.filterValidation}
          setFilter={this.setFilter}
          categories={categories}
        />
        <ProductsTopSellersTable
          topSellers={filteredTopSellers}
          fetchProducts={this.fetchProducts}
          loading={loading}
          sort={sort}
          setSort={this.setSort}
        />
      </div>
    );
  }
}

ProductsTopSellersList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default ProductsTopSellersList;
