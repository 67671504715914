import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PlanningMap from 'modules/Visits/components/RoutePlanning/Map';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DatePicker from 'modules/Layout/component/Date/DatePicker';
import {
  PLANNED,
  CONFIRMED,
  POSTPONED
} from 'modules/Visits/utils/visitStatuses';
import PageLoader from 'modules/Layout/component/PageLoader';
import { Box, Grid } from '@material-ui/core';
import t from 'translate/translate';
import { ROUTE_VISITS_MAP_ROUTES } from 'routing/routes/Visits';
import Button from 'modules/Layout/component/Button';
import arrayMove from 'array-move';
import VisitsList from 'modules/Visits/components/RoutePlanning/VisitsList/List';
import setVisitsTime from 'modules/Visits/utils/setVisitsTime';
import PlanningModal from 'modules/Visits/components/RoutePlanning/PlanningModal';
import * as Multistep from 'modules/Visits/components/RoutePlanning/PlanningWizard';

const API_DATE_FORMAT = 'dd.MM.yyyy';

class VisitsRoutePlanning extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      date: format(new Date(), API_DATE_FORMAT),
      endDate: format(new Date(), API_DATE_FORMAT),
      filter: {
        user_id: context.user.id,
        statuses: [PLANNED, CONFIRMED, POSTPONED]
      },
      clientsFilter: {
        my_clients: true,
        per_page: Number.MAX_SAFE_INTEGER
      },
      sort: {
        sort_field: 'scheduled_at'
      },
      visits: [],
      clients: [],
      mapRenderKey: 1,
      planningModalOpen: false,
      loading: true
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.onDateChange = this.onDateChange.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onClientMarkerClick = this.onClientMarkerClick.bind(this);
    this.onVisitDelete = this.onVisitDelete.bind(this);
    this.onMapReload = this.onMapReload.bind(this);
    this.onPlanningModalOpen = this.onPlanningModalOpen.bind(this);
    this.onPlanningModalClose = this.onPlanningModalClose.bind(this);
    this.onPlanningDateChange = this.onPlanningDateChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onAddingVisitsSuccess = this.onAddingVisitsSuccess.bind(this);
    this.onPostponementCheckboxChange = this.onPostponementCheckboxChange.bind(
      this
    );
  }

  componentDidMount() {
    this.setCurrentPage('Route planning');

    this.fetchData();
  }

  onDateChange(e) {
    const { value, name } = e.target;
    this.setState({ [name]: value }, this.fetchVisits);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { date, endDate } = this.state;

    this.setState(prevState => ({
      visits: setVisitsTime(arrayMove(prevState.visits, oldIndex, newIndex), {
        date,
        endDate
      })
    }));
  }

  onClientMarkerClick(clientId, clientData) {
    const { date, endDate } = this.state;

    if (clientData.client) {
      const {
        name: client_name,
        lat,
        long,
        id: client_id,
        city,
        street,
        postal_code
      } = clientData.client;
      this.setState(prevState => ({
        visits: setVisitsTime(
          [
            ...prevState.visits,
            {
              client_name,
              lat,
              long,
              client_id,
              place: `${city}, ${street}, ${postal_code}`,
              id: `_${Math.random().toString(36).substr(2, 9)}`
            }
          ],
          { date, endDate }
        )
      }));
    }
  }

  onVisitDelete(id) {
    const { date, endDate } = this.state;
    this.setState(prevState => ({
      visits: setVisitsTime(
        prevState.visits.filter(visit => visit.id !== id),
        { date, endDate }
      )
    }));
  }

  onMapReload() {
    this.setState({ mapRenderKey: Math.random() });
  }

  onPlanningModalOpen() {
    this.setState({ planningModalOpen: true });
  }

  onPlanningModalClose() {
    this.setState({ planningModalOpen: false });
  }

  onPlanningDateChange(e, visitId) {
    const { value } = e.target;

    this.setState(prevState => ({
      visits: prevState.visits.map(visit =>
        visit.id === visitId ? { ...visit, scheduled_at: value } : visit
      )
    }));
  }

  onTextChange(e, visitId) {
    const { value, name } = e.target;

    this.setState(prevState => ({
      visits: prevState.visits.map(visit =>
        visit.id === visitId ? { ...visit, [name]: value } : visit
      )
    }));
  }

  onPostponementCheckboxChange(e, visitId) {
    this.setState(prevState => ({
      visits: prevState.visits.map(visit =>
        visit.id === visitId
          ? { ...visit, postponement: e.target.checked }
          : visit
      )
    }));
  }

  async onAddingVisitsSuccess() {
    this.onPlanningModalClose();
    await this.fetchVisits();
  }

  async fetchVisits() {
    const { date, endDate, filter, sort } = this.state;

    this.setState({ loading: true });

    try {
      const {
        data: { data: fetchedVisits }
      } = await VisitsApi.getVisits({
        ...filter,
        ...sort,
        per_page: Number.MAX_SAFE_INTEGER,
        date_from: date,
        date_to: endDate
      });

      this.setState({
        visits: setVisitsTime(fetchedVisits, { date, endDate }),
        loading: false
      });
    } catch (err) {
      this.setState({ loading: false });
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchData() {
    const { date, endDate, filter, sort, clientsFilter } = this.state;

    this.setState({ loading: true });

    try {
      const [
        {
          data: { data: fetchedVisits }
        },
        {
          data: { data: fetchedClients }
        }
      ] = await Promise.all([
        VisitsApi.getVisits({
          ...filter,
          ...sort,
          per_page: Number.MAX_SAFE_INTEGER,
          date_from: date,
          date_to: endDate
        }),
        ClientsApi.getClients({
          ...clientsFilter
        })
      ]);

      this.setState({
        visits: setVisitsTime(fetchedVisits, { date, endDate }),
        clients: fetchedClients,
        loading: false
      });
    } catch (err) {
      this.setState({ loading: false });
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  render() {
    const {
      visits,
      clients,
      loading,
      date,
      endDate,
      mapRenderKey,
      planningModalOpen
    } = this.state;

    return (
      <>
        <Box display='flex'>
          <DatePicker
            fullWidth={false}
            label='Date from'
            onChange={this.onDateChange}
            name='date'
            minDate={new Date()}
            maxDate={parse(endDate, API_DATE_FORMAT, new Date())}
          />
          <div style={{ marginLeft: 10 }}>
            <DatePicker
              fullWidth={false}
              label='Date to'
              onChange={this.onDateChange}
              name='endDate'
              minDate={parse(date, API_DATE_FORMAT, new Date())}
            />
          </div>
          <Button
            color='primary'
            text={t('Show route')}
            onClick={this.onMapReload}
            style={{ marginLeft: 10 }}
          />
          <Button
            color='primary'
            text={t('Routes')}
            onClick={() => this.props.history.push(ROUTE_VISITS_MAP_ROUTES)}
            style={{ marginLeft: 'auto' }}
          />
        </Box>
        <Grid container spacing={4} style={{ height: '100vh' }}>
          <Grid item xs={12} lg={8}>
            {loading ? (
              <PageLoader />
            ) : (
              <PlanningMap
                onClientMarkerClick={this.onClientMarkerClick}
                visits={visits}
                clients={clients}
                mapRenderKey={mapRenderKey}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <VisitsList
              visits={visits}
              onSortEnd={this.onSortEnd}
              onVisitDelete={this.onVisitDelete}
              onPlanningModalOpen={this.onPlanningModalOpen}
            />
          </Grid>
        </Grid>
        {planningModalOpen && (
          <PlanningModal
            open={planningModalOpen}
            onClose={this.onPlanningModalClose}
          >
            <Multistep.Wizard>
              {visits.map((visit, index) => (
                <Multistep.Page
                  key={visit.id}
                  pageIndex={index + 1}
                  visit={visit}
                  onPlanningDateChange={this.onPlanningDateChange}
                  onTextChange={this.onTextChange}
                  onPostponementCheckboxChange={
                    this.onPostponementCheckboxChange
                  }
                />
              ))}
              <Multistep.Controls
                visits={visits}
                onAddingVisitsSuccess={this.onAddingVisitsSuccess}
              />
            </Multistep.Wizard>
          </PlanningModal>
        )}
      </>
    );
  }
}

VisitsRoutePlanning.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default withRouter(VisitsRoutePlanning);
