import { useCurrentPage } from 'modules/Shared/hook';
import t from 'translate/translate';

const Affiliation = () => {
  useCurrentPage('Affiliation');

  return <div>{t('Affiliation page will be placed here.')}</div>;
};

export default Affiliation;
