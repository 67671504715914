import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography
} from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import ProductsCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import StatisticsSalesComparisonFilter from 'modules/Statistics/SalesComparison/component/List/Filter';
import StatisticsExportApi from 'api/connections/Statistics/StatisticsExportApi';
import { downloadFile } from 'modules/Shared/utils/file';
import IconButton from 'modules/Layout/component/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import {
  comparisonTypes,
  comparisonTypesSelect,
  isWholesalerNeeded
} from 'modules/Statistics/SalesComparison/utils/comparisonTypesUtils';
import { isEmpty } from 'lodash/lang';
import formatSalesResults from 'modules/SalesResults/utils/formatSalesResults';
import SalesComparisonCharts from 'modules/Statistics/SalesComparison/component/Comparison/Charts/Summary';
import t from 'translate/translate';
import SalesComparisonNavigationBar from 'modules/Statistics/SalesComparison/component/Comparison/NavigationBar';
import SalesResultsLegend from 'modules/SalesResults/component/ResultsDetails/Legend';
import Divider from '@material-ui/core/Divider';
import SalesComparisonDirectWholesalersCharts from 'modules/Statistics/SalesComparison/component/Comparison/Charts/DirectWholesalers';
import SalesComparisonWholesalersCharts from 'modules/Statistics/SalesComparison/component/Comparison/Charts/Wholesalers';

const getProductsCategories = data => {
  const groups = data.map(({ name, id }) => ({ name, id }));
  const categories = data
    .map(({ categories: categoriesData }) =>
      categoriesData.map(category => ({ name: category.name, id: category.id }))
    )
    .reduce((total, item) => [...total, ...item], []);
  const subcategories = data
    .map(({ categories: categoriesData }) =>
      categoriesData.map(({ subcategories: subcategoriesData }) =>
        subcategoriesData.map(subcategory => ({
          name: subcategory.name,
          id: subcategory.id
        }))
      )
    )
    .reduce((total, item) => [...total, ...item], [])
    .reduce((total, item) => [...total, ...item], []);

  return { groups, categories, subcategories };
};

class StatisticsSalesComparison extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const { user } = this.context;

    this.state = {
      filter: {
        period: 'year',
        group_id: null,
        category_id: null,
        subcategory_id: null,
        type: comparisonTypes['direct-wholesalers-summary'],
        wholesaler_id: []
      },
      wholesalersFilter: {
        country_id: user.country_id
      },
      filterValidation: {},
      statistics: [],
      wholesalers: [],
      groups: [],
      categories: [],
      subcategories: [],
      loading: false,
      stack: [],
      breadcrumbs: [t('Comprehensive data')],
      data: { wholesalersSales: [], directSales: [] },
      viewType: 'pie',
      infoText: 'Pick period first'
    };

    this.setFilter = this.setFilter.bind(this);
    this.updateWholesalers = this.updateWholesalers.bind(this);
    this.fetchStatistics = this.fetchStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportStatistics = this.exportStatistics.bind(this);
    this.drillDownSummary = this.drillDownSummary.bind(this);
    this.drillUpSummary = this.drillUpSummary.bind(this);
    this.onViewTypeChange = this.onViewTypeChange.bind(this);
    this.drillDownDirectWholesalers = this.drillDownDirectWholesalers.bind(
      this
    );
    this.drillDownWholesalers = this.drillDownWholesalers.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Sales comparison');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onViewTypeChange(type) {
    this.setState({ viewType: type });
  }

  setFilter(filter) {
    if (filter.type !== this.state.filter.type) {
      this.setState({ data: { wholesalersSales: [], directSales: [] } });
    }
    this.setState(
      {
        filter: { ...filter, wholesaler_id: this.state.filter.wholesaler_id }
      },
      () => this.fetchStatistics()
    );
  }

  getChartsTitle() {
    return t(
      comparisonTypesSelect.find(({ slug }) => slug === this.state.filter.type)
        .label
    );
  }

  updateWholesalers(name, value) {
    this.setState({
      filter: { ...this.state.filter, [name]: value }
    });
  }

  async fetchData() {
    const { wholesalersFilter } = this.state;

    try {
      const [
        {
          data: { data: wholesalers }
        },
        { data: productsCategories }
      ] = await Promise.all([
        WholesalersApi.getWholesalers({
          ...wholesalersFilter,
          per_page: Number.MAX_SAFE_INTEGER
        }),
        ProductsCategoriesApi.getProductCategories()
      ]);

      const { groups, categories, subcategories } = getProductsCategories(
        productsCategories
      );

      this.setState({
        wholesalers,
        groups,
        categories,
        subcategories,
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchStatistics() {
    const { filter } = this.state;

    if (!filter.year) return null;
    if (filter.period === 'quarter' && !filter.quarter) return null;
    if (filter.period === 'month' && !filter.month) return null;
    if (isWholesalerNeeded(filter.type) && isEmpty(filter.wholesaler_id)) {
      this.setState({
        directSalesStatisticsData: [],
        wholesalersSalesStatisticsData: [],
        data: {
          directSales: [],
          wholesalersSales: []
        },
        stack: [],
        infoText: 'Choose wholesaler'
      });
      return;
    }
    try {
      this.setState({ loading: true });

      const { data } = await StatisticsApi.getSalesComparison({
        ...filter
      });

      if (filter.type === comparisonTypes['direct-wholesalers-summary']) {
        this.setState({
          directSalesStatisticsData: data.direct,
          wholesalersSalesStatisticsData: data.wholesalers,
          data: {
            directSales: formatSalesResults(data.direct),
            wholesalersSales: formatSalesResults(data.wholesalers)
          },
          stack: [],
          breadcrumbs: [t('Comprehensive data')],
          loading: false,
          infoText: 'No data'
        });
      }

      if (filter.type === comparisonTypes['direct-wholesalers']) {
        const wholesalersData = data.wholesalers_items
          .map(wholesaler => ({
            name: wholesaler.name,
            id: wholesaler.id,
            data: formatSalesResults(wholesaler.data)
          }))
          .filter(item => !isEmpty(item.data));

        this.setState({
          directSalesStatisticsData: data.direct,
          wholesalersSalesStatisticsData: data.wholesalers_items,
          data: {
            directSales: formatSalesResults(data.direct),
            wholesalersSales: wholesalersData
          },
          stack: [],
          breadcrumbs: [t('Comprehensive data')],
          loading: false,
          infoText: 'No data'
        });
      }

      if (filter.type === comparisonTypes.wholesalers) {
        const wholesalersData = data.wholesalers_items
          .map(wholesaler => ({
            name: wholesaler.name,
            id: wholesaler.id,
            data: formatSalesResults(wholesaler.data)
          }))
          .filter(item => !isEmpty(item.data));

        this.setState({
          directSalesStatisticsData: [],
          wholesalersSalesStatisticsData: data.wholesalers_items,
          data: {
            directSales: [],
            wholesalersSales: wholesalersData
          },
          stack: [],
          breadcrumbs: [t('Comprehensive data')],
          loading: false,
          infoText: 'No data'
        });
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportStatistics() {
    try {
      const { filter } = this.state;

      const res = await StatisticsExportApi.exportSalesComparisonRanking({
        ...filter
      });

      downloadFile(res);
    } catch (err) {
      if (err instanceof ApiError) {
        this.props.contextMethods.setAlert(err.getPayload().message);
      }
    }
  }

  drillDownSummary(data, clickedData, secondaryData) {
    if (data.children.length > 0) {
      this.setState(prevState => ({
        data: {
          [clickedData]:
            prevState.data[clickedData].find(sale => sale.id === data.id)
              ?.children || [],
          [secondaryData]:
            prevState.data[secondaryData].find(sale => sale.id === data.id)
              ?.children || []
        },
        breadcrumbs: [...prevState.breadcrumbs, data.name],
        stack: [...prevState.stack, prevState.data]
      }));
    }
  }

  drillUpSummary(index) {
    this.setState(prevState => {
      if (prevState.breadcrumbs.length <= 1) return { ...prevState };
      return {
        data: prevState.stack[index],
        breadcrumbs: prevState.breadcrumbs.slice(0, index + 1),
        stack: prevState.stack.slice(0, index)
      };
    });
  }

  drillDownDirectWholesalers(data) {
    if (data.children.length > 0) {
      this.setState(prevState => {
        return {
          data: {
            wholesalersSales: prevState.data.wholesalersSales.map(
              wholesaler => ({
                name: wholesaler.name,
                id: wholesaler.id,
                data:
                  wholesaler.data.find(sale => sale.id === data.id)?.children ||
                  []
              })
            ),
            directSales:
              prevState.data.directSales.find(sale => sale.id === data.id)
                ?.children || []
          },
          breadcrumbs: [...prevState.breadcrumbs, data.name],
          stack: [...prevState.stack, prevState.data]
        };
      });
    }
  }

  drillDownWholesalers(data) {
    if (data.children.length > 0) {
      this.setState(prevState => {
        return {
          data: {
            wholesalersSales: prevState.data.wholesalersSales.map(
              wholesaler => ({
                name: wholesaler.name,
                id: wholesaler.id,
                data:
                  wholesaler.data.find(sale => sale.id === data.id)?.children ||
                  []
              })
            )
          },
          breadcrumbs: [...prevState.breadcrumbs, data.name],
          stack: [...prevState.stack, prevState.data]
        };
      });
    }
  }

  noData() {
    return (
      isEmpty(this.state.data.wholesalersSales) &&
      isEmpty(this.state.data.directSales)
    );
  }

  renderActions() {
    const {
      filter: { year }
    } = this.state;

    return (
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <IconButton
            onClick={this.exportStatistics}
            icon={
              <GetAppOutlinedIcon
                fontSize='large'
                color={year ? 'primary' : 'disabled'}
              />
            }
            alt='export table'
            disabled={!year}
            tooltipMsg='Pick period first'
          />
        </Box>
      </Box>
    );
  }

  renderCharts() {
    const {
      data,
      viewType,
      filter: { type },
      infoText
    } = this.state;

    return this.noData() ? (
      <Typography align='center' variant='subtitle1' color='textSecondary'>
        {t(infoText)}
      </Typography>
    ) : (
      <>
        {type === comparisonTypes['direct-wholesalers-summary'] && (
          <SalesComparisonCharts
            data={data}
            viewType={viewType}
            onDrillDown={this.drillDownSummary}
            nameSuffix={Date.now()}
            thirdSection={false}
          />
        )}
        {type === comparisonTypes['direct-wholesalers'] && (
          <SalesComparisonDirectWholesalersCharts
            data={data}
            viewType={viewType}
            onDrillDown={this.drillDownDirectWholesalers}
            nameSuffix={Date.now()}
            thirdSection={false}
          />
        )}
        {type === comparisonTypes.wholesalers && (
          <SalesComparisonWholesalersCharts
            data={data}
            viewType={viewType}
            onDrillDown={this.drillDownWholesalers}
            nameSuffix={Date.now()}
            thirdSection={false}
          />
        )}
        {viewType !== 'table' &&
          type === comparisonTypes['direct-wholesalers-summary'] && (
            <>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography align='center'>
                {t('Wholesalers sales legend')}
              </Typography>
              <SalesResultsLegend nameSuffix={Date.now()} />
              <Box my={2}>
                <Divider />
              </Box>
              <Typography align='center'>{t('Direct sales legend')}</Typography>
              <SalesResultsLegend nameSuffix={Date.now() + 1} />
            </>
          )}
      </>
    );
  }

  render() {
    const {
      wholesalers,
      groups,
      categories,
      subcategories,
      filter,
      loading,
      filterValidation,
      breadcrumbs,
      viewType
    } = this.state;

    return (
      <div>
        <StatisticsSalesComparisonFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          onMultiselectFilterChange={this.updateWholesalers}
          wholesalers={wholesalers}
          groups={groups}
          categories={categories}
          subcategories={subcategories}
        />

        {this.renderActions()}
        <Card>
          <CardContent>
            <Typography gutterBottom align='center' variant='h6'>
              {this.getChartsTitle()}
            </Typography>
            <SalesComparisonNavigationBar
              onDrillUp={this.drillUpSummary}
              breadcrumbs={breadcrumbs}
              viewType={viewType}
              onViewTypeChange={this.onViewTypeChange}
            />
            {loading ? (
              <Box display='flex' justifyContent='center'>
                <CircularProgress />
              </Box>
            ) : (
              this.renderCharts()
            )}
          </CardContent>
        </Card>
        {this.renderActions()}
      </div>
    );
  }
}

StatisticsSalesComparison.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default StatisticsSalesComparison;
