export default {
  Bonuses: 'Premie',
  Bonus: 'Premia',
  'Targets bonuses': 'Premie',
  'Bonus details': 'Szczegóły premii',
  'Create target bonus': 'Dodawanie premii',
  'Target bonuses details': 'Szczegóły premii',
  'Update target bonuses': 'Edycja premii',
  'waiting for target confirmation': 'oczekiwanie na zatwierdzenie celu',
  'waiting for contract confirmation': 'oczekiwanie na zatwierdzenie kontraktu',
  'waiting for confirmation': 'oczekiwanie na zatwierdzenie',
  confirmed: 'zatwierdzona',
  paid: 'wypłacona',
  suspended: 'zawieszona',
  'Value from': 'Wartość od',
  'Value to': 'Wartość do',
  'Bonus at 90%': 'Premia przy progu 90%',
  'Bonus at 100%': 'Premia przy progu 100%',
  'Bonus at 110%': 'Premia przy progu 110%',
  'Bonus at 120%': 'Premia przy progu 120%',
  'Target dont have bonus': 'Cel nie posiada premii',
  'You are not allowed to make changes': 'Nie możesz dokonywać zmian',
  'Set final value': 'Edytuj ostateczną wartość premii',
  'Final value': 'Ostateczną wartość premii',
  confirm: 'zatwierdź',
  Confirmation: 'Potwierzenie',
  'Are you sure you want to confirm this bonus':
    'Czy na pewno chcesz zatwierdzić tą premię?',
  'Are you sure you want to set paid status this bonus':
    'Czy na pewno chcesz zmienić staus premii na zapłacona?',
  suspend: 'zawieszenie',
  'Suspend confirmation': 'Potwierdzenie zawieszenia',
  'Are you sure you want to suspend bonus':
    'Czy na pewno chcesz zawiesić premie?',
  'undo suspension': 'cofnięcie zawieszenia',
  'Are you sure you want to undo suspension for this bonus':
    'Czy na pewno chcesz cofnąć zawieszenie dla tej premii?',
  'Value of the goal achieved ': 'Wartość zrealizowanego celu',
  Confirmed: 'Zatwierdzony',
  Unconfirmed: 'Niezatwierdzony',
  'Confirmed by': 'Zatwierdzone przez',
  'Confirmed at': 'Data zatwierdzenia',
  'Suspended by': 'Zawieszona przez',
  'Suspended at': 'Data zawieszenia',
  'Suspended reason': 'Powód zawieszenia',
  'Goal achievement forecasts': 'Prognoza realizacji celu',
  'Final bonus value': 'Ostateczna wartość premii',
  'set status to paid': 'ustaw status wypłacona',
  'Date from': 'Data od',
  'Date to': 'Data do',
  'Bonus value at each goal fulfillment level':
    'Wartość premii przy poszczególnych poziomach spełnienia celu',
  'Estimated bonus value': 'Szacowana wartość premii'
};
