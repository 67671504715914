import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';

import ProductsTreeSubCategories from 'modules/Products/component/ProductsTree/ProductsTreeSubCategories';

class ProductsTreeCategories extends Component {
  isItemInAssignedData({ id: itemId }) {
    const { selected } = this.props;

    return Boolean(selected.categories.find(i => i.id === itemId));
  }

  render() {
    const {
      categoriesObj,
      productsData,
      treeItemsToDisplay,
      selected,
      onClick,
      onSelect,
      selectMode,
      showAllItems,
      selectAllItems
    } = this.props;

    return categoriesObj.map(item => {
      if (
        !showAllItems &&
        !selectMode &&
        treeItemsToDisplay &&
        !treeItemsToDisplay.categories.includes(item.id)
      ) {
        return null;
      }

      return (
        <TreeItem
          itemData={item}
          key={item.id}
          nodeId={item.id.toString()}
          labelText={item.name}
          selectMode={selectMode}
          isSelected={selectAllItems || this.isItemInAssignedData(item)}
          onSelect={onSelect}
        >
          {!isEmpty(item.subcategories) && (
            <ProductsTreeSubCategories
              categoriesObj={item.subcategories}
              productsData={productsData}
              treeItemsToDisplay={treeItemsToDisplay}
              selected={selected}
              onClick={onClick}
              onSelect={onSelect}
              selectMode={selectMode}
              showAllItems={showAllItems || this.isItemInAssignedData(item)}
              selectAllItems={selectAllItems || this.isItemInAssignedData(item)}
            />
          )}
        </TreeItem>
      );
    });
  }
}

const selectedPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  group_id: PropTypes.number,
  category_id: PropTypes.number,
  subcategory_id: PropTypes.number
});

ProductsTreeCategories.propTypes = {
  categoriesObj: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productsData: PropTypes.shape({}).isRequired,
  treeItemsToDisplay: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.number),
    categories: PropTypes.arrayOf(PropTypes.number),
    subcategories: PropTypes.arrayOf(PropTypes.number),
    products: PropTypes.arrayOf(PropTypes.number)
  }),
  selected: PropTypes.shape({
    groups: PropTypes.arrayOf(selectedPropType),
    categories: PropTypes.arrayOf(selectedPropType),
    subcategories: PropTypes.arrayOf(selectedPropType),
    products: PropTypes.arrayOf(selectedPropType)
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectMode: PropTypes.bool.isRequired,
  showAllItems: PropTypes.bool.isRequired,
  selectAllItems: PropTypes.bool.isRequired
};

ProductsTreeCategories.defaultProps = {
  treeItemsToDisplay: null
};

export default ProductsTreeCategories;
