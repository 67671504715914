import { ROUTE_WHOLESALERS_LIST, permMap } from 'routing/routes/Wholesalers';
import ListIcon from '@material-ui/icons/List';

const menu = [
  {
    key: 'menu-wholesalers-list',
    label: 'Wholesalers',
    icon: <ListIcon />,
    path: ROUTE_WHOLESALERS_LIST,
    permissions: permMap[ROUTE_WHOLESALERS_LIST]
  }
];

export default menu;
