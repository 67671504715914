import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: '1rem'
  },
  signatureWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.5rem'
  },
  signature: {
    fontSize: 14
  }
});

const VisitNote = props => {
  const classes = useStyles();
  const {
    note: { content, creator_name, created_at }
  } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display='flex' flexDirection='column'>
          <Box className={classes.signatureWrapper}>
            <Typography className={classes.signature} color='textSecondary'>
              {creator_name} {created_at}
            </Typography>
          </Box>
          <Typography variant='body2' component='p'>
            {content}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

VisitNote.propTypes = {
  note: PropTypes.shape({
    creator_id: PropTypes.number,
    content: PropTypes.string,
    creator_name: PropTypes.string,
    created_at: PropTypes.string
  }).isRequired
};

export default VisitNote;
