const insertPathParams = (path, params) => {
  let result = path;
  const paramsArr = Object.entries(params);

  paramsArr.forEach(([key, value]) => {
    result = result.replace(`:${key}`, value);
  });

  return result;
};

export default insertPathParams;
