import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';

const ClientsContractsDetailsThresholds = props => {
  const sortBonuses = () => {
    return props.bonuses.sort((a, b) =>
      parseInt(a.value, 10) < parseInt(b.value, 10) ? 0 : -1
    );
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Typography component='h6' variant='h6' style={{ marginBottom: '.5rem' }}>
        {t('Thresholds')}
      </Typography>
      {!isEmpty(props.bonuses) ? (
        <>
          {sortBonuses().map(bonus => (
            <TextDataSet
              key={bonus.value}
              label={t('Bonus at the threshold <%=value%>', {
                value: bonus.value
              })}
              data={`${bonus.percent}%`}
            />
          ))}
        </>
      ) : (
        <Typography variant='body1'>
          {t('Client contract dont have thresholds')}
        </Typography>
      )}
    </Box>
  );
};

ClientsContractsDetailsThresholds.defaultProps = {
  bonuses: []
};

ClientsContractsDetailsThresholds.propTypes = {
  bonuses: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
};

export default ClientsContractsDetailsThresholds;
