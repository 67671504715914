import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import t from 'translate/translate';
import Table from 'modules/Layout/component/List/Table';
import targetTypes, {
  VALUABLE_TARGET_TYPE,
  QUANTITATIVE_MANAGERS_TARGET_TYPE,
  VALUABLE_MANAGERS_TARGET_TYPE
} from 'modules/Targets/targetTypes';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_TARGETS_DETAILS,
  ROUTE_TARGETS_UPDATE
} from 'routing/routes/Targets';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  TARGET_DELETE,
  TARGET_LIST,
  TARGET_UPDATE
} from 'api/auth/permissions/Targets';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN,
  TELEMARKETER
} from 'api/auth/roles';

export const renderEstimationPercent = percent => {
  if (percent !== 0 && !percent) return t('No data');

  return `${percent.toFixed(2)}%`;
};

class TargetsTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_TARGETS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_TARGETS_UPDATE, { id }));
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'type',
        label: 'Type',
        sortable: true,
        render: (value, row) => {
          const { for_management } = row;
          let targetTypeId = value;

          if (for_management) {
            targetTypeId =
              value === VALUABLE_TARGET_TYPE
                ? VALUABLE_MANAGERS_TARGET_TYPE
                : QUANTITATIVE_MANAGERS_TARGET_TYPE;
          }

          return t(targetTypes.find(type => type.id === targetTypeId).name);
        }
      },
      {
        property: 'user_name',
        label: 'User name',
        sortable: true,
        displayStatus: this.context.hasRole([
          SUPER_ADMIN,
          ADMIN,
          DIRECTOR,
          DEPARTMENT_MANAGER
        ])
      },
      {
        property: 'department_name',
        label: 'Department name',
        sortable: true,
        displayStatus: this.context.hasRole([
          SUPER_ADMIN,
          ADMIN,
          DIRECTOR,
          TELEMARKETER
        ])
      },
      {
        property: 'period',
        label: 'Period',
        sortable: false
      },
      {
        property: 'current_estimation_percent',
        label: 'Estimated target achievement level',
        sortable: false,
        nullable: true,
        render: renderEstimationPercent
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: this.context.hasPermission([TARGET_LIST]),
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([TARGET_UPDATE]),
            disable: ({ result_value }) => result_value || result_value === 0,
            disableMsg: 'Target is closed',
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([TARGET_DELETE]),
            disable: ({ result_value }) => result_value || result_value === 0,
            disableMsg: 'Target is closed',
            onClick: this.props.onDeleteClick
          },
          copy: {
            status: this.context.hasPermission([TARGET_UPDATE]),
            onClick: this.props.onCopyClick
          }
        }
      }
    ];
  }

  render() {
    const { targets, sort, setSort, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={targets}
        sort={sort}
        setSort={setSort}
        loading={loading}
        actions
      />
    );
  }
}

TargetsTable.defaultProps = {
  targets: []
};

TargetsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  targets: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  onCopyClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default withRouter(TargetsTable);
