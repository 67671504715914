import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import SalesGroupFileUpdateForm from 'modules/SalesGroups/component/Files/Update/Form';
import SalesGroupsFilesApi from 'api/connections/SalesGroups/SalesGroupsFilesApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import insertPathParams from 'api/utils/insertPathParams';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  ROUTE_SHOPPING_GROUPS_DETAILS,
  ROUTE_SHOPPING_GROUPS_LIST
} from 'routing/routes/ShoppingGroups';

class SalesGroupFileUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.groupId = parseInt(this.props.match.params.groupId, 10);
    this.fileId = parseInt(this.props.match.params.fileId, 10);
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      salesGroupFile: null
    };
  }

  componentDidMount() {
    if (!this.groupId || !this.fileId) {
      this.props.history.push(ROUTE_SHOPPING_GROUPS_LIST);
    }
    this.setCurrentPage('Update shopping group file');
    this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: { data: files }
      } = await SalesGroupsFilesApi.getSalesGroupFiles(this.groupId);

      const salesGroupFile = files.find(file => file.id === this.fileId);

      if (
        !salesGroupFile ||
        (salesGroupFile && salesGroupFile.creator_id !== this.context.user.id)
      ) {
        let path = insertPathParams(ROUTE_SHOPPING_GROUPS_DETAILS, {
          id: this.groupId
        });
        path += '?tab_index=general_data';
        this.props.history.push(path);
      }

      this.setState({ salesGroupFile });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);

        if (err instanceof NotFoundApiError) {
          let path = insertPathParams(ROUTE_SHOPPING_GROUPS_DETAILS, {
            id: this.groupId
          });
          path += '?tab_index=general_data';
          this.props.history.push(path);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { salesGroupFile } = this.state;
    if (!salesGroupFile) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <SalesGroupFileUpdateForm salesGroupFile={salesGroupFile} />
          </Paper>
        </Box>
      </Box>
    );
  }
}

SalesGroupFileUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(SalesGroupFileUpdate);
