import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_CLIENTS_DETAILS,
  ROUTE_CLIENTS_UPDATE
} from 'routing/routes/Clients';
import { CLIENTS_UPDATE } from 'api/auth/permissions/Clients';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import renderAddress from 'modules/Clients/utils/renderAddress';

class ClientsTable extends Component {
  static contextType = AuthContext;

  static renderPotential(evaluation) {
    const departmentsEvaluations = evaluation.map(
      ({ potential_evaluation, department_name, id }) => ({
        potential_evaluation,
        department_name,
        id
      })
    );

    return departmentsEvaluations.map(
      ({ potential_evaluation, department_name, id }) => (
        <div key={`${department_name}-${potential_evaluation}-${id}`}>
          {department_name}: {potential_evaluation}
        </div>
      )
    );
  }

  constructor(props) {
    super(props);

    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_CLIENTS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_CLIENTS_UPDATE, { id }));
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'recipient_number',
        label: 'Recipient number',
        sortable: false
      },
      {
        property: 'tax_number',
        label: 'Tax number',
        sortable: true
      },
      {
        property: 'id',
        label: 'Address',
        sortable: false,
        render: (_, row) => renderAddress(row, this.props.countries)
      },
      {
        property: 'sales_group_name',
        label: 'Shopping group',
        sortable: false
      },
      {
        property: 'potential_evaluation',
        label: 'Client category',
        sortable: true,
        render: ClientsTable.renderPotential
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasPermission([CLIENTS_UPDATE]),
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: true,
            disable: ({ can_update }) => !can_update,
            disableMsg:
              'The client is not assigned to you or to your subordinate',
            onClick: this.onUpdateClick
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          }
        }
      }
    ];
  }

  render() {
    const { clients, sort, setSort, loading } = this.props;
    const { hasPermission } = this.context;

    return (
      <Table
        cols={this.getCols()}
        rows={clients}
        sort={sort}
        setSort={setSort}
        hover
        actions={hasPermission([CLIENTS_UPDATE])}
        loading={loading}
      />
    );
  }
}

ClientsTable.defaultProps = {
  clients: [],
  countries: []
};

ClientsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  loading: PropTypes.bool.isRequired
};

export default withRouter(ClientsTable);
