import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import { SUPER_ADMIN } from 'api/auth/roles';
import {
  WHOLESALERS_UPDATE,
  WHOLESALERS_DELETE
} from 'api/auth/permissions/Wholesalers';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_WHOLESALERS_DETAILS,
  ROUTE_WHOLESALERS_UPDATE
} from 'routing/routes/Wholesalers';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DeleteWholesalerDialog from 'modules/Wholesalers/component/DeleteWholesalerDialog';

class WholesalersTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
      wholesalerToDelete: null
    };

    this.renderCountry = this.renderCountry.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_WHOLESALERS_DETAILS, { id })
    );
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_WHOLESALERS_UPDATE, { id }));
  }

  onDeleteClick(wholesaler) {
    this.setState({ deleteDialogOpen: true, wholesalerToDelete: wholesaler });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, wholesalerToDelete: null });
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'tax_number',
        label: 'Tax number',
        sortable: true
      },
      {
        property: 'phone',
        label: 'Phone number',
        sortable: true
      },
      {
        property: 'main_contact_email',
        label: 'Email',
        sortable: true
      },
      {
        property: 'country_id',
        label: 'Country',
        sortable: true,
        render: this.renderCountry,
        displayStatus: this.context.hasRole([SUPER_ADMIN])
      },
      {
        property: 'city',
        label: 'City',
        sortable: false
      },
      {
        property: 'street',
        label: 'Street',
        sortable: false
      },
      {
        property: 'postal_code',
        label: 'Postal code',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([WHOLESALERS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([WHOLESALERS_DELETE]),
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  renderCountry(id) {
    if (id) {
      const country = this.props.countries.find(item => item.id === id);
      return country?.name;
    }
    return '';
  }

  render() {
    const { deleteDialogOpen, wholesalerToDelete } = this.state;
    const {
      wholesalers,
      sort,
      setSort,
      fetchWholesalers,
      loading
    } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={wholesalers}
          sort={sort}
          setSort={setSort}
          hover
          actions
          loading={loading}
        />
        {deleteDialogOpen && (
          <DeleteWholesalerDialog
            wholesaler={wholesalerToDelete}
            onClose={this.onDeleteDialogClose}
            onSuccess={fetchWholesalers}
          />
        )}
      </>
    );
  }
}

WholesalersTable.defaultProps = {
  countries: [],
  wholesalers: []
};

WholesalersTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  wholesalers: PropTypes.arrayOf(PropTypes.shape({})),
  fetchWholesalers: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  loading: PropTypes.bool.isRequired
};

export default withRouter(WholesalersTable);
