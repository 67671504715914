import { Component } from 'react';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import PropTypes from 'prop-types';
import Loader from 'modules/Layout/component/Loader';
import { isEmpty } from 'lodash/lang';
import capitalize from 'modules/Shared/utils/capitalize';
import ClientsTreePostalCodes from 'modules/Clients/component/ClientsTree/PostalCodes';

class ClientsTreeCities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataToRender: undefined
    };
  }

  async componentDidMount() {
    const { parentId, parentIdPropName } = (() => {
      if (this.props.subsubregionId)
        return {
          parentId: this.props.subsubregionId,
          parentIdPropName: 'subsubregion_id'
        };
      if (this.props.subregionId)
        return {
          parentId: this.props.subregionId,
          parentIdPropName: 'subregion_id'
        };
      if (this.props.regionId)
        return {
          parentId: this.props.regionId,
          parentIdPropName: 'region_id'
        };
    })();

    const dataToRender = await this.props.getDataToRender(
      'cities',
      parentId,
      parentIdPropName
    );

    this.setState({ dataToRender });
  }

  render() {
    const { dataToRender } = this.state;
    const { selectMode, isParentSelected } = this.props;

    if (dataToRender === undefined) return <Loader />;

    if (isEmpty(dataToRender)) return null;

    return dataToRender.map(city => {
      const isVisible = this.props.isVisible('cities', city.id);
      const isSelected =
        isParentSelected || this.props.isSelected('cities', city.id);

      if (!isVisible && !isSelected && !selectMode) return null;

      const item = {
        postalCodeLevel: 'cities',
        region_id: this.props.regionId,
        subregion_id: this.props.subregionId,
        subsubregion_id: this.props.subsubregionId,
        ...city
      };

      return (
        <TreeItem
          itemData={item}
          key={`city-${city.id}`}
          nodeId={`city-${city.id}`}
          labelText={capitalize(city.name)}
          labelInfo={
            selectMode ? city.clients_number : city.assigned_clients_number
          }
          selectMode={selectMode}
          isSelected={isSelected}
          onSelect={this.props.onSelect}
          actionBtn={
            !selectMode && isSelected && this.props.renderDeleteItemBtn(item)
          }
        >
          <ClientsTreePostalCodes
            regionId={this.props.regionId}
            subregionId={this.props.subregionId}
            subsubregionId={this.props.subsubregionId}
            cityId={city.id}
            getDataToRender={this.props.getDataToRender}
            isVisible={this.props.isVisible}
            isParentSelected={isSelected}
            isSelected={this.props.isSelected}
            selectMode={selectMode}
            onSelect={this.props.onSelect}
            renderDeleteItemBtn={this.props.renderDeleteItemBtn}
          />
        </TreeItem>
      );
    });
  }
}

ClientsTreeCities.defaultProps = {
  subregionId: null,
  subsubregionId: null
};

ClientsTreeCities.propTypes = {
  regionId: PropTypes.number.isRequired,
  subregionId: PropTypes.number,
  subsubregionId: PropTypes.number,
  getDataToRender: PropTypes.func.isRequired,
  isVisible: PropTypes.func.isRequired,
  isParentSelected: PropTypes.bool.isRequired,
  isSelected: PropTypes.func.isRequired,
  selectMode: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  renderDeleteItemBtn: PropTypes.func.isRequired
};

export default ClientsTreeCities;
