import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Button from 'modules/Layout/component/Button';
import Input from 'modules/Layout/component/Input';
import { Box } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import { validate } from 'modules/Shared/utils/validator';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import WholesalersMappingsApi from 'api/connections/Wholesalers/WholesalersMappingsApi';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

class ProductWholesalersMappingsCreateForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        wholesalerId: '',
        product_id: props.productId,
        external_identifier: ''
      },
      validation: {
        wholesalerId: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Wholesaler')
          })
        },
        external_identifier: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('External identifier')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(
      insertPathParams(ROUTE_PRODUCTS_DETAILS, { id: this.props.productId })
    );
  }

  handleError(err) {
    const { setAlert } = this.context;
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      this.setState(({ validation: validationState }) => {
        return {
          validation: { ...validationState, ...newValidateState }
        };
      });
    }
    setAlert(message);
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(prevState => {
      const { formData, validation } = prevState;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onAutocompleteChange(event, value) {
    this.setState(prevState => {
      const { formData, validation } = prevState;

      return {
        formData: {
          ...formData,
          wholesalerId: value ? value.key : ''
        },
        validation: {
          ...validation,
          wholesalerId: {
            ...validation.wholesalerId,
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const {
      formData,
      formData: { wholesalerId }
    } = this.state;
    this.setState({ loading: true });
    try {
      await WholesalersMappingsApi.createWholesalerMapping(
        wholesalerId,
        formData
      );
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { external_identifier, wholesalerId },
      validation,
      loading
    } = this.state;
    const { wholesalers, productId, sku } = this.props;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <TextDataSet label='Name' data={sku} />
        <Autocomplete
          label='Wholesaler'
          name='wholesalerId'
          value={wholesalerId}
          required
          options={formatOptions(wholesalers, 'id', 'name')}
          onChange={this.onAutocompleteChange}
          errorStatus={validation.wholesalerId.status}
          errorText={validation.wholesalerId.message}
        />
        <Input
          name='external_identifier'
          label='External identifier'
          required
          value={external_identifier}
          onChange={this.onChange}
          errorStatus={validation.external_identifier.status}
          errorText={validation.external_identifier.message}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Link
            to={insertPathParams(ROUTE_PRODUCTS_DETAILS, {
              id: productId
            })}
            className='router-button'
          >
            <Button text={t('Cancel')} />
          </Link>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

ProductWholesalersMappingsCreateForm.propTypes = {
  productId: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  wholesalers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(ProductWholesalersMappingsCreateForm);
