import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Tab } from '@material-ui/core';
import TabPanel from 'modules/Layout/component/TabPanel';
import Tabs from 'modules/Layout/component/Tabs';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';

import t from 'translate/translate';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  ROUTE_CLIENTS_LIST,
  ROUTE_INTERNET_CLIENTS_DETAILS
} from 'routing/routes/Clients';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';

import ClientDetailsBasicData from 'modules/Clients/view/Details/BasicData';
import ClientDetailsFiles from 'modules/Clients/view/Details/Files';
import ClientDetailsNotes from 'modules/Clients/view/Details/Notes';
import UpcomingVisits from 'modules/Clients/component/Details/Visits/UpcomingVisits';
import VisitsHistory from 'modules/Clients/component/Details/Visits/VisitsHistory';
import ClientDetailsDiscounts from 'modules/Clients/view/Details/Discounts';
import ClientDetailsOrders from 'modules/Clients/view/Details/Orders';
import ClientDetailsWholesalersMappings from 'modules/Clients/view/Details/WholesalersMappings/List';
import ClientDetailsNewProducts from 'modules/Clients/view/Details/NewProducts';
import ClientTopSellers from 'modules/Clients/view/Details/TopSellers';
import ClientComments from 'modules/Clients/view/Details/Comments';
import ClientsDetailsPriceLists from 'modules/Clients/view/Details/PriceLists';
import Loader from 'modules/Layout/component/Loader';
import { WHOLESALERS_UPDATE } from 'api/auth/permissions/Wholesalers';
import insertPathParams from 'api/utils/insertPathParams';

class ClientDetailsWrapper extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.clientId = parseInt(props.match.params.id, 10);

    this.state = {
      client: undefined,
      countries: undefined,
      isUserAssignToClient: false,
      isSubordinateAssignToClient: false
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.isInternetClientPath = props.location.pathname.includes(
      'internet_clients'
    );

    this.onContactDeleteSuccess = this.onContactDeleteSuccess.bind(this);
    this.onAccountDeleteSuccess = this.onAccountDeleteSuccess.bind(this);
    this.fetchClient = this.fetchClient.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    if (!this.clientId) {
      this.props.history.push(ROUTE_CLIENTS_LIST);
    }

    this.setCurrentPage(
      this.isInternetClientPath ? 'Internet client details' : 'Client details'
    );
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.setAlert(message);
      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_CLIENTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onContactDeleteSuccess(contactId) {
    this.setState(prevState => {
      return {
        ...prevState,
        client: {
          ...prevState.client,
          contacts: prevState.client.contacts.filter(
            contact => contact.id !== contactId
          )
        }
      };
    });
  }

  onAccountDeleteSuccess() {
    this.setState(prevState => {
      return {
        client: {
          ...prevState.client,
          account: null
        }
      };
    });
  }

  async fetchClient() {
    try {
      const {
        data: { data: client }
      } = await ClientsApi.getClient(this.clientId);

      if (client.is_internet && !this.isInternetClientPath) {
        return this.props.history.push(
          insertPathParams(ROUTE_INTERNET_CLIENTS_DETAILS, { id: client.id })
        );
      }

      this.setState({ client });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    try {
      let countries = [];

      const {
        data: { data: client }
      } = await ClientsApi.getClient(this.clientId);

      if (client.is_internet && !this.isInternetClientPath) {
        return this.props.history.push(
          insertPathParams(ROUTE_INTERNET_CLIENTS_DETAILS, { id: client.id })
        );
      }

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        client,
        countries
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { client, countries } = this.state;

    if (!client) return <Loader />;

    const { is_internet } = client;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Tabs>
            <Tab label={t('General data')} value='general_data' />
            <Tab label={t('Files')} value='files' />
            <Tab label={t('Notes')} value='notes' />
            {!is_internet && (
              <Tab label={t('Upcoming visits')} value='upcoming_visits' />
            )}
            {!is_internet && (
              <Tab label={t('Visits history')} value='visits_history' />
            )}
            {!is_internet && (
              <Tab
                label={t('New products since last visit')}
                value='new_products'
              />
            )}
            {!is_internet && <Tab label={t('Discounts')} value='discounts' />}
            {!is_internet && <Tab label={t('Orders')} value='orders' />}
            {this.context.hasPermission([WHOLESALERS_UPDATE]) && (
              <Tab
                label={t('Wholesalers mappings')}
                value='wholesalers_mappings'
              />
            )}
            <Tab label={t('Top sellers')} value='top_sellers' />
            <Tab label={t('Comments')} value='comments' />
            {!is_internet && (
              <Tab label={t('Price lists')} value='price_lists' />
            )}
          </Tabs>
          <TabPanel index='general_data'>
            <ClientDetailsBasicData
              client={client}
              countries={countries}
              fetchClient={this.fetchClient}
              onContactDeleteSuccess={this.onContactDeleteSuccess}
              onAccountDeleteSuccess={this.onAccountDeleteSuccess}
              setAlert={this.setAlert}
            />
          </TabPanel>
          <TabPanel index='files'>
            <ClientDetailsFiles
              updateStatus={client.can_update}
              setAlert={this.setAlert}
              setCurrentPage={this.setCurrentPage}
            />
          </TabPanel>
          <TabPanel index='notes'>
            <ClientDetailsNotes
              updateStatus={client.can_update}
              setAlert={this.setAlert}
            />
          </TabPanel>
          <TabPanel index='upcoming_visits'>
            <UpcomingVisits clientId={this.clientId} setAlert={this.setAlert} />
          </TabPanel>
          <TabPanel index='visits_history'>
            <VisitsHistory clientId={this.clientId} setAlert={this.setAlert} />
          </TabPanel>
          <TabPanel index='new_products'>
            <ClientDetailsNewProducts
              client={client}
              setAlert={this.setAlert}
            />
          </TabPanel>
          <TabPanel index='discounts'>
            <ClientDetailsDiscounts
              updateStatus={client.can_update}
              salesGroupId={client.sales_group_id}
              clientName={client.name}
              setAlert={this.setAlert}
            />
          </TabPanel>
          <TabPanel index='orders'>
            <ClientDetailsOrders />
          </TabPanel>
          {this.context.hasPermission([WHOLESALERS_UPDATE]) && (
            <TabPanel index='wholesalers_mappings'>
              <ClientDetailsWholesalersMappings client={client} />
            </TabPanel>
          )}
          <TabPanel index='top_sellers'>
            <ClientTopSellers client={client} setAlert={this.setAlert} />
          </TabPanel>
          <TabPanel index='comments'>
            <ClientComments client={client} setAlert={this.setAlert} />
          </TabPanel>
          <TabPanel index='price_lists'>
            <ClientsDetailsPriceLists
              client={client}
              setAlert={this.setAlert}
              handleError={this.handleError}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

ClientDetailsWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ClientDetailsWrapper);
