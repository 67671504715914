import { Component } from 'react';
import PropTypes from 'prop-types';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Input from 'modules/Layout/component/Input';
import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import WholesalersImportColumnsApi from 'api/connections/Wholesalers/WholesalersImportColumnsApi';
import Loader from 'modules/Layout/component/Loader';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import { Block as BlockIcon } from '@material-ui/icons';

class WholesalersColumnsMapping extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      columns: undefined,
      formData: undefined,
      loading: false,
      updateStatus: false
    };

    this.fetchColumns = this.fetchColumns.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onUpdateCancelClick = this.onUpdateCancelClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchColumns();
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Mapping saved'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.setState(state => {
      return {
        updateStatus: false,
        columns: state.formData,
        formData: undefined
      };
    });
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onUpdateClick() {
    this.setState(state => {
      return {
        updateStatus: true,
        formData: state.columns
      };
    });
  }

  onUpdateCancelClick() {
    this.setState({
      updateStatus: false,
      formData: undefined
    });
  }

  onChange(e) {
    this.setState(state => {
      const { formData } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        }
      };
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    await this.makeApiCall();
  }

  async makeApiCall() {
    const { formData } = this.state;

    try {
      this.setState({ loading: true });

      await WholesalersImportColumnsApi.updateWholesalersImportColumns(
        this.props.wholesaler.id,
        formData
      );

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  async fetchColumns() {
    const { id: wholesaler_id } = this.props.wholesaler;

    try {
      this.setState({ loading: true });

      const {
        data: { data }
      } = await WholesalersImportColumnsApi.getWholesalersImportColumns({
        wholesaler_id
      });

      this.setState({
        columns: data[0],
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderColumnSet(label, name, defaults) {
    const { columns, formData, updateStatus } = this.state;

    return (
      <Box mb={2}>
        <Typography style={{ fontWeight: 600 }}>{t(label)}</Typography>
        <Typography>{`${t(
          'Default values'
        )}: ${defaults.toUpperCase()}`}</Typography>
        {updateStatus ? (
          <Input
            name={name}
            label='Custom value'
            value={formData[name]}
            onChange={this.onChange}
          />
        ) : (
          <Typography>{`${t('Custom value')}: ${
            columns[name] ?? ''
          }`}</Typography>
        )}
      </Box>
    );
  }

  render() {
    const { columns, loading, updateStatus } = this.state;

    if (!columns || loading) return <Loader />;

    return (
      <Grid container justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <Box width={1} display='flex' flexDirection='row-reverse' mb={2}>
              {updateStatus ? (
                <>
                  <IconButton
                    className='impersonate-icon'
                    onClick={this.onSubmit}
                    icon={<SaveIcon />}
                    alt='save'
                  />
                  <IconButton
                    className='delete-icon'
                    onClick={this.onUpdateCancelClick}
                    icon={<BlockIcon />}
                    alt='cancel'
                  />
                </>
              ) : (
                <IconButton
                  className='update-icon'
                  onClick={this.onUpdateClick}
                  icon={<EditIcon />}
                  alt='update'
                />
              )}
            </Box>

            {this.renderColumnSet(
              'Client number',
              'client_number',
              'numer klienta, klient id, id klienta'
            )}
            {this.renderColumnSet(
              'Client name',
              'client_name',
              'nazwa klienta'
            )}
            {this.renderColumnSet('Tax number', 'tax_number', 'numer nip, nip')}
            {this.renderColumnSet('Postal code', 'postal_code', 'kod pocztowy')}
            {this.renderColumnSet('City', 'city', 'miasto')}
            {this.renderColumnSet('Street', 'street', 'ulica')}
            {this.renderColumnSet('Buy date', 'buy_date', 'data zakupu, data')}
            {this.renderColumnSet(
              'Product code',
              'product_code',
              'kod produktu, produkt id, id produktu'
            )}
            {this.renderColumnSet(
              'Product',
              'product',
              'produkt, nazwa produktu'
            )}
            {this.renderColumnSet(
              'Price',
              'price',
              'cena produktu netto, cena, cena produktu, cena netto'
            )}
            {this.renderColumnSet(
              'Quantity',
              'quantity',
              'liczba opakowań, liczba, ilość, ilosc, liczba opakowan'
            )}
            {this.renderColumnSet(
              'Price sum',
              'price_sum',
              'łączna kwota netto, laczna kwota netto, razem'
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

WholesalersColumnsMapping.propTypes = {
  wholesaler: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default WholesalersColumnsMapping;
