import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import ClientContactUpdateForm from 'modules/Clients/component/Details/Contacts/Update/Form';
import { withRouter } from 'react-router-dom';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_CLIENTS_DETAILS,
  ROUTE_CLIENTS_LIST
} from 'routing/routes/Clients';

class ClientContactUpdate extends Component {
  constructor(props) {
    super(props);
    this.clientId = parseInt(this.props.match.params.clientId, 10);
    this.contactId = parseInt(this.props.match.params.contactId, 10);

    this.state = {
      clientContact: null
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  componentDidMount() {
    if (!this.clientId || !this.contactId) {
      this.props.history.push(ROUTE_CLIENTS_LIST);
    }
    this.setCurrentPage('Update client contact');
    this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: {
          data: { contacts }
        }
      } = await ClientsApi.getClient(this.clientId);

      const clientContact = contacts.find(
        contact => contact.id === this.contactId
      );

      if (!clientContact) {
        this.props.history.push(
          insertPathParams(ROUTE_CLIENTS_DETAILS, { id: this.clientId })
        );
      }

      this.setState({ clientContact });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
        if (err instanceof NotFoundApiError) {
          this.props.history.push(
            insertPathParams(ROUTE_CLIENTS_DETAILS, {
              id: this.clientId
            })
          );
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { clientContact } = this.state;
    if (!clientContact) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ClientContactUpdateForm
              clientId={this.clientId}
              contactId={this.contactId}
              clientContact={clientContact}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ClientContactUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      clientId: PropTypes.string.isRequired,
      contactId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ClientContactUpdate);
