import { useState } from 'react';
import PropTypes from 'prop-types';
import DayBox from 'modules/Visits/components/Calendar/DayBox';
import { Box } from '@material-ui/core';
import useStyles from 'modules/Visits/components/Calendar/DaysWrapper/styles';
import CalendarModal from 'modules/Visits/components/Calendar/VisitsListModal';

const DaysWrapper = ({ days }) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [chosenDay, setChosenDay] = useState({});

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
    setChosenDay({});
  };

  const handleChooseDay = day => {
    setChosenDay(day);
  };

  return (
    <>
      <Box className={classes.daysWrapper}>
        {days.map(day => (
          <DayBox
            key={day.id}
            day={day}
            handleChooseDay={handleChooseDay}
            onModalOpenFn={onModalOpen}
          />
        ))}
      </Box>
      {modalOpen && (
        <CalendarModal
          onClose={onModalClose}
          open={modalOpen}
          chosenDay={chosenDay}
        />
      )}
    </>
  );
};

DaysWrapper.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({ fullDate: PropTypes.instanceOf(Date) })
  ).isRequired
};

export default DaysWrapper;
