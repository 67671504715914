import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import TaxesApi from 'api/connections/Taxes/TaxesApi';
import ProductsApi from 'api/connections/Products/ProductsApi';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import ProductUpdateForm from 'modules/Products/component/Update/Form';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { ROUTE_PRODUCTS_LIST } from 'routing/routes/Products';
import { withRouter } from 'react-router-dom';

class ProductUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { id: productId } = props.match.params;
    this.state = {
      productId,
      product: null,
      countries: [],
      categories: [],
      taxes: []
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  async componentDidMount() {
    if (!this.state.productId) {
      this.props.history.push(ROUTE_PRODUCTS_LIST);
    }
    this.setCurrentPage('Product update');
    await this.fetchData();
  }

  async fetchData() {
    try {
      let countries = [];
      const [
        { data: categories },
        {
          data: { data: taxes }
        },
        {
          data: { data: product }
        }
      ] = await Promise.all([
        ProductCategoriesApi.getProductCategories(),
        TaxesApi.getTaxes(),
        ProductsApi.getProduct(this.state.productId)
      ]);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        product,
        countries,
        taxes,
        categories
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { product, countries, categories, taxes } = this.state;
    if (!product) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ProductUpdateForm
              product={product}
              countries={countries}
              categories={categories}
              taxes={taxes}
              setAlert={this.setAlert}
              authContext={this.context}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ProductUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ProductUpdate);
