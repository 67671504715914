import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  header: {
    height: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  breadcrumb: {
    background: '#eaeff1',
    borderBottom: '1px solid #bdbdbd'
  },
  menuButton: {
    marginLeft: -theme.spacing(1)
  }
}));

export default useStyles;
