import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import { Box } from '@material-ui/core';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import insertPathParams from 'api/utils/insertPathParams';
import { PRODUCT_MANAGER, SUPER_ADMIN } from 'api/auth/roles';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import {
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCTS_UPDATE
} from 'routing/routes/Products';
import {
  PRODUCTS_UPDATE,
  PRODUCTS_DELETE
} from 'api/auth/permissions/Products';
import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import priorityOptions from 'modules/Products/priorityTypes';

import DeleteProductDialog from 'modules/Products/component/DeleteProductDialog';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import renderPrice from 'modules/Products/utils/renderPrice';

class ProductDetailsBasicDataSets extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      productDeleteDialogStatus: false
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onProductDeleteSuccess = this.onProductDeleteSuccess.bind(this);
  }

  onDeleteClick() {
    this.setState({ productDeleteDialogStatus: true });
  }

  onEditClick() {
    const { id } = this.props.product;

    this.props.history.push(insertPathParams(ROUTE_PRODUCTS_UPDATE, { id }));
  }

  onDeleteDialogClose() {
    this.setState({ productDeleteDialogStatus: false });
  }

  onProductDeleteSuccess() {
    this.props.history.push(ROUTE_PRODUCTS_LIST);
  }

  formatSkuData() {
    const { hasRole } = this.context;
    const {
      product: { sku, all_sku },
      countries
    } = this.props;

    if (hasRole([SUPER_ADMIN])) {
      return all_sku.map(skuObj => {
        const { country_id: countryId, value } = skuObj;
        return `${countries.find(country => country.id === countryId).name}: ${
          value || t('No data')
        }`;
      });
    }

    return sku;
  }

  formatPricesData() {
    const {
      hasRole,
      user: { country_id: userCountryId }
    } = this.context;

    const {
      product: { prices },
      countries
    } = this.props;

    if (hasRole([SUPER_ADMIN])) {
      return prices.map(priceObj => {
        const { country_id: countryId, price, currency } = priceObj;
        const countryName = countries.find(country => country.id === countryId)
          .name;

        return `${countryName}: ${renderPrice(price, currency)}`;
      });
    }

    const resultPrice = prices.find(
      price => price.country_id === userCountryId
    );

    return resultPrice
      ? renderPrice(resultPrice.price, resultPrice.currency)
      : t('No data');
  }

  renderTaxes() {
    const {
      hasRole,
      user: { country_id: userCountryId }
    } = this.context;
    const {
      countries,
      taxes: taxesData,
      product: { taxes: taxesToRender }
    } = this.props;

    if (!taxesToRender) return null;

    if (hasRole([SUPER_ADMIN, PRODUCT_MANAGER])) {
      return taxesToRender.map(tax => {
        const { country_id: taxCountryId, tax_id: taxId } = tax;

        const { name: countryName } = countries.find(
          country => country.id === taxCountryId
        );
        const taxName = taxesData.find(
          taxData => taxData.id === taxId && taxData.country_id === taxCountryId
        )?.name;

        return `${countryName}: ${taxName || t('No data')}`;
      });
    }

    let taxesDataObj = null;

    const taxObj = taxesToRender.find(tax => tax.country_id === userCountryId);
    if (taxObj) {
      taxesDataObj = taxesData.find(
        tax => tax.id === taxObj.tax_id && tax.country_id === userCountryId
      );
    }

    return taxesDataObj ? taxesDataObj.name : '';
  }

  renderCountries() {
    const {
      product: { countries: productCountries },
      countries
    } = this.props;

    if (!productCountries) return t('Globally available');

    return productCountries.map(
      productCountry =>
        countries.find(country => country.id === productCountry)?.name
    );
  }

  render() {
    const { productDeleteDialogStatus } = this.state;
    const {
      product: {
        id,
        sku,
        ean,
        group_name,
        category_name,
        subcategory_name,
        index,
        sale_start_date,
        countries,
        direct_order_only,
        priority,
        can_delete,
        promotions_blocked
      }
    } = this.props;

    return (
      <>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <TextDataSet label='Name' data={this.formatSkuData()} />
            {priority && (
              <TextDataSet
                label='Priority'
                data={priorityOptions.find(p => p.id === priority).icon}
              />
            )}
            <TextDataSet label='EAN' data={ean} />
            <TextDataSet label='Product group' data={group_name} />
            <TextDataSet label='Category' data={category_name} />
            <TextDataSet label='Subcategory' data={subcategory_name} />
            <TextDataSet label='Index' data={index} />
            <TextDataSet label='Price' data={this.formatPricesData()} />
            <TextDataSet label='Taxes' data={this.renderTaxes()} />
            {!isEmpty(countries) && (
              <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
                <TextDataSet
                  label='Only available in countries'
                  data={this.renderCountries()}
                />
              </Authorize>
            )}
            <TextDataSet label='Date of sale' data={sale_start_date} />
            <TextDataSet
              data={
                direct_order_only ? (
                  <CheckIcon color='primary' />
                ) : (
                  <BlockIcon color='error' />
                )
              }
              label='Product available only in direct orders'
            />
            <TextDataSet
              data={
                promotions_blocked ? (
                  <CheckIcon color='primary' />
                ) : (
                  <BlockIcon color='error' />
                )
              }
              label='Product unavailable in promotions'
            />
          </Box>
          <Box display='flex' alignItems='flex-start'>
            <Authorize permissions={[PRODUCTS_UPDATE]}>
              <IconButton
                className='update-icon'
                onClick={this.onEditClick}
                icon={<EditIcon />}
                alt='update'
              />
            </Authorize>
            <Authorize permissions={[PRODUCTS_DELETE]}>
              <IconButton
                className='delete-icon'
                onClick={this.onDeleteClick}
                icon={<DeleteForeverIcon />}
                alt='delete'
                disabled={!can_delete}
                tooltipMsg='You cannot delete product when there are open sales targets for that product.'
              />
            </Authorize>
          </Box>
        </Box>
        {productDeleteDialogStatus && (
          <DeleteProductDialog
            product={{ id, sku }}
            onClose={this.onDeleteDialogClose}
            onSuccess={this.onProductDeleteSuccess}
          />
        )}
      </>
    );
  }
}

ProductDetailsBasicDataSets.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.shape({}),
    ean: PropTypes.string,
    group_name: PropTypes.string.isRequired,
    category_name: PropTypes.string.isRequired,
    subcategory_name: PropTypes.string.isRequired,
    index: PropTypes.string,
    sku: PropTypes.string.isRequired,
    prices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    taxes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    sale_start_date: PropTypes.string.isRequired,
    all_sku: PropTypes.arrayOf(PropTypes.shape({})),
    direct_order_only: PropTypes.oneOf([0, 1]).isRequired,
    priority: PropTypes.number,
    can_delete: PropTypes.bool.isRequired,
    promotions_blocked: PropTypes.bool.isRequired
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  taxes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ProductDetailsBasicDataSets);
