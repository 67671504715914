import BaseApiConfig from 'api/connections/BaseApiConfig';

export const authorizationReqData = {
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET
};

class AuthApi extends BaseApiConfig {
  static formatAuthorizationReq(reqData) {
    return {
      grant_type: 'password',
      ...authorizationReqData,
      ...reqData
    };
  }

  constructor(props) {
    super(props);

    this.tokenPath = 'auth/token';
    this.resetPasswordPath = 'auth/password/send';
    this.changePasswordPath = 'auth/password/change';
    this.impersonatePath = 'auth/impersonate';
  }

  login(data) {
    const payload = AuthApi.formatAuthorizationReq(data);
    return this.post(this.tokenPath, payload);
  }

  resetPassword(payload) {
    return this.post(this.resetPasswordPath, payload);
  }

  changePassword(payload) {
    return this.post(this.changePasswordPath, payload);
  }

  impersonate(payload) {
    return this.post(this.impersonatePath, payload);
  }
}

export default new AuthApi();
