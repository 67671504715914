import { Box } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t, { availableLocals } from 'translate/translate';
import { PRODUCT_MANAGER, roles } from 'api/auth/roles';
import Authorize from 'modules/Auth/component/Authorize';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { USERS_DELETE, USERS_UPDATE } from 'api/auth/permissions/Users';
import IconButton from 'modules/Layout/component/IconButton';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_USERS_UPDATE } from 'routing/routes/Users';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { isEmpty } from 'lodash/lang';
import { useContext } from 'react';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const UserDataSets = props => {
  const {
    user,
    countries,
    updateActionDisableStatus,
    updateActionDisableMsg,
    onDeleteClick
  } = props;

  const { user: loggedUser } = useContext(AuthContext);
  const history = useHistory();

  const getCountry = () => {
    if (!user.country_id) return null;

    const result = countries.find(country => country.id === user.country_id);

    return result && result.name;
  };

  const getDepartments = () => {
    if (isEmpty(user.departments)) return null;

    return user.departments.map(department => department.name).join(', ');
  };

  const setDeleteDisableStatus = () => {
    const { id, can_delete } = user;
    const { id: loggedUserId } = loggedUser;

    return id === loggedUserId || !can_delete;
  };

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box>
        <TextDataSet label='First name' data={user.first_name} />
        <TextDataSet label='Last name' data={user.last_name} />
        <TextDataSet label='Email' data={user.email} />
        <TextDataSet label='Phone number' data={user.phone} />
        <TextDataSet
          label='Language'
          data={t(
            availableLocals.find(lang => lang.slug === user.language).name
          )}
        />
        <TextDataSet
          label='Role'
          data={t(roles.find(role => role.slug === user.role).name)}
        />
        <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
          {user.role !== PRODUCT_MANAGER && (
            <TextDataSet label='Country' data={getCountry()} />
          )}
        </Authorize>
        <TextDataSet label='Departments' data={getDepartments()} />
      </Box>
      <Box display='flex' alignItems='flex-start'>
        <Authorize permissions={[USERS_UPDATE]}>
          <IconButton
            className='update-icon'
            onClick={() =>
              history.push(
                insertPathParams(ROUTE_USERS_UPDATE, {
                  id: user.id
                })
              )
            }
            icon={<EditIcon />}
            alt='update'
            disabled={updateActionDisableStatus}
            tooltipMsg={updateActionDisableMsg}
          />
        </Authorize>
        <Authorize permissions={[USERS_DELETE]}>
          <IconButton
            className='delete-icon'
            onClick={() => onDeleteClick()}
            icon={<DeleteForeverIcon />}
            disabled={setDeleteDisableStatus()}
            tooltipMsg='You cannot delete Your own account, or user with open targets.'
            alt='delete'
          />
        </Authorize>
      </Box>
    </Box>
  );
};

UserDataSets.defaultProps = {
  updateActionDisableMsg: null
};

UserDataSets.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.number,
    language: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    country_id: PropTypes.number,
    departments: PropTypes.arrayOf(PropTypes.shape({})),
    can_delete: PropTypes.bool.isRequired
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  updateActionDisableStatus: PropTypes.bool.isRequired,
  updateActionDisableMsg: PropTypes.string
};

export default UserDataSets;
