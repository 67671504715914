import ListIcon from '@material-ui/icons/List';
import { ROUTE_CLIENTS_CONTRACTS_LIST } from 'routing/routes/ClientsContracts';
import { CLIENT } from 'api/auth/roles';

const menu = [
  {
    key: 'menu-contracts-list',
    label: 'Contracts',
    icon: <ListIcon />,
    path: ROUTE_CLIENTS_CONTRACTS_LIST,
    roles: [CLIENT]
  }
];

export default menu;
