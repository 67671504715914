import PropTypes from 'prop-types';
import {
  Box,
  Button as MaterialUiButton,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import useStyles from 'modules/Layout/component/Button/style';
import t from 'translate/translate';

const Button = props => {
  const classes = useStyles();
  const {
    type,
    form,
    fullWidth,
    color,
    text,
    loading,
    onClick,
    disabled,
    tooltipMsg,
    style,
    alt
  } = props;

  const btn = (
    <Tooltip title={t(alt)}>
      <MaterialUiButton
        form={form}
        type={type}
        fullWidth={fullWidth}
        variant='contained'
        color={color}
        className={classes.button}
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        {text}
        {loading && (
          <CircularProgress
            color='inherit'
            size={20}
            className={classes.progress}
          />
        )}
      </MaterialUiButton>
    </Tooltip>
  );

  const btnWithTooltip = (
    <Tooltip title={t(tooltipMsg)}>
      <Box>{btn}</Box>
    </Tooltip>
  );

  if (disabled && tooltipMsg) return btnWithTooltip;

  return btn;
};

Button.defaultProps = {
  form: null,
  type: 'button',
  fullWidth: false,
  color: undefined,
  text: 'Click Here!',
  loading: false,
  onClick: () => {},
  disabled: false,
  tooltipMsg: null,
  alt: '',
  style: {}
};

Button.propTypes = {
  form: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.shape({}).isRequired
};

export default Button;
