import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Map from 'modules/Visits/components/MapRoutes/Map';
import format from 'date-fns/format';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DatePicker from 'modules/Layout/component/Date/DatePicker';
import {
  PLANNED,
  CONFIRMED,
  POSTPONED
} from 'modules/Visits/utils/visitStatuses';
import PageLoader from 'modules/Layout/component/PageLoader';
import { Box } from '@material-ui/core';
import t from 'translate/translate';
import { ROUTE_VISITS_ROUTE_PLANNING } from 'routing/routes/Visits';
import Button from 'modules/Layout/component/Button';

const API_DATE_FORMAT = 'dd.MM.yyyy';

class VisitsMapRoutes extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      date: format(new Date(), API_DATE_FORMAT),
      endDate: format(new Date(), API_DATE_FORMAT),
      filter: {
        user_id: context.user.id,
        statuses: [PLANNED, CONFIRMED, POSTPONED]
      },
      sort: {
        sort_field: 'scheduled_at'
      },
      visits: [],
      loading: true
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Map routes');

    this.fetchVisits();
  }

  async onDateChange(e) {
    const { value } = e.target;
    this.setState({ date: value, endDate: value }, this.fetchVisits);
  }

  async fetchVisits() {
    const { date, endDate, filter, sort } = this.state;

    this.setState({ loading: true });

    try {
      const {
        data: { data: fetchedVisits }
      } = await VisitsApi.getVisits({
        ...filter,
        ...sort,
        per_page: Number.MAX_SAFE_INTEGER,
        date_from: date,
        date_to: endDate
      });
      this.setState({
        visits: fetchedVisits,
        loading: false
      });
    } catch (err) {
      this.setState({ loading: false });
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  render() {
    const { visits, loading } = this.state;

    return (
      <>
        <Box display='flex'>
          <DatePicker
            fullWidth={false}
            label='Date'
            onChange={this.onDateChange}
            name='Date'
          />
          <Button
            color='primary'
            text={t('Route planning')}
            onClick={() => this.props.history.push(ROUTE_VISITS_ROUTE_PLANNING)}
            style={{ marginLeft: 'auto' }}
          />
        </Box>
        {loading ? <PageLoader /> : <Map visits={visits} />}
      </>
    );
  }
}

VisitsMapRoutes.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default withRouter(VisitsMapRoutes);
