import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';
import Button from 'modules/Layout/component/Button';

const DashboardList = ({ data, renderItem, perPage }) => {
  const [showAll, setShowAll] = useState(false);

  if (isEmpty(data))
    return <Typography variant='body1'>{t('No data')}</Typography>;

  const dataToRender = showAll ? data : data.slice(0, perPage);

  const renderButton = () => {
    return (
      <Box display='flex' justifyContent='center'>
        <Button
          onClick={() => setShowAll(!showAll)}
          color='primary'
          text={t(showAll ? 'Show less' : 'Show more')}
          style={{ margin: 0, marginTop: '.4rem' }}
        />
      </Box>
    );
  };

  return (
    <Box>
      {dataToRender.map(renderItem)}
      {data.length > perPage && renderButton()}
    </Box>
  );
};

DashboardList.defaultProps = {
  perPage: 10
};

DashboardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderItem: PropTypes.func.isRequired,
  perPage: PropTypes.number
};

export default DashboardList;
