import PropTypes from 'prop-types';
import { useContext, useEffect, useState, createContext } from 'react';
import CategoryBox from 'modules/KnowledgeBase/components/CategoryBox';
import KnowledgeBaseApi from 'api/connections/KnowledgeBase/KnowledgeBaseApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { useHistory } from 'react-router-dom';
import PageLoader from 'modules/Layout/component/PageLoader';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import Button from 'modules/Layout/component/Button';
import { Box, Grid } from '@material-ui/core';
import t from 'translate/translate';
import CreateCategoryModal from 'modules/KnowledgeBase/components/CreateCategoryModal';
import UpdateCategoryModal from 'modules/KnowledgeBase/components/UpdateCategoryModal';
import SendFilesToClientModal from 'modules/KnowledgeBase/components/SendFilesToClientModal';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Dialog from 'modules/Layout/component/Dialog';
import Checkbox from 'modules/Layout/component/Checkbox';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import { isEmpty } from 'lodash/lang';
import Input from 'modules/Layout/component/Input';

export const languages = [
  { slug: 'pl', name: 'Polski' },
  { slug: 'en', name: 'English' }
];

export const KnowledgeBaseContext = createContext({});

const KnowledgeBaseView = ({ contextMethods }) => {
  const {
    user: { language: userLanguage, country_id },
    hasRole
  } = useContext(AuthContext);

  const [filters, setFilters] = useState({ language: '', note_name: '' });
  const [knowledge, setKnowledge] = useState([]);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [createCategoryModalOpen, setCreateCategoryModalOpen] = useState(false);
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false);
  const [updateCategoryModalOpen, setUpdateCategoryModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [categoryToUpdate, setCategoryToUpdate] = useState(null);
  const [sendFiles, setSendFiles] = useState(false);
  const [linksToSend, setLinksToSend] = useState([]);
  const [filesToSend, setFilesToSend] = useState([]);
  const [sendFilesModalOpen, setSendFilesModalOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState(country_id);
  const [areClientsLoading, setAreClientsLoading] = useState(false);

  const { history } = useHistory();
  const { setAlert, setCurrentPage } = contextMethods;

  const fetchKnowledgeBase = async () => {
    setIsLoading(true);
    try {
      const {
        data: { data: fetchedKnowledge }
      } = await KnowledgeBaseApi.getKnowledge(filters);
      setKnowledge(fetchedKnowledge);
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();

        setAlert(message);

        if (err instanceof NotFoundApiError) {
          history.push('/');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClients = async () => {
    if (!countryId) return;
    setAreClientsLoading(true);
    try {
      const {
        data: { data: fetchedClients }
      } = await ClientsApi.getAllClients({
        my_clients: !hasRole([ADMIN, SUPER_ADMIN, DIRECTOR]),
        country_id: countryId
      });
      setClients(fetchedClients);
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();

        setAlert(message);

        if (err instanceof NotFoundApiError) {
          history.push('/');
        }
      }
    } finally {
      setAreClientsLoading(false);
    }
  };

  const fetchCountries = async () => {
    setIsLoading(true);
    try {
      const {
        data: { data: fetchedCountries }
      } = await PostalCodesApi.getCountries({
        per_page: Number.MAX_SAFE_INTEGER
      });
      setCountries(fetchedCountries);
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();

        setAlert(message);

        if (err instanceof NotFoundApiError) {
          history.push('/');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onLanguageChange = e => {
    setFilters({ language: e.target.value });
  };

  const onNoteNameChange = e => {
    setFilters({ note_name: e.target.value });
  };

  const toggleModalOpen = () => {
    setCreateCategoryModalOpen(!createCategoryModalOpen);
  };

  const onCountryChange = e => {
    const { value } = e.target;

    setCountryId(value);
  };

  const deleteCategory = async categoryId => {
    try {
      await KnowledgeBaseApi.deleteCategory(categoryId);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      setKnowledge(knowledge.filter(category => category.id !== categoryId));
      setDeleteCategoryModalOpen(false);
      setCategoryToDelete(null);
    } catch (err) {
      if (err instanceof ValidationApiError || err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      } else {
        console.error(err);
      }
    }
  };

  const updateCategory = async payload => {
    const names = {};

    payload.forEach(item => {
      names[item.language] = item.value;
    });
    setKnowledge(
      knowledge.map(category =>
        category.id === categoryToUpdate.id
          ? { ...category, name: names }
          : category
      )
    );
    setUpdateCategoryModalOpen(false);
    setCategoryToUpdate(null);
  };

  const onDeleteModalOpen = categoryId => {
    setCategoryToDelete(knowledge.find(category => category.id === categoryId));
    setDeleteCategoryModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteCategoryModalOpen(false);
    setCategoryToDelete(null);
  };

  const onUpdateModalOpen = categoryId => {
    setCategoryToUpdate(knowledge.find(category => category.id === categoryId));
    setUpdateCategoryModalOpen(true);
  };

  const onUpdateModalClose = () => {
    setUpdateCategoryModalOpen(false);
    setCategoryToUpdate(null);
  };

  const onSendFilesCheckboxChange = e => {
    const { checked } = e.target;
    setSendFiles(checked);
  };

  const toggleFileToSend = (checked, fileId) => {
    return checked
      ? setFilesToSend([...filesToSend, fileId])
      : setFilesToSend(filesToSend.filter(id => id !== fileId));
  };

  const toggleLinkToSend = (checked, linkId) => {
    return checked
      ? setLinksToSend([...linksToSend, linkId])
      : setLinksToSend(linksToSend.filter(id => id !== linkId));
  };

  const onSendFilesModalOpen = () => {
    setSendFilesModalOpen(true);
  };

  const onSendFilesModalClose = () => {
    setSendFilesModalOpen(false);
  };

  const onSendSuccess = () => {
    setLinksToSend([]);
    setFilesToSend([]);
    setSendFiles(false);
    setSendFilesModalOpen(false);
  };

  useEffect(() => {
    setCurrentPage('Knowledge base');
    fetchClients();

    if (hasRole([SUPER_ADMIN])) {
      fetchCountries();
    }
  }, []);

  useEffect(() => {
    fetchKnowledgeBase();
  }, [filters]);

  useEffect(() => {
    fetchClients();
  }, [countryId]);

  return (
    <KnowledgeBaseContext.Provider
      value={{
        sendFiles,
        toggleFileToSend,
        toggleLinkToSend,
        filesToSend,
        linksToSend
      }}
    >
      <div>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', lg: 'row' }}
          alignItems='center'
          justifyContent='space-between'
        >
          <Box display='flex' width={{ xs: '100%', lg: '50%' }}>
            <Input
              name='note_name'
              label='Name'
              type='text'
              value={filters.note_name}
              onChange={onNoteNameChange}
            />
            <Box width='35%' ml={1}>
              <Select
                name='language'
                label='Language'
                value={filters.language}
                options={formatOptions(languages, 'slug', 'name')}
                onChange={onLanguageChange}
                emptyValueText='Choose language'
              />
            </Box>
          </Box>
          <Box display='flex'>
            <Checkbox
              onChange={onSendFilesCheckboxChange}
              checked={sendFiles}
              name='send_files'
              label='Select attachments'
              style={{ marginLeft: 10 }}
            />
            {sendFiles && (
              <Box mr={1}>
                <Button
                  text={t('Send attachments')}
                  color='primary'
                  onClick={onSendFilesModalOpen}
                  disabled={isEmpty(linksToSend) && isEmpty(filesToSend)}
                  tooltipMsg={t('Choose category or note first')}
                />
              </Box>
            )}
            <Button
              text={t('Add category')}
              color='primary'
              onClick={toggleModalOpen}
            />
          </Box>
        </Box>
        {isLoading ? (
          <PageLoader />
        ) : (
          <Grid container spacing={2}>
            {knowledge.map(({ id, name, notes }) => (
              <Grid item xs={12} md={6} key={id}>
                <CategoryBox
                  name={name[userLanguage] || name.en || Object.values(name)[0]}
                  notes={notes}
                  id={id}
                  deleteCategoryModalOpenFn={onDeleteModalOpen}
                  updateCategoryModalOpenFn={onUpdateModalOpen}
                  onCreateNoteSuccess={fetchKnowledgeBase}
                  onDeleteNoteSuccess={fetchKnowledgeBase}
                  onUpdateNoteSuccess={fetchKnowledgeBase}
                  setAlert={setAlert}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <CreateCategoryModal
          open={createCategoryModalOpen}
          toggleModalOpenFn={toggleModalOpen}
          onCreateSuccess={() => {
            setCreateCategoryModalOpen(false);
            fetchKnowledgeBase();
          }}
          setAlert={setAlert}
        />
        {deleteCategoryModalOpen && (
          <Dialog
            onCancel={onDeleteModalClose}
            open={deleteCategoryModalOpen}
            onApprove={() => deleteCategory(categoryToDelete.id)}
            title={`${t('Delete category')}: ${
              categoryToDelete?.name[userLanguage] ||
              categoryToDelete?.name.en ||
              Object.values(categoryToDelete.name)[0]
            }`}
            description={t('Are you sure you want to delete category?')}
          />
        )}
        {updateCategoryModalOpen && (
          <UpdateCategoryModal
            open={updateCategoryModalOpen}
            toggleModalOpenFn={onUpdateModalClose}
            onUpdateSuccess={updateCategory}
            setAlert={setAlert}
            categoriesNames={categoryToUpdate.name}
            categoryToUpdate={categoryToUpdate}
          />
        )}
        {sendFilesModalOpen && (
          <SendFilesToClientModal
            open={sendFilesModalOpen}
            toggleModalOpenFn={onUpdateModalClose}
            modalOpenFn={onSendFilesModalOpen}
            modalCloseFn={onSendFilesModalClose}
            onSendSuccess={onSendSuccess}
            setAlert={setAlert}
            linksToSend={linksToSend}
            filesToSend={filesToSend}
            clients={clients}
            countries={countries}
            countryId={countryId}
            onCountryChange={onCountryChange}
            areClientsLoading={areClientsLoading}
            hasRole={hasRole}
          />
        )}
      </div>
    </KnowledgeBaseContext.Provider>
  );
};

KnowledgeBaseView.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired
};

export default KnowledgeBaseView;
