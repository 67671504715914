import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import { CURRENCIES_MANAGEMENT } from 'api/auth/permissions/Currencies';
import CurrenciesRatesApi from 'api/connections/Currencies/CurrenciesRatesApi';
import { Box } from '@material-ui/core';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Pagination from 'modules/Layout/component/List/Pagination';
import CurrencyRateFormModal from 'modules/Currencies/component/Modals/RateForm';
import CurrencyRateDeleteModal from 'modules/Currencies/component/Modals/RateDelete';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class CurrencyDetailsRatesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      rates: [],
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      loading: false,
      actionModals: {
        rateToModify: null,
        formModalStatus: false,
        deleteModalStatus: false
      }
    };

    this.setPagination = this.setPagination.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.fetchCurrencyRates = this.fetchCurrencyRates.bind(this);
  }

  componentDidMount() {
    this.fetchCurrencyRates();
  }

  onCreateClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true
      }
    }));
  }

  onUpdateClick(rateToModify) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true,
        rateToModify
      }
    }));
  }

  onDeleteClick(rateToModify) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        deleteModalStatus: true,
        rateToModify
      }
    }));
  }

  getCols() {
    return [
      {
        property: 'value',
        label: 'Value',
        sortable: false
      },
      {
        property: 'date_from',
        label: 'Date from',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasPermission([CURRENCIES_MANAGEMENT]),
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  setPagination(pagination) {
    this.setState({
      pagination: { ...this.state.pagination, ...pagination }
    });
  }

  getDataToRender() {
    const { rates } = this.state;
    const { per_page, page } = this.state.pagination;

    const paginationFrom = (page - 1) * per_page;
    const paginationTo = paginationFrom + per_page;

    return {
      data: rates.slice(paginationFrom, paginationTo),
      total: rates.length
    };
  }

  closeAllModals() {
    this.setState({
      actionModals: {
        rateToModify: null,
        formModalStatus: false,
        deleteModalStatus: false
      }
    });
  }

  async fetchCurrencyRates() {
    try {
      this.setState({ loading: true });

      const {
        data: { data: rates }
      } = await CurrenciesRatesApi.getCurrencyRates(this.props.currencyId);

      this.setState({
        rates,
        loading: false,
        pagination: {
          ...this.state.pagination,
          page: 1
        }
      });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  render() {
    const { loading, pagination } = this.state;
    const {
      rateToModify,
      formModalStatus,
      deleteModalStatus
    } = this.state.actionModals;

    const { data, total } = this.getDataToRender();

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={data}
          loading={loading}
          dense
          actions
        />
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>
            <Authorize permissions={[CURRENCIES_MANAGEMENT]}>
              <IconButton
                className='create-icon'
                onClick={this.onCreateClick}
                icon={<AddIcon fontSize='large' />}
                alt='create'
              />
            </Authorize>
          </Box>
          <Pagination
            pagination={{ ...pagination, total }}
            setPagination={this.setPagination}
          />
        </Box>
        {formModalStatus && (
          <CurrencyRateFormModal
            currencyId={this.props.currencyId}
            rate={rateToModify}
            handleError={this.props.handleError}
            setAlert={this.props.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchCurrencyRates}
          />
        )}
        {deleteModalStatus && (
          <CurrencyRateDeleteModal
            currencyId={this.props.currencyId}
            rate={rateToModify}
            handleError={this.props.handleError}
            setAlert={this.props.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchCurrencyRates}
          />
        )}
      </>
    );
  }
}

CurrencyDetailsRatesList.propTypes = {
  currencyId: PropTypes.number.isRequired,
  handleError: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default CurrencyDetailsRatesList;
