import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

import TargetsBonusesApi from 'api/connections/TargetsBonuses/TargetsBonusesApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  ROUTE_TARGETS_BONUSES_DETAILS,
  ROUTE_TARGETS_BONUSES_LIST
} from 'routing/routes/TargetsBonuses';

class UpdateTargetBonusForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const { bonus } = props;
    this.bonusId = bonus.id;

    this.state = {
      formData: {
        name: bonus.name,
        value_level_1: bonus.value_level_1,
        value_level_2: bonus.value_level_2,
        value_level_3: bonus.value_level_3,
        value_level_4: bonus.value_level_4
      },
      validation: {
        name: {
          message: t('Field <%= field %> is required', {
            field: t('Name')
          }),
          status: false
        },
        value_level_1: {
          message: t('Field <%= field %> is required', {
            field: t('Bonus at 90%')
          }),
          status: false
        },
        value_level_2: {
          message: t('Field <%= field %> is required', {
            field: t('Bonus at 100%')
          }),
          status: false
        },
        value_level_3: {
          message: t('Field <%= field %> is required', {
            field: t('Bonus at 110%')
          }),
          status: false
        },
        value_level_4: {
          message: t('Field <%= field %> is required', {
            field: t('Bonus at 120%')
          }),
          status: false
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(
      insertPathParams(ROUTE_TARGETS_BONUSES_DETAILS, {
        id: this.props.bonus.id
      })
    );
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      await TargetsBonusesApi.updateTargetBonusData(this.bonusId, formData);

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        name,
        value_level_1,
        value_level_2,
        value_level_3,
        value_level_4
      },
      loading,
      validation
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='name'
          label='Name'
          value={name}
          type='text'
          onChange={this.onChange}
          required
          errorStatus={validation.name.status}
          errorText={validation.name.message}
        />
        <Input
          name='value_level_1'
          label='Bonus at 90%'
          value={value_level_1}
          type='number'
          onChange={this.onChange}
          required
          errorStatus={validation.value_level_1.status}
          errorText={validation.value_level_1.message}
        />
        <Input
          name='value_level_2'
          label='Bonus at 100%'
          value={value_level_2}
          type='number'
          onChange={this.onChange}
          required
          errorStatus={validation.value_level_2.status}
          errorText={validation.value_level_2.message}
        />
        <Input
          name='value_level_3'
          label='Bonus at 110%'
          value={value_level_3}
          type='number'
          onChange={this.onChange}
          required
          errorStatus={validation.value_level_3.status}
          errorText={validation.value_level_3.message}
        />
        <Input
          name='value_level_4'
          label='Bonus at 120%'
          value={value_level_4}
          type='number'
          onChange={this.onChange}
          required
          errorStatus={validation.value_level_4.status}
          errorText={validation.value_level_4.message}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link to={ROUTE_TARGETS_BONUSES_LIST} className='router-button'>
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

UpdateTargetBonusForm.propTypes = {
  bonus: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value_level_1: PropTypes.number,
    value_level_2: PropTypes.number,
    value_level_3: PropTypes.number,
    value_level_4: PropTypes.number
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(UpdateTargetBonusForm);
