import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  SUPER_ADMIN
} from 'api/auth/roles';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import {
  ROUTE_CLIENTS_DETAILS,
  ROUTE_CLIENTS_LIST,
  ROUTE_INTERNET_CLIENTS_DETAILS
} from 'routing/routes/Clients';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import {
  POSTAL_CODES_COUNTRIES_SHOW,
  POSTAL_CODES_SHOW
} from 'api/auth/permissions/PostalCodes';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import debounce from 'lodash/debounce';
import GeocodeApi from 'api/connections/Geocode/GeocodeApi';
import Checkbox from 'modules/Layout/component/Checkbox';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import Divider from '@material-ui/core/Divider';
import Authorize from 'modules/Auth/component/Authorize';
import _ from 'lodash';

const MAX_AUTOCOMPLETE_RESULTS = 100;

class ClientCreateForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.assignUserLabel = context.hasRole([
      SUPER_ADMIN,
      ADMIN,
      DIRECTOR,
      DEPARTMENT_MANAGER
    ])
      ? 'Assign employees to the client'
      : 'Assign client to myself';

    const { departments: userDepartments } = context.user;

    this.state = {
      formData: {
        name: '',
        recipient_number: '',
        country_id: context.hasRole([SUPER_ADMIN])
          ? ''
          : context.user.country_id,
        tax_number: '',
        city: '',
        street: '',
        postal_code: '',
        hours: '',
        sales_group_id: '',
        assign_users: true,
        email: '',
        phone: '',
        is_internet: props.isInternet
      },
      activities: [],
      departments: userDepartments,

      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Name') })
        },
        recipient_number: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Recipient number')
          })
        },
        country_id: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Country') })
        },
        email: {
          status: false,
          message: null
        },
        phone: {
          status: false,
          message: null
        }
      },
      loading: false,
      cities: [],
      salesGroups: [],
      autocompleteLoading: false
    };

    this.setFetchPlaceDebounce = debounce(this.fetchAddress, 500);
    this.setFetchDebounce = debounce(name => this.fetchCities(name), 500);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onIsInternetChange = this.onIsInternetChange.bind(this);
    this.onAutocompleteInputChange = this.onAutocompleteInputChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onActivityChange = this.onActivityChange.bind(this);
  }

  componentDidMount() {
    if (this.state.formData.country_id) {
      this.fetchSalesGroups();
    }
    if (this.context.hasRole([ADMIN])) this.fetchDepartments();
  }

  handleResponse(res) {
    const { id } = res;

    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    const path = insertPathParams(
      this.props.isInternet
        ? ROUTE_INTERNET_CLIENTS_DETAILS
        : ROUTE_CLIENTS_DETAILS,
      { id }
    );

    this.props.history.push(path);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState(
      state => {
        const { formData, validation } = state;

        return {
          formData: {
            ...formData,
            [name]: value
          },
          validation: {
            ...validation,
            [name]: {
              ...validation[name],
              status: false
            }
          },
          salesGroups: name === 'country_id' ? [] : [...state.salesGroups]
        };
      },
      () => {
        if (['street', 'postal_code'].includes(name)) {
          this.setFetchPlaceDebounce();
        }

        if (name === 'country_id') {
          this.fetchDepartments();
          this.fetchSalesGroups();
        }
      }
    );
  }

  onIsInternetChange(e) {
    const { checked } = e.target;

    this.setState(state => {
      const { formData } = state;

      return {
        ...state,
        activities: checked ? [] : formData.activity,
        formData: {
          ...formData,
          is_internet: checked,
          sales_group_id: checked ? null : formData.sales_group_id,

          assign_users: checked ? false : formData.assign_users
        }
      };
    });
  }

  onAutocompleteInputChange(e, newItem) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        city: newItem
      }
    }));
    if (this.context.hasPermission([POSTAL_CODES_SHOW])) {
      this.setFetchDebounce(newItem);
    }
    this.setFetchPlaceDebounce();
  }

  onAutocompleteChange(e, newItem) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        city: newItem?.key
      }
    }));
  }

  onActivityChange(res) {
    let value = null;

    if (res) {
      value = _.isArray(res) ? res.map(v => v.key) : res.key;
    }

    this.setState({
      activities: value
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData, activities: activity } = this.state;
    const { placePosition } = this.props;

    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await ClientsApi.createClient({
        ...formData,
        activity,
        ...placePosition
      });
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  async fetchCities(name = '') {
    const {
      formData: { country_id }
    } = this.state;
    this.setState({ autocompleteLoading: true });
    try {
      const {
        data: { data: cities }
      } = await PostalCodesApi.getCities({
        country_id,
        name,
        per_page: MAX_AUTOCOMPLETE_RESULTS
      });
      this.setState({ cities, autocompleteLoading: false });
    } catch (err) {
      this.setState({ autocompleteLoading: false });
      this.handleError(err);
    }
  }

  async fetchAddress() {
    const {
      formData: { city, street, postal_code }
    } = this.state;

    if (![city, street, postal_code].some(item => item)) return null;

    try {
      const {
        data: { results, status }
      } = await GeocodeApi.getAddress({
        address: `${city}, ${street}, ${postal_code}`,
        key: process.env.REACT_APP_GOOGLE_MAP_KEY
      });

      if (status === 'OK') {
        const { lat, lng: long } = results[0].geometry.location;
        this.props.handlePlaceChangeFn(lat, long);
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchSalesGroups() {
    const { country_id } = this.state.formData;

    try {
      const {
        data: { data: salesGroups }
      } = await SalesGroupsApi.getSalesGroups({
        country_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({ salesGroups });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchDepartments() {
    const { country_id } = this.state.formData;

    if (!country_id) return;

    try {
      const {
        data: { data: departments }
      } = await DepartmentsApi.getDepartments({
        country_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({ departments });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        name,
        recipient_number,
        country_id,
        tax_number,
        street,
        postal_code,
        hours,
        sales_group_id,
        assign_users,
        bank_account_for_bonuses,
        email,
        phone,
        is_internet
      },
      activities,
      departments,
      cities,
      salesGroups,
      validation,
      loading,
      autocompleteLoading
    } = this.state;
    const { countries } = this.props;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='name'
          label='Name'
          value={name}
          required
          autoFocus
          onChange={this.onChange}
          errorStatus={validation.name.status}
          errorText={validation.name.message}
        />
        <Input
          name='recipient_number'
          label='Recipient number'
          value={recipient_number}
          required
          onChange={this.onChange}
          errorStatus={validation.recipient_number.status}
          errorText={validation.recipient_number.message}
        />
        {this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
          <Select
            name='country_id'
            label='Country'
            value={country_id}
            required
            options={formatOptions(countries, 'id', 'name')}
            onChange={this.onChange}
            errorStatus={validation.country_id.status}
            errorText={validation.country_id.message}
          />
        )}
        <Autocomplete
          name='city'
          label='City'
          onChange={this.onAutocompleteChange}
          onInputChange={this.onAutocompleteInputChange}
          options={formatOptions(cities, 'name', 'name')}
          disabled={country_id === ''}
          loading={autocompleteLoading}
          freeSolo
        />
        <Input
          name='street'
          label='Street'
          value={street}
          onChange={this.onChange}
        />
        <Input
          name='postal_code'
          label='Postal code'
          value={postal_code}
          onChange={this.onChange}
        />
        <Input
          name='tax_number'
          label='Tax number'
          value={tax_number}
          onChange={this.onChange}
          errorStatus={validation.tax_number?.status}
          errorText={validation.tax_number?.message}
        />
        <Input
          name='hours'
          label='Hours'
          value={hours}
          onChange={this.onChange}
        />
        <Input
          name='email'
          label='Email'
          value={email}
          onChange={this.onChange}
          errorStatus={validation.email.status}
          errorText={validation.email.message}
        />
        <Input
          name='phone'
          label='Phone number'
          value={phone}
          onChange={this.onChange}
          errorStatus={validation.phone.status}
          errorText={validation.phone.message}
        />
        <Input
          name='bank_account_for_bonuses'
          label='Bank account for bonuses'
          value={bank_account_for_bonuses}
          onChange={this.onChange}
        />
        <Checkbox
          onChange={this.onIsInternetChange}
          checked={is_internet}
          name='is_internet'
          label='Internet client'
        />
        {!is_internet && (
          <>
            <Select
              name='sales_group_id'
              label='Shopping group'
              value={sales_group_id}
              options={formatOptions(salesGroups, 'id', 'name')}
              onChange={this.onChange}
              disabled={!country_id}
              tooltipMsg='Choose country first'
            />
            <Authorize roles={[SUPER_ADMIN, ADMIN, DIRECTOR]}>
              <Autocomplete
                name='activity'
                label='Activity in the department'
                value={activities}
                options={formatOptions(departments, 'id', 'name')}
                onChange={(__, v) => this.onActivityChange(v)}
                multiple
                disabled={!country_id}
                tooltipMsg='Select country first'
              />
              <Divider />
            </Authorize>
            <Checkbox
              name='assign_users'
              checked={assign_users}
              onChange={this.onChange}
              label={this.assignUserLabel}
            />
          </>
        )}
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link to={ROUTE_CLIENTS_LIST} className='router-button'>
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

ClientCreateForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAlert: PropTypes.func.isRequired,
  handlePlaceChangeFn: PropTypes.func.isRequired,
  placePosition: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    long: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  isInternet: PropTypes.bool.isRequired
};

export default withRouter(ClientCreateForm);
