import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { validateBaseOnFormInputs } from 'modules/Shared/utils/validator';
import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import CategoryPicker from 'modules/Layout/component/CategoryPicker';
import { Typography, Box } from '@material-ui/core';
import { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import { isArray } from 'lodash/lang';
import ProductsApi from 'api/connections/Products/ProductsApi';

class PriceListsAddProductModal extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      formData: {
        product_id: null,
        price: ''
      },
      groupId: null,
      categoryId: null,
      subcategoryId: null,
      productsData: [],
      loadingProducts: false,
      validation: {
        product_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Product')
          })
        },
        price: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Price')
          })
        }
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onCategoryPick = this.onCategoryPick.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation(e.target.elements);
    if (isValid) {
      const { product_id, price } = this.state.formData;
      const product = this.state.productsData.find(p => p.id === product_id);

      this.props.onSuccess({ product, price });
      this.props.onClose();
    }
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onChange(event);
  }

  onCategoryPick(name, value) {
    this.setState(
      state => {
        let { groupId, categoryId, subcategoryId } = state;

        if (name === 'group') {
          groupId = value?.key;
          categoryId = null;
          subcategoryId = null;
        }
        if (name === 'category') {
          categoryId = value?.key;
          subcategoryId = null;
        }
        if (name === 'subcategory') subcategoryId = value?.key;

        return {
          ...state,
          groupId,
          categoryId,
          subcategoryId,
          formData: {
            ...state.formData,
            product_id: null
          },
          validation: {
            ...state.validation,
            product_id: {
              ...state.validation.product_id,
              status: false
            }
          }
        };
      },
      () => this.fetchProducts()
    );
  }

  getProductOptions() {
    const { productsData } = this.state;
    const { products } = this.props;

    return productsData.filter(({ id }) => {
      return !products.map(p => p.id).includes(id);
    });
  }

  runValidation(formElements) {
    const {
      validation,
      formData: { product_id }
    } = this.state;

    /* eslint prefer-const: "off" */
    let [isValid, validateBaseOnFormInputsState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!product_id) {
      isValid = false;
      validateBaseOnFormInputsState.product_id.status = true;
    }

    if (!isValid) {
      this.setState({ validation: validateBaseOnFormInputsState });
    }

    return isValid;
  }

  async fetchProducts() {
    const { subcategoryId } = this.state;

    if (!subcategoryId) return null;

    try {
      this.setState({ loadingProducts: true });

      const {
        data: { data: productsData }
      } = await ProductsApi.getProducts({
        subcategory_id: subcategoryId,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        productsData,
        loadingProducts: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { product_id, price },
      groupId,
      categoryId,
      subcategoryId,
      validation,
      loadingProducts
    } = this.state;

    const { onClose } = this.props;

    const productOptions = this.getProductOptions();

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Add product')}</DialogTitle>
        <DialogContent>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            <Box className='language-form-box'>
              <Typography component='h6' variant='h6'>
                {t('Category')}
              </Typography>
              <CategoryPicker
                onChange={this.onCategoryPick}
                groupId={groupId}
                categoryId={categoryId}
                subcategoryId={subcategoryId}
              />
            </Box>
            <Autocomplete
              label='Product'
              name='product_id'
              value={product_id}
              options={formatOptions(productOptions, 'id', 'sku')}
              onChange={(_, v) => this.onAutocompleteChange('product_id', v)}
              loading={loadingProducts}
              disabled={!subcategoryId}
              tooltipMsg='Select product subcategory first'
              errorStatus={validation.product_id.status}
              errorText={validation.product_id.message}
            />
            <Input
              name='price'
              label='Price'
              value={price}
              onChange={this.onChange}
              required
              errorStatus={validation.price.status}
              errorText={validation.price.message}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button form='form' type='submit' color='primary' text={t('Save')} />
        </DialogActions>
      </Dialog>
    );
  }
}

PriceListsAddProductModal.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PriceListsAddProductModal;
