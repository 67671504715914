import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { TAXES_DELETE, TAXES_UPDATE } from 'api/auth/permissions/Taxes';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class TaxesTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'country_id',
        label: 'Country',
        sortable: true,
        displayStatus: this.context.hasPermission([
          POSTAL_CODES_COUNTRIES_SHOW
        ]),
        render: id => this.props.countries.find(c => c.id === id).name
      },
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'value',
        label: 'Value',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasSomeOfPermission([
          TAXES_DELETE,
          TAXES_UPDATE
        ]),
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: this.context.hasPermission([TAXES_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.props.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([TAXES_DELETE]),
            disable: false,
            disableMsg: null,
            onClick: this.props.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { taxes, sort, setSort, loading } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={taxes}
          sort={sort}
          setSort={setSort}
          loading={loading}
          actions={this.context.hasSomeOfPermission([
            TAXES_DELETE,
            TAXES_UPDATE
          ])}
        />
      </>
    );
  }
}

TaxesTable.defaultProps = {
  taxes: [],
  countries: []
};

TaxesTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  taxes: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(TaxesTable);
