export default {
  'Clients orders statistics': 'Ranking zamówień klientów',
  'Clients sales statistics': 'Ranking sprzedaży klientów',
  Statistics: 'Rankingi',
  'Clients orders': 'Zamówienia klientów',
  'Clients sales': 'Sprzedaż klientów',
  'Statistics clients orders': 'Ranking zamówień klientów',
  'Statistics clients sales': 'Ranking sprzedaży klientów',
  'Pick year and quarter firsts': 'Wybierz najpierw rok i kwartał',
  'Products sales': 'Sprzedaż produktów',
  'Statistics products sales': 'Ranking sprzedaży produktów',
  'My users': 'Moi użytkownicy',
  'Priority products sales': 'Sprzedaż produktów priorytetowych',
  Own: 'Własne',
  High: 'Wysoki',
  Medium: 'Średni',
  Low: 'Niski',
  'Users visits': 'Wizyty użytkowników',
  'Statistics users visits': 'Ranking wizyt użytkowników',
  trader: 'handlowiec',
  'Completed visits to scheduled visits':
    'Stosunek wizyt zakończonych do zaplanowanych',
  department_manager: 'Kierownik',
  key_account_manager: 'Menadżer kluczowych klientów',
  'Pick period first': 'Wybierz najpierw okres',
  'Statistics priority products sales':
    'Ranking sprzedaży produktów priorytetowych',
  'Wholesalers sales': 'Sprzedaży hurtowni',
  'Statistics wholesalers sales': 'Ranking sprzedaży hurtowni',
  'Statistics users orders': 'Ranking zamówień użytkowników',
  'Users orders': 'Zamówienia użytkowników',
  'Orders value': 'Wartość zamówień',
  'Users finished visits': 'Odbyte wizyty użytkowników',
  'Visits number': 'Liczba wizyt',
  'Users orders during visits': 'Zamówienia użytkowników podczas wizyt',
  'Statistics users orders during visits':
    'Ranking zamówień użytkowników podczas wizyt',
  'Statistics of visited active clients':
    'Ranking odwiedzonych aktywnych klientów',
  'Orders number to finished visits number':
    'Stosunek liczby zamówień do liczby zakończonych wizyt',
  'Users visits comparison': 'Porównanie wizyt użytkowników',
  'Statistics users visits comparison': 'Porównanie wizyt użytkowników',
  'Visits count': 'Liczba wizyt',
  'Finished visits count': 'Liczba zakończonych wizyt',
  'Canceled visits count': 'Liczba odwołanych wizyt',
  'Postponed visits count': 'Liczba przełożonych wizyt',
  'Moved visits count': 'Liczba przeniesionych wizyt',
  'Statistics users finished visits': 'Ranking odbytych wizyt użytkowników',
  'Sales comparison': 'Porównanie sprzedaży',
  'Statistics sales comparison': 'Porównanie sprzedaży',
  'Price sum': 'Łączna kwota',
  'Quantity sum': 'Łączna liczba sztuk',
  'No products': 'Brak produktów',
  'Products categories': 'Kategorie produktów',
  'Orders number': 'Liczba zamówień',
  'No orders': 'Brak zamówień',
  'Direct sales and total wholesalers sales comparison':
    'Porównanie sprzedaży bezpośredniej i łącznej sprzedaży hurtowni',
  'Direct sales and indicated wholesalers sales comparison':
    'Porównanie sprzedaży bezpośredniej i sprzedaży wskazanych hurtowni',
  'Wholesalers sales comparison': 'Porównanie sprzedaży wskazanych hurtowni',
  'Direct sales': 'Sprzedaż bezpośrednia',
  'Sum of wholesalers sales': 'Łączna suma sprzedaży hurtowni',
  'Sum of direct sales': 'Łączna suma sprzedaży bezpośredniej',
  'Number of wholesalers sales': 'Liczba sztuk sprzedaży hurtowni',
  'Number of direct sales': 'Liczba sztuk sprzedaży bezpośredniej',
  'Wholesalers sales legend': 'Legenda sprzedaży hurtowni',
  'Direct sales legend': 'Legenda sprzedaży bezpośredniej',
  'Traders visits report': 'Raport wizyt handlowców',
  'Visits amount': 'Liczba odbytych wizyt',
  'Orders amount': 'Liczba wysłanych zamówień',
  'Orders summary value': 'Wartość wysłanych zamówień',
  'Promotions packages amount': 'Liczba pakietów z promocji predefiniowanych',
  'Promotions packages define in order amount':
    'Liczba pakietów z promocji ręcznych',
  'Choose wholesaler': 'Wybierz hurtownię',
  'Sales data can be filtered by only one of options at a time: group, category or subcategory':
    'Dane sprzedaży można filtrować tylko według jednej opcji na raz: grupy, kategorii lub podkategorii',
  'The ratio of visited clients to active clients':
    'Stosunek liczby odwiedzonych klientów do aktywnych',
  'Clients amount': 'Ilość klientów',
  'Visited clients amount': 'Ilość odwiedzonych klientów',
  'Clients contracts report': 'Poziom spełnienia kontraktów klientów',
  'Clients targets report': 'Poziom spełnienia kontraktów klientów',
  'Show archive': 'Pokaż archiwalne',
  'Promotions summary value': 'Wartość sumaryczną promocji',
  'Free products quantity': 'Liczba produktów gratisowych',
  'Internet clients report': 'Raport klientów internetowych',
  'Pick dates and client first': 'Wybierz najpierw daty i klienta',
  'Gratis products report': 'Raport produktów gratisowych',
  Dates: 'Daty',
  'Department and user': 'Dział i użytkownik',
  'Pick dates and department': 'Wybierz daty i dział',
  'Price before promotion': 'Cena przed promocją',
  'Price after promotion': 'Cena po promocji',
  'Statistics internet clients orders': 'Raport klientów internetowych'
};
