import { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DaysWrapper from 'modules/Visits/components/Calendar/DaysWrapper';
import CalendarHeader from 'modules/Visits/components/Calendar/Header';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import getMonth from 'date-fns/getMonth';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/MonthYearPicker';
import parse from 'date-fns/parse';
import debounce from 'lodash/debounce';
import format from 'date-fns/format';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import PageLoader from 'modules/Layout/component/PageLoader';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  KEY_ACCOUNT_MANAGER,
  SUPER_ADMIN,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import UsersApi from 'api/connections/Users/UsersApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import InputsHeader from 'modules/Visits/components/Calendar/InputsHeader';
import setCopyVisitsDays from 'modules/Visits/utils/setCopyVisitsDays';
import getDays from 'modules/Visits/utils/getDays';
import CopyVisitsModal from 'modules/Visits/components/Calendar/CopyVisitsModal';

export const CalendarContext = createContext({});

const API_CALL_FORMAT = 'dd.MM.yyyy';

class Calendar extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const monthStart = startOfMonth(new Date());
    const monthEnd = endOfMonth(new Date());

    this.state = {
      monthStart,
      monthEnd,
      dateStart: startOfWeek(monthStart, { weekStartsOn: 1 }),
      dateEnd: endOfWeek(monthEnd, { weekStartsOn: 1 }),
      month: getMonth(monthStart),
      users: [],
      days: [],
      loading: true,
      filter: {
        user_id: context.user.id
      },
      usersFilter: {
        roles: [TRADER, TELEMARKETER, KEY_ACCOUNT_MANAGER],
        per_page: Number.MAX_SAFE_INTEGER,
        country_id: context.hasRole([ADMIN]) ? context.user.country_id : ''
      },
      copyVisits: false,
      copyVisitsDays: { copy_from: null, copy_to: null, start_date: null },
      copyVisitsModalOpen: false
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.maxDate = addYears(new Date(), 10);
    this.setFetchDebounce = debounce(() => this.fetchVisits(), 500);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.onCopyVisitsChange = this.onCopyVisitsChange.bind(this);
    this.handleDaysToCopy = this.handleDaysToCopy.bind(this);
    this.handleDateChangeWithArrows = this.handleDateChangeWithArrows.bind(
      this
    );
    this.onCopyVisitsModalClose = this.onCopyVisitsModalClose.bind(this);
    this.onCopyVisitsModalOpen = this.onCopyVisitsModalOpen.bind(this);
    this.onCopyVisitsSuccess = this.onCopyVisitsSuccess.bind(this);
  }

  componentDidMount() {
    const { hasRole } = this.context;

    this.setCurrentPage('Calendar');

    if (hasRole([ADMIN, DIRECTOR, SUPER_ADMIN, DEPARTMENT_MANAGER])) {
      this.fetchData();
    } else if (hasRole([TRADER, TELEMARKETER, KEY_ACCOUNT_MANAGER])) {
      this.fetchVisits();
    }
  }

  handleDateChange(e) {
    const { value } = e.target;

    const newMonth = parse(value, API_DATE_FORMAT, new Date());
    const monthStart = startOfMonth(newMonth);
    const monthEnd = endOfMonth(newMonth);

    this.setState(
      {
        monthStart,
        monthEnd,
        dateStart: startOfWeek(monthStart, { weekStartsOn: 1 }),
        dateEnd: endOfWeek(monthEnd, { weekStartsOn: 1 }),
        month: getMonth(newMonth)
      },
      this.fetchVisits
    );
  }

  handleDateChangeWithArrows(e, subtract = false) {
    const { monthStart } = this.state;

    const newMonth = addMonths(monthStart, subtract ? -1 : 1);

    const newMonthStart = startOfMonth(newMonth);
    const newMonthEnd = endOfMonth(newMonth);

    this.setState({
      monthStart: newMonthStart,
      monthEnd: newMonthEnd,
      dateStart: startOfWeek(newMonthStart, { weekStartsOn: 1 }),
      dateEnd: endOfWeek(newMonthEnd, { weekStartsOn: 1 }),
      month: getMonth(newMonth),
      loading: true
    });

    this.setFetchDebounce();
  }

  handleDaysToCopy(day) {
    this.setState(prevState => ({
      ...setCopyVisitsDays(prevState, day)
    }));
  }

  onUserChange(e) {
    const userId = e.target.value;
    this.setState(
      { filter: { user_id: userId === '' ? this.context.user.id : userId } },
      this.fetchVisits
    );
  }

  onCopyVisitsChange(e) {
    this.setState({ copyVisits: e.target.checked });
  }

  onCopyVisitsModalClose() {
    this.setState({ copyVisitsModalOpen: false });
  }

  onCopyVisitsModalOpen() {
    this.setState({ copyVisitsModalOpen: true });
  }

  onCopyVisitsSuccess() {
    this.fetchVisits();
  }

  async fetchVisits() {
    const {
      monthStart,
      monthEnd,
      dateStart,
      dateEnd,
      month,
      filter
    } = this.state;

    const formattedStartDate = format(monthStart, API_CALL_FORMAT);
    const formattedEndDate = format(monthEnd, API_CALL_FORMAT);

    this.setState({ loading: true });

    try {
      const {
        data: { data: fetchedVisits }
      } = await VisitsApi.getVisits({
        ...filter,
        date_from: formattedStartDate,
        date_to: formattedEndDate,
        per_page: Number.MAX_SAFE_INTEGER,
        sort_field: 'scheduled_at'
      });
      this.setState({
        days: getDays(dateStart, dateEnd, month, fetchedVisits),
        copyVisitsDays: { copy_from: null, copy_to: null },
        loading: false
      });
    } catch (err) {
      this.setState({ loading: false });
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchData() {
    const { monthStart, monthEnd, dateStart, dateEnd, month } = this.state;

    const formattedStartDate = format(monthStart, API_CALL_FORMAT);
    const formattedEndDate = format(monthEnd, API_CALL_FORMAT);

    this.setState({ loading: true });

    try {
      const [
        {
          data: { data: fetchedVisits }
        },
        {
          data: { data: fetchedUsers }
        }
      ] = await Promise.all([
        VisitsApi.getVisits({
          ...this.state.filter,
          date_from: formattedStartDate,
          date_to: formattedEndDate,
          per_page: Number.MAX_SAFE_INTEGER,
          sort_field: 'scheduled_at'
        }),
        UsersApi.getUsers({ ...this.state.usersFilter })
      ]);

      fetchedUsers.forEach(user => {
        // eslint-disable-next-line no-param-reassign
        user.name = `${user.first_name} ${user.last_name}`;
      });
      this.setState({
        users: fetchedUsers,
        days: getDays(dateStart, dateEnd, month, fetchedVisits),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setState({ loading: false });
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  render() {
    const {
      monthStart,
      loading,
      users,
      filter: { user_id },
      copyVisits,
      days,
      copyVisitsModalOpen,
      copyVisitsDays: { copy_from, copy_to }
    } = this.state;
    const { hasRole } = this.context;

    return (
      <>
        <InputsHeader
          dateChangeWithArrowsFn={this.handleDateChangeWithArrows}
          hasRoleFn={hasRole}
          monthStart={monthStart}
          onDateChangeFn={this.handleDateChange}
          maxDate={this.maxDate}
          onUserChangeFn={this.onUserChange}
          userId={user_id}
          users={users}
          historyPushFn={this.props.history.push}
          onCopyVisitsChangeFn={this.onCopyVisitsChange}
          onCopyVisitsModalOpenFn={this.onCopyVisitsModalOpen}
          disabledCopyButton={!copy_from}
        />
        {loading ? (
          <PageLoader />
        ) : (
          <CalendarContext.Provider
            value={{ handleDaysToCopy: this.handleDaysToCopy, copyVisits }}
          >
            <CalendarHeader />
            <DaysWrapper days={days} />
          </CalendarContext.Provider>
        )}
        {copyVisitsModalOpen && (
          <CopyVisitsModal
            open={copyVisitsModalOpen}
            days={days}
            onSuccess={this.onCopyVisitsSuccess}
            onClose={this.onCopyVisitsModalClose}
            copyFrom={copy_from}
            copyTo={copy_to}
          />
        )}
      </>
    );
  }
}

Calendar.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default withRouter(Calendar);
