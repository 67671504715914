import { Component } from 'react';
import PropTypes from 'prop-types';
import LongAgoVisitedClientsTable from 'modules/Visits/components/LongAgoVisitedClientsTable';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ADMIN, SUPER_ADMIN, DIRECTOR } from 'api/auth/roles';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import LongAgoVisitedClientsFilter from 'modules/Visits/components/LongAgoVisitedClientsTable/Filter';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';

class LongAgoVisitedClients extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const { departments, country_id } = context.user;

    this.state = {
      clients: [],
      countries: [],
      departments,
      filter: {
        my_clients: !context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]),
        name: '',
        city: '',
        country_id,
        tax_number: '',
        sales_group_name: '',
        show_for_department_id: departments.length >= 1 && departments[0].id
      },
      filterValidation: {},
      sort: { sort_field: 'scheduled_at' },
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      loading: true,
      loadingDepartments: true
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.setPagination = this.setPagination.bind(this);
    this.setSort = this.setSort.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Long ago visited clients');

    if (this.context.hasRole([SUPER_ADMIN, ADMIN])) {
      return this.fetchDepartments();
    }

    this.fetchData();
  }

  onCheckboxChange(e) {
    const { checked, name } = e.target;
    this.setState(
      prevState => ({ filter: { ...prevState.filter, [name]: checked } }),
      () => this.fetchData()
    );
  }

  setSort(sort, type, fetchItems) {
    this.setState({ [type]: { ...this.state[type], ...sort } }, () =>
      fetchItems()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchData()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination }
      },
      () => this.fetchData()
    );
  }

  async fetchData() {
    const { hasRole } = this.context;
    const { show_for_department_id } = this.state.filter;

    if (hasRole([SUPER_ADMIN, ADMIN]) && !show_for_department_id) {
      this.setState({ loading: false });
      return;
    }

    try {
      const {
        data: { data: fetchedClients, meta }
      } = await ClientsApi.getNotVisitedClients({
        ...this.state.sort,
        ...this.state.filter,
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page
      });

      this.setState({
        clients: fetchedClients.map(client => ({
          ...client,
          status: client.latest_visit_date
        })),
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    } finally {
      this.setState({ loading: false, loadingDepartments: false });
    }
  }

  async fetchDepartments() {
    this.setState({ loadingDepartments: true, loading: false });

    try {
      const {
        data: { data: fetchedDepartments }
      } = await DepartmentsApi.getDepartments({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        departments: fetchedDepartments
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    } finally {
      this.setState({ loadingDepartments: false });
    }
  }

  renderActions() {
    return (
      <Box
        display='flex'
        flexDirection='row-reverse'
        justifyContent='space-between'
      >
        <Pagination
          pagination={this.state.pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Box>
    );
  }

  render() {
    const {
      clients,
      sort: sortData,
      filter,
      loading,
      loadingDepartments,
      filterValidation,
      departments
    } = this.state;

    return (
      <>
        <LongAgoVisitedClientsFilter
          filter={filter}
          setFilter={this.setFilter}
          validation={filterValidation}
          departments={departments}
          loadingDepartments={loadingDepartments}
          defaultExpanded={!filter.show_for_department_id}
        />
        {this.renderActions()}
        {clients && (
          <LongAgoVisitedClientsTable
            sort={sortData}
            setSort={sort => this.setSort(sort, 'sort', () => this.fetchData())}
            clients={clients}
            loading={loading}
            bodyText={
              !filter.show_for_department_id && 'Select department first'
            }
          />
        )}
        {this.renderActions()}
      </>
    );
  }
}

LongAgoVisitedClients.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default withRouter(LongAgoVisitedClients);
