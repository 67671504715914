import ListIcon from '@material-ui/icons/List';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {
  ROUTE_VISITS_LIST,
  ROUTE_VISITS_MAP_ROUTES,
  ROUTE_LONG_AGO_VISITED_CLIENTS,
  permMap
} from 'routing/routes/Visits';

const menu = [
  {
    key: 'menu-visits-groups',
    label: 'Visits',
    icon: <EventNoteIcon />,
    path: ROUTE_VISITS_LIST,
    anyPermissions: permMap[ROUTE_VISITS_LIST],
    children: [
      {
        key: 'menu-visits-groups-list',
        label: 'Schedule',
        icon: <ListIcon />,
        path: ROUTE_VISITS_LIST,
        permissions: permMap[ROUTE_VISITS_LIST]
      },
      {
        key: 'menu-visits-map-routes',
        label: 'Routes',
        icon: <ListIcon />,
        path: ROUTE_VISITS_MAP_ROUTES,
        permissions: permMap[ROUTE_VISITS_MAP_ROUTES]
      },
      {
        key: 'menu-long-ago-visited-clients',
        label: 'Long ago visited clients',
        icon: <ListIcon />,
        path: ROUTE_LONG_AGO_VISITED_CLIENTS,
        permissions: permMap[ROUTE_LONG_AGO_VISITED_CLIENTS]
      }
    ]
  }
];

export default menu;
