import makeStyles from '@material-ui/core/styles/makeStyles';
import { drawerWidth } from 'modules/Layout/component/Navigator';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  appShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth
    }
  },
  main: {
    flex: 1,
    padding: theme.spacing(4),
    background: '#eaeff1'
  }
}));

export default useStyles;
