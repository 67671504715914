import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Tab } from '@material-ui/core';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import Tabs from 'modules/Layout/component/Tabs';
import TabPanel from 'modules/Layout/component/TabPanel';
import Loader from 'modules/Layout/component/Loader';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import t from 'translate/translate';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { WHOLESALERS_UPDATE } from 'api/auth/permissions/Wholesalers';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import WholesalerDetailsBasicData from 'modules/Wholesalers/view/Details/BasicData';
import WholesalersAgenciesList from 'modules/Wholesalers/view/Details/Agencies/List';
import WholesalerProductMappingDetails from 'modules/Wholesalers/view/Details/ProductsMapping/List';
import WholesalerClientsMappingDetails from 'modules/Wholesalers/view/Details/ClientsMapping/List';
import WholesalerColumnsMappingDetails from 'modules/Wholesalers/view/Details/ColumnsMapping';
import WholesalerDetailsNotes from 'modules/Wholesalers/view/Details/Notes';
import WholesalerDetailsFiles from 'modules/Wholesalers/view/Details/Files';
import { ROUTE_WHOLESALERS_DETAILS } from 'routing/routes/Wholesalers';
import { ADMIN, SUPER_ADMIN, DIRECTOR } from 'api/auth/roles';

class WholesalerDetailsWrapper extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { id: wholesalerId } = props.match.params;

    this.wholesalerId = parseInt(wholesalerId, 10) || null;

    this.state = {
      countries: [],
      filterValidation: {}
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.fetchWholesaler = this.fetchWholesaler.bind(this);
  }

  componentDidMount() {
    if (!this.wholesalerId) {
      this.props.history.push(ROUTE_WHOLESALERS_DETAILS);
    }
    this.setCurrentPage('Wholesaler details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.setAlert(message);
      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_WHOLESALERS_DETAILS);
      }
      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      let countries = [];
      const {
        data: { data: wholesaler }
      } = await WholesalersApi.getWholesaler(this.wholesalerId);
      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        wholesaler,
        countries
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchWholesaler() {
    try {
      const {
        data: { data: wholesaler }
      } = await WholesalersApi.getWholesaler(this.wholesalerId);
      this.setState({ wholesaler });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { wholesaler, countries } = this.state;

    if (!wholesaler) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Tabs>
            <Tab label={t('General data')} value='general_data' />
            <Tab label={t('Agencies')} value='agencies' />
            {this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) && [
              <Tab
                key='products_mapping'
                label={t('Products mapping')}
                value='products_mapping'
              />,
              <Tab
                key='clients_mapping'
                label={t('Clients mapping')}
                value='clients_mapping'
              />
            ]}
            {this.context.hasPermission([WHOLESALERS_UPDATE]) && (
              <Tab label={t('Columns mapping')} value='columns_mapping' />
            )}
            <Tab label={t('Files')} value='wholesaler_files' />
            <Tab label={t('Notes')} value='wholesaler_notes' />
          </Tabs>
          <TabPanel index='general_data'>
            <WholesalerDetailsBasicData
              fetchWholesaler={this.fetchWholesaler}
              wholesaler={wholesaler}
              countries={countries}
            />
          </TabPanel>
          <TabPanel index='agencies'>
            <WholesalersAgenciesList
              wholesalerId={this.wholesalerId}
              wholesalerName={wholesaler.name}
              setAlert={this.setAlert}
            />
          </TabPanel>
          {this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) && (
            <>
              <TabPanel index='products_mapping'>
                <WholesalerProductMappingDetails
                  wholesalerId={this.wholesalerId}
                  wholesalerName={wholesaler.name}
                  setAlert={this.setAlert}
                />
              </TabPanel>
              <TabPanel index='clients_mapping'>
                <WholesalerClientsMappingDetails
                  wholesalerId={this.wholesalerId}
                  wholesalerName={wholesaler.name}
                  setAlert={this.setAlert}
                />
              </TabPanel>
            </>
          )}
          {this.context.hasPermission([WHOLESALERS_UPDATE]) && (
            <TabPanel index='columns_mapping'>
              <WholesalerColumnsMappingDetails
                wholesaler={wholesaler}
                setAlert={this.setAlert}
              />
            </TabPanel>
          )}
          <TabPanel index='wholesaler_files'>
            <WholesalerDetailsFiles
              wholesalerId={this.wholesalerId}
              setAlert={this.setAlert}
            />
          </TabPanel>
          <TabPanel index='wholesaler_notes'>
            <WholesalerDetailsNotes
              wholesalerId={this.wholesalerId}
              setAlert={this.setAlert}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

WholesalerDetailsWrapper.defaultProps = {
  location: {
    state: {}
  }
};

WholesalerDetailsWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({ tabIndex: PropTypes.number })
  }),
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerDetailsWrapper);
