import { Box } from '@material-ui/core';
import SalesResultsBreadcrumbs from 'modules/SalesResults/component/ResultsDetails/Breadcrumbs';
import SalesResultsViewTypeButtons from 'modules/SalesResults/component/ResultsDetails/ViewTypeButtons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const SalesResultsNavigationBar = props => {
  const { breadcrumbs, onDrillUp, viewType, onViewTypeChange } = props;
  const { location } = useHistory();
  const isComparison = Boolean(location.pathname.search('comparison') > 0);

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='4rem'
    >
      {!isComparison && <Box width={3} />}
      <Box width={1} display='flex' justifyContent='center' flexGrow={1}>
        <SalesResultsBreadcrumbs
          breadcrumbs={breadcrumbs}
          onDrillUp={onDrillUp}
        />
      </Box>
      {!isComparison && (
        <Box display='flex' justifyContent='flex-end' width={3}>
          <SalesResultsViewTypeButtons
            viewType={viewType}
            onViewTypeChange={onViewTypeChange}
          />
        </Box>
      )}
    </Box>
  );
};

SalesResultsNavigationBar.defaultProps = {
  onViewTypeChange: undefined
};

SalesResultsNavigationBar.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDrillUp: PropTypes.func.isRequired,
  viewType: PropTypes.string.isRequired,
  onViewTypeChange: PropTypes.func
};

export default SalesResultsNavigationBar;
