import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_VISITS_CREATE } from 'routing/routes/Visits';
import { VISITS_SHOW, VISITS_CREATE } from 'api/auth/permissions/Visits';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { CLIENTS_LIST } from 'api/auth/permissions/Clients';
import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';
import { setColor } from 'modules/Visits/utils/clientStatusDot';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

class LongAgoVisitedClientsTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'status',
        label: 'Time from last visit',
        sortable: false,
        render: value => {
          return (
            <FiberManualRecordIcon
              fontSize='small'
              style={{ color: setColor(value) }}
            />
          );
        }
      },
      {
        property: 'name',
        label: 'Client',
        sortable: true
      },
      {
        property: 'city',
        label: 'City',
        sortable: true
      },
      {
        property: 'street',
        label: 'Street',
        sortable: false
      },
      {
        property: 'latest_visit_date',
        label: 'Latest visit',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasPermission([VISITS_SHOW]),
        actionOptions: {
          details: {
            displayStatus: this.context.hasPermission([CLIENTS_LIST]),
            status: true,
            onClick: this.onDetailsClick
          },
          update: {
            status: false
          },
          createVisit: {
            displayStatus: this.context.hasPermission([VISITS_CREATE]),
            status: this.context.hasPermission([VISITS_CREATE]),
            onClick: this.onCreateVisitClick
          },
          remove: {
            status: false
          }
        }
      }
    ];
  }

  onDetailsClick = ({ id }) => {
    this.props.history.push(insertPathParams(ROUTE_CLIENTS_DETAILS, { id }));
  };

  onCreateVisitClick = ({ id }) => {
    this.props.history.push({
      pathname: insertPathParams(ROUTE_VISITS_CREATE, {
        client: { client_id: id }
      }),
      state: {
        client: {
          client_id: id
        }
      }
    });
  };

  render() {
    const { clients, sort, setSort, loading, bodyText } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={clients}
          sort={sort}
          setSort={setSort}
          hover
          actions
          loading={loading}
          bodyText={bodyText}
        />
      </>
    );
  }
}

LongAgoVisitedClientsTable.defaultProps = {
  bodyText: null
};

LongAgoVisitedClientsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  bodyText: PropTypes.string
};

export default withRouter(LongAgoVisitedClientsTable);
