import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class TargetsBonusesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.targetsBonusesPath = 'targets/bonuses';
    this.targetBonusPath = 'targets/bonuses/:id';
    this.updateTargetBonusDataPath = 'targets/bonuses/:id/data';
    this.updateTargetBonusValuePath = 'targets/bonuses/:id/value';
    this.confirmBonusPath = 'targets/bonuses/:id/confirm';
    this.setPaidStatusPath = 'targets/bonuses/:id/paid';
    this.suspendBonusPath = 'targets/bonuses/:id/suspend';
    this.unsuspendBonusPath = 'targets/bonuses/:id/undo-suspension';
  }

  getTargetsBonuses(pathParams) {
    return this.get(this.targetsBonusesPath, pathParams);
  }

  getTargetBonus(id) {
    return this.get(insertPathParams(this.targetBonusPath, { id }));
  }

  updateTargetBonusData(id, payload) {
    return this.put(
      insertPathParams(this.updateTargetBonusDataPath, { id }),
      payload
    );
  }

  updateTargetBonusValue(id, payload) {
    return this.put(
      insertPathParams(this.updateTargetBonusValuePath, { id }),
      payload
    );
  }

  confirmTargetBonus(id) {
    return this.put(insertPathParams(this.confirmBonusPath, { id }));
  }

  setPaidStatus(id) {
    return this.put(insertPathParams(this.setPaidStatusPath, { id }));
  }

  suspendBonus(id, payload) {
    return this.put(insertPathParams(this.suspendBonusPath, { id }), payload);
  }

  unsuspendBonus(id) {
    return this.put(insertPathParams(this.unsuspendBonusPath, { id }));
  }
}

export default new TargetsBonusesApi();
