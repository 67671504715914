export default {
  Users: 'Użytkownicy',
  'Adding an user': 'Dodawanie Użytkownika',
  'User details': 'Szczegóły użytkownika',
  'User update': 'Edycja użytkownika',
  'Assign password': 'Samodzielnie przypisz hasło',
  'Assign employees who do not have a supervisor in the selected department':
    'Przypisz pracowników nie posiadających przełożonego w wybranym dziale',
  'Choose a supervisor within the department: <%=name%>':
    'Wybierz przełożonego w ramach działu: <%=name%>',
  'Deleting user successfully finish':
    'Usuwanie użytkownika zakończone sukcesem',
  'Are you sure you want to delete the user <%= first_name %> <%= last_name %>':
    'Czy na pewno chcesz usunąć użytkownika <%= first_name %> <%= last_name %>',
  'You cannot delete Your own account, or user with open targets.':
    'Nie możesz usunąć własnego konta ani użytkownika z otwartymi celami.',
  'User does not belong to your department':
    'Użytkownik nie należy do Twojego działu',
  'User is not your subordinate': 'Użytkownik nie jest Twoim podwładnym',
  'You cannot use that account': 'Nie można użyć tego użytkownika',
  'Personal data': 'Dane osobowe',
  'Organizational data': 'Dane organizacyjne',
  'Clients divided into regions': 'Klienci podzieleni na regiony',
  'Customers with an unrecognized postal code':
    'Klienci z nierozpoznanym kodem pocztowym',
  'Customers with an unrecognized cities name':
    'Klienci z nierozpoznanym miastem',
  Unrelated: 'Niezidentyfikowani',
  'Unrelated Clients': 'Niezidentyfikowani klienci',
  'No unrelated clients found':
    'Nie znaleziono niezidentyfikowanych klientów w systemie',
  'Assign clients to user': 'Przypisz klientów do użytkownika',
  'Are you sure you want to remove assigment with client <%=name%>':
    'Czy na pewno chcesz usunąć przypisanie do klienta <%=name%>',
  'Are you sure you want to remove all assigment clients from <%=postalCodeLevel%> <%=name%>':
    'Czy na pewno chcesz usunąć przypisanie wszystkich klientów z <%=postalCodeLevel%> <%=name%>',
  'Are you sure you want to remove all clients with unrecognized cities from subregion <%=name%>':
    'Czy na pewno chcesz usunąć wszystkich klientów z nierozpoznanym miastem z podregionu <%=name%>',
  'Select role and country first': 'Wybierz najpierw role oraz kraj',
  'Basic data': 'Dane podstawowe',
  'User configuration': 'Konfiguracja użytkownika',
  'Select department first': 'Wybierz najpierw dział',
  'Department have no manager': 'Dział nie posiada menadżera',
  'remove assignment': 'usuń powiązanie',
  "Role can be change only by admins and for users that don't have opened targets.":
    'Rola może zostać zmieniona tylko przez administratorów oraz dla użytkowników, którzy nie mają otwartych celów.',
  'Current or previous department': 'Obecny lub poprzedni dział',
  'User has no approved sales results':
    'Użytkownik nie posiada zatwierdzonych wyników sprzedażowych',
  'The possibility to summarize the sales period can be found in the department details.':
    'Możliwość podsumowania okresu sprzedażowego znajduje sie na szczegółach działu.',
  'All employees in the department are assigned to managers':
    'Wszyscy pracownicy w dziale są przypisani do kierowników',
  "Don't choose a department manager": 'Nie wybieraj kierownika',
  'My departments': 'Moje działy',
  'My department': 'Mój dział'
};
