import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t, { availableLocals } from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import { ROUTE_PRODUCT_CATEGORIES_LIST } from 'routing/routes/ProductCategories';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import { formatProductCategoriesError } from 'modules/ProductCategories/utils/formater';
import formatFormMultiLangObj from 'modules/Shared/utils/formatFormMultiLangObj';
import Checkbox from 'modules/Layout/component/Checkbox';

class ProductCategoriesCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        group_id: props.productCategoriesContext.selectedGroupId,
        category_id: props.productCategoriesContext.selectedCategoryId,
        name: formatFormMultiLangObj(),
        include_in_targets: true
      },
      validation: {
        pl: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Name') })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  handleResponse(res) {
    const { language } = this.props;

    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.productCategoriesContext.setStateAfterCreate(res, language);
    this.props.history.push(ROUTE_PRODUCT_CATEGORIES_LIST);
  }

  handleError(err) {
    const { message, errors } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = formatProductCategoriesError(errors);
      this.setState({
        validation: { ...this.state.validation, ...newValidateState }
      });
      this.props.setAlert(message);
    } else if (err instanceof ApiError) {
      this.props.setAlert(message);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState({
      formData: {
        ...this.state.formData,
        name: { ...this.state.formData.name, [e.target.name]: e.target.value }
      },
      validation: {
        ...this.state.validation,
        [e.target.name]: {
          ...this.state.validation[e.target.name],
          status: false
        }
      }
    });
  }

  onCheckboxChange(e) {
    this.setState(prevState => {
      const { checked } = e.target;

      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          include_in_targets: checked
        }
      };
    });
  }

  runValidation() {
    const [isValid, newValidateState] = validate(
      this.state.formData.name,
      this.state.validation
    );
    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await ProductCategoriesApi.createProductCategories(
        this.state.formData
      );
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      selectedGroupName,
      selectedCategoryName
    } = this.props.productCategoriesContext;
    const {
      formData: { group_id, category_id, name, include_in_targets },
      validation,
      loading
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        {group_id && (
          <TextDataSet label='Product group name' data={selectedGroupName} />
        )}

        {category_id && (
          <TextDataSet
            label='Product category name'
            data={selectedCategoryName}
          />
        )}

        <Typography component='h6' variant='h6'>
          {t('Name')}
        </Typography>

        <Box className='language-form-box'>
          {availableLocals.map(local => (
            <Input
              key={`name-${local.slug}`}
              name={local.slug}
              label={local.name}
              value={name[local.slug]}
              required={local.slug === 'pl'}
              onChange={this.onChange}
              errorStatus={validation[local.slug]?.status}
              errorText={validation[local.slug]?.message}
            />
          ))}
        </Box>
        {!group_id && !category_id && (
          <Checkbox
            name='include_in_targets'
            label='Include the group when calculating goals'
            checked={include_in_targets}
            onChange={this.onCheckboxChange}
          />
        )}
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link to={ROUTE_PRODUCT_CATEGORIES_LIST} className='router-button'>
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

ProductCategoriesCreateForm.propTypes = {
  setAlert: PropTypes.func.isRequired,
  productCategoriesContext: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedCategoryId: PropTypes.number,
    selectedGroupName: PropTypes.string,
    selectedCategoryName: PropTypes.string,
    setStateAfterCreate: PropTypes.func.isRequired
  }).isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(ProductCategoriesCreateForm);
