import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper, Typography } from '@material-ui/core';
import t from 'translate/translate';

import ProductDetailsBasicDataSets from 'modules/Products/component/Details/BasicData/DataSets';
import ProductDetailsPriorities from 'modules/Products/component/Details/BasicData/Priorities/List';
import { useContext } from 'react';
import AuthContext from 'modules/Auth/context/Auth/authContext';

const ProductDetailsBasicData = props => {
  const {
    user: { language: userLang }
  } = useContext(AuthContext);

  const { product, countries, taxes, fetchProduct } = props;

  return (
    <Grid container spacing={3} justify='center'>
      <Grid item xs={12} lg={6}>
        <Paper className='p1'>
          <ProductDetailsBasicDataSets
            product={product}
            countries={countries}
            taxes={taxes}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Typography variant='h6'>{t('Description')}</Typography>
              {product.description[userLang]}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <ProductDetailsPriorities
              product={product}
              fetchProduct={fetchProduct}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ProductDetailsBasicData.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  taxes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  fetchProduct: PropTypes.func.isRequired
};

export default withRouter(ProductDetailsBasicData);
