import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import useStyles from 'modules/Layout/component/Header/style';
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SyncIcon from '@material-ui/icons/Sync';
import Breadcrumb from 'modules/Layout/component/Breadcrumb';

import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import t from 'translate/translate';
import { roles } from 'api/auth/roles';
import { ROUTE_DASHBOARD } from 'routing/routes/Dashboard';

const Header = props => {
  const classes = useStyles();
  const { onDrawerToggle } = props;

  const {
    logout,
    impersonateRootId,
    impersonate,
    user: { first_name: firstName, last_name: lastName, role, departments }
  } = useContext(AuthContext);

  const { currentPage } = useContext(CurrentPageContext);

  const history = useHistory();

  const onLogout = () => logout();

  const impersonateCallback = () => history.push(ROUTE_DASHBOARD);

  return (
    <div>
      <AppBar
        color='primary'
        position='sticky'
        elevation={0}
        className={classes.header}
      >
        <Toolbar>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            width={1}
          >
            <IconButton
              style={{ color: 'white' }}
              aria-label='open drawer'
              onClick={onDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Box display='flex' flexDirection='row'>
              <Typography
                style={{ color: 'white' }}
                variant='body1'
                component='h1'
              >
                {firstName} {lastName}
              </Typography>
              <Hidden xsDown>
                <Box mx={3}>
                  <Typography
                    style={{ color: 'white' }}
                    variant='body1'
                    component='h3'
                  >
                    {t('Role')}
                    {':'} {t(roles.find(item => item.slug === role).name)}
                  </Typography>
                </Box>
              </Hidden>
              <Hidden xsDown>
                {!isEmpty(departments) && (
                  <Typography
                    style={{ color: 'white' }}
                    variant='body1'
                    component='h3'
                  >
                    {t('Departments')}
                    {':'}{' '}
                    {departments.map(department => department.name).join(', ')}
                  </Typography>
                )}
              </Hidden>
            </Box>
            <Box>
              {impersonateRootId && (
                <Button
                  startIcon={<SyncIcon />}
                  onClick={() => impersonate(null, impersonateCallback)}
                >
                  <Hidden smDown>{t('Go back to own account')}</Hidden>
                </Button>
              )}
              <Button startIcon={<ExitToAppIcon />} onClick={onLogout}>
                <Hidden smDown>{t('Log out')}</Hidden>
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        className={classes.breadcrumb}
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        width={1}
        py={1}
        px={3}
      >
        <Typography variant='subtitle1' component='h2' color='textSecondary'>
          {t(currentPage)}
        </Typography>
        <Breadcrumb />
      </Box>
    </div>
  );
};

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired
};

export default Header;
