import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import { formatMetaToPagination } from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';

import ClientDetailsNewProductsFilter from 'modules/Clients/component/Details/NewProducts/Filter';
import ClientDetailsNewProductsTable from 'modules/Clients/component/Details/NewProducts/Table';
import ProductsApi from 'api/connections/Products/ProductsApi';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class ClientDetailsNewProducts extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      products: undefined,
      allProducts: undefined,
      sort: {},
      filter: {
        sku: '',
        my_products: !context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR])
      },
      filterValidation: {},
      pagination: {
        total: 0,
        page: 1,
        per_page: 15
      }
    };

    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchProducts = this.fetchProducts.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
    this.fetchProducts();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchProducts()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchProducts()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchProducts()
    );
  }

  async fetchData() {
    try {
      const {
        data: { data: allProducts }
      } = await ProductsApi.getAllProducts();

      this.setState({ allProducts });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchProducts() {
    const {
      pagination: { page, per_page },
      filter,
      sort
    } = this.state;

    try {
      this.setState({
        loading: true
      });

      const {
        data: { data: products, meta }
      } = await ProductsApi.getProducts({
        new_for_client_id: this.props.client.id,
        ...filter,
        ...sort,
        per_page,
        page
      });

      this.setState({
        products,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      products,
      allProducts,
      sort,
      pagination,
      filter,
      filterValidation,
      loading
    } = this.state;

    if (!products) return <Loader />;

    return (
      <Grid container justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <ClientDetailsNewProductsFilter
              filter={filter}
              validation={filterValidation}
              setFilter={this.setFilter}
              allProducts={allProducts}
            />
            <ClientDetailsNewProductsTable
              products={products}
              sort={sort}
              pagination={pagination}
              setSort={this.setSort}
              setPagination={this.setPagination}
              loading={loading}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ClientDetailsNewProducts.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default ClientDetailsNewProducts;
