export default {
  Currencies: 'Waluty',
  Currency: 'Waluta',
  'Currency details': 'Szczegóły waluty',
  'change name': 'zmień nazwę',
  'Update currency': 'Edytuj walute',
  'Create currency': 'Dodaj walutę',
  'Are you sure you want to delete currency <%=currencyName%>?':
    'Czy na pewno chcesz usunać walutę <%=currencyName%>?',
  'Are you sure you want to delete rate with value <%=rateValue%>?':
    'Czy na pewno chcesz usunać kurs o wartości <%=rateValue%>?',
  'Currency rates': 'Kursy waluty',
  'Create rate': 'Dodaj kurs',
  'Update rate': 'Edytuj kurs',
  'The PLN currency cannot be updated': 'Waluta PLN nie może być modyfikowana'
};
