import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { TAXES_CREATE } from 'api/auth/permissions/Taxes';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import TaxesApi from 'api/connections/Taxes/TaxesApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import TaxesTable from 'modules/Taxes/component/List/Table';
import TaxesFilter from 'modules/Taxes/component/List/Filter';
import TaxFormModal from 'modules/Taxes/component/Modals/Form';
import TaxDeleteModal from 'modules/Taxes/component/Modals/Delete';

class TaxesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      filter: {},
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      taxes: [],
      countries: [],
      loading: true,
      actionModals: {
        modifyTax: null,
        formModalStatus: false,
        deleteModalStatus: false
      }
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchTaxes = this.fetchTaxes.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Taxes');
    if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
      return this.fetchData();
    }
    this.fetchTaxes();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onCreateClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true
      }
    }));
  }

  onUpdateClick(data) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        modifyTax: data,
        formModalStatus: true
      }
    }));
  }

  onDeleteClick(data) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        modifyTax: {
          ...data,
          countryName: this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])
            ? state.countries.find(c => c.id === data.country_id).name
            : this.context.user.country_name
        },
        deleteModalStatus: true
      }
    }));
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchTaxes()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchTaxes()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchTaxes()
    );
  }

  async fetchData() {
    const {
      pagination: { per_page, page }
    } = this.state;

    try {
      const [
        {
          data: { data: taxes, meta }
        },
        {
          data: { data: countries }
        }
      ] = await Promise.all([
        TaxesApi.getTaxes({
          per_page,
          page
        }),
        PostalCodesApi.getCountries({ per_page: Number.MAX_SAFE_INTEGER })
      ]);

      this.setState({
        taxes,
        countries,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchTaxes() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: taxes, meta }
      } = await TaxesApi.getTaxes({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        taxes,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  closeAllModals() {
    this.setState({
      actionModals: {
        modifyTax: null,
        updateModalStatus: false,
        deleteModalStatus: false
      }
    });
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Authorize permissions={[TAXES_CREATE]}>
            <IconButton
              className='create-icon'
              onClick={this.onCreateClick}
              icon={<AddIcon fontSize='large' />}
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const {
      taxes,
      countries,
      filter,
      sort,
      loading,
      filterValidation,
      actionModals: { modifyTax, formModalStatus, deleteModalStatus }
    } = this.state;

    return (
      <div>
        {this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
          <TaxesFilter
            filter={filter}
            validation={filterValidation}
            setFilter={this.setFilter}
            countries={countries}
          />
        )}
        {this.renderActions()}
        <TaxesTable
          sort={sort}
          setSort={this.setSort}
          taxes={taxes}
          countries={countries}
          loading={loading}
          onUpdateClick={this.onUpdateClick}
          onDeleteClick={this.onDeleteClick}
        />
        {this.renderActions()}
        {formModalStatus && (
          <TaxFormModal
            tax={modifyTax}
            countries={countries}
            setAlert={this.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchTaxes}
          />
        )}
        {deleteModalStatus && (
          <TaxDeleteModal
            tax={modifyTax}
            handleError={this.handleError}
            setAlert={this.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchTaxes}
          />
        )}
      </div>
    );
  }
}

TaxesList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default TaxesList;
