import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import ProductsBatchesApi from 'api/connections/Products/ProductsBatchesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const ProductBatchDeleteDialog = props => {
  const {
    batch: { id: batchId, number, expiration_date },
    productId,
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteContact = async () => {
    try {
      await ProductsBatchesApi.deleteProductBatch({
        productId,
        batchId
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess(batchId);
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      }
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete batch with batch number <%=number%> and expiration date equal to <%=expiration_date%>',
        {
          number,
          expiration_date
        }
      )}
      onApprove={deleteContact}
      onCancel={onClose}
    />
  );
};

ProductBatchDeleteDialog.propTypes = {
  batch: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    expiration_date: PropTypes.string
  }).isRequired,
  productId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ProductBatchDeleteDialog;
