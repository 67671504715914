import { DEPARTMENT_MANAGER, DIRECTOR } from 'api/auth/roles';

const setUpdateActionDisableStatus = (loggedUser, departments) => {
  const {
    role,
    id: loggedUserId,
    departments: loggedUserDepartments
  } = loggedUser;

  if (role === DIRECTOR) {
    return !departments
      .map(d => d.id)
      .some(element => loggedUserDepartments.map(d => d.id).includes(element));
  }

  if (role === DEPARTMENT_MANAGER) {
    return !departments
      .map(department => department.manager_id)
      .includes(loggedUserId);
  }

  return false;
};

export default setUpdateActionDisableStatus;
