import { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  IconButton as MaterialUiIconButton,
  Tooltip,
  Box
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useWindowDimensions } from 'modules/Shared/hook';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '1rem'
  },
  float: {
    position: 'fixed',
    bottom: '50px',
    right: '50px',
    zIndex: 3000
  },
  iconSize: {
    fontSize: '2rem'
  }
}));

const InfoTooltip = props => {
  const classes = useStyles();

  const { text, float, interactive } = props;

  const { width } = useWindowDimensions();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (width <= 1400) {
    return (
      <Box className={clsx(float && classes.float)}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={text}
              placement='bottom-start'
              interactive={interactive}
              classes={{
                tooltip: classes.tooltip
              }}
            >
              <MaterialUiIconButton onClick={handleTooltipOpen}>
                <InfoIcon
                  className={clsx('impersonate-icon', classes.iconSize)}
                />
              </MaterialUiIconButton>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </Box>
    );
  }

  return (
    <Box className={clsx(float && classes.float)}>
      <Tooltip
        title={text}
        placement='bottom-start'
        interactive
        classes={{
          tooltip: classes.tooltip
        }}
      >
        <MaterialUiIconButton>
          <InfoIcon className={clsx('impersonate-icon', classes.iconSize)} />
        </MaterialUiIconButton>
      </Tooltip>
    </Box>
  );
};

InfoTooltip.defaultProps = {
  float: false,
  interactive: false
};

InfoTooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  float: PropTypes.bool,
  interactive: PropTypes.bool
};

export default InfoTooltip;
