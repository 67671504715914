import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';

import SalesResultsCharts from 'modules/SalesResults/component/ResultsDetails/Charts';
import SalesResultsNavigationBar from 'modules/SalesResults/component/ResultsDetails/NavigationBar';
import SalesResultsLegend from 'modules/SalesResults/component/ResultsDetails/Legend';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_SALES_RESULT_LIST } from 'routing/routes/SalesResults';
import SalesResultsApi from 'api/connections/SalesResults/SalesResultsApi';
import formatSalesResults from 'modules/SalesResults/utils/formatSalesResults';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import Loader from 'modules/Layout/component/Loader';
import SalesResultPeriodPicker from 'modules/SalesResults/component/ResultsDetails/PeriodPicker';
import { withRouter } from 'react-router-dom';

class SalesResultsDepartmentWrapper extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      stack: [],
      breadcrumbs: [t('Comprehensive data')],
      viewType: 'pie',
      renderData: null,

      salesResultData: {},
      periodData: {
        period_type: props.month ? 'month' : '',
        quarter: '',
        period: props.month || ''
      }
    };

    this.drillUp = this.drillUp.bind(this);
    this.drillDown = this.drillDown.bind(this);
    this.onViewTypeChange = this.onViewTypeChange.bind(this);
    this.onAdvancedParametersChange = this.onAdvancedParametersChange.bind(
      this
    );
  }

  componentDidMount() {
    if (!this.props.advancedDetails) {
      this.fetchData();
    }
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_SALES_RESULT_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onViewTypeChange(viewType) {
    this.setState({ viewType });
  }

  async onAdvancedParametersChange(e) {
    const { name, value } = e.target;

    this.setState(
      prevState => {
        return {
          ...prevState,
          periodData: {
            ...prevState.periodData,
            period: name === 'period_type' ? '' : prevState.periodData.period,
            quarter: name === 'period_type' ? '' : prevState.periodData.quarter,
            [name]: value
          },
          salesResultData:
            name === 'period_type' ? {} : prevState.salesResultData,
          renderData: name === 'period_type' ? null : prevState.renderData
        };
      },
      () => {
        const { period, period_type, quarter } = this.state.periodData;

        if (period_type === 'quarter') {
          if (period && quarter) return this.fetchData();
          return null;
        }
        if (period) return this.fetchData();
      }
    );
  }

  drillDown(data) {
    if (data.children.length > 0) {
      this.setState(state => {
        return {
          stack: [...state.stack, state.renderData],
          renderData: data.children,
          breadcrumbs: [...state.breadcrumbs, data.name]
        };
      });
    }
  }

  drillUp(index) {
    this.setState(state => {
      if (state.breadcrumbs.length <= 1) return { ...state };
      return {
        renderData: state.stack[index],
        breadcrumbs: state.breadcrumbs.slice(0, index + 1),
        stack: state.stack.slice(0, index)
      };
    });
  }

  async fetchData() {
    const { period_type, period, quarter } = this.state.periodData;

    try {
      const {
        data: salesResultData
      } = await SalesResultsApi.getSalesResultsDetails({
        department_id: this.props.departmentId,
        period_type,
        period,
        quarter
      });

      const formattedSalesResultsData = formatSalesResults(
        salesResultData.data
      );

      this.setState({
        salesResultData,
        renderData: formattedSalesResultsData,
        stack: [],
        breadcrumb: []
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { renderData, breadcrumbs, salesResultData, periodData } = this.state;
    const { advancedDetails } = this.props;
    const nameSuffix = Date.now();

    const viewType = this.props.viewType ?? this.state.viewType;

    if (!salesResultData.period && !advancedDetails) return <Loader />;

    return (
      <Box display='flex' flexDirection='column'>
        <SalesResultPeriodPicker
          advancedDetails={advancedDetails}
          periodData={periodData}
          departmentName={salesResultData.department_name}
          onChange={this.onAdvancedParametersChange}
        />
        {!renderData || renderData.length === 0 ? (
          <Box display='flex' justifyContent='center'>
            <Typography color='textPrimary'>{t('No data')}</Typography>
          </Box>
        ) : (
          <>
            <SalesResultsNavigationBar
              breadcrumbs={breadcrumbs}
              onDrillUp={this.drillUp}
              viewType={viewType}
              onViewTypeChange={this.onViewTypeChange}
            />
            <SalesResultsCharts
              salesResultsData={renderData}
              viewType={viewType}
              onDrillDown={this.drillDown}
              nameSuffix={nameSuffix}
            />
            <SalesResultsLegend nameSuffix={nameSuffix} />
          </>
        )}
      </Box>
    );
  }
}

SalesResultsDepartmentWrapper.defaultProps = {
  month: null,
  viewType: null
};

SalesResultsDepartmentWrapper.propTypes = {
  departmentId: PropTypes.number.isRequired,
  advancedDetails: PropTypes.bool.isRequired,
  month: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  viewType: PropTypes.string
};

export default withRouter(SalesResultsDepartmentWrapper);
