import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import { withRouter } from 'react-router-dom';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_WHOLESALER_FILE_UPDATE } from 'routing/routes/Wholesalers';
import { ADMIN, SUPER_ADMIN, DIRECTOR } from 'api/auth/roles';
import DeleteWholesalerFileDialog from 'modules/Wholesalers/component/Details/Files/DeleteDialog';

class WholesalerFilesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.wholesalerId = this.props.wholesalerId;
    this.state = {
      deleteDialogOpen: false,
      fileToDelete: null
    };

    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  onUpdateClick({ id: fileId }) {
    this.props.history.push({
      pathname: insertPathParams(ROUTE_WHOLESALER_FILE_UPDATE, {
        fileId,
        wholesalerId: this.wholesalerId
      })
    });
  }

  onDeleteClick(file) {
    this.setState({ deleteDialogOpen: true, fileToDelete: file });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, fileToDelete: null });
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'description',
        label: 'Description',
        sortable: false
      },
      {
        property: 'creator_name',
        label: 'Creator name',
        sortable: true
      },
      {
        property: 'created_at',
        label: 'Created at',
        sortable: true
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: false,
            disable: () => true,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: true,
            disable: ({ creator_id }) =>
              !(
                this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) ||
                creator_id === this.context.user.id
              ),
            disableMsg: 'You are not a creator of this file',
            onClick: this.onUpdateClick
          },
          remove: {
            status: true,
            disable: ({ creator_id }) =>
              !(
                this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) ||
                creator_id === this.context.user.id
              ),
            disableMsg: 'You are not a creator of this file',
            onClick: this.onDeleteClick
          },
          download: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.props.fetchWholesalerFile
          }
        }
      }
    ];
  }

  render() {
    const { files, sort, setSort, onDeleteFileSuccess } = this.props;
    const { deleteDialogOpen, fileToDelete } = this.state;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={files}
          sort={sort}
          setSort={setSort}
          dense
          actions
        />
        {deleteDialogOpen && (
          <DeleteWholesalerFileDialog
            file={fileToDelete}
            wholesalerId={this.wholesalerId}
            onClose={this.onDeleteDialogClose}
            onSuccess={onDeleteFileSuccess}
          />
        )}
      </>
    );
  }
}

WholesalerFilesList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  wholesalerId: PropTypes.number.isRequired,
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  onDeleteFileSuccess: PropTypes.func.isRequired,
  fetchWholesalerFile: PropTypes.func.isRequired
};

export default withRouter(WholesalerFilesList);
