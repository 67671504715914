import BaseApiConfig from 'api/connections/BaseApiConfig';

class ProfileApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.accountPath = 'account';
    this.confirmEmailPath = 'account/confirm-email';
  }

  getUser() {
    return this.get(this.accountPath);
  }

  updateProfile(payload) {
    return super.put(this.accountPath, payload);
  }

  confirmEmail(payload) {
    return super.post(this.confirmEmailPath, payload);
  }
}

export default new ProfileApi();
