import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import {
  ADMIN,
  DIRECTOR,
  SUPER_ADMIN,
  DEPARTMENT_MANAGER,
  PRODUCT_MANAGER,
  CLIENT,
  getSpecificRoles
} from 'api/auth/roles';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class UsersFilter extends Filter {
  static contextType = AuthContext;

  static formatRolesOptions(role) {
    switch (role) {
      case SUPER_ADMIN:
        return getSpecificRoles([CLIENT]);
      case ADMIN: {
        return getSpecificRoles([CLIENT, SUPER_ADMIN]);
      }
      case DIRECTOR: {
        return getSpecificRoles([CLIENT, SUPER_ADMIN, ADMIN, DIRECTOR]);
      }
      case PRODUCT_MANAGER: {
        return getSpecificRoles([CLIENT, SUPER_ADMIN, ADMIN]);
      }
      default: {
        return getSpecificRoles([
          CLIENT,
          SUPER_ADMIN,
          ADMIN,
          DIRECTOR,
          DEPARTMENT_MANAGER
        ]);
      }
    }
  }

  constructor(props, context) {
    super(props);

    this.roleOptions = UsersFilter.formatRolesOptions(context.user.role);
  }

  getInputs() {
    return [
      {
        groupLabel: '',
        children: [
          {
            ...this.getBasicInputValues('my_departments'),
            label: this.context.hasRole([DEPARTMENT_MANAGER])
              ? 'My department'
              : 'My departments',
            type: 'checkbox',
            displayStatus: this.context.hasRole([DEPARTMENT_MANAGER, DIRECTOR])
          }
        ]
      },
      {
        groupLabel: 'Personal data',
        children: [
          {
            ...this.getBasicInputValues('first_name'),
            label: 'First name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('last_name'),
            label: 'Last name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('email'),
            label: 'Email',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: 'Organizational data',
        children: [
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'select',
            displayStatus: this.context.hasRole([SUPER_ADMIN]),
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name')
          },
          {
            ...this.getBasicInputValues('role'),
            label: 'Role',
            type: 'select',
            emptyValueText: 'All roles',
            options: formatOptions(this.roleOptions, 'slug', 'name', true)
          },
          {
            ...this.getBasicInputValues('department_id'),
            label: 'Department',
            type: 'autocomplete',
            displayStatus: this.context.hasPermission([DEPARTMENTS_LIST]),
            options: formatOptions(this.props.departments, 'id', 'name')
          },
          {
            ...this.getBasicInputValues('product_group_name'),
            label: 'Product group',
            type: 'text'
          }
        ]
      }
    ];
  }
}

export default UsersFilter;
