import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_CLIENTS_CONTRACTS_LIST } from 'routing/routes/ClientsContracts';
import ClientsContractsApi from 'api/connections/ClientsContracts/ClientsContractsApi';
import ClientsContractsDataSets from 'modules/ClientsContracts/component/Details/DataSets';
import ClientsContractsDetailsBonuses from 'modules/ClientsContracts/component/Details/Bonuses';
import ClientsContractsDetailsThresholds from 'modules/ClientsContracts/component/Details/Thresholds';

class ClientsContractsDetails extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.clientContractId = parseInt(props.match.params.id, 10);

    this.state = {
      clientContract: null
    };

    this.handleError = this.handleError.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    if (!this.clientContractId) {
      this.props.history.push(ROUTE_CLIENTS_CONTRACTS_LIST);
    }
    this.props.contextMethods.setCurrentPage('Client contract details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_CLIENTS_CONTRACTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: clientContract }
      } = await ClientsContractsApi.getClientContract(this.clientContractId);

      this.setState({ clientContract });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { clientContract } = this.state;

    if (!clientContract) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <ClientsContractsDataSets
              data={clientContract}
              fetchSalesContract={this.fetchData}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <ClientsContractsDetailsThresholds
              bonuses={clientContract.bonuses}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className='p1'>
            <ClientsContractsDetailsBonuses clientContract={clientContract} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ClientsContractsDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ClientsContractsDetails);
