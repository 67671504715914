import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import renderPrice from 'modules/Products/utils/renderPrice';

class SalesTable extends Component {
  static getCols() {
    return [
      {
        property: 'product_sku',
        label: 'Name',
        sortable: true
      },
      {
        property: 'client_name',
        label: 'Client',
        sortable: true
      },
      {
        property: 'buy_date',
        label: 'Buy date',
        sortable: true
      },
      {
        property: 'price',
        label: 'Price',
        sortable: true,
        nullable: true,
        render: (value, { currency }) => renderPrice(value, currency)
      },
      {
        property: 'quantity',
        label: 'Quantity',
        sortable: true
      },
      {
        property: 'id',
        label: 'The value of sales',
        sortable: false,
        render: (_, { price, quantity, currency }) =>
          renderPrice(quantity * price, currency)
      },
      {
        property: 'wholesaler_name',
        label: 'Wholesaler',
        sortable: true
      },
      {
        property: 'wholesaler_product_code',
        label: 'Wholesaler product code',
        sortable: true
      },
      {
        property: 'wholesaler_product_name',
        label: 'Wholesaler product name',
        sortable: true
      },
      {
        property: 'wholesaler_client_name',
        label: 'Wholesaler client name',
        sortable: true
      }
    ];
  }

  render() {
    const { sales, sort, setSort, loading } = this.props;

    return (
      <Table
        cols={SalesTable.getCols()}
        rows={sales}
        sort={sort}
        setSort={setSort}
        loading={loading}
      />
    );
  }
}

SalesTable.defaultProps = {
  sales: []
};

SalesTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  sales: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired
};

export default SalesTable;
