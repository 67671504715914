export const SUPER_ADMIN = 'super_admin';
export const ADMIN = 'admin';
export const DIRECTOR = 'director';
export const DEPARTMENT_MANAGER = 'department_manager';
export const CLIENT = 'client';
export const TRADER = 'trader';
export const TELEMARKETER = 'telemarketer';
export const KEY_ACCOUNT_MANAGER = 'key_account_manager';
export const PRODUCT_MANAGER = 'product_manager';

export const roles = [
  { slug: SUPER_ADMIN, name: 'Super Admin' },
  { slug: ADMIN, name: 'Admin' },
  { slug: DIRECTOR, name: 'Director' },
  { slug: DEPARTMENT_MANAGER, name: 'Department Manager' },
  { slug: CLIENT, name: 'Client' },
  { slug: TRADER, name: 'Trader' },
  { slug: TELEMARKETER, name: 'Telemarketer' },
  { slug: KEY_ACCOUNT_MANAGER, name: 'Key account manager' },
  { slug: PRODUCT_MANAGER, name: 'Product manager' }
];

export const getSpecificRoles = unwantedRoles => {
  return roles.filter(role => !unwantedRoles.includes(role.slug));
};
