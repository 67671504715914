import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  column: {
    border: '1px solid #bdbdbd',
    borderRadius: '10px',
    height: '100%',
    padding: theme.spacing(1)
  },
  item: {
    backgroundColor: 'white',
    borderRadius: '10px',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  text: {
    flex: 'unset',
    minWidth: 'unset'
  }
}));

export default useStyles;
