import { Box } from '@material-ui/core';
import SalesResultsViewTypeButtons from 'modules/SalesResults/component/ResultsDetails/ViewTypeButtons';
import PropTypes from 'prop-types';
import SalesComparisonBreadcrumbs from 'modules/Statistics/SalesComparison/component/Comparison/Breadcrumb';

const SalesComparisonNavigationBar = props => {
  const { breadcrumbs, viewType, onViewTypeChange, onDrillUp } = props;

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='4rem'
    >
      <Box width={1} display='flex' justifyContent='center' flexGrow={1}>
        <SalesComparisonBreadcrumbs
          onDrillUp={onDrillUp}
          breadcrumbs={breadcrumbs}
        />
      </Box>
      <Box display='flex' justifyContent='flex-end' width={3}>
        <SalesResultsViewTypeButtons
          viewType={viewType}
          onViewTypeChange={onViewTypeChange}
        />
      </Box>
    </Box>
  );
};

SalesComparisonNavigationBar.defaultProps = {
  onViewTypeChange: undefined
};

SalesComparisonNavigationBar.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDrillUp: PropTypes.func.isRequired,
  viewType: PropTypes.string.isRequired,
  onViewTypeChange: PropTypes.func
};

export default SalesComparisonNavigationBar;
