import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import renderPrice from 'modules/Products/utils/renderPrice';

class StatisticsProductsTable extends Component {
  static contextType = AuthContext;

  static getCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false
      },
      {
        property: 'group',
        label: 'Group',
        sortable: false
      },
      {
        property: 'category',
        label: 'Category',
        sortable: false
      },
      {
        property: 'subcategory',
        label: 'Subcategory',
        sortable: false
      },
      {
        property: 'quantity_sum',
        label: 'Quantity',
        sortable: true
      },
      {
        property: 'price_sum',
        label: 'Value',
        sortable: true,
        render: (value, { currency }) => renderPrice(value, currency)
      }
    ];
  }

  render() {
    const { statistics, sort, setSort, loading, bodyText } = this.props;

    return (
      <>
        <Table
          cols={StatisticsProductsTable.getCols()}
          rows={statistics}
          sort={sort}
          setSort={setSort}
          loading={loading}
          bodyText={bodyText}
        />
      </>
    );
  }
}

StatisticsProductsTable.defaultProps = {
  statistics: [],
  bodyText: null
};

StatisticsProductsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  statistics: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  bodyText: PropTypes.string
};

export default StatisticsProductsTable;
