import Filter from 'modules/Layout/component/List/Filter';
import parse from 'date-fns/parse';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/DatePicker';
import addYears from 'date-fns/addYears';

class StatisticsSalesComparisonFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: '',
        children: [
          {
            ...this.getBasicInputValues('date_from'),
            label: 'Date from',
            type: 'date',
            maxDate: this.props.filter.date_to
              ? parse(this.props.filter.date_to, API_DATE_FORMAT, new Date())
              : addYears(new Date(), 20)
          },
          {
            ...this.getBasicInputValues('date_to'),
            label: 'Date to',
            type: 'date',
            minDate: this.props.filter.date_from
              ? parse(this.props.filter.date_from, API_DATE_FORMAT, new Date())
              : addYears(new Date(), -20)
          },
          {
            ...this.getBasicInputValues('product_sku'),
            label: 'Product name',
            type: 'text'
          }
        ]
      }
    ];
  }
}

export default StatisticsSalesComparisonFilter;
