import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import Table from 'modules/Layout/component/List/Table';
import { SUPER_ADMIN } from 'api/auth/roles';
import {
  DEPARTMENTS_UPDATE,
  DEPARTMENTS_DELETE
} from 'api/auth/permissions/Departments';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  ROUTE_DEPARTMENTS_DETAILS,
  ROUTE_DEPARTMENTS_UPDATE
} from 'routing/routes/Departments';
import { withRouter } from 'react-router-dom';
import DeleteDepartmentDialog from 'modules/Departments/component/DepartmentDeleteDialog';
import insertPathParams from 'api/utils/insertPathParams';
import CheckIcon from '@material-ui/icons/Check';

class DepartmentsTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
      departmentToDelete: null
    };

    this.renderProductGroup = this.renderProductGroup.bind(this);
    this.renderCategory = this.renderCategory.bind(this);
    this.renderCountry = this.renderCountry.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  componentDidMount() {}

  onDetailsClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_DEPARTMENTS_DETAILS, { id })
    );
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_DEPARTMENTS_UPDATE, { id }));
  }

  onDeleteClick(department) {
    this.setState({ deleteDialogOpen: true, departmentToDelete: department });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, departmentToDelete: null });
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'country_name',
        label: 'Country',
        sortable: true,
        displayStatus: this.context.hasRole([SUPER_ADMIN])
      },
      {
        property: 'product_groups',
        label: 'Product group',
        sortable: false,
        render: this.renderProductGroup
      },
      {
        property: 'categories',
        label: 'Category',
        sortable: false,
        render: this.renderCategory
      },
      {
        property: 'has_all_categories',
        label: 'Assigned to all categories',
        sortable: false,
        render: status => (status ? <CheckIcon color='primary' /> : null)
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([DEPARTMENTS_UPDATE]),
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([DEPARTMENTS_DELETE]),
            onClick: department => this.onDeleteClick(department),
            disable: ({ users_count }) => users_count > 0,
            disableMsg:
              'The department in which the users are located cannot be deleted'
          }
        }
      }
    ];
  }

  renderProductGroup(ids) {
    const productGroups = this.props.categoriesObj.productGroups
      .filter(productGroup => ids.includes(productGroup.id))
      .map(productGroup => productGroup.name);

    return isEmpty(productGroups) ? null : productGroups.join(', ');
  }

  renderCategory(ids) {
    const categories = this.props.categoriesObj.categories
      .filter(category => ids.includes(category.id))
      .map(category => category.name);

    return isEmpty(categories) ? null : categories.join(', ');
  }

  renderCountry(id) {
    const country = this.props.countries.find(item => item.id === id);
    return country?.name;
  }

  render() {
    return (
      <div>
        <Table
          cols={this.getCols()}
          rows={this.props.departments}
          sort={this.props.sort}
          setSort={this.props.setSort}
          actions
          hover
          loading={this.props.loading}
        />
        {this.state.deleteDialogOpen && (
          <DeleteDepartmentDialog
            department={this.state.departmentToDelete}
            onClose={this.onDeleteDialogClose}
            fetchDepartments={this.props.fetchDepartments}
          />
        )}
      </div>
    );
  }
}

DepartmentsTable.defaultProps = {
  departments: [],
  countries: [],
  categoriesObj: {
    productGroups: [],
    categories: []
  }
};

DepartmentsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  categoriesObj: PropTypes.shape({
    productGroups: PropTypes.arrayOf(PropTypes.shape({})),
    categories: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  fetchDepartments: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default withRouter(DepartmentsTable);
