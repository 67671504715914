import { Component } from 'react';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import PropTypes from 'prop-types';
import Loader from 'modules/Layout/component/Loader';
import { isEmpty } from 'lodash/lang';
import capitalize from 'modules/Shared/utils/capitalize';
import ClientsTreeCities from 'modules/Clients/component/ClientsTree/Cities';

class ClientsTreeSubsubregions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataToRender: undefined
    };
  }

  async componentDidMount() {
    const { parentId, parentIdPropName } = (() => {
      if (this.props.subregionId)
        return {
          parentId: this.props.subregionId,
          parentIdPropName: 'subregion_id'
        };
      if (this.props.regionId)
        return {
          parentId: this.props.regionId,
          parentIdPropName: 'region_id'
        };
    })();

    const dataToRender = await this.props.getDataToRender(
      'subsubregions',
      parentId,
      parentIdPropName
    );

    this.setState({ dataToRender });
  }

  render() {
    const { dataToRender } = this.state;
    const { selectMode, isParentSelected } = this.props;

    if (dataToRender === undefined) return <Loader />;

    if (isEmpty(dataToRender)) return null;

    return dataToRender.map(subsubregion => {
      const isVisible = this.props.isVisible('subsubregions', subsubregion.id);
      const isSelected =
        isParentSelected ||
        this.props.isSelected('subsubregions', subsubregion.id);

      if (!isVisible && !isSelected && !selectMode) return null;

      const item = {
        postalCodeLevel: 'subsubregions',
        region_id: this.props.regionId,
        subregion_id: this.props.subregionId,
        ...subsubregion
      };

      return (
        <TreeItem
          itemData={item}
          key={`subsubregion-${subsubregion.id}`}
          nodeId={`subsubregion-${subsubregion.id}`}
          labelText={capitalize(subsubregion.name)}
          labelInfo={
            selectMode
              ? subsubregion.clients_number
              : subsubregion.assigned_clients_number
          }
          selectMode={selectMode}
          isSelected={isSelected}
          onSelect={this.props.onSelect}
          actionBtn={
            !selectMode && isSelected && this.props.renderDeleteItemBtn(item)
          }
        >
          <ClientsTreeCities
            regionId={this.props.regionId}
            subregionId={this.props.subregionId}
            subsubregionId={subsubregion.id}
            getDataToRender={this.props.getDataToRender}
            isVisible={this.props.isVisible}
            isParentSelected={isSelected}
            isSelected={this.props.isSelected}
            selectMode={selectMode}
            onSelect={this.props.onSelect}
            renderDeleteItemBtn={this.props.renderDeleteItemBtn}
          />
        </TreeItem>
      );
    });
  }
}

ClientsTreeSubsubregions.propTypes = {
  regionId: PropTypes.number.isRequired,
  subregionId: PropTypes.number.isRequired,
  getDataToRender: PropTypes.func.isRequired,
  isVisible: PropTypes.func.isRequired,
  isParentSelected: PropTypes.bool.isRequired,
  isSelected: PropTypes.func.isRequired,
  selectMode: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  renderDeleteItemBtn: PropTypes.func.isRequired
};

export default ClientsTreeSubsubregions;
