import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_CLIENTS_CONTRACTS_DETAILS } from 'routing/routes/ClientsContracts';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { CLIENTS_CONTRACTS_LIST } from 'api/auth/permissions/ClientsContracts';
import t from 'translate/translate';
import clientContractStatuses from 'modules/ClientsContracts/clientContractStatuses';

class TargetsTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'client_name',
        label: 'Client name',
        sortable: true
      },
      {
        property: 'sales_group_name',
        label: 'Shopping group name',
        sortable: true
      },
      {
        property: 'status',
        label: 'Status',
        sortable: true,
        render: status => t(clientContractStatuses[status])
      },
      {
        property: 'period',
        label: 'Period',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: this.context.hasPermission([CLIENTS_CONTRACTS_LIST]),
            disable: false,
            disableMsg: null,
            onClick: ({ id }) => {
              this.props.history.push(
                insertPathParams(ROUTE_CLIENTS_CONTRACTS_DETAILS, { id })
              );
            }
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          }
        }
      }
    ];
  }

  render() {
    const { clientsContracts, sort, setSort, loading } = this.props;

    return (
      <Table
        cols={this.getCols()}
        rows={clientsContracts}
        sort={sort}
        setSort={setSort}
        loading={loading}
        actions
      />
    );
  }
}

TargetsTable.defaultProps = {
  clientsContracts: []
};

TargetsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  clientsContracts: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(TargetsTable);
