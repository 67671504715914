import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import PublicWrapper from 'modules/Layout/view/PublicWrapper';
import Form from 'modules/Auth/component/Password/Change/Form';
import { ROUTE_LOGIN } from 'routing/routes/Auth';
import t from 'translate/translate';

const Change = () => {
  const location = useLocation();
  const [status, setStatus] = useState(false);

  const getToken = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('token');
  };

  const token = getToken();

  return (
    <PublicWrapper>
      {!token && (
        <Box my={5}>
          <Typography color='error' component='h1' variant='h5'>
            {t('Something goes wrong, try again later')}
          </Typography>
        </Box>
      )}
      {token && !status && <Form setStatus={setStatus} token={token} />}
      {status && (
        <Box my={5}>
          <Typography component='h1' variant='h5'>
            {t(
              'Changing password finish successfully, Try to log in now with brand new password.'
            )}
          </Typography>
        </Box>
      )}
      <Box>
        <Link to={ROUTE_LOGIN} className='router-link'>
          <Typography color='primary'>{t('Go back to login')}</Typography>
        </Link>
      </Box>
    </PublicWrapper>
  );
};

export default Change;
