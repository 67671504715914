import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import useStyles from 'modules/Visits/components/shared/styles';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';

const VisitInfoWindow = ({ visit, show }) => {
  const classes = useStyles({ show });
  const {
    client_name,
    place,
    scheduled_at,
    last_visit_at,
    description,
    evaluation
  } = visit;
  return (
    <Box className={classes.infoWindow}>
      <Box display='flex'>
        <Typography className={classes.label}> {t('Client name')}: </Typography>
        <Typography className={classes.paragraph}>{client_name}</Typography>
      </Box>
      <Box display='flex'>
        <Typography className={classes.label}> {t('Place')}: </Typography>
        <Typography className={classes.paragraph}>{place}</Typography>
      </Box>
      <Box display='flex'>
        <Typography className={classes.label}>{t('Last visit at')}:</Typography>
        <Typography className={classes.paragraph}>
          {last_visit_at || t('No data')}
        </Typography>
      </Box>
      <Box display='flex'>
        <Typography className={classes.label}>{t('Scheduled at')}: </Typography>
        <Typography className={classes.paragraph}>{scheduled_at}</Typography>
      </Box>
      <Box display='flex'>
        <Typography className={classes.label}>{t('Description')}: </Typography>
        <Typography className={classes.paragraph}>
          {description || t('No data')}
        </Typography>
      </Box>
      <Typography className={classes.label}>
        {t("Client's evaluation")}:{' '}
      </Typography>
      {!isEmpty(evaluation)
        ? evaluation.map(
            ({
              cooperation_evaluation,
              potential_evaluation,
              department_name
            }) => (
              <div key={department_name}>
                <Typography
                  className={classes.label}
                  style={{ paddingLeft: 10 }}
                >
                  {department_name}:
                </Typography>
                <Box display='flex' style={{ paddingLeft: 10 }}>
                  <Typography className={classes.label}>
                    {t('Cooperation')}:
                  </Typography>
                  <Typography
                    style={{ marginRight: 5 }}
                    className={classes.paragraph}
                  >
                    {cooperation_evaluation || t('No data')}
                  </Typography>
                  <Typography className={classes.label}>
                    {t('Potential')}:
                  </Typography>
                  <Typography className={classes.paragraph}>
                    {potential_evaluation || t('No data')}
                  </Typography>
                </Box>
              </div>
            )
          )
        : t('No data')}
    </Box>
  );
};

VisitInfoWindow.propTypes = {
  visit: PropTypes.shape({
    client_name: PropTypes.string,
    place: PropTypes.string,
    last_visit_at: PropTypes.string,
    description: PropTypes.string,
    scheduled_at: PropTypes.string,
    evaluation: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  show: PropTypes.bool.isRequired
};

export default VisitInfoWindow;
