export default {
  Departments: 'Działy',
  Department: 'Dział',
  'Adding a department': 'Dodawanie działu',
  'Update department': 'Edytowanie działu',
  'Selected departments': 'Wybrane działy',
  Country: 'Kraj',
  'All countries': 'Wszystkie kraje',
  'Product group': 'Grupa produktowa',
  'Product groups': 'Grupy produktowe',
  Category: 'Kategoria',
  Categories: 'Kategorie',
  'The department in which the users are located cannot be deleted':
    'Nie można usunać działu w którym znajdują się użytkownicy',
  'Are you sure you want to delete department <%=name%>':
    'Czy na pewno chcesz usunać dział <%=name%>',
  'Deleting department successfully finish':
    'Usuwanie działu zakończone sukcesem',
  'Selected product group or category is required':
    'Należy wybrać jedną grupę produktową bądź kategorie',
  'Department details': 'Szczegóły działu',
  'Department user list': 'Lista użytkowników działu',
  'Users not assign to managers': 'Użytkownicy nie przypisani do kierowników',
  'Sales results approved': 'Zatwierdzone wyniki sprzedażowe',
  'No user assign to department': 'Dział nie posiada żadnych użytkowników',
  'Users assigned to the manager': 'Użytkownicy przypisani do kierownika',
  'Department assigned to all categories':
    'Dział przypisany do wszystkich kategorii',
  'Assigned to all categories': 'Przypisany do wszystkich kategorii',
  'Department sales results comparison':
    'Porównanie wyników sprzedażowych działu',
  'Sales results comparison': 'Porównanie wyników sprzedażowych',
  'add third comparison section': 'dodaj trzecią sekcje porównania',
  'export users list': 'eksportuj listę użytkowników',
  'change to previous year': 'zmień na poprzedni rok',
  'change to next year': 'zmień na przyszły rok',
  'Order types': 'Typy zamówienia',
  'Are you sure you want to delete order type <%=orderTypeName%>?':
    'Czy na pewno chcesz usunać typ zamówienia <%=orderTypeName%>?',
  'Order type deleted': 'Typ zamówienia usunięty',
  'Order type saved': 'Typ zamówienia zapisany',
  'Short name': 'Nazwa skrócona',
  'Create order type': 'Dodaj typ zamówienia',
  'Update order type': 'Edytuj typ zamówienia',
  'Department have no order types': 'Dział nie posiada typów zamówienia',
  'You are not director of this department': 'Nie jesteś dyrektorem tego działu'
};
