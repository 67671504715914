import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import StatisticsWholesalersSalesTable from 'modules/Statistics/WholesalersSales/component/List/Table';
import StatisticsWholesalersSalesFilter from 'modules/Statistics/WholesalersSales/component/List/Filter';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import ProductsCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import StatisticsExportApi from 'api/connections/Statistics/StatisticsExportApi';
import { downloadFile } from 'modules/Shared/utils/file';
import IconButton from 'modules/Layout/component/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

class StatisticsWholesalersSalesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      filter: {
        type: 'all',
        period: 'year',
        shopping_groups: false
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      statistics: [],
      countries: [],
      salesGroups: [],
      productsCategories: [],
      loading: false
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchStatistics = this.fetchStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportStatistics = this.exportStatistics.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Statistics wholesalers sales');
    if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
      this.fetchCountries();
    }
    this.fetchFilters();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setSort(sort) {
    this.setState({ sort: { ...this.state.sort, ...sort } }, () =>
      this.fetchStatistics()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination }
      },
      () => this.fetchStatistics()
    );
  }

  setFilter(filter) {
    this.setState(
      prevState => {
        return {
          filter: {
            ...prevState.filter,
            ...filter,
            category_id: !filter.shopping_groups ? filter.category_id : '',
            sales_group_id: filter.shopping_groups ? filter.sales_group_id : ''
          },
          pagination: { ...prevState.pagination, page: 1 }
        };
      },
      () => this.fetchStatistics()
    );
  }

  async fetchCountries() {
    try {
      const {
        data: { data: countries }
      } = await PostalCodesApi.getCountries({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        countries,
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchFilters() {
    const { sort, filter } = this.state;

    try {
      this.setState({ loading: true });

      const [
        {
          data: { data: salesGroups }
        },
        { data: productsCategories }
      ] = await Promise.all([
        SalesGroupsApi.getSalesGroups({
          ...sort,
          ...filter,
          per_page: Number.MAX_SAFE_INTEGER
        }),
        ProductsCategoriesApi.getProductCategories()
      ]);

      this.setState({
        salesGroups,
        productsCategories,
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchStatistics() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    if (!filter.year) return null;
    if (filter.period === 'quarter' && !filter.quarter) return null;
    if (filter.period === 'month' && !filter.month) return null;

    try {
      this.setState({ loading: true });

      const {
        data: { data: statistics, meta }
      } = await StatisticsApi.getWholesalersSales({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        statistics,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportStatistics() {
    try {
      const { filter, sort } = this.state;

      const res = await StatisticsExportApi.exportWholesalersSalesRanking({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      if (err instanceof ApiError) {
        this.props.contextMethods.setAlert(err.getPayload().message);
      }
    }
  }

  renderActions() {
    const {
      pagination,
      filter: { year }
    } = this.state;

    return (
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <IconButton
            onClick={this.exportStatistics}
            icon={
              <GetAppOutlinedIcon
                color={year ? 'primary' : 'disabled'}
                fontSize='large'
              />
            }
            alt='export table'
            disabled={!year}
            tooltipMsg='Pick period first'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const {
      statistics,
      countries,
      filter,
      sort,
      loading,
      filterValidation,
      productsCategories,
      salesGroups,
      shoppingGroupsCheckbox
    } = this.state;

    return (
      <div>
        <StatisticsWholesalersSalesFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          countries={countries}
          productsCategories={productsCategories}
          salesGroups={salesGroups}
          shoppingGroupsCheckbox={shoppingGroupsCheckbox}
        />

        {this.renderActions()}
        <StatisticsWholesalersSalesTable
          sort={sort}
          setSort={this.setSort}
          statistics={statistics}
          countries={countries}
          loading={loading}
          bodyText={
            !filter.year || !filter.quarter ? 'Pick period first' : null
          }
        />
        {this.renderActions()}
      </div>
    );
  }
}

StatisticsWholesalersSalesList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default StatisticsWholesalersSalesList;
