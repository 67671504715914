import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  breadCrumbsLink: {
    cursor: 'pointer',
    fontSize: theme.spacing(2.2),
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  breadCrumbsLinkLast: {
    fontSize: theme.spacing(2.2)
  }
}));

const SalesResultsBreadcrumbs = ({ breadcrumbs, onDrillUp }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label='breadcrumbs'>
      {breadcrumbs.map((name, index) => {
        const last = index === breadcrumbs.length - 1;

        return (
          <Typography
            key={name}
            color='inherit'
            onClick={() => !last && onDrillUp(index)}
            className={clsx(
              last ? classes.breadCrumbsLinkLast : classes.breadCrumbsLink
            )}
          >
            {name}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

SalesResultsBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDrillUp: PropTypes.func.isRequired
};

export default SalesResultsBreadcrumbs;
