import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';

class VisitsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: 'Visit data',
        children: [
          {
            ...this.getBasicInputValues('user_name'),
            label: 'User name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('status'),
            label: 'Status',
            type: 'select',
            emptyValueText: 'Status',
            options: formatOptions(this.props.visits, 'status', 'name', true)
          }
        ]
      },
      {
        groupLabel: 'Visit date',
        children: [
          !this.props.archive
            ? {
                ...this.getBasicInputValues('date_to'),
                label: 'To',
                type: 'date'
              }
            : {
                ...this.getBasicInputValues('date_from'),
                label: 'From',
                type: 'date'
              }
        ]
      }
    ];
  }
}

export default VisitsFilter;
