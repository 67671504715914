import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid, Typography } from '@material-ui/core';
import t from 'translate/translate';
import PieChart from 'modules/Layout/component/Charts/PieChart';
import BarChart from 'modules/Layout/component/Charts/BarChart';
import ResultsTable from 'modules/SalesResults/component/ResultsDetails/Table';
import { useResize } from 'modules/Shared/hook';
import Card from '@material-ui/core/Card';
import SalesResultsLegend from 'modules/SalesResults/component/ResultsDetails/Legend';
import {
  renderWholesalersBars,
  renderWholesalersPies
} from 'modules/Statistics/SalesComparison/utils/renderData';

const SalesComparisonDirectWholesalersCharts = props => {
  const {
    data: { wholesalersSales, directSales },
    viewType,
    onDrillDown,
    nameSuffix,
    thirdSection
  } = props;

  const componentRef = useRef();
  const { width } = useResize(componentRef, thirdSection);

  const chartWidth = width > 590 ? width / 14 : width / 7;
  const barWidth = width > 590 ? width / 8 : width / 4;

  return (
    <Box display='flex' justifyContent='space-around' ref={componentRef}>
      {viewType === 'pie' && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Direct sales')}
              </Typography>
            </Box>
            <Card>
              <CardContent>
                <Box display='flex' justifyContent='space-around'>
                  <PieChart
                    data={directSales}
                    valuePropertyName='quantity_sum'
                    title={t('Number of direct sales')}
                    outerRadius={chartWidth}
                    innerRadius={15}
                    onDrillDown={data => onDrillDown(data)}
                    nameSuffix={nameSuffix}
                    suffix='szt.'
                  />

                  <PieChart
                    data={directSales}
                    valuePropertyName='price_sum'
                    title={t('Sum of direct sales')}
                    outerRadius={chartWidth}
                    innerRadius={15}
                    onDrillDown={data => onDrillDown(data)}
                    nameSuffix={nameSuffix}
                    suffix='PLN'
                  />
                </Box>
                <SalesResultsLegend nameSuffix={nameSuffix} />
              </CardContent>
            </Card>
          </Grid>
          {renderWholesalersPies(
            nameSuffix,
            wholesalersSales,
            chartWidth,
            onDrillDown
          )}
        </Grid>
      )}
      {viewType === 'bar' && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Direct sales')}
              </Typography>
            </Box>
            <Card>
              <CardContent>
                <Box display='flex' justifyContent='space-around'>
                  <BarChart
                    data={directSales}
                    valuePropertyName='quantity_sum'
                    title={t('Number of direct sales')}
                    width={barWidth}
                    onDrillDown={data => onDrillDown(data)}
                    nameSuffix={nameSuffix}
                    suffix='szt.'
                  />
                  <BarChart
                    data={directSales}
                    valuePropertyName='price_sum'
                    title={t('Sum of direct sales')}
                    width={barWidth}
                    onDrillDown={data => onDrillDown(data)}
                    nameSuffix={nameSuffix}
                    suffix='PLN'
                  />
                </Box>
                <SalesResultsLegend nameSuffix={nameSuffix} />
              </CardContent>
            </Card>
          </Grid>
          {renderWholesalersBars(
            nameSuffix,
            wholesalersSales,
            barWidth,
            onDrillDown
          )}
        </Grid>
      )}
      {viewType === 'table' && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <Typography align='center' variant='h6'>
                {t('Direct sales')}
              </Typography>
            </Box>
            <ResultsTable
              data={directSales}
              onDrillDown={data => onDrillDown(data)}
              nameSuffix={nameSuffix}
            />
          </Grid>
          {wholesalersSales.map((wholesaler, index) => (
            <Grid item xs={12} md={6}>
              <Box mb={4}>
                <Typography align='center' variant='h6'>
                  {wholesaler.name}
                </Typography>
              </Box>
              <ResultsTable
                data={wholesaler.data}
                onDrillDown={data => onDrillDown(data)}
                nameSuffix={nameSuffix + index + 1}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

SalesComparisonDirectWholesalersCharts.propTypes = {
  data: PropTypes.shape({
    wholesalersSales: PropTypes.arrayOf(PropTypes.shape({})),
    directSales: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  viewType: PropTypes.string.isRequired,
  onDrillDown: PropTypes.func.isRequired,
  nameSuffix: PropTypes.number.isRequired,
  thirdSection: PropTypes.bool.isRequired
};

export default SalesComparisonDirectWholesalersCharts;
