import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import t from 'translate/translate';
import PriceListsDetailsClientsList from 'modules/PriceLists/component/Details/ClientsList';

const PriceListClients = props => {
  const { clients, country_id } = props.priceList;

  return (
    <Grid container spacing={3} justify='center'>
      <Grid item xs={8}>
        <Paper className='p1'>
          <Typography
            component='h6'
            variant='h6'
            style={{ marginBottom: '1rem' }}
          >
            {t('Clients assign to price list')}
          </Typography>
          <PriceListsDetailsClientsList
            clients={clients}
            countryId={country_id}
            onClientsAddSuccess={props.onClientsAddSuccess}
            onClientsDeleteSuccess={props.onClientsDeleteSuccess}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

PriceListClients.propTypes = {
  priceList: PropTypes.shape({
    name: PropTypes.string.isRequired,
    clients: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    departments: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    country_id: PropTypes.number
  }).isRequired,
  onClientsAddSuccess: PropTypes.func.isRequired,
  onClientsDeleteSuccess: PropTypes.func.isRequired
};

export default PriceListClients;
