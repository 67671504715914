import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Table from 'modules/Layout/component/List/Table';
import { SUPER_ADMIN, PRODUCT_MANAGER } from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_PRODUCTS_DETAILS,
  ROUTE_PRODUCTS_UPDATE
} from 'routing/routes/Products';
import {
  PRODUCTS_UPDATE,
  PRODUCTS_DELETE
} from 'api/auth/permissions/Products';
import DeleteProductDialog from 'modules/Products/component/DeleteProductDialog';
import priorityOptions from 'modules/Products/priorityTypes';
import renderPrice from 'modules/Products/utils/renderPrice';

class ProductsTable extends Component {
  static contextType = AuthContext;

  static renderPriority(value) {
    if (value)
      return priorityOptions.find(priority => priority.id === value).icon;
  }

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
      productToDelete: {}
    };

    this.renderPrices = this.renderPrices.bind(this);
    this.renderTaxes = this.renderTaxes.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_PRODUCTS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_PRODUCTS_UPDATE, { id }));
  }

  onDeleteClick(product) {
    this.setState({ deleteDialogOpen: true, productToDelete: product });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, productToDelete: {} });
  }

  getCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false
      },
      {
        property: 'subcategory_name',
        label: 'Subcategory',
        sortable: false
      },
      {
        property: 'prices',
        label: 'Price',
        sortable: false,
        render: this.renderPrices
      },
      {
        property: 'batches_count',
        label: 'Batches total',
        sortable: true
      },
      {
        property: 'priority',
        label: 'Priority',
        sortable: true,
        render: ProductsTable.renderPriority
      },
      {
        property: 'taxes',
        label: 'Taxes',
        sortable: false,
        render: this.renderTaxes
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([PRODUCTS_UPDATE]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([PRODUCTS_DELETE]),
            disable: ({ can_delete }) => !can_delete,
            disableMsg:
              'You cannot delete product when there are open sales targets for that product.',
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  renderPrices(prices) {
    const {
      hasRole,
      user: { country_id: userCountryId }
    } = this.context;
    const { countries } = this.props;

    if (hasRole([SUPER_ADMIN, PRODUCT_MANAGER])) {
      return prices.map(item => {
        const { price, currency, country_id } = item;

        const countryName = countries.find(country => country.id === country_id)
          ?.name;

        return (
          <div key={`price-${country_id}`}>
            {countryName}: {renderPrice(price, currency)}
          </div>
        );
      });
    }

    const priceObj = prices.find(price => price.country_id === userCountryId);

    return priceObj
      ? renderPrice(priceObj.price, priceObj.currency)
      : t('No data');
  }

  renderTaxes(taxesToRender) {
    const {
      hasRole,
      user: { country_id: userCountryId }
    } = this.context;
    const { countries, taxes: taxesData } = this.props;

    if (hasRole([SUPER_ADMIN, PRODUCT_MANAGER])) {
      return taxesToRender.map(tax => {
        const { country_id: taxCountryId, tax_id: taxId } = tax;

        const countryName = countries.find(
          country => country.id === taxCountryId
        )?.name;

        const taxName = taxesData.find(
          taxData => taxData.id === taxId && taxData.country_id === taxCountryId
        )?.name;

        return (
          <div key={`${taxCountryId}-${taxId}`}>
            {countryName}: {taxName ?? t('No data')}
          </div>
        );
      });
    }

    let taxesDataObj = null;

    const taxObj = taxesToRender.find(tax => tax.country_id === userCountryId);
    if (taxObj) {
      taxesDataObj = taxesData.find(
        tax => tax.id === taxObj.tax_id && tax.country_id === userCountryId
      );
    }

    return taxesDataObj?.name ?? t('No data');
  }

  render() {
    const { deleteDialogOpen, productToDelete } = this.state;
    const { products, sort, setSort, fetchProducts, loading } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={products}
          sort={sort}
          setSort={setSort}
          hover
          actions
          loading={loading}
        />
        {deleteDialogOpen && (
          <DeleteProductDialog
            product={productToDelete}
            onClose={this.onDeleteDialogClose}
            onSuccess={fetchProducts}
          />
        )}
      </>
    );
  }
}

ProductsTable.defaultProps = {
  countries: [],
  products: [],
  taxes: []
};

ProductsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  taxes: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  fetchProducts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default withRouter(ProductsTable);
