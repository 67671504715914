import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import t from 'translate/translate';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';

class ProductsTreeProducts extends Component {
  isItemInAssignedData({ id: itemId }) {
    const { selected } = this.props;

    return Boolean(selected.products.find(i => i.id === itemId));
  }

  render() {
    const {
      productsData,
      treeItemsToDisplay,
      onSelect,
      selectMode,
      showAllItems,
      selectAllItems
    } = this.props;

    if (productsData === 'loading') {
      return <CircularProgress size={20} />;
    }

    if (isEmpty(productsData)) {
      return t('Category dont have any products');
    }

    return productsData.map(product => {
      if (
        !showAllItems &&
        !selectMode &&
        treeItemsToDisplay &&
        !treeItemsToDisplay.products.includes(product.id)
      ) {
        return null;
      }

      return (
        <TreeItem
          itemData={product}
          key={product.id}
          nodeId={product.id.toString()}
          labelText={product.sku}
          selectMode={selectMode}
          isSelected={selectAllItems || this.isItemInAssignedData(product)}
          onSelect={onSelect}
        />
      );
    });
  }
}

const selectedPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  group_id: PropTypes.number,
  category_id: PropTypes.number,
  subcategory_id: PropTypes.number
});

ProductsTreeProducts.propTypes = {
  productsData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.string
  ]).isRequired,
  treeItemsToDisplay: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.number),
    categories: PropTypes.arrayOf(PropTypes.number),
    subcategories: PropTypes.arrayOf(PropTypes.number),
    products: PropTypes.arrayOf(PropTypes.number)
  }),
  selected: PropTypes.shape({
    groups: PropTypes.arrayOf(selectedPropType),
    categories: PropTypes.arrayOf(selectedPropType),
    subcategories: PropTypes.arrayOf(selectedPropType),
    products: PropTypes.arrayOf(selectedPropType)
  }).isRequired,
  selectMode: PropTypes.bool.isRequired,
  showAllItems: PropTypes.bool.isRequired,
  selectAllItems: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
};

ProductsTreeProducts.defaultProps = {
  treeItemsToDisplay: null
};

export default ProductsTreeProducts;
