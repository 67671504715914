export const BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION = 0;
export const BONUS_TYPE_WAITING_FOR_CONFIRMATION = 1;
export const BONUS_TYPE_CONFIRMED = 2;
export const BONUS_TYPE_PAID = 3;
export const BONUS_TYPE_SUSPENDED = 4;

export default [
  {
    id: BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION,
    name: 'waiting for target confirmation'
  },
  { id: BONUS_TYPE_WAITING_FOR_CONFIRMATION, name: 'waiting for confirmation' },
  { id: BONUS_TYPE_CONFIRMED, name: 'confirmed' },
  { id: BONUS_TYPE_PAID, name: 'paid' },
  { id: BONUS_TYPE_SUSPENDED, name: 'suspended' }
];
