export default {
  'Knowledge base': 'Baza wiedzy',
  'create file': 'dodawanie pliku',
  'Choose language': 'Wybierz język',
  'Add category': 'Dodaj kategorie',
  'Delete category': 'Usuwanie kategorii',
  'Are you sure you want to delete category?':
    'Czy na pewno chcesz usunąć kategorię?',
  'Update category': 'Edycja kategorii',
  'Delete note': 'Usuwanie notatki',
  'Are you sure you want to delete note?':
    'Czy na pewno chcesz usunąć notatkę?',
  'Update note': 'Edycja notatki',
  'Remove file': 'Usuń plik',
  'Category cannot be deleted if there are notes.':
    'Kategoria nie może zostać usunięta, jeśli ma notatki.',
  'Send files': 'Wysyłanie plików',
  'Send attachments': 'Wyślij załączniki',
  'Choose category or note first': 'Wybierz najpierw kategorię lub notatkę',
  'Choose client first': 'Wybierz najpierw klienta',
  'Choose client': 'Wybierz klienta',
  'Choose country': 'Wybierz kraj',
  'Clients without email cannot be chosen.':
    'Klienci bez adresu poczty elektronicznej nie mogę zostać wybrani.',
  'Select attachments': 'Wybierz załączniki',
  'System client': 'Klient systemowy',
  'Email addresses': 'Adresy email',
  'Choose client or fill emails': 'Wybierz klienta lub wprowadż adres email'
};
