import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ClientsAccountApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.clientAccountPath = 'clients/:id/account';
  }

  createClientAccount(id, payload) {
    return this.post(insertPathParams(this.clientAccountPath, { id }), payload);
  }

  updateClientAccount(id, payload) {
    return this.put(insertPathParams(this.clientAccountPath, { id }), payload);
  }

  deleteClientAccount(id) {
    return this.delete(insertPathParams(this.clientAccountPath, { id }));
  }
}

export default new ClientsAccountApi();
