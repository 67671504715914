import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import ClientFileUpdateForm from 'modules/Clients/component/Details/Files/Update/Form';
import { withRouter } from 'react-router-dom';
import ClientsFilesApi from 'api/connections/Clients/ClientsFilesApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_CLIENTS_DETAILS,
  ROUTE_CLIENTS_LIST
} from 'routing/routes/Clients';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class ClientFileUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.clientId = parseInt(this.props.match.params.clientId, 10);
    this.fileId = parseInt(this.props.match.params.fileId, 10);

    this.state = {
      clientFile: null
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  componentDidMount() {
    if (!this.clientId || !this.fileId) {
      this.props.history.push(ROUTE_CLIENTS_LIST);
    }
    this.setCurrentPage('Update client file');
    this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: { data: files }
      } = await ClientsFilesApi.getClientFiles(this.clientId);

      const clientFile = files.find(file => file.id === this.fileId);

      if (
        !clientFile ||
        (clientFile && clientFile.creator_id !== this.context.user.id)
      ) {
        let path = insertPathParams(ROUTE_CLIENTS_DETAILS, {
          id: this.clientId
        });
        path += '?tab_index=general_data';
        this.props.history.push(path);
      }

      this.setState({ clientFile });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
        if (err instanceof NotFoundApiError) {
          let path = insertPathParams(ROUTE_CLIENTS_DETAILS, {
            id: this.clientId
          });
          path += '?tab_index=general_data';
          this.props.history.push(path);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { clientFile } = this.state;
    if (!clientFile) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ClientFileUpdateForm
              clientId={this.clientId}
              fileId={this.fileId}
              clientFile={clientFile}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ClientFileUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      clientId: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ClientFileUpdate);
