import PropTypes from 'prop-types';
import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const BasicContextConsumerWrapper = ({ component }) => {
  return (
    <AlertContext.Consumer>
      {({ setAlert }) => (
        <CurrentPageContext.Consumer>
          {({ setCurrentPage }) => component({ setAlert, setCurrentPage })}
        </CurrentPageContext.Consumer>
      )}
    </AlertContext.Consumer>
  );
};

BasicContextConsumerWrapper.propTypes = {
  component: PropTypes.func.isRequired
};

export default BasicContextConsumerWrapper;
