import { Component } from 'react';
import PropTypes from 'prop-types';
import insertPathParams from 'api/utils/insertPathParams';
import { Box, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import DashboardListItem from 'modules/Dashboard/component/Layout/ListItem';
import DashboardList from 'modules/Dashboard/component/Layout/List';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';
import {
  DEPARTMENT_MANAGER,
  KEY_ACCOUNT_MANAGER,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import { setColor } from 'modules/Visits/utils/clientStatusDot';

class DashboardLongLostClients extends Component {
  static contextType = AuthContext;

  static getInformationBox(client) {
    const { latest_visit_date, name } = client;

    return (
      <Box display='flex' flexDirection='row'>
        <FiberManualRecordIcon
          fontSize='small'
          style={{ color: setColor(latest_visit_date), marginRight: '.5rem' }}
        />
        <Typography style={{ fontWeight: '600' }}>{name}</Typography>
      </Box>
    );
  }

  static getRedirectLink({ id }) {
    return insertPathParams(ROUTE_CLIENTS_DETAILS, { id });
  }

  static renderItem(client) {
    const informationBox = DashboardLongLostClients.getInformationBox(client);
    const redirectLink = DashboardLongLostClients.getRedirectLink(client);

    return (
      <DashboardListItem
        key={client.id}
        informationBox={informationBox}
        redirectLink={redirectLink}
      />
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      clients: undefined,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const pathParams = {
      my_clients: this.context.hasRole([
        DEPARTMENT_MANAGER,
        KEY_ACCOUNT_MANAGER,
        TELEMARKETER,
        TRADER
      ]),
      potential_evaluation: 'A',
      active: true,
      per_page: Number.MAX_SAFE_INTEGER
    };

    try {
      const {
        data: { data: clients }
      } = await ClientsApi.getNotVisitedClients(pathParams);

      this.setState({ clients, loading: false });
    } catch (err) {
      this.props.handleError(err);
      this.setState({ clients: undefined, loading: false });
    }
  }

  render() {
    const { loading, clients } = this.state;

    if (loading) return <Loader />;

    return (
      <DashboardList
        data={clients}
        renderItem={DashboardLongLostClients.renderItem}
      />
    );
  }
}

DashboardLongLostClients.defaultProps = {
  handleError: null
};

DashboardLongLostClients.propTypes = {
  handleError: PropTypes.func
};

export default DashboardLongLostClients;
