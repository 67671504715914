import { Box } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import { periodTypes } from 'modules/Shared/dateConsts';
import YearPicker from 'modules/Layout/component/Date/YearPicker';
import Input from 'modules/Layout/component/Input';
import MonthYearPicker from 'modules/Layout/component/Date/MonthYearPicker';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const SalesResultPeriodPicker = props => {
  const {
    periodData: { period_type, quarter, period },
    advancedDetails,
    departmentName,
    onChange
  } = props;
  const { location } = useHistory();
  const isComparison = Boolean(location.pathname.search('comparison') > 0);

  return (
    <Box
      display='flex'
      flexDirection={{ xs: 'column', lg: 'row' }}
      alignItems='flex-start'
    >
      {advancedDetails && (
        <Box
          display='flex'
          flexDirection='column'
          width={{ xs: 1, lg: isComparison ? 1 : '30%' }}
        >
          <Select
            name='period_type'
            label='Period type'
            value={period_type}
            fullWidth
            options={formatOptions(periodTypes, 'slug', 'name', true)}
            onChange={onChange}
          />
          {period_type === 'year' && (
            <YearPicker
              name='period'
              label='Year'
              value={period}
              onChange={onChange}
            />
          )}
          {period_type === 'quarter' && (
            <Input
              name='quarter'
              label='Quarter'
              value={quarter}
              type='number'
              inputProps={{
                min: 1,
                max: 4
              }}
              onChange={onChange}
            />
          )}
          {period_type === 'quarter' && (
            <YearPicker
              name='period'
              label='Year'
              value={period}
              onChange={onChange}
            />
          )}
          {period_type === 'month' && (
            <MonthYearPicker
              name='period'
              label='Month'
              value={period}
              onChange={onChange}
            />
          )}
        </Box>
      )}
      {period && !isComparison && (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          mt={1.2}
          ml={{ lg: 2 }}
        >
          <TextDataSet label='Department name' data={departmentName} />
          <TextDataSet label='Period type' data={t(period_type)} />
          {period_type === 'quarter' ? (
            <TextDataSet label='Period' data={`${quarter}.${period}`} />
          ) : (
            <TextDataSet label='Period' data={period} />
          )}
        </Box>
      )}
    </Box>
  );
};

SalesResultPeriodPicker.defaultProps = {
  departmentName: null
};

SalesResultPeriodPicker.propTypes = {
  advancedDetails: PropTypes.bool.isRequired,
  periodData: PropTypes.shape({
    period_type: PropTypes.string.isRequired,
    quarter: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  departmentName: PropTypes.string
};

export default SalesResultPeriodPicker;
