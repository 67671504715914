import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';

const ClientContactDeleteDialog = props => {
  const {
    client,
    client: { id: clientId, name },
    groupId,
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteClient = async () => {
    try {
      await SalesGroupsApi.detachSalesGroupFromClient({
        clientId,
        groupId
      });

      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });

      onClose();
      onSuccess(client);
    } catch (err) {
      if (err instanceof ApiError) {
        setAlert(err.getPayload().message);
      }
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to detach client <%=name%> from shopping group',
        { name }
      )}
      onApprove={deleteClient}
      onCancel={onClose}
    />
  );
};

ClientContactDeleteDialog.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  groupId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ClientContactDeleteDialog;
