import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import { formatMetaToPagination } from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';

import WholesalersMappingsApi from 'api/connections/Wholesalers/WholesalersMappingsApi';
import ClientsWholesalerMappingFilter from 'modules/Clients/component/Details/ClientsMapping/Table/Filter';
import ClientsMappingList from 'modules/Clients/component/Details/ClientsMapping/Table';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

class ClientDetailsWholesalersMappings extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      mappings: [],
      filter: {
        wholesaler_name: ''
      },
      filterValidation: {},
      pagination: {
        total: 0,
        page: 1,
        per_page: 15
      }
    };

    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchMappings = this.fetchMappings.bind(this);
  }

  componentDidMount() {
    this.fetchMappings();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchMappings()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchMappings()
    );
  }

  async fetchMappings() {
    const {
      pagination: { page, per_page },
      filter
    } = this.state;

    try {
      const {
        data: { data: mappings, meta }
      } = await WholesalersMappingsApi.getWholesalersClientsMappings({
        client_id: this.props.client.id,
        ...filter,
        per_page,
        page
      });

      this.setState({
        mappings,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { mappings, pagination, filter, filterValidation } = this.state;
    const { client } = this.props;

    if (!mappings) return <Loader />;

    return (
      <Grid container justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <ClientsWholesalerMappingFilter
              filter={filter}
              validation={filterValidation}
              setFilter={this.setFilter}
            />
            <ClientsMappingList
              client={client}
              mappings={mappings}
              onDeleteMappingSuccess={this.fetchMappings}
              setPagination={this.setPagination}
              pagination={pagination}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ClientDetailsWholesalersMappings.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default ClientDetailsWholesalersMappings;
