export default function (data) {
  const {
    target: { categories: targetCategories },
    additionalData: { categoriesObj }
  } = data;

  const categoryGroups = targetCategories
    .filter(i => categoriesObj.productGroups.map(d => d.id).includes(i.id))
    .map(i => i.id);
  const categories = targetCategories
    .filter(i => categoriesObj.categories.map(d => d.id).includes(i.id))
    .map(i => i.id);
  const subCategories = targetCategories
    .filter(i => categoriesObj.subCategories.map(d => d.id).includes(i.id))
    .map(i => i.id);

  return {
    categoryGroups,
    categories,
    subCategories
  };
}
