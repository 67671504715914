import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import PromotionForm from 'modules/Promotions/component/Form';

class PromotionCreate extends Component {
  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Create promotion');
  }

  render() {
    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <PromotionForm setAlert={this.props.contextMethods.setAlert} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

PromotionCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired
};

export default PromotionCreate;
