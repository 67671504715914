import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import useStyles, {
  buttonLinkStyle
} from 'modules/Visits/components/Calendar/DayBox/styles';
import Button from 'modules/Layout/component/Button';
import { useHistory } from 'react-router-dom';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { ROUTE_VISITS_CREATE } from 'routing/routes/Visits';
import { CalendarContext } from 'modules/Visits/view/Calendar';

const DayBox = ({ day, handleChooseDay, onModalOpenFn }) => {
  const { handleDaysToCopy, copyVisits } = useContext(CalendarContext);

  const { disabledDay, disabledCreating, date, visits, fullDate } = day;
  const classes = useStyles({
    disabled: day.disabledDay,
    copyVisits,
    isClicked: day.isClicked
  });
  const history = useHistory();

  const handleIsClicked = () => {
    handleDaysToCopy(day);
  };

  const onVisitClick = id => {
    history.push(`/visits/details/${id}`);
  };

  return (
    <Box
      className={classes.dayBox}
      onClick={copyVisits ? handleIsClicked : null}
    >
      <button
        type='button'
        className={classes.dayButton}
        disabled={disabledDay}
        onClick={() => {
          handleChooseDay(day);
          onModalOpenFn();
        }}
      >
        <Typography variant='h6' align='center'>
          {date}
        </Typography>
      </button>
      <List>
        {/* eslint-disable-next-line array-callback-return */}
        {visits?.map(({ client_name, id }, index) => {
          if (index < 3)
            return (
              <ListItem className={classes.listItem} key={id}>
                <Button
                  style={buttonLinkStyle}
                  text={client_name}
                  color='primary'
                  onClick={() => onVisitClick(id)}
                  disabled={disabledDay || copyVisits}
                />
              </ListItem>
            );
          if (index === 3) {
            return (
              <Typography variant='body1' align='center' key={id}>
                ...
              </Typography>
            );
          }
        })}
      </List>
      <IconButton
        icon={<AddIcon />}
        onClick={() =>
          history.push(ROUTE_VISITS_CREATE, { date: { fullDate } })
        }
        className='create-icon'
        boxStyles={{ marginTop: 'auto', marginLeft: 'auto' }}
        alt='create'
        disabled={disabledDay || disabledCreating}
      />
    </Box>
  );
};

DayBox.propTypes = {
  day: PropTypes.shape({
    disabledDay: PropTypes.bool,
    disabledCreating: PropTypes.bool,
    date: PropTypes.number.isRequired,
    visits: PropTypes.arrayOf(PropTypes.shape({})),
    fullDate: PropTypes.instanceOf(Date),
    isClicked: PropTypes.bool
  }).isRequired,
  handleChooseDay: PropTypes.func.isRequired,
  onModalOpenFn: PropTypes.func.isRequired
};

export default DayBox;
