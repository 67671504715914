import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';

class ProductWholesalersMappingsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('wholesaler_id'),
            label: 'Wholesalers',
            type: 'select',
            emptyValueText: 'All wholesaler',
            options: formatOptions(this.props.wholesalers, 'id', 'name')
          }
        ]
      }
    ];
  }
}

export default ProductWholesalersMappingsFilter;
