export default {
  Discounts: 'Rabaty',
  'Discounts and promotions': 'Promocje i rabaty',
  Discount: 'Rabat',
  'Create discount': 'Dodawanie rabatu',
  'Update discount': 'Edycja rabatu',
  'With a discount': 'Posiadający rabat',
  'Without a discount': 'Nie posiadający rabatu',
  'All types': 'Wszystkie typy',
  percentage: 'procentowy',
  'order value': 'od wartosci zamówienia',
  'Order value discounts': 'Rabaty od wartosci zamówienia',
  'payment date': 'od terminu płatności',
  'Value of the discount': 'Wartość rabatu',
  'Order value': 'Wartość zamówienia',
  'Discount value': 'Wartość rabatu',
  'Discount details': 'Szczegóły rabatu',
  'Discount created by': 'Rabat dodał(a)',
  'Last modifications was made by': 'Ostatnią modyfikacje wprowadził(a)',
  'A discount of <%=discount%>% of the order is added to each direct order':
    'Przy każdym zamówieniu bezpośrednim naliczany jest rabat o wysokości <%=discount%>% zamówienia',
  'A discount of <%=discount%>% of the order is added to the selected direct order':
    'Przy wybranym zamówieniu bezpośrednim naliczany jest rabat o wysokości <%=discount%>% zamówienia',
  'Discount is applied to each direct order based on the order value':
    'Rabat naliczany jest przy każdym zamówieniu bezpośrednim na podstawie wartości zamówienia',
  'Clients discounts': 'Rabaty klienta',
  'Shopping group discounts': 'Rabaty grupy zakupowej',
  'Client are not assign to any shopping group':
    'Klient nie jest przypisany do żadnej grupy zakupowej',
  'shopping group details': 'szczegóły grupy zakupowej',
  'Recipient type': 'Typ odbiorcy',
  Percentage: 'Procent',
  'Field Discount value is required and should be between 0-99':
    'Pole Wartość rabatu jest wymagane i wartość powinna być pomiędzy 0-99',
  'Field Order value is required and should be more than 0':
    'Pole Wartość zamówienia jest wymagane i wartość powinna być większa niż 0',
  'Save the currently edited discount threshold':
    'Zapisz aktualnie edytowany próg rabatu',
  'At least one discount threshold must be added':
    'Przynajmniej jeden próg rabatu musi być dodany',
  'Are you sure you want to delete discount <%=name%>?':
    'Czy na pewno chcesz usunać rabat <%=name%>',
  'You can only modify discounts assigned to your clients.':
    'Możesz jedynie modyfikować rabaty przypisane do twoich klientów.',
  'Restriction to categories': 'Ograniczenia dla kategorii',
  'Select a category covered by discount.':
    'Wybierz kategorię produktową objętą rabatem',
  'Restrictions to categories': 'Ograniczenia do kategorii',
  'Country where discount is available': 'Kraj w którym rabat jest dostępny',
  'Discount name': 'Nazwa rabatu'
};
