import { Component } from 'react';
import { Box, IconButton, Paper } from '@material-ui/core';
import ClientCreateForm from 'modules/Clients/component/Create/Form';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ApiError from 'api/exceptions/ApiError';
import PropTypes from 'prop-types';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';

const defaultCenter = {
  lat: 52.0,
  lng: 19.5
};

class ClientCreate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      placePosition: { lat: '', long: '' }
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage(
      this.props.isInternet ? 'Create internet client' : 'Create client'
    );
    this.fetchData();
  }

  handlePlaceChange(lat, long) {
    this.setState({ placePosition: { lat, long } });
  }

  async fetchData() {
    try {
      let countries = [];

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({ countries });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { countries, placePosition } = this.state;
    const { isInternet } = this.props;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem', marginBottom: '2rem' }}>
            <ClientCreateForm
              countries={countries}
              setAlert={this.setAlert}
              handlePlaceChangeFn={this.handlePlaceChange}
              placePosition={placePosition}
              isInternet={isInternet}
            />
            <div style={{ width: '100%', height: '60vh' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_KEY
                }}
                defaultCenter={defaultCenter}
                center={{
                  lat: placePosition.lat || defaultCenter.lat,
                  lng: placePosition.long || defaultCenter.lng
                }}
                defaultZoom={6}
                zoom={placePosition.lat ? 12 : 6}
              >
                <IconButton
                  lat={parseFloat(placePosition.lat) || 0.0}
                  lng={parseFloat(placePosition.long) || 0.0}
                  style={{
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <RoomIcon fontSize='large' style={{ color: '#c73a3a' }} />
                </IconButton>
              </GoogleMapReact>
            </div>
          </Paper>
        </Box>
      </Box>
    );
  }
}

ClientCreate.defaultProps = {
  isInternet: false
};

ClientCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  isInternet: PropTypes.bool
};

export default ClientCreate;
