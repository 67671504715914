import { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from 'modules/Layout/component/ValueOrRange/style';
import t from 'translate/translate';
import { Box } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Input from 'modules/Layout/component/Input';
import DatePicker from 'modules/Layout/component/Date/DatePicker';

const ValueOrRange = props => {
  const classes = useStyles();
  const [result, setValue] = useState('value');
  const { value, rangeFrom, rangeTo } = props.options;
  const { onFilter } = props;

  /* eslint no-restricted-syntax: "off" */
  /* eslint guard-for-in: "off" */
  const clearValues = () => {
    for (const input in props.options) {
      const { type, property: name } = props.options[input];

      onFilter({ target: { type, name, value: null } });
    }
  };

  const handleChange = event => {
    setValue(event.target.value);
    clearValues();
  };

  const renderInput = input => {
    const {
      type,
      property,
      label,
      value: inputValue,
      inputProps,
      errorStatus,
      errorText,
      maxDate,
      minDate
    } = input;

    if (type === 'date') {
      return (
        <DatePicker
          name={property}
          label={label}
          value={inputValue}
          onChange={onFilter}
          maxDate={maxDate}
          minDate={minDate}
        />
      );
    }
    if (type === 'number') {
      return (
        <Input
          name={property}
          label={label}
          value={inputValue}
          onChange={onFilter}
          type={type}
          inputProps={inputProps}
          errorStatus={errorStatus}
          errorText={errorText}
        />
      );
    }
  };

  return (
    <>
      <FormControl component='fieldset'>
        <RadioGroup
          name='valueOrRange'
          className={classes.formGroupWrapper}
          value={result}
          onChange={handleChange}
        >
          <FormControlLabel
            value='value'
            control={<Radio />}
            label={t('Value')}
          />
          <FormControlLabel
            value='range'
            control={<Radio />}
            label={t('Range')}
          />
        </RadioGroup>
      </FormControl>
      {result === 'value' && (
        <Box className={classes.accordionDetailsBox}>{renderInput(value)}</Box>
      )}
      {result === 'range' && (
        <>
          <Box className={classes.accordionDetailsBox}>
            {renderInput(rangeFrom)}
          </Box>
          <Box className={classes.accordionDetailsBox}>
            {renderInput(rangeTo)}
          </Box>
        </>
      )}
    </>
  );
};

ValueOrRange.propTypes = {
  options: PropTypes.shape({
    value: PropTypes.shape({}).isRequired,
    rangeFrom: PropTypes.shape({}).isRequired,
    rangeTo: PropTypes.shape({}).isRequired
  }).isRequired,
  onFilter: PropTypes.func.isRequired
};

export default ValueOrRange;
