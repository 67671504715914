import { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography, IconButton as Marker } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import t from 'translate/translate';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  statusesObj as visitStatuses,
  FINISHED,
  CANCELED
} from 'modules/Visits/utils/visitStatuses';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import RoomIcon from '@material-ui/icons/Room';
import { Edit as EditIcon } from '@material-ui/icons';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BlockIcon from '@material-ui/icons/Block';
import Authorize from 'modules/Auth/component/Authorize';
import {
  VISITS_CANCEL,
  VISITS_POSTPONE,
  VISITS_UPDATE
} from 'api/auth/permissions/Visits';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_VISITS_POSTPONE,
  ROUTE_VISITS_UPDATE
} from 'routing/routes/Visits';
import { useHistory, useParams } from 'react-router-dom';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import {
  DIRECTOR,
  SUPER_ADMIN,
  ADMIN,
  DEPARTMENT_MANAGER
} from 'api/auth/roles';
import IconButton from 'modules/Layout/component/IconButton';
import { isEmpty } from 'lodash/lang';
import CancelVisitModal from 'modules/Visits/components/CancelVisitModal';

const classes = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const BasicDataBox = ({ visit, user: userEmployee, onCancelVisitSuccess }) => {
  const { hasRole, user: currentUser } = useContext(AuthContext);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const position = {
    lat: (visit.lat && parseFloat(visit.lat)) || 0,
    lng: (visit.lat && parseFloat(visit.long)) || 0
  };

  const history = useHistory();
  const { id } = useParams();

  const {
    client_name,
    place,
    user_name,
    status,
    scheduled_at,
    postponement_history,
    description,
    cancel_reason
  } = visit;

  const onUpdateClick = () => {
    history.push(insertPathParams(ROUTE_VISITS_UPDATE, { id }));
  };

  const onPostponeClick = () => {
    history.push(insertPathParams(ROUTE_VISITS_POSTPONE, { id }));
  };

  const onCancelVisitClick = () => {
    setIsModalOpen(true);
  };

  const onCancelVisitModalClose = () => {
    setIsModalOpen(false);
  };

  const isUpdateDisabled = () => {
    if (hasRole([DIRECTOR])) {
      const { departments } = currentUser;
      const departmentsIds = departments.map(
        ({ id: userDepartmentId }) => userDepartmentId
      );

      if (userEmployee && userEmployee.departments) {
        const employeeDepartmentsIds = userEmployee.departments.map(
          ({ id: employeeDepartmentId }) => employeeDepartmentId
        );

        const isUsersEmployee = employeeDepartmentsIds.some(
          employeeDepartmentId => departmentsIds.includes(employeeDepartmentId)
        );
        if (isUsersEmployee) {
          setIsUpdateButtonDisabled(false);
          return;
        }
      }
      setIsUpdateButtonDisabled(true);
    } else if (hasRole([DEPARTMENT_MANAGER])) {
      const managerId = currentUser.id;
      const userManagersIds = userEmployee.departments?.map(
        ({ manager_id }) => manager_id
      );

      if (!userManagersIds?.includes(managerId)) {
        setIsUpdateButtonDisabled(true);
      }
    } else if (!hasRole([ADMIN, SUPER_ADMIN])) {
      if (visit.user_id !== currentUser.id) setIsUpdateButtonDisabled(true);
    }
  };

  useEffect(() => {
    isUpdateDisabled();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Box className={classes().container}>
          <Box>
            {status === CANCELED && (
              <TextDataSet label={t('Cancel reason')} data={cancel_reason} />
            )}
            <TextDataSet label={t('Client')} data={client_name} />
            <TextDataSet label={t('Place')} data={place} />
            <TextDataSet label={t('User')} data={user_name} />
            <TextDataSet label={t('Status')} data={t(visitStatuses[status])} />
            <TextDataSet
              label={t('Date')}
              data={scheduled_at.substring(0, 16)}
            />
            <TextDataSet label={t('Description')} data={description} />
            <Typography variant='h6'>
              {t('Informations about postponed visits')}:
            </Typography>
            {!isEmpty(postponement_history) ? (
              postponement_history.map(
                ({
                  old_scheduled_at,
                  postponed_at,
                  postponed_name,
                  postponement_reason
                }) => (
                  <Box
                    boxShadow={3}
                    padding={1}
                    m={1}
                    my={2}
                    key={`${old_scheduled_at}-${postponed_at}`}
                  >
                    <TextDataSet
                      label={t('Previous visit date')}
                      data={old_scheduled_at}
                    />
                    <TextDataSet
                      label={t('Postponed at')}
                      data={postponed_at}
                    />
                    <TextDataSet
                      label={t('Postponed by')}
                      data={postponed_name}
                    />
                    <TextDataSet
                      label={t('Postponement reason')}
                      data={postponement_reason}
                    />
                  </Box>
                )
              )
            ) : (
              <Typography>{t('No data')}</Typography>
            )}
          </Box>
          <Box display='flex' alignItems='flex-start'>
            <Authorize permissions={[VISITS_UPDATE]}>
              <IconButton
                className='update-icon'
                onClick={onUpdateClick}
                icon={<EditIcon />}
                alt='update'
                disabled={
                  status === FINISHED ||
                  status === CANCELED ||
                  isUpdateButtonDisabled
                }
                tooltipMsg={t(
                  'You can update your, your employee visit and visit which is not finished'
                )}
              />
            </Authorize>
            <Authorize permissions={[VISITS_POSTPONE]}>
              <IconButton
                className='update-icon'
                onClick={onPostponeClick}
                icon={<ScheduleIcon />}
                alt='postponement'
                disabled={
                  status === FINISHED ||
                  status === CANCELED ||
                  isUpdateButtonDisabled
                }
                tooltipMsg={t(
                  'You can postpone your, your employee visit and visit which is not finished'
                )}
              />
            </Authorize>
            <Authorize permissions={[VISITS_CANCEL]}>
              <IconButton
                className='delete-icon'
                onClick={onCancelVisitClick}
                icon={<BlockIcon />}
                alt='cancellation'
                disabled={
                  status === FINISHED ||
                  status === CANCELED ||
                  isUpdateButtonDisabled
                }
                tooltipMsg={t(
                  'You can cancel your, your employee visit and visit which is not finished'
                )}
              />
            </Authorize>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} style={{ height: '500px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_KEY
          }}
          defaultCenter={position}
          defaultZoom={12}
        >
          <Marker
            lat={position.lat}
            lng={position.lng}
            style={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <RoomIcon fontSize='large' />
          </Marker>
        </GoogleMapReact>
      </Grid>
      {isModalOpen && (
        <CancelVisitModal
          visit={visit}
          onSuccess={onCancelVisitSuccess}
          onClose={onCancelVisitModalClose}
        />
      )}
    </Grid>
  );
};

BasicDataBox.propTypes = {
  visit: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lat: PropTypes.string,
    long: PropTypes.string,
    client_name: PropTypes.string,
    place: PropTypes.string,
    user_name: PropTypes.string,
    status: PropTypes.number,
    scheduled_at: PropTypes.string,
    postponement_history: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string,
    user_id: PropTypes.number,
    cancel_reason: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    departments: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }))
  }).isRequired,
  onCancelVisitSuccess: PropTypes.func.isRequired
};

export default BasicDataBox;
