import ListIcon from '@material-ui/icons/List';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import {
  ROUTE_CLIENTS_LIST,
  ROUTE_INTERNET_CLIENTS_LIST,
  ROUTE_CLIENTS_UNASSIGNED_TO_EMPLOYEE,
  permMap as clientsPermMap
} from 'routing/routes/Clients';
import {
  ROUTE_SHOPPING_GROUPS_LIST,
  permMap as salesGroupsPermMap
} from 'routing/routes/ShoppingGroups';
import {
  ROUTE_CLIENTS_CONTRACTS_LIST,
  permMap as clientsContractsPermMap
} from 'routing/routes/ClientsContracts';
import {
  ROUTE_CLIENTS_BONUSES_LIST,
  permMap as clientsBonusesPermMap
} from 'routing/routes/ClientsBonuses';

const menu = [
  {
    key: 'menu-clients',
    label: 'Clients',
    icon: <ContactPhoneIcon />,
    path: ROUTE_CLIENTS_LIST,
    anyPermissions: [
      ...salesGroupsPermMap[ROUTE_SHOPPING_GROUPS_LIST],
      ...clientsPermMap[ROUTE_CLIENTS_LIST]
    ],
    children: [
      {
        key: 'menu-clients-list',
        label: 'Clients',
        icon: <ListIcon />,
        path: ROUTE_CLIENTS_LIST,
        permissions: clientsPermMap[ROUTE_CLIENTS_LIST]
      },
      {
        key: 'menu-internet-clients-list',
        label: 'Internet clients',
        icon: <ListIcon />,
        path: ROUTE_INTERNET_CLIENTS_LIST,
        permissions: clientsPermMap[ROUTE_INTERNET_CLIENTS_LIST]
      },
      {
        key: 'menu-sales-groups-list',
        label: 'Shopping Groups',
        icon: <ListIcon />,
        path: ROUTE_SHOPPING_GROUPS_LIST,
        permissions: salesGroupsPermMap[ROUTE_SHOPPING_GROUPS_LIST]
      },
      {
        key: 'menu-clients-unassigned-to-employee',
        label: 'Clients unassigned to employee',
        icon: <ListIcon />,
        path: ROUTE_CLIENTS_UNASSIGNED_TO_EMPLOYEE,
        permissions: clientsPermMap[ROUTE_CLIENTS_UNASSIGNED_TO_EMPLOYEE]
      },
      {
        key: 'menu-clients-contracts-list',
        label: 'Clients contracts',
        icon: <ListIcon />,
        path: ROUTE_CLIENTS_CONTRACTS_LIST,
        permissions: clientsContractsPermMap[ROUTE_CLIENTS_CONTRACTS_LIST]
      },
      {
        key: 'menu-clients-bonuses-list',
        label: 'Clients bonuses',
        icon: <ListIcon />,
        path: ROUTE_CLIENTS_BONUSES_LIST,
        permissions: clientsBonusesPermMap[ROUTE_CLIENTS_BONUSES_LIST]
      }
    ]
  }
];

export default menu;
