import { Component } from 'react';
import PropTypes from 'prop-types';
import ClientsTable from 'modules/Clients/component/List/Table';
import ClientsFilter from 'modules/Clients/component/List/Filter';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { Box, IconButton } from '@material-ui/core';
import LayoutIconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { CLIENTS_CREATE } from 'api/auth/permissions/Clients';
import { ROUTE_CLIENTS_CREATE } from 'routing/routes/Clients';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import {
  DEPARTMENT_MANAGER,
  KEY_ACCOUNT_MANAGER,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { downloadFile } from 'modules/Shared/utils/file';
import ClientsFilesApi from 'api/connections/Clients/ClientsFilesApi';

class ClientsList extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      filter: {
        name: '',
        recipient_number: '',
        tax_number: '',
        address: '',
        country_id: '',
        sales_group_name: '',
        my_clients: context.hasRole([
          DEPARTMENT_MANAGER,
          KEY_ACCOUNT_MANAGER,
          TELEMARKETER,
          TRADER
        ]),
        has_active_individual_target: false,
        has_active_group_target: false,
        active: true,
        status: 1
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      clients: [],
      countries: [],
      loading: true
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportClients = this.exportClients.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Clients');
    this.fetchData();
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchClients()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchClients()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchClients()
    );
  }

  async fetchClients() {
    const {
      filter,
      sort,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: clients, meta }
      } = await ClientsApi.getClients({
        ...sort,
        ...filter,
        per_page,
        page
      });

      const newClients = clients.map(client => ({
        ...client,
        cooperation_evaluation: [...client.evaluation],
        potential_evaluation: [...client.evaluation]
      }));

      this.setState({
        clients: newClients,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchData() {
    const {
      filter,
      sort,
      pagination: { per_page, page }
    } = this.state;

    try {
      let countries = [];

      const {
        data: { data: clients, meta }
      } = await ClientsApi.getClients({
        ...sort,
        ...filter,
        per_page,
        page
      });
      const newClients = clients.map(client => ({
        ...client,
        cooperation_evaluation: [...client.evaluation],
        potential_evaluation: [...client.evaluation]
      }));

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        clients: newClients,
        countries,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      }
    }
  }

  async exportClients() {
    try {
      const { filter, sort } = this.state;

      const res = await ClientsFilesApi.exportClients({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      if (err instanceof ApiError) {
        this.props.contextMethods.setAlert(err.getPayload().message);
      }
    }
  }

  renderActions() {
    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          <Authorize permissions={[CLIENTS_CREATE]}>
            <Link to={ROUTE_CLIENTS_CREATE} className='router-button'>
              <IconButton>
                <AddIcon color='primary' fontSize='large' />
              </IconButton>
            </Link>
          </Authorize>
          <LayoutIconButton
            onClick={this.exportClients}
            icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
            alt='export table'
          />
        </Box>
        <Pagination
          pagination={this.state.pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    return (
      <div>
        <ClientsFilter
          filter={this.state.filter}
          validation={this.state.filterValidation}
          setFilter={this.setFilter}
          countries={this.state.countries}
          hasRole={this.context.hasRole}
        />
        {this.renderActions()}
        <ClientsTable
          sort={this.state.sort}
          setSort={this.setSort}
          clients={this.state.clients}
          countries={this.state.countries}
          loading={this.state.loading}
        />
        {this.renderActions()}
      </div>
    );
  }
}

ClientsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default ClientsList;
