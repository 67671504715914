import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const DeleteWholesalerDialog = props => {
  const { wholesaler, onClose, onSuccess } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteWholesaler = async () => {
    try {
      await WholesalersApi.deleteWholesaler(wholesaler.id);
      setAlert({
        value: t('Deleting wholesaler successfully finish'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      }
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete wholesaler <%= name %>', {
        name: wholesaler.name
      })}
      onApprove={deleteWholesaler}
      onCancel={onClose}
    />
  );
};

DeleteWholesalerDialog.propTypes = {
  wholesaler: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default DeleteWholesalerDialog;
