import { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash/lang';
import { withRouter } from 'react-router-dom';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import {
  ROUTE_PROMOTIONS_DETAILS,
  ROUTE_PROMOTIONS_LIST
} from 'routing/routes/Promotions';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import t, { availableLocals } from 'translate/translate';
import { validateBaseOnFormInputs } from 'modules/Shared/utils/validator';
import formatFormMultiLangObj from 'modules/Shared/utils/formatFormMultiLangObj';
import { Box, Typography } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DateTimePicker from 'modules/Layout/component/Date/DateTimePicker';
import promotionsTypes, {
  PERCENTAGE_PROMOTION,
  GROUP_PROMOTION,
  FREE_PRODUCT_PROMOTION
} from 'modules/Promotions/promotionsTypes';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Checkbox from 'modules/Layout/component/Checkbox';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import PromotionsApi from 'api/connections/Promotions/PromotionsApi';
import ProductsApi from 'api/connections/Products/ProductsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import insertPathParams from 'api/utils/insertPathParams';
import ordersTypes from 'modules/Orders/ordersTypes';
import CategoryPicker from 'modules/Promotions/component/CategoryPicker';

class PromotionForm extends Component {
  static contextType = AuthContext;

  static getFormData(promotion, isSuperAdmin) {
    return {
      name: formatFormMultiLangObj(promotion?.name),
      type: promotion?.type ?? '',
      start_date: promotion?.start_date ?? null,
      end_date: promotion?.end_date ?? null,
      group_id: promotion?.group_id ?? null,
      category_id: promotion?.subcategory_id ?? null,
      products: promotion?.products.map(p => p.id) ?? [],
      percentage: promotion
        ? (promotion.type_data.percentage * 100).toFixed(0)
        : '',
      amount: promotion?.type_data.amount ?? '',
      exact_amount: Boolean(promotion?.type_data.exact_amount) ?? false,
      same_product: Boolean(promotion?.type_data.same_product),
      can_combine: promotion?.can_combine ?? false,
      order_type: promotion?.order_type ?? '',
      clients: promotion?.clients.map(c => c.id) ?? null,
      sales_groups: promotion?.sales_groups.map(g => g.id) ?? null,
      regions: promotion?.regions.map(r => r.id) ?? null,
      country_id: promotion?.country_id ?? '',
      is_global: promotion ? !promotion.country_id : isSuperAdmin,
      is_dedicated: Boolean(promotion?.is_dedicated)
    };
  }

  constructor(props, context) {
    super(props, context);

    const isSuperAdmin = context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]);

    this.state = {
      formData: PromotionForm.getFormData(props.promotion, isSuperAdmin),
      loading: false,
      loadingProducts: false,
      loadingClients: false,
      loadingSalesGroups: false,
      loadingRegions: false,
      loadingCountries: false,
      productsData: null,
      countriesData: null,
      clientsData: null,
      salesGroupsData: null,
      regionsData: null,
      validation: {
        // Name in PL
        pl: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        },
        type: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Type')
          })
        },
        start_date: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Start date')
          })
        },
        end_date: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('End date')
          })
        },
        percentage: {
          status: false,
          message: t(
            'Field Promotion value is required and should be between 0-99'
          )
        },
        amount: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Amount')
          })
        },
        order_type: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Order type')
          })
        }
      }
    };

    this.redirect = this.redirect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onIsGlobalChange = this.onIsGlobalChange.bind(this);
    this.onIsDedicatedChange = this.onIsDedicatedChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onCategoryPick = this.onCategoryPick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
      this.fetchCountries();
    }
    if (this.props.promotion) {
      this.fetchProducts();
    }
    if (this.state.formData.is_dedicated) {
      this.fetchClients();
      this.fetchSalesGroups();
      this.fetchRegions();
    }
  }

  handleResponse(res) {
    const { id } = res.data.data;

    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.redirect(id);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation(e.target.elements);
    if (isValid) this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, type } = e.target;
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      return {
        ...state,
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onIsGlobalChange(e) {
    this.setState(state => {
      const { checked } = e.target;

      return {
        ...state,
        formData: {
          ...state.formData,
          is_global: checked,
          country_id: ''
        }
      };
    });
  }

  onIsDedicatedChange(e) {
    this.setState(
      state => {
        const { checked } = e.target;

        return {
          ...state,
          formData: {
            ...state.formData,
            is_dedicated: checked,
            country_id: ''
          }
        };
      },
      () => {
        if (!this.state.regionsData && !this.state.loadingRegions) {
          this.fetchClients();
          this.fetchSalesGroups();
          this.fetchRegions();
        }
      }
    );
  }

  onNameChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        ...state,
        formData: {
          ...formData,
          name: {
            ...formData.name,
            [name]: value
          }
        },
        validation: {
          ...validation,
          pl:
            name === 'pl'
              ? {
                  ...validation.pl,
                  status: false
                }
              : validation.pl
        }
      };
    });
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onChange(event);
  }

  onCategoryPick(res) {
    const { groupId, categoryId, subcategoryId } = res;

    this.setState(
      state => {
        return {
          ...state,
          formData: {
            ...state.formData,
            group_id: groupId,
            category_id: subcategoryId || categoryId,
            products: [],
            loadingProducts: Boolean(subcategoryId)
          }
        };
      },
      () => {
        if (subcategoryId) this.fetchProducts();
      }
    );
  }

  runValidation(formElements) {
    /* eslint prefer-const: "off" */
    const { validation } = this.state;
    const { start_date, end_date } = this.state.formData;

    let [isValid, newValidateState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!start_date) {
      isValid = false;
      newValidateState.start_date.status = true;
    }

    if (!end_date) {
      isValid = false;
      newValidateState.end_date.status = true;
    }

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  redirect(promotionId) {
    const id = promotionId || this.props.promotion?.id;

    if (!this.props.promotion && !promotionId) {
      return this.props.history.push(ROUTE_PROMOTIONS_LIST);
    }

    return this.props.history.push(
      insertPathParams(ROUTE_PROMOTIONS_DETAILS, { id })
    );
  }

  isProductsValid() {
    const {
      formData: { products },
      productsData
    } = this.state;

    if (!productsData) return false;

    const promotionBlockedProducts = productsData
      .filter(({ promotions_blocked }) => promotions_blocked)
      .map(({ id }) => id);

    return products.some(p => promotionBlockedProducts.includes(p));
  }

  async fetchClients() {
    this.setState({ loadingClients: true });

    try {
      const {
        data: { data: clientsData }
      } = await ClientsApi.getAllClients();

      this.setState({
        clientsData,
        loadingClients: false
      });
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  async fetchSalesGroups() {
    this.setState({ loadingSalesGroups: true });

    try {
      const {
        data: { data: salesGroupsData }
      } = await SalesGroupsApi.getSalesGroups({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        salesGroupsData,
        loadingSalesGroups: false
      });
    } catch (err) {
      this.setState({ loadingSalesGroups: false });
      this.handleError(err);
    }
  }

  async fetchRegions() {
    this.setState({ loadingRegions: true });

    try {
      const {
        data: { data: regionsData }
      } = await PostalCodesApi.getRegions({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        regionsData,
        loadingRegions: false
      });
    } catch (err) {
      this.setState({ loadingRegions: false });
      this.handleError(err);
    }
  }

  async fetchCountries() {
    this.setState({ loadingCountries: true });

    try {
      const {
        data: { data: countriesData }
      } = await DepartmentsApi.getCountries({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.polandId = countriesData.find(c => c.language === 'pl')?.id;

      this.setState({
        countriesData,
        loadingCountries: false
      });
    } catch (err) {
      this.setState({ loadingCountries: false });
      this.handleError(err);
    }
  }

  async fetchProducts() {
    const { category_id } = this.state.formData;

    try {
      const {
        data: { data: productsData }
      } = await ProductsApi.getProducts({
        subcategory_id: category_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        productsData,
        loadingProducts: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async makeApiCall() {
    const { formData } = this.state;
    const { promotion } = this.props;

    const payload = {
      ...formData,
      percentage: formData.percentage / 100
    };

    this.setState({ loading: true });

    try {
      let res = null;

      if (formData.type === PERCENTAGE_PROMOTION) {
        res = promotion
          ? await PromotionsApi.updatePercentagePromotion(promotion.id, payload)
          : await PromotionsApi.createPercentagePromotion(payload);
      }
      if (formData.type === GROUP_PROMOTION) {
        res = promotion
          ? await PromotionsApi.updateGroupPromotion(promotion.id, payload)
          : await PromotionsApi.createGroupPromotion(payload);
      }
      if (formData.type === FREE_PRODUCT_PROMOTION) {
        res = promotion
          ? await PromotionsApi.updateFreeproductPromotion(
              promotion.id,
              payload
            )
          : await PromotionsApi.createFreeproductPromotion(payload);
      }

      this.setState({ loading: false });
      this.handleResponse(res);
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        name,
        type,
        start_date,
        end_date,
        products,
        category_id,
        percentage,
        amount,
        exact_amount,
        same_product,
        can_combine,
        order_type,
        clients,
        sales_groups,
        regions,
        country_id,
        is_global,
        is_dedicated
      },
      validation,
      loading,
      productsData,
      loadingProducts,
      clientsData,
      loadingClients,
      salesGroupsData,
      loadingSalesGroups,
      regionsData,
      loadingRegions,
      countriesData,
      loadingCountries
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <>
          <Typography component='h6' variant='h6'>
            {t('Name')}
          </Typography>
          <Box className='language-form-box'>
            {availableLocals.map(local => (
              <Input
                key={`name-${local.slug}`}
                name={local.slug}
                label={local.name}
                value={name[local.slug]}
                required={local.slug === 'pl'}
                onChange={this.onNameChange}
                errorStatus={validation[local.slug]?.status}
                errorText={validation[local.slug]?.message}
              />
            ))}
          </Box>
          <Typography component='h6' variant='h6'>
            {t('Basic data')}
          </Typography>
          <Box className='language-form-box'>
            <DateTimePicker
              name='start_date'
              label='Start date'
              value={start_date}
              required
              dateFormat='dd.MM.yyyy HH:mm'
              onChange={this.onChange}
              errorStatus={validation.start_date.status}
              errorText={validation.start_date.message}
            />
            <DateTimePicker
              name='end_date'
              label='End date'
              value={end_date}
              required
              dateFormat='dd.MM.yyyy HH:mm'
              onChange={this.onChange}
              errorStatus={validation.end_date.status}
              errorText={validation.end_date.message}
            />
            <Select
              name='order_type'
              label='Order type'
              value={order_type}
              required
              options={formatOptions(ordersTypes, 'id', 'name', true)}
              onChange={this.onChange}
              errorStatus={validation.order_type.status}
              errorText={validation.order_type.message}
            />
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Box width={1}>
                <Checkbox
                  name='is_global'
                  label={t('Is global')}
                  checked={is_global}
                  onChange={this.onIsGlobalChange}
                />
              </Box>
              {this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
                <Box width={1}>
                  <Select
                    name='country_id'
                    label='Country'
                    value={countriesData ? country_id : ''}
                    options={formatOptions(countriesData ?? [], 'id', 'name')}
                    onChange={this.onChange}
                    loading={loadingCountries}
                    disabled={is_global}
                    tooltipMsg='Setting country available only when promotion is not global'
                  />
                </Box>
              )}
            </Box>
            <Checkbox
              name='can_combine'
              label={t('Promotion can be combined with others')}
              checked={can_combine}
              onChange={this.onChange}
            />
          </Box>
          <Typography component='h6' variant='h6'>
            {t('Dedicated promotion settings')}
          </Typography>
          <Box className='language-form-box'>
            <Box mt={1}>
              <Checkbox
                onChange={this.onIsDedicatedChange}
                name='is_dedicated'
                checked={is_dedicated}
                label='The promotion is to be dedicated to special recipients'
              />
            </Box>
            {is_dedicated && (
              <>
                <Autocomplete
                  name='sales_groups'
                  label='Shopping groups'
                  value={sales_groups}
                  options={formatOptions(salesGroupsData ?? [], 'id', 'name')}
                  onChange={(_, v) =>
                    this.onAutocompleteChange('sales_groups', v)
                  }
                  multiple
                  loading={loadingSalesGroups}
                />
                <Autocomplete
                  name='clients'
                  label='Clients'
                  value={clients}
                  options={formatOptions(clientsData ?? [], 'id', 'name')}
                  onChange={(_, v) => this.onAutocompleteChange('clients', v)}
                  multiple
                  loading={loadingClients}
                />
                <Autocomplete
                  label='Region'
                  name='regions'
                  value={regions}
                  options={formatOptions(regionsData ?? [], 'id', 'name')}
                  onChange={(_, v) => this.onAutocompleteChange('regions', v)}
                  multiple
                  loading={loadingRegions}
                />
              </>
            )}
          </Box>
          <Typography component='h6' variant='h6'>
            {t('Category / Products')}
          </Typography>
          <Box className='language-form-box'>
            <Box mt={1} ml={1}>
              <Typography variant='body1' color='textSecondary'>
                {t(
                  'Select a category or specific products covered by the promotion.'
                )}
              </Typography>
            </Box>
            <CategoryPicker
              onChange={this.onCategoryPick}
              promotion={this.props.promotion}
            />
            <Autocomplete
              label='Products'
              name='products'
              value={products}
              options={formatOptions(productsData ?? [], 'id', 'sku')}
              onChange={(_, v) => this.onAutocompleteChange('products', v)}
              multiple
              loading={loadingProducts}
              disabled={!category_id}
              tooltipMsg='Select product subcategory first'
              getOptionDisabled={option => {
                const { promotions_blocked } = productsData.find(
                  ({ id }) => id === option.key
                );
                return promotions_blocked;
              }}
              errorStatus={this.isProductsValid()}
              errorText={t(
                'This field contains products that are excluded from the promotion'
              )}
            />
          </Box>
          <Typography component='h6' variant='h6'>
            {t('Promotion type data')}
          </Typography>
          <Box className='language-form-box'>
            <Select
              name='type'
              label='Promotion type'
              value={type}
              required
              options={formatOptions(promotionsTypes, 'id', 'name', true)}
              onChange={this.onChange}
              disabled={Boolean(this.props.promotion)}
              tooltipMsg='Cannot change promotion type on update'
              errorStatus={validation.type.status}
              errorText={validation.type.message}
            />
            {(type || type === 0) && (
              <>
                {(type === PERCENTAGE_PROMOTION ||
                  type === GROUP_PROMOTION) && (
                  <Input
                    name='percentage'
                    label='Percentage promotion value'
                    type='number'
                    inputProps={{
                      min: 0,
                      max: 99
                    }}
                    value={percentage}
                    required
                    onChange={this.onChange}
                    errorStatus={validation.percentage.status}
                    errorText={validation.percentage.message}
                  />
                )}
                {type === GROUP_PROMOTION && (
                  <>
                    <Box display='flex' alignItems='center'>
                      <Box width={{ xs: 1, lg: '70%' }}>
                        <Input
                          name='amount'
                          label='Amount'
                          type='number'
                          value={amount}
                          required
                          onChange={this.onChange}
                          errorStatus={validation.amount.status}
                          errorText={validation.amount.message}
                        />
                      </Box>
                      <Box
                        width={{ xs: 1, lg: '30%' }}
                        display='flex'
                        justifyContent='center'
                      >
                        <Checkbox
                          name='exact_amount'
                          label={t('Exact amount')}
                          checked={exact_amount}
                          onChange={this.onChange}
                        />
                      </Box>
                    </Box>
                  </>
                )}
                {type === FREE_PRODUCT_PROMOTION && (
                  <>
                    <Input
                      name='amount'
                      label='Amount'
                      type='number'
                      value={amount}
                      required
                      onChange={this.onChange}
                      errorStatus={validation.amount.status}
                      errorText={validation.amount.message}
                    />
                    <Checkbox
                      name='same_product'
                      label={t('The same products must be ordered')}
                      checked={same_product}
                      onChange={this.onChange}
                    />
                  </>
                )}
              </>
            )}
          </Box>
        </>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button text={t('Cancel')} onClick={() => this.redirect()} />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

PromotionForm.defaultProps = {
  promotion: null
};

PromotionForm.propTypes = {
  promotion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({})),
    subcategory_id: PropTypes.number,
    type_data: PropTypes.shape({}),
    amount: PropTypes.number,
    exact_amount: PropTypes.bool,
    same_product: PropTypes.bool,
    can_combine: PropTypes.bool,
    order_type: PropTypes.number,
    clients: PropTypes.arrayOf(PropTypes.shape({})),
    sales_groups: PropTypes.arrayOf(PropTypes.shape({})),
    regions: PropTypes.arrayOf(PropTypes.shape({})),
    country_id: PropTypes.number,
    is_global: PropTypes.bool,
    is_dedicated: PropTypes.number
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(PromotionForm);
