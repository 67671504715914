import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class WholesalersFilesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.wholesalerFilesPath = 'wholesalers/:id/files';
    this.wholesalerFilePath = 'wholesalers/:wholesalerId/files/:fileId';
  }

  getWholesalerFiles(id, pathParams) {
    return this.get(
      insertPathParams(this.wholesalerFilesPath, { id }),
      pathParams
    );
  }

  createWholesalerFile(id, payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return this.post(
      insertPathParams(this.wholesalerFilesPath, { id }),
      payload,
      options
    );
  }

  updateWholesalerFile(ids, payload) {
    return this.put(insertPathParams(this.wholesalerFilePath, ids), payload);
  }

  deleteWholesalerFile(ids) {
    return this.delete(insertPathParams(this.wholesalerFilePath, ids));
  }

  getWholesalerFile(ids) {
    const options = {
      responseType: 'blob'
    };
    return this.get(
      insertPathParams(this.wholesalerFilePath, ids),
      {},
      options
    );
  }
}

export default new WholesalersFilesApi();
