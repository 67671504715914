import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Box from '@material-ui/core/Box';
import { formatOptions } from 'modules/Layout/component/Select';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

class SubcategoryPicker extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    const { initData } = props;

    this.state = {
      groupId: initData?.groupId ?? null,
      categoryId: initData?.categoryId ?? null,
      subcategoryId: initData?.subcategoryId ?? null,
      categories: [],
      loading: true
    };

    this.onCategoryChange = this.onCategoryChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onCategoryChange(name, inputValue) {
    const value = inputValue?.key || null;

    this.setState(state => {
      const { groupId, categoryId, subcategoryId } = state;

      const newState = {
        groupId,
        categoryId,
        subcategoryId
      };

      if (name === 'group') {
        newState.groupId = value;
        newState.categoryId = null;
        newState.subcategoryId = null;
      } else if (name === 'category') {
        newState.categoryId = value;
        newState.subcategoryId = null;
      } else {
        newState.subcategoryId = value;
      }

      this.props.onSuccess(newState);

      return newState;
    });
  }

  async fetchData() {
    try {
      const {
        data: categories
      } = await ProductCategoriesApi.getProductCategories();

      this.setState({ categories, loading: false });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const {
      groupId,
      categoryId,
      subcategoryId,
      categories,
      loading
    } = this.state;

    return (
      <>
        <Box mr={{ xs: 0, lg: 1 }} width={1}>
          <Autocomplete
            label='Product group'
            name='group'
            value={isEmpty(categories) ? null : groupId}
            options={formatOptions(categories, 'id', 'name')}
            onChange={(e, value) => this.onCategoryChange('group', value)}
            loading={loading}
          />
        </Box>
        <Box mr={{ xs: 0, lg: 1 }} width={1}>
          <Autocomplete
            label='Product category'
            name='category'
            value={isEmpty(categories) ? null : categoryId}
            options={formatOptions(
              categories.find(g => g.id === groupId)?.categories || [],
              'id',
              'name'
            )}
            onChange={(e, value) => this.onCategoryChange('category', value)}
            loading={loading}
          />
        </Box>
        <Box width={1}>
          <Autocomplete
            label='Product subcategory'
            name='subcategory'
            value={isEmpty(categories) ? null : subcategoryId}
            options={formatOptions(
              categories
                .find(g => g.id === groupId)
                ?.categories.find(c => c.id === categoryId)?.subcategories ||
                [],
              'id',
              'name'
            )}
            onChange={(e, v) => this.onCategoryChange('subcategory', v)}
            loading={loading}
          />
        </Box>
      </>
    );
  }
}

SubcategoryPicker.defaultProps = {
  initData: null
};

SubcategoryPicker.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  initData: PropTypes.shape({
    groupId: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired,
    subcategoryId: PropTypes.number.isRequired
  })
};

export default SubcategoryPicker;
