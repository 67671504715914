export const SET_STATE = 'SET_STATE';
export const ITEM_SELECTED = 'ITEM_SELECTED';
export const ADD_BTN_CLICKED = 'ADD_BTN_CLICKED';
export const SET_STATE_AFTER_CREATE = 'SET_STATE_AFTER_CREATE';
export const SET_ITEM = 'SET_ITEM';
export const SET_DELETE_ITEM = 'SET_DELETE_ITEM';
export const CLEAR_AFTER_DELETE = 'CLEAR_AFTER_DELETE';
export const CLEAR_ITEM = 'CLEAR_ITEM';

export default (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return {
        ...state,
        ...action.payload
      };
    case ITEM_SELECTED:
    case ADD_BTN_CLICKED:
    case SET_STATE_AFTER_CREATE:
      return {
        ...state,
        ...action.payload
      };
    case SET_ITEM:
      return {
        ...state,
        item: action.payload.item,
        itemType: action.payload.itemType
      };
    case SET_DELETE_ITEM:
      return {
        ...state,
        item: action.payload.item,
        itemType: action.payload.itemType,
        deleteDialogOpenStatus: true
      };
    case CLEAR_AFTER_DELETE: {
      const {
        item: { id: itemId },
        selectedGroupId: groupId,
        selectedGroupName: groupName,
        selectedCategoryId: categoryId,
        selectedCategoryName: categoryName
      } = state;

      let result = {
        ...state,
        item: null,
        itemType: null,
        deleteDialogOpenStatus: false,
        selectedGroupId: itemId === groupId ? null : groupId,
        selectedGroupName: itemId === groupId ? null : groupName,
        selectedCategoryId: itemId === categoryId ? null : categoryId,
        selectedCategoryName: itemId === categoryId ? null : categoryName
      };

      const isGroup = itemId === groupId;

      if (isGroup) {
        result = {
          ...result,
          selectedCategoryId: null,
          selectedCategoryName: null
        };
      }

      return result;
    }
    case CLEAR_ITEM:
      return {
        ...state,
        item: null,
        itemType: null,
        deleteDialogOpenStatus: false
      };
    default:
      return state;
  }
};
