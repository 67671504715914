import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { isEqual } from 'lodash/lang';
import SalesResultsCharts from 'modules/SalesResults/component/ResultsDetails/Charts';
import SalesResultsNavigationBar from 'modules/SalesResults/component/ResultsDetails/NavigationBar';
import SalesResultsLegend from 'modules/SalesResults/component/ResultsDetails/Legend';
import SalesResultsApi from 'api/connections/SalesResults/SalesResultsApi';
import formatSalesResults from 'modules/SalesResults/utils/formatSalesResults';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import IconButton from 'modules/Layout/component/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/* eslint no-shadow: "off" */
class SalesResultsWrapper extends Component {
  static contextType = AlertContext;

  static renderLoading() {
    return <Loader />;
  }

  static renderNoData() {
    return (
      <Box display='flex' justifyContent='center'>
        <Typography color='textPrimary'>{t('No data')}</Typography>
      </Box>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      stack: [],
      breadcrumbs: [t('Comprehensive data')],
      viewType: 'pie',
      renderData: null,
      salesResultData: {},
      loading: false
    };

    this.drillUp = this.drillUp.bind(this);
    this.drillDown = this.drillDown.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.filter, this.props.filter)) {
      this.fetchData();
    }
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  }

  drillDown(data) {
    if (data.children.length > 0) {
      this.setState(state => {
        return {
          stack: [...state.stack, state.renderData],
          renderData: data.children,
          breadcrumbs: [...state.breadcrumbs, data.name]
        };
      });
    }
  }

  drillUp(index) {
    this.setState(state => {
      if (state.breadcrumbs.length <= 1) return { ...state };
      return {
        renderData: state.stack[index],
        breadcrumbs: state.breadcrumbs.slice(0, index + 1),
        stack: state.stack.slice(0, index)
      };
    });
  }

  async fetchData() {
    const { filter } = this.props;

    if (!filter.date_from || !filter.date_to) return null;

    try {
      this.setState({ loading: true });

      const {
        data: { data: salesResultData }
      } = await SalesResultsApi.getCurrentSalesResults(filter);

      const formattedSalesResultsData = formatSalesResults(salesResultData);

      this.setState({
        salesResultData,
        renderData: formattedSalesResultsData,
        stack: [],
        breadcrumb: [],
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderData() {
    const { breadcrumbs, renderData } = this.state;
    const { viewType, quantityOrValue } = this.props;
    const nameSuffix = Date.now();

    return (
      <>
        <Box display='flex' flexDirection='row-reverse'>
          <IconButton
            className='delete-icon'
            onClick={this.props.onDeleteClick}
            icon={<CloseIcon />}
            size='small'
            alt='close chart'
          />
        </Box>
        <SalesResultsNavigationBar
          breadcrumbs={breadcrumbs}
          onDrillUp={this.drillUp}
          viewType={viewType}
        />
        <SalesResultsCharts
          salesResultsData={renderData}
          viewType={viewType}
          quantityOrValue={quantityOrValue}
          onDrillDown={this.drillDown}
          nameSuffix={nameSuffix}
        />
        <SalesResultsLegend nameSuffix={nameSuffix} />
      </>
    );
  }

  renderContent() {
    const { loading, renderData } = this.state;
    const { date_from, date_to } = this.props.filter;

    if (!date_from || !date_to) {
      return null;
    }

    if (loading) {
      return SalesResultsWrapper.renderLoading();
    }

    if (!renderData || renderData.length === 0) {
      return SalesResultsWrapper.renderNoData();
    }

    return this.renderData();
  }

  render() {
    return this.renderContent();
  }
}

SalesResultsWrapper.propTypes = {
  filter: PropTypes.shape({
    date_from: PropTypes.string,
    date_to: PropTypes.string
  }).isRequired,
  viewType: PropTypes.string.isRequired,
  quantityOrValue: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default SalesResultsWrapper;
