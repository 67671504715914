import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import Input from 'modules/Layout/component/Input';
import { Box } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import WholesalersAgenciesApi from 'api/connections/Wholesalers/WholesalersAgenciesApi';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { ROUTE_WHOLESALERS_DETAILS } from 'routing/routes/Wholesalers';
import ApiError from 'api/exceptions/ApiError';

class WholesalerAgencyForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: props.agency?.name ?? '',
        email: props.agency?.email ?? '',
        city: props.agency?.city ?? '',
        street: props.agency?.street ?? '',
        postal_code: props.agency?.postal_code ?? ''
      },
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        },
        email: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Email')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Agency saved'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.redirect();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(prevState => {
      const { formData, validation } = prevState;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  redirect() {
    const { wholesalerId } = this.props;

    let path = insertPathParams(ROUTE_WHOLESALERS_DETAILS, {
      id: wholesalerId
    });

    path += '?tab_index=agencies';

    this.props.history.push(path);
  }

  async makeApiCall() {
    const { formData } = this.state;
    const { wholesalerId, agency } = this.props;

    try {
      this.setState({ loading: true });

      if (agency) {
        await WholesalersAgenciesApi.updateWholesalerAgency(
          { wholesalerId, agencyId: agency.id },
          formData
        );
      } else {
        await WholesalersAgenciesApi.createWholesalerAgency(
          wholesalerId,
          formData
        );
      }
      this.setState({ loading: false });

      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { name, email, city, street, postal_code },
      validation,
      loading
    } = this.state;

    const { wholesalerName } = this.props;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <TextDataSet label='Wholesaler' data={wholesalerName} />
        <Input
          name='name'
          label='Name'
          required
          value={name}
          onChange={this.onChange}
          errorStatus={validation.name.status}
          errorText={validation.name.message}
        />
        <Input
          name='email'
          label='Email'
          required
          value={email}
          onChange={this.onChange}
          errorStatus={validation.email.status}
          errorText={validation.email.message}
        />
        <Input name='city' label='City' value={city} onChange={this.onChange} />
        <Input
          name='street'
          label='Street'
          value={street}
          onChange={this.onChange}
        />
        <Input
          name='postal_code'
          label='Postal code'
          value={postal_code}
          onChange={this.onChange}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button text={t('Cancel')} onClick={this.redirect} />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

WholesalerAgencyForm.defaultProps = {
  agency: undefined
};

WholesalerAgencyForm.propTypes = {
  wholesalerId: PropTypes.number.isRequired,
  wholesalerName: PropTypes.string.isRequired,
  agency: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    postal_code: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(WholesalerAgencyForm);
