import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';

const Loader = () => {
  return (
    <Box display='flex' justifyContent='center'>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
