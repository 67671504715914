import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    '&:focus > .MuiTreeItem-content .MuiTreeItem-label ': {
      backgroundColor: 'unset!important'
    },
    '& .MuiTreeItem-content': {
      cursor: 'default'
    },
    '& .MuiTreeItem-label': {
      '&:hover, &:focus': {
        backgroundColor: 'unset!important'
      },
      '& .MuiTypography-root': {
        fontSize: '0.95rem'
      }
    }
  },
  labelRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  customBadge: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    minWidth: '22px',
    minHeight: '22px',
    padding: '1px 7px',
    borderRadius: '15px',
    marginLeft: '5px',

    fontSize: '11px',
    fontWeight: '600',
    letterSpacing: '1px',
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
}));

export default useStyles;
