import fileDownload from 'js-file-download';

export const downloadFile = (res, fileName = null) => {
  const file = new Blob([res.data]);

  const contentDisposition = res.headers['content-disposition'];
  const fileNameIndex = contentDisposition.indexOf('filename=') + 9;

  let fileNameEnd = contentDisposition.indexOf(';', fileNameIndex);
  fileNameEnd = fileNameEnd === -1 ? contentDisposition.length : fileNameEnd;

  const headerFileName = contentDisposition
    .substring(fileNameIndex, fileNameEnd)
    .replaceAll('"', '');

  const finalFileName = fileName || headerFileName || 'file';

  fileDownload(file, finalFileName);
};
