export const UNCONFIRMED_CLIENTS_CONTRACT_STATUS = 0;
export const CONFIRMED_CLIENTS_CONTRACT_STATUS = 1;

export default {
  [UNCONFIRMED_CLIENTS_CONTRACT_STATUS]: 'open',
  [CONFIRMED_CLIENTS_CONTRACT_STATUS]: 'close'
};

export const clientContractStatusesArr = [
  {
    key: UNCONFIRMED_CLIENTS_CONTRACT_STATUS,
    name: 'open'
  },
  {
    key: CONFIRMED_CLIENTS_CONTRACT_STATUS,
    name: 'close'
  }
];
