import { Component } from 'react';
import PropTypes from 'prop-types';
import { ROUTE_WHOLESALERS_LIST } from 'routing/routes/Wholesalers';
import { Box, Paper } from '@material-ui/core';
import WholesalersContactCreateForm from 'modules/Wholesalers/component/Contacts/Create/Form';
import { withRouter } from 'react-router-dom';
import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';

class WholesalerContactCreate extends Component {
  static contextType = CurrentPageContext;

  async componentDidMount() {
    const { id: wholesalerId } = this.props.match.params;
    if (!wholesalerId) {
      this.props.history.push(ROUTE_WHOLESALERS_LIST);
    }
    const { setCurrentPage } = this.context;
    setCurrentPage('Create wholesalers contact');
  }

  render() {
    const { id: wholesalerId } = this.props.match.params;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <WholesalersContactCreateForm wholesalerId={wholesalerId} />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalerContactCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(WholesalerContactCreate);
