export default {
  'Postal codes': 'Kody pocztowe',
  'Postal code': 'Kod pocztowy',
  'Postal Codes': 'Kody Pocztowe',
  Regions: 'Regiony',
  Country: 'Kraj',
  Region: 'Województwo',
  Subregion: 'Powiat',
  'Sub subregion': 'Gmina',
  City: 'Miasto',
  'Are you sure you want to delete region: ':
    'Czy na pewno chcesz usunąć województwo: ',
  'Are you sure you want to delete subregion: ':
    'Czy na pewno chcesz usunąć powiat: ',
  'Are you sure you want to delete sub subregion: ':
    'Czy na pewno chcesz usunąć gminę: ',
  'Are you sure you want to delete city: ':
    'Czy na pewno chcesz usunąć miasto: ',
  'Are you sure you want to delete postal code: ':
    'Czy na pewno chcesz usunąć kod pocztowy: ',
  'Delete all the cities of this region, first.':
    'Najpierw usuń miasta tego województwa.',
  'Delete all the cities of this subregion, first.':
    'Najpierw usuń miasta tego powiatu.',
  'Delete all the cities of this sub subregion, first.':
    'Najpierw usuń miasta tej gminy.',
  'Delete all the postal codes of this city, first.':
    'Najpierw usuń kody pocztowe tego miasta.',
  'No data': 'Brak danych',
  'Adding an item': 'Dodawanie elementu',
  Type: 'Typ',
  'Structure depth': 'Głębokość struktury',
  'Default language': 'Domyślny język',
  'Adding a country': 'Dodawanie kraju',
  'Adding a region': 'Dodawanie województwa',
  'Adding a subregion': 'Dodawanie powiatu',
  'Adding a sub subregion': 'Dodawanie gminy',
  'Adding a city': 'Dodawanie miasta',
  'Adding a postal code': 'Dodawanie kodu pocztowego',
  'Editing a country': 'Edycja kraju',
  'Editing a region': 'Edycja województwa',
  'Editing a subregion': 'Edycja powiatu',
  'Editing a sub subregion': 'Edycja gminy',
  'Editing a city': 'Edycja miasta',
  'Editing a postal code': 'Edycja kodu pocztowego',
  'Parent region': 'Region nadrzędny',
  Currency: 'Waluta'
};
