import PropTypes from 'prop-types';
import { useContext } from 'react';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import ProductPriorityApi from 'api/connections/Products/ProductPriorityApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const ProductPriorityDeleteDialog = props => {
  const { setAlert } = useContext(AlertContext);

  const { productId, priorityId, onClose, onSuccess } = props;

  const onApprove = async () => {
    try {
      await ProductPriorityApi.deleteProductPriority({ productId, priorityId });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      err instanceof ApiError
        ? setAlert(err.getPayload().message)
        : console.error(err);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete priority?')}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

ProductPriorityDeleteDialog.propTypes = {
  productId: PropTypes.number.isRequired,
  priorityId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ProductPriorityDeleteDialog;
