import { DEPARTMENT_MANAGER, DIRECTOR } from 'api/auth/roles';

const setUpdateActionDisableMsg = role => {
  if (role === DIRECTOR) {
    return 'User does not belong to your department';
  }
  if (role === DEPARTMENT_MANAGER) {
    return 'User is not your subordinate';
  }
  return null;
};

export default setUpdateActionDisableMsg;
