export const hasToken = () => {
  const value = localStorage.getItem('access_token');
  return value !== null;
};

export const hasRefreshToken = () => {
  const value = localStorage.getItem('refresh_token');
  return value !== null;
};

export const setAccessToken = token => {
  localStorage.setItem('access_token', token);
};

export const setRefreshToken = token => {
  localStorage.setItem('refresh_token', token);
};

export const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

export const clearTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};
