export default {
  'Shopping groups': 'Grupy zakupowe',
  'Shopping Groups': 'Grupy Zakupowe',
  'Shopping group': 'Grupa zakupowa',
  'Shopping group name': 'Nazwa grupy zakupowej',
  'Shopping group details': 'Szczegóły grupy zakupowej',
  'Create shopping group': 'Dodawanie grupy zakupowej',
  'Update shopping group': 'Edytowanie grupy sprzedażowej',
  'Are you sure you want to delete shopping group <%=name%>':
    'Czy na pewno chcesz usunać grupe zakupową <%=name%>',
  'Create shopping group file': 'Dodawanie pliku grupy zakupowej',
  'Update shopping group file': 'Edytowanie pliku grupy zakupowej',
  'Assign sales group to client': 'Przypisz grupę produktową do klienta',
  'Assign client to sales group': 'Przypisz klienta do grupy zakupowej',
  'Are you sure you want to detach shopping group <%=groupName%> from client':
    'Czy na pewno chcesz odłaczyć grupe zakupową <%=groupName%> od klienta',
  'Are you sure you want to detach client <%=name%> from shopping group':
    'Czy na pewno chcesz odłaczyć klienta <%=name%> od grupy zakupowej',
  'Select Client first': 'Wybierz klienta',
  'Select product subcategory first':
    'Wybierz najpierw podkategorię produktową',
  'Current shopping group': 'Obecna grupa zakupowa',
  'Selecting a customer who already has a shopping group will result in his removal from the group he currently has.':
    'Wybranie klienta, który posiada już grupę zakupową spowoduje wypisanie go z grupy którą obecnie posiada.',
  'export price list': 'eksportuj cennik'
};
