export const ORDER_STATUS_PREPARING = 0;
export const ORDER_STATUS_SENT_TO_PROCESSING = 1;

export default [
  { status: ORDER_STATUS_PREPARING, name: 'preparing' },
  { status: ORDER_STATUS_SENT_TO_PROCESSING, name: 'sent for processing' },
  { status: 2, name: 'accepted for processing' },
  { status: 3, name: 'denied' },
  { status: 4, name: 'processing' },
  { status: 5, name: 'processed' },
  { status: 6, name: 'processed partially' }
];
