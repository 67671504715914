export const BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION = 0;
export const BONUS_TYPE_WAITING_FOR_CONFIRMATION = 1;
export const BONUS_TYPE_CONFIRMED = 2;
export const BONUS_TYPE_PAID = 3;
export const BONUS_TYPE_SUSPENDED = 4;

export default {
  [BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION]: 'in progress',
  [BONUS_TYPE_WAITING_FOR_CONFIRMATION]: 'waiting for confirmation',
  [BONUS_TYPE_CONFIRMED]: 'confirmed',
  [BONUS_TYPE_PAID]: 'paid',
  [BONUS_TYPE_SUSPENDED]: 'suspended'
};

export const clientBonusStatusesArr = [
  {
    key: BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION,
    name: 'in progress'
  },
  {
    key: BONUS_TYPE_WAITING_FOR_CONFIRMATION,
    name: 'waiting for confirmation'
  },
  { key: BONUS_TYPE_CONFIRMED, name: 'confirmed' },
  { key: BONUS_TYPE_PAID, name: 'paid' },
  { key: BONUS_TYPE_SUSPENDED, name: 'suspended' }
];
