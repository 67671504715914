import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import ClientsAccountApi from 'api/connections/Clients/ClientsAccountApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const ClientAccountDeleteDialog = props => {
  const { clientId, clientName, onClose, onSuccess } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteAccount = async () => {
    try {
      await ClientsAccountApi.deleteClientAccount(clientId);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete account for client <%=clientName%>',
        { clientName }
      )}
      onApprove={deleteAccount}
      onCancel={onClose}
    />
  );
};

ClientAccountDeleteDialog.propTypes = {
  clientId: PropTypes.number.isRequired,
  clientName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ClientAccountDeleteDialog;
