import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ImportsApi from 'api/connections/Imports/ImportsApi';
import Loader from 'modules/Layout/component/Loader';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import RefreshIcon from '@material-ui/icons/Refresh';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import Authorize from 'modules/Auth/component/Authorize';
import { IMPORTS_START } from 'api/auth/permissions/Imports';
import { downloadFile } from 'modules/Shared/utils/file';

import ImportsTable from 'modules/Imports/component/List/Table';
import ImportsFilter from 'modules/Imports/component/List/Filter';

class ImportsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filter: {
        country_id: null,
        status: ''
      },
      filterValidation: {},
      loading: true,
      refreshLoading: false,
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      }
    };

    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
  }

  componentDidMount() {
    const type = this.props.type.replace('_', ' ');

    this.props.contextMethods.setCurrentPage(`Imports ${type}`);

    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onDownloadClick({ id }) {
    try {
      const res = await ImportsApi.downloadImportFile(id);

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination }
      },
      () => this.fetchData()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchData()
    );
  }

  async fetchData(refresh = false) {
    const { filter } = this.state;
    const { per_page, page } = this.state.pagination;
    const { type } = this.props;

    try {
      this.setState({
        refreshLoading: refresh,
        loading: !refresh
      });

      const {
        data: { data, meta }
      } = await ImportsApi.getImports({
        type,
        per_page,
        page,
        ...filter
      });

      this.setState({
        data,
        pagination: formatMetaToPagination(meta),
        refreshLoading: false,
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async startImport() {
    const { type } = this.props;

    try {
      await ImportsApi.startImport({ type });

      this.setState({ pagination: { ...this.state.pagination, page: 1 } }, () =>
        this.fetchData()
      );
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination, refreshLoading } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex' flexDirection='row'>
          <Authorize permissions={[IMPORTS_START]}>
            <IconButton
              className='create-icon'
              onClick={() => this.startImport()}
              icon={<PlayCircleFilledWhiteIcon fontSize='large' />}
              alt='start import'
              disabled={this.context.user.language !== 'pl'}
              tooltipMsg='Imports operate only in Poland'
            />
          </Authorize>
          <IconButton
            className='update-icon'
            onClick={() => this.fetchData(true)}
            icon={<RefreshIcon fontSize='large' />}
            alt='refresh list'
          />
          {refreshLoading && <Loader />}
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const { data, loading, filter, filterValidation } = this.state;

    return (
      <>
        <ImportsFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderActions()}
        <ImportsTable
          data={data}
          loading={loading}
          onDownloadClick={this.onDownloadClick}
        />
        {this.renderActions()}
      </>
    );
  }
}

ImportsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  type: PropTypes.string.isRequired
};

export default ImportsList;
