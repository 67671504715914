import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class WholesalersAgenciesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.wholesalerAgenciesPath = 'wholesalers/:id/agencies';
    this.wholesalerAgencyPath = 'wholesalers/:wholesalerId/agencies/:agencyId';
  }

  getWholesalerAgencies(id, pathParams) {
    return this.get(
      insertPathParams(this.wholesalerAgenciesPath, { id }),
      pathParams
    );
  }

  getWholesalerAgency(ids) {
    return this.get(insertPathParams(this.wholesalerAgencyPath, ids));
  }

  createWholesalerAgency(id, payload) {
    return this.post(
      insertPathParams(this.wholesalerAgenciesPath, { id }),
      payload
    );
  }

  updateWholesalerAgency(ids, payload) {
    return this.put(insertPathParams(this.wholesalerAgencyPath, ids), payload);
  }

  deleteWholesalerAgency(ids) {
    return this.delete(insertPathParams(this.wholesalerAgencyPath, ids));
  }
}

export default new WholesalersAgenciesApi();
