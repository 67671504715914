import dashboardMenu from 'modules/Layout/component/Navigator/menuConfig/Dashboard';
import clientsMenu from 'modules/Layout/component/Navigator/menuConfig/Clients';
import ProductsMenu from 'modules/Layout/component/Navigator/menuConfig/Products';
import wholesalersMenu from 'modules/Layout/component/Navigator/menuConfig/Wholesalers';
import SalesMenu from 'modules/Layout/component/Navigator/menuConfig/Sales';
import VisitsMenu from 'modules/Layout/component/Navigator/menuConfig/Visits';
import TargetsMenu from 'modules/Layout/component/Navigator/menuConfig/Targets';
import DiscountsMenu from 'modules/Layout/component/Navigator/menuConfig/Discounts';
import AdministrationMenu from 'modules/Layout/component/Navigator/menuConfig/Administration';
import StatisticsMenu from 'modules/Layout/component/Navigator/menuConfig/Statistics';
import KnowledgeBaseMenu from 'modules/Layout/component/Navigator/menuConfig/KnowledgeBase';
import AffiliationMenu from 'modules/Layout/component/Navigator/menuConfig/Affiliation';
import ClientsContractsMenu from 'modules/Layout/component/Navigator/menuConfig/ClientsContracts';
import PriceListsMenu from 'modules/Layout/component/Navigator/menuConfig/PriceLists';
import ImportsMenu from 'modules/Layout/component/Navigator/menuConfig/Imports';

export default [
  ...dashboardMenu,
  ...VisitsMenu,
  ...clientsMenu,
  ...ProductsMenu,
  ...SalesMenu,
  ...DiscountsMenu,
  ...TargetsMenu,
  ...PriceListsMenu,
  ...wholesalersMenu,
  ...StatisticsMenu,
  ...KnowledgeBaseMenu,
  ...ImportsMenu,
  ...AdministrationMenu,
  ...AffiliationMenu,
  ...ClientsContractsMenu
];
