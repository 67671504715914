import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import DiscountsApi from 'api/connections/Discounts/DiscountsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import { useContext } from 'react';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const DiscountsDeleteDialog = props => {
  const { setAlert } = useContext(AlertContext);

  const {
    discount: { id, name },
    onClose,
    onSuccess
  } = props;

  const handleError = err => {
    err instanceof ApiError
      ? setAlert(err.getPayload().message)
      : console.error(err);
  };

  const onApprove = async () => {
    try {
      await DiscountsApi.deletedDiscount(id);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      handleError(err);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete discount <%=name%>?', {
        name
      })}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

DiscountsDeleteDialog.propTypes = {
  discount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default DiscountsDeleteDialog;
