export default {
  Visits: 'Wizyty',
  Visit: 'Wizyta',
  Daily: 'Dzienny',
  Weekly: 'Tygodniowy',
  Monthly: 'Miesięczny',
  January: 'Styczeń',
  February: 'Luty',
  March: 'Marzec',
  April: 'Kwiecień',
  May: 'Maj',
  June: 'Czerwiec',
  July: 'Lipiec',
  August: 'Sierpień',
  September: 'Wrzesień',
  October: 'Październik',
  November: 'Listopad',
  December: 'Grudzień',
  Time: 'Godzina',
  Mon: 'Pon',
  Tue: 'Wt',
  Wed: 'Śr',
  Thu: 'Czw',
  Fri: 'Pt',
  Sat: 'Sob',
  Sun: 'Ndz',
  User: 'Użytkownik',
  Schedule: 'Harmonogram Wizyt',
  "Meeting point at the client's": 'Miejsce spotkania u klienta',
  'Geographical coordinates': 'Współrzędne geograficzne',
  Latitude: 'Szerokość',
  Longitude: 'Długość',
  'Visit time': 'Czas spotkania',
  'Unknown city': 'Nieznane miasto',
  'Unknown street': 'Nieznana ulica',
  'Unknown postal code': 'Nieznany kod pocztowy',
  'Visit details': 'Szczegóły wizyty',
  'Update visit': 'Edycja wizyty',
  'Postponement reason': 'Powód przełożenia',
  'Visit postponement': 'Przekładanie wizyty',
  Postpone: 'Przekładanie',
  'Previous visit date': 'Poprzednia data wizyty',
  'Postponed at': 'Przełożona dnia',
  'Postponed by': 'Przełożone przez',
  'Informations about postponed visits': 'Informacje o odłożonych wizytach',
  'Visit cannot be finished or canceled':
    'Wizyta nie może być zakończona lub anulowana',
  'You can update your, your employee visit and visit which is not finished':
    'Możesz edytować swoją wizytę, wizytę swojego pracownika oraz te, które nie są zakończone',
  'You can postpone your, your employee visit and visit which is not finished':
    'Możesz przełożyć swoją wizytę, wizytę swojego pracownika oraz te, które nie są zakończone',
  Planned: 'Zaplanowane',
  Confirmed: 'Potwierdzone',
  Finished: 'Zakończone',
  Canceled: 'Odwołane',
  Postponed: 'Przełożone',
  cancellation: 'odwołanie',
  'You can cancel your, your employee visit and visit which is not finished':
    'Możesz odwołać swoją wizytę, wizytę swojego pracownika oraz te, które nie są zakończone',
  'Visit cancellation': 'Odwołanie wizyty',
  'Cancel reason': 'Powód odwołania',
  'Are you sure you want to to cancel this visit?':
    'Na pewno chcesz odwołać tę wizytę?',
  'You are not assign to this visit': 'Nie jesteś przypisany do tej wizyty',
  'Remembered products': 'Zapamiętane produkty',
  Note: 'Notatka',
  'Are you sure you want to delete product "<%=sku%>" from current visit?':
    'Jesteś pewien, że chcesz usunąć produkt "<%=sku%>" z wizyty?',
  'You are not creator of this note': 'Nie jesteś twórcą tej notatki',
  'Add product': 'Dodawanie produktu',
  'Add visit file': 'Dodawanie pliku wizyty',
  'Update visit file': 'Edytowanie pliku wizyty',
  'Visits list': 'Lista wizyt',
  Calendar: 'Kalendarz',
  'Mon.': 'Pon.',
  'Tue.': 'Wt.',
  'Wed.': 'Śr.',
  'Thu.': 'Czw.',
  'Fri.': 'Pt.',
  'Sat.': 'Sob.',
  'Sun.': 'Ndz.',
  'Daily visits list': 'Dzienna lista wizyt',
  Routes: 'Trasy',
  'Last visit at': 'Data ostatniego spotkania',
  'Route planning': 'Planowanie trasy',
  'Show route': 'Pokaż trasę',
  'Copying visits': 'Kopiowanie wizyt',
  'Copy visits': 'Kopiuj wizyty',
  'Are you sure you want to copy selected visits?':
    'Na pewno chcesz skopiować wybrane wizyty?',
  'Number of visits': 'Liczba wizyt',
  'Number of days': 'Liczba dni',
  'You can update your or your employee visit which is not finished.':
    'Możesz edytować swoją wizytę lub wizytę swojego pracownika, która nie jest zakończona.',
  'You can postpone your or your employee visit which is not finished.':
    'Możesz przełożyć swoją wizytę lub wizytę swojego pracownika, która nie jest zakończona.',
  'You can cancel your or your employee visit which is not finished.':
    'Możesz odwołać swoją wizytę lub wizytę swojego pracownika, która nie jest zakończona.',
  'My visits': 'Moje wizyty',
  'No visits': 'Brak wizyt',
  'Add client': 'Dodaj klienta',
  'Latest visit': 'Ostatnia wizyta',
  Close: 'Zamknij',
  'Long ago visited clients': 'Dawno nieodwiedzeni klienci',
  'Create visit': 'Dodawanie wizyty',
  'create visit': 'dodawanie wizyty',
  'Map routes': 'Trasy',
  'Time from last visit': 'Czas od ostatniej wizyty',
  Add: 'Dodaj'
};
