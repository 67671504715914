import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { ROUTE_WHOLESALERS_DETAILS } from 'routing/routes/Wholesalers';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import insertPathParams from 'api/utils/insertPathParams';
import Checkbox from 'modules/Layout/component/Checkbox';
import ChipInput from 'modules/Layout/component/ChipInput';
import { formatWholesalersEmailDomainsError } from 'modules/Wholesalers/utils/formatter';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

class UpdateWholesalersForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    const {
      id,
      name,
      city,
      street,
      postal_code,
      phone,
      tax_number,
      main_contact_email,
      orders_emails,
      orders_email_same_as_main,
      emails,
      domains,
      provides_sales_data
    } = props.wholesaler;

    this.state = {
      wholesalerId: id,
      formData: {
        name: name || '',
        city: city || '',
        street: street || '',
        postal_code: postal_code || '',
        phone: phone || '',
        tax_number: tax_number || '',
        main_contact_email: main_contact_email || '',
        orders_emails: orders_emails || [],
        emails: emails || [],
        domains: domains || [],
        orders_email_same_as_main,
        provides_sales_data
      },
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Name') })
        },
        main_contact_email: {
          status: false,
          message: t('Wrong format')
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChipAdd = this.onChipAdd.bind(this);
    this.onChipDelete = this.onChipDelete.bind(this);
  }

  handleResponse(res) {
    const { id } = res;

    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(
      insertPathParams(ROUTE_WHOLESALERS_DETAILS, { id })
    );
  }

  handleError(err) {
    const { errors, message } = err.getPayload();
    this.context.setAlert(message);
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      let emailDomainsValidState = {};

      const { emails, domains, orders_emails } = this.state.formData;
      emailDomainsValidState = formatWholesalersEmailDomainsError(errors, {
        emails,
        domains,
        orders_emails
      });

      this.setState(({ validation: validationState }) => {
        return {
          validation: {
            ...validationState,
            ...newValidateState,
            ...emailDomainsValidState
          }
        };
      });
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, type } = e.target;
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onChipAdd(name, chip) {
    this.setState(prevState => {
      const { formData } = prevState;

      return {
        formData: {
          ...formData,
          [name]: [...formData[name], chip]
        }
      };
    });
  }

  onChipDelete(name, chip) {
    this.setState(prevState => {
      const { formData, validation } = prevState;

      return {
        formData: {
          ...formData,
          [name]: formData[name].filter(data => data !== chip)
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { wholesalerId, formData } = this.state;
    const payload = {
      ...formData,
      emails: formData.provides_sales_data ? formData.emails : [],
      domains: formData.provides_sales_data ? formData.domains : []
    };

    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await WholesalersApi.updateWholesaler(wholesalerId, payload);
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: {
        name,
        city,
        street,
        postal_code,
        phone,
        tax_number,
        main_contact_email,
        orders_emails,
        orders_email_same_as_main,
        emails,
        domains,
        provides_sales_data
      },
      wholesalerId,
      validation,
      loading
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='name'
          label='Name'
          value={name}
          required
          multiline
          inputProps={{ maxLength: 500 }}
          onChange={this.onChange}
          errorStatus={validation.name.status}
          errorText={validation.name.message}
        />
        <Input name='city' label='City' value={city} onChange={this.onChange} />
        <Input
          name='street'
          label='Street'
          value={street}
          onChange={this.onChange}
        />
        <Input
          name='postal_code'
          label='Postal code'
          value={postal_code}
          onChange={this.onChange}
        />
        <Input
          name='phone'
          label='Phone number'
          value={phone}
          onChange={this.onChange}
        />
        <Input
          name='tax_number'
          label='Tax number'
          value={tax_number}
          onChange={this.onChange}
          errorStatus={validation.tax_number?.status}
          errorText={validation.tax_number?.message}
        />
        <Input
          name='main_contact_email'
          label='Main contact email'
          type='email'
          value={main_contact_email}
          onChange={this.onChange}
          errorStatus={validation.main_contact_email.status}
          errorText={validation.main_contact_email.message}
        />
        <Checkbox
          name='orders_email_same_as_main'
          label='Orders email same as main'
          onChange={this.onChange}
          checked={orders_email_same_as_main}
        />
        {!orders_email_same_as_main && (
          <Box mb={validation.orders_emails?.status ? 2 : 0}>
            <ChipInput
              name='orders_emails'
              label='Orders email'
              value={orders_emails}
              onAdd={this.onChipAdd}
              onDelete={this.onChipDelete}
              errorStatus={validation.orders_emails?.status}
              errorText={validation.orders_emails?.message}
            />
          </Box>
        )}
        <Checkbox
          name='provides_sales_data'
          label='The warehouse provides sales data'
          onChange={this.onChange}
          checked={provides_sales_data}
        />
        {provides_sales_data && (
          <>
            <Box mb={validation.emails?.status ? 2 : 0}>
              <ChipInput
                name='emails'
                label='Import emails'
                value={emails}
                onAdd={this.onChipAdd}
                onDelete={this.onChipDelete}
                errorStatus={validation.emails?.status}
                errorText={validation.emails?.message}
              />
            </Box>
            <ChipInput
              name='domains'
              label='Import domains'
              value={domains}
              onAdd={this.onChipAdd}
              onDelete={this.onChipDelete}
              errorStatus={validation.domains?.status}
              errorText={validation.domains?.message}
            />
          </>
        )}
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link
              to={insertPathParams(ROUTE_WHOLESALERS_DETAILS, {
                id: wholesalerId
              })}
              className='router-button'
            >
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

UpdateWholesalersForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  wholesaler: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    country_id: PropTypes.number,
    city: PropTypes.string,
    street: PropTypes.string,
    postal_code: PropTypes.string,
    phone: PropTypes.string,
    tax_number: PropTypes.string,
    main_contact_email: PropTypes.string,
    orders_emails: PropTypes.arrayOf(PropTypes.string),
    orders_email_same_as_main: PropTypes.bool,
    emails: PropTypes.arrayOf(PropTypes.string),
    domains: PropTypes.arrayOf(PropTypes.string),
    provides_sales_data: PropTypes.bool.isRequired
  }).isRequired
};

export default withRouter(UpdateWholesalersForm);
