import PropTypes from 'prop-types';
import t from 'translate/translate';
import { TablePagination } from '@material-ui/core';

export const formatMetaToPagination = meta => {
  return {
    page: meta.current_page,
    per_page: meta.per_page,
    total: meta.total
  };
};

const Pagination = props => {
  const { pagination, setPagination, rowsPerPageOptions } = props;

  const onChangePage = (event, newPage) => {
    // component page indexing from 0
    setPagination({ page: newPage + 1 });
  };

  const onChangeRowsPerPage = event => {
    const rowsAmount = parseInt(event.target.value, 10);
    setPagination({ per_page: rowsAmount, page: 1 });
  };

  return (
    <TablePagination
      component='div'
      count={pagination.total}
      page={pagination.page - 1}
      onChangePage={onChangePage}
      rowsPerPage={pagination.per_page}
      rowsPerPageOptions={rowsPerPageOptions}
      onChangeRowsPerPage={onChangeRowsPerPage}
      labelRowsPerPage={t('Rows per page')}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} ${t('of')} ${count}`
      }
    />
  );
};

Pagination.defaultProps = {
  rowsPerPageOptions: [5, 15, 30, 100]
};

Pagination.propTypes = {
  pagination: PropTypes.shape({
    per_page: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number)
};

export default Pagination;
