import { useState, createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { Box, Typography } from '@material-ui/core';
import Button from 'modules/Layout/component/Button';
import t from 'translate/translate';
import DateTimePicker from 'modules/Layout/component/Date/DateTimePicker';
import Input from 'modules/Layout/component/Input';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import Checkbox from 'modules/Layout/component/Checkbox';

const WizardContext = createContext({
  currentPage: 1,
  changePage: () => {},
  pageIndexes: [],
  updatePageIndexes: () => {}
});

const isCheckboxDisabled = (visit, name = '') => {
  if (visit.new_scheduled_at) return false;
  if (!visit.postponement && name === 'description') return false;
  if (visit.postponement && name === 'date') return false;

  return true;
};

const Page = ({
  pageIndex,
  visit,
  onPlanningDateChange,
  onTextChange,
  onPostponementCheckboxChange
}) => {
  const { currentPage, updatePageIndexes } = useContext(WizardContext);

  useEffect(() => {
    updatePageIndexes(pageIndex);
  });

  return currentPage === pageIndex ? (
    <div>
      <Typography variant='h6'>{visit.client_name}</Typography>
      {visit.scheduled_at && (
        <Checkbox
          onChange={e => onPostponementCheckboxChange(e, visit.id)}
          name='Visit postponement'
          label='Visit postponement'
        />
      )}
      <DateTimePicker
        label='Scheduled at'
        onChange={e => onPlanningDateChange(e, visit.id)}
        name='scheduled at'
        value={visit.scheduled_at || visit.new_scheduled_at}
        variant=''
        minDate={new Date()}
        disabled={isCheckboxDisabled(visit, 'date')}
      />

      <Input
        name='place'
        label='Place'
        value={visit.place}
        onChange={e => onTextChange(e, visit.id)}
      />

      <Input
        name='description'
        label='Description'
        value={visit.description}
        onChange={e => onTextChange(e, visit.id)}
        disabled={isCheckboxDisabled(visit, 'description')}
      />

      {visit.scheduled_at && (
        <Input
          name='postponement_reason'
          label='Postponement reason'
          value={visit.postponement_reason}
          onChange={e => onTextChange(e, visit.id)}
          disabled={!visit.postponement}
        />
      )}
    </div>
  ) : null;
};

const Controls = ({ visits, onAddingVisitsSuccess }) => {
  const { changePage, currentPage, pageIndexes } = useContext(WizardContext);
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const handleResponse = () => {
    setAlert({ value: 'Success', variant: COLOR_VARIANTS_SUCCESS });
    onAddingVisitsSuccess();
  };

  const handleError = err => {
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      setAlert(message);
    } else if (err instanceof ApiError) {
      setAlert(message);
    }
  };

  const makeApiCall = async () => {
    setLoading(true);
    const newVisits = visits
      .filter(visit => visit.new_scheduled_at)
      .map(({ new_scheduled_at, place, description, client_id }) => ({
        new_scheduled_at,
        place,
        description,
        client_id
      }));
    const oldVisitsToUpdate = visits.filter(
      visit => visit.scheduled_at && !visit.postponement
    );
    const oldVisitsToPostpone = visits.filter(
      visit => visit.scheduled_at && visit.postponement
    );

    try {
      const res = await Promise.all([
        ...newVisits.map(visit =>
          VisitsApi.createVisit({
            ...visit,
            scheduled_at: visit.new_scheduled_at
          })
        ),
        ...oldVisitsToUpdate.map(visit =>
          VisitsApi.updateVisit(visit.id, visit)
        ),
        ...oldVisitsToPostpone.map(visit =>
          VisitsApi.postponeVisit(visit.id, visit)
        )
      ]);
      setLoading(false);
      handleResponse(res);
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  };

  const lastPage = Math.max(...pageIndexes);

  return (
    <>
      <Box display='flex' justifyContent='center'>
        <Pagination
          count={pageIndexes.length}
          page={currentPage}
          onChange={changePage}
        />
      </Box>
      {currentPage === lastPage && (
        <Button
          text={t('Save')}
          color='primary'
          onClick={makeApiCall}
          loading={loading}
        />
      )}
    </>
  );
};

const Wizard = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageIndexes, setPageIndexes] = useState([]);

  const updatePageIndexes = pageIndex => {
    if (pageIndexes.includes(pageIndex)) {
      return;
    }
    setPageIndexes([...pageIndexes, pageIndex]);
  };

  const changePage = (e, pageIndex) => {
    setCurrentPage(pageIndex);
  };

  return (
    <WizardContext.Provider
      value={{
        currentPage,
        changePage,
        pageIndexes,
        updatePageIndexes
      }}
    >
      <div>{children}</div>
    </WizardContext.Provider>
  );
};

Wizard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

Page.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  visit: PropTypes.shape({
    scheduled_at: PropTypes.string,
    new_scheduled_at: PropTypes.string,
    place: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    client_name: PropTypes.string,
    postponement: PropTypes.bool,
    postponement_reason: PropTypes.string
  }).isRequired,
  onPlanningDateChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onPostponementCheckboxChange: PropTypes.func.isRequired
};

Controls.propTypes = {
  onAddingVisitsSuccess: PropTypes.func.isRequired,
  visits: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export { Page, Controls, Wizard };
