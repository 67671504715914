import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import MuiPickersProvider from 'modules/Layout/component/Date/MuiPickersProvider';
import t from 'translate/translate';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

export const API_DATE_FORMAT = 'yyyy';

const parseDateString = dateStr => {
  return parse(dateStr, API_DATE_FORMAT, new Date());
};

const YearPicker = props => {
  const {
    name,
    label,
    value,
    initValueNow,
    onChange,
    errorStatus,
    errorText
  } = props;

  const [selectedDate, handleDateChange] = useState(() => {
    if (initValueNow) return new Date();
    if (value) return parseDateString(value);
    return null;
  });

  const OnDateChange = date => {
    handleDateChange(date);
    const formattedDate = format(date, API_DATE_FORMAT);
    onChange({
      target: {
        name,
        value: formattedDate
      }
    });
  };

  useEffect(() => {
    const currentValue = value ? parseDateString(value) : null;
    handleDateChange(currentValue);
  }, [value]);

  return (
    <MuiPickersProvider>
      <MuiDatePicker
        margin='normal'
        inputVariant='outlined'
        variant='inline'
        views={['year']}
        autoOk
        fullWidth
        invalidDateMessage={t(
          `Wrong Date format, correct one is ${API_DATE_FORMAT}`
        )}
        label={t(label)}
        value={selectedDate}
        onChange={OnDateChange}
        error={errorStatus}
        helperText={errorStatus && errorText}
      />
    </MuiPickersProvider>
  );
};

YearPicker.defaultProps = {
  value: null,
  initValueNow: false,
  errorStatus: false,
  errorText: ''
};

YearPicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  initValueNow: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errorStatus: PropTypes.bool,
  errorText: PropTypes.string
};

export default YearPicker;
