import { Route } from 'react-router-dom';
import Affiliation from 'modules/Affiliation/view';

export const ROUTE_AFFILIATION = '/affiliation';

const routes = {
  protected: [
    <Route
      key='affiliation'
      exact
      path={ROUTE_AFFILIATION}
      redirect
      component={Affiliation}
    />
  ]
};

export default routes;
