import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { DEPARTMENT_MANAGER, KEY_ACCOUNT_MANAGER } from 'api/auth/roles';
import { clientBusinessStatusesArr } from 'modules/Clients/utils/clientBusinessStatuses';

const activityOptions = [
  { value: false, status: 'inactive' },
  { value: true, status: 'active' }
];

class LongAgoVisitedClientsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('my_clients'),
            label: 'My clients',
            type: 'checkbox',
            displayStatus: this.context.hasRole([
              DEPARTMENT_MANAGER,
              KEY_ACCOUNT_MANAGER
            ])
          },
          {
            ...this.getBasicInputValues('show_for_department_id'),
            label: 'Department',
            type: 'autocomplete',
            displayStatus: this.props.departments.length > 1,
            options: formatOptions(this.props.departments, 'id', 'name'),
            loading: this.props.loadingDepartments
          }
        ]
      },
      {
        groupLabel: 'Organizational data',
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('recipient_number'),
            label: 'Recipient number',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('tax_number'),
            label: 'Tax number',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('sales_group_name'),
            label: 'Shopping group name',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: 'Address',
        children: [
          {
            ...this.getBasicInputValues('city'),
            label: 'City',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: "Client's evaluation",
        children: [
          {
            ...this.getBasicInputValues('potential_evaluation'),
            label: 'Client category',
            type: 'select',
            emptyValueText: "Client's evaluation",
            options: ['A', 'B', 'C']
          }
        ]
      },
      {
        groupLabel: 'Client business status',
        children: [
          {
            ...this.getBasicInputValues('status'),
            label: 'Client business status',
            type: 'select',
            emptyValueText: 'All',
            options: formatOptions(
              clientBusinessStatusesArr,
              'value',
              'status',
              true
            )
          },
          {
            ...this.getBasicInputValues('active'),
            label: 'Activity in the department',
            type: 'select',
            emptyValueText: 'All',
            options: formatOptions(activityOptions, 'value', 'status', true)
          }
        ]
      },
      {
        groupLabel: 'Discount',
        children: [
          {
            ...this.getBasicInputValues('has_discounts'),
            label: 'With a discount',
            type: 'checkbox'
          },
          {
            ...this.getBasicInputValues('has_no_discounts'),
            label: 'Without a discount',
            type: 'checkbox'
          }
        ]
      }
    ];
  }
}

export default LongAgoVisitedClientsFilter;
