import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import { ROUTE_TARGETS_BONUSES_LIST } from 'routing/routes/TargetsBonuses';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import TargetsBonusesApi from 'api/connections/TargetsBonuses/TargetsBonusesApi';
import TargetBonusesDetailsDataSets from 'modules/TargetsBonuses/component/Details/DataSets';
import TargetBonusesTargetDataSets from 'modules/TargetsBonuses/component/Details/TargetDataSets';

class TargetBonusesDetails extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.bonusId = parseInt(props.match.params.id, 10);

    this.state = {
      bonus: null
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    if (!this.bonusId) {
      this.props.history.push(ROUTE_TARGETS_BONUSES_LIST);
    }
    this.setCurrentPage('Bonus details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_TARGETS_BONUSES_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: bonus }
      } = await TargetsBonusesApi.getTargetBonus(this.bonusId);

      this.setState({ bonus });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { bonus } = this.state;

    if (!bonus) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <TargetBonusesDetailsDataSets
              bonus={bonus}
              fetchBonusData={this.fetchData}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <TargetBonusesTargetDataSets bonus={bonus} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TargetBonusesDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(TargetBonusesDetails);
