import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';
import ClientFileCreateForm from 'modules/Clients/component/Details/Files/Create/Form';
import { withRouter } from 'react-router-dom';
import CurrentPageContext from 'modules/Shared/context/CurrentPage/currentPageContext';
import { ROUTE_CLIENTS_LIST } from 'routing/routes/Clients';

class ClientContactCreate extends Component {
  static contextType = CurrentPageContext;

  constructor(props) {
    super(props);
    this.clientId = parseInt(this.props.match.params.id, 10);
  }

  async componentDidMount() {
    if (!this.clientId) {
      this.props.history.push(ROUTE_CLIENTS_LIST);
    }
    this.context.setCurrentPage('Create client file');
  }

  render() {
    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ClientFileCreateForm clientId={this.clientId} />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ClientContactCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ClientContactCreate);
