import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import IconButton from 'modules/Layout/component/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import insertPathParams from 'api/utils/insertPathParams';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_CLIENTS_CONTRACTS_DETAILS } from 'routing/routes/ClientsContracts';
import clientContractStatuses from 'modules/ClientsContracts/clientContractStatuses';
import ClientsContractsDetailsThresholds from 'modules/ClientsContracts/component/Details/Thresholds';

class ClientsBonusesDetailsContract extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.onContractDetailsCLick = this.onContractDetailsCLick.bind(this);
  }

  onContractDetailsCLick() {
    const { id } = this.props.contract;

    this.props.history.push(
      insertPathParams(ROUTE_CLIENTS_CONTRACTS_DETAILS, { id })
    );
  }

  render() {
    const { name, status, bonuses } = this.props.contract;

    return (
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Typography
            component='h6'
            variant='h6'
            style={{ marginBottom: '.5rem' }}
          >
            {t('Contract')}
          </Typography>
          <TextDataSet label='Name' data={name} />
          <TextDataSet
            label='Status'
            data={t(clientContractStatuses[status])}
          />
          <ClientsContractsDetailsThresholds bonuses={bonuses} />
        </Box>
        <Box display='flex' alignItems='flex-start'>
          <IconButton
            className='details-icon'
            onClick={this.onContractDetailsCLick}
            icon={<SearchIcon />}
            alt='details'
          />
        </Box>
      </Box>
    );
  }
}

ClientsBonusesDetailsContract.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    bonuses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ClientsBonusesDetailsContract);
