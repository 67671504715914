import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import {
  roles,
  DEPARTMENT_MANAGER,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/DatePicker';
import addYears from 'date-fns/addYears';
import parse from 'date-fns/parse';

class StatisticsGratisProductsReportFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: 'Dates',
        children: [
          {
            ...this.getBasicInputValues('date_from'),
            label: 'Date from',
            type: 'date',
            maxDate: this.props.filter.date_to
              ? parse(this.props.filter.date_to, API_DATE_FORMAT, new Date())
              : addYears(new Date(), 20)
          },
          {
            ...this.getBasicInputValues('date_to'),
            label: 'Date to',
            type: 'date',
            minDate: this.props.filter.date_from
              ? parse(this.props.filter.date_from, API_DATE_FORMAT, new Date())
              : addYears(new Date(), -20)
          }
        ]
      },
      {
        groupLabel: 'Department and user',
        children: [
          {
            ...this.getBasicInputValues('department_id'),
            label: 'Departments',
            type: 'autocomplete',
            options: formatOptions(this.props.departments, 'id', 'name'),
            loading: this.props.loadingDepartments
          },
          {
            ...this.getBasicInputValues('role'),
            label: 'Role',
            type: 'select',
            options: formatOptions(
              roles.filter(role =>
                [DEPARTMENT_MANAGER, TELEMARKETER, TRADER].includes(role.slug)
              ),
              'slug',
              'name',
              true
            )
          },
          {
            ...this.getBasicInputValues('user_id'),
            label: 'User',
            type: 'autocomplete',
            options: formatOptions(this.props.users, 'id', 'full_name'),
            loading: this.props.loadingUsers,
            disabled:
              !this.props.filter.department_id || !this.props.filter.role,
            tooltipMsg: 'Pick department and role first'
          }
        ]
      },
      {
        groupLabel: 'Products',
        children: [
          {
            ...this.getBasicInputValues('products'),
            value: this.props.filter.products,
            label: 'Products',
            type: 'multiAutocomplete',
            options: formatOptions(this.props.products, 'id', 'sku'),
            emptyValueText: 'Products',
            loading: this.props.loadingProducts
          }
        ]
      }
    ];
  }
}

export default StatisticsGratisProductsReportFilter;
