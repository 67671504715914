import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';

class ProductsTopSellersFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('category_id'),
            label: 'Category',
            type: 'autocomplete',
            options: formatOptions(this.props.categories, 'id', 'name')
          }
        ]
      }
    ];
  }
}

export default ProductsTopSellersFilter;
