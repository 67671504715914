import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import Input from 'modules/Layout/component/Input';
import { Box } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import WholesalersMappingsApi from 'api/connections/Wholesalers/WholesalersMappingsApi';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { ROUTE_CLIENTS_DETAILS } from 'routing/routes/Clients';

class WholesalerClientsMappingUpdateForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        client_number: props.mapping.client_number
      },
      validation: {
        client_number: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Client number')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Mapping saved'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.redirect();
  }

  handleError(err) {
    const { setAlert } = this.context;
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      this.setState(({ validation: validationState }) => {
        return {
          validation: { ...validationState, ...newValidateState }
        };
      });
    }
    setAlert(message);
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(prevState => {
      const { formData, validation } = prevState;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  redirect() {
    const { client_id } = this.props.mapping;

    let path = insertPathParams(ROUTE_CLIENTS_DETAILS, {
      id: client_id
    });

    path += '?tab_index=wholesalers_mappings';

    this.props.history.push(path);
  }

  async makeApiCall() {
    const { client_number } = this.state.formData;

    const payload = {
      ...this.props.mapping,
      client_number
    };

    const { id: mappingId, wholesaler_id: wholesalerId } = this.props.mapping;

    try {
      this.setState({ loading: true });
      await WholesalersMappingsApi.updateWholesalerClientMapping(
        { wholesalerId, mappingId },
        payload
      );
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { client_number },
      validation,
      loading
    } = this.state;
    const { wholesaler_name, client_name } = this.props.mapping;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <TextDataSet label='Wholesaler' data={wholesaler_name} />
        <TextDataSet label='Client' data={client_name} />
        <Input
          name='client_number'
          label='Client number'
          required
          value={client_number}
          onChange={this.onChange}
          errorStatus={validation.client_number.status}
          errorText={validation.client_number.message}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button text={t('Cancel')} onClick={this.redirect} />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

WholesalerClientsMappingUpdateForm.propTypes = {
  mapping: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(WholesalerClientsMappingUpdateForm);
