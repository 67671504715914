import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import Accordion from 'modules/Layout/component/Accordion';
import Pagination from 'modules/Layout/component/List/Pagination';
import Input from 'modules/Layout/component/Input';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_PRICE_LISTS_DETAILS } from 'routing/routes/PriceLists';
import PriceListsApi from 'api/connections/PriceLists/PriceListsApi';
import t from 'translate/translate';
import _ from 'lodash';
import { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import { isArray } from 'lodash/lang';
import IconButton from 'modules/Layout/component/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import getDefaultPriceList from 'modules/PriceLists/utils/getDefaultPriceList';
import { PRICE_LISTS_MANAGEMENT } from 'api/auth/permissions/PriceLists';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ADMIN, SUPER_ADMIN } from 'api/auth/roles';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';

class ClientsDetailsPriceLists extends Component {
  static contextType = AuthContext;

  static renderName(name, { id }) {
    return (
      <Link
        to={insertPathParams(ROUTE_PRICE_LISTS_DETAILS, {
          id
        })}
        className='router-link router-link-underline'
        target='_blank'
      >
        {name}
      </Link>
    );
  }

  constructor(props, context) {
    super(props, context);

    const departmentsToRender = this.getDepartmentsToRender(
      props.client,
      context
    );

    this.state = {
      priceLists: [],
      name: '',
      department_id:
        departmentsToRender.length === 1 ? departmentsToRender[0].id : '',
      pagination: {
        per_page: 5,
        page: 1,
        total: 0
      },
      loading: true
    };

    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.setDefaultPriceList = this.setDefaultPriceList.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderAction = this.renderAction.bind(this);
  }

  componentDidMount() {
    this.fetchPriceLists();
  }

  onDetailsClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_PRICE_LISTS_DETAILS, { id })
    );
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => {
        if (name === 'department_id') this.fetchPriceLists();
      }
    );
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onChange(event);
  }

  getCols() {
    const cols = [
      {
        property: 'name',
        label: 'Name',
        sortable: false,
        render: ClientsDetailsPriceLists.renderName
      }
    ];

    if (this.context.hasPermission([PRICE_LISTS_MANAGEMENT])) {
      cols.push({
        property: 'id',
        label: 'Actions',
        sortable: false,
        render: this.renderAction
      });
    }

    return cols;
  }

  setPagination(pagination) {
    this.setState({
      pagination: { ...this.state.pagination, ...pagination }
    });
  }

  getDataToRender() {
    let { priceLists } = this.state;
    const {
      name,
      pagination: { per_page, page }
    } = this.state;

    if (name) {
      priceLists = priceLists.filter(item =>
        item.name.toLowerCase().includes(name.toLowerCase())
      );
    }

    const paginationFrom = (page - 1) * per_page;
    const paginationTo = paginationFrom + per_page;

    return {
      data: priceLists.slice(paginationFrom, paginationTo),
      total: priceLists.length
    };
  }

  getDepartmentsToRender(propClient = undefined, propContext = undefined) {
    const context = propContext ?? this.context;
    const client = propClient ?? this.props.client;

    const { users } = client;
    const userDepartments = context.user.departments;

    const clientDepartments = _.uniqBy(
      users.map(user => ({
        id: user.department_id,
        name: user.department_name
      })),
      'id'
    );

    if (this.context.hasRole([SUPER_ADMIN, ADMIN])) {
      return clientDepartments;
    }

    return userDepartments.filter(({ id: userDepartmentId }) =>
      clientDepartments.map(({ id }) => id).includes(userDepartmentId)
    );
  }

  async setDefaultPriceList(price_list_id) {
    const { department_id, priceLists } = this.state;
    const { id: client_id } = this.props.client;

    const payload = {
      client_id,
      department_id,
      price_list_id
    };

    const defaultPriceList = getDefaultPriceList(priceLists);

    try {
      defaultPriceList?.id === price_list_id
        ? await PriceListsApi.unsetDefaultPriceList(payload)
        : await PriceListsApi.setDefaultPriceList(payload);

      this.fetchPriceLists();
    } catch (err) {
      this.props.handleError(err);
    }
  }

  async fetchPriceLists() {
    const { department_id } = this.state;

    try {
      const {
        data: { data: priceLists }
      } = await PriceListsApi.getPricesLists({
        for_client_id: this.props.client.id,
        for_department_id: department_id,
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        priceLists,
        loading: false
      });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  renderAction(id, row) {
    const { department_id } = this.state;

    return (
      <Box display='flex' justifyContent='center'>
        <IconButton
          onClick={() => this.setDefaultPriceList(id)}
          icon={
            row.is_default ? (
              <StarIcon
                color={department_id ? 'primary' : 'disabled'}
                fontSize='large'
              />
            ) : (
              <StarBorderIcon
                color={department_id ? 'primary' : 'disabled'}
                fontSize='large'
              />
            )
          }
          alt={row.is_default ? 'remove default' : 'set as default'}
          disabled={!department_id}
          tooltipMsg='Select department first'
        />
      </Box>
    );
  }

  render() {
    const { priceLists, name, pagination, loading, department_id } = this.state;

    const { data, total } = this.getDataToRender();

    const departmentsToRender = this.getDepartmentsToRender();

    const defaultPriceList = getDefaultPriceList(priceLists);

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} md={10}>
          <Paper className='p1'>
            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem' }}
            >
              {t('Default price list')}
            </Typography>
            <Box display='flex' flexDirection='column' width={1}>
              <Box width={{ xs: 1, md: 1 / 2 }}>
                {departmentsToRender.length === 1 ? (
                  <TextDataSet
                    label='Department'
                    data={departmentsToRender[0].name}
                  />
                ) : (
                  <Autocomplete
                    name='department_id'
                    label='Department'
                    value={department_id}
                    options={formatOptions(departmentsToRender, 'id', 'name')}
                    onChange={(id, v) =>
                      this.onAutocompleteChange('department_id', v)
                    }
                    fullWidth={false}
                  />
                )}
              </Box>
              {defaultPriceList && (
                <Box
                  className='language-form-box'
                  width={{ xs: 1, md: 1 / 2 }}
                  mt={1}
                  style={{ paddingTop: '.5rem' }}
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography>{defaultPriceList?.name}</Typography>
                  {this.context.hasPermission([PRICE_LISTS_MANAGEMENT]) && (
                    <IconButton
                      onClick={() =>
                        this.setDefaultPriceList(defaultPriceList.id)
                      }
                      icon={<StarIcon color='primary' fontSize='large' />}
                      alt='remove default'
                    />
                  )}
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={10}>
          <Paper className='p1'>
            <Accordion label={t('Filter')}>
              <Input
                onChange={this.onChange}
                label='Name'
                name='name'
                value={name}
                fullWidth={false}
              />
            </Accordion>
            <Table cols={this.getCols()} rows={data} loading={loading} dense />
            <Pagination
              setPagination={this.setPagination}
              pagination={{ ...pagination, total }}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ClientsDetailsPriceLists.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
  }).isRequired,
  handleError: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ClientsDetailsPriceLists);
