import { Component } from 'react';
import PropTypes from 'prop-types';
import SalesTable from 'modules/Sales/component/List/Table';
import SalesFilter from 'modules/Sales/component/List/Filter';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import SalesApi from 'api/connections/Sales/SalesApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';

class SalesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        product_name: '',
        wholesaler_name: '',
        wholesaler_product_name: '',
        wholesaler_product_code: '',
        wholesaler_client_name: '',
        client_name: '',
        buy_date: null,
        buy_date_from: null,
        buy_date_to: null,
        quantity: '',
        quantity_from: '',
        quantity_to: '',
        price: '',
        price_from: '',
        price_to: ''
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      sales: [],
      loading: true
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Shopping data set');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchSales()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchSales()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchSales()
    );
  }

  async fetchSales() {
    try {
      const {
        data: { data: sales, meta }
      } = await SalesApi.getSales({
        ...this.state.sort,
        ...this.state.filter,
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page
      });
      this.setState({
        sales,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: sales, meta }
      } = await SalesApi.getSales({
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page
      });

      this.setState({
        sales,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { filter, sort, sales, pagination, loading } = this.state;
    return (
      <div>
        <SalesFilter
          filter={filter}
          validation={this.state.filterValidation}
          setFilter={this.setFilter}
        />
        <SalesTable
          sort={sort}
          setSort={this.setSort}
          sales={sales}
          loading={loading}
        />
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </div>
    );
  }
}

SalesList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default SalesList;
