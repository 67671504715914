import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
  List
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import t from 'translate/translate';
import Authorize from 'modules/Auth/component/Authorize';
import useStyles from 'modules/Layout/component/Navigator/style';
import NavigatorListItem from 'modules/Layout/component/Navigator/ListItem';
import { isEmpty } from 'lodash/lang';

const NavigatorNestedListItem = ({ item, openListItemKey, onClick }) => {
  const classes = useStyles();
  const { pathname: locationPath } = useLocation();

  const isActive = item.children.some(obj => obj.path === locationPath);

  const renderItem = () => {
    return (
      <Box>
        <ListItem
          button
          onClick={() => onClick(item.key)}
          className={clsx(classes.item, isActive && classes.itemActiveItem)}
        >
          <ListItemIcon className={classes.itemIcon}>{item.icon}</ListItemIcon>
          <ListItemText primary={t(item.label)} color='primary' />
          {openListItemKey === item.key ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={openListItemKey === item.key}
          timeout='auto'
          unmountOnExit
        >
          <List component='div' disablePadding>
            {item.children.map(childItem => {
              return (
                <NavigatorListItem
                  key={childItem.key}
                  item={childItem}
                  nested
                />
              );
            })}
          </List>
        </Collapse>
      </Box>
    );
  };

  if (isEmpty(item.anyPermissions) && isEmpty(item.roles)) {
    return renderItem();
  }

  return (
    <Authorize anyPermissions={item.anyPermissions} roles={item.roles}>
      {renderItem()}
    </Authorize>
  );
};

NavigatorNestedListItem.defaultProps = {
  openListItemKey: null
};

NavigatorNestedListItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
    anyPermissions: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  openListItemKey: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default NavigatorNestedListItem;
