import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { quarters, months, periodTypes } from 'modules/Shared/dateConsts';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { DIRECTOR, DEPARTMENT_MANAGER } from 'api/auth/roles';

const setFilterTypes = role => {
  if ([DIRECTOR].includes(role)) {
    return [
      { slug: 'department', label: 'Department' },
      { slug: 'all', label: 'All' },
      { slug: 'user', label: 'User' },
      { slug: 'own', label: 'Own' }
    ];
  }

  if ([DEPARTMENT_MANAGER].includes(role)) {
    return [
      { slug: 'all', label: 'All' },
      { slug: 'user', label: 'User' },
      { slug: 'own', label: 'Own' }
    ];
  }

  return [{ slug: 'all', label: 'All' }];
};

const filterPriorities = [
  { slug: 1, label: 'High' },
  { slug: 2, label: 'Medium' },
  { slug: 3, label: 'Low' }
];

const setPeriodItemsType = type => {
  switch (type) {
    case 'month':
      return ['Month', months];
    case 'quarter':
      return ['Quarter', quarters];
    default:
      return ['', []];
  }
};

class StatisticsPriorityProductsSalesFilter extends Filter {
  getInputs() {
    const [label, data] = setPeriodItemsType(this.props.filter.period);

    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'autocomplete',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasPermission([
              POSTAL_CODES_COUNTRIES_SHOW
            ])
          },
          {
            ...this.getBasicInputValues('type'),
            label: 'Type',
            type: 'select',
            options: formatOptions(
              setFilterTypes(this.context.user.role),
              'slug',
              'label',
              true
            )
          },
          {
            ...this.getBasicInputValues('user_id'),
            label: 'User',
            type: 'autocomplete',
            options: formatOptions(
              this.props.users.filter(
                user => user.country_id === this.props.filter.country_id
              ),
              'id',
              'name',
              true
            ),
            disabled:
              !this.props.filter.country_id ||
              this.props.filter.type !== 'user',
            displayStatus: this.context.hasRole([DIRECTOR, DEPARTMENT_MANAGER])
          },
          {
            ...this.getBasicInputValues('priority'),
            label: 'Priority',
            type: 'select',
            options: formatOptions(filterPriorities, 'slug', 'label', true)
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('year'),
            label: 'Year',
            type: 'year'
          },
          {
            ...this.getBasicInputValues('period'),
            label: 'Period',
            type: 'select',
            options: formatOptions(periodTypes, 'slug', 'name', true)
          },
          {
            ...this.getBasicInputValues(this.props.filter.period),
            label,
            type: 'select',
            options: formatOptions(data, 'id', 'name', true),
            displayStatus: this.props.filter.period !== 'year'
          }
        ]
      }
    ];
  }
}

export default StatisticsPriorityProductsSalesFilter;
