import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#59e2cb',
      main: '#00af9a',
      dark: '#007f6c',
      contrastText: '#007f6c'
    },
    secondary: {
      light: '#00af9a',
      main: '#00af9a',
      dark: '#00af9a'
    }
  },
  getContrastText: true,
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5
    }
  },
  shape: {
    borderRadius: 8
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    toolbar: {
      minHeight: 48
    }
  }
});

const customTheme = createMuiTheme({
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c'
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none',
        color: theme.palette.common.white
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1)
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white
      },
      flexContainer: {
        display: 'flex',
        flexWrap: 'wrap'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        marginRight: theme.spacing(2),
        marginTop: '5px',
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        lineHeight: 'unset',
        minHeight: 'unset',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: theme.spacing(1),
        '&$selected': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1)
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#eaeff1'
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '0.8rem',
        marginBottom: '0.3rem'
      }
    },
    MuiTextField: {
      root: {
        color: theme.palette.primary.main,
        '&:hover,&:focus': {
          color: theme.palette.primary.main
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 16px)'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: theme.spacing(1.6, 2)
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: theme.palette.common.white
      },
      toolbarBtnSelected: {
        color: theme.palette.common.white
      }
    },
    MuiPickersDay: {
      daySelected: {
        color: theme.palette.common.white
      }
    },
    MuiDialogTitle: {
      root: {
        padding: theme.spacing(1.2),
        borderBottom: '1px solid',
        borderBottomColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    MuiDialogContent: {
      root: {
        padding: theme.spacing(1.2)
      }
    },
    MuiDialogContentText: {
      root: {
        margin: 0,
        marginBottom: 0
      }
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(1.2),
        borderTop: '1px solid',
        borderTopColor: 'rgba(0, 0, 0, 0.08)',
        '& .MuiButton-root': {
          margin: 0
        }
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '0!important'
      },
      input: {
        padding: '12.8px 16px !important'
      }
    },
    MuiLink: {
      root: {
        cursor: 'pointer'
      }
    }
  }
});

export default customTheme;
