import { Component } from 'react';
import PropTypes from 'prop-types';
import insertPathParams from 'api/utils/insertPathParams';
import { Box, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import Loader from 'modules/Layout/component/Loader';
import DashboardListItem from 'modules/Dashboard/component/Layout/ListItem';
import DashboardList from 'modules/Dashboard/component/Layout/List';
import { ROUTE_VISITS_DETAILS } from 'routing/routes/Visits';
import VisitsApi from 'api/connections/Visits/VisitsApi';

class DashboardVisits extends Component {
  static getInformationBox(visit) {
    const { scheduled_at, client_name, place } = visit;

    return (
      <Box display='flex' flexDirection='column'>
        <Typography style={{ fontWeight: '600' }}>{client_name}</Typography>
        <Typography variant='body1'>
          {scheduled_at} - {place}
        </Typography>
      </Box>
    );
  }

  static getRedirectLink({ id }) {
    return insertPathParams(ROUTE_VISITS_DETAILS, { id });
  }

  static renderItem(visit) {
    const informationBox = DashboardVisits.getInformationBox(visit);
    const redirectLink = DashboardVisits.getRedirectLink(visit);

    return (
      <DashboardListItem
        key={visit.id}
        informationBox={informationBox}
        redirectLink={redirectLink}
      />
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      visits: undefined,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const today = format(new Date(), 'dd.MM.yyyy');

    const pathParams = {
      my_visits: true,
      date_from: today,
      date_to: today
    };

    try {
      const {
        data: { data: visits }
      } = await VisitsApi.getVisits(pathParams);

      this.setState({ visits, loading: false });
    } catch (err) {
      this.setState({ visits: undefined, loading: false });
      this.props.handleError(err);
    }
  }

  render() {
    const { loading, visits } = this.state;

    if (loading) return <Loader />;

    return (
      <DashboardList data={visits} renderItem={DashboardVisits.renderItem} />
    );
  }
}

DashboardVisits.defaultProps = {
  handleError: null
};

DashboardVisits.propTypes = {
  handleError: PropTypes.func
};

export default DashboardVisits;
