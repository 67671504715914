import routerCreator from 'routing/routerCreator';
import registerInterceptors from 'api/registerInterceptors';
import { ThemeProvider } from '@material-ui/core/styles';

import AuthState from 'modules/Auth/context/Auth/AuthState';
import CurrentPageState from 'modules/Shared/context/CurrentPage/CurrentPageState';
import AlertState from 'modules/Shared/context/Alert/AlertState';
import ProductCategoriesProvider from 'modules/ProductCategories/context/ProductCategoriesProvider';
import PostalCodesProvider from 'modules/PostalCodes/context/PostalCodesProvider';
import theme from 'modules/Layout/theme';

registerInterceptors();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthState>
        <CurrentPageState>
          <AlertState>
            <ProductCategoriesProvider>
              <PostalCodesProvider>{routerCreator()}</PostalCodesProvider>
            </ProductCategoriesProvider>
          </AlertState>
        </CurrentPageState>
      </AuthState>
    </ThemeProvider>
  );
};

export default App;
