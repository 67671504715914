export const months = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' }
];

export const periodTypes = [
  { slug: 'year', name: 'Year' },
  { slug: 'quarter', name: 'Quarter' },
  { slug: 'month', name: 'Month' }
];

export const quarters = [
  { id: 1, name: 'I' },
  { id: 2, name: 'II' },
  { id: 3, name: 'III' },
  { id: 4, name: 'IV' }
];
