import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PostalCodesBox from 'modules/PostalCodes/component/shared/PostalCodesBox';
import { ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import IconButton from 'modules/Layout/component/IconButton';
import Dialog from 'modules/Layout/component/Dialog';
import { useStyles } from 'modules/PostalCodes/component/shared/style';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import ApiError from 'api/exceptions/ValidationApiError';
import { PostalCodesContext } from 'modules/PostalCodes/context/PostalCodesProvider';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import t from 'translate/translate';
import {
  REGION,
  SUBREGION,
  SUBSUBREGION,
  CITY
} from 'modules/PostalCodes/constants';
import EditIcon from '@material-ui/icons/Edit';
import {
  ROUTE_POSTAL_CODES_CREATE,
  ROUTE_POSTAL_CODES_UPDATE
} from 'routing/routes/PostalCodes';

const CitiesList = () => {
  const classes = useStyles();

  const {
    selectedItem,
    handleListItemClick,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    dialogData,
    countryDepth,
    handleChosenItem
  } = useContext(PostalCodesContext);
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCities = async params => {
    try {
      setIsLoading(true);
      const {
        data: { data: citiesData }
      } = await PostalCodesApi.getCities({
        ...params,
        per_page: Number.MAX_SAFE_INTEGER
      });
      setCities(citiesData);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const removeItem = async () => {
    try {
      const { id } = dialogData;
      await PostalCodesApi.deleteCity(id);
      setCities(prevState => prevState.filter(city => city.id !== id));
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const filters = {};
  let triggeringItemId = null;
  let parentType = null;
  // eslint-disable-next-line default-case
  switch (countryDepth) {
    case 2:
      filters.subsubregion_id = selectedItem[SUBSUBREGION];
      triggeringItemId = selectedItem[SUBSUBREGION];
      parentType = 'subsubregion';
    // eslint-disable-next-line no-fallthrough
    case 1:
      filters.subregion_id = selectedItem[SUBREGION];
      if (countryDepth === 1) {
        parentType = 'subregion';
        triggeringItemId = selectedItem[SUBREGION];
      }
    // eslint-disable-next-line no-fallthrough
    case 0:
      filters.region_id = selectedItem[REGION];
      if (countryDepth === 0) {
        parentType = 'region';
        triggeringItemId = selectedItem[REGION];
      }
  }

  const addCity = () => {
    handleChosenItem({ type: 'city' });
    history.push(ROUTE_POSTAL_CODES_CREATE);
  };

  const updateCity = (e, id, name) => {
    e.stopPropagation();
    handleChosenItem({ type: 'city', name, id, parentType });
    history.push(ROUTE_POSTAL_CODES_UPDATE);
  };

  useEffect(() => {
    if (triggeringItemId) {
      fetchCities(filters);
    }
  }, [triggeringItemId]);

  return (
    triggeringItemId &&
    (isLoading ? (
      <CircularProgress style={{ marginLeft: 30 }} />
    ) : (
      <>
        <PostalCodesBox title='City' data={cities} addFn={addCity}>
          {cities.map(({ name, id, postal_codes_count }) => (
            <ListItem
              onClick={() => {
                handleListItemClick({ id, type: CITY });
              }}
              className={classes.listItem}
              selected={selectedItem[CITY] === id}
              key={`${name}${id}`}
            >
              <ListItemText
                style={{ cursor: 'default', marginRight: 20 }}
                primary={name}
              />
              <IconButton
                className='update-icon'
                onClick={e => updateCity(e, id, name)}
                icon={<EditIcon fontSize='small' />}
                alt='update'
              />
              <IconButton
                className='delete-icon'
                onClick={e => handleOpenDialog(id, name, e, CITY)}
                icon={<DeleteForeverIcon />}
                tooltipMsg='Delete all the postal codes of this city, first.'
                disabled={postal_codes_count > 0}
                alt='delete'
              />
            </ListItem>
          ))}
        </PostalCodesBox>
        <Dialog
          open={openDialog[CITY]}
          title={t('Deletion confirmation')}
          description={`${t('Are you sure you want to delete city: ')}${
            dialogData.name
          }?`}
          onApprove={async () => {
            await removeItem();
            handleCloseDialog();
          }}
          onCancel={handleCloseDialog}
        />
      </>
    ))
  );
};

export default CitiesList;
