import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import t from 'translate/translate';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { validate } from 'modules/Shared/utils/validator';
import { Box, Typography } from '@material-ui/core';
import Button from 'modules/Layout/component/Button';
import FileUploadButton from 'modules/Layout/component/FileUploadButton';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import SalesImportsApi from 'api/connections/SalesImports/SalesImportsApi';
import { ROUTE_SALES_IMPORTS_LIST } from 'routing/routes/SalesImports';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';

const validExtensions = ['csv', 'ods', 'xls', 'xlsx'];

class SalesImportCreateForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    this.setAlert = props.setAlert;

    this.state = {
      formData: {
        file: '',
        country_id: context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])
          ? ''
          : context.user.country_id,
        wholesaler_id: null
      },
      extension: null,
      validation: {
        file: {
          status: false,
          message: t('File is required')
        },
        fileExtension: {
          status: false,
          message: t('Wrong file type, valid types are: csv, ods, xls, xlsx')
        },
        country_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Country')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.redirectToSalesImportList = this.redirectToSalesImportList.bind(this);
  }

  handleResponse() {
    this.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.redirectToSalesImportList();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onAutocompleteChange(value) {
    this.setState(state => {
      return {
        ...state,
        formData: {
          ...state.formData,
          wholesaler_id: value ? value.key : null
        }
      };
    });
  }

  onUpload(file, fileNameAndExtension = {}) {
    const { fileExtension } = fileNameAndExtension;

    this.setState(prevState => {
      const { formData, validation } = prevState;
      return {
        formData: {
          ...formData,
          file: file || ''
        },
        fileExtension: fileExtension || '',
        validation: {
          ...validation,
          file: {
            ...validation.file,
            status: false
          },
          fileExtension: {
            ...validation.fileExtension,
            status: false
          }
        }
      };
    });
  }

  redirectToSalesImportList() {
    this.props.history.push(ROUTE_SALES_IMPORTS_LIST);
  }

  runValidation() {
    /* eslint prefer-const: "off" */
    const { formData, fileExtension, validation } = this.state;
    let [isValid, newValidateState] = validate(formData, validation);

    if (!formData.file) {
      newValidateState.file.status = true;
      isValid = false;
    }

    if (formData.file && !validExtensions.includes(fileExtension)) {
      newValidateState.fileExtension.status = true;
      isValid = false;
    }

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { file, country_id, wholesaler_id } = this.state.formData;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('country_id', country_id);
    formData.append('wholesaler_id', wholesaler_id);

    this.setState({ loading: true });
    try {
      await SalesImportsApi.createSalesImport(formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { country_id, wholesaler_id },
      validation,
      loading
    } = this.state;
    const { countries, wholesalers } = this.props;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <FileUploadButton name='file' onUpload={this.onUpload} />
        {validation.file.status && (
          <Typography
            color='error'
            variant='caption'
            style={{ marginLeft: '.8rem', marginTop: '.7rem' }}
          >
            {t(validation.file.message)}
          </Typography>
        )}
        {validation.fileExtension.status && (
          <Typography
            color='error'
            variant='caption'
            style={{ marginLeft: '.8rem', marginTop: '.7rem' }}
          >
            {t(validation.fileExtension.message)}
          </Typography>
        )}

        {this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
          <Select
            name='country_id'
            label='Country'
            value={country_id}
            required
            options={formatOptions(countries, 'id', 'name')}
            onChange={this.onChange}
            errorStatus={validation.country_id.status}
            errorText={validation.country_id.message}
          />
        )}
        <Autocomplete
          name='wholesaler_id'
          label='Wholesaler'
          value={wholesaler_id}
          options={formatOptions(wholesalers, 'id', 'name')}
          onChange={(_, v) => this.onAutocompleteChange(v)}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Button text={t('Cancel')} onClick={this.redirectToSalesImportList} />
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

SalesImportCreateForm.defaultProps = {
  countries: null
};

SalesImportCreateForm.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  wholesalers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAlert: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(SalesImportCreateForm);
