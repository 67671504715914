import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import insertPathParams from 'api/utils/insertPathParams';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

import { SUPER_ADMIN } from 'api/auth/roles';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import {
  ROUTE_SHOPPING_GROUPS_DETAILS,
  ROUTE_SHOPPING_GROUPS_LIST
} from 'routing/routes/ShoppingGroups';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class SalesGroupCreateForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      formData: {
        name: '',
        country_id: context.hasRole([SUPER_ADMIN])
          ? ''
          : context.user.country_id,
        description: ''
      },
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Name') })
        },
        country_id: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Country') })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse(res) {
    const { id } = res;

    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(
      insertPathParams(ROUTE_SHOPPING_GROUPS_DETAILS, { id })
    );
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await SalesGroupsApi.createSalesGroup(formData);
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { name, country_id, description },
      validation,
      loading
    } = this.state;
    const { countries } = this.props;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='name'
          label='Name'
          value={name}
          required
          autoFocus
          onChange={this.onChange}
          errorStatus={validation.name.status}
          errorText={validation.name.message}
        />
        {this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
          <Select
            name='country_id'
            label='Country'
            value={country_id}
            required
            options={formatOptions(countries, 'id', 'name')}
            onChange={this.onChange}
            errorStatus={validation.country_id.status}
            errorText={validation.country_id.message}
          />
        )}
        <Input
          name='description'
          label='Description'
          value={description}
          onChange={this.onChange}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Box>
            <Link to={ROUTE_SHOPPING_GROUPS_LIST} className='router-button'>
              <Button text={t('Cancel')} />
            </Link>
          </Box>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

SalesGroupCreateForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(SalesGroupCreateForm);
