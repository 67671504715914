import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, IconButton, Paper } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import ClientUpdateForm from 'modules/Clients/component/Update/Form';
import { ROUTE_CLIENTS_LIST } from 'routing/routes/Clients';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';

const defaultCenter = {
  lat: 52.0,
  lng: 19.5
};

class ClientUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.clientId = parseInt(props.match.params.id, 10);

    this.state = {
      client: null
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.handlePlaceChange = this.handlePlaceChange.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage(
      this.props.isInternet ? 'Update internet client' : 'Update client'
    );

    this.fetchData();
  }

  handlePlaceChange(lat, long) {
    this.setState(prevState => ({
      client: { ...prevState.client, lat, long }
    }));
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);
      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_CLIENTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: client }
      } = await ClientsApi.getClient(this.clientId);

      this.setState({ client });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { client } = this.state;
    const { isInternet } = this.props;

    if (!client) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ClientUpdateForm
              client={client}
              setAlert={this.setAlert}
              handlePlaceChangeFn={this.handlePlaceChange}
              placePosition={{ lat: client.lat, long: client.long }}
              isInternet={isInternet}
            />
            <div style={{ width: '100%', height: '60vh' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_KEY
                }}
                defaultCenter={defaultCenter}
                center={{
                  lat: parseFloat(client.lat) || defaultCenter.lat,
                  lng: parseFloat(client.long) || defaultCenter.lng
                }}
                defaultZoom={6}
                zoom={client.lat ? 12 : 6}
              >
                <IconButton
                  lat={parseFloat(client.lat) || 0.0}
                  lng={parseFloat(client.long) || 0.0}
                  style={{
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <RoomIcon fontSize='large' style={{ color: '#c73a3a' }} />
                </IconButton>
              </GoogleMapReact>
            </div>
          </Paper>
        </Box>
      </Box>
    );
  }
}

ClientUpdate.defaultProps = {
  isInternet: false
};

ClientUpdate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  isInternet: PropTypes.bool
};

export default withRouter(ClientUpdate);
