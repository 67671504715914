import { Route } from 'react-router-dom';
import {
  ORDERS_LIST,
  ORDERS_CREATE,
  ORDERS_UPDATE
} from 'api/auth/permissions/Orders';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import OrdersList from 'modules/Orders/view/List';
import OrderCreate from 'modules/Orders/view/Create';
import OrderUpdate from 'modules/Orders/view/Update';
import OrderDetails from 'modules/Orders/view/Details';

export const ROUTE_ORDERS_LIST = '/orders';
export const ROUTE_ORDERS_CREATE = '/orders/create';
export const ROUTE_ORDERS_DETAILS = '/orders/details/:id';
export const ROUTE_ORDERS_UPDATE = '/orders/update/:id';

export const permMap = {
  [ROUTE_ORDERS_LIST]: [ORDERS_LIST],
  [ROUTE_ORDERS_CREATE]: [ORDERS_CREATE],
  [ROUTE_ORDERS_UPDATE]: [ORDERS_UPDATE],
  [ROUTE_ORDERS_DETAILS]: [ORDERS_LIST]
};

const routes = {
  protected: [
    <Route key='orders-list' exact path={ROUTE_ORDERS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_ORDERS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <OrdersList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='orders-create' exact path={ROUTE_ORDERS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_ORDERS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <OrderCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='orders-update' exact path={ROUTE_ORDERS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_ORDERS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <OrderUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='orders-details' exact path={ROUTE_ORDERS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_ORDERS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <OrderDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
