import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import UpdateVisitForm from 'modules/Visits/components/UpdateVisitForm';
import { Box, Paper, CircularProgress } from '@material-ui/core';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import ApiError from 'api/exceptions/ApiError';

class UpdateVisitView extends Component {
  constructor(props) {
    super(props);

    this.state = { visit: null };

    const { setAlert, setCurrentPage } = this.props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  componentDidMount() {
    this.setCurrentPage('Update visit');
    this.fetchVisit();
  }

  fetchVisit = async () => {
    const { id } = this.props.match.params;
    try {
      const {
        data: { data: fetchedVisit }
      } = await VisitsApi.getVisit(id);
      this.setState({
        visit: fetchedVisit
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      }
    }
  };

  render() {
    const { visit } = this.state;
    return visit ? (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem', marginBottom: '2rem' }}>
            <UpdateVisitForm
              setAlert={this.setAlert}
              visit={this.state.visit}
            />
          </Paper>
        </Box>
      </Box>
    ) : (
      <CircularProgress />
    );
  }
}

UpdateVisitView.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(UpdateVisitView);
