import PropTypes from 'prop-types';
import RoomIcon from '@material-ui/icons/Room';
import { IconButton } from '@material-ui/core';
import ClientInfoWindow from 'modules/Visits/components/RoutePlanning/ClientInfoWindow';

const ClientMarker = ({ client, lat, lng, $hover }) => {
  return (
    <>
      <IconButton
        lat={lat}
        lng={lng}
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <RoomIcon fontSize='large' />
      </IconButton>
      <ClientInfoWindow client={client} show={$hover} />
    </>
  );
};

ClientMarker.defaultProps = {
  $hover: false
};

ClientMarker.propTypes = {
  client: PropTypes.shape({}).isRequired,
  lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  $hover: PropTypes.bool
};

export default ClientMarker;
