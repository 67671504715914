import { Route } from 'react-router-dom';
import {
  PROMOTIONS_LIST,
  PROMOTIONS_CREATE,
  PROMOTIONS_UPDATE
} from 'api/auth/permissions/Promotions';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import PromotionsList from 'modules/Promotions/view/List';
import PromotionsDetails from 'modules/Promotions/view/Details';
import PromotionCreate from 'modules/Promotions/view/Create';
import PromotionUpdate from 'modules/Promotions/view/Update';

export const ROUTE_PROMOTIONS_LIST = '/promotions';
export const ROUTE_PROMOTIONS_DETAILS = '/promotions/details/:id';
export const ROUTE_PROMOTIONS_CREATE = '/promotions/create';
export const ROUTE_PROMOTIONS_UPDATE = '/promotions/update/:id';

export const permMap = {
  [ROUTE_PROMOTIONS_LIST]: [PROMOTIONS_LIST],
  [ROUTE_PROMOTIONS_DETAILS]: [PROMOTIONS_LIST],
  [ROUTE_PROMOTIONS_CREATE]: [PROMOTIONS_CREATE],
  [ROUTE_PROMOTIONS_UPDATE]: [PROMOTIONS_UPDATE]
};

const routes = {
  protected: [
    <Route key='promotions-list' exact path={ROUTE_PROMOTIONS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_PROMOTIONS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <PromotionsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='promotions-details' exact path={ROUTE_PROMOTIONS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_PROMOTIONS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <PromotionsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='promotions-create' exact path={ROUTE_PROMOTIONS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_PROMOTIONS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <PromotionCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='promotions-update' exact path={ROUTE_PROMOTIONS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_PROMOTIONS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <PromotionUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
