import { Route } from 'react-router-dom';
import {
  DEPARTMENTS_LIST,
  DEPARTMENTS_CREATE,
  DEPARTMENTS_UPDATE
} from 'api/auth/permissions/Departments';
import { SALES_PERIODS_READ } from 'api/auth/permissions/SalesResults';
import Authorize from 'modules/Auth/component/Authorize';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import DepartmentsList from 'modules/Departments/view/List';
import DepartmentsCreate from 'modules/Departments/view/Create';
import DepartmentsUpdate from 'modules/Departments/view/Update';
import DepartmentsDetailsWrapper from 'modules/Departments/view/Details/DetailsWrapper';
import SalesResultsDetails from 'modules/SalesResults/view/Details';
import InDepartmentSalesResultsComparison from 'modules/SalesResults/view/InDepartmentComparison';

export const ROUTE_DEPARTMENTS_LIST = '/departments';
export const ROUTE_DEPARTMENTS_DETAILS = '/departments/details/:id';
export const ROUTE_DEPARTMENTS_CREATE = '/departments/create';
export const ROUTE_DEPARTMENTS_UPDATE = '/departments/update/:id';
export const ROUTE_DEPARTMENTS_SALES_RESULT =
  '/departments/details/:department_id/sales_results';
export const ROUTE_DEPARTMENTS_SALES_RESULT_COMPARISON =
  '/departments/details/:id/sales_results_comparison';

export const permMap = {
  [ROUTE_DEPARTMENTS_LIST]: [DEPARTMENTS_LIST],
  [ROUTE_DEPARTMENTS_CREATE]: [DEPARTMENTS_CREATE],
  [ROUTE_DEPARTMENTS_UPDATE]: [DEPARTMENTS_UPDATE],
  [ROUTE_DEPARTMENTS_DETAILS]: [DEPARTMENTS_LIST],
  [ROUTE_DEPARTMENTS_SALES_RESULT]: [SALES_PERIODS_READ],
  [ROUTE_DEPARTMENTS_SALES_RESULT_COMPARISON]: [SALES_PERIODS_READ]
};

const routes = {
  protected: [
    <Route key='departments' exact path={ROUTE_DEPARTMENTS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_DEPARTMENTS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <DepartmentsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='departments-create' exact path={ROUTE_DEPARTMENTS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_DEPARTMENTS_CREATE]}>
        <DepartmentsCreate />
      </Authorize>
    </Route>,
    <Route key='departments-update' exact path={ROUTE_DEPARTMENTS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_DEPARTMENTS_UPDATE]}>
        <DepartmentsUpdate />
      </Authorize>
    </Route>,
    <Route key='departments-details' exact path={ROUTE_DEPARTMENTS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_DEPARTMENTS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <DepartmentsDetailsWrapper contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='departments-sales-result'
      exact
      path={ROUTE_DEPARTMENTS_SALES_RESULT}
    >
      <Authorize redirect permissions={permMap[ROUTE_DEPARTMENTS_SALES_RESULT]}>
        <BasicContextConsumerWrapper
          component={fn => <SalesResultsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='departments-sales-result_comparison'
      exact
      path={ROUTE_DEPARTMENTS_SALES_RESULT_COMPARISON}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_DEPARTMENTS_SALES_RESULT_COMPARISON]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <InDepartmentSalesResultsComparison contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
