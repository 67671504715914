import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import { ROUTE_CLIENTS_LIST } from 'routing/routes/Clients';
import WholesalersMappingsApi from 'api/connections/Wholesalers/WholesalersMappingsApi';
import WholesalerClientsMappingUpdateForm from 'modules/Clients/component/Details/ClientsMapping/Update/Form';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';

class WholesalerClientsMappingUpdate extends Component {
  constructor(props) {
    super(props);

    this.clientId = parseInt(props.match.params.client_id, 10);
    this.wholesalerId = parseInt(props.match.params.wholesaler_id, 10);

    this.state = {
      mapping: undefined
    };
  }

  async componentDidMount() {
    if (!this.clientId || !this.wholesalerId) {
      this.redirectToList();
    }
    this.props.contextMethods.setCurrentPage('Update client mapping');

    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.redirectToList();
      }
    } else {
      console.error(err);
    }
  }

  redirectToList() {
    return this.props.history.push(ROUTE_CLIENTS_LIST);
  }

  async fetchData() {
    try {
      const {
        data: {
          data: [mapping]
        }
      } = await WholesalersMappingsApi.getWholesalersClientsMappings({
        client_id: this.clientId,
        wholesaler_id: this.wholesalerId
      });

      if (!mapping) return this.redirectToList();

      this.setState({ mapping });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { mapping } = this.state;

    if (!mapping) return <Loader />;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <WholesalerClientsMappingUpdateForm mapping={mapping} />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalerClientsMappingUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      client_id: PropTypes.string.isRequired,
      wholesaler_id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerClientsMappingUpdate);
