import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import {
  ROUTE_VISITS_LIST,
  ROUTE_VISIT_FILE_CREATE
} from 'routing/routes/Visits';
import VisitFilesApi from 'api/connections/Visits/VisitFilesApi';
import VisitFilesFilter from 'modules/Visits/components/Details/Files/Filter';
import VisitFilesList from 'modules/Visits/components/Details/Files/List';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import { VISITS_UPDATE } from 'api/auth/permissions/Visits';
import insertPathParams from 'api/utils/insertPathParams';
import fileDownload from 'js-file-download';

class VisitDetailsFiles extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.visitId = parseInt(props.match.params.id, 10);
    this.state = {
      files: [],
      sort: {},
      filter: {
        name: '',
        creator_name: '',
        created_at_from: null,
        created_at_to: null
      },
      filterValidation: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      }
    };

    const { setAlert } = props;
    this.setAlert = setAlert;

    this.setSort = this.setSort.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onDeleteFileSuccess = this.onDeleteFileSuccess.bind(this);
    this.fetchVisitFile = this.fetchVisitFile.bind(this);
  }

  componentDidMount() {
    this.fetchVisitFiles();
  }

  onDeleteFileSuccess(fileId) {
    this.setState(prevState => {
      return {
        ...prevState,
        files: prevState.files.filter(file => file.id !== fileId)
      };
    });
  }

  onCreateClick() {
    this.props.history.push(
      insertPathParams(ROUTE_VISIT_FILE_CREATE, { id: this.visitId })
    );
  }

  setSort(sort) {
    this.setState(
      prevState => {
        const { sort: prevSort } = prevState;
        return { sort: { ...prevSort, ...sort } };
      },
      () => this.fetchVisitFiles()
    );
  }

  setFilter(filter) {
    this.setState(
      prevState => {
        const { filter: prevFilter, pagination: prevPagination } = prevState;
        return {
          filter: { ...prevFilter, ...filter },
          pagination: { ...prevPagination, page: 1 }
        };
      },
      () => this.fetchVisitFiles()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchVisitFiles()
    );
  }

  async fetchVisitFiles() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    const {
      visit: { id: visitId }
    } = this.props;

    try {
      const {
        data: { data: files, meta }
      } = await VisitFilesApi.getVisitFiles(visitId, {
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        files,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();

        this.setAlert(message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_VISITS_LIST);
        }

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchVisitFile({ id: fileId, name }) {
    try {
      const res = await VisitFilesApi.getVisitFile({
        visitId: this.visitId,
        fileId
      });

      const file = new Blob([res.data]);

      fileDownload(file, name);
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { files, sort, filter, filterValidation, pagination } = this.state;
    const {
      visit: { id: visitId, user_id }
    } = this.props;
    const { user } = this.context;

    return (
      <Paper style={{ padding: '1rem' }}>
        <VisitFilesFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        <VisitFilesList
          files={files}
          visitId={visitId}
          sort={sort}
          setSort={this.setSort}
          onDeleteFileSuccess={this.onDeleteFileSuccess}
          fetchVisitFile={this.fetchVisitFile}
        />
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>
            <Authorize permissions={[VISITS_UPDATE]}>
              <IconButton
                className='create-icon'
                onClick={this.onCreateClick}
                icon={<AddIcon fontSize='large' />}
                disabled={
                  !(
                    this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) ||
                    user.id === user_id
                  )
                }
                tooltipMsg='You are not assign to this visit'
                alt='create'
              />
            </Authorize>
          </Box>
          <Pagination
            pagination={pagination}
            setPagination={this.setPagination}
            rowsPerPageOptions={[5, 15, 30, 100]}
          />
        </Box>
      </Paper>
    );
  }
}

VisitDetailsFiles.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  visit: PropTypes.shape({ id: PropTypes.number, user_id: PropTypes.number })
    .isRequired
};

export default withRouter(VisitDetailsFiles);
