import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from 'modules/Layout/component/IconButton';

const DashboardListItem = ({ informationBox, redirectLink }) => {
  const history = useHistory();

  const onRedirectClick = () => {
    history.push(redirectLink);
  };

  const renderRedirectIcon = () => {
    return (
      <IconButton
        className='details-icon'
        onClick={() => onRedirectClick()}
        icon={<SearchIcon />}
        alt='details'
      />
    );
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      p={1}
      mb={1}
      className='dashboard-list-item'
    >
      {informationBox}
      {redirectLink && renderRedirectIcon()}
    </Box>
  );
};

DashboardListItem.propTypes = {
  informationBox: PropTypes.node.isRequired,
  redirectLink: PropTypes.string.isRequired
};

export default DashboardListItem;
