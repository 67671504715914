import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import getPathQuery from 'api/utils/getPathQuery';
import { ROUTE_DISCOUNTS_LIST } from 'routing/routes/Discounts';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import DiscountsApi from 'api/connections/Discounts/DiscountsApi';
import DiscountForm from 'modules/Discounts/component/Form';

class DiscountUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.discountId = parseInt(props.match.params.id, 10);

    this.clientId = parseInt(
      getPathQuery(this.props.location.search, 'client_id'),
      10
    );
    this.salesGroupId = parseInt(
      getPathQuery(this.props.location.search, 'sales_group_id'),
      10
    );
    this.salesGroupName = getPathQuery(
      this.props.location.search,
      'sales_group_name'
    );
    this.clientName = getPathQuery(this.props.location.search, 'client_name');

    this.setAlert = props.contextMethods.setAlert;
    this.setCurrentPage = props.contextMethods.setCurrentPage;

    this.state = { discount: null };
  }

  componentDidMount() {
    this.setCurrentPage('Update discount');
    this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: { data: discount }
      } = await DiscountsApi.getDiscount(this.discountId);

      this.setState({ discount });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_DISCOUNTS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { discount } = this.state;

    if (!discount) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <DiscountForm
              discount={discount}
              clientId={this.clientId}
              clientName={this.clientName}
              salesGroupId={this.salesGroupId}
              salesGroupName={this.salesGroupName}
              setAlert={this.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

DiscountUpdate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(DiscountUpdate);
