import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash/lang';
import t from 'translate/translate';
import { Box, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from 'modules/Layout/component/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import insertPathParams from 'api/utils/insertPathParams';
import { months } from 'modules/Shared/dateConsts';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import { ROUTE_DEPARTMENTS_LIST } from 'routing/routes/Departments';
import { ROUTE_USERS_SALES_RESULT } from 'routing/routes/Users';
import SalesResultsApi from 'api/connections/SalesResults/SalesResultsApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Loader from 'modules/Layout/component/Loader';
import { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import setUpdateActionDisableStatus from 'modules/Users/utils/setUpdateActionDisableStatus';

class UserSalesPeriodArchives extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.currentMonth = currentDate.getMonth();

    this.state = {
      salesResults: null,
      year: this.currentYear,
      department_id: props.user.sales_results_departments[0]?.id
    };

    this.actionAuthorization = (() => {
      if (context.hasRole([SUPER_ADMIN, ADMIN])) return true;
      if (context.hasRole([DIRECTOR])) {
        return setUpdateActionDisableStatus(
          context.user,
          props.user.departments
        );
      }
      return false;
    })();
  }

  componentDidMount() {
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_DEPARTMENTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onDepartmentChange(res) {
    let department_id = null;

    if (res) {
      department_id = isArray(res) ? res.map(v => v.key) : res.key;
    }

    this.setState({ department_id }, () => this.fetchData());
  }

  onDetailsClick(month) {
    const { year, department_id } = this.state;

    const path = `${insertPathParams(ROUTE_USERS_SALES_RESULT, {
      user_id: this.props.user.id
    })}?month=${month.id}.${year}&department_id=${department_id}`;

    this.props.history.push(path);
  }

  getSummarizedMonths() {
    const { included_months } = this.state.salesResults;

    return included_months.map(item => parseInt(item.split('.')[0], 10));
  }

  validMonthRange(month) {
    const { year } = this.state;

    if (year < this.currentYear) return true;

    return year <= this.currentYear && month <= this.currentMonth + 1;
  }

  changeDate(increment = false) {
    this.setState(
      ({ year: prevYear }) => {
        return { year: increment ? prevYear + 1 : prevYear - 1 };
      },
      () => this.fetchData()
    );
  }

  async fetchData() {
    const { year, department_id } = this.state;

    if (!year || !department_id) return;

    try {
      const {
        data: salesResults
      } = await SalesResultsApi.getUserSalesResultsDetails({
        user_id: this.props.user.id,
        department_id,
        period_type: 'year',
        year
      });

      this.setState({
        salesResults
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderBody() {
    const { year, salesResults } = this.state;

    if (!salesResults) return <Loader />;

    const summarizedMonths = this.getSummarizedMonths();

    return (
      <>
        <Box w={1} display='flex' justifyContent='center' alignItems='center'>
          <IconButton
            onClick={() => this.changeDate()}
            icon={<ArrowBackIcon />}
            alt='change to previous year'
          />
          <Typography component='h6' variant='h6'>
            {year}
          </Typography>
          <IconButton
            onClick={() => this.changeDate(true)}
            icon={<ArrowForwardIcon />}
            alt='change to next year'
          />
        </Box>
        <Box display='flex' flexDirection='column'>
          {months.map(month => (
            <Box
              key={month.id}
              mb={0.8}
              display='flex'
              flexDirection='row'
              alignItems='center'
              style={{ height: '2rem' }}
            >
              <Box
                width='2.5rem'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                {summarizedMonths.includes(month.id) && (
                  <CheckIcon color='primary' fontSize='small' />
                )}
              </Box>
              <Box minWidth='12rem'>
                <Typography variant='body1' style={{ marginRight: '.5rem' }}>
                  {t(month.name)}
                </Typography>
              </Box>
              <Box>
                {this.validMonthRange(month.id) && this.actionAuthorization && (
                  <>
                    {summarizedMonths.includes(month.id) && (
                      <IconButton
                        onClick={() => this.onDetailsClick(month)}
                        className='details-icon'
                        size='small'
                        icon={<SearchIcon />}
                        alt='details'
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </>
    );
  }

  render() {
    const { department_id } = this.state;

    return (
      <>
        <Typography component='h6' variant='h6'>
          {t('Sales results approved')}
        </Typography>
        <Typography variant='body2' style={{ marginBottom: '1rem' }}>
          {t(
            'The possibility to summarize the sales period can be found in the department details.'
          )}
        </Typography>
        {isEmpty(this.props.user.sales_results_departments) ? (
          <Box display='flex' justifyContent='center'>
            <Typography variant='body1'>
              {t('User has no approved sales results')}
            </Typography>
          </Box>
        ) : (
          <>
            <Box>
              <Autocomplete
                name='department_id'
                label='Current or previous department'
                value={department_id}
                options={formatOptions(
                  this.props.user.sales_results_departments,
                  'id',
                  'name'
                )}
                onChange={(_, v) => this.onDepartmentChange(v)}
              />
            </Box>
            {department_id ? (
              this.renderBody()
            ) : (
              <Box display='flex' justifyContent='center'>
                <Typography variant='body1'>{t('Pick department')}</Typography>
              </Box>
            )}
          </>
        )}
      </>
    );
  }
}

UserSalesPeriodArchives.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sales_results_departments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ),
    departments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired
    )
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(UserSalesPeriodArchives);
