import { Component } from 'react';
import PropTypes from 'prop-types';
import { ROUTE_WHOLESALERS_CREATE } from 'routing/routes/Wholesalers';
import WholesalersTable from 'modules/Wholesalers/component/List/Table';
import WholesalersFilter from 'modules/Wholesalers/component/List/Filter';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import { Box } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';
import { WHOLESALERS_CREATE } from 'api/auth/permissions/Wholesalers';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { downloadFile } from '../../../Shared/utils/file';

class WholesalersList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        name: '',
        tax_number: '',
        phone: '',
        main_contact_email: '',
        country_id: '',
        city: '',
        street: '',
        postal_code: '',
        invalid_import: null,
        missing_import: null
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      wholesalers: [],
      countries: [],
      loading: true
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchWholesalers = this.fetchWholesalers.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportWholesalers = this.exportWholesalers.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Wholesalers');
    this.fetchData();
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchWholesalers()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchWholesalers()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchWholesalers()
    );
  }

  async fetchWholesalers() {
    try {
      const {
        data: { data: wholesalers, meta }
      } = await WholesalersApi.getWholesalers({
        ...this.state.sort,
        sort_field:
          this.state.sort.sort_field === 'country_id'
            ? 'country_name'
            : this.state.sort.sort_field,
        ...this.state.filter,
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page
      });

      this.setState({
        wholesalers,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchData() {
    try {
      let countries = [];
      const {
        data: { data: wholesalers, meta }
      } = await WholesalersApi.getWholesalers({
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page
      });

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await DepartmentsApi.getCountries();
        countries = countriesData;
      }

      this.setState({
        wholesalers,
        countries,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      }
    }
  }

  async exportWholesalers() {
    try {
      const { filter, sort } = this.state;

      const res = await WholesalersApi.exportWholesalers({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      if (err instanceof ApiError) {
        this.props.contextMethods.setAlert(err.getPayload().message);
      }
    }
  }

  renderActions() {
    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box display='flex'>
          <Authorize permissions={[WHOLESALERS_CREATE]}>
            <IconButton
              icon={<AddIcon fontSize='large' />}
              onClick={() => this.props.history.push(ROUTE_WHOLESALERS_CREATE)}
              className='create-icon'
              alt='create'
            />
          </Authorize>
          <IconButton
            onClick={this.exportWholesalers}
            icon={<GetAppOutlinedIcon color='primary' fontSize='large' />}
            alt='export table'
          />
        </Box>
        <Pagination
          pagination={this.state.pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    return (
      <div>
        <WholesalersFilter
          filter={this.state.filter}
          validation={this.state.filterValidation}
          setFilter={this.setFilter}
          countries={this.state.countries}
        />
        {this.renderActions()}
        <WholesalersTable
          sort={this.state.sort}
          setSort={this.setSort}
          wholesalers={this.state.wholesalers}
          fetchWholesalers={this.fetchWholesalers}
          countries={this.state.countries}
          loading={this.state.loading}
        />
        {this.renderActions()}
      </div>
    );
  }
}

WholesalersList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(WholesalersList);
