import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { quarters } from 'modules/Shared/dateConsts';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';

const filterTypes = [{ slug: 'my_clients', label: 'My clients' }];

class StatisticsClientsOrdersFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'autocomplete',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasPermission([
              POSTAL_CODES_COUNTRIES_SHOW
            ])
          },
          {
            ...this.getBasicInputValues('type'),
            label: 'Type',
            type: 'select',
            emptyValueText: 'All',
            options: formatOptions(filterTypes, 'slug', 'label', true)
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('year'),
            label: 'Year',
            type: 'year'
          },
          {
            ...this.getBasicInputValues('quarter'),
            label: 'Quarter',
            type: 'select',
            options: formatOptions(quarters, 'id', 'name')
          }
        ]
      }
    ];
  }
}

export default StatisticsClientsOrdersFilter;
