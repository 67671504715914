import Filter from 'modules/Layout/component/List/Filter';
import { SUPER_ADMIN } from 'api/auth/roles';
import { formatOptions } from 'modules/Layout/component/Select';

class DepartmentsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'select',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasRole([SUPER_ADMIN])
          }
        ]
      }
    ];
  }
}

export default DepartmentsFilter;
