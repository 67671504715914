import BaseApiConfig from 'api/connections/BaseApiConfig';

class StatisticsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    const statisticsPath = 'statistics';

    this.statisticsClientsOrdersPath = `${statisticsPath}/clients-orders-ranking`;
    this.statisticsClientsSalesPath = `${statisticsPath}/clients-sales-ranking`;
    this.statisticsInternetClientsSalesPath = `${statisticsPath}/internet-clients-report`;
    this.statisticsProductsSalesPath = `${statisticsPath}/products-ranking`;
    this.statisticsPriorityProductsSalesPath = `${statisticsPath}/priority-products`;
    this.statisticsUsersFinishedVisitsPath = `${statisticsPath}/users-finished-visits-ranking`;
    this.statisticsWholesalersSalesPath = `${statisticsPath}/wholesalers-ranking`;
    this.statisticsUsersOrdersValuePath = `${statisticsPath}/users-orders-value-ranking`;
    this.statisticsUsersVisitsPath = `${statisticsPath}/users-visits-ranking`;
    this.statisticsUsersOrdersVisitsPath = `${statisticsPath}/users-orders-visits-ranking`;
    this.statisticsUsersVisitsClientsPath = `${statisticsPath}/users-visited-clients-ranking`;
    this.statisticsUsersVisitsComparisonPath = `${statisticsPath}/users-visits-comparison`;
    this.statisticsSalesComparisonPath = `${statisticsPath}/wholesalers-sales-comparison`;
    this.statisticsTradersResultsPath = `${statisticsPath}/traders-results`;
    this.statisticsTradersResultsSendingSettingsPath = `${statisticsPath}/traders-results/sending-settings`;
    this.clientsTargetsReportPath = `${statisticsPath}/clients-targets-ranking`;
    this.gratisProductsReportPath = `${statisticsPath}/users-free-products-report`;
  }

  getClientsOrders(pathParams) {
    return this.get(this.statisticsClientsOrdersPath, pathParams);
  }

  getClientsSales(pathParams) {
    return this.get(this.statisticsClientsSalesPath, pathParams);
  }

  getInternetClientsSales(pathParams) {
    return this.get(this.statisticsInternetClientsSalesPath, pathParams);
  }

  getProductsSales(pathParams) {
    return this.get(this.statisticsProductsSalesPath, pathParams);
  }

  getPriorityProductsSales(pathParams) {
    return this.get(this.statisticsPriorityProductsSalesPath, pathParams);
  }

  getUsersFinishedVisits(pathParams) {
    return this.get(this.statisticsUsersFinishedVisitsPath, pathParams);
  }

  getWholesalersSales(pathParams) {
    return this.get(this.statisticsWholesalersSalesPath, pathParams);
  }

  getUsersOrdersValue(pathParams) {
    return this.get(this.statisticsUsersOrdersValuePath, pathParams);
  }

  getUsersVisits(pathParams) {
    return this.get(this.statisticsUsersVisitsPath, pathParams);
  }

  getUsersOrdersVisits(pathParams) {
    return this.get(this.statisticsUsersOrdersVisitsPath, pathParams);
  }

  getUsersVisitsClients(pathParams) {
    return this.get(this.statisticsUsersVisitsClientsPath, pathParams);
  }

  getUsersVisitsComparison(pathParams) {
    return this.get(this.statisticsUsersVisitsComparisonPath, pathParams);
  }

  getSalesComparison(pathParams) {
    return this.get(this.statisticsSalesComparisonPath, pathParams);
  }

  getTradersResults(pathParams) {
    return this.get(this.statisticsTradersResultsPath, pathParams);
  }

  getTradersResultsSendingSettings() {
    return this.get(this.statisticsTradersResultsSendingSettingsPath);
  }

  updateTradersResultsSendingSettings(payload) {
    return this.put(this.statisticsTradersResultsSendingSettingsPath, payload);
  }

  getClientsTargetsReport(pathParams) {
    return this.get(this.clientsTargetsReportPath, pathParams);
  }

  getGratisProductsReport(pathParams) {
    return this.get(this.gratisProductsReportPath, pathParams);
  }
}

export default new StatisticsApi();
