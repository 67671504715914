import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class SalesImportsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.salesImportsPath = 'sales-imports';
    this.salesImportPath = 'sales-imports/:id';
    this.salesImportLogPath = 'sales-imports/:id/log';
    this.salesImportFilePath = 'sales-imports/:id/file';
    this.salesImportCancelPath = 'sales-imports/:id/cancel';
    this.salesImportWholesalerPath = 'sales-imports/:id/wholesaler';
    this.salesImportMapProductPath = 'sales-imports/:importId/product/:tempId';
    this.salesImportMapClientPath = 'sales-imports/:importId/client/:tempId';
    this.salesImportExport = 'sales-imports/export';
  }

  getSalesImports(pathParams) {
    return this.get(this.salesImportsPath, pathParams);
  }

  getSalesImport(id) {
    return this.get(insertPathParams(this.salesImportPath, { id }));
  }

  getSalesImportLog(id) {
    const options = {
      responseType: 'blob'
    };
    return this.get(
      insertPathParams(this.salesImportLogPath, { id }),
      {},
      options
    );
  }

  getSalesImportOriginalFile(id) {
    const options = {
      responseType: 'blob'
    };
    return this.get(
      insertPathParams(this.salesImportFilePath, { id }),
      {},
      options
    );
  }

  cancelSalesImport(id, payload) {
    return this.post(
      insertPathParams(this.salesImportCancelPath, { id }),
      payload
    );
  }

  setWholesaler(id, payload) {
    return this.post(
      insertPathParams(this.salesImportWholesalerPath, { id }),
      payload
    );
  }

  mapProduct(ids, payload) {
    return this.post(
      insertPathParams(this.salesImportMapProductPath, ids),
      payload
    );
  }

  mapClient(ids, payload) {
    return this.post(
      insertPathParams(this.salesImportMapClientPath, ids),
      payload
    );
  }

  createSalesImport(payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return this.post(this.salesImportsPath, payload, options);
  }

  exportSalesImport(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.salesImportExport, pathParams, options);
  }
}

export default new SalesImportsApi();
