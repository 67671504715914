import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class ClientsContractsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.clientsContractsPath = 'targets/clients';
    this.clientsContractPath = 'targets/clients/:id';
    this.confirmClientsContractPath = 'targets/clients/:id/confirm';
  }

  getClientsContracts(pathParams) {
    return this.get(this.clientsContractsPath, pathParams);
  }

  getClientContract(id) {
    return this.get(insertPathParams(this.clientsContractPath, { id }));
  }

  createClientContract(payload) {
    return this.post(this.clientsContractsPath, payload);
  }

  updateClientContract(id, payload) {
    return this.put(
      insertPathParams(this.clientsContractPath, { id }),
      payload
    );
  }

  confirmClientContract(id) {
    return this.put(insertPathParams(this.confirmClientsContractPath, { id }));
  }

  deleteClientContract(id) {
    return this.delete(insertPathParams(this.clientsContractPath, { id }));
  }
}

export default new ClientsContractsApi();
