import { Component } from 'react';
import PropTypes from 'prop-types';
import insertPathParams from 'api/utils/insertPathParams';
import { Box, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import DashboardListItem from 'modules/Dashboard/component/Layout/ListItem';
import DashboardList from 'modules/Dashboard/component/Layout/List';
import TargetsApi from 'api/connections/Targets/TargetsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_TARGETS_DETAILS } from 'routing/routes/Targets';

class DashboardTargets extends Component {
  static contextType = AuthContext;

  static getInformationBox(target) {
    const { name } = target;

    const current_value = target.current_value || 0;
    const current_percent = target.current_percent || 0;

    return (
      <Box display='flex' flexDirection='column'>
        <Typography style={{ fontWeight: '600' }}>
          {name} - {current_value} ({current_percent}%)
        </Typography>
      </Box>
    );
  }

  static getRedirectLink({ id }) {
    return insertPathParams(ROUTE_TARGETS_DETAILS, { id });
  }

  static renderItem(target) {
    const informationBox = DashboardTargets.getInformationBox(target);
    const redirectLink = DashboardTargets.getRedirectLink(target);

    return (
      <DashboardListItem
        key={target.id}
        informationBox={informationBox}
        redirectLink={redirectLink}
      />
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      targets: undefined,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const pathParams = {
      user_id: this.context.user.id,
      active: true,
      per_page: Number.MAX_SAFE_INTEGER
    };

    try {
      const {
        data: { data: targets }
      } = await TargetsApi.getTargets(pathParams);

      this.setState({ targets, loading: false });
    } catch (err) {
      this.setState({ targets: undefined, loading: false });
      this.props.handleError(err);
    }
  }

  render() {
    const { loading, targets } = this.state;

    if (loading) return <Loader />;

    return (
      <DashboardList data={targets} renderItem={DashboardTargets.renderItem} />
    );
  }
}

DashboardTargets.defaultProps = {
  handleError: null
};

DashboardTargets.propTypes = {
  handleError: PropTypes.func
};

export default DashboardTargets;
