import BaseApiConfig from 'api/connections/BaseApiConfig';
import axios from 'axios';

class GeocodeApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.geocodePath = 'https://maps.googleapis.com/maps/api/geocode/json';
  }

  getAddress(pathParams) {
    const paramsPath = BaseApiConfig.formatPathParams(pathParams);
    return axios.get(`${this.geocodePath}${paramsPath}`, {
      transformRequest: (data, headers) => {
        // eslint-disable-next-line no-param-reassign
        delete headers.Authorization;
      }
    });
  }
}

export default new GeocodeApi();
