import {
  PAYMENT_TYPE_DISCOUNTS,
  THRESHOLD_DISCOUNTS
} from 'modules/Discounts/discountsTypes';
import getPriceForProduct from 'modules/PriceLists/utils/getPriceForProduct';
import { isEmpty } from 'lodash/lang';

function getPrice(product, countryId, priceList) {
  let price;

  if (priceList) {
    const priceListPrice = getPriceForProduct(priceList, product.product_id);

    if (priceListPrice) price = priceListPrice;
  }

  if (!price) {
    price = product.prices.find(p => p.country_id === countryId)?.price || 0;
  }

  return parseFloat(price);
}

function getTax(product, countryId, taxesData) {
  // Product on details view
  if (product.tax_value !== undefined) {
    return product.tax_value || 0;
  }

  let tax = 0;

  const taxId = product.taxes.find(
    productTax => productTax.country_id === countryId
  )?.tax_id;

  if (taxId) {
    tax =
      taxesData.find(
        taxData => taxData.id === taxId && taxData.country_id === countryId
      )?.value || 0;
  }

  return tax;
}

export function getPromotions(product, promotions) {
  if (product.is_free) return [];

  return promotions.filter(promotion => {
    return promotion.products.includes(product.id);
  });
}

export function getPromotionsPercentageSum(promotions) {
  return promotions.reduce((acc, { percent }) => acc + percent / 100, 0);
}

function getReachedThreshold(discount, totalNetValue) {
  const sortedThresholds = discount.type_data
    .filter(thr => thr.threshold <= totalNetValue)
    .sort((a, b) => (a.threshold > b.threshold ? -1 : 0));

  return sortedThresholds[0]?.percentage;
}

function isDiscountCoveringProduct(product, discount, totalNetValue) {
  if (discount.type === THRESHOLD_DISCOUNTS) {
    if (!getReachedThreshold(discount, totalNetValue)) return false;
  }

  if (
    isEmpty([
      ...discount.subcategory_id,
      ...discount.category_id,
      ...discount.group_id
    ])
  ) {
    return true;
  }

  return (
    discount.subcategory_id.includes(product.subcategory_id) ||
    discount.category_id.includes(product.category_id) ||
    discount.group_id.includes(product.group_id)
  );
}

function getDiscounts(product, discounts, totalNetValue) {
  if (product.is_free) return [];

  return discounts.filter(discount =>
    isDiscountCoveringProduct(product, discount, totalNetValue)
  );
}

export function getDiscountsPercentageSum(discounts, totalNetValue) {
  return discounts.reduce((acc, discount) => {
    let percentage;

    if (discount.type === THRESHOLD_DISCOUNTS) {
      percentage = getReachedThreshold(discount, totalNetValue);
    } else {
      percentage = discount.type_data.percentage;
    }

    return acc + percentage;
  }, 0);
}

/* eslint no-shadow: "off" */
function formatProductsArray(products, taxesData, countryId, priceList) {
  return products.map(product => {
    const {
      id,
      product_id,
      quantity,
      is_free,
      group_id,
      category_id,
      subcategory_id
    } = product;

    const price = getPrice(product, countryId, priceList);
    const tax = getTax(product, countryId, taxesData);

    return {
      id,
      product_id,
      group_id,
      category_id,
      subcategory_id,
      is_free,
      quantity,
      price,
      tax,
      netValueBeforeReduction: quantity * price
    };
  });
}

function addPromotions(products, promotions) {
  return products.map(product => {
    const { is_free, quantity, netValueBeforeReduction } = product;

    const result = {
      ...product,
      promotions: [],
      promotionsPercentageSum: 0,
      promotionsValue: 0,
      netValueWithPromotion: undefined
    };

    if (is_free) {
      result.netValueWithPromotion = parseFloat(quantity * 0.01);
    } else {
      result.promotions = getPromotions(product, promotions);
      result.promotionsPercentageSum = getPromotionsPercentageSum(
        result.promotions
      );
      result.promotionsValue =
        netValueBeforeReduction * result.promotionsPercentageSum;
      result.netValueWithPromotion =
        netValueBeforeReduction - result.promotionsValue;
    }

    return result;
  });
}

function addDiscounts(
  products,
  discounts,
  paymentDiscountId,
  isDirect,
  totalNetValue
) {
  const filteredDiscounts = discounts.filter(discount =>
    discount.type === PAYMENT_TYPE_DISCOUNTS
      ? discount.id === paymentDiscountId
      : true
  );

  return products.map(product => {
    const { is_free, netValueWithPromotion } = product;

    const result = {
      ...product,
      discounts: [],
      discountsPercentageSum: 0,
      discountsValue: 0,
      netValueWithDiscount: undefined
    };

    if (is_free || !isDirect) {
      result.netValueWithDiscount = netValueWithPromotion;
    } else {
      result.discounts = getDiscounts(
        product,
        filteredDiscounts,
        totalNetValue
      );

      result.discountsPercentageSum = getDiscountsPercentageSum(
        result.discounts,
        totalNetValue
      );

      result.discountsValue =
        netValueWithPromotion * result.discountsPercentageSum;

      result.netValueWithDiscount =
        netValueWithPromotion - result.discountsValue;
    }

    return result;
  });
}

function getGrossValueBeforeReduction(products) {
  return products.reduce((acc, product) => {
    const { netValueBeforeReduction, tax } = product;

    const grossValue = netValueBeforeReduction + netValueBeforeReduction * tax;

    return acc + grossValue;
  }, 0);
}

function getNetValueBeforeReduction(products) {
  return products.reduce(
    (acc, { netValueBeforeReduction }) => acc + netValueBeforeReduction,
    0
  );
}

function getTotalGrossValue(products) {
  return products.reduce((acc, product) => {
    const { netValueWithDiscount, tax } = product;

    const total = netValueWithDiscount + netValueWithDiscount * tax;

    return acc + total;
  }, 0);
}

function getTotalNetValueBeforeDiscount(products) {
  return products.reduce(
    (acc, { netValueWithPromotion }) => acc + netValueWithPromotion,
    0
  );
}

function getTotalNetValue(products) {
  return products.reduce(
    (acc, { netValueWithDiscount }) => acc + netValueWithDiscount,
    0
  );
}

function getTotalGrossReductionValue(products) {
  return products.reduce(
    (acc, { promotionsValue, discountsValue, tax }) =>
      acc +
      (promotionsValue + discountsValue) +
      (promotionsValue + discountsValue) * tax,
    0
  );
}

function getTotalGrossGratisProductsValue(products) {
  return products.reduce(
    (acc, { is_free, price, tax }) =>
      is_free ? acc + price + price * tax : acc,
    0
  );
}

export default function calculateOrderValues(params) {
  const {
    products,
    discounts,
    paymentDiscountId,
    promotions,
    taxesData,
    countryId,
    isDirect,
    priceList
  } = params;

  let formattedProducts = formatProductsArray(
    products,
    taxesData,
    countryId,
    priceList
  );

  formattedProducts = addPromotions(formattedProducts, promotions);

  const totalNetValueBeforeDiscount = getTotalNetValueBeforeDiscount(
    formattedProducts
  );

  formattedProducts = addDiscounts(
    formattedProducts,
    discounts,
    paymentDiscountId,
    isDirect,
    totalNetValueBeforeDiscount
  );

  const totalNetValueBeforeReduction = getNetValueBeforeReduction(
    formattedProducts
  );

  const totalGrossValueBeforeReduction = getGrossValueBeforeReduction(
    formattedProducts
  );
  const totalGrossReductionValue = getTotalGrossReductionValue(
    formattedProducts
  );
  const totalGrossGratisProductsValue = getTotalGrossGratisProductsValue(
    formattedProducts
  );
  const totalGrossValue = getTotalGrossValue(formattedProducts);

  const totalNetValue = getTotalNetValue(formattedProducts);

  return {
    allProducts: formattedProducts,
    totalNetValueBeforeReduction,
    totalGrossValueBeforeReduction,
    totalGrossReductionValue,
    totalGrossGratisProductsValue,
    totalNetValueBeforeDiscount,
    totalGrossValue,
    totalNetValue
  };
}
