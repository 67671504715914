import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, TableSortLabel } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import t from 'translate/translate';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import SalesGroupNotesFilter from 'modules/SalesGroups/component/Notes/List/Filter';
import SalesGroupsNotesApi from 'api/connections/SalesGroups/SalesGroupsNotesApi';
import SalesGroupNote from 'modules/SalesGroups/component/Notes/List/ListItem';
import SalesGroupNoteCreateDialog from 'modules/SalesGroups/component/Notes/Create/Dialog';
import SalesGroupNoteUpdateDialog from 'modules/SalesGroups/component/Notes/Update/Dialog';
import SalesGroupNoteDeleteDialog from 'modules/SalesGroups/component/Notes/Delete/Dialog';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import { ROUTE_SHOPPING_GROUPS_LIST } from 'routing/routes/ShoppingGroups';

const useStyles = {
  icon: {
    fontSize: '1.8rem'
  }
};

class SalesGroupDetailsNotes extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.groupId = parseInt(props.match.params.id, 10);
    this.setAlert = props.setAlert;

    this.state = {
      notes: [],
      sort: {
        sort_field: '',
        sort_order: 'asc'
      },
      filter: {
        creator_name: '',
        created_at_from: null,
        created_at_to: null
      },
      filterValidation: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      createDialogOpenStatus: false,
      updateDialogOpenStatus: false,
      deleteDialogOpenStatus: false,
      noteToModify: null
    };

    this.setSort = this.setSort.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onCreateSuccess = this.onCreateSuccess.bind(this);
    this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  componentDidMount() {
    this.fetchSalesGroupNotes();
  }

  onDialogClose() {
    this.setState({
      createDialogOpenStatus: false,
      updateDialogOpenStatus: false,
      deleteDialogOpenStatus: false,
      noteToModify: null
    });
  }

  onCreateClick() {
    this.setState({ createDialogOpenStatus: true });
  }

  onUpdateClick(noteToModify) {
    this.setState({ updateDialogOpenStatus: true, noteToModify });
  }

  onDeleteClick(noteToModify) {
    this.setState({ deleteDialogOpenStatus: true, noteToModify });
  }

  onCreateSuccess(note) {
    this.setState(({ notes: prevNotes }) => {
      return { notes: [...prevNotes, note] };
    });
  }

  onUpdateSuccess(note) {
    this.setState(prevState => {
      return {
        notes: prevState.notes.map(prevNote =>
          prevNote.id === note.id ? note : prevNote
        ),
        noteToModify: null
      };
    });
  }

  onDeleteSuccess(noteId) {
    this.setState(prevState => {
      return {
        notes: prevState.notes.filter(prevNote => prevNote.id !== noteId),
        noteToModify: null
      };
    });
  }

  setSort(sort) {
    this.setState(
      prevState => {
        const { sort: prevSort } = prevState;
        return { sort: { ...prevSort, ...sort } };
      },
      () => this.fetchSalesGroupNotes()
    );
  }

  setFilter(filter) {
    this.setState(
      prevState => {
        const { filter: prevFilter, pagination: prevPagination } = prevState;
        return {
          filter: { ...prevFilter, ...filter },
          pagination: { ...prevPagination, page: 1 }
        };
      },
      () => this.fetchSalesGroupNotes()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchSalesGroupNotes()
    );
  }

  async fetchSalesGroupNotes() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: notes, meta }
      } = await SalesGroupsNotesApi.getSalesGroupNotes(this.groupId, {
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        notes,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_SHOPPING_GROUPS_LIST);
        }

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  renderActions() {
    const { pagination } = this.state;
    const { isUserAssignToGroupClient } = this.props;

    return (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        width={1}
      >
        <Box>
          <IconButton
            className='create-icon'
            onClick={this.onCreateClick}
            icon={<AddIcon fontSize='large' />}
            disabled={
              !(
                this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) ||
                isUserAssignToGroupClient
              )
            }
            tooltipMsg='You are not assign to this client'
            alt='create'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const {
      notes,
      sort,
      filter,
      filterValidation,
      createDialogOpenStatus,
      updateDialogOpenStatus,
      deleteDialogOpenStatus,
      noteToModify
    } = this.state;
    const { isUserAssignToGroupClient } = this.props;
    const sortOptions = [
      { key: 'creator_name', value: 'Creator name' },
      { key: 'created_at', value: 'Created at' }
    ];

    return (
      <>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Box
                display='flex'
                flexDirection={{ xs: 'column', xl: 'row' }}
                alignItems='center'
              >
                <Box width={1}>
                  <SalesGroupNotesFilter
                    filter={filter}
                    validation={filterValidation}
                    setFilter={this.setFilter}
                  />
                </Box>
                {this.renderActions()}
                <Box
                  width={1}
                  display='flex'
                  maxWidth='300px'
                  marginLeft={{ xl: '1rem' }}
                >
                  <Select
                    name='sort_field'
                    value={sort.sort_field}
                    options={formatOptions(sortOptions, 'key', 'value', true)}
                    emptyValueText={t('None')}
                    label={t('Sort by')}
                    style={{ input: { height: '48px' } }}
                    margin='none'
                    onChange={({ target: { name, value } }) =>
                      this.setSort({ [name]: value })
                    }
                  />
                  <TableSortLabel
                    active
                    direction={sort.sort_order}
                    onClick={() =>
                      this.setSort({
                        sort_order: sort.sort_order === 'asc' ? 'desc' : 'asc'
                      })
                    }
                    classes={{ icon: this.props.classes.icon }}
                  />
                </Box>
              </Box>
              {notes.map(note => (
                <SalesGroupNote
                  note={note}
                  key={note.id}
                  isUserAssignToGroupClient={isUserAssignToGroupClient}
                  onUpdateClick={this.onUpdateClick}
                  onDeleteClick={this.onDeleteClick}
                />
              ))}
              {this.renderActions()}
            </Paper>
          </Grid>
        </Grid>
        {createDialogOpenStatus && (
          <SalesGroupNoteCreateDialog
            groupId={this.groupId}
            onSuccess={this.onCreateSuccess}
            onClose={this.onDialogClose}
          />
        )}
        {updateDialogOpenStatus && (
          <SalesGroupNoteUpdateDialog
            groupId={this.groupId}
            note={noteToModify}
            onSuccess={this.onUpdateSuccess}
            onClose={this.onDialogClose}
          />
        )}
        {deleteDialogOpenStatus && (
          <SalesGroupNoteDeleteDialog
            groupId={this.groupId}
            noteId={noteToModify.id}
            onSuccess={this.onDeleteSuccess}
            onClose={this.onDialogClose}
          />
        )}
      </>
    );
  }
}

SalesGroupDetailsNotes.propTypes = {
  isUserAssignToGroupClient: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(withStyles(useStyles)(SalesGroupDetailsNotes));
