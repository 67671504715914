import { Component } from 'react';
import PropTypes from 'prop-types';
import insertPathParams from 'api/utils/insertPathParams';
import { Box, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import DashboardListItem from 'modules/Dashboard/component/Layout/ListItem';
import DashboardList from 'modules/Dashboard/component/Layout/List';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';

class DashboardPriorityProducts extends Component {
  static contextType = AuthContext;

  static getInformationBox(product) {
    const { sku, price_sum, quantity_sum } = product;

    return (
      <Box display='flex' flexDirection='column'>
        <Typography style={{ fontWeight: '600' }}>
          {sku} - {quantity_sum} szt - {price_sum} PLN
        </Typography>
      </Box>
    );
  }

  static getRedirectLink({ id }) {
    return insertPathParams(ROUTE_PRODUCTS_DETAILS, { id });
  }

  static renderItem(product) {
    const informationBox = DashboardPriorityProducts.getInformationBox(product);
    const redirectLink = DashboardPriorityProducts.getRedirectLink(product);

    return (
      <DashboardListItem
        key={product.id}
        informationBox={informationBox}
        redirectLink={redirectLink}
      />
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      products: undefined,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const pathParams = {
      type: 'all',
      priority: 1,
      per_page: 10,
      sort_field: 'price_sum'
    };

    try {
      const {
        data: { data: products }
      } = await StatisticsApi.getPriorityProductsSales(pathParams);

      this.setState({ products, loading: false });
    } catch (err) {
      this.setState({ products: undefined, loading: false });
      this.props.handleError(err);
    }
  }

  render() {
    const { loading, products } = this.state;

    if (loading) return <Loader />;

    return (
      <DashboardList
        data={products}
        renderItem={DashboardPriorityProducts.renderItem}
      />
    );
  }
}

DashboardPriorityProducts.defaultProps = {
  handleError: null
};

DashboardPriorityProducts.propTypes = {
  handleError: PropTypes.func
};

export default DashboardPriorityProducts;
