import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import AppsIcon from '@material-ui/icons/Apps';
import {
  ROUTE_TAXES_LIST,
  permMap as texesPermMap
} from 'routing/routes/Taxes';
import {
  ROUTE_POSTAL_CODES_LIST,
  permMap as postalPermMap
} from 'routing/routes/PostalCodes';
import {
  permMap as departmentsPermMap,
  ROUTE_DEPARTMENTS_LIST
} from 'routing/routes/Departments';
import {
  permMap as usersPermMap,
  ROUTE_USERS_LIST
} from 'routing/routes/Users';
import { ROUTE_PROFILE } from 'routing/routes/Profile';
import {
  ROUTE_CURRENCIES_LIST,
  permMap as currenciesPermMap
} from 'routing/routes/Currencies';

import {
  ROUTE_PAYMENT_METHODS_LIST,
  permMap as paymentMethodsPermMap
} from 'routing/routes/PaymentMethods';

const menu = [
  {
    key: 'menu-administration',
    label: 'Administration and configuration',
    icon: <AppsIcon />,
    path: '/',
    children: [
      {
        key: 'menu-profile',
        label: 'My Profile',
        icon: <PersonIcon />,
        path: ROUTE_PROFILE,
        permissions: []
      },
      {
        key: 'menu-users',
        label: 'Users',
        icon: <ListIcon />,
        path: ROUTE_USERS_LIST,
        permissions: usersPermMap[ROUTE_USERS_LIST]
      },
      {
        key: 'menu-departments',
        label: 'Departments',
        icon: <ListIcon />,
        path: ROUTE_DEPARTMENTS_LIST,
        permissions: departmentsPermMap[ROUTE_DEPARTMENTS_LIST]
      },
      {
        key: 'menu-taxes-list',
        label: 'Taxes types',
        icon: <ListIcon />,
        path: ROUTE_TAXES_LIST,
        permissions: texesPermMap[ROUTE_TAXES_LIST]
      },
      {
        key: 'menu-currencies-list',
        label: 'Currencies',
        icon: <ListIcon />,
        path: ROUTE_CURRENCIES_LIST,
        permissions: currenciesPermMap[ROUTE_CURRENCIES_LIST]
      },
      {
        key: 'menu-postalcodes-nested',
        label: 'Postal Codes',
        icon: <ListIcon />,
        path: ROUTE_POSTAL_CODES_LIST,
        permissions: postalPermMap[ROUTE_POSTAL_CODES_LIST]
      },
      {
        key: 'menu-payment-methods-nested',
        label: 'Payment methods',
        icon: <ListIcon />,
        path: ROUTE_PAYMENT_METHODS_LIST,
        permissions: paymentMethodsPermMap[ROUTE_PAYMENT_METHODS_LIST]
      }
    ]
  }
];

export default menu;
