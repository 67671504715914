import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { Grid, Paper } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_CLIENTS_BONUSES_LIST } from 'routing/routes/ClientsBonuses';
import ClientsBonusesApi from 'api/connections/ClientsBonuses/ClientsBonusesApi';
import ClientsContractsApi from 'api/connections/ClientsContracts/ClientsContractsApi';
import ClientsBonusesDetailsDataSets from 'modules/ClientsBonuses/component/Details/DataSets';
import ClientsBonusesDetailsContract from 'modules/ClientsBonuses/component/Details/Contract';
import ClientsBonusesDetailsHistory from 'modules/ClientsBonuses/component/Details/History';

class ClientsBonusesDetails extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.bonusId = parseInt(props.match.params.id, 10);

    this.state = {
      bonus: undefined,
      contract: undefined
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    if (!this.bonusId) {
      this.props.history.push(ROUTE_CLIENTS_BONUSES_LIST);
    }
    this.setCurrentPage('Client bonus details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_CLIENTS_BONUSES_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchData() {
    try {
      const {
        data: { data: bonus }
      } = await ClientsBonusesApi.getBonus(this.bonusId);

      const { target_id } = bonus;

      const {
        data: { data: contract }
      } = await ClientsContractsApi.getClientContract(target_id);

      this.setState({ bonus, contract });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { bonus, contract } = this.state;

    if (!bonus || !contract) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3} justify='center'>
            <Grid item xs={12}>
              <Paper className='p1'>
                <ClientsBonusesDetailsDataSets
                  bonus={bonus}
                  fetchBonusData={this.fetchData}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className='p1'>
                <ClientsBonusesDetailsHistory bonus={bonus} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <ClientsBonusesDetailsContract contract={contract} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ClientsBonusesDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ClientsBonusesDetails);
