import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import PromotionForm from 'modules/Promotions/component/Form';
import { withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import PromotionsApi from 'api/connections/Promotions/PromotionsApi';
import { ROUTE_PROMOTIONS_LIST } from 'routing/routes/Promotions';
import Loader from 'modules/Layout/component/Loader';

class PromotionUpdate extends Component {
  constructor(props) {
    super(props);

    this.promotionId = parseInt(props.match.params.id, 10);

    this.state = {
      promotion: null
    };
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Update promotion');
    this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: { data: promotion }
      } = await PromotionsApi.getPromotion(this.promotionId);

      this.setState({ promotion });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setAlert(err.getPayload().message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_PROMOTIONS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { promotion } = this.state;

    if (!promotion) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={7}>
          <Paper className='p1'>
            <PromotionForm
              promotion={promotion}
              setAlert={this.props.contextMethods.setAlert}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

PromotionUpdate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired
};

export default withRouter(PromotionUpdate);
