import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';

class ProductsTopSellersTable extends Component {
  static contextType = AuthContext;

  getCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false
      },
      {
        property: 'category_name',
        label: 'Category',
        sortable: false
      },
      {
        property: 'bought_quantity',
        label: 'Quantity',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: true,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: ({ id }) => {
              this.props.history.push(
                insertPathParams(ROUTE_PRODUCTS_DETAILS, { id })
              );
            }
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          }
        }
      }
    ];
  }

  render() {
    const { topSellers, loading, bodyText, sort, setSort } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={topSellers}
          loading={loading}
          bodyText={bodyText}
          sort={sort}
          setSort={setSort}
          actions
        />
      </>
    );
  }
}

ProductsTopSellersTable.defaultProps = {
  topSellers: [],
  bodyText: null
};

ProductsTopSellersTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  topSellers: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  bodyText: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ProductsTopSellersTable);
