import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import PaymentMethodsApi from 'api/connections/PaymentMethods/PaymentMethodsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

const PaymentMethodDeleteModal = props => {
  const {
    paymentMethod: { id: paymentMethodId, name: paymentMethodName },
    onClose,
    onSuccess,
    handleError,
    setAlert
  } = props;

  const onApprove = async () => {
    try {
      await PaymentMethodsApi.deletePaymentMethod(paymentMethodId);
      setAlert({
        value: t('Payment method deleted'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      handleError(err);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete payment method <%=paymentMethodName%>?',
        { paymentMethodName }
      )}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

PaymentMethodDeleteModal.propTypes = {
  paymentMethod: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default PaymentMethodDeleteModal;
