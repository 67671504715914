import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';
import StatisticsExportApi from 'api/connections/Statistics/StatisticsExportApi';
import { downloadFile } from 'modules/Shared/utils/file';
import IconButton from 'modules/Layout/component/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import StatisticsTraderVisitsReportTable from 'modules/Statistics/TradersVisitsReport/component/List/Table';
import StatisticsTraderVisitsReportFilter from 'modules/Statistics/TradersVisitsReport/component/List/Filter';

class StatisticsTraderVisitsReport extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const { user, hasPermission } = context;

    this.state = {
      filter: {
        department_id: !hasPermission([DEPARTMENTS_LIST])
          ? user.departments[0]?.id
          : '',
        date_from: '',
        date_to: ''
      },
      filterValidation: {},
      statistics: [],
      departments: !hasPermission([DEPARTMENTS_LIST]) ? user.departments : [],
      loadingDepartments: false,
      loadingStatistics: false
    };

    this.setFilter = this.setFilter.bind(this);
    this.fetchStatistics = this.fetchStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportStatistics = this.exportStatistics.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Traders visits report');

    if (this.context.hasPermission([DEPARTMENTS_LIST])) {
      return this.fetchDepartments();
    }

    this.fetchStatistics();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setFilter(filter) {
    this.setState({ filter: { ...this.state.filter, ...filter } }, () =>
      this.fetchStatistics()
    );
  }

  setBodyText() {
    const { department_id, date_from, date_to } = this.state.filter;

    if (this.context.hasPermission([DEPARTMENTS_LIST]) && !department_id) {
      return 'Pick department';
    }

    if (!date_from || !date_to) {
      return 'Pick dates';
    }

    return null;
  }

  async fetchDepartments() {
    try {
      this.setState({ loadingDepartments: true });

      const {
        data: { data: departments }
      } = await DepartmentsApi.getDepartments({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        departments,
        loadingDepartments: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchStatistics() {
    const { filter } = this.state;

    if (!filter.date_from || !filter.date_to || !filter.department_id) {
      return null;
    }

    try {
      this.setState({ loadingStatistics: true });

      const {
        data: { data: statistics }
      } = await StatisticsApi.getTradersResults({
        ...filter
      });

      this.setState({
        statistics,
        loadingStatistics: false
      });
    } catch (err) {
      this.setState({ statistics: [], loadingStatistics: false });
      this.handleError(err);
    }
  }

  async exportStatistics() {
    try {
      const { filter } = this.state;

      const res = await StatisticsExportApi.exportTraderVisitsReport(filter);

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { date_from, date_to, department_id } = this.state.filter;

    const isValid = date_from && date_to && department_id;

    return (
      <Box display='flex' justifyContent='flex-start'>
        <IconButton
          onClick={this.exportStatistics}
          icon={
            <GetAppOutlinedIcon
              color={isValid ? 'primary' : 'disabled'}
              fontSize='large'
            />
          }
          alt='export table'
          disabled={!isValid}
          tooltipMsg={this.setBodyText()}
        />
      </Box>
    );
  }

  render() {
    const {
      statistics,
      countries,
      departments,
      filter,
      filterValidation,
      loadingCountries,
      loadingDepartments,
      loadingStatistics
    } = this.state;

    return (
      <div>
        <StatisticsTraderVisitsReportFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          countries={countries}
          departments={departments}
          loadingCountries={loadingCountries}
          loadingDepartments={loadingDepartments}
        />

        {this.renderActions()}
        <StatisticsTraderVisitsReportTable
          statistics={statistics}
          countries={countries}
          loading={loadingStatistics}
          bodyText={this.setBodyText()}
        />
        {this.renderActions()}
      </div>
    );
  }
}

StatisticsTraderVisitsReport.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default StatisticsTraderVisitsReport;
