import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box, Paper, TableSortLabel, Typography } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import t from 'translate/translate';
import { ROUTE_VISITS_LIST } from 'routing/routes/Visits';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import VisitNotesFilter from 'modules/Visits/components/Details/Notes/List/Filter';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import VisitNote from 'modules/Visits/components/Details/Notes/List/ListItem';
import Authorize from 'modules/Auth/component/Authorize';
import { VISITS_UPDATE } from 'api/auth/permissions/Visits';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import VisitNoteCreateDialog from 'modules/Visits/components/Details/Notes/Create/Dialog';
import PageLoader from 'modules/Layout/component/PageLoader';
import { isEmpty } from 'lodash/lang';

const useStyles = {
  icon: {
    fontSize: '1.8rem'
  }
};

class VisitNotesView extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      notes: [],
      sort: {
        sort_field: '',
        sort_order: 'asc'
      },
      filter: {
        creator_name: '',
        created_at_from: null,
        created_at_to: null
      },
      filterValidation: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      createDialogOpenStatus: false,
      isLoading: true
    };

    this.setAlert = props.setAlert;

    this.setSort = this.setSort.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.onCreateSuccess = this.onCreateSuccess.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
  }

  componentDidMount() {
    this.fetchVisitNotes();
  }

  onDialogClose() {
    this.setState({
      createDialogOpenStatus: false
    });
  }

  onCreateClick() {
    this.setState({ createDialogOpenStatus: true });
  }

  onCreateSuccess(note) {
    this.setState(({ notes: prevNotes }) => {
      return { notes: [...prevNotes, note] };
    });
  }

  setSort(sort) {
    this.setState(
      prevState => {
        const { sort: prevSort } = prevState;
        return { sort: { ...prevSort, ...sort } };
      },
      () => this.fetchVisitNotes()
    );
  }

  setFilter(filter) {
    this.setState(
      prevState => {
        const { filter: prevFilter, pagination: prevPagination } = prevState;
        return {
          filter: { ...prevFilter, ...filter },
          pagination: { ...prevPagination, page: 1 }
        };
      },
      () => this.fetchVisitNotes()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchVisitNotes()
    );
  }

  async fetchVisitNotes() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    const {
      visit: { id: visitId }
    } = this.props;

    try {
      this.setState({ isLoading: true });
      const {
        data: { data: notes, meta }
      } = await VisitsApi.getVisitNotes(visitId, {
        ...sort,
        ...filter,
        per_page,
        page
      });
      this.setState({
        notes,
        isLoading: false,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      this.setState({ isLoading: false });
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_VISITS_LIST);
        }

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  render() {
    const {
      notes,
      sort,
      filter,
      filterValidation,
      pagination,
      createDialogOpenStatus,
      isLoading
    } = this.state;
    const { user, hasRole } = this.context;
    const {
      visit: { id: visitId, user_id }
    } = this.props;
    const sortOptions = [
      { key: 'creator_name', value: 'Creator name' },
      { key: 'created_at', value: 'Created at' }
    ];

    return (
      <Paper className='p1'>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', xl: 'row' }}
          alignItems='center'
        >
          <Box width={1} mb={1.5}>
            <VisitNotesFilter
              filter={filter}
              validation={filterValidation}
              setFilter={this.setFilter}
            />
          </Box>
          <Box
            display='flex'
            width={1}
            maxWidth='300px'
            marginLeft={{ xl: '1rem' }}
          >
            <Select
              name='sort_field'
              value={sort.sort_field}
              options={formatOptions(sortOptions, 'key', 'value', true)}
              emptyValueText={t('None')}
              label={t('Sort by')}
              style={{
                input: { height: '48px' }
              }}
              margin='none'
              onChange={({ target: { name, value } }) =>
                this.setSort({ [name]: value })
              }
            />
            <TableSortLabel
              active
              direction={sort.sort_order}
              onClick={() =>
                this.setSort({
                  sort_order: sort.sort_order === 'asc' ? 'desc' : 'asc'
                })
              }
              classes={{ icon: this.props.classes.icon }}
            />
          </Box>
        </Box>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {!isEmpty(notes) ? (
              notes.map(note => <VisitNote note={note} key={note.id} />)
            ) : (
              <Typography
                variant='subtitle1'
                style={{ textAlign: 'center', marginTop: '20px' }}
              >
                {t('No data')}
              </Typography>
            )}
          </>
        )}

        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>
            <Authorize permissions={[VISITS_UPDATE]}>
              <IconButton
                className='create-icon'
                onClick={this.onCreateClick}
                icon={<AddIcon fontSize='large' />}
                disabled={
                  !(
                    hasRole([SUPER_ADMIN, ADMIN, DIRECTOR]) ||
                    user.id === user_id
                  )
                }
                tooltipMsg='You are not assign to this visit'
                alt='create'
              />
            </Authorize>
          </Box>
          <Pagination
            pagination={pagination}
            setPagination={this.setPagination}
            rowsPerPageOptions={[5, 15, 30, 100]}
          />
        </Box>
        {createDialogOpenStatus && (
          <VisitNoteCreateDialog
            visitId={visitId}
            onSuccess={this.onCreateSuccess}
            onClose={this.onDialogClose}
          />
        )}
      </Paper>
    );
  }
}

VisitNotesView.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired
  }).isRequired,
  visit: PropTypes.shape({ id: PropTypes.number, user_id: PropTypes.number })
    .isRequired
};

export default withRouter(withStyles(useStyles)(VisitNotesView));
