import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class WholesalersMappingsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.getWholesalersMappingsPath = 'wholesalers/mappings';
    this.getWholesalersClientsMappingsPath = 'wholesalers/client-mappings';
    this.createWholesalerMappingPath = 'wholesalers/:id/mappings';
    this.createWholesalerClientMappingPath = 'wholesalers/:id/client-mappings';
    this.wholesalerMappingPath =
      'wholesalers/:wholesalerId/mappings/:mappingId';
    this.wholesalerClientMappingPath =
      'wholesalers/:wholesalerId/client-mappings/:mappingId';
  }

  getWholesalersMappings(pathParams) {
    return this.get(this.getWholesalersMappingsPath, pathParams);
  }

  getWholesalersClientsMappings(pathParams) {
    return this.get(this.getWholesalersClientsMappingsPath, pathParams);
  }

  createWholesalerMapping(id, payload) {
    return this.post(
      insertPathParams(this.createWholesalerMappingPath, { id }),
      payload
    );
  }

  createWholesalerClientMapping(id, payload) {
    return this.post(
      insertPathParams(this.createWholesalerClientMappingPath, { id }),
      payload
    );
  }

  updateWholesalerMapping(ids, payload) {
    return this.put(insertPathParams(this.wholesalerMappingPath, ids), payload);
  }

  updateWholesalerClientMapping(ids, payload) {
    return this.put(
      insertPathParams(this.wholesalerClientMappingPath, ids),
      payload
    );
  }

  deleteWholesalerMapping(ids) {
    return this.delete(insertPathParams(this.wholesalerMappingPath, ids));
  }

  deleteWholesalerClientMapping(ids) {
    return this.delete(insertPathParams(this.wholesalerClientMappingPath, ids));
  }
}

export default new WholesalersMappingsApi();
