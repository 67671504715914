import { Route } from 'react-router-dom';
import { PRICE_LISTS_SHOW } from 'api/auth/permissions/PriceLists';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import PriceListsList from 'modules/PriceLists/view/List';
import PriceListsDetails from 'modules/PriceLists/view/Details/DetailsWrapper';

export const ROUTE_PRICE_LISTS_LIST = '/price_lists';
export const ROUTE_PRICE_LISTS_DETAILS = '/price_lists/details/:id';

export const permMap = {
  [ROUTE_PRICE_LISTS_LIST]: [PRICE_LISTS_SHOW],
  [ROUTE_PRICE_LISTS_DETAILS]: [PRICE_LISTS_SHOW]
};

const routes = {
  protected: [
    <Route key='price-lists-list' exact path={ROUTE_PRICE_LISTS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_PRICE_LISTS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <PriceListsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='price-lists-details' exact path={ROUTE_PRICE_LISTS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_PRICE_LISTS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <PriceListsDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
