import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import MuiPickersProvider from 'modules/Layout/component/Date/MuiPickersProvider';
import t from 'translate/translate';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import addYears from 'date-fns/addYears';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import IconButton from 'modules/Layout/component/IconButton';
import { Box } from '@material-ui/core';

export const API_DATE_FORMAT = 'dd.MM.yyyy';

const parseDateString = dateStr => {
  return parse(dateStr, API_DATE_FORMAT, new Date());
};

const DatePicker = props => {
  const {
    name,
    label,
    value,
    initValueNow,
    onChange,
    errorStatus,
    errorText,
    fullWidth,
    minDate,
    maxDate
  } = props;

  const [selectedDate, handleDateChange] = useState(() => {
    if (initValueNow) return new Date();
    if (value) return parseDateString(value);
    return null;
  });

  // TODO remove on Timer refactoring
  useEffect(() => {
    if (value) {
      handleDateChange(parseDateString(value));
    } else {
      handleDateChange(null);
    }
  }, [value]);

  const OnDateChange = date => {
    handleDateChange(date);
    const formattedDate = date ? format(date, API_DATE_FORMAT) : null;
    onChange({
      target: {
        name,
        value: formattedDate
      }
    });
  };

  const onClear = () => {
    OnDateChange(null);
  };

  return (
    <MuiPickersProvider>
      <Box width={1} display='flex' flexDirection='row' alignItems='center'>
        <MuiDatePicker
          margin='normal'
          inputVariant='outlined'
          variant='inline'
          autoOk
          fullWidth={fullWidth}
          invalidDateMessage={t(
            `Wrong Date format, correct one is ${API_DATE_FORMAT}`
          )}
          label={t(label)}
          value={selectedDate}
          onChange={OnDateChange}
          error={errorStatus}
          helperText={errorStatus && errorText}
          minDate={minDate}
          maxDate={maxDate}
        />
        <IconButton
          className='delete-icon'
          onClick={onClear}
          icon={<DeleteForeverIcon />}
          disabled={!value}
          alt='clear'
          style={{ marginTop: '6px' }}
        />
      </Box>
    </MuiPickersProvider>
  );
};

DatePicker.defaultProps = {
  value: null,
  initValueNow: false,
  errorStatus: false,
  errorText: '',
  fullWidth: true,
  minDate: addYears(new Date(), -20),
  maxDate: addYears(new Date(), 20)
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  initValueNow: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errorStatus: PropTypes.bool,
  errorText: PropTypes.string,
  fullWidth: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
};

export default DatePicker;
