import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import StatisticsApi from 'api/connections/Statistics/StatisticsApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import StatisticsUsersOrdersVisitsTable from 'modules/Statistics/UsersOrdersVisits/component/List/Table';
import StatisticsUsersOrdersVisitsFilter from 'modules/Statistics/UsersOrdersVisits/component/List/Filter';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';
import StatisticsExportApi from 'api/connections/Statistics/StatisticsExportApi';
import { downloadFile } from 'modules/Shared/utils/file';
import IconButton from 'modules/Layout/component/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

class StatisticsUsersOrdersVisitsList extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const { user, hasPermission } = context;

    this.state = {
      filter: {
        type: 'all',
        period: 'year',
        role: 'trader',
        department_id: user.departments[0]?.id || 1,
        country_id: ''
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      statistics: [],
      countries: [],
      departments: !hasPermission([DEPARTMENTS_LIST]) ? user.departments : [],
      loading: false
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchStatistics = this.fetchStatistics.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportStatistics = this.exportStatistics.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage(
      'Statistics users orders during visits'
    );
    if (
      this.context.hasPermission([
        POSTAL_CODES_COUNTRIES_SHOW,
        DEPARTMENTS_LIST
      ])
    ) {
      return this.fetchData();
    }
    this.fetchStatistics();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setSort(sort) {
    this.setState({ sort: { ...this.state.sort, ...sort } }, () =>
      this.fetchStatistics()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination }
      },
      () => this.fetchStatistics()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchStatistics()
    );
  }

  async fetchData() {
    try {
      const [
        {
          data: { data: countries }
        },
        {
          data: { data: departments }
        }
      ] = await Promise.all([
        PostalCodesApi.getCountries({
          per_page: Number.MAX_SAFE_INTEGER
        }),
        DepartmentsApi.getDepartments({ per_page: Number.MAX_SAFE_INTEGER })
      ]);

      this.setState({
        countries,
        departments,
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchStatistics() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    if (!filter.year) return null;
    if (filter.period === 'quarter' && !filter.quarter) return null;
    if (filter.period === 'month' && !filter.month) return null;

    try {
      this.setState({ loading: true });

      const {
        data: { data: statistics, meta }
      } = await StatisticsApi.getUsersOrdersVisits({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        statistics,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportStatistics() {
    try {
      const { filter, sort } = this.state;

      const res = await StatisticsExportApi.exportUsersOrdersVisitsRanking({
        ...filter,
        ...sort
      });

      downloadFile(res);
    } catch (err) {
      if (err instanceof ApiError) {
        this.props.contextMethods.setAlert(err.getPayload().message);
      }
    }
  }

  renderActions() {
    const {
      pagination,
      filter: { year }
    } = this.state;

    return (
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <IconButton
            onClick={this.exportStatistics}
            icon={
              <GetAppOutlinedIcon
                color={year ? 'primary' : 'disabled'}
                fontSize='large'
              />
            }
            alt='export table'
            disabled={!year}
            tooltipMsg='Pick period first'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const {
      statistics,
      countries,
      departments,
      filter,
      sort,
      loading,
      filterValidation
    } = this.state;

    return (
      <div>
        <StatisticsUsersOrdersVisitsFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          countries={countries}
          departments={departments}
        />

        {this.renderActions()}
        <StatisticsUsersOrdersVisitsTable
          sort={sort}
          setSort={this.setSort}
          statistics={statistics}
          countries={countries}
          loading={loading}
          bodyText={!filter.year ? 'Pick period first' : null}
        />
        {this.renderActions()}
      </div>
    );
  }
}

StatisticsUsersOrdersVisitsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default StatisticsUsersOrdersVisitsList;
