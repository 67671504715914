import { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';

const margin = {
  top: 10,
  right: 10,
  bottom: 10,
  left: 70
};

const BarChart = props => {
  const {
    data,
    valuePropertyName,
    title,
    onDrillDown,
    nameSuffix,
    suffix,
    prefix
  } = props;

  const width = props.width + margin.left + margin.right;
  const height = props.width + margin.top + margin.bottom;

  const colorScale = d3
    .scaleSequential()
    .interpolator(d3.interpolateCool)
    .domain([0, data.length]);

  const higherValue = Math.max(...data.map(d => d[valuePropertyName]));

  const renderValue = value => {
    if (suffix) {
      return `${value} ${t(suffix)}`;
    }

    if (prefix) {
      return `${t(prefix)} ${value}`;
    }

    return valuePropertyName === 'price_sum' ? `${value} PLN` : value;
  };

  const drawChart = () => {
    d3.select(`#pie-container-${valuePropertyName}-${nameSuffix}`)
      .select('svg')
      .remove();

    const div = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip-donut')
      .style('opacity', 0);

    const svg = d3
      .select(`#pie-container-${valuePropertyName}-${nameSuffix}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    const x = d3
      .scaleBand()
      .domain(d3.range(data.length))
      .range([margin.left, width - margin.right])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, higherValue])
      .range([height - margin.bottom, margin.top]);

    const yAxis = g => {
      g.attr('transform', `translate(${margin.left}, 0)`).call(
        d3.axisLeft(y).ticks(null, data.format)
      );
    };

    svg
      .append('g')
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('fill', (_, i) => colorScale(i))
      .attr('x', (d, i) => x(i))
      .attr('y', d => y(d[valuePropertyName]))
      .attr('height', d => y(0) - y(d[valuePropertyName]))
      .attr('width', x.bandwidth())
      .on('click', (_, d) => {
        div.transition().duration('50').style('opacity', 0);
        onDrillDown(d);
      })
      .on('mouseover', function (_, d) {
        d3.select(this).transition().duration('50').attr('opacity', '.85');
        div.transition().duration(50).style('opacity', 1);
        div.html(
          `<strong>${renderValue(d[valuePropertyName])}</strong> ${d.name}`
        );
      })
      .on('mousemove', event => {
        div
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 15}px`);
      })
      .on('mouseout', function () {
        d3.select(this).transition().duration('50').attr('opacity', '1');
        div.transition().duration('50').style('opacity', 0);
      });

    const legend = d3.select(`#legend-container-${nameSuffix}`);

    legend.select('g').remove();
    legend
      .append('g')
      .attr('class', 'legend')
      .selectAll('text')
      .data(data)
      .enter()
      .append('div')
      .attr('class', 'legend-item');

    legend
      .selectAll('.legend-item')
      .append('div')
      .attr('class', 'legend-dot')
      .style('background', (_, i) => {
        return colorScale(i);
      });

    legend
      .selectAll('.legend-item')
      .append('text')
      .text(d => d.name);

    svg.append('g').call(yAxis);
    svg.node();
  };

  useEffect(() => {
    drawChart();
  }, [data, width]);

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='body1'>{title}</Typography>
      <div id={`pie-container-${valuePropertyName}-${nameSuffix}`} />
    </Box>
  );
};

BarChart.defaultProps = {
  prefix: '',
  suffix: ''
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  valuePropertyName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onDrillDown: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  nameSuffix: PropTypes.number.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string
};

export default BarChart;
