import { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  KEY_ACCOUNT_MANAGER,
  PRODUCT_MANAGER,
  SUPER_ADMIN,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import DashboardBoxWrapper from 'modules/Dashboard/component/Layout/DashboardBoxWrapper';
import DashboardVisits from 'modules/Dashboard/component/Visits';
import DashboardTargets from 'modules/Dashboard/component/Targets';
import DashboardLongLostClients from 'modules/Dashboard/component/LongLostClients';
import DashboardAdminFailedImports from 'modules/Dashboard/component/AdminFailedImports';
import DashboardAdminOutstandingImports from 'modules/Dashboard/component/AdminOutstandingImports';
import DashboardTopSellers from 'modules/Dashboard/component/TopSellers';
import DashboardPriorityProducts from 'modules/Dashboard/component/PriorityProducts';

class Dashboard extends Component {
  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Dashboard');
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <DashboardBoxWrapper
              title="Today's visits"
              roles={[DEPARTMENT_MANAGER, TRADER, TELEMARKETER]}
              component={<DashboardVisits />}
            />
            <DashboardBoxWrapper
              title='Targets'
              roles={[
                DIRECTOR,
                DEPARTMENT_MANAGER,
                TRADER,
                TELEMARKETER,
                KEY_ACCOUNT_MANAGER
              ]}
              component={<DashboardTargets />}
            />
            <DashboardBoxWrapper
              title='Imports needing attention'
              roles={[SUPER_ADMIN, ADMIN, DIRECTOR, DEPARTMENT_MANAGER]}
              component={<DashboardAdminFailedImports />}
            />
            <DashboardBoxWrapper
              title='Top sellers not ordered for a long time'
              roles={[
                SUPER_ADMIN,
                ADMIN,
                DIRECTOR,
                DEPARTMENT_MANAGER,
                TRADER,
                TELEMARKETER,
                KEY_ACCOUNT_MANAGER,
                PRODUCT_MANAGER
              ]}
              component={<DashboardTopSellers />}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <DashboardBoxWrapper
              title='Long-lost clients'
              roles={[
                DIRECTOR,
                DEPARTMENT_MANAGER,
                TRADER,
                TELEMARKETER,
                KEY_ACCOUNT_MANAGER
              ]}
              component={<DashboardLongLostClients />}
            />
            <DashboardBoxWrapper
              title='Priority products with lowest sales'
              roles={[
                SUPER_ADMIN,
                ADMIN,
                DIRECTOR,
                DEPARTMENT_MANAGER,
                TRADER,
                TELEMARKETER,
                KEY_ACCOUNT_MANAGER,
                PRODUCT_MANAGER
              ]}
              component={<DashboardPriorityProducts />}
            />
            <DashboardBoxWrapper
              title='Outstanding imports'
              roles={[SUPER_ADMIN, ADMIN, DIRECTOR, DEPARTMENT_MANAGER]}
              component={<DashboardAdminOutstandingImports />}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Dashboard.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default Dashboard;
