import { Route } from 'react-router-dom';
import { IMPORTS_SHOW } from 'api/auth/permissions/Imports';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import ImportsList from 'modules/Imports/view/List';

export const ROUTE_IMPORTS_CLIENTS = '/imports_clients';
export const ROUTE_IMPORTS_PRODUCTS = '/imports_products';
export const ROUTE_IMPORTS_PRICE_LISTS = '/imports_price_lists';
export const ROUTE_IMPORTS_PRODUCT_BATCHES = '/imports_product_batches';

export const permMap = {
  [ROUTE_IMPORTS_CLIENTS]: [IMPORTS_SHOW],
  [ROUTE_IMPORTS_PRODUCTS]: [IMPORTS_SHOW],
  [ROUTE_IMPORTS_PRICE_LISTS]: [IMPORTS_SHOW],
  [ROUTE_IMPORTS_PRODUCT_BATCHES]: [IMPORTS_SHOW]
};

const routes = {
  protected: [
    <Route key='imports-clients' exact path={ROUTE_IMPORTS_CLIENTS}>
      <Authorize redirect permissions={permMap[ROUTE_IMPORTS_CLIENTS]}>
        <BasicContextConsumerWrapper
          component={fn => <ImportsList contextMethods={fn} type='clients' />}
        />
      </Authorize>
    </Route>,
    <Route key='imports-products' exact path={ROUTE_IMPORTS_PRODUCTS}>
      <Authorize redirect permissions={permMap[ROUTE_IMPORTS_PRODUCTS]}>
        <BasicContextConsumerWrapper
          component={fn => <ImportsList contextMethods={fn} type='products' />}
        />
      </Authorize>
    </Route>,
    <Route key='imports-price-lists' exact path={ROUTE_IMPORTS_PRICE_LISTS}>
      <Authorize redirect permissions={permMap[ROUTE_IMPORTS_PRICE_LISTS]}>
        <BasicContextConsumerWrapper
          component={fn => (
            <ImportsList contextMethods={fn} type='price_lists' />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='imports-product-batches'
      exact
      path={ROUTE_IMPORTS_PRODUCT_BATCHES}
    >
      <Authorize redirect permissions={permMap[ROUTE_IMPORTS_PRODUCT_BATCHES]}>
        <BasicContextConsumerWrapper
          component={fn => (
            <ImportsList contextMethods={fn} type='product_batches' />
          )}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
