export const useStyles = theme => ({
  mainWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  columnWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  columnTitle: {
    marginLeft: theme.spacing(2)
  },
  column: {
    border: '1px solid #bdbdbd',
    borderRadius: '10px',
    height: '100%',
    margin: '0.5rem',
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    margin: '0.7rem 0.5rem'
  },
  itemContentText: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    height: '3.9rem',
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    },
    borderRadius: '7px 0 0 7px'
  },
  borderRadius: {
    borderRadius: '7px'
  },
  itemContentBtn: {
    display: 'flex',
    flexShrink: 0,
    padding: '1rem',
    borderLeft: '1px solid',
    borderLeftColor: 'rgba(0, 0, 0, 0.08)'
  },
  selectedItem: {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  addButton: {
    border: '1px dashed green',
    margin: '0.5rem',
    padding: '0.5rem'
  }
});
