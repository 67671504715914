import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import PromotionsApi from 'api/connections/Promotions/PromotionsApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Loader from 'modules/Layout/component/Loader';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import t from 'translate/translate';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import {
  PRODUCTS_CREATE,
  PRODUCTS_UPDATE
} from 'api/auth/permissions/Products';
import PromotionDeleteDialog from 'modules/Promotions/component/DeleteDialog';
import Authorize from 'modules/Auth/component/Authorize';
import {
  PROMOTIONS_DELETE,
  PROMOTIONS_UPDATE
} from 'api/auth/permissions/Promotions';
import IconButton from 'modules/Layout/component/IconButton';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import {
  ROUTE_PROMOTIONS_LIST,
  ROUTE_PROMOTIONS_UPDATE
} from 'routing/routes/Promotions';
import promotionsTypes from 'modules/Promotions/promotionsTypes';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import CheckIcon from '@material-ui/icons/Check';
import ordersTypes from 'modules/Orders/ordersTypes';
import ProductsApi from 'api/connections/Products/ProductsApi';

class PromotionDetails extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.promotionId = parseInt(props.match.params.id, 10);
    this.userLang = context.user.language;

    this.state = {
      promotion: null,
      countries: [],
      productUnavailableInPromotion: undefined,
      deleteDialogStatus: false
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onDialogSuccess = this.onDialogSuccess.bind(this);
  }

  componentDidMount() {
    if (!this.promotionId) {
      this.props.history.push(ROUTE_PROMOTIONS_LIST);
    }

    this.props.contextMethods.setCurrentPage('Promotion details');
    this.fetchData();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_PROMOTIONS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onDeleteClick() {
    this.setState({ deleteDialogStatus: true });
  }

  onUpdateClick() {
    this.props.history.push(
      insertPathParams(ROUTE_PROMOTIONS_UPDATE, { id: this.promotionId })
    );
  }

  onDialogClose() {
    this.setState({
      deleteDialogStatus: false
    });
  }

  onDialogSuccess() {
    this.props.history.push(ROUTE_PROMOTIONS_LIST);
  }

  getProductsTableCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false,
        render: (sku, { id }) => {
          if (
            this.context.hasSomeOfPermission([PRODUCTS_CREATE, PRODUCTS_UPDATE])
          ) {
            return (
              <Link
                to={insertPathParams(ROUTE_PRODUCTS_DETAILS, {
                  id
                })}
                className='router-link router-link-underline'
              >
                {sku}
              </Link>
            );
          }

          return sku;
        }
      },
      {
        property: 'group_name',
        label: 'Product group',
        sortable: false
      },
      {
        property: 'category_name',
        label: 'Category',
        sortable: false
      },
      {
        property: 'subcategory_name',
        label: 'Subcategory',
        sortable: false
      }
    ];
  }

  async fetchData() {
    let countries = [];

    try {
      const {
        data: { data: promotion }
      } = await PromotionsApi.getPromotion(this.promotionId);

      if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
        const {
          data: { data: countriesData }
        } = await PostalCodesApi.getCountries({
          per_page: Number.MAX_SAFE_INTEGER
        });

        countries = countriesData;
      }

      const { group_id, category_id, subcategory_id, products } = promotion;

      const {
        data: { data: allProducts }
      } = await ProductsApi.getProducts({
        group_id,
        category_id,
        subcategory_id,
        per_page: Number.MAX_SAFE_INTEGER,
        promotions_blocked: true
      });

      const productUnavailableInPromotion = _.uniqBy(
        [...allProducts, ...products],
        'id'
      ).filter(product => product.promotions_blocked);

      this.setState({ promotion, productUnavailableInPromotion, countries });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderCountryName() {
    const {
      promotion: { country_id },
      countries
    } = this.state;

    return countries.find(c => c.id === country_id)?.name;
  }

  render() {
    const {
      promotion,
      productUnavailableInPromotion,
      deleteDialogStatus
    } = this.state;

    if (!promotion) return <Loader />;

    const {
      name,
      type,
      start_date,
      end_date,
      group_name,
      category_name,
      subcategory_name,
      products,
      can_combine,
      order_type,
      clients,
      sales_groups,
      regions,
      country_id,
      is_dedicated,
      type_data: { percentage, amount, exact_amount, same_product }
    } = promotion;

    return (
      <>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12}>
            <Grid container spacing={3} justify='center'>
              <Grid item lg={6} xs={12}>
                <Paper className='p1'>
                  <Box display='flex' mb={1}>
                    <Box width={1}>
                      <Typography component='h6' variant='h6'>
                        {t('Basic data')}
                      </Typography>
                    </Box>
                    <Box display='flex' alignItems='flex-start'>
                      <Authorize permissions={[PROMOTIONS_UPDATE]}>
                        <IconButton
                          className='update-icon'
                          onClick={this.onUpdateClick}
                          icon={<EditIcon />}
                          alt='update'
                        />
                      </Authorize>
                      <Authorize permissions={[PROMOTIONS_DELETE]}>
                        <IconButton
                          className='delete-icon'
                          onClick={this.onDeleteClick}
                          icon={<DeleteForeverIcon />}
                          alt='delete'
                        />
                      </Authorize>
                    </Box>
                  </Box>
                  <Box width={1}>
                    <TextDataSet data={name[this.userLang]} label='Name' />
                    <TextDataSet data={start_date} label='Start date' />
                    <TextDataSet data={end_date} label='End date' />
                    <TextDataSet
                      data={t(promotionsTypes[type].name)}
                      label='Type'
                    />
                    {Boolean(order_type) && order_type === 0 && (
                      <TextDataSet
                        data={t(ordersTypes[order_type].name)}
                        label='Order type'
                      />
                    )}
                    {percentage && (
                      <TextDataSet
                        data={`${(percentage * 100).toFixed(0)}%`}
                        label='Percentage'
                      />
                    )}
                    {amount && <TextDataSet data={amount} label='Amount' />}
                    {Boolean(exact_amount) && (
                      <TextDataSet
                        data={<CheckIcon color='primary' />}
                        label='Exact amount'
                      />
                    )}
                    {Boolean(same_product) && (
                      <TextDataSet
                        data={<CheckIcon color='primary' />}
                        label='The same products must be ordered'
                      />
                    )}
                    {Boolean(can_combine) && (
                      <TextDataSet
                        data={<CheckIcon color='primary' />}
                        label='Promotion can be combined with others'
                      />
                    )}
                    {!country_id && (
                      <TextDataSet
                        data={<CheckIcon color='primary' />}
                        label='Is global'
                      />
                    )}
                    {country_id &&
                      this.context.hasPermission([
                        POSTAL_CODES_COUNTRIES_SHOW
                      ]) && (
                        <TextDataSet
                          data={this.renderCountryName()}
                          label='Country'
                        />
                      )}
                  </Box>
                </Paper>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Paper className='p1'>
                  <Box mb={1}>
                    <Typography component='h6' variant='h6'>
                      {t('Dedicated promotion settings')}
                    </Typography>
                  </Box>
                  {Boolean(is_dedicated) && (
                    <TextDataSet
                      data={<CheckIcon color='primary' />}
                      label='The promotion is to be dedicated to special recipients'
                    />
                  )}
                  <TextDataSet
                    data={clients.map(c => c.name)}
                    label='Clients'
                  />
                  <TextDataSet
                    data={sales_groups.map(g => g.name)}
                    label='Shopping groups'
                  />
                  <TextDataSet
                    data={regions.map(r => r.name)}
                    label='Regions'
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Box mb={1}>
                <Typography component='h6' variant='h6'>
                  {t('Products')}
                </Typography>
              </Box>
              <TextDataSet data={group_name} label='Group' />
              <TextDataSet data={category_name} label='Category' />
              <TextDataSet data={subcategory_name} label='Subcategory' />
              <Table cols={this.getProductsTableCols()} rows={products} dense />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Box mb={1}>
                <Typography component='h6' variant='h6'>
                  {t('Product unavailable in promotions')}
                </Typography>
              </Box>
              <Table
                cols={this.getProductsTableCols()}
                rows={productUnavailableInPromotion}
                dense
              />
            </Paper>
          </Grid>
        </Grid>
        {deleteDialogStatus && (
          <PromotionDeleteDialog
            id={promotion.id}
            name={promotion.name[this.userLang]}
            onClose={this.onDialogClose}
            onSuccess={this.onDialogSuccess}
          />
        )}
      </>
    );
  }
}

PromotionDetails.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(PromotionDetails);
