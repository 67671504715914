export default {
  'Payment methods': 'Metody płatności',
  'Payment method': 'Metoda płatności',
  'Payment method deleted': 'Forma płatności usunięta',
  'Are you sure you want to delete payment method <%=paymentMethodName%>?':
    'Czy na pewno chcesz usunąć formę płatności <%=paymentMethodName%>?',
  'Update payment method': 'Edytowanie formy płatności',
  'Create payment method': 'Dodawanie formy płatności',
  Identifier: 'Identyfikator',
  'Price limit': 'Limit płatności',
  'The price limit for this payment method is <%=priceLimit%>':
    'Limit cenowy dla tej formy płatności wynosi <%=priceLimit%>'
};
