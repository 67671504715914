import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ROUTE_PRODUCT_CATEGORIES_LIST } from 'routing/routes/ProductCategories';
import { Box, Paper } from '@material-ui/core';
import ProductCategoriesUpdateForm from 'modules/ProductCategories/components/Update/Form';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import ApiError from 'api/exceptions/ApiError';
import { ProductCategoriesContext } from 'modules/ProductCategories/context/ProductCategoriesProvider';

class ProductCategoriesUpdate extends Component {
  static contextType = ProductCategoriesContext;

  constructor(props) {
    super(props);
    this.state = {
      productCategories: []
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  async componentDidMount() {
    const { item, itemType } = this.context;
    if (!item) {
      return this.props.history.push(ROUTE_PRODUCT_CATEGORIES_LIST);
    }
    this.setCurrentPage(`Update product ${itemType}`);
    this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: productCategories
      } = await ProductCategoriesApi.getProductCategories();
      this.setState({ productCategories });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { item } = this.context;
    if (!item) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <ProductCategoriesUpdateForm
              setAlert={this.setAlert}
              productCategoriesContext={this.context}
              productCategories={this.state.productCategories}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

ProductCategoriesUpdate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(ProductCategoriesUpdate);
