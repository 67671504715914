import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import fileDownload from 'js-file-download';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import IconButton from 'modules/Layout/component/IconButton';
import Authorize from 'modules/Auth/component/Authorize';

import { CLIENTS_UPDATE } from 'api/auth/permissions/Clients';
import {
  ROUTE_CLIENTS_FILE_CREATE,
  ROUTE_CLIENTS_LIST
} from 'routing/routes/Clients';
import ClientsFilesApi from 'api/connections/Clients/ClientsFilesApi';
import ClientFilesFilter from 'modules/Clients/component/Details/Files/Filter';
import ClientFilesList from 'modules/Clients/component/Details/Files/List';
import insertPathParams from 'api/utils/insertPathParams';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class ClientDetailsFiles extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.clientId = parseInt(props.match.params.id, 10);
    this.state = {
      files: [],
      sort: {},
      filter: {
        name: '',
        creator_name: '',
        created_at_from: null,
        created_at_to: null
      },
      filterValidation: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      }
    };

    const { setAlert, setCurrentPage } = props;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.setSort = this.setSort.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.fetchClientFile = this.fetchClientFile.bind(this);
    this.onDeleteFileSuccess = this.onDeleteFileSuccess.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
  }

  componentDidMount() {
    this.fetchClientFiles();
  }

  onDeleteFileSuccess(fileId) {
    this.setState(prevState => {
      return {
        ...prevState,
        files: prevState.files.filter(file => file.id !== fileId)
      };
    });
  }

  onCreateClick() {
    this.props.history.push(
      insertPathParams(ROUTE_CLIENTS_FILE_CREATE, { id: this.clientId })
    );
  }

  setSort(sort) {
    this.setState(
      prevState => {
        const { sort: prevSort } = prevState;
        return { sort: { ...prevSort, ...sort } };
      },
      () => this.fetchClientFiles()
    );
  }

  setFilter(filter) {
    this.setState(
      prevState => {
        const { filter: prevFilter, pagination: prevPagination } = prevState;
        return {
          filter: { ...prevFilter, ...filter },
          pagination: { ...prevPagination, page: 1 }
        };
      },
      () => this.fetchClientFiles()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchClientFiles()
    );
  }

  async fetchClientFiles() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: files, meta }
      } = await ClientsFilesApi.getClientFiles(this.clientId, {
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        files,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_CLIENTS_LIST);
        }

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchClientFile({ id: fileId, name }) {
    try {
      const res = await ClientsFilesApi.getClientFile({
        clientId: this.clientId,
        fileId
      });

      const file = new Blob([res.data]);

      fileDownload(file, name);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderAction() {
    const { pagination } = this.state;
    const { updateStatus } = this.props;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Authorize permissions={[CLIENTS_UPDATE]}>
            <IconButton
              className='create-icon'
              onClick={this.onCreateClick}
              icon={<AddIcon fontSize='large' />}
              disabled={!updateStatus}
              tooltipMsg='The client is not assigned to you or to your subordinate'
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const { files, sort, filter, filterValidation } = this.state;

    return (
      <Paper style={{ padding: '1rem' }}>
        <ClientFilesFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderAction()}
        <ClientFilesList
          files={files}
          clientId={this.clientId}
          sort={sort}
          setSort={this.setSort}
          fetchClientFile={this.fetchClientFile}
          onDeleteFileSuccess={this.onDeleteFileSuccess}
        />
        {this.renderAction()}
      </Paper>
    );
  }
}

ClientDetailsFiles.propTypes = {
  updateStatus: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired
};

export default withRouter(ClientDetailsFiles);
