import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { validate } from 'modules/Shared/utils/validator';
import t from 'translate/translate';
import { Typography, Box } from '@material-ui/core';
import AuthApi from 'api/connections/Auth/AuthApi';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';

const Form = ({ setStatus, token }) => {
  const { setAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password_confirmation: ''
  });
  const [validation, setValidation] = useState({
    email: {
      status: false,
      message: t(
        'Pole <%= field %> is required, and should be in the correct format',
        { field: t('Email') }
      )
    },
    password: {
      status: false,
      message: t(
        'Password must contain at least 8 characters, including at least 1 uppercase letter, lowercase letter and a number.'
      )
    },
    password_confirmation: {
      status: false,
      message: t('Passwords are not equal')
    }
  });

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidation({
      ...validation,
      [e.target.name]: { ...validation[e.target.name], status: false }
    });
  };

  const runValidation = () => {
    /* eslint prefer-const: "off" */
    let [isValid, newValidateState] = validate(formData, validation);
    if (
      formData.password.length &&
      formData.password !== formData.password_confirmation
    ) {
      isValid = false;
      newValidateState.password_confirmation.status = true;
    }

    if (!isValid) {
      setValidation(newValidateState);
    }

    return isValid;
  };

  const handleError = err => {
    const { message, errors } = err.getPayload();
    if (err instanceof ValidationApiError && errors) {
      const newValidateState = err.processApiValidationError();
      setValidation({ ...validation, ...newValidateState });
      setAlert(message);
    } else if (err instanceof ApiError) {
      setAlert(message);
    }
  };

  const makeApiCall = async () => {
    setLoading(true);
    try {
      await AuthApi.changePassword({ ...formData, token });
      setStatus(true);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const isValid = runValidation();
    if (isValid) await makeApiCall();
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Box my={4}>
        <Typography component='h1' variant='h5'>
          {t('Fill fields below to change Your password')}
        </Typography>
      </Box>
      <Input
        name='email'
        label='Email'
        type='email'
        required
        value={formData.email}
        onChange={onChange}
        errorStatus={validation.email.status}
        errorText={validation.email.message}
      />
      <Input
        name='password'
        label='Password'
        type='password'
        required
        value={formData.password}
        onChange={onChange}
        errorStatus={validation.password.status}
        errorText={validation.password.message}
      />
      <Input
        name='password_confirmation'
        label='Repeat password'
        type='password'
        required
        value={formData.password_confirmation}
        onChange={onChange}
        errorStatus={validation.password_confirmation.status}
        errorText={validation.password_confirmation.message}
      />
      <Button
        type='submit'
        fullWidth
        text={t('Send')}
        loading={loading}
        color='primary'
      />
    </form>
  );
};

Form.propTypes = {
  setStatus: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};

export default Form;
