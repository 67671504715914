import { Component, useState } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';
import Paper from '@material-ui/core/Paper';
import { Box, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExtendButton from 'modules/Statistics/SalesComparison/component/List/Table/ExtendButton';
import Collapse from '@material-ui/core/Collapse';
import {
  DataCell,
  HeaderCell
} from 'modules/Statistics/SalesComparison/component/List/Table/Rows/Rows';
import renderPrice from 'modules/Products/utils/renderPrice';

const Row = ({ row, currency }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <ExtendButton
            open={open}
            onClick={() => setOpen(!open)}
            disabled={isEmpty(row.products)}
            tooltipMsg='No products'
          />
        </TableCell>
        <DataCell text={`${row.first_name} ${row.last_name}`} />
        <DataCell text={t(row.role)} />
        <DataCell text={row.client_name} />
        <DataCell text={row.quantity} />
        <DataCell text={renderPrice(row.before_promotion, currency)} />
        <DataCell text={renderPrice(row.after_promotion, currency)} />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('Products')}
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <HeaderCell text='Name' />
                    <HeaderCell text='Quantity' />
                    <HeaderCell text='Price before promotion' />
                    <HeaderCell text='Price after promotion' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.products.map(product => (
                    <TableRow key={product.id}>
                      <DataCell text={product.sku} />
                      <DataCell text={product.quantity} />
                      <DataCell
                        text={renderPrice(product.before_promotion, currency)}
                      />
                      <DataCell
                        text={renderPrice(product.after_promotion, currency)}
                      />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

class StatisticsGratisProductsReportTable extends Component {
  renderBody() {
    const { loading, bodyText, statistics } = this.props;

    if (loading)
      return (
        <TableRow>
          <TableCell align='center' colSpan={7}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );

    if (bodyText) {
      return (
        <TableRow>
          <TableCell align='center' colSpan={7}>
            <Typography variant='subtitle1' color='textSecondary'>
              {t(bodyText)}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    if (isEmpty(statistics)) {
      return (
        <TableRow>
          <TableCell align='center' colSpan={7}>
            {t('No data')}
          </TableCell>
        </TableRow>
      );
    }

    return statistics.map(statistic => (
      <Row
        key={`${statistic.id}-${statistic.client_id}`}
        row={statistic}
        currency={statistic.currency}
      />
    ));
  }

  render() {
    return (
      <TableContainer component={Paper}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <HeaderCell text='User' />
              <HeaderCell text='Role' />
              <HeaderCell text='Client' />
              <HeaderCell text='Quantity' />
              <HeaderCell text='Price before promotion' />
              <HeaderCell text='Price after promotion' />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderBody()}</TableBody>
        </Table>
      </TableContainer>
    );
  }
}

Row.propTypes = {
  row: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    client_name: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    before_promotion: PropTypes.number.isRequired,
    after_promotion: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
  }).isRequired,
  currency: PropTypes.string.isRequired
};

StatisticsGratisProductsReportTable.defaultProps = {
  bodyText: undefined
};

StatisticsGratisProductsReportTable.propTypes = {
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      client_name: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      before_promotion: PropTypes.number.isRequired,
      after_promotion: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      products: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  bodyText: PropTypes.string
};

export default StatisticsGratisProductsReportTable;
