import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import SalesGroupsNotesApi from 'api/connections/SalesGroups/SalesGroupsNotesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const SalesGroupNoteDeleteDialog = props => {
  const { noteId, groupId, onClose, onSuccess } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteNote = async () => {
    try {
      await SalesGroupsNotesApi.deleteSalesGroupNote({
        groupId,
        noteId
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess(noteId);
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete this note')}
      onApprove={deleteNote}
      onCancel={onClose}
    />
  );
};

SalesGroupNoteDeleteDialog.propTypes = {
  noteId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default SalesGroupNoteDeleteDialog;
