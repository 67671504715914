import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import { Box } from '@material-ui/core';
import DatePicker, {
  API_DATE_FORMAT
} from 'modules/Layout/component/Date/DatePicker';
import Accordion from 'modules/Layout/component/Accordion';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import parse from 'date-fns/parse';
import addYears from 'date-fns/addYears';

class ClientComparisonSalesResultsFilter extends Component {
  static contextType = AuthContext;

  render() {
    const { date_from, date_to } = this.props.filter;

    const { filterTitle } = this.props;

    return (
      <Accordion label={t(filterTitle)}>
        <Box>
          <DatePicker
            name='date_from'
            label='Date from'
            value={date_from}
            onChange={this.props.onFilterChange}
            maxDate={
              date_to
                ? parse(date_to, API_DATE_FORMAT, new Date())
                : addYears(new Date(), 20)
            }
          />
          <DatePicker
            name='date_to'
            label='Date to'
            value={date_to}
            onChange={this.props.onFilterChange}
            minDate={
              date_from
                ? parse(date_from, API_DATE_FORMAT, new Date())
                : addYears(new Date(), -20)
            }
          />
        </Box>
      </Accordion>
    );
  }
}

ClientComparisonSalesResultsFilter.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    date_from: PropTypes.string,
    date_to: PropTypes.string
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired
};

export default ClientComparisonSalesResultsFilter;
