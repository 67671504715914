import { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';
import ProductCategoriesReducer, {
  SET_DELETE_ITEM,
  CLEAR_ITEM,
  CLEAR_AFTER_DELETE,
  SET_ITEM,
  ITEM_SELECTED,
  ADD_BTN_CLICKED,
  SET_STATE_AFTER_CREATE
} from 'modules/ProductCategories/context/ProductCategoriesReducer';

export const ProductCategoriesContext = createContext();
ProductCategoriesContext.displayName = 'ProductCategoriesContext';

export const TYPE_GROUP = 'group';
export const TYPE_CATEGORY = 'category';
export const TYPE_SUBCATEGORY = 'subcategory';

const ProductCategoriesProvider = props => {
  const { children } = props;
  const initialState = {
    selectedGroupId: null,
    selectedGroupName: null,
    selectedCategoryId: null,
    selectedCategoryName: null,
    item: null,
    itemType: null,
    deleteDialogOpenStatus: false
  };

  const [state, dispatch] = useReducer(ProductCategoriesReducer, initialState);

  const getItemType = item => {
    let type = TYPE_SUBCATEGORY;

    if (item.subcategories) type = TYPE_CATEGORY;
    else if (item.categories) type = TYPE_GROUP;

    return type;
  };

  const addBtnClicked = type => {
    const newState = {
      itemType: type
    };
    if (type === TYPE_GROUP) {
      newState.selectedGroupId = null;
      newState.selectedCategoryId = null;
    } else if (type === TYPE_CATEGORY) {
      newState.selectedCategoryId = null;
      newState.selectedCategoryName = null;
    }

    dispatch({
      type: ADD_BTN_CLICKED,
      payload: newState
    });
  };

  const setStateAfterCreate = (res, lang) => {
    let newState = {};

    if (!res.group_id) {
      newState = {
        selectedGroupId: res.id,
        selectedGroupName: res.name[lang]
      };
    } else if (!res.category_id) {
      newState = {
        selectedCategoryId: res.id,
        selectedCategoryName: res.name[lang]
      };
    }

    dispatch({
      type: SET_STATE_AFTER_CREATE,
      payload: newState
    });
  };

  const itemSelected = item => {
    const type = getItemType(item);
    let scrollTo = null;
    let newState = {};

    if (type === TYPE_GROUP) {
      if (item.id === state.selectedGroupId) {
        newState = {
          selectedGroupId: null,
          selectedCategoryId: null
        };
        scrollTo = TYPE_GROUP;
      } else {
        newState = {
          selectedGroupId: item.id,
          selectedGroupName: item.name,
          selectedCategoryId: null
        };
        scrollTo = TYPE_CATEGORY;
      }
    } else if (type === TYPE_CATEGORY) {
      if (item.id === state.selectedCategoryId) {
        newState = {
          selectedCategoryId: null
        };
        scrollTo = TYPE_CATEGORY;
      } else {
        newState = {
          selectedCategoryId: item.id,
          selectedCategoryName: item.name
        };
        scrollTo = TYPE_SUBCATEGORY;
      }
    }

    dispatch({
      type: ITEM_SELECTED,
      payload: newState
    });

    return scrollTo;
  };

  const setItem = item => {
    const itemType = getItemType(item);

    dispatch({
      type: SET_ITEM,
      payload: { item, itemType }
    });
  };

  const setDeleteItem = (e, item) => {
    e.stopPropagation();
    const itemType = getItemType(item);

    dispatch({
      type: SET_DELETE_ITEM,
      payload: { item, itemType }
    });
  };

  const clearAfterSuccessfulDelete = () => {
    dispatch({
      type: CLEAR_AFTER_DELETE
    });
  };

  const clearItem = () => {
    dispatch({
      type: CLEAR_ITEM
    });
  };

  return (
    <ProductCategoriesContext.Provider
      value={{
        selectedGroupId: state.selectedGroupId,
        selectedCategoryId: state.selectedCategoryId,
        selectedGroupName: state.selectedGroupName,
        selectedCategoryName: state.selectedCategoryName,
        item: state.item,
        itemType: state.itemType,
        deleteDialogOpenStatus: state.deleteDialogOpenStatus,
        itemSelected,
        addBtnClicked,
        setStateAfterCreate,
        setItem,
        setDeleteItem,
        clearAfterSuccessfulDelete,
        clearItem
      }}
    >
      {children}
    </ProductCategoriesContext.Provider>
  );
};

ProductCategoriesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProductCategoriesProvider;
