import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import renderPrice from 'modules/Products/utils/renderPrice';

class StatisticsTraderVisitsReportTable extends Component {
  static getCols() {
    return [
      {
        property: 'first_name',
        label: 'First name',
        sortable: false
      },
      {
        property: 'last_name',
        label: 'Last name',
        sortable: false
      },
      {
        property: 'visits_count',
        label: 'Visits amount',
        sortable: false
      },
      {
        property: 'orders_count',
        label: 'Orders amount',
        sortable: false
      },
      {
        property: 'orders_sum_value',
        label: 'Orders summary value',
        sortable: false,
        render: (value, { currency }) => renderPrice(value, currency)
      },
      {
        property: 'promotions_sum_value',
        label: 'Promotions summary value',
        sortable: false,
        render: (value, { currency }) => renderPrice(value, currency)
      },
      {
        property: 'free_products_quantity',
        label: 'Free products quantity',
        sortable: false
      }
    ];
  }

  render() {
    const { statistics, loading, bodyText } = this.props;

    return (
      <>
        <Table
          cols={StatisticsTraderVisitsReportTable.getCols()}
          rows={statistics}
          loading={loading}
          bodyText={bodyText}
        />
      </>
    );
  }
}

StatisticsTraderVisitsReportTable.defaultProps = {
  statistics: [],
  bodyText: null
};

StatisticsTraderVisitsReportTable.propTypes = {
  statistics: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  bodyText: PropTypes.string
};

export default StatisticsTraderVisitsReportTable;
