import PropTypes from 'prop-types';
import MaterialUiChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import t from 'translate/translate';

const ChipInput = props => {
  const {
    name,
    label,
    required,
    inputProps,
    autoFocus,
    value,
    onAdd,
    onDelete,
    errorStatus,
    errorText,
    fullWidth
  } = props;

  const chipRenderer = ({ chip, className, handleDelete }, key) => (
    <Chip
      className={className}
      key={key}
      label={chip}
      onDelete={handleDelete}
    />
  );

  return (
    <MaterialUiChipInput
      variant='outlined'
      margin='normal'
      required={required}
      inputprops={inputProps}
      fullWidth={fullWidth}
      fullWidthInput
      id={name}
      label={t(label)}
      name={name}
      autoFocus={autoFocus}
      newChipKeys={['Enter', ' ']}
      chipRenderer={chipRenderer}
      value={value}
      onAdd={chip => onAdd(name, chip)}
      onDelete={(chip, index) => onDelete(name, chip, index)}
      error={errorStatus}
      helperText={errorStatus && errorText}
    />
  );
};

ChipInput.defaultProps = {
  required: false,
  inputProps: {},
  autoFocus: false,
  value: [],
  errorStatus: false,
  errorText: '',
  fullWidth: true
};

ChipInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  autoFocus: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errorStatus: PropTypes.bool,
  errorText: PropTypes.string
};

export default ChipInput;
