import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PostalCodesBox from 'modules/PostalCodes/component/shared/PostalCodesBox';
import { ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import IconButton from 'modules/Layout/component/IconButton';
import Dialog from 'modules/Layout/component/Dialog';
import { useStyles } from 'modules/PostalCodes/component/shared/style';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import ApiError from 'api/exceptions/ValidationApiError';
import { PostalCodesContext } from 'modules/PostalCodes/context/PostalCodesProvider';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import t from 'translate/translate';
import { CITY, POSTALCODE } from 'modules/PostalCodes/constants';
import EditIcon from '@material-ui/icons/Edit';
import {
  ROUTE_POSTAL_CODES_CREATE,
  ROUTE_POSTAL_CODES_UPDATE
} from 'routing/routes/PostalCodes';

const PostalCodesList = () => {
  const classes = useStyles();

  const {
    selectedItem,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    dialogData,
    handleChosenItem
  } = useContext(PostalCodesContext);
  const { setAlert } = useContext(AlertContext);
  const history = useHistory();
  const [postalCodes, setPostalCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPostalCodes = async id => {
    try {
      setIsLoading(true);
      const {
        data: { data: postalCodesData }
      } = await PostalCodesApi.getPostalCodes({
        city_id: id,
        per_page: Number.MAX_SAFE_INTEGER
      });
      setPostalCodes(postalCodesData);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const removeItem = async () => {
    try {
      const { id } = dialogData;
      await PostalCodesApi.deletePostalCode(id);
      setPostalCodes(prevState =>
        prevState.filter(postalCode => postalCode.id !== id)
      );
    } catch (e) {
      if (e instanceof ApiError) {
        setAlert(e.getPayload().message);
      }
    }
  };

  const addPostalCode = () => {
    handleChosenItem({ type: 'postal code' });
    history.push(ROUTE_POSTAL_CODES_CREATE);
  };

  const updatePostalCode = (e, id, name) => {
    handleChosenItem({ type: 'postal code', name, id, parentType: 'city' });
    history.push(ROUTE_POSTAL_CODES_UPDATE);
  };

  useEffect(() => {
    if (selectedItem[CITY]) {
      fetchPostalCodes(selectedItem[CITY]);
    }
  }, [selectedItem[CITY]]);

  return (
    selectedItem[CITY] &&
    (isLoading ? (
      <CircularProgress style={{ marginLeft: 30 }} />
    ) : (
      <>
        <PostalCodesBox
          title='Postal code'
          data={postalCodes}
          addFn={addPostalCode}
        >
          {postalCodes.map(({ code: name, id }) => (
            <ListItem className={classes.listItem} key={`${name}${id}`}>
              <ListItemText
                style={{ cursor: 'default', marginRight: 20 }}
                primary={name}
              />
              <IconButton
                className='update-icon'
                onClick={e => updatePostalCode(e, id, name)}
                icon={<EditIcon fontSize='small' />}
                alt='update'
              />
              <IconButton
                className='delete-icon'
                onClick={e => handleOpenDialog(id, name, e, POSTALCODE)}
                icon={<DeleteForeverIcon />}
                alt='delete'
              />
            </ListItem>
          ))}
        </PostalCodesBox>
        <Dialog
          open={openDialog[POSTALCODE]}
          title={t('Deletion confirmation')}
          description={`${t('Are you sure you want to delete postal code: ')}${
            dialogData.name
          }?`}
          onApprove={async () => {
            await removeItem();
            handleCloseDialog();
          }}
          onCancel={handleCloseDialog}
        />
      </>
    ))
  );
};

export default PostalCodesList;
