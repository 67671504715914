import { ROUTE_PRICE_LISTS_LIST, permMap } from 'routing/routes/PriceLists';
import ListIcon from '@material-ui/icons/List';

const menu = [
  {
    key: 'menu-price-lists-list',
    label: 'Price lists',
    icon: <ListIcon />,
    path: ROUTE_PRICE_LISTS_LIST,
    permissions: permMap[ROUTE_PRICE_LISTS_LIST]
  }
];

export default menu;
