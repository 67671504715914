export default {
  Promotions: 'Promocje',
  'free product': 'gratisowy produkt',
  group: 'grupowy',
  'Create promotion': 'Dodawanie promocji',
  'Update promotion': 'Edycja promocji',
  'Start date': 'Data rozpoczęcia',
  'End date': 'Data zakończenia',
  'Promotion type': 'Typ promocji',
  'Promotion type data': 'Dane zależne od typu',
  'Percentage promotion value': 'Wartość procentowa promocji',
  'Field Promotion value is required and should be between 0-99':
    'Pole Wartość promocji jest wymagane i wartość powinna być pomiędzy 0-99',
  'Exact amount': 'Dokładna ilość',
  'The same products must be ordered': 'Należy zamówić te same produkty',
  'Promotion can be combined with others': 'Promocja może być łączona z innymi',
  'Can combine': 'Nachodzące',
  'Order type': 'Typ zamównienia',
  'direct orders': 'zamówienia bezpośrednie',
  'all type of orders': 'wszystkie typy zamówienia',
  'Cannot change promotion type on update':
    'Nie można zmienić typu promocji podczas edycji',
  'Dedicated promotion settings': 'Ustawienia promocji dedykowanej',
  'Is global': 'Promocja globalna',
  'Setting country available only when promotion is not global':
    'Wybór kaju dostępny tylko w przypadku kiedy promocja nie jest globalna.',
  'Are you sure you want to delete promotion <%=name%>?':
    'Czy na pewno chcesz usunać promocje <%=name%>?',
  'Promotion details': 'Szczegóły promocji',
  'There are promotions that cannot be combined or this promotion can only be used on direct orders.':
    'Istnieją promocje które nie mogą się łączyć lub ta promocja może być wykorzystana tylko przy zamówieniach bezpośrednich.',
  'This promotion can be apply only in direct order.':
    'Ta promocja może być wykorzystana tylko przy zamówieniach bezpośrednich.',
  'Category / Products': 'Kategorie / Produkty',
  'Select a category or specific products covered by the promotion.':
    'Wybierz kategorię lub konkretne produkty objęte promocją.',
  'The promotion is to be dedicated to special recipients':
    'Promocja ma być dedykowana dla specjalnych odbiorców',
  'Free product promotions': 'Promocja na darmowy produkt',
  Percent: 'Procent',
  'Dedicated to client': 'Dedykowana dla klienta',
  'This field contains products that are excluded from the promotion':
    'To pole zawiera produkty wyłączone z promocji'
};
