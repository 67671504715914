import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import WholesalerNotesApi from 'api/connections/Wholesalers/WholesalersNotesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const WholesalerNoteDeleteDialog = props => {
  const { noteId, wholesalerId, onClose, onSuccess, open } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteNote = async () => {
    try {
      await WholesalerNotesApi.deleteWholesalerNote({
        wholesalerId,
        noteId
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess(noteId);
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete this note')}
      onApprove={deleteNote}
      onCancel={onClose}
    />
  );
};

WholesalerNoteDeleteDialog.defaultProps = {
  open: false
};

WholesalerNoteDeleteDialog.propTypes = {
  noteId: PropTypes.number.isRequired,
  wholesalerId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default WholesalerNoteDeleteDialog;
