import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import {
  ROUTE_WHOLESALERS_LIST,
  ROUTE_WHOLESALER_FILE_CREATE
} from 'routing/routes/Wholesalers';
import WholesalerFilesApi from 'api/connections/Wholesalers/WholesalersFilesApi';
import WholesalerFilesFilter from 'modules/Wholesalers/component/Details/Files/List/Filter';
import WholesalerFilesList from 'modules/Wholesalers/component/Details/Files/List';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import insertPathParams from 'api/utils/insertPathParams';
import fileDownload from 'js-file-download';
import PageLoader from 'modules/Layout/component/PageLoader';
import { isEmpty } from 'lodash/lang';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  DIRECTOR,
  KEY_ACCOUNT_MANAGER,
  SUPER_ADMIN,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';

class WholesalerDetailsFiles extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.wholesalerId = parseInt(props.match.params.id, 10);
    this.state = {
      files: [],
      sort: {},
      filter: {
        name: '',
        creator_name: '',
        created_at_from: null,
        created_at_to: null
      },
      filterValidation: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      }
    };

    const { setAlert } = props;
    this.setAlert = setAlert;

    this.setSort = this.setSort.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onDeleteFileSuccess = this.onDeleteFileSuccess.bind(this);
    this.fetchWholesalerFile = this.fetchWholesalerFile.bind(this);
  }

  componentDidMount() {
    this.fetchWholesalerFiles();
  }

  onDeleteFileSuccess(fileId) {
    this.setState(prevState => {
      return {
        ...prevState,
        files: prevState.files.filter(file => file.id !== fileId)
      };
    });
  }

  onCreateClick() {
    this.props.history.push(
      insertPathParams(ROUTE_WHOLESALER_FILE_CREATE, { id: this.wholesalerId })
    );
  }

  setSort(sort) {
    this.setState(
      prevState => {
        const { sort: prevSort } = prevState;
        return { sort: { ...prevSort, ...sort } };
      },
      () => this.fetchWholesalerFiles()
    );
  }

  setFilter(filter) {
    this.setState(
      prevState => {
        const { filter: prevFilter, pagination: prevPagination } = prevState;
        return {
          filter: { ...prevFilter, ...filter },
          pagination: { ...prevPagination, page: 1 }
        };
      },
      () => this.fetchWholesalerFiles()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchWholesalerFiles()
    );
  }

  async fetchWholesalerFiles() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    const { wholesalerId } = this.props;

    try {
      const {
        data: { data: files, meta }
      } = await WholesalerFilesApi.getWholesalerFiles(wholesalerId, {
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        files,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();

        this.setAlert(message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_WHOLESALERS_LIST);
        }

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  async fetchWholesalerFile({ id: fileId, name }) {
    try {
      const res = await WholesalerFilesApi.getWholesalerFile({
        wholesalerId: this.wholesalerId,
        fileId
      });

      const file = new Blob([res.data]);

      fileDownload(file, name);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <IconButton
            className='create-icon'
            onClick={this.onCreateClick}
            icon={<AddIcon fontSize='large' />}
            alt='create'
            disabled={
              !this.context.hasRole([
                SUPER_ADMIN,
                ADMIN,
                DIRECTOR,
                DEPARTMENT_MANAGER,
                TRADER,
                TELEMARKETER,
                KEY_ACCOUNT_MANAGER
              ])
            }
            tooltipMsg='You do not have the proper permissions'
          />
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const { files, sort, filter, filterValidation } = this.state;

    const { wholesalerId } = this.props;

    if (isEmpty(files)) <PageLoader />;

    return (
      <Paper style={{ padding: '1rem' }}>
        <WholesalerFilesFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderActions()}
        <WholesalerFilesList
          files={files}
          wholesalerId={wholesalerId}
          sort={sort}
          setSort={this.setSort}
          onDeleteFileSuccess={this.onDeleteFileSuccess}
          fetchWholesalerFile={this.fetchWholesalerFile}
        />
        {this.renderActions()}
      </Paper>
    );
  }
}

WholesalerDetailsFiles.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  wholesalerId: PropTypes.number.isRequired
};

export default withRouter(WholesalerDetailsFiles);
