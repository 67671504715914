import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import t from 'translate/translate';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import CircularProgress from '@material-ui/core/CircularProgress';

class ProductsTreeProducts extends Component {
  isItemInAssignedData({ id: itemId }) {
    const { selected } = this.props;

    return Boolean(selected?.product_id === itemId);
  }

  render() {
    const { productsData, onSelect } = this.props;

    if (productsData === 'loading') {
      return <CircularProgress size={20} />;
    }

    if (isEmpty(productsData)) {
      return t('Category dont have any products');
    }

    return productsData.map(product => {
      return (
        <TreeItem
          itemData={product}
          key={product.id}
          nodeId={product.id.toString()}
          labelText={product.sku}
          selectMode
          isSelected={this.isItemInAssignedData(product)}
          onSelect={e => onSelect(e, product)}
        />
      );
    });
  }
}

ProductsTreeProducts.propTypes = {
  productsData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.string
  ]).isRequired,
  treeItemsToDisplay: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.number),
    categories: PropTypes.arrayOf(PropTypes.number),
    subcategories: PropTypes.arrayOf(PropTypes.number),
    products: PropTypes.arrayOf(PropTypes.number)
  }),
  selected: PropTypes.shape({
    product_id: PropTypes.number
  }).isRequired,
  onSelect: PropTypes.func.isRequired
};

ProductsTreeProducts.defaultProps = {
  treeItemsToDisplay: null
};

export default ProductsTreeProducts;
