import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { ROUTE_WHOLESALERS_DETAILS } from 'routing/routes/Wholesalers';
import WholesalersContactApi from 'api/connections/Wholesalers/WholesalersContactApi';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import insertPathParams from 'api/utils/insertPathParams';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

class WholesalersContactCreateForm extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    const { wholesalerId, contactId, wholesalerContact } = this.props;
    this.wholesalerId = wholesalerId;
    this.contactId = contactId;
    this.state = {
      formData: {
        first_name: wholesalerContact.first_name || '',
        last_name: wholesalerContact.last_name || '',
        position: wholesalerContact.position || '',
        email: wholesalerContact.email || '',
        phone: wholesalerContact.phone || ''
      },
      validation: {
        first_name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('First name')
          })
        },
        last_name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Last name')
          })
        },
        email: {
          status: false,
          message: t('One of this field is required')
        },
        phone: {
          status: false,
          message: t('One of this field is required')
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(
      insertPathParams(ROUTE_WHOLESALERS_DETAILS, { id: this.wholesalerId })
    );
  }

  handleError(err) {
    const { setAlert } = this.context;
    const { message } = err.getPayload();
    if (err instanceof ValidationApiError) {
      const newValidateState = err.processApiValidationError();
      this.setState(({ validation: validationState }) => {
        return {
          validation: { ...validationState, ...newValidateState }
        };
      });
      setAlert(message);
    } else if (err instanceof ApiError) {
      setAlert(message);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          },
          email: {
            ...validation.email,
            status:
              name === 'email' || name === 'phone'
                ? false
                : validation.email.status
          },
          phone: {
            ...validation.phone,
            status:
              name === 'email' || name === 'phone'
                ? false
                : validation.phone.status
          }
        }
      };
    });
  }

  runValidation() {
    /* eslint prefer-const: "off" */
    const {
      formData,
      formData: { email, phone },
      validation
    } = this.state;
    let [isValid, newValidateState] = validate(formData, validation);

    if (!email && !phone) {
      isValid = false;
      newValidateState = {
        ...newValidateState,
        email: {
          ...newValidateState.email,
          status: true
        },
        phone: {
          ...newValidateState.phone,
          status: true
        }
      };
    }

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    const ids = {
      wholesalerId: this.wholesalerId,
      contactId: this.contactId
    };
    this.setState({ loading: true });
    try {
      await WholesalersContactApi.updateWholesalerContact(ids, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { first_name, last_name, position, email, phone },
      validation,
      loading
    } = this.state;

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Input
          name='first_name'
          label='First name'
          value={first_name}
          required
          autoFocus
          onChange={this.onChange}
          errorStatus={validation.first_name.status}
          errorText={validation.first_name.message}
        />
        <Input
          name='last_name'
          label='Last name'
          value={last_name}
          required
          onChange={this.onChange}
          errorStatus={validation.last_name.status}
          errorText={validation.last_name.message}
        />
        <Input
          name='position'
          label='Position'
          value={position}
          onChange={this.onChange}
        />
        <Input
          name='email'
          label='Email'
          value={email}
          onChange={this.onChange}
          errorStatus={validation.email.status}
          errorText={validation.email.message}
        />
        <Input
          name='phone'
          label='Phone number'
          value={phone}
          onChange={this.onChange}
          errorStatus={validation.phone.status}
          errorText={validation.phone.message}
        />
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-around'
          width={1}
        >
          <Link
            to={insertPathParams(ROUTE_WHOLESALERS_DETAILS, {
              id: this.wholesalerId
            })}
            className='router-button'
          >
            <Button text={t('Cancel')} />
          </Link>
          <Box>
            <Button
              type='submit'
              fullWidth
              color='primary'
              text={t('Save')}
              loading={loading}
            />
          </Box>
        </Box>
      </form>
    );
  }
}

WholesalersContactCreateForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  wholesalerId: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
  wholesalerContact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    position: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }).isRequired
};

export default withRouter(WholesalersContactCreateForm);
