import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PublicWrapper from 'modules/Layout/view/PublicWrapper';
import t from 'translate/translate';
import { ROUTE_DASHBOARD } from 'routing/routes/Dashboard';

const NotFound = () => {
  return (
    <PublicWrapper>
      <Typography component='h1' variant='h3'>
        {t('404 Page not found')}
      </Typography>
      <Box mt={4}>
        <Link to={ROUTE_DASHBOARD} className='router-link'>
          <Typography color='primary'>{t('Go to home page')}</Typography>
        </Link>
      </Box>
    </PublicWrapper>
  );
};

export default NotFound;
