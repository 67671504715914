import { Route } from 'react-router-dom';
import { TARGET_LIST } from 'api/auth/permissions/Targets';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';

import TargetsBonusesList from 'modules/TargetsBonuses/view/List';
import TargetsBonusesDetails from 'modules/TargetsBonuses/view/Details';
import TargetsBonusesCreate from 'modules/TargetsBonuses/view/Create';
import TargetsBonusesUpdate from 'modules/TargetsBonuses/view/Update';

export const ROUTE_TARGETS_BONUSES_LIST = '/bonuses';
export const ROUTE_TARGETS_BONUSES_DETAILS = '/bonuses/details/:id';
export const ROUTE_TARGETS_BONUSES_CREATE = '/bonuses/create/:targetId';
export const ROUTE_TARGETS_BONUSES_UPDATE = '/bonuses/update/:bonusId';

export const permMap = {
  [ROUTE_TARGETS_BONUSES_LIST]: [TARGET_LIST],
  [ROUTE_TARGETS_BONUSES_DETAILS]: [TARGET_LIST],
  [ROUTE_TARGETS_BONUSES_CREATE]: [TARGET_LIST],
  [ROUTE_TARGETS_BONUSES_UPDATE]: [TARGET_LIST]
};

const routes = {
  protected: [
    <Route key='targets-bonuses-list' exact path={ROUTE_TARGETS_BONUSES_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_TARGETS_BONUSES_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <TargetsBonusesList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='targets-bonuses-details'
      exact
      path={ROUTE_TARGETS_BONUSES_DETAILS}
    >
      <Authorize redirect permissions={permMap[ROUTE_TARGETS_BONUSES_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <TargetsBonusesDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='targets-bonuses-create'
      exact
      path={ROUTE_TARGETS_BONUSES_CREATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_TARGETS_BONUSES_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <TargetsBonusesCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='targets-bonuses-update'
      exact
      path={ROUTE_TARGETS_BONUSES_UPDATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_TARGETS_BONUSES_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <TargetsBonusesUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
