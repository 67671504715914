import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { Box } from '@material-ui/core';
import insertPathParams from 'api/utils/insertPathParams';
import Pagination from 'modules/Layout/component/List/Pagination';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';

class ClientDetailsNewProductsTable extends Component {
  static contextType = AuthContext;

  static renderLink(sku, product_id) {
    return (
      <Link
        to={insertPathParams(ROUTE_PRODUCTS_DETAILS, {
          id: product_id
        })}
        className='router-link router-link-underline'
      >
        {sku}
      </Link>
    );
  }

  static getCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: true,
        render: (sku, { id }) =>
          ClientDetailsNewProductsTable.renderLink(sku, id)
      },
      {
        property: 'subcategory_name',
        label: 'Subcategory',
        sortable: false
      }
    ];
  }

  render() {
    const {
      products,
      pagination,
      setPagination,
      sort,
      setSort,
      loading
    } = this.props;

    return (
      <>
        <Box display='flex-end'>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </Box>
        <Table
          cols={ClientDetailsNewProductsTable.getCols()}
          rows={products}
          sort={sort}
          setSort={setSort}
          loading={loading}
          dense
        />
        <Box display='flex-end'>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </Box>
      </>
    );
  }
}

ClientDetailsNewProductsTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  sort: PropTypes.shape({}).isRequired,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ClientDetailsNewProductsTable);
