import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Box, Divider, Typography } from '@material-ui/core';
import t from 'translate/translate';
import Accordion from 'modules/Layout/component/Accordion';
import Loader from 'modules/Layout/component/Loader';
import IconButton from 'modules/Layout/component/IconButton';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_PROMOTIONS_DETAILS } from 'routing/routes/Promotions';
import SearchIcon from '@material-ui/icons/Search';

const promotionAccordionLabel = (
  <Box width={1}>
    <Typography component='h6' variant='h6'>
      {t('Promotions')}
    </Typography>
  </Box>
);

class OrderPromotions extends Component {
  renderPromotionInfo(promotion, index) {
    const redirectLink = insertPathParams(ROUTE_PROMOTIONS_DETAILS, {
      id: promotion.id
    });

    return (
      <Box key={promotion.id}>
        <Box display='flex' alignItems='center' width={1}>
          <Typography variant='subtitle1'>{`${promotion.name}`}</Typography>
          {promotion.type_data.percentage && (
            <Typography
              variant='subtitle1'
              style={{ marginLeft: '.5rem' }}
            >{`(${(
              promotion.type_data.percentage * 100
            ).toFixed()}%)`}</Typography>
          )}
          <Link to={redirectLink} className='router-button' target='_blank'>
            <IconButton
              className='details-icon'
              icon={<SearchIcon />}
              alt='details'
            />
          </Link>
        </Box>
        {index !== this.props.promotionsData.length - 1 && <Divider />}
      </Box>
    );
  }

  renderPromotionsInfo() {
    const { clientId, promotionsData } = this.props;

    if (!clientId) {
      return (
        <Typography variant='subtitle1' color='textSecondary'>
          {t('Select client first')}
        </Typography>
      );
    }

    if (!promotionsData) return <Loader />;

    return (
      <Box width={1}>
        {promotionsData.map((promotion, index) =>
          this.renderPromotionInfo(promotion, index)
        )}
      </Box>
    );
  }

  render() {
    return (
      <>
        <Accordion label={promotionAccordionLabel}>
          {this.renderPromotionsInfo()}
        </Accordion>
      </>
    );
  }
}

OrderPromotions.defaultProps = {
  clientId: null,
  promotionsData: null
};

OrderPromotions.propTypes = {
  clientId: PropTypes.number,
  promotionsData: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(OrderPromotions);
