import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { PAYMENT_TYPE_DISCOUNTS } from 'modules/Discounts/discountsTypes';
import Checkbox from 'modules/Layout/component/Checkbox';
import { isEmpty } from 'lodash/lang';
import DatePicker from 'modules/Layout/component/Date/DatePicker';
import Loader from 'modules/Layout/component/Loader';
import Input from 'modules/Layout/component/Input';
import { formatOptions } from 'modules/Layout/component/Select';
import Autocomplete from 'modules/Layout/component/Autocomplete';

class OrderConfigurations extends Component {
  constructor(props) {
    super(props);

    this.onIsDirectChange = this.onIsDirectChange.bind(this);
    this.onDiscountChange = this.onDiscountChange.bind(this);
  }

  onDiscountChange(e) {
    const { paymentDiscountId } = this.props;
    const name = parseInt(e.target.name, 10);
    const value = paymentDiscountId === name ? null : name;

    const event = {
      target: {
        name: 'payment_discount_id',
        value
      }
    };

    this.props.onChange(event);
  }

  onIsDirectChange(e) {
    const { value: type } = e.target;

    const event = {
      target: {
        name: 'is_direct',
        value: type === 'direct'
      }
    };

    this.props.onChange(event);
  }

  renderDiscounts() {
    const { discounts: discountsData, paymentDiscountId } = this.props;
    const discounts = discountsData.filter(
      d => d.type === PAYMENT_TYPE_DISCOUNTS
    );

    if (isEmpty(discounts)) {
      return (
        <Typography variant='subtitle1' color='textSecondary'>
          {t('Selected client have no payment date discounts')}
        </Typography>
      );
    }

    return discounts.map(discount => {
      const {
        id,
        name,
        type_data: { percentage }
      } = discount;

      return (
        <Checkbox
          key={id}
          name={`${id}}`}
          checked={paymentDiscountId === id}
          useInternalState={false}
          onChange={this.onDiscountChange}
          label={`${name} - ${percentage * 100}%`}
        />
      );
    });
  }

  render() {
    const {
      clientId,
      visitId,
      visit,
      paymentMethods,
      paymentMethod,
      paymentDate,
      isDirect,
      description,
      onChange
    } = this.props;

    if (visitId && !visit) return <Loader />;

    if (!clientId) {
      return (
        <Typography variant='subtitle1' color='textSecondary'>
          {t('Select client first')}
        </Typography>
      );
    }

    const {
      priceLimitValidStatus,
      priceLimit
    } = this.props.getPriceLimitValidStatus();

    return (
      <Box>
        <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
          {t('Payment date discount')}
        </Typography>
        {!isDirect && (
          <Typography
            variant='subtitle1'
            color='error'
            style={{ fontWeight: 600 }}
          >
            {t(
              'Wholesaler order type is selected, make sure you can ad selected discount.'
            )}
          </Typography>
        )}
        <Box display='flex' flexDirection='column'>
          {this.renderDiscounts()}
        </Box>

        <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
          {t('Payment method and date')}
        </Typography>
        <Box display='flex' width={1}>
          <Box width={1} mr={2}>
            <Autocomplete
              name='payment_method_id'
              label='Payment method'
              value={paymentMethod}
              options={formatOptions(paymentMethods || [], 'id', 'name')}
              onChange={(_, v) =>
                this.props.onAutocompleteChange('payment_method_id', v)
              }
              loading={!paymentMethods}
              errorStatus={paymentMethod && !priceLimitValidStatus}
              errorText={t(
                'The price limit for this payment method is <%=priceLimit%>',
                { priceLimit }
              )}
            />
          </Box>
          <Box width={1}>
            <DatePicker
              name='payment_date'
              label='Payment date'
              value={paymentDate}
              onChange={onChange}
            />
          </Box>
        </Box>
        <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
          {t('Description')}
        </Typography>
        <Input
          name='description'
          label='Description'
          multiline
          value={description}
          onChange={onChange}
        />

        {visitId && (
          <>
            <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
              {t('Visit')}
            </Typography>
            <Box display='flex' width={1}>
              <Box display='flex' alignItems='center'>
                <Box mr={2}>
                  <Typography>{`${visit.place} - ${visit.scheduled_at}`}</Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  }
}

OrderConfigurations.defaultProps = {
  clientId: null,
  visitId: null,
  visit: null,
  discounts: [],
  paymentDiscountId: null,
  paymentDate: null,
  description: '',
  paymentMethods: undefined,
  paymentMethod: undefined
};

OrderConfigurations.propTypes = {
  clientId: PropTypes.number,
  visitId: PropTypes.number,
  visit: PropTypes.shape({
    place: PropTypes.string.isRequired,
    scheduled_at: PropTypes.string.isRequired
  }),
  description: PropTypes.string,
  paymentDiscountId: PropTypes.number,
  discounts: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  paymentMethod: PropTypes.number,
  paymentDate: PropTypes.string,
  isDirect: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onAutocompleteChange: PropTypes.func.isRequired,
  getPriceLimitValidStatus: PropTypes.func.isRequired
};

export default OrderConfigurations;
