/* eslint no-shadow: "off" */
import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import Input from 'modules/Layout/component/Input';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Loader from 'modules/Layout/component/Loader';
import PeriodPicker from 'modules/Layout/component/Date/PeriodPicker';
import t from 'translate/translate';
import { TRADER, TELEMARKETER, KEY_ACCOUNT_MANAGER } from 'api/auth/roles';
import { formatOptions } from 'modules/Layout/component/Select';
import { prepareCategories } from 'modules/ProductCategories/utils/formater';

import ClientsApi from 'api/connections/Clients/ClientsApi';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import ProductCategoriesApi from 'api/connections/Products/ProductCategoriesApi';
import ProductsApi from 'api/connections/Products/ProductsApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';

const listQueryParams = { per_page: Number.MAX_SAFE_INTEGER };

class ValuableTargetForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isEqual(nextProps.specificTargetTypeData, prevState.formData)) {
      return {
        formData: nextProps.specificTargetTypeData
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      formData: props.specificTargetTypeData,
      data: null,
      loadingClients: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.department && this.props.department) ||
      (this.props.department &&
        prevProps.department?.id !== this.props.department?.id)
    ) {
      this.fetchSalesGroups();
      this.fetchProducts();
    }

    if (
      (!prevProps.user && this.props.user) ||
      prevProps.user?.id !== this.props.user?.id
    ) {
      this.fetchClients();
    }
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = _.isArray(res) ? res.map(v => v.key) : res.key;
    }

    this.props.onChange({ target: { name, value } });
  }

  getProductQueryParams() {
    const {
      department: { all_product_groups, all_categories, all_subcategories }
    } = this.props;

    return {
      per_page: Number.MAX_SAFE_INTEGER,
      group_id: all_product_groups.join(','),
      category_id: all_categories.join(','),
      subcategory_id: all_subcategories.join(',')
    };
  }

  async fetchData() {
    const { department } = this.props;
    let salesGroups = [];
    let products = [];

    try {
      const [
        { data: categories },
        {
          data: { data: regions }
        },
        {
          data: { data: subregions }
        },
        {
          data: { data: subsubregions }
        }
      ] = await Promise.all([
        ProductCategoriesApi.getProductCategories(),
        PostalCodesApi.getRegions(listQueryParams),
        PostalCodesApi.getSubRegions(listQueryParams),
        PostalCodesApi.getSubSubRegions(listQueryParams)
      ]);

      if (department) {
        const [
          {
            data: { data: salesGroupsData }
          },
          {
            data: { data: productsData }
          }
        ] = await Promise.all([
          SalesGroupsApi.getSalesGroups({
            per_page: Number.MAX_SAFE_INTEGER,
            country_id: department.country_id
          }),
          ProductsApi.getProducts(this.getProductQueryParams())
        ]);

        salesGroups = salesGroupsData;
        products = productsData;
      }

      this.setState(
        state => ({
          ...state,
          data: {
            salesGroups,
            products,
            categoriesObj: prepareCategories(categories),
            regions,
            subregions,
            subsubregions,
            clients: [],
            loading: false
          }
        }),
        () => this.fetchClients()
      );
    } catch (err) {
      this.props.handleError(err);
    }
  }

  async fetchProducts() {
    try {
      const {
        data: { data: products }
      } = await ProductsApi.getProducts(this.getProductQueryParams());

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          products
        }
      }));
    } catch (err) {
      this.props.handleError(err);
    }
  }

  async fetchSalesGroups() {
    const { department } = this.props;

    const pathParams = {
      per_page: Number.MAX_SAFE_INTEGER
    };

    if (department) pathParams.country_id = department.country_id;

    try {
      const {
        data: { data: salesGroups }
      } = await SalesGroupsApi.getSalesGroups(pathParams);

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          salesGroups
        }
      }));
    } catch (err) {
      this.props.handleError(err);
    }
  }

  async fetchClients() {
    const { user, department } = this.props;

    if (!department || !user) return null;

    const clientsPathParams = {
      per_page: Number.MAX_SAFE_INTEGER,
      country_id: department.country_id
    };

    if ([TRADER, TELEMARKETER, KEY_ACCOUNT_MANAGER].includes(user.role)) {
      clientsPathParams.user_id = user.id;
      clientsPathParams.department_id = department.id;
    }

    try {
      this.setState({ loadingClients: true });

      const {
        data: { data: clients }
      } = await ClientsApi.getClients(clientsPathParams);

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          clients
        },
        loadingClients: false
      }));
    } catch (err) {
      this.props.handleError(err);
    }
  }

  render() {
    const {
      formData: {
        value,
        sales_groups,
        clients,
        products,
        categoryGroups,
        categories,
        subCategories,
        regions,
        subregions,
        subsubregions,
        period_type,
        period_from
      },
      data,
      loadingClients
    } = this.state;
    const {
      validation,
      onChange,
      onPeriodChange,
      department,
      user
    } = this.props;

    if (!data) return <Loader />;

    return (
      <>
        <Input
          name='value'
          label='Value'
          value={value}
          type='number'
          onChange={onChange}
          required
          errorStatus={validation.value.status}
          errorText={validation.value.message}
        />
        <Typography component='h6' variant='h6'>
          {t('Limitation on clients')}
        </Typography>
        <Box className='language-form-box'>
          <Autocomplete
            name='sales_groups'
            label='Shopping groups'
            value={sales_groups}
            options={formatOptions(data.salesGroups, 'id', 'name')}
            onChange={(_, v) => this.onAutocompleteChange('sales_groups', v)}
            multiple
            disabled={!department}
            tooltipMsg='Select department first'
          />
          <Autocomplete
            name='clients'
            label='Clients'
            value={clients}
            options={formatOptions(data.clients, 'id', 'name')}
            onChange={(_, v) => this.onAutocompleteChange('clients', v)}
            multiple
            loading={loadingClients}
            disabled={!(department && user)}
            tooltipMsg='Select department and user first'
          />
        </Box>
        <Typography component='h6' variant='h6'>
          {t('Period')}
        </Typography>
        <Box className='language-form-box'>
          <PeriodPicker
            onChange={onPeriodChange}
            validation={validation}
            values={{ period_type, period_from }}
          />
        </Box>
        <Typography component='h6' variant='h6'>
          {t('Products')}
        </Typography>
        <Box className='language-form-box'>
          <Autocomplete
            label='Product group'
            name='categoryGroups'
            value={categoryGroups}
            options={formatOptions(
              data.categoriesObj.productGroups.filter(g =>
                department?.all_product_groups.includes(g.id)
              ),
              'id',
              'name'
            )}
            onChange={(_, v) => this.onAutocompleteChange('categoryGroups', v)}
            multiple
            disabled={!department}
            tooltipMsg='Select department first'
          />
          <Autocomplete
            label='Category'
            name='categories'
            value={categories}
            options={formatOptions(
              data.categoriesObj.categories.filter(c =>
                department?.all_categories.includes(c.id)
              ),
              'id',
              'name'
            )}
            onChange={(_, v) => this.onAutocompleteChange('categories', v)}
            multiple
            disabled={!department}
            tooltipMsg='Select department first'
          />
          <Autocomplete
            label='Subcategory'
            name='subCategories'
            value={subCategories}
            options={formatOptions(
              data.categoriesObj.subCategories.filter(s =>
                department?.all_subcategories.includes(s.id)
              ),
              'id',
              'name'
            )}
            onChange={(_, v) => this.onAutocompleteChange('subCategories', v)}
            multiple
            disabled={!department}
            tooltipMsg='Select department first'
          />
          <Autocomplete
            label='Products'
            name='products'
            value={products}
            options={formatOptions(data.products, 'id', 'sku')}
            onChange={(_, v) => this.onAutocompleteChange('products', v)}
            multiple
            disabled={!department}
            tooltipMsg='Select department first'
          />
        </Box>
        <Typography component='h6' variant='h6'>
          {t('Regions')}
        </Typography>
        <Box className='language-form-box'>
          <Autocomplete
            label='Region'
            name='regions'
            value={regions}
            options={formatOptions(data.regions, 'id', 'name')}
            onChange={(_, v) => this.onAutocompleteChange('regions', v)}
            multiple
          />
          <Autocomplete
            label='Subregion'
            name='subregions'
            value={subregions}
            options={formatOptions(data.subregions, 'id', 'name')}
            onChange={(_, v) => this.onAutocompleteChange('subregions', v)}
            multiple
          />
          <Autocomplete
            label='Sub subregion'
            name='subsubregions'
            value={subsubregions}
            options={formatOptions(data.subsubregions, 'id', 'name')}
            onChange={(_, v) => this.onAutocompleteChange('subsubregions', v)}
            multiple
          />
        </Box>
      </>
    );
  }
}

ValuableTargetForm.defaultProps = {
  department: null,
  user: null
};

ValuableTargetForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  validation: PropTypes.shape({
    value: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      message: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  handleError: PropTypes.func.isRequired,
  department: PropTypes.shape({
    id: PropTypes.number,
    country_id: PropTypes.number,
    all_product_groups: PropTypes.arrayOf(PropTypes.number),
    all_categories: PropTypes.arrayOf(PropTypes.number),
    all_subcategories: PropTypes.arrayOf(PropTypes.number)
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string
  }),
  specificTargetTypeData: PropTypes.shape({}).isRequired
};

export default ValuableTargetForm;
