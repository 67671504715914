import Visits from 'modules/Clients/component/Details/Visits/Visits';
import PropTypes from 'prop-types';
import VisitsTable from 'modules/Clients/component/Details/Visits/VisitsTable';
import VisitsFilter from 'modules/Clients/component/Details/Visits/VisitsTable/Filter';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Pagination from 'modules/Layout/component/List/Pagination';
import {
  DEPARTMENT_MANAGER,
  KEY_ACCOUNT_MANAGER,
  TELEMARKETER,
  TRADER
} from 'api/auth/roles';
import { Link } from 'react-router-dom';
import { ROUTE_VISITS_CREATE } from 'routing/routes/Visits';
import { Box, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  getStatuses,
  PLANNED,
  CONFIRMED,
  POSTPONED
} from 'modules/Visits/utils/visitStatuses';

class UpcomingVisits extends Visits {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        statuses: [PLANNED, CONFIRMED, POSTPONED],
        date_from: new Date()
      },
      filterValidation: {},
      sort: { sort_field: 'scheduled_at' },
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      visits: []
    };
  }

  componentDidMount() {
    this.fetchVisits('date_from');
  }

  render() {
    return (
      <>
        <VisitsFilter
          filter={this.state.filter}
          validation={this.state.filterValidation}
          setFilter={filter => this.setFilter(filter, 'date_from')}
          hasRole={this.context.hasRole}
          visits={getStatuses([PLANNED, CONFIRMED, POSTPONED])}
        />
        <VisitsTable
          sort={this.state.sort}
          setSort={sort => this.setSort(sort, 'date_from')}
          visits={this.state.visits}
        />
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          {this.context.hasRole([
            TRADER,
            KEY_ACCOUNT_MANAGER,
            TELEMARKETER,
            DEPARTMENT_MANAGER
          ]) && (
            <Link
              to={{
                pathname: ROUTE_VISITS_CREATE,
                state: { client: { client_id: this.props.clientId } }
              }}
              className='router-button'
            >
              <IconButton>
                <AddIcon color='primary' fontSize='large' />
              </IconButton>
            </Link>
          )}
          <Pagination
            pagination={this.state.pagination}
            setPagination={pagination =>
              this.setPagination(pagination, 'date_from')
            }
            rowsPerPageOptions={[5, 10, 15]}
          />
        </Box>
      </>
    );
  }
}

UpcomingVisits.propTypes = {
  setAlert: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired
};

export default UpcomingVisits;
