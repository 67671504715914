import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Table from 'modules/Layout/component/List/Table';
import Input from 'modules/Layout/component/Input';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from 'modules/Layout/component/IconButton';
import { Box } from '@material-ui/core';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import Typography from '@material-ui/core/Typography';
import Checkbox from 'modules/Layout/component/Checkbox';
import {
  getPromotions,
  getPromotionsPercentageSum
} from 'modules/Orders/utils/calculations';
import { isEmpty } from 'lodash/lang';
import { Link } from 'react-router-dom';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_PRODUCTS_DETAILS } from 'routing/routes/Products';
import ProductPromotionsTooltip from 'modules/Orders/component/OrderPreparing/ProductPromotionsTooltip';
import AddProductDialog from 'modules/Orders/component/OrderPreparing/AddProductDialog';
import CopyProductDialog from 'modules/Orders/component/OrderPreparing/CopyProductDialog';
import getPriceForProduct from 'modules/PriceLists/utils/getPriceForProduct';
import renderPrice from 'modules/Products/utils/renderPrice';

class OrderProductsTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const { products } = props;

    this.state = {
      products,
      addProductDialog: false,
      copyProductDialog: false
    };

    this.onProductAddClick = this.onProductAddClick.bind(this);
    this.onProductCopyClick = this.onProductCopyClick.bind(this);
    this.onProductAddSuccess = this.onProductAddSuccess.bind(this);
    this.onProductCopySuccess = this.onProductCopySuccess.bind(this);
    this.onProductDeleteClick = this.onProductDeleteClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.renderProductPromotion = this.renderProductPromotion.bind(this);
    this.renderQuantityCell = this.renderQuantityCell.bind(this);
    this.renderGratisProductCell = this.renderGratisProductCell.bind(this);
    this.getProductPriceAndCurrency = this.getProductPriceAndCurrency.bind(
      this
    );
  }

  onProductAddClick() {
    this.setState({ addProductDialog: true });
  }

  onProductCopyClick() {
    this.setState({ copyProductDialog: true });
  }

  onProductAddSuccess(productId) {
    const product = {
      ...this.props.productsData.find(p => p.id === productId),
      id: this.getOrderProductId(),
      product_id: productId,
      quantity: 1,
      is_free: false,
      free_comment: ''
    };

    let products;

    this.setState(
      state => {
        products = [...state.products, product];

        return { products };
      },
      () => this.props.onChange(products)
    );
  }

  onProductCopySuccess(ids) {
    const indexes = [];

    const formattedProducts = ids.map(id => {
      const index = this.getOrderProductId(null, indexes);

      indexes.push(index);

      return {
        ...this.props.productsData.find(p => p.id === id),
        product_id: id,
        id: index,
        quantity: 1,
        is_free: false,
        free_comment: ''
      };
    });

    let products;

    this.setState(
      state => {
        products = [...state.products, ...formattedProducts];

        return { products };
      },
      () => {
        this.props.onChange(products);
        this.onDialogClose();
      }
    );
  }

  onProductDeleteClick({ id }) {
    let products = null;

    this.setState(
      state => {
        products = state.products.filter(p => p.id !== id);

        return { products };
      },
      () => this.props.onChange(products, true, id)
    );
  }

  onDialogClose() {
    this.setState({
      addProductDialog: false,
      copyProductDialog: false
    });
  }

  onQuantityChange(quantity, index) {
    let products;

    this.setState(
      state => {
        products = [...state.products];
        products[index].quantity = quantity ? parseInt(quantity, 10) : '';

        return {
          ...state,
          products
        };
      },
      () => this.props.onChange(products)
    );
  }

  onGratisChange(checked, index) {
    let products;
    let deletedProductId;

    this.setState(
      state => {
        products = [...state.products];
        products[index].is_free = !checked;
        products[index].free_comment = '';

        deletedProductId = checked ? null : products[index].id;

        return {
          ...state,
          products
        };
      },
      () => this.props.onChange(products, true, deletedProductId)
    );
  }

  onGratisCommentChange(comment, index) {
    let products;

    this.setState(
      state => {
        products = [...state.products];
        products[index].free_comment = comment;

        return {
          ...state,
          products
        };
      },
      () => this.props.onChange(products, false)
    );
  }

  getOrderProductId(index = null, indexes = []) {
    const { products } = this.state;

    let id = index;

    if (!index) {
      id = products.length + 1;
    }

    if ([...products.map(p => p.id), ...indexes].includes(id)) {
      return this.getOrderProductId(id + 1, indexes);
    }

    return id;
  }

  getProductsTableCols() {
    return [
      {
        property: 'sku',
        label: 'Name',
        sortable: false,
        render: (sku, { product_id: id }) => {
          return (
            <Link
              to={insertPathParams(ROUTE_PRODUCTS_DETAILS, { id })}
              className='router-link router-link-underline'
              target='_blank'
            >
              {sku}
            </Link>
          );
        }
      },
      {
        property: 'quantity',
        label: 'Quantity',
        sortable: false,
        nullable: true,
        render: (quantity, row, index) => {
          return this.renderQuantityCell(quantity, row, index);
        }
      },
      {
        property: 'prices',
        label: 'Price (net)',
        sortable: false,
        render: (_, row) => {
          const { price, currency } = this.getProductPriceAndCurrency(row);
          return renderPrice(price, currency);
        }
      },
      {
        property: 'id', // For Summary price render purpose
        label: 'Summary price',
        sortable: false,
        render: (_, row) => {
          const { price, currency } = this.getProductPriceAndCurrency(row);
          const { quantity } = row;
          return renderPrice(price * quantity, currency);
        }
      },
      {
        property: 'group_id', // For Promotion info render purpose
        label: 'Promotions',
        sortable: false,
        render: this.renderProductPromotion
      },
      {
        property: 'is_free',
        label: 'Gratis',
        sortable: false,
        nullable: true,
        displayStatus: this.props.isDirect,
        render: (checked, row, index) => {
          return this.renderGratisProductCell(checked, row, index);
        }
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: !this.props.isDetails,
        actionOptions: {
          details: {
            status: false,
            disable: null,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: false,
            disable: null,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onProductDeleteClick
          }
        }
      }
    ];
  }

  getProductPriceAndCurrency(row) {
    const { prices, is_free, product_id } = row;
    const { clientCountryId, priceList } = this.props;

    /* eslint prefer-const: "off" */
    let { price, currency } = prices.find(
      p => p.country_id === clientCountryId
    );

    if (is_free) {
      price = 0.01;
    } else if (priceList) {
      const priceListPrice = getPriceForProduct(priceList, product_id);

      if (priceListPrice) price = priceListPrice;
    }

    return { price, currency };
  }

  renderQuantityCell(quantity, row, index) {
    const validationStatus = !quantity || quantity > row.batches_count;

    let validationMessage;

    if (!quantity) {
      validationMessage = t('Quantity is required');
    }
    if (quantity > row.batches_count) {
      validationMessage = t('Max batches count is <%=count%>', {
        count: row.batches_count
      });
    }

    return (
      <Input
        label=''
        name={`quantity-${index}`}
        value={quantity}
        inputProps={{
          min: 0,
          max: row.batches_count
        }}
        onChange={event => this.onQuantityChange(event.target.value, index)}
        errorStatus={validationStatus}
        errorText={validationMessage}
      />
    );
  }

  renderGratisProductCell(checked, row, index) {
    const isInvalid = checked && row.promotions_blocked;

    return (
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='center'
      >
        <Checkbox
          name={`gratis-${index}`}
          useInternalState={false}
          checked={checked}
          onChange={() => this.onGratisChange(checked, index)}
          labelStyle={{ marginRight: 0 }}
          disabled={!checked && row.promotions_blocked}
          tooltipMsg='The product is unavailable in promotions'
        />
        {checked && (
          <Input
            label='Comment'
            name={`free_comment-${index}`}
            value={row.free_comment || ''}
            multiline
            onChange={event =>
              this.onGratisCommentChange(event.target.value, index)
            }
            errorStatus={isInvalid}
            errorText={t('The product is unavailable in promotions')}
          />
        )}
      </Box>
    );
  }

  renderProductPromotion(_, product) {
    const { promotions, promotionsData } = this.props;

    const validPromotions = getPromotions(product, promotions);

    let percentageSum;
    let resultPrice;
    const { currency } = this.getProductPriceAndCurrency(product);

    if (!isEmpty(validPromotions)) {
      const { price } = this.getProductPriceAndCurrency(product);
      const summaryPrice = price * product.quantity;

      percentageSum = getPromotionsPercentageSum(validPromotions);
      resultPrice = summaryPrice - (summaryPrice * percentageSum) / 100;
    }

    return (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
      >
        {percentageSum && (
          <Typography
            color={product.promotions_blocked ? 'error' : 'primary'}
          >{`${percentageSum}% (${renderPrice(
            resultPrice,
            currency
          )})`}</Typography>
        )}
        <ProductPromotionsTooltip
          product={product}
          promotions={promotionsData}
        />
      </Box>
    );
  }

  render() {
    const { addProductDialog, copyProductDialog, products } = this.state;
    const {
      clientId,
      clientCountryId,
      productsData,
      promotionsData,
      isDetails,
      isDirect
    } = this.props;

    return (
      <>
        <Table
          cols={this.getProductsTableCols()}
          rows={products}
          actions={!isDetails}
          dense
          loading={clientCountryId && !productsData}
        />
        <Box display='flex' alignItems='center'>
          {!isDetails && (
            <>
              <IconButton
                onClick={this.onProductAddClick}
                className='create-icon'
                icon={<AddIcon fontSize='large' />}
                alt='add product'
                disabled={!clientCountryId}
                tooltipMsg={t('Select client first')}
              />
              <Typography variant='subtitle1' color='textSecondary'>
                {t('Click on the add button to add product.')}
              </Typography>
              <Box ml={2} display='flex' alignItems='center'>
                <IconButton
                  onClick={this.onProductCopyClick}
                  className='update-icon'
                  icon={<FileCopyIcon />}
                  alt='copy products'
                  disabled={!clientCountryId}
                  tooltipMsg={t('Select client first')}
                />
                <Typography variant='subtitle1' color='textSecondary'>
                  {t('Click on the button to copy products from last order.')}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        {addProductDialog && (
          <AddProductDialog
            isDirect={isDirect}
            productsData={productsData}
            promotionsData={promotionsData}
            promotionOrderType={this.props.promotionOrderType}
            onClose={this.onDialogClose}
            onProductAddSuccess={this.onProductAddSuccess}
            clientCountryId={clientCountryId}
          />
        )}
        {copyProductDialog && (
          <CopyProductDialog
            clientId={clientId}
            isDirect={isDirect}
            onClose={this.onDialogClose}
            onProductAddSuccess={this.onProductCopySuccess}
            handleError={this.props.handleError}
          />
        )}
      </>
    );
  }
}

OrderProductsTable.defaultProps = {
  clientCountryId: null,
  clientId: null,
  productsData: null,
  promotionsData: null,
  promotions: [],
  isDetails: false,
  promotionOrderType: null,
  priceList: null
};

OrderProductsTable.propTypes = {
  isDirect: PropTypes.bool.isRequired,
  clientId: PropTypes.number,
  clientCountryId: PropTypes.number,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      promotions_blocked: PropTypes.bool.isRequired
    })
  ).isRequired,
  promotionOrderType: PropTypes.number,
  promotionsData: PropTypes.arrayOf(PropTypes.shape({})),
  promotions: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  productsData: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  isDetails: PropTypes.bool,
  handleError: PropTypes.func.isRequired,
  priceList: PropTypes.shape({})
};

export default OrderProductsTable;
