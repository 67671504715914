import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import Accordion from 'modules/Layout/component/Accordion';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import Input from 'modules/Layout/component/Input';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_PRICE_LISTS_DETAILS } from 'routing/routes/PriceLists';
import PriceListsApi from 'api/connections/PriceLists/PriceListsApi';
import t from 'translate/translate';

class DepartmentsDetailsPriceLists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      priceList: [],
      name: '',
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      loading: true
    };

    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.fetchPriceLists();
  }

  onDetailsClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_PRICE_LISTS_DETAILS, { id })
    );
  }

  onChange(e) {
    const { value: name } = e.target;

    this.setState(
      { name, pagination: { ...this.state.pagination, page: 1 } },
      () => this.fetchPriceLists()
    );
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          }
        }
      }
    ];
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination }
      },
      () => this.fetchPriceLists()
    );
  }

  async fetchPriceLists() {
    const {
      name,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: priceList, meta }
      } = await PriceListsApi.getPricesLists({
        for_department_id: this.props.departmentId,
        name,
        per_page,
        page
      });

      this.setState({
        priceList,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  render() {
    const { priceList, name, pagination, loading } = this.state;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={6}>
          <Paper className='p1'>
            <Accordion label={t('Filter')}>
              <Input
                onChange={this.onChange}
                label='Name'
                name='name'
                value={name}
                fullWidth={false}
              />
            </Accordion>
            <Table
              cols={this.getCols()}
              rows={priceList}
              loading={loading}
              dense
              actions
            />
            <Pagination
              setPagination={this.setPagination}
              pagination={pagination}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

DepartmentsDetailsPriceLists.propTypes = {
  departmentId: PropTypes.number.isRequired,
  handleError: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(DepartmentsDetailsPriceLists);
