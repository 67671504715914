import axios from 'axios';
import UnauthenticatedApiError from 'api/exceptions/UnauthenticatedApiError';
import UnauthorizedApiError from 'api/exceptions/UnauthorizedApiError';
import BadRequestApiError from 'api/exceptions/BadRequestApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import ServerApiError from 'api/exceptions/ServerApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken,
  clearTokens,
  getRefreshToken
} from 'api/auth/services/token';
import { authorizationReqData } from 'api/connections/Auth/AuthApi';

const REFRESH_TOKEN_PATH = `${process.env.REACT_APP_API_URL}auth/token`;

const formatRefreshTokenReq = () => {
  return {
    grant_type: 'refresh_token',
    refresh_token: getRefreshToken(),
    ...authorizationReqData
  };
};

const handleError = async error => {
  if (error.response) {
    const { status } = error.response;
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === REFRESH_TOKEN_PATH
    ) {
      clearTokens();
      return Promise.reject(new UnauthenticatedApiError(error));
    }

    /* eslint no-underscore-dangle: "off" */
    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const {
          data: { access_token, refresh_token }
        } = await axios.post(REFRESH_TOKEN_PATH, formatRefreshTokenReq());

        setAccessToken(access_token);
        setRefreshToken(refresh_token);

        return axios(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    switch (status) {
      case 400:
        return Promise.reject(new BadRequestApiError(error));
      case 403:
        return Promise.reject(new UnauthorizedApiError(error));
      case 404:
        return Promise.reject(new NotFoundApiError(error));
      case 422:
        return Promise.reject(new ValidationApiError(error));
      case 500:
        return Promise.reject(new ServerApiError(error));
      default:
        return Promise.reject(error);
    }
  }

  return Promise.reject(error);
};

const registerInterceptors = () => {
  axios.interceptors.request.use(
    config => {
      const token = getAccessToken();

      const resultConfig = config;

      if (token) {
        resultConfig.headers.Authorization = `Bearer ${token}`;
      }
      resultConfig.headers['Content-Type'] = 'application/json';

      return resultConfig;
    },
    error => handleError(error)
  );

  axios.interceptors.response.use(
    response => response,
    error => handleError(error)
  );
};

export default registerInterceptors;
