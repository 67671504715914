import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';
import {
  PERCENTAGE_DISCOUNTS,
  PAYMENT_TYPE_DISCOUNTS,
  THRESHOLD_DISCOUNTS
} from 'modules/Discounts/discountsTypes';
import Table from 'modules/Layout/component/List/Table';
import IconButton from 'modules/Layout/component/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_DISCOUNTS_DETAILS } from 'routing/routes/Discounts';
import Accordion from 'modules/Layout/component/Accordion';
import clsx from 'clsx';

const useStyles = {
  disableColors: {
    color: 'rgba(0, 0, 0, 0.54)!important',
    '& .MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)!important'
    },
    '& .MuiTableCell-head': {
      color: 'rgba(0, 0, 0, 0.54)!important'
    },
    '& .MuiTypography-colorPrimary': {
      color: 'rgba(0, 0, 0, 0.54)!important'
    }
  }
};

const discountAccordionLabel = (
  <Box width={1}>
    <Typography component='h6' variant='h6'>
      {t('Discounts')}
    </Typography>
  </Box>
);

class OrderDiscounts extends Component {
  isThresholdReached(threshold, thresholds) {
    const { netValue } = this.props;

    const sortedThresholds = thresholds
      .filter(thr => thr.threshold <= netValue)
      .sort((a, b) => (a.threshold > b.threshold ? -1 : 0));

    return sortedThresholds[0]?.threshold === threshold;
  }

  isDiscountCoveringProducts(discount) {
    const { products } = this.props;

    if (
      isEmpty([
        ...discount.subcategory_id,
        ...discount.category_id,
        ...discount.group_id
      ])
    ) {
      return true;
    }

    return (
      products.some(({ subcategory_id }) =>
        discount.subcategory_id.includes(subcategory_id)
      ) ||
      products.some(({ category_id }) =>
        discount.category_id.includes(category_id)
      ) ||
      products.some(({ group_id }) => discount.group_id.includes(group_id))
    );
  }

  renderDiscounts() {
    const { discounts } = this.props;
    const sortedDiscounts = discounts
      .filter(d => d.type !== PAYMENT_TYPE_DISCOUNTS)
      .sort(a => (a.type === 1 ? 0 : -1));

    return sortedDiscounts.map((discount, index) => {
      let discountComponent = null;

      if (
        discount.type === PERCENTAGE_DISCOUNTS ||
        discount.type === PAYMENT_TYPE_DISCOUNTS
      ) {
        discountComponent = this.renderPercentageDiscount(discount);
      }
      if (discount.type === THRESHOLD_DISCOUNTS) {
        discountComponent = this.renderThresholdDiscount(discount);
      }

      return (
        <Box key={discount.id} mb={1}>
          <Box mb={1}>{discountComponent}</Box>
          {index !== sortedDiscounts.length - 1 && <Divider />}
        </Box>
      );
    });
  }

  renderPercentageDiscount(discount) {
    const redirectLink = insertPathParams(ROUTE_DISCOUNTS_DETAILS, {
      id: discount.id
    });

    const color = this.isDiscountCoveringProducts(discount)
      ? 'primary'
      : 'initial';

    return (
      <Box display='flex'>
        <Box display='flex' alignItems='center'>
          <Box mr={1}>
            <Typography
              variant='subtitle1'
              color={color}
            >{`${discount.name} -`}</Typography>
          </Box>
          <Typography variant='subtitle1' color={color}>{`${
            discount.type_data.percentage * 100
          }%`}</Typography>
          <Link to={redirectLink} className='router-button' target='_blank'>
            <IconButton
              className='details-icon'
              icon={<SearchIcon />}
              alt='details'
            />
          </Link>
        </Box>
      </Box>
    );
  }

  renderThresholdDiscount(discount) {
    const { id, name, type_data: thresholds } = discount;

    const redirectLink = insertPathParams(ROUTE_DISCOUNTS_DETAILS, { id });

    return (
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Box display='flex' flexDirection='column'>
          <Typography variant='subtitle1'>{name}</Typography>
          <Table
            cols={[
              {
                property: 'threshold',
                label: 'Order value',
                sortable: false,
                render: threshold => (
                  <Typography
                    color={
                      this.isThresholdReached(threshold, thresholds)
                        ? 'primary'
                        : 'initial'
                    }
                  >
                    {threshold}
                  </Typography>
                )
              },
              {
                property: 'percentage',
                label: 'Discount value',
                sortable: false,
                render: (value, { threshold }) => (
                  <Typography
                    color={
                      this.isThresholdReached(threshold, thresholds)
                        ? 'primary'
                        : 'initial'
                    }
                  >{`${(value * 100).toFixed(0)}%`}</Typography>
                )
              }
            ]}
            rows={thresholds}
            dense
          />
        </Box>
        <Link to={redirectLink} className='router-button' target='_blank'>
          <IconButton
            className='details-icon'
            icon={<SearchIcon />}
            alt='details'
          />
        </Link>
      </Box>
    );
  }

  renderData() {
    const { disableColors } = this.props.classes;

    const { clientId, discounts, isDirect } = this.props;

    if (!clientId) {
      return (
        <Typography variant='subtitle1' color='textSecondary'>
          {t('Select client first')}
        </Typography>
      );
    }

    if (isEmpty(discounts)) {
      return (
        <Typography variant='subtitle1' color='textSecondary'>
          {t('Client have no discounts')}
        </Typography>
      );
    }

    const discountSection = (
      <Box width={1} className={clsx(!isDirect && disableColors)}>
        {this.renderDiscounts()}
      </Box>
    );

    if (isDirect) return discountSection;

    return (
      <Tooltip title={t('Discounts available only on direct order.')}>
        {discountSection}
      </Tooltip>
    );
  }

  render() {
    return (
      <Accordion label={discountAccordionLabel}>{this.renderData()}</Accordion>
    );
  }
}

OrderDiscounts.defaultProps = {
  clientId: null,
  discounts: null
};

OrderDiscounts.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  clientId: PropTypes.number,
  netValue: PropTypes.number.isRequired,
  isDirect: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    disableColors: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(useStyles)(OrderDiscounts);
