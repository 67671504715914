import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validateBaseOnFormInputs } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

import TargetsApi from 'api/connections/Targets/TargetsApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { DEPARTMENT_MANAGER } from 'api/auth/roles';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import { formatOptions } from 'modules/Layout/component/Select';
import formatDepartmentsOptions from 'modules/Targets/formatters/formatDepartmentsOptions';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import UsersApi from 'api/connections/Users/UsersApi';
import formatUsersWithFullNames from 'modules/Targets/formatters/formatUsersWithFullNames';
import formatUsersOptions from 'modules/Targets/formatters/formatUsersOptions';
import { isArray } from 'lodash/lang';
import { Box, Typography } from '@material-ui/core';
import PeriodPicker from 'modules/Layout/component/Date/PeriodPicker';
import Loader from 'modules/Layout/component/Loader';

class TargetCopyDialog extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      formData: {
        name: props.targetToCopy.name,
        department_id: props.targetToCopy.department_id,
        user_id: props.targetToCopy.user_id,
        period_type: props.targetToCopy.period_type,
        period_from: props.targetToCopy.period_from
      },
      data: {
        departments: undefined,
        users: undefined
      },
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        },
        department_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Department')
          })
        },
        user_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('User')
          })
        },
        period_type: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Period')
          })
        },
        period_from: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Period')
          })
        },
        quarter: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Quarter')
          })
        }
      },
      loading: false,
      loadingDepartments: true,
      loadingUsers: true
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onClose();
    this.props.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.props.setAlert(message);

      if (err instanceof ValidationApiError) {
        const validateBaseOnFormInputsState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...validateBaseOnFormInputsState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation(e.target.elements);
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState(
      state => {
        const { formData, validation } = state;

        return {
          formData: {
            ...formData,
            [name]: value
          },
          validation: {
            ...validation,
            [name]: {
              ...validation[name],
              status: false
            }
          },
          loadingUsers: name === 'department_id' ? true : state.loadingUsers
        };
      },
      () => {
        if (name === 'department_id') this.fetchUsers(value);
      }
    );
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onChange(event);
  }

  onPeriodChange(data) {
    const { period_type, period_from } = data;

    this.setState(state => {
      const { formData, validation } = state;

      return {
        ...state,
        formData: {
          ...formData,
          period_type,
          period_from
        },
        validation: {
          ...validation,
          period_type: {
            ...validation.period_type,
            status: false
          },
          period_from: {
            ...validation.period_from,
            status: false
          }
        }
      };
    });
  }

  runValidation(formElements) {
    const { formData, validation } = this.state;
    /* eslint prefer-const: "off" */
    let [isValid, validateBaseOnFormInputsState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!formData.period_type) {
      validateBaseOnFormInputsState.period_type.status = true;
      isValid = false;
    }

    if (!formData.period_from) {
      validateBaseOnFormInputsState.period_from.status = true;
      isValid = false;
    }

    if (!isValid) {
      this.setState({ validation: validateBaseOnFormInputsState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    this.setState({ loading: true });

    try {
      await TargetsApi.copyTarget(this.props.targetToCopy.id, formData);

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  async fetchData() {
    let departments = [];
    let users = [];
    const { department_id } = this.props.targetToCopy;

    try {
      if (this.context.user.role !== DEPARTMENT_MANAGER) {
        const {
          data: { data: departmentsData }
        } = await DepartmentsApi.getDepartments({
          per_page: Number.MAX_SAFE_INTEGER
        });

        departments = departmentsData;
      }

      const {
        data: { data: usersData }
      } = await UsersApi.getUsers({
        per_page: Number.MAX_SAFE_INTEGER,
        department_id
      });

      users = formatUsersWithFullNames(usersData);

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          departments,
          users
        },
        loadingDepartments: false,
        loadingUsers: false
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchUsers(department_id) {
    let users = [];

    try {
      const {
        data: { data: usersData }
      } = await UsersApi.getUsers({
        per_page: Number.MAX_SAFE_INTEGER,
        department_id
      });

      users = formatUsersWithFullNames(usersData);

      this.setState(state => ({
        ...state,
        data: {
          ...state.data,
          users
        },
        loadingUsers: false
      }));
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { name, department_id, user_id, period_type, period_from },
      data,
      validation,
      loading,
      loadingDepartments,
      loadingUsers
    } = this.state;

    const { onClose } = this.props;
    const { for_management } = this.props.targetToCopy;

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Copy target')}</DialogTitle>
        <DialogContent>
          {!data.departments ? (
            <Loader />
          ) : (
            <form id='form' noValidate onSubmit={this.onSubmit}>
              <Input
                name='name'
                label='Name'
                value={name}
                onChange={this.onChange}
                required
                errorStatus={validation.name.status}
                errorText={validation.name.message}
              />
              {this.context.user.role !== DEPARTMENT_MANAGER && (
                <Autocomplete
                  name='department_id'
                  label='Department'
                  value={department_id}
                  options={formatOptions(
                    formatDepartmentsOptions(
                      data.departments,
                      this.context.user
                    ),
                    'id',
                    'name'
                  )}
                  onChange={(_, v) =>
                    this.onAutocompleteChange('department_id', v)
                  }
                  required
                  errorStatus={validation.department_id.status}
                  errorText={validation.department_id.message}
                  loading={loadingDepartments}
                />
              )}
              <Autocomplete
                name='user_id'
                label='User'
                value={user_id}
                options={formatOptions(
                  formatUsersOptions(
                    data.users ?? [],
                    for_management,
                    this.context.user
                  ),
                  'id',
                  'full_name'
                )}
                onChange={(_, v) => this.onAutocompleteChange('user_id', v)}
                required
                errorStatus={validation.user_id.status}
                errorText={validation.user_id.message}
                disabled={!department_id}
                tooltipMsg='Select department first'
                loading={loadingUsers}
              />
              <Typography component='h6' variant='h6'>
                {t('Period')}
              </Typography>
              <Box className='language-form-box'>
                <PeriodPicker
                  onChange={this.onPeriodChange}
                  validation={validation}
                  values={{ period_type, period_from }}
                />
              </Box>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button
            form='form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

TargetCopyDialog.propTypes = {
  targetToCopy: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
    department_id: PropTypes.number.isRequired,
    period_type: PropTypes.string.isRequired,
    period_from: PropTypes.string.isRequired,
    for_management: PropTypes.bool.isRequired
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default TargetCopyDialog;
