import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import t from 'translate/translate';
import {
  statusesObj as visitStatuses,
  FINISHED,
  CANCELED
} from 'modules/Visits/utils/visitStatuses';
import {
  VISITS_CANCEL,
  VISITS_POSTPONE,
  VISITS_SHOW,
  VISITS_UPDATE
} from 'api/auth/permissions/Visits';
import insertPathParams from 'api/utils/insertPathParams';
import {
  ROUTE_VISITS_DETAILS,
  ROUTE_VISITS_POSTPONE,
  ROUTE_VISITS_UPDATE
} from 'routing/routes/Visits';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import CancelVisitModal from 'modules/Visits/components/CancelVisitModal';

class VisitsTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      chosenVisit: {},
      openVisitModal: false
    };
  }

  getCols() {
    return [
      {
        property: 'user_name',
        label: 'User name',
        sortable: true
      },
      {
        property: 'scheduled_at',
        label: 'Scheduled at',
        sortable: true
      },

      {
        property: 'place',
        label: 'Place',
        sortable: false
      },
      {
        property: 'status',
        label: 'Status',
        sortable: true,
        render: value => t(visitStatuses[value])
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasPermission([VISITS_SHOW]),
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([VISITS_UPDATE]),
            disable: this.isUpdateDisabled,
            disableMsg: t(
              'You can update your, your employee visit and visit which is not finished'
            ),
            onClick: this.onUpdateClick
          },
          remove: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: () => {}
          },
          postpone: {
            status: this.context.hasPermission([VISITS_POSTPONE]),
            disable: this.isUpdateDisabled,
            disableMsg: t(
              'You can postpone your, your employee visit and visit which is not finished'
            ),
            onClick: this.onPostponeClick
          },
          cancel: {
            status: this.context.hasPermission([VISITS_CANCEL]),
            disable: this.isUpdateDisabled,
            disableMsg: t(
              'You can cancel your, your employee visit and visit which is not finished'
            ),
            onClick: this.onCancelVisitClick
          }
        }
      }
    ];
  }

  onDetailsClick = ({ id }) => {
    this.props.history.push(insertPathParams(ROUTE_VISITS_DETAILS, { id }));
  };

  onUpdateClick = ({ id }) => {
    this.props.history.push(insertPathParams(ROUTE_VISITS_UPDATE, { id }));
  };

  onPostponeClick = ({ id }) => {
    this.props.history.push(insertPathParams(ROUTE_VISITS_POSTPONE, { id }));
  };

  onCancelVisitClick = item => {
    this.setState({ openVisitModal: true, chosenVisit: item });
  };

  onCancelVisitSuccess = () => {
    const { chosenVisit } = this.state;
    this.props.history.push(
      insertPathParams(ROUTE_VISITS_DETAILS, { id: chosenVisit.id })
    );
  };

  onCancelVisitModalClose = () => {
    this.setState({ openVisitModal: false });
  };

  isUpdateDisabled = row => {
    const { status } = row;
    const { hasRole, user } = this.context;

    if (!hasRole([ADMIN, SUPER_ADMIN, DIRECTOR])) {
      return row.user_id !== user.id;
    }

    return status === FINISHED || status === CANCELED;
  };

  render() {
    const { visits, sort, setSort } = this.props;
    const { hasPermission } = this.context;
    const { openVisitModal, chosenVisit } = this.state;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={visits}
          sort={sort}
          setSort={setSort}
          hover
          actions={hasPermission([VISITS_SHOW])}
        />
        {openVisitModal && (
          <CancelVisitModal
            visit={chosenVisit}
            onSuccess={this.onCancelVisitSuccess}
            onClose={this.onCancelVisitModalClose}
          />
        )}
      </>
    );
  }
}

VisitsTable.defaultProps = {
  visits: []
};

VisitsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  visits: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(VisitsTable);
