import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { PRICE_LISTS_MANAGEMENT } from 'api/auth/permissions/PriceLists';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import PriceListsApi from 'api/connections/PriceLists/PriceListsApi';
import PostalCodesApi from 'api/connections/PostalCodes/PostalCodesApi';
import DepartmentsApi from 'api/connections/Departments/DepartmentsApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import PriceListsTable from 'modules/PriceLists/component/List/Table';
import PriceListsFilter from 'modules/PriceLists/component/List/Filter';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';
import PriceListFormModal from 'modules/PriceLists/component/Modals/Form';
import PriceListDeleteModal from 'modules/PriceLists/component/Modals/Delete';

class PriceListsList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      filter: {
        name: '',
        country_id: '',
        client_id: '',
        department_id: '',
        for_client_id: '',
        for_department_id: ''
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      priceLists: [],
      countries: [],
      departments: [],
      clients: [],
      loading: true,
      loadingCountries: true,
      loadingDepartments: true,
      loadingClients: true,
      actionModals: {
        modifyPriceList: null,
        formModalStatus: false,
        deleteModalStatus: false
      }
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchPriceLists = this.fetchPriceLists.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Price lists');
    if (this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])) {
      this.fetchCountries();
    }
    if (this.context.hasPermission([DEPARTMENTS_LIST])) {
      this.fetchDepartments();
    }
    this.fetchClients();
    this.fetchPriceLists();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  onCreateClick() {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        formModalStatus: true
      }
    }));
  }

  onUpdateClick(data) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        modifyPriceList: data,
        formModalStatus: true
      }
    }));
  }

  onDeleteClick(modifyPriceList) {
    this.setState(state => ({
      actionModals: {
        ...state.actionModals,
        deleteModalStatus: true,
        modifyPriceList
      }
    }));
  }

  setSort(sort) {
    this.setState({ sort: { ...this.state.sort, ...sort } }, () =>
      this.fetchPriceLists()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination }
      },
      () => this.fetchPriceLists()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchPriceLists()
    );
  }

  async fetchCountries() {
    try {
      const {
        data: { data: countries }
      } = await PostalCodesApi.getCountries({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        countries,
        loadingCountries: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchDepartments() {
    try {
      const {
        data: { data: departments }
      } = await DepartmentsApi.getDepartments({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        departments,
        loadingDepartments: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchClients() {
    try {
      const {
        data: { data: clients }
      } = await ClientsApi.getAllClients({
        per_page: Number.MAX_SAFE_INTEGER
      });

      this.setState({
        clients,
        loadingClients: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchPriceLists() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      this.setState({ loading: true });

      const {
        data: { data: priceLists, meta }
      } = await PriceListsApi.getPricesLists({
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        priceLists,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  closeAllModals() {
    this.setState({
      actionModals: {
        modifyPriceList: null,
        updateModalStatus: false,
        deleteModalStatus: false
      }
    });
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box>
          <Authorize permissions={[PRICE_LISTS_MANAGEMENT]}>
            <IconButton
              className='create-icon'
              onClick={this.onCreateClick}
              icon={<AddIcon fontSize='large' />}
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const {
      priceLists,
      countries,
      departments,
      clients,
      loading,
      loadingCountries,
      loadingDepartments,
      loadingClients,
      filter,
      sort,
      filterValidation,
      actionModals: { modifyPriceList, formModalStatus, deleteModalStatus }
    } = this.state;

    return (
      <>
        <PriceListsFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          countries={countries}
          departments={departments}
          clients={clients}
          loadingCountries={loadingCountries}
          loadingDepartments={loadingDepartments}
          loadingClients={loadingClients}
        />
        {this.renderActions()}
        <PriceListsTable
          sort={sort}
          setSort={this.setSort}
          priceLists={priceLists}
          countries={countries}
          loading={loading}
          onUpdateClick={this.onUpdateClick}
          onDeleteClick={this.onDeleteClick}
        />
        {this.renderActions()}
        {formModalStatus && (
          <PriceListFormModal
            priceList={modifyPriceList}
            setAlert={this.props.contextMethods.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchPriceLists}
            countries={countries}
          />
        )}
        {deleteModalStatus && (
          <PriceListDeleteModal
            priceList={modifyPriceList}
            handleError={this.handleError}
            setAlert={this.props.contextMethods.setAlert}
            onClose={this.closeAllModals}
            onSuccess={this.fetchPriceLists}
          />
        )}
      </>
    );
  }
}

PriceListsList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default PriceListsList;
