import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import t from 'translate/translate';
import Checkbox from 'modules/Layout/component/Checkbox';

class SaveAndSendDialog extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      copyToClient: false
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { checked } = e.target;

    this.setState({ copyToClient: checked });
  }

  render() {
    const { copyToClient } = this.state;
    const { onSuccess, onClose } = this.props;

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Confirm sending an order')}</DialogTitle>
        <DialogContent>
          <Checkbox
            onChange={this.onChange}
            name='copyToClient'
            label={t('Send copy of order to client')}
            checked={copyToClient}
          />
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button
            color='primary'
            text={t('Send')}
            onClick={() => onSuccess(copyToClient)}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

SaveAndSendDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default SaveAndSendDialog;
