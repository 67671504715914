import { Component } from 'react';
import PropTypes from 'prop-types';
import ApiError from 'api/exceptions/ApiError';
import BasicDataBox from 'modules/Visits/components/Details/BasicDataBox';
import UsersApi from 'api/connections/Users/UsersApi';
import PageLoader from 'modules/Layout/component/PageLoader';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { DEPARTMENT_MANAGER, DIRECTOR } from 'api/auth/roles';

class VisitBasicData extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      user: null,
      isLoading: !!context.hasRole([DIRECTOR, DEPARTMENT_MANAGER])
    };

    const { setAlert, visit, fetchVisit } = props;
    this.setAlert = setAlert;
    this.visit = visit;
    this.fetchVisit = fetchVisit;
  }

  componentDidMount() {
    if (this.context.hasRole([DIRECTOR, DEPARTMENT_MANAGER])) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { visit } = this.props;

    try {
      const {
        data: { data: fetchedUser }
      } = await UsersApi.getUser(visit.user_id);

      this.setState({
        user: fetchedUser,
        isLoading: false
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    const { isLoading, user } = this.state;
    const { visit } = this.props;

    return (
      <>
        {isLoading ? (
          <PageLoader />
        ) : (
          <BasicDataBox
            visit={visit}
            user={user}
            onCancelVisitSuccess={this.fetchVisit}
          />
        )}
      </>
    );
  }
}

VisitBasicData.propTypes = {
  setAlert: PropTypes.func.isRequired,
  visit: PropTypes.shape({ id: PropTypes.number, user_id: PropTypes.number })
    .isRequired,
  fetchVisit: PropTypes.func.isRequired
};

export default VisitBasicData;
