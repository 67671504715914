import { Component } from 'react';
import { ROUTE_WHOLESALERS_LIST } from 'routing/routes/Wholesalers';
import { Box, Paper } from '@material-ui/core';
import UpdateWholesalersForm from 'modules/Wholesalers/component/Update/Form';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import PropTypes from 'prop-types';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import { withRouter } from 'react-router-dom';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class WholesalersUpdate extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const { id: wholesalerId } = props.match.params;

    this.state = {
      wholesalerId
    };

    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  async componentDidMount() {
    this.setCurrentPage('Update wholesaler');
    await this.fetchData();
  }

  async fetchData() {
    const { wholesalerId } = this.state;

    try {
      const {
        data: { data: wholesaler }
      } = await WholesalersApi.getWholesaler(wholesalerId);

      this.setState({ wholesaler });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_WHOLESALERS_LIST);
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { wholesaler } = this.state;
    if (!wholesaler) return null;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <UpdateWholesalersForm wholesaler={wholesaler} />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalersUpdate.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(WholesalersUpdate);
