import { Component } from 'react';
import PropTypes from 'prop-types';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import t from 'translate/translate';
import Table from 'modules/Layout/component/List/Table';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import { Box, Typography } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash/lang';
import IconButton from 'modules/Layout/component/IconButton';
import debounce from 'lodash/debounce';

class UnassignedToEmployeeClientsList extends Component {
  static getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'address',
        label: 'Address',
        sortable: false
      }
    ];
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      clients: undefined,
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      loading: false,
      selected: []
    };

    this.fetchClientsDebounce = debounce(this.fetchClients, 500);

    this.setPagination = this.setPagination.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.department_id !== prevProps.department_id ||
      this.props.address !== prevProps.address ||
      this.props.role !== prevProps.role
    ) {
      this.onFilterUpdate(this.props.address !== prevProps.address);
    }
  }

  onFilterUpdate(isAddressChanged) {
    const { department_id } = this.props;

    this.setState(
      {
        selected: [],
        clients: undefined,
        pagination: { ...this.state.pagination, page: 1 },
        loading: department_id
      },
      () => {
        this.props.onChange({ target: { name: 'selected', value: [] } });
      }
    );

    if (this.props.department_id) {
      isAddressChanged ? this.fetchClientsDebounce() : this.fetchClients();
    }
  }

  onSelect(id) {
    this.setState(({ selected: prevSelected }) => {
      const selected = prevSelected.includes(id)
        ? prevSelected.filter(selectedId => selectedId !== id)
        : [...prevSelected, id];

      this.props.onChange({ target: { name: 'selected', value: selected } });

      return { selected };
    });
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchClients()
    );
  }

  clearSelected() {
    this.setState({ selected: [] }, () => {
      this.props.onChange({ target: { name: 'selected', value: [] } });
    });
  }

  async fetchClients() {
    const { department_id, role, address } = this.props;
    const { per_page, page } = this.state.pagination;
    const pathParams = { department_id, role, address, per_page, page };

    try {
      const {
        data: { data: clients, meta }
      } = await ClientsApi.getUnassignedClient(pathParams);

      this.setState({
        clients,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  render() {
    const { clients, pagination, loading, selected } = this.state;

    return (
      <>
        <Box
          mt={2}
          mb={2}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6'>
            {t('List of clients not assigned to employees')}
          </Typography>
          <Box display='flex' alignItems='center'>
            <Typography variant='body1'>{`${t('Selected')}: ${
              selected.length
            }`}</Typography>
            <IconButton
              className='delete-icon'
              onClick={this.clearSelected}
              icon={<DeleteForeverIcon />}
              disabled={isEmpty(selected)}
              tooltipMsg='no clients selected'
              alt='clear selected clients'
            />
          </Box>
        </Box>
        <Table
          cols={UnassignedToEmployeeClientsList.getCols()}
          rows={clients}
          loading={loading}
          bodyText={!clients ? t('Enter the correct data in the filter') : ''}
          selected={selected}
          onSelect={this.onSelect}
        />
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </>
    );
  }
}

UnassignedToEmployeeClientsList.defaultProps = {
  department_id: undefined
};

UnassignedToEmployeeClientsList.propTypes = {
  department_id: PropTypes.number,
  role: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default UnassignedToEmployeeClientsList;
