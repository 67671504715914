import useStyles from 'modules/Layout/component/List/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import t from 'translate/translate';
import TableRow from '@material-ui/core/TableRow';
import { useState } from 'react';
import ExtendButton from 'modules/Statistics/SalesComparison/component/List/Table/ExtendButton';
import { isEmpty } from 'lodash/lang';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';
import renderPrice from 'modules/Products/utils/renderPrice';

export const HeaderCell = ({ text }) => {
  const classes = useStyles();

  return (
    <TableCell align='center'>
      <Typography variant='subtitle1' className={classes.cellHeader}>
        {t(text)}
      </Typography>
    </TableCell>
  );
};

export const DataCell = ({ text }) => {
  return <TableCell align='center'>{text}</TableCell>;
};

const RowWholesaler = ({ row, currency }) => {
  return (
    <TableRow>
      <TableCell />
      <DataCell text={row.name} />
      <DataCell text={renderPrice(row.price_sum, currency)} />
      <DataCell text={row.quantity_sum} />
    </TableRow>
  );
};

const RowProduct = ({ row, currency }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          {row.wholesalers && (
            <ExtendButton
              open={open}
              onClick={() => setOpen(!open)}
              disabled={isEmpty(row.wholesalers)}
              tooltipMsg='No wholesalers'
            />
          )}
        </TableCell>
        <DataCell text={row.sku} />
        <DataCell text={renderPrice(row.price_sum, currency)} />
        <DataCell text={row.quantity_sum} />
      </TableRow>
      {row.wholesalers && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box margin={1}>
                <Typography variant='h6' gutterBottom component='div'>
                  {t('Wholesalers')}
                </Typography>
                <Table size='small' aria-label='purchases'>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <HeaderCell text='Wholesaler' />
                      <HeaderCell text='Price sum' />
                      <HeaderCell text='Quantity sum' />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.wholesalers.map(wholesaler => (
                      <RowWholesaler
                        row={wholesaler}
                        key={wholesaler.id}
                        currency={currency}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const RowSubcategory = ({ row, currency }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <ExtendButton
            open={open}
            onClick={() => setOpen(!open)}
            disabled={isEmpty(row.products)}
            tooltipMsg='No products'
          />
        </TableCell>
        <DataCell text={row.name} />
        <DataCell text={renderPrice(row.price_sum, currency)} />
        <DataCell text={row.quantity_sum} />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('Products')}
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <HeaderCell text='Name' />
                    <HeaderCell text='Price sum' />
                    <HeaderCell text='Quantity sum' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.products.map(product => (
                    <RowProduct
                      row={product}
                      key={product.id}
                      currency={currency}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const RowCategory = ({ row, currency }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <ExtendButton
            open={open}
            onClick={() => setOpen(!open)}
            disabled={isEmpty(row.subcategories)}
            tooltipMsg='No subcategories'
          />
        </TableCell>
        <DataCell text={row.name} />
        <DataCell text={renderPrice(row.price_sum, currency)} />
        <DataCell text={row.quantity_sum} />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('Subcategories')}
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <HeaderCell text='Subcategory' />
                    <HeaderCell text='Price sum' />
                    <HeaderCell text='Quantity sum' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subcategories.map(subcategory => (
                    <RowSubcategory
                      row={subcategory}
                      key={subcategory.id}
                      currency={currency}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Row = ({ row, currency }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <ExtendButton
            open={open}
            onClick={() => setOpen(!open)}
            disabled={isEmpty(row.categories)}
            tooltipMsg='No categories'
          />
        </TableCell>
        <DataCell text={row.name} />
        <DataCell text={renderPrice(row.price_sum, currency)} />
        <DataCell text={row.quantity_sum} />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                {t('Categories')}
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <HeaderCell text='Category' />
                    <HeaderCell text='Price sum' />
                    <HeaderCell text='Quantity sum' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.categories.map(category => (
                    <RowCategory
                      row={category}
                      key={category.id}
                      currency={currency}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const renderData = (rows, currency) => {
  return (
    <>
      {rows.map(row => (
        <Row key={row.id} row={row} currency={currency} />
      ))}
    </>
  );
};

export const renderBodyText = bodyText => {
  return (
    <TableRow>
      <TableCell align='center' colSpan={4}>
        <Typography variant='subtitle1' color='textSecondary'>
          {t(bodyText)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    price_sum: PropTypes.number,
    quantity_sum: PropTypes.number
  }).isRequired,
  currency: PropTypes.string.isRequired
};

RowCategory.propTypes = {
  row: PropTypes.shape({
    subcategories: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    price_sum: PropTypes.number,
    quantity_sum: PropTypes.number
  }).isRequired,
  currency: PropTypes.string.isRequired
};

RowSubcategory.propTypes = {
  row: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    price_sum: PropTypes.number,
    quantity_sum: PropTypes.number
  }).isRequired,
  currency: PropTypes.string.isRequired
};

RowProduct.propTypes = {
  row: PropTypes.shape({
    wholesalers: PropTypes.arrayOf(PropTypes.shape({})),
    sku: PropTypes.string,
    price_sum: PropTypes.number,
    quantity_sum: PropTypes.number
  }).isRequired,
  currency: PropTypes.string.isRequired
};

RowWholesaler.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string,
    price_sum: PropTypes.number,
    quantity_sum: PropTypes.number
  }).isRequired,
  currency: PropTypes.string.isRequired
};

DataCell.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

HeaderCell.propTypes = {
  text: PropTypes.string.isRequired
};
