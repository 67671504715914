import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class WholesalersContactApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.createWholesalerContactPath = 'wholesalers/:id/contacts';
    this.wholesalerContactPath =
      'wholesalers/:wholesalerId/contacts/:contactId';
  }

  createWholesalerContact(id, payload) {
    return this.post(
      insertPathParams(this.createWholesalerContactPath, { id }),
      payload
    );
  }

  updateWholesalerContact(ids, payload) {
    return this.put(insertPathParams(this.wholesalerContactPath, ids), payload);
  }

  deleteWholesalerContact(ids) {
    return this.delete(insertPathParams(this.wholesalerContactPath, ids));
  }
}

export default new WholesalersContactApi();
