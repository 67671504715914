import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box, Paper, TableSortLabel } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import t from 'translate/translate';
import Authorize from 'modules/Auth/component/Authorize';

import { ROUTE_CLIENTS_LIST } from 'routing/routes/Clients';
import { CLIENTS_UPDATE } from 'api/auth/permissions/Clients';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import ClientNotesFilter from 'modules/Clients/component/Details/Notes/List/Filter';
import ClientsNotesApi from 'api/connections/Clients/ClientsNotesApi';
import ClientNote from 'modules/Clients/component/Details/Notes/List/ListItem';
import ClientNoteCreateDialog from 'modules/Clients/component/Details/Notes/Create/Dialog';
import ClientNoteUpdateDialog from 'modules/Clients/component/Details/Notes/Update/Dialog';
import ClientNoteDeleteDialog from 'modules/Clients/component/Details/Notes/Delete/Dialog';

const useStyles = {
  icon: {
    fontSize: '1.8rem'
  }
};

class ClientDetailsNotes extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.clientId = parseInt(props.match.params.id, 10);
    this.state = {
      notes: [],
      sort: {
        sort_field: '',
        sort_order: 'asc'
      },
      filter: {
        creator_name: '',
        created_at_from: null,
        created_at_to: null
      },
      filterValidation: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      createDialogOpenStatus: false,
      updateDialogOpenStatus: false,
      deleteDialogOpenStatus: false,
      noteToModify: null
    };

    this.setAlert = props.setAlert;

    this.setSort = this.setSort.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onCreateSuccess = this.onCreateSuccess.bind(this);
    this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  componentDidMount() {
    this.fetchClientNotes();
  }

  onDialogClose() {
    this.setState({
      createDialogOpenStatus: false,
      updateDialogOpenStatus: false,
      deleteDialogOpenStatus: false,
      noteToModify: null
    });
  }

  onCreateClick() {
    this.setState({ createDialogOpenStatus: true });
  }

  onUpdateClick(noteToModify) {
    this.setState({ updateDialogOpenStatus: true, noteToModify });
  }

  onDeleteClick(noteToModify) {
    this.setState({ deleteDialogOpenStatus: true, noteToModify });
  }

  onCreateSuccess(note) {
    this.setState(({ notes: prevNotes }) => {
      return { notes: [...prevNotes, note] };
    });
  }

  onUpdateSuccess(note) {
    this.setState(prevState => {
      return {
        notes: prevState.notes.map(prevNote =>
          prevNote.id === note.id ? note : prevNote
        ),
        noteToModify: null
      };
    });
  }

  onDeleteSuccess(noteId) {
    this.setState(prevState => {
      return {
        notes: prevState.notes.filter(prevNote => prevNote.id !== noteId),
        noteToModify: null
      };
    });
  }

  setSort(sort) {
    this.setState(
      prevState => {
        const { sort: prevSort } = prevState;
        return { sort: { ...prevSort, ...sort } };
      },
      () => this.fetchClientNotes()
    );
  }

  setFilter(filter) {
    this.setState(
      prevState => {
        const { filter: prevFilter, pagination: prevPagination } = prevState;
        return {
          filter: { ...prevFilter, ...filter },
          pagination: { ...prevPagination, page: 1 }
        };
      },
      () => this.fetchClientNotes()
    );
  }

  setPagination(pagination) {
    this.setState(
      prevState => {
        const { pagination: prevPagination } = prevState;
        return { pagination: { ...prevPagination, ...pagination } };
      },
      () => this.fetchClientNotes()
    );
  }

  async fetchClientNotes() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    try {
      const {
        data: { data: notes, meta }
      } = await ClientsNotesApi.getClientNotes(this.clientId, {
        ...sort,
        ...filter,
        per_page,
        page
      });

      this.setState({
        notes,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);

        if (err instanceof NotFoundApiError) {
          this.props.history.push(ROUTE_CLIENTS_LIST);
        }

        if (err instanceof ValidationApiError) {
          const newValidateState = err.processApiValidationError();
          this.setState(({ filterValidation: prevValidation }) => {
            return {
              filterValidation: { ...prevValidation, ...newValidateState }
            };
          });
        }
      }
    }
  }

  renderAction() {
    const { pagination } = this.state;
    const { updateStatus } = this.props;

    return (
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        width={1}
      >
        <Box>
          <Authorize permissions={[CLIENTS_UPDATE]}>
            <IconButton
              className='create-icon'
              onClick={this.onCreateClick}
              icon={<AddIcon fontSize='large' />}
              disabled={!updateStatus}
              tooltipMsg='The client is not assigned to you or to your subordinate'
              alt='create'
            />
          </Authorize>
        </Box>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
          rowsPerPageOptions={[5, 15, 30, 100]}
        />
      </Box>
    );
  }

  render() {
    const {
      notes,
      sort,
      filter,
      filterValidation,
      createDialogOpenStatus,
      updateDialogOpenStatus,
      deleteDialogOpenStatus,
      noteToModify
    } = this.state;
    const { updateStatus } = this.props;
    const sortOptions = [
      { key: 'creator_name', value: 'Creator name' },
      { key: 'created_at', value: 'Created at' }
    ];

    return (
      <Paper style={{ padding: '1rem' }}>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', xl: 'row' }}
          alignItems='center'
        >
          <Box width={1}>
            <ClientNotesFilter
              filter={filter}
              validation={filterValidation}
              setFilter={this.setFilter}
            />
          </Box>
          {this.renderAction()}
          <Box
            display='flex'
            width={1}
            maxWidth='300px'
            marginLeft={{ xl: '1rem' }}
          >
            <Select
              name='sort_field'
              value={sort.sort_field}
              options={formatOptions(sortOptions, 'key', 'value', true)}
              emptyValueText={t('None')}
              label={t('Sort by')}
              style={{ input: { height: '48px' } }}
              margin='none'
              onChange={({ target: { name, value } }) =>
                this.setSort({ [name]: value })
              }
            />
            <TableSortLabel
              active
              direction={sort.sort_order}
              onClick={() =>
                this.setSort({
                  sort_order: sort.sort_order === 'asc' ? 'desc' : 'asc'
                })
              }
              classes={{ icon: this.props.classes.icon }}
            />
          </Box>
        </Box>
        {notes.map(note => (
          <ClientNote
            note={note}
            key={note.id}
            updateStatus={updateStatus}
            onUpdateClick={this.onUpdateClick}
            onDeleteClick={this.onDeleteClick}
          />
        ))}
        {this.renderAction()}
        {createDialogOpenStatus && (
          <ClientNoteCreateDialog
            clientId={this.clientId}
            onSuccess={this.onCreateSuccess}
            onClose={this.onDialogClose}
          />
        )}
        {updateDialogOpenStatus && (
          <ClientNoteUpdateDialog
            clientId={this.clientId}
            note={noteToModify}
            onSuccess={this.onUpdateSuccess}
            onClose={this.onDialogClose}
          />
        )}
        {deleteDialogOpenStatus && (
          <ClientNoteDeleteDialog
            clientId={this.clientId}
            noteId={noteToModify.id}
            onSuccess={this.onDeleteSuccess}
            onClose={this.onDialogClose}
          />
        )}
      </Paper>
    );
  }
}

ClientDetailsNotes.propTypes = {
  updateStatus: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  setAlert: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(withStyles(useStyles)(ClientDetailsNotes));
