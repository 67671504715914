import { Component } from 'react';
import TreeItem from 'modules/Layout/component/TreeView/TreeItem';
import PropTypes from 'prop-types';
import Loader from 'modules/Layout/component/Loader';
import { isEmpty } from 'lodash/lang';
import capitalize from 'modules/Shared/utils/capitalize';
import ClientsTreeSubsubregions from 'modules/Clients/component/ClientsTree/Subsubregions';
import ClientsTreeCities from 'modules/Clients/component/ClientsTree/Cities';

class ClientsTreeSubregions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataToRender: undefined
    };
  }

  async componentDidMount() {
    const dataToRender = await this.props.getDataToRender(
      'subregions',
      this.props.regionId
    );

    this.setState({ dataToRender });
  }

  render() {
    const { dataToRender } = this.state;
    const { selectMode, isParentSelected, depth } = this.props;

    if (dataToRender === undefined) return <Loader />;

    if (isEmpty(dataToRender)) return null;

    return dataToRender.map(subregion => {
      const isVisible = this.props.isVisible('subregions', subregion.id);
      const isSelected =
        isParentSelected || this.props.isSelected('subregions', subregion.id);

      if (!isVisible && !isSelected && !selectMode) return null;

      const item = {
        postalCodeLevel: 'subregions',
        region_id: this.props.regionId,
        ...subregion
      };

      return (
        <TreeItem
          itemData={item}
          key={`subregion-${subregion.id}`}
          nodeId={`subregion-${subregion.id}`}
          labelText={capitalize(subregion.name)}
          labelInfo={
            selectMode
              ? subregion.clients_number
              : subregion.assigned_clients_number
          }
          selectMode={selectMode}
          isSelected={isSelected}
          onSelect={this.props.onSelect}
          actionBtn={
            !selectMode && isSelected && this.props.renderDeleteItemBtn(item)
          }
        >
          {depth === 1 && (
            <ClientsTreeCities
              regionId={this.props.regionId}
              subregionId={subregion.id}
              getDataToRender={this.props.getDataToRender}
              isVisible={this.props.isVisible}
              isParentSelected={isSelected}
              isSelected={this.props.isSelected}
              selectMode={selectMode}
              onSelect={this.props.onSelect}
              renderDeleteItemBtn={this.props.renderDeleteItemBtn}
            />
          )}
          {depth === 2 && (
            <ClientsTreeSubsubregions
              regionId={this.props.regionId}
              subregionId={subregion.id}
              getDataToRender={this.props.getDataToRender}
              isVisible={this.props.isVisible}
              isParentSelected={isSelected}
              isSelected={this.props.isSelected}
              selectMode={selectMode}
              onSelect={this.props.onSelect}
              renderDeleteItemBtn={this.props.renderDeleteItemBtn}
            />
          )}
        </TreeItem>
      );
    });
  }
}

ClientsTreeSubregions.propTypes = {
  regionId: PropTypes.number.isRequired,
  getDataToRender: PropTypes.func.isRequired,
  isVisible: PropTypes.func.isRequired,
  isParentSelected: PropTypes.bool.isRequired,
  isSelected: PropTypes.func.isRequired,
  selectMode: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  depth: PropTypes.number.isRequired,
  renderDeleteItemBtn: PropTypes.func.isRequired
};

export default ClientsTreeSubregions;
