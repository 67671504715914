import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import ProfileApi from 'api/connections/Users/ProfileApi';
import t from 'translate/translate';

import { ROUTE_LOGIN } from 'routing/routes/Auth';
import Button from 'modules/Layout/component/Button';

import PublicWrapper from 'modules/Layout/view/PublicWrapper';

const ConfirmEmail = () => {
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const getToken = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('token');
  };

  const runApiCall = async token => {
    try {
      const res = await ProfileApi.confirmEmail({ token });
      if (res.status === 204) {
        setStatus(!status);
        setLoading(!loading);
      }
    } catch (err) {
      setLoading(!loading);
    }
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      runApiCall(token);
    } else {
      setLoading(!loading);
    }
  }, []);

  return (
    <PublicWrapper>
      <Box my={5} textAlign='center'>
        {loading && <CircularProgress color='inherit' size={40} />}
        {!loading && status && (
          <Typography variant='h5' color='primary'>
            {t('Email confirmed, You can log in now')}
          </Typography>
        )}
        {!loading && !status && (
          <Typography variant='h5' color='error'>
            {t('Something goes wrong, try again later')}
          </Typography>
        )}
      </Box>
      {!loading && (
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Link to={ROUTE_LOGIN} className='router-link'>
            <Button text={t('Log in')} fullWidth />
          </Link>
        </Box>
      )}
    </PublicWrapper>
  );
};

export default ConfirmEmail;
