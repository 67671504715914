import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import AddIcon from '@material-ui/icons/Add';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import insertPathParams from 'api/utils/insertPathParams';
import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon
} from '@material-ui/icons';
import t from 'translate/translate';

import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import {
  SHOPPING_GROUPS_UPDATE,
  SHOPPING_GROUPS_DELETE
} from 'api/auth/permissions/SalesGroups';
import {
  ROUTE_SHOPPING_GROUPS_LIST,
  ROUTE_SHOPPING_GROUPS_UPDATE
} from 'routing/routes/ShoppingGroups';
import SalesGroupClientsList from 'modules/SalesGroups/component/Details/Clients/List';
import SaleGroupClientsCreateDialog from 'modules/SalesGroups/component/Details/Clients/Create/Dialog';
import DeleteSalesGroupDialog from 'modules/SalesGroups/component/Delete/Dialog';

const SalesGroupsDetailsBasicData = props => {
  const history = useHistory();
  const {
    salesGroup,
    salesGroup: {
      id,
      name,
      description,
      country_id: salesGroupCountryId,
      clients
    },
    countries,
    onAssignClientToSalesGroupSuccess,
    onDetachClientFromSalesGroupSuccess
  } = props;

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [assignClientDialogStatus, setAssignClientDialogStatus] = useState(
    false
  );

  const getCountry = () => {
    if (!salesGroupCountryId) return null;
    return countries.find(country => country.id === salesGroupCountryId)?.name;
  };

  const onEditClick = () => {
    history.push(insertPathParams(ROUTE_SHOPPING_GROUPS_UPDATE, { id }));
  };

  const onDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const onAssignClientClick = () => {
    setAssignClientDialogStatus(true);
  };

  const onDialogClose = () => {
    setDeleteDialogOpen(false);
    setAssignClientDialogStatus(false);
  };

  const onDeleteSuccess = () => {
    history.push(ROUTE_SHOPPING_GROUPS_LIST);
  };

  if (!id) {
    return (
      <Paper style={{ padding: '1rem' }}>
        <Box display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <Grid container spacing={3} justify='center'>
      <Grid item xs={12} lg={5}>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12}>
            <Paper className='p1'>
              <Box display='flex' justifyContent='space-between'>
                <Box>
                  <TextDataSet label='Name' data={name} />
                  <TextDataSet label='Description' data={description} />
                  <Authorize permissions={[POSTAL_CODES_COUNTRIES_SHOW]}>
                    <TextDataSet label='Country' data={getCountry()} />
                  </Authorize>
                </Box>
                <Box display='flex' alignItems='flex-start'>
                  <Authorize permissions={[SHOPPING_GROUPS_UPDATE]}>
                    <IconButton
                      className='update-icon'
                      onClick={onEditClick}
                      icon={<EditIcon />}
                      alt='update'
                    />
                  </Authorize>
                  <Authorize permissions={[SHOPPING_GROUPS_DELETE]}>
                    <IconButton
                      className='delete-icon'
                      onClick={onDeleteClick}
                      icon={<DeleteForeverIcon />}
                      alt='delete'
                    />
                  </Authorize>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Paper className='p1'>
          <Typography
            component='h6'
            variant='h6'
            style={{ marginBottom: '1rem' }}
          >
            {t('Clients')}
          </Typography>
          <SalesGroupClientsList
            salesGroupId={id}
            clients={clients}
            onDetachClientFromSalesGroupSuccess={
              onDetachClientFromSalesGroupSuccess
            }
          />
          <Authorize permissions={[SHOPPING_GROUPS_UPDATE]}>
            <Box display='flex' alignItems='center'>
              <IconButton
                className='create-icon'
                onClick={onAssignClientClick}
                icon={<AddIcon color='primary' fontSize='large' />}
                alt='create'
              />
            </Box>
          </Authorize>
        </Paper>
      </Grid>
      {deleteDialogOpen && (
        <DeleteSalesGroupDialog
          salesGroup={salesGroup}
          onClose={onDialogClose}
          onSuccess={onDeleteSuccess}
        />
      )}
      {assignClientDialogStatus && (
        <SaleGroupClientsCreateDialog
          salesGroup={salesGroup}
          onClose={onDialogClose}
          onSuccess={onAssignClientToSalesGroupSuccess}
        />
      )}
    </Grid>
  );
};

SalesGroupsDetailsBasicData.propTypes = {
  salesGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    country_id: PropTypes.number,
    clients: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAssignClientToSalesGroupSuccess: PropTypes.func.isRequired,
  onDetachClientFromSalesGroupSuccess: PropTypes.func.isRequired
};

export default SalesGroupsDetailsBasicData;
