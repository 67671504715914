import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { Typography } from '@material-ui/core';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';

class CancelVisitModal extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.onSuccess = props.onSuccess;
    this.onClose = props.onClose;
    this.visit = props.visit;

    this.state = {
      formData: {
        cancel_reason: ''
      },
      validation: {
        cancel_reason: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Cancel reason')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.onSuccess();
    this.onClose();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.context.setAlert(message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { validation } = state;
      const { value } = e.target;

      return {
        formData: {
          cancel_reason: value
        },
        validation: {
          cancel_reason: {
            ...validation.cancel_reason,
            status: false
          }
        }
      };
    });
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    this.setState({ loading: true });
    try {
      await VisitsApi.cancelVisit(this.visit.id, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { cancel_reason },
      validation,
      loading
    } = this.state;

    const {
      visit: { client_name, place, user_name, scheduled_at }
    } = this.props;

    return (
      <Dialog
        open
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Cancel confirmation')}</DialogTitle>
        <DialogContent>
          <Typography variant='h6' component='h6'>
            {t('Are you sure you want to to cancel this visit?')}
          </Typography>
          <TextDataSet label={t('Client')} data={client_name} />
          <TextDataSet label={t('Place')} data={place} />
          <TextDataSet label={t('User')} data={user_name} />
          <TextDataSet label={t('Date')} data={scheduled_at} />
          <form id='cancel-visit-form' noValidate onSubmit={this.onSubmit}>
            <Input
              name='cancel_reason'
              label='Cancel reason'
              value={cancel_reason}
              required
              errorStatus={validation.cancel_reason.status}
              errorText={validation.cancel_reason.message}
              onChange={this.onChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.onClose} />
          <Button
            form='cancel-visit-form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

CancelVisitModal.propTypes = {
  visit: PropTypes.shape({
    client_name: PropTypes.string,
    place: PropTypes.string,
    user_name: PropTypes.string,
    scheduled_at: PropTypes.string
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CancelVisitModal;
