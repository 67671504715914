import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import AuthContext from 'modules/Auth/context/Auth/authContext';

const Authorize = ({
  children,
  redirect,
  permissions,
  anyPermissions,
  roles
}) => {
  const { hasPermission, hasRole, hasSomeOfPermission } = useContext(
    AuthContext
  );

  if (permissions && hasPermission(permissions)) {
    return children;
  }

  if (anyPermissions && hasSomeOfPermission(anyPermissions)) {
    return children;
  }

  if (roles && hasRole(roles)) {
    return children;
  }

  if (redirect) return <Redirect to='/404' />;

  return null;
};

Authorize.defaultProps = {
  redirect: null,
  permissions: null,
  anyPermissions: null,
  roles: null
};

Authorize.propTypes = {
  children: PropTypes.node.isRequired,
  redirect: PropTypes.bool,
  permissions: PropTypes.arrayOf(PropTypes.string),
  anyPermissions: PropTypes.arrayOf(PropTypes.string),
  roles: PropTypes.arrayOf(PropTypes.string)
};

export default Authorize;
