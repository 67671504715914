import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { validateBaseOnFormInputs } from 'modules/Shared/utils/validator';
import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';

class PriceListsChangePriceModal extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    const { price } = props.product;

    this.state = {
      formData: {
        price
      },
      validation: {
        price: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('price')
          })
        }
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation(e.target.elements);
    if (isValid) {
      const { price } = this.state.formData;
      const { id } = this.props.product;

      this.props.onSuccess(id, price);
      this.props.onClose();
    }
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation(formElements) {
    const { validation } = this.state;
    const [isValid, validateBaseOnFormInputsState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!isValid) {
      this.setState({ validation: validateBaseOnFormInputsState });
    }

    return isValid;
  }

  render() {
    const {
      formData: { price },
      validation
    } = this.state;

    const {
      onClose,
      product: { sku }
    } = this.props;

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>
          {t('Update price for product <%=sku%>', { sku })}
        </DialogTitle>
        <DialogContent>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            <Input
              name='price'
              label='Price'
              value={price}
              onChange={this.onChange}
              required
              errorStatus={validation.price.status}
              errorText={validation.price.message}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button form='form' type='submit' color='primary' text={t('Save')} />
        </DialogActions>
      </Dialog>
    );
  }
}

PriceListsChangePriceModal.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PriceListsChangePriceModal;
