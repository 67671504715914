import PropTypes from 'prop-types';
import RoomIcon from '@material-ui/icons/Room';
import { IconButton, Typography } from '@material-ui/core';
import VisitInfoWindow from 'modules/Visits/components/shared/VisitInfoWindow';
import useStyles from 'modules/Visits/components/shared/styles';

const VisitMarker = ({ visit, lat, lng, $hover, order }) => {
  const classes = useStyles();

  return (
    <>
      <IconButton
        lat={parseFloat(lat) || 0.0}
        lng={parseFloat(lng) || 0.0}
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <RoomIcon fontSize='large' style={{ color: '#c73a3a' }} />
      </IconButton>
      <Typography className={classes.order}>{order}</Typography>
      <VisitInfoWindow visit={visit} show={$hover} />
    </>
  );
};

VisitMarker.defaultProps = {
  $hover: false
};

VisitMarker.propTypes = {
  visit: PropTypes.shape({}).isRequired,
  lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  $hover: PropTypes.bool,
  order: PropTypes.number.isRequired
};

export default VisitMarker;
