export const formatCategoriesError = (errors, categoriesObj) => {
  let groupError = '';
  let categoriesError = '';

  /* eslint no-restricted-syntax: off */
  for (const [key, value] of Object.entries(errors)) {
    const [groupName, id] = key.split('.');
    const { name } =
      groupName === 'product_groups'
        ? categoriesObj.productGroups.find(item => item.id === parseInt(id, 10))
        : categoriesObj.categories.find(item => item.id === parseInt(id, 10));

    groupName === 'product_groups'
      ? (groupError += `${name}: ${value}`)
      : (categoriesError += `${name}: ${value}`);
  }

  return { groupError, categoriesError };
};
