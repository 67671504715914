import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import ResultsTable from 'modules/SalesResults/component/ResultsDetails/Table';
import { useResize } from 'modules/Shared/hook';
import {
  renderWholesalersBars,
  renderWholesalersPies
} from 'modules/Statistics/SalesComparison/utils/renderData';

const SalesComparisonWholesalersCharts = props => {
  const {
    data: { wholesalersSales },
    viewType,
    onDrillDown,
    nameSuffix,
    thirdSection
  } = props;

  const componentRef = useRef();
  const { width } = useResize(componentRef, thirdSection);

  const chartWidth = width > 590 ? width / 14 : width / 7;
  const barWidth = width > 590 ? width / 8 : width / 4;

  return (
    <Box display='flex' justifyContent='space-around' ref={componentRef}>
      {viewType === 'pie' && (
        <Grid container spacing={4}>
          {renderWholesalersPies(
            nameSuffix,
            wholesalersSales,
            chartWidth,
            onDrillDown
          )}
        </Grid>
      )}
      {viewType === 'bar' && (
        <Grid container spacing={4}>
          {renderWholesalersBars(
            nameSuffix,
            wholesalersSales,
            barWidth,
            onDrillDown
          )}
        </Grid>
      )}
      {viewType === 'table' && (
        <Grid container spacing={4}>
          {wholesalersSales.map((wholesaler, index) => (
            <Grid item xs={12} md={6}>
              <Box mb={4}>
                <Typography align='center' variant='h6'>
                  {wholesaler.name}
                </Typography>
              </Box>
              <ResultsTable
                data={wholesaler.data}
                onDrillDown={data => onDrillDown(data)}
                nameSuffix={nameSuffix + index + 1}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

SalesComparisonWholesalersCharts.propTypes = {
  data: PropTypes.shape({
    wholesalersSales: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  viewType: PropTypes.string.isRequired,
  onDrillDown: PropTypes.func.isRequired,
  nameSuffix: PropTypes.number.isRequired,
  thirdSection: PropTypes.bool.isRequired
};

export default SalesComparisonWholesalersCharts;
