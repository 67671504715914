import { Component } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import { Box, Typography } from '@material-ui/core';
import renderPrice from 'modules/Products/utils/renderPrice';

class OrderSummary extends Component {
  renderCommunication(totalWithDiscountAndPromotions) {
    const {
      totalPrice,
      orderDate,
      calculationResult: { totalNetValueBeforeReduction },
      currency
    } = this.props;

    if (
      totalPrice &&
      totalNetValueBeforeReduction &&
      parseFloat(totalPrice).toFixed(2) !==
        parseFloat(totalWithDiscountAndPromotions).toFixed(2)
    ) {
      return (
        <Box my={1}>
          <Typography
            variant='h6'
            color='error'
            align='justify'
            style={{
              lineHeight: 1.4,
              fontWeight: 600
            }}
          >
            {t(
              'The order was prepared on <%=date%>. At that time, the order value was <%=previousPrice%> <%=currency%>. Currently, the order value is <%=currentPrice%> <%=currency%>. This is due to the change in discounts, promotions and product prices. Please make sure the current price is acceptable before shipping your order.',
              {
                date: orderDate,
                previousPrice: totalPrice,
                currentPrice: totalWithDiscountAndPromotions,
                currency
              }
            )}
          </Typography>
        </Box>
      );
    }
  }

  render() {
    const { isDetails, calculationResult, currency } = this.props;

    return (
      <>
        {isDetails && this.renderCommunication(calculationResult.totalNetValue)}
        <TextDataSet
          data={renderPrice(
            calculationResult.totalGrossValueBeforeReduction,
            currency
          )}
          label={t('Order value without promotions and discounts')}
        />
        <TextDataSet
          data={renderPrice(
            calculationResult.totalGrossReductionValue,
            currency
          )}
          label={t('Promotions and discounts value')}
        />
        <TextDataSet
          data={renderPrice(
            calculationResult.totalGrossGratisProductsValue,
            currency
          )}
          label={t('Gratis products before discount')}
        />
        <TextDataSet
          data={renderPrice(calculationResult.totalGrossValue, currency)}
          label={t('Total order value')}
        />
      </>
    );
  }
}

OrderSummary.defaultProps = {
  isDetails: false,
  totalPrice: null,
  orderDate: null,
  currency: null
};

OrderSummary.propTypes = {
  isDetails: PropTypes.bool,
  currency: PropTypes.string,
  totalPrice: PropTypes.string,
  orderDate: PropTypes.string,
  calculationResult: PropTypes.shape({
    totalNetValueBeforeReduction: PropTypes.number.isRequired,
    totalGrossValueBeforeReduction: PropTypes.number.isRequired,
    totalGrossReductionValue: PropTypes.number.isRequired,
    totalGrossGratisProductsValue: PropTypes.number.isRequired,
    totalGrossValue: PropTypes.number.isRequired,
    totalNetValueBeforeDiscount: PropTypes.number.isRequired,
    totalNetValue: PropTypes.number.isRequired
  }).isRequired
};

export default OrderSummary;
