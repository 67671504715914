export default {
  'Sales results archive': 'Archiwum Wyników Sprzedażowych',
  'Sales results': 'Wyniki sprzedażowe',
  'Departments sales results archive': 'Archiwum wyników sprzedażowych działów',
  'Department sales results details': 'Szczegóły wyników sprzedażowych działu',
  'User sales results details': 'Szczegóły wyników sprzedażowych użytkownika',
  'Month from': 'Miesiąc od',
  'Month to': 'Miesiąc do',
  month: 'miesiąc',
  recalculate: 'przelicz',
  summarize: 'podsumuj',
  'Department name': 'Nazwa działu',
  Period: 'Okres',
  'Period type': 'Typ okresu',
  'Number of products sold': 'Ilość sprzedanych produktów',
  'Value of products sold': 'Wartość sprzedanych produktów',
  'Summary confirmation': 'Potwierdzenie podsumowania',
  'Recalculate confirmation': 'Potwierdzenie przeliczenia',
  'Are you sure you want to summary sales result for department <%=department_name%> in month <%=monthName%>?':
    'Czy na pewno chcesz podsumować wyniki sprzedaży działu <%=department_name%> za miesiąc <%=monthName%>?',
  "Are you sure you want to recalculate sales result for department <%=department_name%> in month <%=monthName%>? There may be changes in the structure of the category tree or the department's categories, which will have an impact on the sales data included in the department's month summary.":
    'Czy na pewno chcesz przeliczyć wyniki sprzedaży działu <%=department_name%> za miesiąc <%=monthName%>? Mogły zajść zmiany w strukturze drzewa kategorii lub powiązań działu z obsługiwanymi kategoriami co będzie miało wpływ na dane sprzedażowe uwzględniane w wyniku podsumowania miesiąca.',
  'Check the results over a longer period': 'Sprawdź wyniki z dłuższego okresu',
  Year: 'Rok',
  year: 'rok',
  Quarter: 'Kwartał',
  quarter: 'kwartał',
  Month: 'Miesiąc',
  'Comprehensive data': 'Dane całościowe',
  'switch to bar chart': 'przełącz wykres słupkowy',
  'switch to pie chart': 'przełącz wykres kołowy',
  'switch to table': 'przełącz na tabele',
  'switch to quantity': 'wyświetl ilość',
  'switch to values': 'wyświetl wartości',
  'Compare the periods': 'Porównaj okresy',
  'Sales summary': 'Podsumowanie sprzedaży',
  'Comparison of sales results': 'Porównanie wyników sprzedażowych',
  'First chart': 'Pierwszy wykres',
  'Second chart': 'Drugi wykres',
  'Third chart': 'Trzeci wykres',
  'Select the date from and to for the first chart':
    'Wybierz date od i do dla pierwszego wykresu',
  'Select the period for the first chart':
    'Wybierz okres dla pierwszego wykresu',
  'Client sales result comparison': 'Porównanie wyników sprzedażowych klienta',
  'close chart': 'zamknij wykres'
};
