import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';
import parse from 'date-fns/parse';
import addYears from 'date-fns/addYears';

class SalesResultsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('department_id'),
            label: 'Department',
            type: 'autocomplete',
            emptyValueText: 'All departments',
            displayStatus: this.context.hasPermission([DEPARTMENTS_LIST]),
            options: formatOptions(this.props.departments, 'id', 'name')
          },
          {
            ...this.getBasicInputValues('month_from'),
            label: 'Month from',
            type: 'monthYear',
            maxDate: this.props.filter.month_to
              ? parse(this.props.filter.month_to, 'MM.yyyy', new Date())
              : addYears(new Date(), 20)
          },
          {
            ...this.getBasicInputValues('month_to'),
            label: 'Month to',
            type: 'monthYear',
            minDate: this.props.filter.month_from
              ? parse(this.props.filter.month_from, 'MM.yyyy', new Date())
              : addYears(new Date(), -20)
          }
        ]
      }
    ];
  }
}

export default SalesResultsFilter;
