import {
  SET_COUNTRY_DEPTH,
  SET_SELECTED_ITEM,
  SET_OPEN_DIALOG,
  SET_CLOSE_DIALOG,
  SET_DIALOG_DATA,
  SET_CHOSEN_ITEM
} from 'modules/PostalCodes/context/reducer/reducer';

export const setCountryDepthAction = payload => ({
  type: SET_COUNTRY_DEPTH,
  payload
});

export const setSelectedItemAction = payload => ({
  type: SET_SELECTED_ITEM,
  payload
});

export const setOpenDialogAction = payload => ({
  type: SET_OPEN_DIALOG,
  payload
});

export const setCloseDialogAction = payload => ({
  type: SET_CLOSE_DIALOG,
  payload
});

export const setDialogDataAction = payload => ({
  type: SET_DIALOG_DATA,
  payload
});

export const setChosenItemAction = payload => ({
  type: SET_CHOSEN_ITEM,
  payload
});
