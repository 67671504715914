import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { ROUTE_KNOWLEDGE_BASE, permMap } from 'routing/routes/KnowledgeBase';

const menu = [
  {
    key: 'knowledge-base-groups',
    label: 'Knowledge base',
    icon: <EmojiObjectsIcon />,
    path: ROUTE_KNOWLEDGE_BASE,
    permissions: permMap[ROUTE_KNOWLEDGE_BASE]
  }
];

export default menu;
