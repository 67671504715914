import { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import { Box } from '@material-ui/core';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import ClientsContractsBonusesApi from 'api/connections/ClientsBonuses/ClientsBonusesApi';
import ClientsContractsBonusesTable from 'modules/ClientsBonuses/component/List/Table';
import ClientsContractsBonusesFilter from 'modules/ClientsBonuses/component/List/Filter';

class ClientsContractsBonusesList extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const { setAlert, setCurrentPage } = props.contextMethods;

    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;

    this.state = {
      filter: {},
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      bonuses: undefined,
      loading: true
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.fetchClientsContractsBonuses = this.fetchClientsContractsBonuses.bind(
      this
    );
    this.renderActions = this.renderActions.bind(this);
  }

  componentDidMount() {
    this.setCurrentPage('Clients bonuses');
    this.fetchClientsContractsBonuses();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  setSort(sort) {
    this.setState(
      { sort: { ...this.state.sort, ...sort }, loading: true },
      () => this.fetchClientsContractsBonuses()
    );
  }

  setPagination(pagination) {
    this.setState(
      {
        pagination: { ...this.state.pagination, ...pagination },
        loading: true
      },
      () => this.fetchClientsContractsBonuses()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 },
        loading: true
      },
      () => this.fetchClientsContractsBonuses()
    );
  }

  async fetchClientsContractsBonuses() {
    const {
      sort,
      filter,
      pagination: { per_page, page }
    } = this.state;

    const pathParams = {
      ...sort,
      ...filter,
      per_page,
      page
    };

    try {
      const {
        data: { data: bonuses, meta }
      } = await ClientsContractsBonusesApi.getBonuses(pathParams);

      this.setState({
        bonuses,
        pagination: formatMetaToPagination(meta),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { pagination } = this.state;

    return (
      <Box display='flex' flexDirection='row-reverse'>
        <Pagination
          pagination={pagination}
          setPagination={this.setPagination}
        />
      </Box>
    );
  }

  render() {
    const { filter, sort, bonuses, loading, filterValidation } = this.state;

    return (
      <div>
        <ClientsContractsBonusesFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
        />
        {this.renderActions()}
        <ClientsContractsBonusesTable
          sort={sort}
          setSort={this.setSort}
          bonuses={bonuses}
          loading={loading}
        />
        {this.renderActions()}
      </div>
    );
  }
}

ClientsContractsBonusesList.propTypes = {
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default ClientsContractsBonusesList;
