export const validate = (obj, validateObj) => {
  const objKeys = Object.keys(obj);
  const newValidateState = { ...validateObj };
  let isValid = true;

  // TODO pętla po zadeklarowanym obiekcie validateObj,
  //  ponieważ teraz niepotrzebnie tworzy nowe klucze
  // TODO validować takrze zagnieżdżone obiekty validateObj
  objKeys.forEach(key => {
    let el = document.querySelector(`input[name=${key}]`);
    if (!el) el = document.querySelector(`textarea[name=${key}]`);
    if (el) {
      const validationStatus = el.checkValidity();
      if (!validationStatus) isValid = false;

      newValidateState[key] = {
        ...validateObj[key],
        status: !validationStatus
      };
    }
  });

  return [isValid, newValidateState];
};

export const validateBaseOnFormInputs = (validateObj, formElements) => {
  const validateObjKeys = Object.keys(validateObj);
  const newValidateState = { ...validateObj };
  let isValid = true;

  validateObjKeys.forEach(key => {
    const formElement = formElements[key];

    if (formElement) {
      const validationStatus = formElement.checkValidity();
      if (!validationStatus) isValid = false;

      newValidateState[key] = {
        ...validateObj[key],
        status: !validationStatus
      };
    }
  });

  return [isValid, newValidateState];
};

export const validateSingleItem = name => {
  const el =
    document.querySelector(`input[name=${name}]`) ||
    document.querySelector(`input[name=${name}]`);
  return el.checkValidity();
};
