import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { Typography, Tooltip } from '@material-ui/core';
import { isEmpty } from 'lodash/lang';
import Table from 'modules/Layout/component/List/Table';
import OrdersApi from 'api/connections/Orders/OrdersApi';

class CopyProductDialog extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      orderId: undefined,
      orders: undefined
    };

    this.onSelect = this.onSelect.bind(this);
    this.onSave = this.onSave.bind(this);
    this.renderProducts = this.renderProducts.bind(this);
    this.isRowDisabled = this.isRowDisabled.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSelect(orderId) {
    this.setState({ orderId });
  }

  onSave() {
    const { orderId, orders } = this.state;

    const { products } = orderId ? orders.find(o => o.id === orderId) : null;

    const productsIds = this.getFilterProductsIds(products);

    this.props.onProductAddSuccess(productsIds);
  }

  getCols() {
    return [
      {
        property: 'order_date',
        label: 'Order date',
        sortable: false
      },
      {
        property: 'total_price',
        label: 'Summary price',
        sortable: false
      },
      {
        property: 'products',
        label: 'Products',
        sortable: false,
        render: this.renderProducts
      }
    ];
  }

  getFilterProductsIds(products) {
    const { isDirect } = this.props;

    return products
      .filter(product =>
        Boolean(
          product.batches_count !== 0 &&
            !(Boolean(product.direct_order_only) && !isDirect)
        )
      )
      .map(p => p.id);
  }

  isRowDisabled(row) {
    if (isEmpty(row.products)) return true;

    const filterProducts = this.getFilterProductsIds(row.products);

    return isEmpty(filterProducts);
  }

  async fetchData() {
    const { clientId } = this.props;

    try {
      const {
        data: { data: orders }
      } = await OrdersApi.getLatestOrders({
        client_id: clientId
      });

      this.setState({ orders });
    } catch (err) {
      this.props.handleError(err);
    }
  }

  renderProducts(products) {
    const { isDirect } = this.props;

    return products.map(product => {
      /* eslint no-shadow: "off" */
      const { disableStatus, disableMsg } = (() => {
        const disableStatus = Boolean(
          product.batches_count === 0 ||
            (Boolean(product.direct_order_only) && !isDirect)
        );

        const disableMsg =
          Boolean(product.direct_order_only) && !isDirect
            ? 'The product only available on direct order.'
            : 'The product batch count is 0.';

        return { disableStatus, disableMsg };
      })();

      const content = (
        <Typography
          key={product.id}
          color={disableStatus ? 'textSecondary' : 'initial'}
        >
          {product.sku}
        </Typography>
      );

      return disableStatus ? (
        <Tooltip title={t(disableMsg)}>{content}</Tooltip>
      ) : (
        content
      );
    });
  }

  render() {
    const { orderId, orders } = this.state;

    return (
      <Dialog
        open
        onClose={this.props.onClose}
        maxWidth='lg'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Copy products from last order')}</DialogTitle>
        <DialogContent>
          <Table
            cols={this.getCols()}
            rows={orders}
            hover
            dense
            isRowDisabled={this.isRowDisabled}
            rowDisabledTooltip='The order has no products to copy.'
            selected={[orderId]}
            onSelect={this.onSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button text={t('Close')} onClick={this.props.onClose} />
          <Button text={t('Save')} color='primary' onClick={this.onSave} />
        </DialogActions>
      </Dialog>
    );
  }
}

CopyProductDialog.defaultProps = {};

CopyProductDialog.propTypes = {
  clientId: PropTypes.number.isRequired,
  isDirect: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProductAddSuccess: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired
};

export default CopyProductDialog;
