import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@material-ui/core';
import t from 'translate/translate';
import { isEmpty } from 'lodash/lang';
import {
  PAYMENT_TYPE_DISCOUNTS,
  THRESHOLD_DISCOUNTS
} from 'modules/Discounts/discountsTypes';
import { ORDER_STATUS_PREPARING } from 'modules/Orders/ordersStatuses';
import insertPathParams from 'api/utils/insertPathParams';
import { Link } from 'react-router-dom';
import { ROUTE_DISCOUNTS_DETAILS } from 'routing/routes/Discounts';
import Accordion from 'modules/Layout/component/Accordion';
import IconButton from 'modules/Layout/component/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const discountAccordionLabel = (
  <Box width={1}>
    <Typography component='h6' variant='h6'>
      {t('Discounts')}
    </Typography>
  </Box>
);

class OrderDetailsDiscounts extends Component {
  static renderPercentageDiscount(discount) {
    const {
      id,
      name,
      type_data: { percentage }
    } = discount;

    const redirectLink = insertPathParams(ROUTE_DISCOUNTS_DETAILS, { id });

    return (
      <Box display='flex' alignItems='center'>
        <Typography variant='subtitle1'>
          {`${name} - ${t('discount of <%=percentage%> was applied.', {
            percentage: `${percentage * 100}%`
          })}`}
        </Typography>
        <Link to={redirectLink} className='router-button' target='_blank'>
          <IconButton
            className='details-icon'
            icon={<SearchIcon />}
            alt='details'
          />
        </Link>
      </Box>
    );
  }

  getReachedThreshold(thresholds) {
    const { netValue } = this.props;

    const sortedThresholds = thresholds
      .filter(thr => thr.threshold <= netValue)
      .sort((a, b) => (a.threshold > b.threshold ? -1 : 0));

    return sortedThresholds[0];
  }

  getDiscountName(id, name) {
    const { status } = this.props;

    return status === ORDER_STATUS_PREPARING ? (
      <Link
        to={insertPathParams(ROUTE_DISCOUNTS_DETAILS, { id })}
        className='router-link router-link-underline'
        target='_blank'
      >
        <Typography variant='subtitle1'>{`"${name}"`}</Typography>
      </Link>
    ) : (
      <Typography variant='subtitle1'>{`"${name}"`}</Typography>
    );
  }

  renderThresholdDiscount(discount) {
    const { id, name, type_data: thresholds } = discount;
    const { threshold, percentage } = this.getReachedThreshold(thresholds);

    const redirectLink = insertPathParams(ROUTE_DISCOUNTS_DETAILS, { id });

    return (
      <Box display='flex' alignItems='center'>
        <Typography variant='subtitle1'>
          {`${name} - ${t(
            'discount of <%=percentage%> was applied after reaching threshold of <%=threshold%>.',
            {
              threshold,
              percentage: `${(percentage * 100).toFixed(0)}%`
            }
          )}`}
        </Typography>
        <Link to={redirectLink} className='router-button' target='_blank'>
          <IconButton
            className='details-icon'
            icon={<SearchIcon />}
            alt='details'
          />
        </Link>
      </Box>
    );
  }

  renderDiscounts() {
    const {
      discounts,
      netValue,
      paymentDiscountId,
      paymentDiscountValue,
      paymentDiscountName
    } = this.props;

    const sortedDiscounts = discounts
      .filter(d => d.type !== PAYMENT_TYPE_DISCOUNTS)
      .filter(d => {
        if (d.type === THRESHOLD_DISCOUNTS) {
          if (d.type_data.find(th => th.threshold <= netValue)) {
            return d;
          }
          return null;
        }
        return d;
      })
      .sort(a => (a.type === 1 ? 0 : -1));

    if (paymentDiscountValue) {
      sortedDiscounts.push({
        id: paymentDiscountId,
        type: PAYMENT_TYPE_DISCOUNTS,
        type_data: { percentage: paymentDiscountValue },
        name: paymentDiscountName
      });
    }

    return sortedDiscounts.map((discount, index) => {
      let discountComponent;

      if (discount.type === THRESHOLD_DISCOUNTS) {
        discountComponent = this.renderThresholdDiscount(discount);
      } else {
        discountComponent = OrderDetailsDiscounts.renderPercentageDiscount(
          discount
        );
      }

      return (
        <Box key={`${discount.type}-${discount.name}`} mb={1}>
          <Box mb={1}>{discountComponent}</Box>
          {index !== sortedDiscounts.length - 1 && <Divider />}
        </Box>
      );
    });
  }

  renderData() {
    const { discounts } = this.props;

    if (isEmpty(discounts)) {
      return (
        <Typography variant='subtitle1' color='textSecondary'>
          {t('No discount will apply to order')}
        </Typography>
      );
    }

    return (
      <Box width={1} display='flex' flexDirection='column'>
        {this.renderDiscounts()}
      </Box>
    );
  }

  render() {
    return (
      <Accordion label={discountAccordionLabel}>{this.renderData()}</Accordion>
    );
  }
}

OrderDetailsDiscounts.defaultProps = {
  discounts: null,
  paymentDiscountId: null,
  paymentDiscountValue: null,
  paymentDiscountName: null
};

OrderDetailsDiscounts.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  netValue: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  paymentDiscountId: PropTypes.number,
  paymentDiscountValue: PropTypes.number,
  paymentDiscountName: PropTypes.string
};

export default OrderDetailsDiscounts;
