import BaseApiConfig from 'api/connections/BaseApiConfig';

class ClientsEvaluationApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.clientsEvaluationPath = '/clients/evaluation';
  }

  createEvaluation(payload) {
    return this.post(this.clientsEvaluationPath, payload);
  }
}

export default new ClientsEvaluationApi();
