import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';

const ClientsTreeDeleteDialog = props => {
  const { item, onClose, onApprove } = props;

  const getDescription = () => {
    const { postalCodeLevel, name } = item;

    if (postalCodeLevel !== 'clients') {
      return t(
        'Are you sure you want to remove all assigment clients from <%=postalCodeLevel%> <%=name%>',
        { postalCodeLevel, name }
      );
    }

    if (postalCodeLevel === 'clients') {
      return t(
        'Are you sure you want to remove assigment with client <%=name%>',
        { name }
      );
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={getDescription()}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

ClientsTreeDeleteDialog.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    postalCodeLevel: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired
};

export default ClientsTreeDeleteDialog;
