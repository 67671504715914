import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import OrderForm from 'modules/Orders/component/OrderPreparing/Form';
import getPathQuery from 'api/utils/getPathQuery';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_CLIENTS_LIST } from 'routing/routes/Clients';
import Loader from 'modules/Layout/component/Loader';

class OrderCreate extends Component {
  constructor(props) {
    super(props);

    this.clientId = parseInt(
      getPathQuery(this.props.location.search, 'client_id'),
      10
    );

    this.visitId = parseInt(
      getPathQuery(this.props.location.search, 'visit_id'),
      10
    );

    this.state = {
      client: null,
      visit: null
    };
  }

  componentDidMount() {
    this.props.contextMethods.setCurrentPage('Create order');
    if (this.clientId) {
      this.fetchClient();
    }
    if (this.visitId) {
      this.fetchVisit();
    }
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);
      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_CLIENTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  async fetchClient() {
    try {
      const {
        data: { data: client }
      } = await ClientsApi.getClient(this.clientId);

      this.setState({ client });
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchVisit() {
    try {
      const {
        data: { data: visit }
      } = await VisitsApi.getVisit(this.visitId);

      this.setState({ visit });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const { client, visit } = this.state;

    if ((this.clientId && !client) || (this.visitId && !visit))
      return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} xl={10}>
          <Paper className='p1'>
            <OrderForm
              setAlert={this.props.contextMethods.setAlert}
              client={client}
              visit={visit}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

OrderCreate.propTypes = {
  contextMethods: PropTypes.shape({
    setCurrentPage: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(OrderCreate);
