import { Route } from 'react-router-dom';
import ProfileDetailsWrapper from 'modules/Profile/view/Details/DetailsWrapper';
import Update from 'modules/Profile/view/Update';
import ConfirmEmail from 'modules/Profile/view/ConfirmEmail';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';

export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_UPDATE = '/profile/update';
export const ROUTE_CONFIRM_EMAIL = '/confirm-email';

const routes = {
  public: [
    <Route
      key='confirm-email'
      exact
      path={ROUTE_CONFIRM_EMAIL}
      component={ConfirmEmail}
    />
  ],
  protected: [
    <Route key='profile' exact path={ROUTE_PROFILE}>
      <BasicContextConsumerWrapper
        component={fn => <ProfileDetailsWrapper contextMethods={fn} />}
      />
    </Route>,
    <Route
      key='profile-update'
      exact
      path={ROUTE_PROFILE_UPDATE}
      component={Update}
    />
  ]
};

export default routes;
