import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ROUTE_WHOLESALERS_DETAILS,
  ROUTE_WHOLESALERS_LIST
} from 'routing/routes/Wholesalers';
import { Box, Paper } from '@material-ui/core';
import WholesalersContactUpdateForm from 'modules/Wholesalers/component/Contacts/Update/Form';
import { withRouter } from 'react-router-dom';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import insertPathParams from 'api/utils/insertPathParams';

class WholesalerContactUpdate extends Component {
  constructor(props) {
    super(props);
    const { wholesalerId, contactId } = props.match.params;
    this.wholesalerId = parseInt(wholesalerId, 10) || null;
    this.contactId = parseInt(contactId, 10) || null;
    this.state = {
      wholesalerContact: null
    };
    const { setAlert, setCurrentPage } = props.contextMethods;
    this.setAlert = setAlert;
    this.setCurrentPage = setCurrentPage;
  }

  async componentDidMount() {
    if (!this.wholesalerId || !this.contactId) {
      this.props.history.push(ROUTE_WHOLESALERS_LIST);
    }
    this.setCurrentPage('Update wholesalers contact');
    await this.fetchData();
  }

  async fetchData() {
    try {
      const {
        data: {
          data: { contacts }
        }
      } = await WholesalersApi.getWholesaler(this.wholesalerId);

      const wholesalerContact = contacts.find(
        contact => contact.id === this.contactId
      );

      if (!wholesalerContact) {
        this.props.history.push(
          insertPathParams(ROUTE_WHOLESALERS_DETAILS, { id: this.wholesalerId })
        );
      }

      this.setState({ wholesalerContact });
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        this.setAlert(message);
        if (err instanceof NotFoundApiError) {
          this.props.history.push(
            insertPathParams(ROUTE_WHOLESALERS_DETAILS, {
              id: this.wholesalerId
            })
          );
        }
      } else {
        console.error(err);
      }
    }
  }

  render() {
    const { wholesalerContact } = this.state;
    if (!wholesalerContact) return null;
    const { wholesalerId, contactId } = this.props.match.params;

    return (
      <Box width={1} display='flex' justifyContent='center'>
        <Box width={{ xs: 1, md: '60%' }} display='flex' flexDirection='column'>
          <Paper style={{ padding: '1rem' }}>
            <WholesalersContactUpdateForm
              wholesalerId={wholesalerId}
              contactId={contactId}
              wholesalerContact={wholesalerContact}
            />
          </Paper>
        </Box>
      </Box>
    );
  }
}

WholesalerContactUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      wholesalerId: PropTypes.string.isRequired,
      contactId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(WholesalerContactUpdate);
