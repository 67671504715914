import clsx from 'clsx';
import { List, ListItem } from '@material-ui/core';
import useStyles from 'modules/Layout/component/Navigator/style';
import logo from 'asset/img/cropped-logo-crop-32x32.png';
import NavigatorListItem from 'modules/Layout/component/Navigator/ListItem';
import NavigatorNestedListItem from 'modules/Layout/component/Navigator/NestedListItem';
import menuConfig from 'modules/Layout/component/Navigator/menuConfig';
import { useState } from 'react';

const NavigatorList = () => {
  const classes = useStyles();
  const [openListItemKey, setOpenListItemKey] = useState(null);

  const handleListItemClick = itemKey => {
    setOpenListItemKey(openListItemKey === itemKey ? null : itemKey);
  };

  const logoElement = (
    <ListItem
      className={clsx(classes.firebase, classes.item, classes.itemCategory)}
    >
      <img src={logo} alt='VetExpert logo' style={{ marginRight: '25px' }} />
      VetExpert
    </ListItem>
  );

  const renderItems = items =>
    items.map(item => {
      return item.children ? (
        <NavigatorNestedListItem
          key={item.key}
          item={item}
          openListItemKey={openListItemKey}
          onClick={handleListItemClick}
        />
      ) : (
        <NavigatorListItem key={item.key} item={item} />
      );
    });

  return (
    <List disablePadding>
      {logoElement}
      {renderItems(menuConfig)}
    </List>
  );
};

export default NavigatorList;
