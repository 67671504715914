import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import PromotionsApi from 'api/connections/Promotions/PromotionsApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import { useContext } from 'react';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ApiError from 'api/exceptions/ApiError';

const PromotionDeleteDialog = props => {
  const { setAlert } = useContext(AlertContext);

  const { id, name, onClose, onSuccess } = props;

  const onApprove = async () => {
    try {
      await PromotionsApi.deletePromotion(id);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      err instanceof ApiError
        ? setAlert(err.getPayload().message)
        : console.error(err);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete promotion <%=name%>?', {
        name
      })}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

PromotionDeleteDialog.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default PromotionDeleteDialog;
