import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Authorize from 'modules/Auth/component/Authorize';
import { ORDER_TYPES_MANAGEMENT } from 'api/auth/permissions/OrderTypes';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';

const SortableItem = SortableElement(
  ({ orderType, onDeleteClick, onUpdateClick }) => {
    const { name, short_name } = orderType;

    return (
      <Box
        className='p1 language-form-box'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography>
          <b>{short_name}</b> - {name}
        </Typography>
        <Box display='flex' flexDirection='row'>
          <Authorize permissions={[ORDER_TYPES_MANAGEMENT]}>
            <IconButton
              onClick={() => onUpdateClick(orderType)}
              className='update-icon'
              icon={<EditIcon />}
              alt='change name'
            />
            <IconButton
              onClick={() => onDeleteClick(orderType)}
              className='delete-icon'
              icon={<DeleteForeverIcon />}
              alt='delete'
            />
          </Authorize>
        </Box>
      </Box>
    );
  }
);

const SortableList = SortableContainer(
  ({ orderTypes, onUpdateClick, onDeleteClick }) => {
    return (
      <Box>
        {orderTypes.map((orderType, index) => (
          <SortableItem
            key={orderType.id}
            index={index}
            orderType={orderType}
            onUpdateClick={onUpdateClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
      </Box>
    );
  }
);

const OrderTypeList = props => {
  const { orderTypes, onSortEnd, onUpdateClick, onDeleteClick } = props;

  return (
    <SortableList
      onSortEnd={onSortEnd}
      pressThreshold={20}
      shouldCancelStart={e =>
        ['svg', 'path', 'BUTTON'].includes(e.target.tagName)
      }
      orderTypes={orderTypes}
      onUpdateClick={onUpdateClick}
      onDeleteClick={onDeleteClick}
    />
  );
};

OrderTypeList.propTypes = {
  orderTypes: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default OrderTypeList;
