import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import OrdersApi from 'api/connections/Orders/OrdersApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import { useContext } from 'react';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ApiError from 'api/exceptions/ApiError';

const OrderDeleteDialog = props => {
  const { setAlert } = useContext(AlertContext);

  const {
    order: { id: orderId, client_name: clientName },
    onClose,
    onSuccess
  } = props;

  const onApprove = async () => {
    try {
      await OrdersApi.deleteOrder(orderId);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      err instanceof ApiError
        ? setAlert(err.getPayload().message)
        : console.error(err);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete order for client <%=client%>?',
        { client: clientName }
      )}
      onApprove={onApprove}
      onCancel={onClose}
    />
  );
};

OrderDeleteDialog.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default OrderDeleteDialog;
