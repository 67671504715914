import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Accordion from 'modules/Layout/component/Accordion';
import ClientsTree from 'modules/Clients/component/ClientsTree';
import { Box, Grid } from '@material-ui/core';

class UserClients extends Component {
  constructor(props) {
    super(props);

    this.fetchParams = {
      user_id: parseInt(props.match.params.id, 10),
      country_id: props.userCountryId
    };
  }

  render() {
    return (
      <Grid container spacing={3} justify='center'>
        {this.props.userDepartments.map((department, index) => {
          const { name: departmentName, id: departmentId } = department;

          return (
            <Grid item xs={12} md={8} key={departmentId}>
              <Accordion label={departmentName} defaultExpanded={index === 0}>
                <Box width={1} display='flex' justifyContent='center'>
                  <ClientsTree
                    fetchParams={{
                      ...this.fetchParams,
                      department_id: departmentId
                    }}
                    disableAddBtnStatus={this.props.updateActionDisableStatus}
                    disableAddBtnMsg={this.props.updateActionDisableMsg}
                  />
                </Box>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

UserClients.defaultProps = {
  updateActionDisableMsg: null
};

UserClients.propTypes = {
  userDepartments: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  userCountryId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  updateActionDisableStatus: PropTypes.bool.isRequired,
  updateActionDisableMsg: PropTypes.string
};

export default withRouter(UserClients);
