import { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validateBaseOnFormInputs } from 'modules/Shared/utils/validator';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import TaxesApi from 'api/connections/Taxes/TaxesApi';
import Select, { formatOptions } from 'modules/Layout/component/Select';

class TaxFormModal extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.tax = props.tax;

    this.state = {
      formData: {
        name: this.tax?.name || '',
        value: this.tax?.value || '',
        country_id: context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW])
          ? ''
          : context.user.country_id
      },
      validation: {
        name: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Name')
          })
        },
        value: {
          status: false,
          message: t('Field Value is required and correct format is X.XX')
        },
        country_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Country')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.props.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onClose();
    this.props.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.props.setAlert(message);

      if (err instanceof ValidationApiError) {
        const validateBaseOnFormInputsState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...validateBaseOnFormInputsState }
          };
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation(e.target.elements);
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, value } = e.target;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  runValidation(formElements) {
    const { validation } = this.state;
    const [isValid, validateBaseOnFormInputsState] = validateBaseOnFormInputs(
      validation,
      formElements
    );

    if (!isValid) {
      this.setState({ validation: validateBaseOnFormInputsState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;
    this.setState({ loading: true });

    try {
      if (this.tax) {
        await TaxesApi.updateTax(this.tax.id, formData);
      } else {
        await TaxesApi.createTax(formData);
      }

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const {
      formData: { name, value, country_id },
      validation,
      loading
    } = this.state;

    const { countries, onClose } = this.props;

    return (
      <Dialog
        open
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t(this.tax ? 'Update tax' : 'Create tax')}</DialogTitle>
        <DialogContent>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            {!this.tax &&
              this.context.hasPermission([POSTAL_CODES_COUNTRIES_SHOW]) && (
                <Select
                  name='country_id'
                  label='Country'
                  value={country_id}
                  required
                  options={formatOptions(countries, 'id', 'name')}
                  onChange={this.onChange}
                  errorStatus={validation.country_id.status}
                  errorText={validation.country_id.message}
                />
              )}
            <Input
              name='name'
              label='Name'
              value={name}
              onChange={this.onChange}
              required
              errorStatus={validation.name.status}
              errorText={validation.name.message}
            />
            <Input
              name='value'
              label='Value'
              type='number'
              value={value}
              onChange={this.onChange}
              required
              errorStatus={validation.value.status}
              errorText={validation.value.message}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={onClose} />
          <Button
            form='form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

TaxFormModal.defaultProps = {
  tax: null
};

TaxFormModal.propTypes = {
  tax: PropTypes.shape({}),
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default TaxFormModal;
