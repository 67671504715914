export const SET_COUNTRY_DEPTH = 'SET_COUNTRY_DEPTH';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const SET_OPEN_DIALOG = 'SET_OPEN_DIALOG';
export const SET_CLOSE_DIALOG = 'SET_CLOSE_DIALOG';
export const SET_DIALOG_DATA = 'SET_DIALOG_DATA';
export const SET_CHOSEN_ITEM = 'SET_CHOSEN_ITEM_TYPE';

const postalCodesReducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_COUNTRY_DEPTH:
      return { ...state, countryDepth: action.payload };
    case SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: { ...state.selectedItem, ...action.payload }
      };
    case SET_OPEN_DIALOG:
      return { ...state, openDialog: { [action.payload]: true } };
    case SET_CLOSE_DIALOG:
      return { ...state, openDialog: { [action.payload]: false } };
    case SET_DIALOG_DATA:
      return {
        ...state,
        dialogData: { id: action.payload.id, name: action.payload.name }
      };
    case SET_CHOSEN_ITEM:
      return {
        ...state,
        chosenItem: action.payload
      };
  }
};

export default postalCodesReducer;
