import { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import insertPathParams from 'api/utils/insertPathParams';
import { Link } from 'react-router-dom';
import { ROUTE_DEPARTMENTS_DETAILS } from 'routing/routes/Departments';
import { Box } from '@material-ui/core';
import Authorize from 'modules/Auth/component/Authorize';
import { PRICE_LISTS_MANAGEMENT } from 'api/auth/permissions/PriceLists';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Pagination from 'modules/Layout/component/List/Pagination';
import PriceListsAddDepartmentsModal from 'modules/PriceLists/component/Modals/AddDepartment';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { DEPARTMENTS_LIST } from 'api/auth/permissions/Departments';

class PriceListsDetailsDepartmentsList extends Component {
  static contextType = AuthContext;

  static renderName(name, { id }) {
    return (
      <Link
        to={insertPathParams(ROUTE_DEPARTMENTS_DETAILS, {
          id
        })}
        className='router-link router-link-underline'
        target='_blank'
      >
        {name}
      </Link>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      addDepartmentModalStatus: false
    };

    this.setPagination = this.setPagination.bind(this);
    this.onAddDepartmentClick = this.onAddDepartmentClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onAddDepartmentClick() {
    this.setState({ addDepartmentModalStatus: true });
  }

  getDataToRender() {
    const { departments } = this.props;
    const { per_page, page } = this.state.pagination;

    const paginationFrom = (page - 1) * per_page;
    const paginationTo = paginationFrom + per_page;

    return {
      data: departments.slice(paginationFrom, paginationTo),
      total: departments.length
    };
  }

  setPagination(pagination) {
    this.setState({
      pagination: { ...this.state.pagination, ...pagination }
    });
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false,
        render: PriceListsDetailsDepartmentsList.renderName
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        displayStatus: this.context.hasPermission([
          PRICE_LISTS_MANAGEMENT,
          DEPARTMENTS_LIST
        ]),
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          remove: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: ({ id }) => this.props.onDepartmentDeleteSuccess(id)
          }
        }
      }
    ];
  }

  closeModal() {
    this.setState({ addDepartmentModalStatus: false });
  }

  render() {
    const { pagination, addDepartmentModalStatus } = this.state;

    const { data, total } = this.getDataToRender();

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={data}
          dense
          actions={this.context.hasPermission([
            PRICE_LISTS_MANAGEMENT,
            DEPARTMENTS_LIST
          ])}
        />
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>
            <Authorize permissions={[PRICE_LISTS_MANAGEMENT, DEPARTMENTS_LIST]}>
              <IconButton
                className='create-icon'
                onClick={this.onAddDepartmentClick}
                icon={<AddIcon fontSize='large' />}
                alt='create'
              />
            </Authorize>
          </Box>
          <Pagination
            pagination={{ ...pagination, total }}
            setPagination={this.setPagination}
          />
        </Box>
        {addDepartmentModalStatus && (
          <PriceListsAddDepartmentsModal
            departments={this.props.departments}
            countryId={this.props.countryId}
            onSuccess={this.props.onDepartmentAddSuccess}
            onClose={this.closeModal}
          />
        )}
      </>
    );
  }
}

PriceListsDetailsDepartmentsList.defaultProps = {
  countryId: null
};

PriceListsDetailsDepartmentsList.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  countryId: PropTypes.number,
  onDepartmentAddSuccess: PropTypes.func.isRequired,
  onDepartmentDeleteSuccess: PropTypes.func.isRequired
};

export default PriceListsDetailsDepartmentsList;
