import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Box, DialogContentText } from '@material-ui/core';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';

import AlertContext from 'modules/Shared/context/Alert/alertContext';
import SalesGroupsApi from 'api/connections/SalesGroups/SalesGroupsApi';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import SaleGroupClientsTable from 'modules/SalesGroups/component/Details/Clients/Create/Table';
import SaleGroupClientsFilter from 'modules/SalesGroups/component/Details/Clients/Create/Filter';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

class SaleGroupClientsCreateDialog extends Component {
  static contextType = AlertContext;

  constructor(props, context) {
    super(props, context);
    const { salesGroup, onSuccess, onClose } = props;

    this.salesGroup = salesGroup;
    this.onSuccess = onSuccess;
    this.onClose = onClose;
    this.setAlert = context.setAlert;

    this.state = {
      filter: {
        name: '',
        tax_number: '',
        address: ''
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      clients: [],
      tableSelected: [],
      loading: false
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onTableSelect = this.onTableSelect.bind(this);
  }

  componentDidMount() {
    this.fetchClients();
  }

  handleResponse(clientId) {
    const { clients } = this.state;

    const client = clients.find(c => c.id === clientId);

    this.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.onSuccess(client);
    this.onClose();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  async onSubmit() {
    const { id: groupId } = this.salesGroup;
    const clientId = this.state.tableSelected[0];

    const ids = {
      groupId,
      clientId
    };

    this.setState({ loading: true });
    try {
      await SalesGroupsApi.assignSalesGroupToClient(ids);
      this.handleResponse(clientId);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  onTableSelect(id) {
    this.setState(({ tableSelected: prevSelected }) => {
      const result = prevSelected[0] === id ? [] : [id];
      return { tableSelected: result };
    });
  }

  setPagination(pagination) {
    this.setState(
      { pagination: { ...this.state.pagination, ...pagination } },
      () => this.fetchClients()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchClients()
    );
  }

  setSort(sort) {
    this.setState({ sort: { ...this.state.sort, ...sort } }, () =>
      this.fetchClients()
    );
  }

  async fetchClients() {
    const { country_id } = this.salesGroup;

    try {
      const {
        data: { data: clients, meta }
      } = await ClientsApi.getClients({
        ...this.state.sort,
        ...this.state.filter,
        per_page: this.state.pagination.per_page,
        page: this.state.pagination.page,
        country_id
      });

      this.setState({
        clients,
        tableSelected: [],
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      clients,
      sort,
      pagination,
      filter,
      filterValidation,
      tableSelected,
      loading
    } = this.state;

    return (
      <Dialog
        open
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='md'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle id='form-dialog-title'>
          {t('Assign client to sales group')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t(
              'Selecting a customer who already has a shopping group will result in his removal from the group he currently has.'
            )}
          </DialogContentText>
          <SaleGroupClientsFilter
            filter={filter}
            validation={filterValidation}
            setFilter={this.setFilter}
          />
          <SaleGroupClientsTable
            clients={clients}
            sort={sort}
            setSort={this.setSort}
            selected={tableSelected}
            onSelect={this.onTableSelect}
          />
          <Box display='flex' flexDirection='row-reverse'>
            <Pagination
              pagination={pagination}
              setPagination={this.setPagination}
              rowsPerPageOptions={[5, 15, 30, 100]}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: '1rem' }}>
          <Button
            text={t('Cancel')}
            onClick={this.onClose}
            style={{ marginTop: '0', marginBottom: '0' }}
          />
          <Button
            text={t('Approve')}
            color='primary'
            onClick={this.onSubmit}
            disabled={isEmpty(tableSelected)}
            tooltipMsg={isEmpty(tableSelected) ? 'Select Client first' : null}
            loading={loading}
            style={{ marginTop: '0', marginBottom: '0' }}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

SaleGroupClientsCreateDialog.propTypes = {
  salesGroup: PropTypes.shape({}).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SaleGroupClientsCreateDialog;
