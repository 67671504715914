import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'modules/Layout/component/List/Table';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import t from 'translate/translate';
import { SUPER_ADMIN, ADMIN, DIRECTOR, roles } from 'api/auth/roles';
import { USERS_DELETE, USERS_UPDATE } from 'api/auth/permissions/Users';
import { ROUTE_USERS_DETAILS, ROUTE_USERS_UPDATE } from 'routing/routes/Users';
import insertPathParams from 'api/utils/insertPathParams';
import setUpdateActionDisableStatus from 'modules/Users/utils/setUpdateActionDisableStatus';
import setUpdateActionDisableMsg from 'modules/Users/utils/setUpdateActionDisableMsg';
import { ROUTE_DASHBOARD } from 'routing/routes/Dashboard';

class UsersTable extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    this.user = context.user;
    this.updateActionDisableMsg = setUpdateActionDisableMsg(context.user.role);
    this.renderCountry = this.renderCountry.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.impersonateCallback = this.impersonateCallback.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_USERS_DETAILS, { id }));
  }

  onUpdateClick({ id }) {
    this.props.history.push(insertPathParams(ROUTE_USERS_UPDATE, { id }));
  }

  setDeleteDisableStatus(row) {
    const { id, can_delete } = row;

    if (id === this.user.id || !can_delete) return true;

    return false;
  }

  getCols() {
    return [
      {
        property: 'first_name',
        label: 'First name',
        sortable: true
      },
      {
        property: 'last_name',
        label: 'Last name',
        sortable: true
      },
      {
        property: 'email',
        label: 'Email',
        sortable: false
      },
      {
        property: 'country_id',
        label: 'Country',
        sortable: false,
        render: this.renderCountry,
        displayStatus: this.context.hasRole([SUPER_ADMIN])
      },
      {
        property: 'role',
        label: 'Role',
        sortable: true,
        render: UsersTable.renderRole
      },
      {
        property: 'departments',
        label: 'Departments',
        sortable: false,
        render: UsersTable.renderDepartments,
        displayStatus: this.context.hasRole([SUPER_ADMIN, ADMIN, DIRECTOR])
      },
      {
        property: 'product_groups_names',
        label: 'Product groups',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([USERS_UPDATE]),
            disable: ({ departments }) =>
              setUpdateActionDisableStatus(this.user, departments),
            disableMsg: this.updateActionDisableMsg,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([USERS_DELETE]),
            disable: row => this.setDeleteDisableStatus(row),
            disableMsg:
              'You cannot delete Your own account, or user with open targets.',
            onClick: this.props.onDeleteClick
          },
          impersonate: {
            status: this.context.hasRole([SUPER_ADMIN, ADMIN]),
            disable: ({ id }) =>
              id === this.user.id && !this.context.impersonateRootId,
            disableMsg: 'You cannot use that account',
            onClick: ({ id }) =>
              this.context.impersonate(id, this.impersonateCallback)
          }
        }
      }
    ];
  }

  impersonateCallback() {
    this.props.history.push(ROUTE_DASHBOARD);
  }

  renderCountry(id) {
    if (id) {
      const country = this.props.countries.find(item => item.id === id);
      return country?.name;
    }
    return '--';
  }

  static renderRole(value) {
    return t(roles.find(role => role.slug === value).name);
  }

  static renderDepartments(value) {
    return value.map(department => department.name).join(', ');
  }

  render() {
    return (
      <Table
        cols={this.getCols()}
        rows={this.props.users}
        sort={this.props.sort}
        setSort={this.props.setSort}
        actions
        hover
        loading={this.props.loading}
      />
    );
  }
}

UsersTable.defaultProps = {
  users: [],
  countries: []
};

UsersTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default withRouter(UsersTable);
