import { Typography } from '@material-ui/core';
import PublicWrapper from 'modules/Layout/view/PublicWrapper';
import Form from 'modules/Auth/component/Login/Form';

import t from 'translate/translate';

const Login = () => {
  return (
    <PublicWrapper>
      <Typography component='h1' variant='h5'>
        {t('Log in')}
      </Typography>
      <Form />
    </PublicWrapper>
  );
};

export default Login;
