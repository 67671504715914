import Filter from 'modules/Layout/component/List/Filter';

class PriceListsDepartmentsFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('name'),
            label: 'Name',
            type: 'text'
          }
        ]
      }
    ];
  }
}

export default PriceListsDepartmentsFilter;
