import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Table from 'modules/Layout/component/List/Table';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { withRouter } from 'react-router-dom';
import {
  ROUTE_WHOLESALERS_CONTACT_CREATE,
  ROUTE_WHOLESALERS_CONTACT_UPDATE
} from 'routing/routes/Wholesalers';
import insertPathParams from 'api/utils/insertPathParams';
import { ADMIN, SUPER_ADMIN, DIRECTOR } from 'api/auth/roles';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import WholesalerContactDeleteDialog from 'modules/Wholesalers/component/Contacts/DeleteDialog';

class WholesalerContactsDetails extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.wholesalerId = props.wholesalerId;
    this.state = {
      deleteDialogOpen: false,
      contactToDelete: null
    };
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
  }

  onUpdateClick({ id: contactId }) {
    this.props.history.push(
      insertPathParams(ROUTE_WHOLESALERS_CONTACT_UPDATE, {
        wholesalerId: this.wholesalerId,
        contactId
      })
    );
  }

  onCreateClick() {
    this.props.history.push(
      insertPathParams(ROUTE_WHOLESALERS_CONTACT_CREATE, {
        id: this.wholesalerId
      })
    );
  }

  onDeleteClick(contact) {
    this.setState({ deleteDialogOpen: true, contactToDelete: contact });
  }

  onDeleteDialogClose() {
    this.setState({ deleteDialogOpen: false, contactToDelete: null });
  }

  getCols() {
    return [
      {
        property: 'first_name',
        label: 'First name',
        sortable: false
      },
      {
        property: 'last_name',
        label: 'Last name',
        sortable: false
      },
      {
        property: 'position',
        label: 'Position',
        sortable: false
      },
      {
        property: 'email',
        label: 'Email',
        sortable: false
      },
      {
        property: 'phone',
        label: 'Phone number',
        sortable: false
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: false,
            disable: false,
            disableMsg: null,
            onClick: null
          },
          update: {
            status: this.context.hasRole([ADMIN, SUPER_ADMIN, DIRECTOR]),
            disable: false,
            disableMsg: null,
            onClick: this.onUpdateClick
          },
          remove: {
            status: this.context.hasRole([ADMIN, SUPER_ADMIN, DIRECTOR]),
            disable: false,
            disableMsg: null,
            onClick: this.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { contacts, fetchWholesaler } = this.props;
    const { deleteDialogOpen, contactToDelete } = this.state;

    return (
      <>
        <Table cols={this.getCols()} rows={contacts} dense actions />
        <Box display='flex' alignItems='center'>
          <IconButton
            className='create-icon'
            onClick={this.onCreateClick}
            icon={<AddIcon fontSize='large' />}
            alt='create'
          />
        </Box>
        {deleteDialogOpen && (
          <WholesalerContactDeleteDialog
            contact={contactToDelete}
            wholesalerId={this.wholesalerId}
            onClose={this.onDeleteDialogClose}
            onSuccess={fetchWholesaler}
          />
        )}
      </>
    );
  }
}

WholesalerContactsDetails.defaultProps = {
  contacts: []
};

WholesalerContactsDetails.propTypes = {
  wholesalerId: PropTypes.number.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  fetchWholesaler: PropTypes.func.isRequired
};

export default withRouter(WholesalerContactsDetails);
