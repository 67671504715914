import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import WholesalersContactApi from 'api/connections/Wholesalers/WholesalersContactApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const WholesalerContactDeleteDialog = props => {
  const {
    contact: { id: contactId, first_name, last_name },
    wholesalerId,
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteContact = async () => {
    try {
      await WholesalersContactApi.deleteWholesalerContact({
        wholesalerId,
        contactId
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess();
    } catch (err) {
      if (err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      }
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t(
        'Are you sure you want to delete contact <%= full_name %>',
        {
          full_name: `${first_name} ${last_name}`
        }
      )}
      onApprove={deleteContact}
      onCancel={onClose}
    />
  );
};

WholesalerContactDeleteDialog.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired
  }).isRequired,
  wholesalerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default WholesalerContactDeleteDialog;
