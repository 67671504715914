import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import FileUploadButton from 'modules/Layout/component/FileUploadButton';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import Typography from '@material-ui/core/Typography';
import { DialogContentText } from '@material-ui/core';

class ImportMappingsDialog extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);
    this.wholesalerId = this.props.wholesalerId;
    this.state = {
      file: '',
      validation: {
        status: false,
        message: t('Field <%= field %> is required', {
          field: t('File')
        })
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpload = this.onUpload.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onClose();
    this.props.onSuccess();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState({
          validation: { ...newValidateState.file }
        });
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onUpload(file) {
    this.setState({
      file: file || '',
      validation: {
        status: false,
        message: t('Field <%= field %> is required', {
          field: t('File')
        })
      }
    });
  }

  runValidation() {
    const { file } = this.state;

    if (!file) {
      this.setState({
        validation: {
          status: true,
          message: t('Field <%= field %> is required', {
            field: t('File')
          })
        }
      });
    }

    return Boolean(file);
  }

  async makeApiCall() {
    const { file } = this.state;

    const formData = new FormData();
    formData.append('file', file);

    this.setState({ loading: true });

    try {
      await WholesalersApi.importMappings(this.wholesalerId, formData);

      this.setState({ loading: false });
      this.handleResponse();
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  }

  render() {
    const { validation, loading } = this.state;

    return (
      <Dialog
        open
        onClose={this.props.onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Import products mappings')}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '1rem' }}>
            {t('Load csv or excel file')}
          </DialogContentText>
          <form id='form' noValidate onSubmit={this.onSubmit}>
            <FileUploadButton name='file' onUpload={this.onUpload} />
            {validation.status && (
              <Typography
                variant='body2'
                color='error'
                style={{ marginTop: '.5rem' }}
              >
                {validation.message}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.props.onClose} />
          <Button
            form='form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

ImportMappingsDialog.propTypes = {
  wholesalerId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ImportMappingsDialog;
