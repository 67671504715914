import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from 'modules/Layout/component/List/Table';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import { PRICE_LISTS_MANAGEMENT } from 'api/auth/permissions/PriceLists';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_PRICE_LISTS_DETAILS } from 'routing/routes/PriceLists';

class PriceListsTable extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.onDetailsClick = this.onDetailsClick.bind(this);
  }

  onDetailsClick({ id }) {
    this.props.history.push(
      insertPathParams(ROUTE_PRICE_LISTS_DETAILS, { id })
    );
  }

  getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: false
      },
      {
        property: 'country_id',
        label: 'Country',
        sortable: true,
        displayStatus: this.context.hasPermission([
          POSTAL_CODES_COUNTRIES_SHOW
        ]),
        render: id => this.props.countries.find(c => c.id === id)?.name
      },
      {
        property: 'ACTIONS',
        label: 'Actions',
        sortable: false,
        actionOptions: {
          details: {
            status: true,
            disable: false,
            disableMsg: null,
            onClick: this.onDetailsClick
          },
          update: {
            status: this.context.hasPermission([PRICE_LISTS_MANAGEMENT]),
            disable: false,
            disableMsg: null,
            onClick: this.props.onUpdateClick
          },
          remove: {
            status: this.context.hasPermission([PRICE_LISTS_MANAGEMENT]),
            disable: false,
            disableMsg: null,
            onClick: this.props.onDeleteClick
          }
        }
      }
    ];
  }

  render() {
    const { priceLists, sort, setSort, loading } = this.props;

    return (
      <>
        <Table
          cols={this.getCols()}
          rows={priceLists}
          sort={sort}
          setSort={setSort}
          loading={loading}
          actions
        />
      </>
    );
  }
}

PriceListsTable.defaultProps = {
  priceLists: [],
  countries: []
};

PriceListsTable.propTypes = {
  sort: PropTypes.shape({}).isRequired,
  setSort: PropTypes.func.isRequired,
  priceLists: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default withRouter(PriceListsTable);
