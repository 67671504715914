import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { quarters, months, periodTypes } from 'modules/Shared/dateConsts';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';
import {
  roles,
  DEPARTMENT_MANAGER,
  TELEMARKETER,
  TRADER,
  KEY_ACCOUNT_MANAGER
} from 'api/auth/roles';

const setPeriodItemsType = type => {
  switch (type) {
    case 'month':
      return ['Month', months];
    case 'quarter':
      return ['Quarter', quarters];
    default:
      return ['', []];
  }
};

class StatisticsUsersFinishedVisitsFilter extends Filter {
  getInputs() {
    const [label, data] = setPeriodItemsType(this.props.filter.period);

    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'autocomplete',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasPermission([
              POSTAL_CODES_COUNTRIES_SHOW
            ])
          },
          {
            ...this.getBasicInputValues('role'),
            label: 'Role',
            type: 'select',
            options: formatOptions(
              roles.filter(role =>
                [
                  DEPARTMENT_MANAGER,
                  TELEMARKETER,
                  TRADER,
                  KEY_ACCOUNT_MANAGER
                ].includes(role.slug)
              ),
              'slug',
              'name',
              true
            )
          },
          {
            ...this.getBasicInputValues('first_name'),
            label: 'First name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('last_name'),
            label: 'Last name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('department_id'),
            label: 'Departments',
            type: 'select',
            options: formatOptions(
              this.props.filter.country_id
                ? this.props.departments.filter(
                    department =>
                      department.country_id === this.props.filter.country_id
                  )
                : this.props.departments,
              'id',
              'name',
              true
            )
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('year'),
            label: 'Year',
            type: 'year'
          },
          {
            ...this.getBasicInputValues('period'),
            label: 'Period',
            type: 'select',
            options: formatOptions(periodTypes, 'slug', 'name', true)
          },
          {
            ...this.getBasicInputValues(this.props.filter.period),
            label,
            type: 'select',
            options: formatOptions(data, 'id', 'name', true),
            displayStatus: this.props.filter.period !== 'year'
          }
        ]
      }
    ];
  }
}

export default StatisticsUsersFinishedVisitsFilter;
