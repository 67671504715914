import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import Authorize from 'modules/Auth/component/Authorize';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const DashboardBoxWrapper = ({ title, roles, component }) => {
  const { setAlert } = useContext(AlertContext);

  const handleError = err => {
    if (err instanceof ApiError) {
      setAlert(err.getPayload().message);
    } else {
      console.error(err);
    }
  };

  return (
    <Authorize roles={roles}>
      <Grid item xs={12}>
        <Paper className='p1'>
          <Box mb={1}>
            <Typography variant='h6'>{t(title)}</Typography>
          </Box>
          {React.cloneElement(component, { handleError })}
        </Paper>
      </Grid>
    </Authorize>
  );
};

DashboardBoxWrapper.defaultProps = {
  roles: []
};

DashboardBoxWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  component: PropTypes.node.isRequired
};

export default DashboardBoxWrapper;
