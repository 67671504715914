import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import t from 'translate/translate';
import { Box, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from 'modules/Layout/component/IconButton';
import SyncIcon from '@material-ui/icons/Sync';
import SearchIcon from '@material-ui/icons/Search';
import insertPathParams from 'api/utils/insertPathParams';
import { months } from 'modules/Shared/dateConsts';

import SalesPeriodSummaryRecalculateDialog from 'modules/Departments/component/Details/SalesPeriodSummaryRecalculateDialog';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ADMIN, DIRECTOR, SUPER_ADMIN } from 'api/auth/roles';
import {
  ROUTE_DEPARTMENTS_SALES_RESULT,
  ROUTE_DEPARTMENTS_SALES_RESULT_COMPARISON
} from 'routing/routes/Departments';
import Button from 'modules/Layout/component/Button';

class DepartmentYearSalesResults extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.currentMonth = currentDate.getMonth();

    this.state = {
      year: this.currentYear,
      summarizeDialogStatus: false,
      recalculateDialogStatus: false,
      monthToUpdate: null
    };

    this.actionAuthorization = (() => {
      if (context.hasRole([SUPER_ADMIN, ADMIN])) return true;
      if (context.hasRole([DIRECTOR])) {
        return props.checkIfUserIsDepartmentDirector();
      }
      return false;
    })();

    this.onDialogClose = this.onDialogClose.bind(this);
    this.onAdvancedDetailsClick = this.onAdvancedDetailsClick.bind(this);
    this.onComparisonClick = this.onComparisonClick.bind(this);
  }

  onRecalculateClick(month) {
    this.setState({ recalculateDialogStatus: true, monthToUpdate: month });
  }

  onSummarizeClick(month) {
    this.setState({ summarizeDialogStatus: true, monthToUpdate: month });
  }

  onDetailsClick(month) {
    const { year } = this.state;
    const { department_id } = this.props.salesResults;

    const path = `${insertPathParams(ROUTE_DEPARTMENTS_SALES_RESULT, {
      department_id
    })}?month=${month.id}.${year}`;

    this.props.history.push(path);
  }

  onAdvancedDetailsClick() {
    const { department_id } = this.props.salesResults;

    const path = `${insertPathParams(ROUTE_DEPARTMENTS_SALES_RESULT, {
      department_id
    })}?advanced=true`;

    this.props.history.push(path);
  }

  onComparisonClick() {
    const { department_id } = this.props.salesResults;

    const path = `${insertPathParams(
      ROUTE_DEPARTMENTS_SALES_RESULT_COMPARISON,
      {
        id: department_id
      }
    )}`;

    this.props.history.push(path);
  }

  onDialogClose() {
    this.setState({
      recalculateDialogStatus: false,
      summarizeDialogStatus: false,
      monthToUpdate: null
    });
  }

  getSummarizedMonths() {
    const { included_months } = this.props.salesResults;

    return included_months.map(item => parseInt(item.split('.')[0], 10));
  }

  validMonthRange(month) {
    const { year } = this.state;

    if (year < this.currentYear) return true;

    return year <= this.currentYear && month <= this.currentMonth + 1;
  }

  changeDate(increment = false) {
    let year = null;

    this.setState(
      ({ year: prevYear }) => {
        year = increment ? prevYear + 1 : prevYear - 1;
        return { year };
      },
      () => this.props.onPeriodDateChange(year)
    );
  }

  render() {
    const {
      year,
      summarizeDialogStatus,
      recalculateDialogStatus,
      monthToUpdate
    } = this.state;
    const { salesResults, onPeriodDateChange } = this.props;

    const summarizedMonths = this.getSummarizedMonths();

    return (
      <>
        <Typography
          component='h6'
          variant='h6'
          style={{ marginBottom: '1rem' }}
        >
          {t('Sales results approved')}
        </Typography>
        <Box w={1} display='flex' justifyContent='center' alignItems='center'>
          <IconButton
            onClick={() => this.changeDate()}
            icon={<ArrowBackIcon />}
            alt='change to previous year'
          />
          <Typography component='h6' variant='h6'>
            {year}
          </Typography>
          <IconButton
            onClick={() => this.changeDate(true)}
            icon={<ArrowForwardIcon />}
            alt='change to next year'
          />
        </Box>
        <Box display='flex' flexDirection='column'>
          {months.map(month => (
            <Box
              key={month.id}
              mb={0.8}
              display='flex'
              flexDirection='row'
              alignItems='center'
              style={{ height: '2rem' }}
            >
              <Box
                width='2.5rem'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                {summarizedMonths.includes(month.id) && (
                  <CheckIcon color='primary' fontSize='small' />
                )}
              </Box>
              <Box minWidth='12rem'>
                <Typography variant='body1' style={{ marginRight: '.5rem' }}>
                  {t(month.name)}
                </Typography>
              </Box>
              <Box>
                {this.validMonthRange(month.id) && this.actionAuthorization && (
                  <>
                    {summarizedMonths.includes(month.id) ? (
                      <Box display='flex'>
                        <IconButton
                          onClick={() => this.onRecalculateClick(month)}
                          className='update-icon'
                          size='small'
                          icon={<SyncIcon />}
                          alt='recalculate'
                        />
                        <IconButton
                          onClick={() => this.onDetailsClick(month)}
                          className='details-icon'
                          size='small'
                          icon={<SearchIcon />}
                          alt='details'
                        />
                      </Box>
                    ) : (
                      <IconButton
                        onClick={() => this.onSummarizeClick(month)}
                        className='details-icon'
                        icon={<CheckIcon />}
                        size='small'
                        alt='summarize'
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <Button
            onClick={this.onAdvancedDetailsClick}
            color='primary'
            text={t('Check the results over a longer period')}
          />
          <Button
            onClick={this.onComparisonClick}
            color='primary'
            text={t('Compare the periods')}
          />
        </Box>
        {(summarizeDialogStatus || recalculateDialogStatus) && (
          <SalesPeriodSummaryRecalculateDialog
            department_id={salesResults.department_id}
            department_name={salesResults.department_name}
            month={`${monthToUpdate.id}.${year}`}
            monthName={monthToUpdate.name}
            type={summarizeDialogStatus ? 'summarize' : 'recalculate'}
            onClose={this.onDialogClose}
            onSuccess={() => onPeriodDateChange(year)}
          />
        )}
      </>
    );
  }
}

DepartmentYearSalesResults.propTypes = {
  salesResults: PropTypes.shape({
    data: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.number)
    ]),
    included_months: PropTypes.arrayOf(PropTypes.string),
    department_id: PropTypes.number.isRequired,
    department_name: PropTypes.string.isRequired
  }).isRequired,
  onPeriodDateChange: PropTypes.func.isRequired,
  checkIfUserIsDepartmentDirector: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(DepartmentYearSalesResults);
