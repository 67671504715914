import { Component } from 'react';
import PropTypes from 'prop-types';
import insertPathParams from 'api/utils/insertPathParams';
import { Box, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import DashboardListItem from 'modules/Dashboard/component/Layout/ListItem';
import DashboardList from 'modules/Dashboard/component/Layout/List';
import WholesalersApi from 'api/connections/Wholesalers/WholesalersApi';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ROUTE_WHOLESALERS_DETAILS } from 'routing/routes/Wholesalers';

class DashboardAdminOutstandingImports extends Component {
  static contextType = AuthContext;

  static getInformationBox(wholesaler) {
    const { name, city, street, postal_code } = wholesaler;
    const address = `${street ?? ''} ${postal_code ?? ''} ${city ?? ''}`;

    return (
      <Box display='flex' flexDirection='column'>
        <Typography style={{ fontWeight: '600' }}>{name}</Typography>
        <Typography>{address}</Typography>
      </Box>
    );
  }

  static getRedirectLink({ id }) {
    return insertPathParams(ROUTE_WHOLESALERS_DETAILS, { id });
  }

  static renderItem(wholesaler) {
    const informationBox = DashboardAdminOutstandingImports.getInformationBox(
      wholesaler
    );
    const redirectLink = DashboardAdminOutstandingImports.getRedirectLink(
      wholesaler
    );

    return (
      <DashboardListItem
        key={wholesaler.id}
        informationBox={informationBox}
        redirectLink={redirectLink}
      />
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      wholesalers: undefined,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const pathParams = {
      import_after_term: true,
      per_page: Number.MAX_SAFE_INTEGER
    };

    try {
      const {
        data: { data: wholesalers }
      } = await WholesalersApi.getWholesalers(pathParams);

      this.setState({ wholesalers, loading: false });
    } catch (err) {
      this.props.handleError(err);
      this.setState({ wholesalers: undefined, loading: false });
    }
  }

  render() {
    const { loading, wholesalers } = this.state;

    if (loading) return <Loader />;

    return (
      <DashboardList
        data={wholesalers}
        renderItem={DashboardAdminOutstandingImports.renderItem}
      />
    );
  }
}

DashboardAdminOutstandingImports.defaultProps = {
  handleError: null
};

DashboardAdminOutstandingImports.propTypes = {
  handleError: PropTypes.func
};

export default DashboardAdminOutstandingImports;
