export default {
  Sales: 'Sprzedaż',
  'Data set': 'Zbiór Danych Sprzedażowych ',
  'Shopping data set': 'Zbiór danych zakupowych',
  'Buy date': 'Data sprzedaży',
  'Wholesaler product code': 'Kod produktu hurtowni',
  'Wholesaler product name': 'Nazwa produktu hurtowni',
  'Wholesaler client name': 'Nazwa klienta hurtowni',
  'Client name': 'Nazwa klienta',
  'Wholesaler name': 'Nazwa hurtowni',
  'Buy date from': 'Data sprzedaży od',
  'Buy date to': 'Data sprzedaży do',
  'Quantity from': 'Ilość od',
  'Quantity to': 'Ilość do',
  'Price from': 'Cena od',
  'Price to': 'Cena do',
  'The product name must be send without wholesaler name field':
    'Pole nazwa produktu musi być przesłane bez pola nazwa hurtowni',
  'The wholesaler name must be send without product name field':
    'Pole nazwa hurtowni musi być przesłane bez pola nazwa produktu',
  Date: 'Data',
  Value: 'Wartość',
  Range: 'Zakres',
  'The value of sales': 'Wartość sprzedaży'
};
