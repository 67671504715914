import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import { Box, Typography } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import targetTypes, { VALUABLE_TARGET_TYPE } from 'modules/Targets/targetTypes';
import t from 'translate/translate';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import {
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import {
  TARGET_DELETE,
  TARGET_UPDATE,
  TARGET_CONFIRM
} from 'api/auth/permissions/Targets';
import {
  ROUTE_TARGETS_LIST,
  ROUTE_TARGETS_UPDATE
} from 'routing/routes/Targets';
import insertPathParams from 'api/utils/insertPathParams';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import CheckIcon from '@material-ui/icons/Check';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import renderPrice from 'modules/Products/utils/renderPrice';

import TargetDeleteDialog from 'modules/Targets/component/TargetDeleteDialog';
import TargetConfirmDialog from 'modules/Targets/component/TargetConfirmDialog';
import TargetCopyDialog from 'modules/Targets/component/CopyDialog';

class TargetDataSets extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      deleteDialogOpen: false,
      confirmDialogOpen: false,
      copyDialogOpenStatus: false
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.onDialogClose = this.onDialogClose.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
    this.onCopyClick = this.onCopyClick.bind(this);
    this.onCopyDialogClose = this.onCopyDialogClose.bind(this);
  }

  onDeleteClick() {
    this.setState({ deleteDialogOpen: true });
  }

  onConfirmClick() {
    this.setState({ confirmDialogOpen: true });
  }

  onDialogClose() {
    this.setState({ deleteDialogOpen: false, confirmDialogOpen: false });
  }

  onDeleteSuccess() {
    this.props.history.push(ROUTE_TARGETS_LIST);
  }

  onUpdateClick() {
    const { id } = this.props.data;
    this.props.history.push(insertPathParams(ROUTE_TARGETS_UPDATE, { id }));
  }

  onCopyClick() {
    this.setState({
      copyDialogOpenStatus: true
    });
  }

  onCopyDialogClose() {
    this.setState({
      copyDialogOpenStatus: false
    });
  }

  render() {
    const {
      data: {
        id,
        name,
        type,
        value,
        period,
        user_name,
        department_name,
        categories,
        products,
        clients,
        sales_groups,
        regions,
        subregions,
        subsubregions,
        result_value,
        currency
      },
      fetchTarget
    } = this.props;

    const {
      deleteDialogOpen,
      confirmDialogOpen,
      copyDialogOpenStatus
    } = this.state;

    return (
      <>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem' }}
            >
              {t('Basic data')}
            </Typography>
            <TextDataSet label='Name' data={name} />
            <TextDataSet
              label='Type'
              data={t(targetTypes.find(o => o.id === type).name)}
            />
            <TextDataSet label='Department' data={department_name} />
            <TextDataSet label='User' data={user_name} />

            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem', marginTop: '1rem' }}
            >
              {t('Target definition')}
            </Typography>
            <TextDataSet
              label='Value'
              data={
                type === VALUABLE_TARGET_TYPE
                  ? renderPrice(value, currency)
                  : value
              }
            />
            <TextDataSet label='Period' data={period} />

            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem', marginTop: '1rem' }}
            >
              {t('Products included in target')}
            </Typography>
            {isEmpty(categories) && isEmpty(products) ? (
              <Typography variant='body1'>
                {t('The target includes all products')}
              </Typography>
            ) : (
              <>
                <TextDataSet
                  label='Categories'
                  data={categories.map(d => d.name)}
                />
                <TextDataSet label='Products' data={products.map(p => p.sku)} />
              </>
            )}

            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem', marginTop: '1rem' }}
            >
              {t('Limitation on clients')}
            </Typography>
            {isEmpty(clients) && isEmpty(sales_groups) ? (
              <Typography variant='body1'>{t('No restrictions')}</Typography>
            ) : (
              <>
                <TextDataSet label='Clients' data={clients.map(d => d.name)} />
                <TextDataSet
                  label='Shopping groups'
                  data={sales_groups.map(d => d.name)}
                />
              </>
            )}

            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem', marginTop: '1rem' }}
            >
              {t('Limitation on regions')}
            </Typography>
            {isEmpty(regions) &&
            isEmpty(subregions) &&
            isEmpty(subsubregions) ? (
              <Typography variant='body1'>{t('No restrictions')}</Typography>
            ) : (
              <>
                <TextDataSet label='Region' data={regions.map(d => d.name)} />
                <TextDataSet
                  label='Subregion'
                  data={subregions.map(d => d.name)}
                />
                <TextDataSet
                  label='Sub subregion'
                  data={subsubregions.map(d => d.name)}
                />
              </>
            )}
          </Box>
          <Box display='flex' alignItems='flex-start'>
            <Authorize permissions={[TARGET_CONFIRM]}>
              <IconButton
                onClick={this.onConfirmClick}
                className='details-icon'
                icon={<CheckIcon />}
                alt='summarize'
              />
            </Authorize>
            <Authorize permissions={[TARGET_UPDATE]}>
              <IconButton
                className='update-icon'
                onClick={this.onUpdateClick}
                icon={<EditIcon />}
                disabled={result_value || result_value === 0}
                tooltipMsg='Target is closed'
                alt='update'
              />
            </Authorize>
            <Authorize permissions={[TARGET_DELETE]}>
              <IconButton
                className='delete-icon'
                onClick={this.onDeleteClick}
                icon={<DeleteForeverIcon />}
                alt='delete'
                disabled={result_value || result_value === 0}
                tooltipMsg='Target is closed'
              />
            </Authorize>
            <Authorize permissions={[TARGET_UPDATE]}>
              <IconButton
                className='update-icon'
                onClick={this.onCopyClick}
                icon={<FileCopyIcon />}
                alt='copy'
              />
            </Authorize>
          </Box>
        </Box>
        {deleteDialogOpen && (
          <TargetDeleteDialog
            target={{ id, name }}
            onClose={this.onDialogClose}
            onSuccess={this.onDeleteSuccess}
          />
        )}
        {confirmDialogOpen && (
          <TargetConfirmDialog
            target={{ id, name }}
            onClose={this.onDialogClose}
            onSuccess={fetchTarget}
          />
        )}
        {copyDialogOpenStatus && (
          <TargetCopyDialog
            targetToCopy={this.props.data}
            onSuccess={() => {}}
            onClose={this.onCopyDialogClose}
            setAlert={this.props.setAlert}
          />
        )}
      </>
    );
  }
}

TargetDataSets.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
    value: PropTypes.number,
    period: PropTypes.string,
    user_id: PropTypes.number,
    user_name: PropTypes.string,
    department_id: PropTypes.number,
    department_name: PropTypes.string,
    for_management: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    clients: PropTypes.arrayOf(PropTypes.shape({})),
    sales_groups: PropTypes.arrayOf(PropTypes.shape({})),
    regions: PropTypes.arrayOf(PropTypes.shape({})),
    subregions: PropTypes.arrayOf(PropTypes.shape({})),
    subsubregions: PropTypes.arrayOf(PropTypes.shape({})),
    result_value: PropTypes.number,
    currency: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  fetchTarget: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(TargetDataSets);
