import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ApiError from 'api/exceptions/ApiError';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import SalesResultsApi from 'api/connections/SalesResults/SalesResultsApi';
import StatisticsSalesComparisonTable from 'modules/Statistics/SalesComparison/component/List/Table';
import SalesGroupDetailsSalesSummaryFilter from 'modules/SalesGroups/component/SalesSummary/Filter';
import { downloadFile } from 'modules/Shared/utils/file';
import IconButton from 'modules/Layout/component/IconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import * as _ from 'lodash';

export const prepareStatisticsData = data => {
  const filteredData = _.values(data).filter(value => value.quantity_sum > 0);

  return filteredData.map(group => ({
    ...group,
    categories: Object.values(group.categories).map(category => ({
      ...category,
      subcategories: Object.values(category.subcategories).map(subcategory => ({
        ...subcategory,
        products: Object.values(subcategory.products).map(product => ({
          ...product
        }))
      }))
    }))
  }));
};

class SalesGroupDetailsSalesSummary extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      filter: {},
      filterValidation: {},
      data: [],
      loading: false
    };

    this.setFilter = this.setFilter.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.exportData = this.exportData.bind(this);
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  setFilter(filter) {
    this.setState(
      {
        filter
      },
      () => this.fetchData()
    );
  }

  async fetchData() {
    const { filter } = this.state;

    if (!filter.date_from || !filter.date_to) return null;

    try {
      this.setState({ loading: true });

      const {
        data: { data }
      } = await SalesResultsApi.getCurrentSalesResults({
        sales_group_id: this.props.salesGroupId,
        ...filter
      });

      this.setState({
        data: prepareStatisticsData(data),
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async exportData() {
    try {
      const { date_from, date_to, product_sku } = this.state.filter;

      const res = await SalesResultsApi.exportCurrentSalesResults({
        date1_from: date_from,
        date1_to: date_to,
        product_sku,
        sales_group_id: this.props.salesGroupId
      });

      downloadFile(res);
    } catch (err) {
      this.handleError(err);
    }
  }

  renderActions() {
    const { date_from, date_to } = this.state.filter;

    return (
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <IconButton
            onClick={this.exportData}
            icon={<GetAppOutlinedIcon fontSize='large' />}
            alt='export table'
            disabled={!date_from || !date_to}
            tooltipMsg='Pick dates first'
          />
        </Box>
      </Box>
    );
  }

  render() {
    const { data, filter, loading, filterValidation } = this.state;

    return (
      <div>
        <SalesGroupDetailsSalesSummaryFilter
          filter={filter}
          validation={filterValidation}
          setFilter={this.setFilter}
          defaultExpanded
        />

        {this.renderActions()}
        <StatisticsSalesComparisonTable
          rows={data}
          loading={loading}
          bodyText={
            !filter.date_from || !filter.date_to ? 'Pick dates first' : null
          }
        />
        {this.renderActions()}
      </div>
    );
  }
}

SalesGroupDetailsSalesSummary.propTypes = {
  setAlert: PropTypes.func.isRequired,
  salesGroupId: PropTypes.number.isRequired
};

export default SalesGroupDetailsSalesSummary;
