import Filter from 'modules/Layout/component/List/Filter';
import { formatOptions } from 'modules/Layout/component/Select';
import { quarters, months, periodTypes } from 'modules/Shared/dateConsts';
import { POSTAL_CODES_COUNTRIES_SHOW } from 'api/auth/permissions/PostalCodes';

const filterTypes = [
  { slug: 'my_clients', label: 'My clients' },
  { slug: 'all', label: 'All' },
  { slug: 'my_users', label: 'My users' }
];

const setPeriodItemsType = type => {
  switch (type) {
    case 'month':
      return ['Month', months];
    case 'quarter':
      return ['Quarter', quarters];
    default:
      return ['', []];
  }
};

class StatisticsProductsSalesFilter extends Filter {
  getInputs() {
    const [label, data] = setPeriodItemsType(this.props.filter.period);

    return [
      {
        groupLabel: null,
        children: [
          {
            ...this.getBasicInputValues('country_id'),
            label: 'Country',
            type: 'autocomplete',
            emptyValueText: 'All countries',
            options: formatOptions(this.props.countries, 'id', 'name'),
            displayStatus: this.context.hasPermission([
              POSTAL_CODES_COUNTRIES_SHOW
            ])
          },
          {
            ...this.getBasicInputValues('type'),
            label: 'Type',
            type: 'select',
            options: formatOptions(filterTypes, 'slug', 'label', true)
          }
        ]
      },
      {
        groupLabel: 'Period',
        children: [
          {
            ...this.getBasicInputValues('year'),
            label: 'Year',
            type: 'year'
          },
          {
            ...this.getBasicInputValues('period'),
            label: 'Period',
            type: 'select',
            options: formatOptions(periodTypes, 'slug', 'name', true)
          },
          {
            ...this.getBasicInputValues(this.props.filter.period),
            label,
            type: 'select',
            options: formatOptions(data, 'id', 'name', true),
            displayStatus: this.props.filter.period !== 'year'
          }
        ]
      }
    ];
  }
}

export default StatisticsProductsSalesFilter;
