import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class DiscountsApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.discountsPath = 'discounts';
    this.discountPath = 'discounts/:id';
    this.percentageDiscountPath = 'discounts/percentage';
    this.paymentDiscountPath = 'discounts/payment';
    this.thresholdDiscountPath = 'discounts/threshold';
    this.updatePercentageDiscountPath = 'discounts/percentage/:id';
    this.updatePaymentDiscountPath = 'discounts/payment/:id';
    this.updateThresholdDiscountPath = 'discounts/threshold/:id';
    this.exportDiscountsPath = 'discounts/export';
  }

  getDiscounts(pathParams) {
    return this.get(this.discountsPath, pathParams);
  }

  getDiscount(id) {
    return this.get(insertPathParams(this.discountPath, { id }));
  }

  createPercentageDiscount(payload) {
    return this.post(this.percentageDiscountPath, payload);
  }

  updatePercentageDiscount(id, payload) {
    return this.put(
      insertPathParams(this.updatePercentageDiscountPath, { id }),
      payload
    );
  }

  createPaymentDiscount(payload) {
    return this.post(this.paymentDiscountPath, payload);
  }

  updatePaymentDiscount(id, payload) {
    return this.put(
      insertPathParams(this.updatePaymentDiscountPath, { id }),
      payload
    );
  }

  createThresholdDiscount(payload) {
    return this.post(this.thresholdDiscountPath, payload);
  }

  updateThresholdDiscount(id, payload) {
    return this.put(
      insertPathParams(this.updateThresholdDiscountPath, { id }),
      payload
    );
  }

  deletedDiscount(id) {
    return this.delete(insertPathParams(this.discountPath, { id }));
  }

  exportDiscounts(pathParams) {
    const options = {
      responseType: 'blob'
    };
    return this.get(this.exportDiscountsPath, pathParams, options);
  }
}

export default new DiscountsApi();
