import PropTypes from 'prop-types';
import t from 'translate/translate';
import { Box } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import Accordion from 'modules/Layout/component/Accordion';
import { periodTypes } from 'modules/Shared/dateConsts';
import YearPicker from 'modules/Layout/component/Date/YearPicker';
import Input from 'modules/Layout/component/Input';
import MonthYearPicker from 'modules/Layout/component/Date/MonthYearPicker';

const InDepartmentComparisonSalesResultsFilter = props => {
  const {
    filterTitle,
    filter: { period_type, quarter, period },
    onFilterChange
  } = props;

  return (
    <Accordion label={t(filterTitle)}>
      <Box width={1}>
        <Select
          name='period_type'
          label='Period type'
          value={period_type}
          options={formatOptions(periodTypes, 'slug', 'name', true)}
          onChange={onFilterChange}
        />
        {period_type === 'year' && (
          <YearPicker
            name='period'
            label='Year'
            value={period}
            onChange={onFilterChange}
          />
        )}
        {period_type === 'quarter' && (
          <Input
            name='quarter'
            label='Quarter'
            value={quarter}
            type='number'
            inputProps={{
              min: 1,
              max: 4
            }}
            onChange={onFilterChange}
          />
        )}
        {period_type === 'quarter' && (
          <YearPicker
            name='period'
            label='Year'
            value={period}
            onChange={onFilterChange}
          />
        )}
        {period_type === 'month' && (
          <MonthYearPicker
            name='period'
            label='Month'
            value={period}
            onChange={onFilterChange}
          />
        )}
      </Box>
    </Accordion>
  );
};

InDepartmentComparisonSalesResultsFilter.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    period_type: PropTypes.string,
    quarter: PropTypes.string,
    period: PropTypes.string
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired
};

export default InDepartmentComparisonSalesResultsFilter;
