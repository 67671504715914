import PropTypes from 'prop-types';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField, CircularProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash/lang';
import t from 'translate/translate';

const useStyles = makeStyles(theme => ({
  listbox: {
    maxHeight: '200px'
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.light
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const prepareValue = (value, options, multiple) => {
  if (multiple && !isEmpty(value)) {
    return options.filter(op => value.includes(op.key));
  }

  if (!multiple && value) {
    return options.find(op => op.key === value);
  }

  return multiple ? [] : null;
};

const Autocomplete = props => {
  const {
    name,
    options,
    groupBy,
    getOptionDisabled,
    label,
    value,
    onChange,
    onInputChange,
    multiple,
    errorStatus,
    errorText,
    required,
    fullWidth,
    loading,
    disabled,
    tooltipMsg,
    freeSolo,
    style,
    onClose
  } = props;
  const classes = useStyles();

  const autocomplete = (
    <MuiAutocomplete
      multiple={multiple}
      options={options}
      groupBy={groupBy}
      getOptionDisabled={getOptionDisabled}
      fullWidth={fullWidth}
      getOptionLabel={option => option.value}
      disableCloseOnSelect={multiple}
      onChange={onChange}
      onInputChange={onInputChange}
      value={prepareValue(value, options, multiple)}
      getOptionSelected={(o, v) => o.key === v.key}
      classes={{
        option: classes.option,
        listbox: classes.listbox
      }}
      noOptionsText={t('No options')}
      disabled={disabled}
      freeSolo={freeSolo}
      onClose={onClose}
      renderInput={params => (
        <TextField
          /* eslint "react/jsx-props-no-spreading": "off" */
          {...params}
          name={name}
          required={required}
          variant='outlined'
          margin='normal'
          label={t(label)}
          error={errorStatus}
          helperText={errorStatus && errorText}
          style={style}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && (
                  <Box mr={5}>
                    <CircularProgress size={20} />
                  </Box>
                )}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );

  const autocompleteWithTooltip = (
    <Tooltip title={t(tooltipMsg)}>
      <Box style={{ width: '100%' }}>{autocomplete}</Box>
    </Tooltip>
  );

  if (disabled && tooltipMsg) return autocompleteWithTooltip;

  return autocomplete;
};

Autocomplete.defaultProps = {
  value: null,
  multiple: false,
  errorStatus: false,
  errorText: '',
  required: false,
  fullWidth: true,
  loading: false,
  disabled: false,
  tooltipMsg: null,
  freeSolo: false,
  onInputChange: () => {},
  getOptionDisabled: null,
  style: {},
  onClose: () => {},
  groupBy: undefined
};

Autocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  groupBy: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ]),
  errorStatus: PropTypes.bool,
  errorText: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  freeSolo: PropTypes.bool,
  style: PropTypes.shape({}),
  onClose: PropTypes.func
};

export default Autocomplete;
