import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { formatOptions } from 'modules/Layout/component/Select';
import formatDepartmentsOptions from 'modules/Targets/formatters/formatDepartmentsOptions';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { isArray } from 'lodash/lang';
import Input from 'modules/Layout/component/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import t from 'translate/translate';
import FormControl from '@material-ui/core/FormControl';

class UnassignedToEmployeeFilter extends Component {
  static contextType = AuthContext;

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.props.onChange(event);
  }

  render() {
    const { department_id, address, departments, role, onChange } = this.props;

    return (
      <Box width={1} display='flex' justifyContent='space-between'>
        <Box width={1 / 2}>
          <Autocomplete
            name='department_id'
            label='Department'
            value={department_id}
            options={formatOptions(
              formatDepartmentsOptions(departments, this.context.user),
              'id',
              'name'
            )}
            onChange={(_, v) => this.onAutocompleteChange('department_id', v)}
          />
          <Input
            name='address'
            label='Address'
            value={address}
            onChange={onChange}
          />
        </Box>
        <Box>
          <FormControl component='fieldset'>
            <RadioGroup name='role' value={role} onChange={onChange}>
              <FormControlLabel
                value='trader'
                control={<Radio />}
                label={t('Not assigned to a trader')}
              />
              <FormControlLabel
                value='telemarketer'
                control={<Radio />}
                label={t('Not assigned to a telemarketer')}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    );
  }
}

UnassignedToEmployeeFilter.defaultProps = {
  departments: [],
  department_id: undefined
};

UnassignedToEmployeeFilter.propTypes = {
  department_id: PropTypes.number,
  role: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  onChange: PropTypes.func.isRequired
};

export default UnassignedToEmployeeFilter;
