export default (data, day) => {
  if (!data.copyVisitsDays.copy_from) {
    return {
      days: data.days.map(stateDay =>
        stateDay.id === day.id ? { ...day, isClicked: true } : stateDay
      ),
      copyVisitsDays: {
        copy_from: day.fullDate,
        copy_to: day.fullDate
      }
    };
  }

  if (data.copyVisitsDays.copy_from && data.copyVisitsDays.copy_to) {
    if (day.fullDate > data.copyVisitsDays.copy_to) {
      return {
        days: data.days.map(stateDay =>
          stateDay.fullDate >= data.copyVisitsDays.copy_from &&
          stateDay.fullDate <= day.fullDate
            ? { ...stateDay, isClicked: true }
            : stateDay
        ),
        copyVisitsDays: {
          ...data.copyVisitsDays,
          copy_to: day.fullDate
        }
      };
    }

    if (day.fullDate < data.copyVisitsDays.copy_from) {
      return {
        days: data.days.map(stateDay =>
          stateDay.fullDate >= day.fullDate &&
          stateDay.fullDate <= data.copyVisitsDays.copy_from
            ? { ...stateDay, isClicked: true }
            : stateDay
        ),
        copyVisitsDays: {
          ...data.copyVisitsDays,
          copy_from: day.fullDate
        }
      };
    }

    if (
      day.fullDate === data.copyVisitsDays.copy_to ||
      day.fullDate === data.copyVisitsDays.copy_from
    ) {
      return {
        days: data.days.map(stateDay =>
          day.id === stateDay.id
            ? { ...stateDay, isClicked: true }
            : { ...stateDay, isClicked: false }
        ),
        copyVisitsDays: {
          copy_from: day.fullDate,
          copy_to: day.fullDate
        }
      };
    }

    if (
      day.fullDate > data.copyVisitsDays.copy_from &&
      day.fullDate < data.copyVisitsDays.copy_to
    ) {
      return {
        days: data.days.map(stateDay =>
          stateDay.fullDate >= data.copyVisitsDays.copy_from &&
          stateDay.fullDate <= day.fullDate
            ? { ...stateDay, isClicked: true }
            : { ...stateDay, isClicked: false }
        ),
        copyVisitsDays: {
          ...data.copyVisitsDays,
          copy_to: day.fullDate
        }
      };
    }
  }
};
