import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from 'modules/Layout/component/Checkbox';
import { formatOptions } from 'modules/Layout/component/Select';
import { Box } from '@material-ui/core';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';

import AlertContext from 'modules/Shared/context/Alert/alertContext';
import SalesImportsApi from 'api/connections/SalesImports/SalesImportsApi';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import { isArray } from 'lodash/lang';

class MapClientDialog extends Component {
  static contextType = AlertContext;

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        client_id: '',
        use_same: false
      },
      validation: {
        client_id: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Client')
          })
        }
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleResponse() {
    this.context.setAlert({
      value: t('Client mapped'),
      variant: COLOR_VARIANTS_SUCCESS
    });
    this.props.onSuccess();
    this.props.onClose();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ validation: validationState }) => {
          return {
            validation: { ...validationState, ...newValidateState }
          };
        });
      }
    } else {
      console.error(err);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  }

  onChange(e) {
    this.setState(state => {
      const { formData, validation } = state;
      const { name, type } = e.target;
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });
  }

  onAutocompleteChange(name, res) {
    let value = null;

    if (res) {
      value = isArray(res) ? res.map(v => v.key) : res.key;
    }

    const event = { target: { name, value } };

    this.onChange(event);
  }

  runValidation() {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  }

  async makeApiCall() {
    const { formData } = this.state;

    const {
      importId,
      temporaryClient: { id: tempId }
    } = this.props;

    try {
      this.setState({ loading: true });
      await SalesImportsApi.mapClient({ importId, tempId }, formData);
      this.handleResponse();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      formData: { client_id, use_same },
      validation,
      loading
    } = this.state;

    const { clients, temporaryClient } = this.props;

    const {
      wholesaler_client_number,
      wholesaler_client_name,
      error_type
    } = temporaryClient;

    return (
      <Dialog
        open
        onClose={this.props.onClose}
        maxWidth='sm'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle>{t('Map temporary client')}</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <TextDataSet
              label='Wholesaler client number'
              data={wholesaler_client_number}
            />
            <TextDataSet
              label='Wholesaler client name'
              data={wholesaler_client_name}
            />
            <TextDataSet label='Error type' data={t(error_type)} />
          </Box>
          <form id='map-client-form' noValidate onSubmit={this.onSubmit}>
            <Autocomplete
              name='client_id'
              label='Client'
              value={client_id}
              options={formatOptions(clients ?? [], 'id', 'name')}
              onChange={(_, v) => this.onAutocompleteChange('client_id', v)}
              required
              errorStatus={validation.client_id.status}
              errorText={validation.client_id.message}
            />
            <Box mt={2}>
              <Checkbox
                onChange={this.onChange}
                checked={use_same}
                name='use_same'
                label={
                  error_type === 'wrong tax number'
                    ? 'Use the client in identical cases in this import'
                    : 'Save and use mapping in identical cases in all imports'
                }
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button text={t('Cancel')} onClick={this.props.onClose} />
          <Button
            form='map-client-form'
            type='submit'
            color='primary'
            text={t('Save')}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

MapClientDialog.propTypes = {
  importId: PropTypes.number.isRequired,
  temporaryClient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    wholesaler_client_number: PropTypes.string.isRequired,
    wholesaler_client_name: PropTypes.string.isRequired,
    error_type: PropTypes.string.isRequired
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default MapClientDialog;
