import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CustomDialog from 'modules/Layout/component/CustomDialog';
import { Box, Typography } from '@material-ui/core';
import Select, { formatOptions } from 'modules/Layout/component/Select';
import t from 'translate/translate';
import Button from 'modules/Layout/component/Button';
import KnowledgeBaseApi from 'api/connections/KnowledgeBase/KnowledgeBaseApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import { SUPER_ADMIN } from 'api/auth/roles';
import AutocompleteBigData from 'modules/Layout/component/AutocompleteBigData';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ChipInput from 'modules/Layout/component/ChipInput';
import { isEmpty } from 'lodash/lang';
import { formatWholesalersEmailDomainsError } from 'modules/Wholesalers/utils/formatter';

const SendFilesToClientModal = ({
  open,
  modalCloseFn,
  onSendSuccess,
  filesToSend,
  linksToSend,
  setAlert,
  clients,
  countries,
  countryId: countryIdProp,
  onCountryChange,
  areClientsLoading,
  hasRole
}) => {
  const [clientId, setClientId] = useState(null);
  const [recipientType, setRecipientType] = useState('client');
  const [emails, setEmails] = useState([]);
  const [emailsValidation, setEmailsValidation] = useState({
    status: false,
    message: null
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleError = err => {
    if (err instanceof ApiError) {
      const { errors, message } = err.getPayload();
      setAlert(message);

      if (err instanceof ValidationApiError) {
        const {
          emails: emailsValidationMsg
        } = formatWholesalersEmailDomainsError(errors, {
          emails
        });

        setEmailsValidation(emailsValidationMsg);
      }
    }
  };

  const onClientChange = value => {
    setClientId(value?.key);
  };

  const onRecipientTypeChange = e => {
    const { value } = e.target;

    setRecipientType(value);
    setClientId(null);
    setEmails([]);
  };

  const sendFiles = async () => {
    setButtonLoading(true);
    try {
      await KnowledgeBaseApi.sendFiles({
        client_id: clientId,
        emails,
        files_notes: filesToSend,
        links_notes: linksToSend
      });

      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });

      onSendSuccess();
    } catch (err) {
      handleError(err);
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    setClientId(null);
  }, [countryIdProp]);

  const renderActions = () => {
    return (
      <Box display='flex' justifyContent='flex-end'>
        <Button
          text={t('Send')}
          color='primary'
          onClick={sendFiles}
          disabled={!clientId && isEmpty(emails)}
          loading={buttonLoading}
          tooltipMsg={t('Choose client or fill emails')}
        />
      </Box>
    );
  };

  const onChipAdd = chip => {
    setEmails([...emails, chip]);
    setEmailsValidation({ ...emailsValidation, status: false });
  };

  const onChipDelete = chip => {
    setEmails(emails.filter(data => data !== chip));
    setEmailsValidation({ ...emailsValidation, status: false });
  };

  return (
    <CustomDialog
      open={open}
      onClose={modalCloseFn}
      title={t('Send attachments')}
      actions={renderActions()}
      maxWidth={hasRole([SUPER_ADMIN]) ? 'md' : 'sm'}
    >
      <Box display='flex' flexDirection='column'>
        <Box display='flex' alignItems='center' mb={2}>
          <Box mr={3}>
            <Typography variant='subtitle1' style={{ whiteSpace: 'nowrap' }}>
              {`${t('Recipient type')}: `}
            </Typography>
          </Box>
          <Box width={1}>
            <FormControl component='fieldset'>
              <RadioGroup
                name='recipientType'
                value={recipientType}
                onChange={onRecipientTypeChange}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value='client'
                  control={<Radio />}
                  label={t('System client')}
                />
                <FormControlLabel
                  value='emails'
                  control={<Radio />}
                  label={t('Email addresses')}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {recipientType === 'client' ? (
          <>
            <Typography color='textSecondary'>
              {t('Clients without email cannot be chosen.')}
            </Typography>
            <Box display='flex'>
              {hasRole([SUPER_ADMIN]) && (
                <Select
                  name='country_id'
                  label='Country'
                  value={countryIdProp}
                  options={formatOptions(countries, 'id', 'name')}
                  onChange={onCountryChange}
                  emptyValueText='Choose country'
                  style={{ input: { marginRight: 10 } }}
                />
              )}
              <AutocompleteBigData
                name='client_id'
                label='Client'
                value={clientId}
                onChange={(e, v) => onClientChange(v)}
                options={formatOptions(clients, 'id', 'name')}
                loading={areClientsLoading}
                getOptionDisabled={option => {
                  const { email } = clients.find(({ id }) => id === option.key);
                  return !email;
                }}
              />
            </Box>
          </>
        ) : (
          <ChipInput
            name='emails'
            label='Email addresses'
            value={emails}
            onAdd={(name, chip) => onChipAdd(chip)}
            onDelete={(name, chip) => onChipDelete(chip)}
            errorStatus={emailsValidation.status}
            errorText={emailsValidation.message}
          />
        )}
      </Box>
    </CustomDialog>
  );
};

SendFilesToClientModal.propTypes = {
  open: PropTypes.bool.isRequired,
  modalCloseFn: PropTypes.func.isRequired,
  onSendSuccess: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  filesToSend: PropTypes.arrayOf(PropTypes.number).isRequired,
  linksToSend: PropTypes.arrayOf(PropTypes.number).isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryId: PropTypes.number.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  areClientsLoading: PropTypes.bool.isRequired,
  hasRole: PropTypes.func.isRequired
};

export default SendFilesToClientModal;
