import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import Loader from 'modules/Layout/component/Loader';
import ApiError from 'api/exceptions/ApiError';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import { ROUTE_DEPARTMENTS_LIST } from 'routing/routes/Departments';
import Authorize from 'modules/Auth/component/Authorize';
import IconButton from 'modules/Layout/component/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { ORDER_TYPES_MANAGEMENT } from 'api/auth/permissions/OrderTypes';
import DepartmentsOrderTypesApi from 'api/connections/Departments/DepartmentsOrderTypesApi';
import arrayMove from 'array-move';
import { isEmpty } from 'lodash/lang';
import t from 'translate/translate';
import AuthContext from 'modules/Auth/context/Auth/authContext';

import OrderTypeList from 'modules/Departments/component/OrderTypes/OrderTypeList';
import DepartmentOrderTypeDeleteModal from 'modules/Departments/component/OrderTypes/DeleteModal';
import DepartmentOrderTypeFormModal from 'modules/Departments/component/OrderTypes/FormModal';
import { DIRECTOR } from 'api/auth/roles';

class DepartmentsDetailsOrderTypes extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      orderTypes: [],
      loading: false,
      formModalStatus: false,
      deleteModalStatus: false,
      orderTypeToModify: undefined
    };

    this.onSortEnd = this.onSortEnd.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.fetchOrderTypes = this.fetchOrderTypes.bind(this);
  }

  componentDidMount() {
    this.fetchOrderTypes();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_DEPARTMENTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  onCreateClick() {
    this.setState({
      formModalStatus: true
    });
  }

  onUpdateClick(data) {
    this.setState({
      orderTypeToModify: data,
      formModalStatus: true
    });
  }

  onDeleteClick(data) {
    this.setState({
      deleteModalStatus: true,
      orderTypeToModify: data
    });
  }

  onSortEnd({ oldIndex, newIndex }) {
    if (oldIndex === newIndex) return;

    this.setState(
      ({ orderTypes }) => ({
        orderTypes: arrayMove(orderTypes, oldIndex, newIndex)
      }),
      () => this.savePriority()
    );
  }

  closeAllModals() {
    this.setState({
      orderTypeToModify: undefined,
      formModalStatus: false,
      deleteModalStatus: false
    });
  }

  async fetchOrderTypes() {
    try {
      this.setState({ loading: true });

      const {
        data: { data: orderTypes }
      } = await DepartmentsOrderTypesApi.getDepartmentOrderTypes({
        department_id: this.props.departmentId,
        per_page: Number.MAX_SAFE_INTEGER,
        page: 1
      });

      this.setState({
        orderTypes,
        loading: false
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  async savePriority() {
    const sort = this.state.orderTypes.map(({ id }) => id);

    try {
      await DepartmentsOrderTypesApi.updateDepartmentOrderTypeSort({
        department_id: this.props.departmentId,
        sort
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  renderCreateAction() {
    const loggedUserDepartments = this.context.user.departments.map(
      ({ id }) => id
    );

    return (
      <Box display='flex'>
        <Authorize permissions={[ORDER_TYPES_MANAGEMENT]}>
          <IconButton
            className='create-icon'
            onClick={this.onCreateClick}
            icon={<AddIcon fontSize='large' />}
            alt='create'
            disabled={
              this.context.hasRole([DIRECTOR]) &&
              !loggedUserDepartments.includes(this.props.departmentId)
            }
            tooltipMsg='You are not director of this department'
          />
        </Authorize>
      </Box>
    );
  }

  render() {
    const {
      orderTypes,
      loading,
      formModalStatus,
      deleteModalStatus,
      orderTypeToModify
    } = this.state;

    if (loading) return <Loader />;

    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} lg={8}>
          <Paper className='p1'>
            <OrderTypeList
              orderTypes={orderTypes}
              onSortEnd={this.onSortEnd}
              onUpdateClick={this.onUpdateClick}
              onDeleteClick={this.onDeleteClick}
            />
            {!loading && isEmpty(orderTypes) && (
              <Box display='flex' justifyContent='center'>
                <Typography color='textSecondary'>
                  {t('Department have no order types')}
                </Typography>
              </Box>
            )}
            {this.renderCreateAction()}
            {formModalStatus && (
              <DepartmentOrderTypeFormModal
                orderType={orderTypeToModify}
                departmentId={this.props.departmentId}
                setAlert={this.props.setAlert}
                onClose={this.closeAllModals}
                onSuccess={this.fetchOrderTypes}
              />
            )}
            {deleteModalStatus && (
              <DepartmentOrderTypeDeleteModal
                orderType={orderTypeToModify}
                setAlert={this.props.setAlert}
                onClose={this.closeAllModals}
                onSuccess={this.fetchOrderTypes}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

DepartmentsDetailsOrderTypes.propTypes = {
  departmentId: PropTypes.number.isRequired,
  setAlert: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(DepartmentsDetailsOrderTypes);
