import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { isArray, isEmpty } from 'lodash/lang';

const useStyles = theme => ({
  dataSet: {
    marginBottom: theme.spacing(0.7)
  },
  label: {
    marginRight: theme.spacing(1),
    fontWeight: 600
  }
});

const TextDataSet = props => {
  const { label, data, classes, color, join } = props;

  const renderNoData = () => {
    return <Typography color={color}>{t('No data')}</Typography>;
  };

  const renderArray = () => {
    if (isEmpty(data)) return renderNoData();

    if (join) return <Typography>{data.join(', ')}</Typography>;

    return (
      <Box>
        {data.map(item => {
          return (
            <Typography key={`${label}-${item}`} color={color}>
              {item}
            </Typography>
          );
        })}
      </Box>
    );
  };

  const renderSingleData = () => {
    return data === null || data === undefined || data === '' ? (
      renderNoData()
    ) : (
      <Typography>{data}</Typography>
    );
  };

  const renderData = () => {
    return isArray(data) ? renderArray() : renderSingleData();
  };

  return (
    <Box display='flex' className={classes.dataSet}>
      <Typography className={classes.label} color={color}>
        {t(label)}:{' '}
      </Typography>
      {renderData()}
    </Box>
  );
};

TextDataSet.defaultProps = {
  data: null,
  color: 'initial',
  join: false
};

TextDataSet.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.node
  ]),
  classes: PropTypes.shape({
    dataSet: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  color: PropTypes.string,
  join: PropTypes.bool
};

export default withStyles(useStyles)(TextDataSet);
