import BaseApiConfig from 'api/connections/BaseApiConfig';
import insertPathParams from 'api/utils/insertPathParams';

class SalesGroupsNotesApi extends BaseApiConfig {
  constructor(props) {
    super(props);

    this.salesGroupPath = 'sales/groups/:id/notes';
    this.salesGroupNotePath = 'sales/groups/:groupId/notes/:noteId';
  }

  getSalesGroupNotes(id, pathParams) {
    return this.get(insertPathParams(this.salesGroupPath, { id }), pathParams);
  }

  createSalesGroupNote(id, payload) {
    return this.post(insertPathParams(this.salesGroupPath, { id }), payload);
  }

  updateSalesGroupNote(ids, payload) {
    return this.put(insertPathParams(this.salesGroupNotePath, ids), payload);
  }

  deleteSalesGroupNote(ids) {
    return this.delete(insertPathParams(this.salesGroupNotePath, ids));
  }
}

export default new SalesGroupsNotesApi();
