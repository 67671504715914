import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import KnowledgeBaseApi from 'api/connections/KnowledgeBase/KnowledgeBaseApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import AlertContext from 'modules/Shared/context/Alert/alertContext';
import { useContext } from 'react';

const DeleteNoteModal = ({ open, onCancel, noteToDelete, onDeleteSuccess }) => {
  const { setAlert } = useContext(AlertContext);

  const deleteNote = async () => {
    try {
      await KnowledgeBaseApi.deleteNote(noteToDelete.id);
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onDeleteSuccess();
    } catch (err) {
      if (err instanceof ValidationApiError || err instanceof ApiError) {
        const { message } = err.getPayload();
        setAlert(message);
      } else {
        console.error(err);
      }
    }
  };

  return (
    <Dialog
      onCancel={onCancel}
      open={open}
      onApprove={deleteNote}
      title={`${t('Delete note')}: ${noteToDelete.name}`}
      description={t('Are you sure you want to delete note?')}
    />
  );
};

DeleteNoteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
  noteToDelete: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired
};

export default DeleteNoteModal;
