import { useContext } from 'react';
import PropTypes from 'prop-types';
import t from 'translate/translate';
import Dialog from 'modules/Layout/component/Dialog';
import VisitFilesApi from 'api/connections/Visits/VisitFilesApi';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import AlertContext from 'modules/Shared/context/Alert/alertContext';

const DeleteVisitFileDialog = props => {
  const {
    file: { id: fileId, name: fileName },
    visitId,
    onClose,
    onSuccess
  } = props;
  const { setAlert } = useContext(AlertContext);

  const deleteMapping = async () => {
    try {
      await VisitFilesApi.deleteVisitFile({
        visitId,
        fileId
      });
      setAlert({
        value: t('Success'),
        variant: COLOR_VARIANTS_SUCCESS
      });
      onClose();
      onSuccess(fileId);
    } catch (err) {
      setAlert(err.getPayload().message);
      onClose();
    }
  };

  return (
    <Dialog
      open
      title={t('Deletion confirmation')}
      description={t('Are you sure you want to delete file <%=fileName%>', {
        fileName
      })}
      onApprove={deleteMapping}
      onCancel={onClose}
    />
  );
};

DeleteVisitFileDialog.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  visitId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default DeleteVisitFileDialog;
