import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import t from 'translate/translate';
import { Link } from 'react-router-dom';
import insertPathParams from 'api/utils/insertPathParams';
import { ROUTE_PROMOTIONS_DETAILS } from 'routing/routes/Promotions';
import IconButton from 'modules/Layout/component/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { isEmpty, isObject } from 'lodash/lang';
import InfoTooltip from 'modules/Layout/component/InfoTooltip';

export const isPromotionCoveringProduct = (product, promotion) => {
  const {
    group_id: productGroupId,
    category_id: productCategoryId,
    subcategory_id: productSubcategoryId
  } = product;

  const productId = product.product_id ?? product.id;

  const {
    group_id: promotionGroupId,
    category_id: promotionCategoryId,
    subcategory_id: promotionSubcategoryId,
    products: promotionProducts
  } = promotion;

  if (!isEmpty(promotionProducts)) {
    return promotionProducts
      .map(p => (isObject(p) ? p.id : p))
      .includes(productId);
  }

  if (promotionSubcategoryId) {
    return promotionSubcategoryId === productSubcategoryId;
  }

  if (promotionCategoryId) {
    return promotionCategoryId === productCategoryId;
  }

  if (promotionGroupId) {
    return promotionGroupId === productGroupId;
  }

  if (
    isEmpty(promotionProducts) &&
    !promotionSubcategoryId &&
    !promotionCategoryId &&
    !promotionGroupId
  ) {
    return true;
  }

  return false;
};

const ProductPromotionsTooltip = ({ product, promotions }) => {
  if (product.promotions_blocked) return null;

  const validPromotions = promotions.filter(promotion =>
    isPromotionCoveringProduct(product, promotion)
  );

  if (isEmpty(validPromotions)) return null;

  const tooltipText = (
    <Box>
      <Typography variant='h6'>{t('Product promotions')}</Typography>
      <Box mt={2}>
        {validPromotions.map(p => (
          <Box
            key={`promotion-tooltip-${p.id}`}
            display='flex'
            flexDirection='row'
            alignItems='center'
          >
            {p.name}
            <Link
              to={insertPathParams(ROUTE_PROMOTIONS_DETAILS, { id: p.id })}
              className='router-button'
              target='_blank'
            >
              <IconButton
                className='details-icon'
                icon={<SearchIcon />}
                alt='details'
              />
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return <InfoTooltip text={tooltipText} interactive />;
};

ProductPromotionsTooltip.propTypes = {
  product: PropTypes.shape({
    promotions_blocked: PropTypes.bool.isRequired
  }).isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default ProductPromotionsTooltip;
