export const UNKNOWN_WHOLESALER_SALE_IMPORT_STATUS = 1;
export const WAITING_FOR_VALIDATION_SALE_IMPORT_STATUS = 2;
export const VALIDATION_SUCCESS_SALE_IMPORT_STATUS = 3;
export const VALIDATION_ERROR_SALE_IMPORT_STATUS = 4;
export const UNMAPPED_PRODUCTS_SALE_IMPORT_STATUS = 5;
export const IMPORTED_SALE_IMPORT_STATUS = 10;
export const CANCELED_SALE_IMPORT_STATUS = 11;

export const SALES_IMPORTS_STATUSES = [
  { id: UNKNOWN_WHOLESALER_SALE_IMPORT_STATUS, name: 'Unknown wholesaler' },
  {
    id: WAITING_FOR_VALIDATION_SALE_IMPORT_STATUS,
    name: 'Waiting for validation'
  },
  { id: VALIDATION_SUCCESS_SALE_IMPORT_STATUS, name: 'Validation success' },
  { id: VALIDATION_ERROR_SALE_IMPORT_STATUS, name: 'Validation error' },
  {
    id: UNMAPPED_PRODUCTS_SALE_IMPORT_STATUS,
    name: 'Unmapped products or clients'
  },
  { id: IMPORTED_SALE_IMPORT_STATUS, name: 'Imported' },
  { id: CANCELED_SALE_IMPORT_STATUS, name: 'Canceled' }
];
