import { Route } from 'react-router-dom';
import Guard from 'routing/Guard';
import Login from 'modules/Auth/view/Login';
import Reset from 'modules/Auth/view/Password/Reset';
import Change from 'modules/Auth/view/Password/Change';
import Forced from 'modules/Auth/view/Password/Forced';

export const ROUTE_LOGIN = '/login';
export const ROUTE_PASSWORD_RESET = '/password/reset';
export const ROUTE_PASSWORD_CHANGE = '/password/change';
export const ROUTE_FORCED_CHANGE_PASSWORD = '/password/forced';

const routes = {
  public: [
    <Route key='auth-login' exact path={ROUTE_LOGIN}>
      <Guard unguarded>
        <Login />
      </Guard>
    </Route>,
    <Route
      key='auth-password-reset'
      exact
      path={ROUTE_PASSWORD_RESET}
      component={Reset}
    />,
    <Route
      key='auth-password-change'
      exact
      path={ROUTE_PASSWORD_CHANGE}
      component={Change}
    />,
    <Route
      key='auth-password-forced'
      exact
      path={ROUTE_FORCED_CHANGE_PASSWORD}
      component={Forced}
    />
  ]
};

export default routes;
