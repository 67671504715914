import Filter from 'modules/Layout/component/List/Filter';
import parse from 'date-fns/parse';
import { API_DATE_FORMAT } from 'modules/Layout/component/Date/DatePicker';
import addYears from 'date-fns/addYears';

class SalesFilter extends Filter {
  getInputs() {
    return [
      {
        groupLabel: 'Product',
        children: [
          {
            ...this.getBasicInputValues('product_sku'),
            label: 'Name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('wholesaler_name'),
            label: 'Wholesaler name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('wholesaler_product_name'),
            label: 'Wholesaler product name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('wholesaler_product_code'),
            label: 'Wholesaler product code',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: 'Client',
        children: [
          {
            ...this.getBasicInputValues('client_name'),
            label: 'Client name',
            type: 'text'
          },
          {
            ...this.getBasicInputValues('wholesaler_client_name'),
            label: 'Wholesaler client name',
            type: 'text'
          }
        ]
      },
      {
        groupLabel: 'Date',
        children: [
          {
            property: 'quantity-valueOrRange',
            type: 'valueOrRange',
            options: {
              value: {
                ...this.getBasicInputValues('buy_date'),
                label: 'Buy date',
                type: 'date'
              },
              rangeFrom: {
                ...this.getBasicInputValues('buy_date_from'),
                label: 'Buy date from',
                type: 'date',
                maxDate: this.props.filter.buy_date_to
                  ? parse(
                      this.props.filter.buy_date_to,
                      API_DATE_FORMAT,
                      new Date()
                    )
                  : addYears(new Date(), 20)
              },
              rangeTo: {
                ...this.getBasicInputValues('buy_date_to'),
                label: 'Buy date to',
                type: 'date',
                minDate: this.props.filter.buy_date_from
                  ? parse(
                      this.props.filter.buy_date_from,
                      API_DATE_FORMAT,
                      new Date()
                    )
                  : addYears(new Date(), -20)
              }
            }
          }
        ]
      },
      {
        groupLabel: 'Quantity',
        children: [
          {
            property: 'quantity-valueOrRange',
            type: 'valueOrRange',
            options: {
              value: {
                ...this.getBasicInputValues('quantity'),
                label: 'Quantity',
                type: 'number',
                inputProps: { min: 0 }
              },
              rangeFrom: {
                ...this.getBasicInputValues('quantity_from'),
                label: 'Quantity from',
                type: 'number',
                inputProps: { min: 0, max: this.props.filter.quantity_to }
              },
              rangeTo: {
                ...this.getBasicInputValues('quantity_to'),
                label: 'Quantity to',
                type: 'number',
                inputProps: { min: this.props.filter.quantity_from ?? 0 }
              }
            }
          }
        ]
      },
      {
        groupLabel: 'Price',
        children: [
          {
            property: 'price-valueOrRange',
            type: 'valueOrRange',
            options: {
              value: {
                ...this.getBasicInputValues('price'),
                label: 'Price',
                type: 'number',
                inputProps: { min: 0 }
              },
              rangeFrom: {
                ...this.getBasicInputValues('price_from'),
                label: 'Price from',
                type: 'number',
                inputProps: { min: 0, max: this.props.filter.price_to }
              },
              rangeTo: {
                ...this.getBasicInputValues('price_to'),
                label: 'Price to',
                type: 'number',
                inputProps: { min: this.props.filter.price_from ?? 0 }
              }
            }
          }
        ]
      }
    ];
  }
}

export default SalesFilter;
