import { SortableElement } from 'react-sortable-hoc';
import { ListItem, ListItemText } from '@material-ui/core';
import useStyles from 'modules/Visits/components/RoutePlanning/VisitsList/styles';
import IconButton from 'modules/Layout/component/IconButton';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';

const SortableListItem = SortableElement(({ visit, onVisitDelete }) => {
  const classes = useStyles();

  return (
    <ListItem
      className={classes.item}
      style={{ cursor: 'grab' }}
      disabled={Boolean(visit.scheduled_at)}
    >
      <div>
        <ListItemText className={classes.text} primary={visit.client_name} />
        <ListItemText
          className={classes.text}
          primary={visit.scheduled_at || visit.new_scheduled_at}
        />
      </div>
      <IconButton
        className='delete-icon'
        icon={<DeleteForeverIcon />}
        onClick={onVisitDelete}
        alt='delete'
      />
    </ListItem>
  );
});

export default SortableListItem;
