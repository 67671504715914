import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import IconButton from 'modules/Layout/component/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import MoneyIcon from '@material-ui/icons/Money';
import BlockIcon from '@material-ui/icons/Block';
import SyncIcon from '@material-ui/icons/Sync';
import TextDataSet from 'modules/Layout/component/Details/TextDataSet';
import t from 'translate/translate';
import clientBonusStatuses, {
  BONUS_TYPE_WAITING_FOR_CONFIRMATION,
  BONUS_TYPE_CONFIRMED,
  BONUS_TYPE_SUSPENDED
} from 'modules/ClientsBonuses/clientBonusStatuses';
import renderPrice from 'modules/Products/utils/renderPrice';

import AuthContext from 'modules/Auth/context/Auth/authContext';
import SetBonusFinalValueDialog from 'modules/ClientsBonuses/component/Dialogs/SetBonusFinalValue';
import ConfirmBonusDialog from 'modules/ClientsBonuses/component/Dialogs/ConfirmBonus';
import PaidBonusDialog from 'modules/ClientsBonuses/component/Dialogs/PaidBonus';
import SuspendBonusDialog from 'modules/ClientsBonuses/component/Dialogs/SuspendBonus';
import UnSuspendBonusDialog from 'modules/ClientsBonuses/component/Dialogs/UnSuspendBonus';
import Authorize from 'modules/Auth/component/Authorize';
import { TARGET_UPDATE } from 'api/auth/permissions/Targets';
import { BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION } from 'modules/TargetsBonuses/targetBonusesTypes';

class ClientsBonusesDetailsDataSets extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      finalValueDialogStatus: false,
      confirmDialogStatus: false,
      paidDialogStatus: false,
      unSuspendDialogStatus: false
    };

    this.onUpdateFinalValueClick = this.onUpdateFinalValueClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.onPaidClick = this.onPaidClick.bind(this);
    this.onSuspendClick = this.onSuspendClick.bind(this);
    this.onUnSuspendClick = this.onUnSuspendClick.bind(this);
    this.closeAllDialogs = this.closeAllDialogs.bind(this);
  }

  onUpdateFinalValueClick() {
    this.setState({ finalValueDialogStatus: true });
  }

  onConfirmClick() {
    this.setState({ confirmDialogStatus: true });
  }

  onPaidClick() {
    this.setState({ paidDialogStatus: true });
  }

  onSuspendClick() {
    this.setState({ suspendDialogStatus: true });
  }

  onUnSuspendClick() {
    this.setState({ unSuspendDialogStatus: true });
  }

  closeAllDialogs() {
    this.setState({
      finalValueDialogStatus: false,
      confirmDialogStatus: false,
      paidDialogStatus: false,
      suspendDialogStatus: false,
      unSuspendDialogStatus: false
    });
  }

  render() {
    const {
      finalValueDialogStatus,
      confirmDialogStatus,
      paidDialogStatus,
      suspendDialogStatus,
      unSuspendDialogStatus
    } = this.state;

    const {
      bonus: {
        id: bonusId,
        status,
        confirmed_by,
        confirmed_at,
        suspended_by,
        suspended_at,
        suspended_reason,
        current_update_date,
        current_estimation_value,
        current_estimation_bonus,
        result_value,
        final_value,
        current_value,
        current_bonus,
        currency
      },
      fetchBonusData
    } = this.props;

    return (
      <>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Typography
              component='h6'
              variant='h6'
              style={{ marginBottom: '.5rem' }}
            >
              {t('Basic data')}
            </Typography>
            <TextDataSet label='Status' data={t(clientBonusStatuses[status])} />
            {!isEmpty(confirmed_by) && (
              <>
                <TextDataSet
                  label='Confirmed by'
                  data={`${confirmed_by.first_name} ${confirmed_by.last_name}`}
                />
                <TextDataSet label='Confirmed at' data={confirmed_at || ''} />
              </>
            )}
            {suspended_at && (
              <>
                <TextDataSet
                  label='Suspended by'
                  data={`${suspended_by.first_name} ${suspended_by.last_name}`}
                />
                <TextDataSet label='Suspended at' data={suspended_at} />
                <TextDataSet label='Suspended reason' data={suspended_reason} />
              </>
            )}

            {status !== BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION && (
              <>
                <TextDataSet
                  label='Final value of purchased products'
                  data={renderPrice(result_value, currency)}
                />
                <TextDataSet
                  label='Final bonus value'
                  data={renderPrice(final_value, currency)}
                />
              </>
            )}

            {status === BONUS_TYPE_WAITING_FOR_TARGET_CONFIRMATION && (
              <>
                <TextDataSet
                  label='Current value of purchased products'
                  data={renderPrice(current_value, currency)}
                />
                <TextDataSet
                  label='Current bonus value'
                  data={renderPrice(current_bonus, currency)}
                />
                <Box mb={2}>
                  <Typography
                    component='h6'
                    variant='h6'
                    style={{ marginBottom: '.5rem', marginTop: '1rem' }}
                  >
                    {t('Goal achievement forecasts')}
                  </Typography>

                  {current_update_date ? (
                    <>
                      <TextDataSet
                        label='Date of estimating the level of contract achievement'
                        data={current_update_date}
                      />
                      <TextDataSet
                        label='Estimated total value of purchased products'
                        data={renderPrice(current_estimation_value, currency)}
                      />
                      <TextDataSet
                        label='Estimated contract bonus value'
                        data={renderPrice(current_estimation_bonus, currency)}
                      />
                    </>
                  ) : (
                    <Typography variant='body1'>
                      {t(
                        'The system has not yet set an estimated contract achievement level.'
                      )}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
          <Box display='flex' alignItems='flex-start'>
            <Authorize permissions={[TARGET_UPDATE]}>
              {status === BONUS_TYPE_WAITING_FOR_CONFIRMATION && (
                <>
                  <IconButton
                    onClick={this.onConfirmClick}
                    className='details-icon'
                    icon={<CheckIcon />}
                    alt='confirm'
                  />
                  <IconButton
                    className='update-icon'
                    onClick={this.onUpdateFinalValueClick}
                    icon={<EditIcon />}
                    alt='update'
                  />
                </>
              )}
              {status === BONUS_TYPE_CONFIRMED && (
                <IconButton
                  className='money-icon'
                  onClick={this.onPaidClick}
                  icon={<MoneyIcon />}
                  alt='set status to paid'
                />
              )}
              {(status === BONUS_TYPE_CONFIRMED ||
                status === BONUS_TYPE_WAITING_FOR_CONFIRMATION) && (
                <IconButton
                  className='delete-icon'
                  onClick={this.onSuspendClick}
                  icon={<BlockIcon />}
                  alt='suspend'
                />
              )}
              {status === BONUS_TYPE_SUSPENDED && (
                <IconButton
                  className='unsuspend-icon'
                  onClick={this.onUnSuspendClick}
                  icon={<SyncIcon />}
                  alt='undo suspension'
                />
              )}
            </Authorize>
          </Box>
        </Box>
        {finalValueDialogStatus && (
          <SetBonusFinalValueDialog
            bonusId={bonusId}
            value={final_value}
            onClose={this.closeAllDialogs}
            onSuccess={fetchBonusData}
          />
        )}
        {confirmDialogStatus && (
          <ConfirmBonusDialog
            bonusId={bonusId}
            onClose={this.closeAllDialogs}
            onSuccess={fetchBonusData}
          />
        )}
        {paidDialogStatus && (
          <PaidBonusDialog
            bonusId={bonusId}
            onClose={this.closeAllDialogs}
            onSuccess={fetchBonusData}
          />
        )}
        {suspendDialogStatus && (
          <SuspendBonusDialog
            bonusId={bonusId}
            onClose={this.closeAllDialogs}
            onSuccess={fetchBonusData}
          />
        )}
        {unSuspendDialogStatus && (
          <UnSuspendBonusDialog
            bonusId={bonusId}
            onClose={this.closeAllDialogs}
            onSuccess={fetchBonusData}
          />
        )}
      </>
    );
  }
}

ClientsBonusesDetailsDataSets.propTypes = {
  bonus: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.number,
    target_id: PropTypes.number,
    client_id: PropTypes.number,
    result_value: PropTypes.number,
    current_value: PropTypes.number,
    current_bonus: PropTypes.number,
    current_estimation_value: PropTypes.number,
    current_estimation_bonus: PropTypes.number,
    current_update_date: PropTypes.string,
    final_value: PropTypes.number,
    confirmed_by: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    confirmed_at: PropTypes.string,
    suspended_by: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    suspended_at: PropTypes.string,
    suspended_reason: PropTypes.string,
    history: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    currency: PropTypes.string.isRequired
  }).isRequired,
  fetchBonusData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired
};

export default withRouter(ClientsBonusesDetailsDataSets);
