/* eslint no-restricted-syntax: off */
import { isEmpty } from 'lodash/lang';

export const formatWholesalersEmailDomainsError = (
  errors,
  { emails, domains, orders_emails }
) => {
  const errEntries = Object.entries(errors);
  const emailsErr = errEntries.filter(
    entry => entry[0].split('.')[0] === 'emails'
  );
  const domainsErr = errEntries.filter(entry => entry[0].includes('domains'));
  const ordersEmailsErr = errEntries.filter(entry =>
    entry[0].includes('orders_emails')
  );

  const formatErrMsg = entries => {
    let msg = '';

    for (const [key, value] of entries) {
      const [name, inputIndex] = key.split('.');

      const correctInputValue = (() => {
        if (name === 'emails') return emails[inputIndex];
        if (name === 'domains') return domains[inputIndex];
        if (name === 'orders_emails') return orders_emails[inputIndex];
      })();

      const correctMsgValue = value.replace(key, correctInputValue);

      msg += `${correctMsgValue} `;
    }

    return msg;
  };

  return {
    emails: {
      status: !isEmpty(emailsErr),
      message: formatErrMsg(emailsErr)
    },
    domains: {
      status: !isEmpty(domainsErr),
      message: formatErrMsg(domainsErr)
    },
    orders_emails: {
      status: !isEmpty(ordersEmailsErr),
      message: formatErrMsg(ordersEmailsErr)
    }
  };
};
