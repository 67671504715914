import FlagIcon from '@material-ui/icons/Flag';

export const PRIORITY_1_ICON = (
  <FlagIcon className='high-priority' fontSize='small' />
);
export const PRIORITY_2_ICON = (
  <FlagIcon className='medium-priority' fontSize='small' />
);
export const PRIORITY_3_ICON = (
  <FlagIcon className='low-priority' fontSize='small' />
);

const priorityOptions = [
  { id: 1, icon: PRIORITY_1_ICON },
  { id: 2, icon: PRIORITY_2_ICON },
  { id: 3, icon: PRIORITY_3_ICON }
];

export default priorityOptions;
