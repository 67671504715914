import { Route } from 'react-router-dom';
import {
  PRODUCTS_LIST,
  PRODUCTS_CREATE,
  PRODUCTS_UPDATE,
  TOP_SELLERS_LIST
} from 'api/auth/permissions/Products';
import BasicContextConsumerWrapper from 'modules/Shared/components/BasicContextConsumerWrapper';
import Authorize from 'modules/Auth/component/Authorize';
import ProductsList from 'modules/Products/view/List';
import ProductDetails from 'modules/Products/view/Details/DetailsWrapper';
import ProductCreate from 'modules/Products/view/Create';
import ProductUpdate from 'modules/Products/view/Update';
import ProductBatchCreate from 'modules/Products/view/Batches/Create';
import ProductBatchUpdate from 'modules/Products/view/Batches/Update';
import ProductWholesalersMappingsCreate from 'modules/Products/view/WholesalersMappings/Create';
import ProductWholesalersMappingsUpdate from 'modules/Products/view/WholesalersMappings/Update';
import TopSellersList from 'modules/Products/view/TopSellers';

export const ROUTE_PRODUCTS_LIST = '/products';
export const ROUTE_PRODUCTS_DETAILS = '/products/details/:id';
export const ROUTE_PRODUCTS_CREATE = '/products/create';
export const ROUTE_PRODUCTS_UPDATE = '/products/update/:id';
export const ROUTE_PRODUCTS_BATCHES_CREATE =
  '/products/details/:id/create_batch';
export const ROUTE_PRODUCTS_BATCHES_UPDATE =
  '/products/details/:productId/update_batch/:batchId';
export const ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_CREATE =
  '/products/details/:id/create_mapping';
export const ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_UPDATE =
  '/products/details/:productId/update_mapping/:mappingId';
export const ROUTE_TOP_SELLERS_LIST = '/top_sellers';

export const permMap = {
  [ROUTE_PRODUCTS_LIST]: [PRODUCTS_LIST],
  [ROUTE_PRODUCTS_DETAILS]: [PRODUCTS_LIST],
  [ROUTE_PRODUCTS_CREATE]: [PRODUCTS_CREATE],
  [ROUTE_PRODUCTS_UPDATE]: [PRODUCTS_UPDATE],
  [ROUTE_PRODUCTS_BATCHES_CREATE]: [PRODUCTS_LIST],
  [ROUTE_PRODUCTS_BATCHES_UPDATE]: [PRODUCTS_LIST],
  [ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_CREATE]: [PRODUCTS_LIST],
  [ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_UPDATE]: [PRODUCTS_LIST],
  [ROUTE_TOP_SELLERS_LIST]: [TOP_SELLERS_LIST]
};

const routes = {
  protected: [
    <Route key='products' exact path={ROUTE_PRODUCTS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_PRODUCTS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <ProductsList contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='products-details' exact path={ROUTE_PRODUCTS_DETAILS}>
      <Authorize redirect permissions={permMap[ROUTE_PRODUCTS_DETAILS]}>
        <BasicContextConsumerWrapper
          component={fn => <ProductDetails contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='products-create' exact path={ROUTE_PRODUCTS_CREATE}>
      <Authorize redirect permissions={permMap[ROUTE_PRODUCTS_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ProductCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route key='products-update' exact path={ROUTE_PRODUCTS_UPDATE}>
      <Authorize redirect permissions={permMap[ROUTE_PRODUCTS_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ProductUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='products-batch-create'
      exact
      path={ROUTE_PRODUCTS_BATCHES_CREATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_PRODUCTS_BATCHES_CREATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ProductBatchCreate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='products-batch-update'
      exact
      path={ROUTE_PRODUCTS_BATCHES_UPDATE}
    >
      <Authorize redirect permissions={permMap[ROUTE_PRODUCTS_BATCHES_UPDATE]}>
        <BasicContextConsumerWrapper
          component={fn => <ProductBatchUpdate contextMethods={fn} />}
        />
      </Authorize>
    </Route>,
    <Route
      key='products-mapping-create'
      exact
      path={ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_CREATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_CREATE]}
      >
        <BasicContextConsumerWrapper
          component={fn => (
            <ProductWholesalersMappingsCreate contextMethods={fn} />
          )}
        />
      </Authorize>
    </Route>,
    <Route
      key='products-mapping-update'
      exact
      path={ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_UPDATE}
    >
      <Authorize
        redirect
        permissions={permMap[ROUTE_PRODUCTS_WHOLESALERS_MAPPINGS_UPDATE]}
      >
        <ProductWholesalersMappingsUpdate />
      </Authorize>
    </Route>,
    <Route key='products-top-sellers-list' exact path={ROUTE_TOP_SELLERS_LIST}>
      <Authorize redirect permissions={permMap[ROUTE_TOP_SELLERS_LIST]}>
        <BasicContextConsumerWrapper
          component={fn => <TopSellersList contextMethods={fn} />}
        />
      </Authorize>
    </Route>
  ]
};

export default routes;
