import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/lang';
import Button from 'modules/Layout/component/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Box } from '@material-ui/core';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import ApiError from 'api/exceptions/ApiError';
import t from 'translate/translate';
import Pagination, {
  formatMetaToPagination
} from 'modules/Layout/component/List/Pagination';

import AlertContext from 'modules/Shared/context/Alert/alertContext';
import ClientsApi from 'api/connections/Clients/ClientsApi';
import SaleGroupClientsFilter from 'modules/SalesGroups/component/Details/Clients/Create/Filter';
import Table from 'modules/Layout/component/List/Table';
import renderAddress from 'modules/Clients/utils/renderAddress';

class PriceListsAddClientsModal extends Component {
  static contextType = AlertContext;

  static getCols() {
    return [
      {
        property: 'name',
        label: 'Name',
        sortable: true
      },
      {
        property: 'tax_number',
        label: 'Tax number',
        sortable: true
      },
      {
        property: 'sales_group_name',
        label: 'Current shopping group'
      },
      {
        property: 'id',
        label: 'Address',
        sortable: false,
        render: (_, row) => renderAddress(row)
      }
    ];
  }

  constructor(props) {
    super(props);

    this.state = {
      filter: {
        name: '',
        tax_number: '',
        address: ''
      },
      filterValidation: {},
      sort: {},
      pagination: {
        per_page: 15,
        page: 1,
        total: 0
      },
      clients: [],
      tableSelected: [],
      loading: false
    };

    this.setSort = this.setSort.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onTableSelect = this.onTableSelect.bind(this);
    this.isRowDisabled = this.isRowDisabled.bind(this);
  }

  componentDidMount() {
    this.fetchClients();
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.context.setAlert(err.getPayload().message);

      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();
        this.setState(({ filterValidation: prevValidation }) => {
          return {
            filterValidation: { ...prevValidation, ...newValidateState }
          };
        });
      }
    }
  }

  onSubmit() {
    const { tableSelected } = this.state;

    this.props.onSuccess(tableSelected);
    this.props.onClose();
  }

  onTableSelect(id) {
    this.setState(({ tableSelected: prevSelected }) => {
      const selected = prevSelected.includes(id)
        ? prevSelected.filter(selectedId => selectedId !== id)
        : [...prevSelected, id];

      return { tableSelected: selected };
    });
  }

  setPagination(pagination) {
    this.setState(
      { pagination: { ...this.state.pagination, ...pagination } },
      () => this.fetchClients()
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: { ...this.state.filter, ...filter },
        pagination: { ...this.state.pagination, page: 1 }
      },
      () => this.fetchClients()
    );
  }

  setSort(sort) {
    this.setState({ sort: { ...this.state.sort, ...sort } }, () =>
      this.fetchClients()
    );
  }

  isRowDisabled({ id }) {
    return this.props.clients.map(client => client.id).includes(id);
  }

  async fetchClients() {
    const { countryId: country_id } = this.props;
    const { sort, filter } = this.state;
    const { per_page, page } = this.state.pagination;

    try {
      const {
        data: { data: clients, meta }
      } = await ClientsApi.getClients({
        ...sort,
        ...filter,
        per_page,
        page,
        country_id
      });

      this.setState({
        clients,
        pagination: formatMetaToPagination(meta)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  render() {
    const {
      clients,
      sort,
      pagination,
      filter,
      filterValidation,
      tableSelected,
      loading
    } = this.state;

    return (
      <Dialog
        open
        onClose={this.props.onClose}
        aria-labelledby='form-dialog-title'
        maxWidth='md'
        fullWidth
        transitionDuration={{
          enter: 200,
          exit: 100
        }}
      >
        <DialogTitle id='form-dialog-title'>
          {t('Add clients to price lists')}
        </DialogTitle>
        <DialogContent>
          <SaleGroupClientsFilter
            filter={filter}
            validation={filterValidation}
            setFilter={this.setFilter}
          />
          <Table
            cols={PriceListsAddClientsModal.getCols()}
            rows={clients}
            sort={sort}
            setSort={this.setSort}
            selected={tableSelected}
            onSelect={this.onTableSelect}
            dense
            isRowDisabled={this.isRowDisabled}
            rowDisabledTooltip='Client is already added'
          />
          <Box display='flex' flexDirection='row-reverse'>
            <Pagination
              pagination={pagination}
              setPagination={this.setPagination}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: '1rem' }}>
          <Button
            text={t('Cancel')}
            onClick={this.props.onClose}
            style={{ marginTop: '0', marginBottom: '0' }}
          />
          <Button
            text={t('Approve (<%=count%>)', { count: tableSelected.length })}
            color='primary'
            onClick={this.onSubmit}
            disabled={isEmpty(tableSelected)}
            tooltipMsg={isEmpty(tableSelected) ? 'Select Client first' : null}
            loading={loading}
            style={{ marginTop: '0', marginBottom: '0' }}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

PriceListsAddClientsModal.defaultProps = {
  countryId: null
};

PriceListsAddClientsModal.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  countryId: PropTypes.number
};

export default PriceListsAddClientsModal;
