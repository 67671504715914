import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ApiError from 'api/exceptions/ApiError';
import { Grid, Tab } from '@material-ui/core';
import TabPanel from 'modules/Layout/component/TabPanel';
import Tabs from 'modules/Layout/component/Tabs';
import t from 'translate/translate';
import { ROUTE_DEPARTMENTS_LIST } from 'routing/routes/Departments';
import NotFoundApiError from 'api/exceptions/NotFoundApiError';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import { ORDER_TYPES_SHOW } from 'api/auth/permissions/OrderTypes';

import DepartmentsDetailsBasicData from 'modules/Departments/view/Details/BasicData';
import DepartmentsDetailsPriceLists from 'modules/Departments/view/Details/PriceLists';
import DepartmentsDetailsOrderTypes from 'modules/Departments/view/Details/OrderTypes';

class DepartmentsDetailsWrapper extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.departmentId = parseInt(props.match.params.id, 10);

    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    if (!this.departmentId) {
      this.props.history.push(ROUTE_DEPARTMENTS_LIST);
    }
    this.props.contextMethods.setCurrentPage('Department details');
  }

  handleError(err) {
    if (err instanceof ApiError) {
      this.props.contextMethods.setAlert(err.getPayload().message);

      if (err instanceof NotFoundApiError) {
        this.props.history.push(ROUTE_DEPARTMENTS_LIST);
      }
    } else {
      console.error(err);
    }
  }

  render() {
    return (
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12}>
          <Tabs>
            <Tab label={t('General data')} value='general_data' />
            <Tab label={t('Price lists')} value='price_lists' />
            {this.context.hasPermission([ORDER_TYPES_SHOW]) && (
              <Tab label={t('Order types')} value='order_types' />
            )}
          </Tabs>
          <TabPanel index='general_data'>
            <DepartmentsDetailsBasicData
              departmentId={this.departmentId}
              handleError={this.handleError}
            />
          </TabPanel>
          <TabPanel index='price_lists'>
            <DepartmentsDetailsPriceLists
              departmentId={this.departmentId}
              handleError={this.handleError}
            />
          </TabPanel>
          <TabPanel index='order_types'>
            <DepartmentsDetailsOrderTypes
              departmentId={this.departmentId}
              setAlert={this.props.contextMethods.setAlert}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

DepartmentsDetailsWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired
  }).isRequired,
  contextMethods: PropTypes.shape({
    setAlert: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(DepartmentsDetailsWrapper);
