import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@material-ui/core';
import t from 'translate/translate';
import Input from 'modules/Layout/component/Input';
import Button from 'modules/Layout/component/Button';
import { ROUTE_VISITS_DETAILS } from 'routing/routes/Visits';
import { validate } from 'modules/Shared/utils/validator';
import { COLOR_VARIANTS_SUCCESS } from 'modules/Shared/type';
import ValidationApiError from 'api/exceptions/ValidationApiError';
import insertPathParams from 'api/utils/insertPathParams';
import AuthContext from 'modules/Auth/context/Auth/authContext';
import DateTimePicker from 'modules/Layout/component/Date/DateTimePicker';
import VisitsApi from 'api/connections/Visits/VisitsApi';
import GeocodeApi from 'api/connections/Geocode/GeocodeApi';
import debounce from 'lodash/debounce';
import ApiError from 'api/exceptions/ApiError';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';

class PostponeVisitForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props, context);
    const {
      setAlert,
      visit: { client_name, scheduled_at, place, lat, long }
    } = props;

    this.setAlert = setAlert;

    this.state = {
      formData: {
        place,
        scheduled_at,
        client_name,
        postponement_reason: '',
        lat,
        long
      },
      validation: {
        scheduled_at: {
          status: false,
          message: t('Field <%= field %> is required', {
            field: t('Visit time')
          })
        },
        place: {
          status: false,
          message: t('Field <%= field %> is required', { field: t('Country') })
        }
      },
      loading: false
    };

    this.setFetchPlaceDebounce = debounce(this.fetchPosition, 500);
  }

  handleResponse = res => {
    const { id } = res;
    this.setAlert({
      value: t('Success'),
      variant: COLOR_VARIANTS_SUCCESS
    });

    this.props.history.push(insertPathParams(ROUTE_VISITS_DETAILS, { id }));
  };

  handleError = err => {
    if (err instanceof ApiError) {
      const { message } = err.getPayload();
      this.setAlert({ value: `${t(message.value)}`, variant: message.variant });
      if (err instanceof ValidationApiError) {
        const newValidateState = err.processApiValidationError();

        this.setState(({ validation: validationState }) => {
          return {
            validation: {
              ...validationState,
              ...newValidateState
            }
          };
        });
      }
    }
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  onSubmit = async e => {
    e.preventDefault();
    const isValid = this.runValidation();
    if (isValid) await this.makeApiCall();
  };

  onChange = e => {
    const { name, type } = e.target;
    this.setState(prevState => {
      const { formData, validation } = prevState;
      const value = type === 'checkbox' ? e.target.checked : e.target.value;

      return {
        formData: {
          ...formData,
          [name]: value
        },
        validation: {
          ...validation,
          [name]: {
            ...validation[name],
            status: false
          }
        }
      };
    });

    if (name === 'place') {
      this.setFetchPlaceDebounce();
    }
  };

  onMapClick = async (lat, long) => {
    await this.fetchAddress(lat, long);
  };

  makeApiCall = async () => {
    const { formData } = this.state;
    const { id } = this.props.visit;
    this.setState({ loading: true });
    try {
      const {
        data: { data: res }
      } = await VisitsApi.postponeVisit(id, formData);
      this.handleResponse(res);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  runValidation = () => {
    const { formData, validation } = this.state;
    const [isValid, newValidateState] = validate(formData, validation);

    if (!isValid) {
      this.setState({ validation: newValidateState });
    }

    return isValid;
  };

  fetchPosition = async () => {
    const {
      formData: { place }
    } = this.state;

    if (!place) return null;

    try {
      const {
        data: { results, status }
      } = await GeocodeApi.getAddress({
        address: place,
        key: process.env.REACT_APP_GOOGLE_MAP_KEY
      });

      if (status === 'OK') {
        const { lat, lng: long } = results[0].geometry.location;
        this.setState(prevState => ({
          formData: { ...prevState.formData, lat, long }
        }));
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  fetchAddress = async (lat, long) => {
    try {
      const {
        data: { results, status }
      } = await GeocodeApi.getAddress({
        latlng: `${lat},${long}`,
        key: process.env.REACT_APP_GOOGLE_MAP_KEY
      });

      if (status === 'OK') {
        const { formatted_address: place } = results[0];
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            place,
            meeting_point_at_the_clients: false,
            lat,
            long
          }
        }));
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  render() {
    const {
      formData: {
        place,
        scheduled_at,
        client_name,
        postponement_reason,
        lat,
        long
      },
      validation,
      loading
    } = this.state;

    const { visit } = this.props;

    return (
      <>
        <form noValidate onSubmit={this.onSubmit}>
          <Typography component='h5' variant='h5'>
            {client_name}
          </Typography>
          <DateTimePicker
            label='Visit time'
            name='scheduled_at'
            required
            onChange={this.onChange}
            value={scheduled_at}
            errorStatus={validation.scheduled_at.status}
            errorText={validation.scheduled_at.message}
          />
          <Input
            name='place'
            label='Place'
            value={place}
            onChange={this.onChange}
            errorStatus={validation.place.status}
            errorText={validation.place.message}
          />
          <Input
            name='postponement_reason'
            label='Postponement reason'
            value={postponement_reason}
            onChange={this.onChange}
          />
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-around'
            width={1}
          >
            <Box>
              <Button onClick={this.handleCancel} text={t('Cancel')} />
            </Box>
            <Box>
              <Button
                type='submit'
                fullWidth
                color='primary'
                text={t('Save')}
                loading={loading}
              />
            </Box>
          </Box>
        </form>
        <div style={{ width: '100%', height: '60vh' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_KEY
            }}
            defaultCenter={{
              lat: parseFloat(visit.lat) || 52.4,
              lng: parseFloat(visit.long) || 16.6
            }}
            center={{
              lat: parseFloat(lat) || 52.4,
              lng: parseFloat(long) || 16.6
            }}
            defaultZoom={lat ? 8 : 3}
            zoom={lat ? 8 : 3}
            onClick={({ lat: placeLat, lng: placeLong }) =>
              this.onMapClick(placeLat, placeLong)
            }
          >
            <IconButton
              lat={parseFloat(lat) || 0.0}
              lng={parseFloat(long) || 0.0}
              style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <RoomIcon fontSize='large' style={{ color: '#c73a3a' }} />
            </IconButton>
          </GoogleMapReact>
        </div>
      </>
    );
  }
}

PostponeVisitForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired,
  visit: PropTypes.shape({
    client_name: PropTypes.string,
    scheduled_at: PropTypes.string,
    description: PropTypes.string,
    place: PropTypes.string,
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    long: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.number,
    id: PropTypes.number
  }).isRequired,
  setAlert: PropTypes.func.isRequired
};

export default withRouter(PostponeVisitForm);
